/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Button,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import InputMask from 'react-input-mask'
import axios from 'axios'

import Header from "components/Headers/Header.jsx";
import UploadDoc from "components/UploadDoc.jsx";
import FuncoesProcob from "funcoes/FuncoesProcob";
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'

class NewConsulta extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            valor: '',
            dado: '',
            renda: '',
            nome: '',
            telefone: '',
            dadoSolidario1: '',
            rendaSolidario1: '',
            nomeSolidario1: '',
            telefoneSolidario1: '',
            dadoSolidario2: '',
            rendaSolidario2: '',
            nomeSolidario2: '',
            telefoneSolidario2: '',
            cliente_id: '',
            clienteSolidario1_id: '',
            clienteSolidario2_id: '',
            tipo_consulta: 1,
            necessario: 1,
            resultado: [],
            mask: '999.999.999-99',
            mask1: '999.999.999-99',
            mask2: '999.999.999-99'
        }
    }

    mascara = (evento) => {
        if(evento.target.value === 'cpf'){
            this.setState({mask: '999.999.999-99'})
        }
        else{
            this.setState({mask: '99.999.999/9999-99'})
        }
    }

    mascara1 = (evento) => {
        if(evento.target.value === 'cpf'){
            this.setState({mask1: '999.999.999-99'})
        }
        else{
            this.setState({mask1: '99.999.999/9999-99'})
        }
    }

    mascara2 = (evento) => {
        if(evento.target.value === 'cpf'){
            this.setState({mask2: '999.999.999-99'})
        }
        else{
            this.setState({mask2: '99.999.999/9999-99'})
        }
    }

    changeValor = (evento) => {
        this.setState({ valor: evento.target.value})
    }
    
    changeDado = (evento) => {
        this.setState({ dado: evento.target.value} )
    }
    
    changeRenda = (evento) => {
        this.setState({ renda: evento.target.value})
    }

    changeTelefone = (evento) => {
        this.setState({ telefone: evento.target.value} )
    }

    changeNome = (evento) => {
        this.setState({ nome: evento.target.value} )
    }
    
    changeDadoSolidario1 = (evento) => {
        this.setState({ dadoSolidario1: evento.target.value} )
    }
    
    changeRendaSolidario1 = (evento) => {
        this.setState({ rendaSolidario1: evento.target.value})
    }

    changeTelefoneSolidario1 = (evento) => {
        this.setState({ telefoneSolidario1: evento.target.value} )
    }

    changeNomeSolidario1 = (evento) => {
        this.setState({ nomeSolidario1: evento.target.value} )
    }
    
    changeDadoSolidario2 = (evento) => {
        this.setState({ dadoSolidario2: evento.target.value} )
    }
    
    changeRendaSolidario2 = (evento) => {
        this.setState({ rendaSolidario2: evento.target.value})
    }

    changeTelefoneSolidario2 = (evento) => {
        this.setState({ telefoneSolidario2: evento.target.value} )
    }

    changeNomeSolidario2 = (evento) => {
        this.setState({ nomeSolidario2: evento.target.value} )
    }

    consultar = () => {
        if(this.state.dado !== ''){
            
            api_epar.get( '/cliente/' + this.state.dado.replace('/', '_')).then((cliente) => {
                if(cliente.data.data.length > 0){
                    this.setState({cliente_id: cliente.data.data[0].id})
                    this.setState({nome: cliente.data.data[0].nome})
                    this.setState({telefone: cliente.data.data[0].telefone})
                    this.setState({renda: cliente.data.data[0].renda_informada})
                }
                else{
                    api_epar.post( '/cliente', {nome: this.state.nome, cpf_cnpj: this.state.dado, telefone: this.state.telefone, renda_informada: this.state.renda}).then((retorno) => {
                        this.setState({cliente_id: retorno.data.data})
                    }).catch((error) => {
                        console.log(error)
                    })
                }

                axios.get('https://api.procob.com/consultas/v2/L0001/' + this.state.dado.replace('/', '_'), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((dadoCliente) => {
                    console.log(dadoCliente.data)
                }).catch((error) => {
                    console.log(error)
                })

                if(this.state.valor != '' && this.state.renda != ''){
                    if(this.state.valor > this.state.renda*0.3){
                        console.log('Não é possível fazer consulta 30%')
                        if(this.state.dadoSolidario1 !== ''){
                            this.solidario1().then(() => {
                                if(this.state.valor > (this.state.renda*0.3 + this.state.rendaSolidario1*0.3)){
                                    console.log('Não é possível fazer consulta 30%')
                                    if(this.state.dadoSolidario2 !== ''){
                                        this.solidario2().then(() => {
                                            if(this.state.valor > (this.state.renda*0.3 + this.state.rendaSolidario1*0.3 + this.state.rendaSolidario2*0.3)){
                                                console.log('Não é possível fazer consulta 30%')
                                            }
                                            else{
                                                console.log('Realizar consulta')
                                                this.realizarConsulta()
                                                this.realizarConsultaSolidario(this.state.dadoSolidario1, this.state.clienteSolidario1_id)
                                                this.realizarConsultaSolidario(this.state.dadoSolidario2, this.state.clienteSolidario2_id)
                                            }
                                        })
                                    }
                                    else{
                                        console.log('Solidário2 sem CPF')
                                    }
                                }
                                else{
                                    console.log('Realizar consulta')
                                    this.realizarConsulta()
                                    this.realizarConsultaSolidario(this.state.dadoSolidario1, this.state.clienteSolidario1_id)
                                }
                            })
                        }
                        else{
                            console.log('Solidário1 sem CPF')
                        }
                    }
                    else{
                        console.log('Realizar consulta')
                        this.realizarConsulta()
                    }
                }
                else{
                    console.log('Campo(s) vazio(s)')
                    console.log(this.state.valor)
                    console.log(this.state.renda)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        else{
            console.log('Principal sem CPF')
        }
    }

    realizarConsulta = () => {
        api_epar.get( '/tipoconsulta/' + this.state.tipo_consulta).then((retornoTipoConsulta) => {
            console.log(retornoTipoConsulta.data.data[0])
            if(retornoTipoConsulta.data.data[0].descricao === 'Procob'){
                this.consultaProcob()
            }
            else{
                console.log('Falta desenvolver')
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    realizarConsultaSolidario = (dado, id_cliente) => {
        api_epar.get( '/tipoconsulta/' + this.state.tipo_consulta).then((retornoTipoConsulta) => {
            console.log(retornoTipoConsulta.data.data[0])
            if(retornoTipoConsulta.data.data[0].descricao === 'Procob'){
                this.consultaProcobSolidario(dado, id_cliente)
            }
            else{
                console.log('Falta desenvolver')
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    consultaProcob = () => {
        axios.get('https://api.procob.com/restricao/v1/R0001/' + this.state.dado.replace(/\D/g, ''), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((response) => {
            response.data.usuario = JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            response.data.cliente_id = this.state.cliente_id
            FuncoesProcob.inserirProcobConsulta(response.data, this.state.dado).then((retornoConsulta) => {
                this.inserirConsultaFinanceiro(response.data, retornoConsulta.data.data)
            })
            console.log(response.data)
        }).catch((error) => {
          console.log(error)
        })
    }

    consultaProcobSolidario = (dado, id_cliente) => {
        axios.get('https://api.procob.com/restricao/v1/R0001/' + dado.replace(/\D/g, ''), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((response) => {
            response.data.usuario = JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            response.data.cliente_id = id_cliente
            FuncoesProcob.inserirProcobConsulta(response.data, dado).then((retornoConsulta) => {})
            console.log(response.data)
        }).catch((error) => {
          console.log(error)
        })
    }

    inserirConsultaFinanceiro = (consulta, consulta_id) => {
        api_epar.post( '/consulta', {
            tipo_consulta_id: this.state.tipo_consulta,
            id_tabela_consulta: consulta_id,
            data_consulta: consulta.date + ' ' + consulta.hour,
            usuarios_id: consulta.usuario,
            valor: this.state.valor,
        }).then((novaConsulta) => {
            this.setState({resultado: [{
                nome: consulta.content.confirmei.conteudo.nome_razao,
                info: this.state.dado,
                id: novaConsulta.data.data
            }]})
            console.log(novaConsulta.data)
            api_epar.post( '/clienteshasconsulta', {
                clientes_id: consulta.cliente_id,
                consulta_id: novaConsulta.data.data
            }).then((retornoClienteConsulta) => {
                console.log(retornoClienteConsulta)
            }).catch((error) => {
                console.log(error)
            })
            if(this.state.clienteSolidario1_id !== '' && this.state.necessario >= 2){
                api_epar.post( '/clienteshasconsulta', {
                    clientes_id: this.state.clienteSolidario1_id,
                    consulta_id: novaConsulta.data.data
                }).then((retornoClienteConsulta) => {
                    console.log(retornoClienteConsulta)
                }).catch((error) => {
                    console.log(error)
                })
            }
            if(this.state.clienteSolidario12_id != '' && this.state.necessario === 3){
                api_epar.post( '/clienteshasconsulta', {
                    clientes_id: this.state.clienteSolidario2_id,
                    consulta_id: novaConsulta.data.data
                }).then((retornoClienteConsulta) => {
                    console.log(retornoClienteConsulta)
                }).catch((error) => {
                    console.log(error)
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    solidario1 = async () => {
        this.setState({necessario: 2})
        console.log('retorno.dadoSolidario1')
        return api_epar.get( '/cliente/' + this.state.dadoSolidario1.replace('/', '_')).then((clienteSolidario1) => {
            if(clienteSolidario1.data.data.length > 0){
                this.setState({clienteSolidario1_id: clienteSolidario1.data.data[0].id})
                this.setState({nomeSolidario1: clienteSolidario1.data.data[0].nome})
                this.setState({telefoneSolidario1: clienteSolidario1.data.data[0].telefone})
                this.setState({rendaSolidario1: clienteSolidario1.data.data[0].renda_informada})
            }
            else{
                api_epar.post( '/cliente', {nome: this.state.nomeSolidario1, cpf_cnpj: this.state.dadoSolidario1, telefone: this.state.telefoneSolidario1, renda_informada: this.state.rendaSolidario1}).then((retorno) => {
                    //response.data.cliente_id = retorno.data.data
                    this.setState({clienteSolidario1_id: retorno.data.data})
                }).catch((error) => {
                    console.log(error)
                })
            }
            axios.get('https://api.procob.com/consultas/v2/L0001/' + this.state.dadoSolidario1.replace('/', '_'), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((dadoCliente) => {
                console.log(dadoCliente.data)
            }).catch((error) => {
                console.log(error)
            })
        })
    }

    solidario2 = async () => {
        this.setState({necessario: 3})
        console.log('retorno.dadoSolidario2')
        return api_epar.get( '/cliente/' + this.state.dadoSolidario2.replace('/', '_')).then((clienteSolidario2) => {
            if(clienteSolidario2.data.data.length > 0){
                this.setState({clienteSolidario2_id: clienteSolidario2.data.data[0].id})
                this.setState({nomeSolidario2: clienteSolidario2.data.data[0].nome})
                this.setState({telefoneSolidario2: clienteSolidario2.data.data[0].telefone})
                this.setState({rendaSolidario2: clienteSolidario2.data.data[0].renda_informada})
            }
            else{
                api_epar.post( '/cliente', {nome: this.state.nomeSolidario2, cpf_cnpj: this.state.dadoSolidario2, telefone: this.state.telefoneSolidario2, renda_informada: this.state.rendaSolidario2}).then((retorno) => {
                    //response.data.cliente_id = retorno.data.data
                    this.setState({clienteSolidario2_id: retorno.data.data})
                }).catch((error) => {
                    console.log(error)
                })
            }
            axios.get('https://api.procob.com/consultas/v2/L0001/' + this.state.dadoSolidario2.replace('/', '_'), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((dadoCliente) => {
                console.log(dadoCliente.data)
            }).catch((error) => {
                console.log(error)
            })
        })
    }

    // detalhar = (evento) => {
    //     //console.log(evento.currentTarget.dataset.value)
    //     this.props.history.push('/admin/detalhe/' + evento.currentTarget.dataset.value)
    // }

    rowConsulta = (record) => {
        return (
            <Card key={record.id} className="card-stats mb-xl-0 w-50 mx-auto" data-value={record.id}>
                <CardBody>
                    <Row>
                    <div className="col">
                        <CardTitle
                        tag="h4"
                        className="text-uppercase text-muted mb-0"
                        >
                        {record.info}
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0">
                        {record.nome}
                        </span>
                    </div>
                    <Col className="col-auto mt-2">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fas fa-check" />
                        </div>
                    </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }

    
  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt-8 bg-gradient-info" fluid style={{minHeight: '65vh'}}>
            <FormGroup>
                <Row className='mb-4'>
                    <Col md={11} className='mb-4'>
                        <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-money-coins" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Valor" type="number" value={this.state.valor} onChange={this.changeValor} />
                        </InputGroup>
                    </Col>
                    <Col md={1} className='mb-4'>
                        <Button onClick={this.consultar}>Buscar</Button>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <Card>
                            <Col md={12}>
                                <h1 className='text-center p-3' style={{color: 'gray'}}>Principal</h1>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-active-40" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="select" onChange={this.mascara} >
                                        <option value='cpf'>CPF</option>
                                        <option value='cnpj'>CNPJ</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-chat-round" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input name='dado' placeholder="CPF / CNPJ" type="text" value={this.state.dado} onChange={this.changeDado} mask={this.state.mask} maskChar=' ' tag={InputMask} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-money-coins" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Renda" type="number" value={this.state.renda} onChange={this.changeRenda} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-mobile-button" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Telefone" type="text" value={this.state.telefone} onChange={this.changeTelefone} mask='(99) 99999-9999' maskChar=' ' tag={InputMask} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-single-02" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder='Nome' value={this.state.nome} onChange={this.changeNome}></Input>
                                </InputGroup>
                            </Col>                     
                        </Card>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <Card>
                            <Col md={12}>
                                <h1 className='text-center p-3' style={{color: 'gray'}}>Solidário</h1>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-active-40" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="select" onChange={this.mascara1} >
                                        <option value='cpf'>CPF</option>
                                        <option value='cnpj'>CNPJ</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-chat-round" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input name='dadoSolidario1' placeholder="CPF / CNPJ" type="text" value={this.state.dadoSolidario1} onChange={this.changeDadoSolidario1} mask={this.state.mask1} maskChar=' ' tag={InputMask} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-money-coins" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Renda" type="number" value={this.state.rendaSolidario1} onChange={this.changeRendaSolidario1} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-mobile-button" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Telefone" type="text" value={this.state.telefoneSolidario1} onChange={this.changeTelefoneSolidario1} mask='(99) 99999-9999' maskChar=' ' tag={InputMask} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-single-02" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder='Nome' value={this.state.nomeSolidario1} onChange={this.changeNomeSolidario1}></Input>
                                </InputGroup>
                            </Col>
                        </Card>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <Card>
                            <Col md={12}>
                                <h1 className='text-center p-3' style={{color: 'gray'}}>Solidário</h1>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-active-40" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="select" onChange={this.mascara2} >
                                        <option value='cpf'>CPF</option>
                                        <option value='cnpj'>CNPJ</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-chat-round" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input name='dadoSolidario2' placeholder="CPF / CNPJ" type="text" value={this.state.dadoSolidario2} onChange={this.changeDadoSolidario2} mask={this.state.mask2} maskChar=' ' tag={InputMask} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-money-coins" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Renda" type="number" value={this.state.rendaSolidario2} onChange={this.changeRendaSolidario2} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-mobile-button" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Telefone" type="text" value={this.state.telefoneSolidario2} onChange={this.changeTelefoneSolidario2} mask='(99) 99999-9999' maskChar=' ' tag={InputMask} />
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-single-02" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder='Nome' value={this.state.nomeSolidario2} onChange={this.changeNomeSolidario2}></Input>
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <UploadDoc userDoc='dadoSolidario2'></UploadDoc>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </FormGroup>
            {this.state.resultado.map(this.rowConsulta)}
        </Container>
      </>
    );
  }
}

export default NewConsulta;
