/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom'
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    Input,
    Button,
    Table,
    Label,
    Container,
    CardBody,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter
} from "reactstrap";

import "assets/scss/myconsulta.scss";
import FuncoesComum from '../funcoes/FuncoesComum'
import {Link} from 'react-router-dom'
import UploadDoc from "components/UploadDoc.jsx";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'

import Header from "components/Headers/Header.jsx";
import moment from "moment";
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'

class MyConsulta extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            consultas: [],
            inicio: moment().subtract(10, 'days').format('YYYY-MM-DD'),
            fim: moment().format('YYYY-MM-DD'),
            status: 'Aprovado',
            modal: false,
            cpf_cnpj: '',
            cliente: '',
            valorMin: '',
            valorMax: ''
        }
    }

    componentDidMount(){
        api_epar.get( '/clienteshasconsulta/user/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).id).then((consultas) => {
            console.log(consultas.data.data)
            this.setState({consultas: FuncoesComum.unirDadosConsulta(consultas)})
        }).catch((error) => {
            console.log(error)
        })
        // api_epar.get( '/procobconsulta/user/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).id).then((consultas) => {
        //     console.log(consultas.data.data)
        //     this.setState({consultas: consultas.data.data})
        // }).catch((error) => {
        //     console.log(error)
        // })
    }

    changeInicio = (evento) => {
      this.setState({ inicio: evento.target.value})
    }

    changeFim = (evento) => {
        this.setState({ fim: evento.target.value} )
    }

    changeStatus = (evento) => {
        this.setState({ status: evento.target.value} )
    }

    changeCpfCnpj = (evento) => {
      this.setState({ cpf_cnpj: evento.target.value})
    }
    
    changeCliente = (evento) => {
      this.setState({ cliente: evento.target.value})
    }

    changeValorMin = (evento) => {
      this.setState({ valorMin: evento.target.value})
    }

    changeValorMax = (evento) => {
      this.setState({ valorMax: evento.target.value})
    }

    filtrar = () => {
      // console.log('Inicio: ' + this.state.inicio + ' / Fim: ' + this.state.fim + ' / Status: ' + this.state.status)
      api_epar.post( '/clienteshasconsulta/filtro/',{
        id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id,
        cliente: this.state.cliente,
        cpf_cnpj: this.state.cpf_cnpj,
        inicio: moment(this.state.inicio).format('YYYY-MM-DD'),
        fim: moment(this.state.fim).add(1, 'days').format('YYYY-MM-DD'),
        valorMin: this.state.valorMin,
        valorMax: this.state.valorMax
      }).then((consultas) => {
        console.log(consultas.data.data)
        this.setState({consultas: FuncoesComum.unirDadosConsulta(consultas)})
      }).catch((error) => {
        console.log(error)
      })
    }
    
    // rowsConsultas = (record) => {
    //   var color = null
    //   if(record.consult_id % 2 === 1){
    //       color = '#1d3762'
    //   }
    //   return(
    //     <tr key={record.id} data-value={record.id} style={{backgroundColor: color}}>
    //       <th scope="row">
    //         {record.nome}
    //       </th>
    //       <td>
    //         {record.cpf_cnpj}
    //       </td>
    //       <td>
    //         {new Date(record.data_consulta).toLocaleString('pt-BR', {timeZone: 'GMT'})}
    //       </td>
    //       <td>
    //         {record.tipo_locador}
    //       </td>
    //       <td>
    //         {record.descricao}
    //       </td>
    //       <td>
    //         {record.status}
    //       </td>
    //       <td>
    //         {record.motivo}
    //       </td>
    //     </tr>
    //   )
    // }

    rowsConsultasCard = (record) => {
      if(record.consulta_id !== undefined){
        var link = '/admin/gerarcontrato/' + record.consulta_id
        var linkDetalhe = '/admin/detalhe/' + record.consulta_id
        var linkConsulta = '/admin/wizard/' + record.consulta_id
        var valor = 0
        var tipoButton
        var mensagem
        var buttonDetalhe = ''

        if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao === 'master'){
          buttonDetalhe = <Button className='colorButtonSecundario float-right mt-4' to={linkDetalhe} tag={Link}>Detalhe da consulta</Button>
        }

        // console.log(record.valor)
        if(record.valor !== null){
          valor = record.valor
        }
        var button = <Button className='colorButton float-right'>Mensagem</Button>

        var statusGeral = new Set(record.statusGeral.replace(/ /g, '').split(','))
        var arrayStatus = []
        for(let status of statusGeral){
          arrayStatus.push(status)
        }

        // console.log(arrayStatus)

        if(arrayStatus.indexOf('Negado') !== -1){
          console.log('Negado')
          tipoButton = 2
        }
        else if(arrayStatus.indexOf('Aprovadocomressalva') !== -1){
          console.log('Aprovado com ressalva')
          tipoButton = 1
        }
        else if(arrayStatus.indexOf('Aprovado') !== -1){
          console.log('Aprovado')
          tipoButton = 0
        }
  
        // if(record.status === 'Negado'){
        //   tipoButton = 2
        // }
        // else if(record.status === 'Aprovado com ressalva'){
        //   tipoButton = 1
        // }
        // else{
        //   for(var i = 0; i < record.solidario.length; i++){
        //     if(record.solidario[i].status === 'Negado'){
        //       tipoButton = 2
        //       break
        //     }
        //     else if(record.solidario[i].status === 'Aprovado com ressalva'){
        //       tipoButton = 1
        //     }
        //   }
        // }
        if(tipoButton === 0){
          button = <Button className='colorButtonSecundario float-right' to={link} tag={Link}>Gerar contrato</Button>
          mensagem = <Label style={{backgroundColor: '#006600', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado</Label>
        }
        if(tipoButton === 1){
          button = <Button className='colorButtonSecundario float-right' onClick={this.modalConsulta} data-value={record.consulta_id}>Upload de arquivos</Button>
          mensagem = <Label style={{backgroundColor: '#b37400', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado com ressalva</Label>
        }
        if(tipoButton === 2){
          button = ''
          mensagem = <Label style={{backgroundColor: '#660000', borderRadius: '4px', color: 'white'}} className='p-1'>Negado</Label>
        }
        
        // if(record.etapa === 'Retorno da busca' || record.etapa === 'Gerar contrato' || record.etapa === 'Realizar busca'){
        //   button = <Button className='colorButton float-right' to={link} tag={Link}>Gerar contrato</Button>
        // }
        // else{
        //   button = <Button className='colorButton float-right' to={linkConsulta} tag={Link}>Voltar para consulta</Button>
        // }
        return (
          <Col key={record.consulta_id} md={4} className='mb-4'>
            <Card style={{minHeight: '55vh'}}>
              <CardHeader>
                <h2 className="mb-0" style={{color: 'black'}}><strong>Consulta:</strong> {record.consulta_id}</h2>
              </CardHeader>
              <CardBody className='cardConsultas'>
                <strong>Valor: </strong>{FuncoesComum.formatarMoeda(valor)} <br/>
                {/* <strong>Locador principal: </strong>{record.principal} / {record.cpf_cnpj}<br/> */}
                <strong>Locatário principal: </strong>{record.cpf_cnpj}<br/>
                <strong>Locatário(s) solidário(s): </strong><br/>{record.solidario.map(this.rowSolidario)}
                <strong>Data da consulta: </strong>{new Date(record.data_consulta).toLocaleString('pt-BR')}<br/>
                {/* <Button className='colorButtonSecundario float-right mt-4' to={linkDetalhe} tag={Link}>Detalhe da consulta</Button> */}
                {buttonDetalhe}
              </CardBody>
              <CardFooter>
                <Label className='mt-2' style={{color: 'black'}}>
                  <strong>Status: </strong>{mensagem}
                </Label>
                {/* <Button className='colorButton float-right' to={link} tag={Link}>Gerar contrato</Button> */}
                {button}
              </CardFooter>
            </Card>
          </Col>
        )
      }
      else{
        return (null)
      }
    }

    rowSolidario = (record) => {
      return (
        <div key={record.index}>
          {/* {record.nome} / {record.cpf_cnpj} */}
          {record.cpf_cnpj}
        </div>
      )
    }

    // Exibir MODAL para adicionar SOLIDÁRIO
    toggleModal = () => {
      this.setState({modal: !this.state.modal})
    }

    modalConsulta = (evento) => {
      console.log(evento.currentTarget.dataset.value)
      var consulta = []
      var index = parseInt(this.state.consultas.map((e) => {
        return e.consulta_id
      }).indexOf(parseInt(evento.currentTarget.dataset.value)))

      if(index !== -1){
        consulta = this.state.consultas[index]
        var element = []
        if(consulta.status === 'Aprovado com ressalva'){
          element = [{
            dado: consulta.cpf_cnpj,
            cliente: consulta.cliente_id
          }]
          // console.log(document.getElementById('arquivos'))
        }

        for(var i = 0; i < consulta.solidario.length; i++){
          if(consulta.solidario[i].status === 'Aprovado com ressalva'){
            element = element.concat([{
              dado: consulta.solidario[i].cpf_cnpj,
              cliente: consulta.solidario[i].cliente_id
            }])
          }
        }
      }

      ReactDOM.render(this.uploadArquivos(element), document.getElementById('arquivos'))
        
      this.toggleModal()
      
    }

    uploadArquivos = (element) => {
      return(
        <Modal isOpen={true} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Upload de arquivos</ModalHeader>
          <ModalBody>
            {element.map(this.inputUpload)}
          </ModalBody>
        </Modal>
      )
    }

    closeModal = () => {
      ReactDOM.render(<div></div>, document.getElementById('arquivos'))
    }

    inputUpload = (record) => { 
      return(
        <div className='text-center' key={record.dado}>
          <Label style={{fontSize: '1.2em', fontWeight: 'bold'}}>{record.dado}</Label>
          <UploadDoc dado={record.dado} arquivo='CPF' cliente={record.cliente} documento='1' />
          <UploadDoc dado={record.dado} arquivo='RG' cliente={record.cliente} documento='2'/>
          <UploadDoc dado={record.dado} arquivo='Comprovante de endereço' cliente={record.cliente} documento='3'/>
          <UploadDoc dado={record.dado} arquivo='Comprovante de renda' cliente={record.cliente} documento='4'/>
        </div>
      )
    }

    // detalhar = (evento) => {
    //   //console.log(evento.currentTarget.dataset.value)
    //   this.props.history.push('/admin/detalhe/' + evento.currentTarget.dataset.value)
    // }

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        {/* <Container className={"mt--8 pb-8" + FuncoesComum.layout()} fluid style={{minHeight: '65vh'}}> */}
        <Container className={"mt-8 pb-8"} fluid style={{minHeight: '65vh'}}>
          <Row className="mt-5 mb-4">
            <Col md={2} className='p-2'>
              <Input style={{color: 'black'}} placeholder="CPF / CNPJ" type="text" value={this.state.cpf_cnpj} onChange={this.changeCpfCnpj} />
            </Col>
            {/* <Col md={5} className='p-2'>
              <Input style={{color: 'black'}} placeholder="Cliente" type="text" value={this.state.cliente} onChange={this.changeCliente} />
            </Col> */}
            <Col md={2} className='p-2'>
              <Input style={{color: 'black'}} placeholder="Valor mínimo" type="number" value={this.state.valorMin} onChange={this.changeValorMin} />
            </Col>
            <Col md={2} className='p-2'>
              <Input style={{color: 'black'}} placeholder="Valor máximo" type="number" value={this.state.valorMax} onChange={this.changeValorMax} />
            </Col>
            <Col md={2} className='p-2'>
                <Input type='date' value={this.state.inicio} onChange={this.changeInicio} style={{color: 'black'}}></Input>
            </Col>
            <Col md={2} className='p-2'>
                <Input type='date' value={this.state.fim} onChange={this.changeFim} style={{color: 'black'}}></Input>
            </Col>
            {/* <Col md={6}>
                <Input type='select' onChange={this.changeStatus}>
                  <option value='Aprovado'>Aprovado</option>
                  <option value='Negado'>Negado</option>
                  <option value='Aguardando IdWall'>Aguardando IdWall</option>
                </Input>
            </Col> */}
            <Col md={2} className='p-2'>
                <Button className='colorButtonSecundario float-right' onClick={this.filtrar}>Filtrar</Button>
            </Col>
            {/* {this.state.consultas.map(this.rowsConsultasCard)} */}
            {/* <div className="col"> */}
              {/* <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">Consultas</h3>
                </CardHeader> */}
                {/* <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Nome / Razão Social</th>
                      <th scope="col">CPF / CNPJ</th>
                      <th scope="col">Data e Hora</th>
                      <th scope="col">Locador</th>
                      <th scope="col">Tipo da consulta</th>
                      <th scope="col">Status</th>
                      <th scope="col">Motivo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.consultas.map(this.rowsConsultas)} */}
                    {/* <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/bootstrap.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Argon Design System
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,500 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          pending
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip731399078"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip731399078"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip491083084"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip491083084"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip528540780"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip528540780"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip237898869"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip237898869"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                    </tr> */}
                  {/* </tbody>
                </Table> */}
              {/* </Card> */}
            {/* </div> */}
          </Row>
          <Row>
            {this.state.consultas.map(this.rowsConsultasCard)}
          </Row>
          <div id='arquivos'>

          </div>
        </Container>
      </>
    );
  }
}

export default MyConsulta;
