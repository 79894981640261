/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    Input,
    Table,
    Container,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'


class TelaAprovacaoTodos extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            mensagem: '',
            motivo: '',
            alert: false
        }
    }

    componentDidMount(){

      
      console.log(api_request + '/clienteshasdocumentacao/' + new URLSearchParams(this.props.location.search).get('cliente'))

      if(parseInt(new URLSearchParams(this.props.location.search).get('opcao')) === 4){
        this.toggleAlert()
      }
      else{
        // Busca os documentos de um cliente
        api_epar.get( '/clienteshasdocumentacao/' + new URLSearchParams(this.props.location.search).get('cliente')).then((resposta) => {
            console.log(resposta)
            if(resposta.data.data.length > 0){
              // Verifica se tem documentos pendentes a serem analisados de um cliente e consulta específica
                var documentos = resposta.data.data.filter(e => {return e.documentacao_id === 4 && e.aprovado === 1 && e.consulta_id === parseInt(new URLSearchParams(this.props.location.search).get('consulta'))})
                if(documentos.length > 0){
                    var cont = 0
                    // Analisado que há documentos a serem analisados, ele passa um por um alterando o seu status no banco de acordo
                    // com a opção escolhida
                    for(var i = 0; i < documentos.length; i++){      
                        // api_epar.get( '/docatualizar/' + new URLSearchParams(this.props.location.search).get('cliente') + '/4/' + documentos[i].path.replace(/\//g, '|') + '/' + new URLSearchParams(this.props.location.search).get('opcao') + '/Sem motivo').then((update) => {
                          api_epar.post( '/docatualizar/' + new URLSearchParams(this.props.location.search).get('cliente') + '/4/' + documentos[i].path.replace(/\//g, '|') + '/' + new URLSearchParams(this.props.location.search).get('opcao'), {motivo: 'Sem motivo'}).then((update) => {
                            console.log(update.data.data)
                            cont++
                            if(cont === documentos.length){
                                this.setState({mensagem: 'ANÁLISE REALIZADA COM SUCESSO!!'})
                            }
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                }
                else{
                    this.setState({mensagem: 'NÃO EXISTE(M) DOCUMENTO(S) PARA SEREM ANALISADOS'})
                }
            }
            else{
                this.setState({mensagem: 'NÃO EXISTE(M) DOCUMENTO(S)'})
            }
        }).catch((error) => {
          console.log(error)
        })
      }
    }
    
    toggleAlert = () => {
      this.setState({alert: !this.state.alert})
      if(this.state.alert){
        if(this.state.motivo){
          // Busca os documentos de um cliente
          api_epar.get( '/clienteshasdocumentacao/' + new URLSearchParams(this.props.location.search).get('cliente')).then((resposta) => {
            console.log(resposta)
            if(resposta.data.data.length > 0){
              // Verifica se tem documentos pendentes a serem analisados de um cliente e consulta específica
                var documentos = resposta.data.data.filter(e => {return e.documentacao_id === 4 && e.aprovado === 1 && e.consulta_id === parseInt(new URLSearchParams(this.props.location.search).get('consulta'))})
                if(documentos.length > 0){
                    var cont = 0
                    // Analisado que há documentos a serem analisados, ele passa um por um alterando o seu status no banco de acordo
                    // com a opção escolhida
                    for(var i = 0; i < documentos.length; i++){      
                        // api_epar.get( '/docatualizar/' + new URLSearchParams(this.props.location.search).get('cliente') + '/4/' + documentos[i].path.replace(/\//g, '|') + '/' + new URLSearchParams(this.props.location.search).get('opcao') + '/' + this.state.motivo).then((update) => {
                          api_epar.post( '/docatualizar/' + new URLSearchParams(this.props.location.search).get('cliente') + '/4/' + documentos[i].path.replace(/\//g, '|') + '/' + new URLSearchParams(this.props.location.search).get('opcao'), {motivo: this.state.motivo}).then((update) => {
                            console.log(update.data.data)
                            cont++
                            if(cont === documentos.length){
                                this.setState({mensagem: 'ANÁLISE REALIZADA COM SUCESSO!!'})
                            }
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                }
                else{
                    this.setState({mensagem: 'NÃO EXISTE(M) DOCUMENTO(S) PARA SEREM ANALISADOS'})
                }
            }
            else{
                this.setState({mensagem: 'NÃO EXISTE(M) DOCUMENTO(S)'})
            }
        }).catch((error) => {
            console.log(error)
        })
        }
        else{
          alert('Explique o motivo para reenvio')
          this.setState({alert: true})
        }
      }
    }


  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Container className='d-flex justify-content-center align-items-center w-100' style={{height: '100vh'}}>
            <div className={'p-5 bg-white'} style={{border: '1px solid  #d9d9d9', borderRadius: '6px'}}>
                {this.state.mensagem}
            </div>
        </Container>
        <Modal isOpen={this.state.alert}>
            <ModalHeader>Motivo para reenvio</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <div>
                          <textarea style={{borderRadius: '5px', width: '100%', height: '100px', padding: '2%'}} placeholder='Descreva aqui o motivo pelo qual está soliticitando reenvio' defaultValue={this.state.motivo} onChange={e => {this.setState({motivo: e.target.value})}}/>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className="btn-dark" onClick={this.toggleAlert}>OK</Button>
            </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default TelaAprovacaoTodos;
