/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum';
import { Link } from "react-router-dom";
import api_admin from '../services/apiadmin'
import ReactGA from 'react-ga'

class CredPago extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount(){

      // console.log(process.env.REACT_APP_UA)

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // Verifica se o usuário tem acesso
      axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
        var dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario_test'))
        var dadosUserAtual = retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        })
        dadosUsuario.email = dadosUserAtual[0].email
        localStorage.setItem('dadosUsuario_test', JSON.stringify(dadosUsuario))

        if(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }).length === 0 || retorno.data.moba.filter((i) => {
          return i.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id && i.bloqueado === 0
        }).length > 0){
          this.props.history.push('/admin/logout')
        }
      })
      axios.post(api_admin + '/imobiliaria/imobuser/permis', {id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id}).then((retorno) => {
        if(retorno.data.per.filter((e) => {
            return e.sistema_id === 2 && e.permitido === 0
        }).length > 0 ){
            this.props.history.push('/admin/logout')
        }
      })

      localStorage.removeItem('nomeSolidario_test')
      localStorage.removeItem('rendaSolidario_test')
      localStorage.removeItem('telefoneSolidario_test')
      localStorage.removeItem('dadoSolidario_test')
      localStorage.removeItem('nome_test')
      localStorage.removeItem('renda_test')
      localStorage.removeItem('telefone_test')
      localStorage.removeItem('dado_test')
      localStorage.removeItem('pessoasBusca_test')
      localStorage.removeItem('mask_test')
      localStorage.removeItem('maskSolidario_test')
      localStorage.removeItem('valor_test')
      localStorage.removeItem('cliente_id_test')
      localStorage.removeItem('clienteSolidario_id_test')
      localStorage.removeItem('consulta_id_test')
      localStorage.removeItem('minuto_test')
      localStorage.removeItem('email_test')
      localStorage.removeItem('aluguel_test')
      localStorage.removeItem('condominio_test')
      localStorage.removeItem('imposto_test')
      localStorage.removeItem('outros_test')
      localStorage.removeItem('luz_test')
      localStorage.removeItem('agua_test')
      localStorage.removeItem('gas_test')
      localStorage.removeItem('nomeSolidarioJF_test')
      localStorage.removeItem('rendaSolidarioJF_test')
      localStorage.removeItem('telefoneSolidarioJF_test')
      localStorage.removeItem('dadoSolidarioJF_test')
      localStorage.removeItem('nomeJF_test')
      localStorage.removeItem('rendaJF_test')
      localStorage.removeItem('telefoneJF_test')
      localStorage.removeItem('dadoJF_test')
      localStorage.removeItem('pessoasBuscaJF_test')
      localStorage.removeItem('maskJF_test')
      localStorage.removeItem('maskSolidarioJF_test')
      localStorage.removeItem('valorJF_test')
      localStorage.removeItem('cliente_idJF_test')
      localStorage.removeItem('clienteSolidario_idJF_test')
      localStorage.removeItem('consulta_idJF_test')
      localStorage.removeItem('minutoJF_test')
      localStorage.removeItem('emailJF_test')
      localStorage.removeItem('aluguelJF_test')
      localStorage.removeItem('condominioJF_test')
      localStorage.removeItem('impostoJF_test')
      localStorage.removeItem('outrosJF_test')
      localStorage.removeItem('luzJF_test')
      localStorage.removeItem('aguaJF_test')
      localStorage.removeItem('gasJF_test')
      localStorage.removeItem('activeTabPrincipalJF_test')
      localStorage.removeItem('activeTabPrincipal_test')
      localStorage.removeItem('activeTab_test')
      localStorage.removeItem('activeTabJF_test')
      localStorage.removeItem('activeTab_test')
      localStorage.removeItem('emailEnviarCartaJF_test')
      localStorage.removeItem('emailEnviarCarta_test')
      localStorage.removeItem('finalidadeLocacao')
      localStorage.removeItem('codigo_cartaJF_test')
      localStorage.removeItem('codigo_carta_test')
      localStorage.removeItem('qualFinalidadeLocacaoJF_test')
      localStorage.removeItem('qualFinalidadeLocacao_test')
    }

    comercial = () => {
        localStorage.setItem('finalidadeLocacao', 'Comercial')
    }

    residencial = () => {
        localStorage.setItem('finalidadeLocacao', 'Residencial')
    }


  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header style={{height: '10vh'}} />
        {/* Irá selecionar qual tipo de consulta irá realizar */}
        <Container className={"mt--4 d-flex justify-content-center align-items-center"} fluid style={{minHeight: '65vh'}}>
            <div className="text-center">
              <div className='float-left mt-8 p-5'>
                <img src={require('../assets/img/residencial.svg')} style={{width: '300px'}}/>
                <div className={"mt-3 ml--4 p-2"} style={{fontSize: '1em', fontWeight: 'bold'}}>
                  Locação de imóveis residenciais.
                </div>
                <div className={"mt-4 ml--2 text-center"} style={{width: '40vh'}}>
                  <Button onClick={this.residencial} className={'w-50' + FuncoesComum.solida()} style={{color: 'white'}} to='/admin/pessoafisica' tag={Link}>Pessoa Física</Button>
                  </div>
                <div className={"mt-3 text-center"} style={{width: '40vh', marginLeft: '-2.5%'}}>
                  <Button onClick={this.residencial} className={'w-50' + FuncoesComum.solida()} style={{color: 'white'}} to='/admin/pessoajuridica' tag={Link}>Pessoa Jurídica</Button>
                </div>
              </div>
              <div className='float-left m-5 p-5 d-none d-xl-block ocultarDivSeparador' style={{fontSize: '1.2em', fontWeight: 'bold'}}>
                <hr style={{border: 'none', borderLeft: '1px solid', height: '22vh', width: '1px'}} />
                OU
                <hr style={{border: 'none', borderLeft: '1px solid', height: '22vh', width: '1px'}} />
              </div>
              <div className='float-left ml--3 mt-3 p-5'>
                <img src={require('../assets/img/comercial.svg')} style={{width: '370px', height: '280px'}}/>
                <div className={"mt-3 ml--4 p-2"} style={{fontSize: '1em', fontWeight: 'bold'}}>
                  Locação para imóveis comerciais.
                </div>
                <div className={"mt-4 ml-4 text-center"} style={{width: '40vh'}}>
                  <Button onClick={this.comercial} className={'w-50' + FuncoesComum.solida()} style={{color: 'white'}} to='/admin/pessoafisica' tag={Link}>Pessoa Física</Button>
                </div>
                <div className={"mt-3 text-center"} style={{width: '40vh', marginLeft: '6.5%' }}>
                  <Button onClick={this.comercial} className={'w-50' + FuncoesComum.solida()} style={{color: 'white'}} to='/admin/pessoajuridica' tag={Link}>Pessoa Jurídica</Button>
                </div>
              </div>
            </div>
        </Container>
      </>
    );
  }
}

export default CredPago;
