/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Nav, 
    NavItem, 
    NavLink,
    TabPane,
    TabContent,
    Label,
    FormGroup
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum';
import InputMask from 'react-input-mask'
import $ from 'jquery'
import DataTable from 'datatables.net'
import "assets/scss/relatorios.scss";
import {Link} from 'react-router-dom'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from "react-ga";

class ListarSinistroContrato extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           sinistros: []
        }
    }

   

    componentDidMount(){

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // Verifica se o usuário tem acesso
      axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
        console.log(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }))

        if(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }).length === 0){
          this.props.history.push('/admin/logout')
        }
      })

      // Busca as consultas realizadas pelo o usuário logado
      api_epar.get( '/sinistrocontrato/' + this.props.match.params.id).then((sinistros) => {
        console.log(sinistros.data.data)
        var carregar = sinistros.data.data
        for(var i = 0; i < sinistros.data.data.length; i++){
            api_epar.get( '/arquivossinistrosinistro/' + sinistros.data.data[i].id).then((arquivos) => {
                if(arquivos.data.data.length > 0){
                    var index = carregar.map((c) => {
                        return c.id
                    }).indexOf(arquivos.data.data[0].sinistro_id)
                    
                    if(index !== -1){
                        carregar[index].arquivos = arquivos.data.data 
                    }
                }

                this.setState({sinistros: carregar})
            })
            // this.setState({sinistros: carregar})
        }
      }).catch((error) => {
          console.log(error)
      })
    }

    carregarSinistros = (record, index) => {
        return(
            <div className="card" style={{margin: '10px', background: '#f2f2f2'}} key={index} onClick={() => {
              if(document.getElementById("collapseOne" + record.id).style.display === 'none'){
                document.getElementById("collapseOne" + record.id).style.display = 'block'
              }
              else{
                document.getElementById("collapseOne" + record.id).style.display = 'none'
              }
            }}>
                <div className="p-3" id="headingOne" style={{cursor: 'pointer', color: 'black'}}>
                  {record.id}. {record.descricao ? record.descricao : 'Sem descrição'}
                </div>
                <div id={"collapseOne" + record.id} style={{display: 'none'}} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                        {record.arquivos ? 
                            record.arquivos.map(this.arquivosSinistros) : 
                            <div className='row'>
                                <div className='col-12' style={{fontSize: '0.9em', color: 'gray'}}>
                                    Não há arquivos referente a esse sinistro
                                </div>
                            </div>
                        }
                    </div>
                </div>        
            </div>
        )
    }

    arquivosSinistros = (arq, i) => {
        // console.log(i)
        return (
          <Row>
            <Col md={12} key={i}>
                <Button className='btn-danger m-2' onClick={() => {window.open(api_request + '/' + arq.path)}}><i className='fas fa-eye' /><label className='ml-1'>{arq.descricao ? arq.descricao : 'Sem descrição'}</label></Button>
            </Col>
          </Row>  
        )
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {
    return (
      <>
        <Header style={{height: '10vh'}} />

        <Container className={"mt-5 d-flex justify-content-center"} fluid style={{width: '80%', minHeight: '65vh', flexDirection: 'column'}}>
            {this.state.sinistros.map(this.carregarSinistros)}
            {this.state.sinistros.length === 0 && <p className='justify-content-center p-4' style={{background: 'white', border: '1px solid black'}}>Não há sinistros referente esse contrato</p>}
        </Container>
      </>
    );
  }
}

export default ListarSinistroContrato;
