/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    Input,
    Table,
    Container,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button
} from "reactstrap";

import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'

import Header from "components/Headers/Header.jsx";
import "assets/scss/tarifacao.scss";
import FuncoesComum from '../funcoes/FuncoesComum'


class Tarifacao extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            tarifacoes: [],
            cpf_cnpj: '',
            cliente: '',
            locador: '',
            descricao: '',
            valor: 0,
            tipo: '',
            inicio: moment().subtract(10, 'days').format('YYYY-MM-DD'),
            fim: moment().format('YYYY-MM-DD')
        }
        if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
            props.history.push('/admin/finalidadelocacao')
        }

        this.cont = 0
        this.atual = ''
    }

    componentDidMount(){
        api_epar.get( '/tarifacao/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((tarifacoes) => {
            console.log(tarifacoes.data.data)
            this.setState({tarifacoes: tarifacoes.data.data})
        }).catch((error) => {
            console.log(error)
        })
    }

    changeCpfCnpj = (evento) => {
      this.setState({ cpf_cnpj: evento.target.value})
    }
    
    changeCliente = (evento) => {
      this.setState({ cliente: evento.target.value})
    }

    changeLocador = (evento) => {
      this.setState({ locador: evento.target.value})
    }
    
    changeDescricao = (evento) => {
      this.setState({ descricao: evento.target.value})
    }

    changeValor = (evento) => {
      this.setState({ valor: evento.target.value})
    }
    
    changeTipo = (evento) => {
      this.setState({ tipo: evento.target.value})
    }

    changeInicio = (evento) => {
        this.setState({ inicio: evento.target.value})
    }

    changeFim = (evento) => {
        this.setState({ fim: evento.target.value} )
    }

    filtrar = () => {
        api_epar.post( '/tarifacao/filtro/',{
          cpf_cnpj: this.state.cpf_cnpj,
          cliente: this.state.cliente,
          locador: this.state.locador,
          descricao: this.state.descricao,
          inicio: moment(this.state.inicio).format('YYYY-MM-DD'),
          fim: moment(this.state.fim).add(1, 'days').format('YYYY-MM-DD'),
          imobiliaria: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id
        }).then((tarifacoes) => {
            console.log(tarifacoes.data.data)
            this.setState({tarifacoes: tarifacoes.data.data})
        }).catch((error) => {
            console.log(error)
        })
    }

    rowsTarifacoes = (record) => {
        var color = null
        if(record.consult_id % 2 === 1){
            color = 'white'
        }

        if(this.atual === ''){
            this.cont += 1
            this.atual = record.consult_id

            var id = 'row' + this.cont
            var target = '.row' + this.cont

            return(
                <tr onClick={this.classAtualizar} className='clickable' data-toggle='collapse' id={id} data-target={target} key={record.id} data-value={record.id} style={{backgroundColor: color}}>
                  <th scope="row">
                    {record.cpf_cnpj}
                  </th>
                  {/* <td>
                    {record.nome}
                  </td> */}
                  <td>
                    {record.tipo_locador}
                  </td>
                  <td>
                    {record.descricao}
                  </td>
                  <td>
                    {record.valor}
                  </td>
                  <td>
                    {record.tipo}
                  </td>
                  <td>
                    {new Date(record.data).toLocaleString('pt-BR')}
                  </td>
                  <td>
                    <i className="ni ni-fat-add" />
                  </td>
                </tr>
            )
            
        }
        else if(this.atual !== record.consult_id){
            this.cont += 1
            this.atual = record.consult_id

            var id = 'row' + this.cont
            var target = '.row' + this.cont

            return(
                <tr onClick={this.classAtualizar} className='clickable' data-toggle='collapse' id={id} data-target={target} key={record.id} data-value={record.id} style={{backgroundColor: color}}>
                  <th scope="row">
                    {record.cpf_cnpj}
                  </th>
                  {/* <td>
                    {record.nome}
                  </td> */}
                  <td>
                    {record.tipo_locador}
                  </td>
                  <td>
                    {record.descricao}
                  </td>
                  <td>
                    {record.valor}
                  </td>
                  <td>
                    {record.tipo}
                  </td>
                  <td>
                    {new Date(record.data).toLocaleString('pt-BR')}
                  </td>
                  <td>
                    <i className="ni ni-fat-add" />
                  </td>
                </tr>
            )

        }
        else{
            var collapse = 'collapse row' + this.cont

            return(
                <tr className={collapse} key={record.id} data-value={record.id} style={{backgroundColor: color}}>
                  <th scope="row">
                    {record.cpf_cnpj}
                  </th>
                  {/* <td>
                    {record.nome}
                  </td> */}
                  <td>
                    {record.tipo_locador}
                  </td>
                  <td>
                    {record.descricao}
                  </td>
                  <td>
                    {record.valor}
                  </td>
                  <td>
                    {record.tipo}
                  </td>
                  <td>
                    {new Date(record.data).toLocaleString('pt-BR')}
                  </td>
                  <td>
                  </td>
                </tr>
            )
        }
    }

    classAtualizar = (evento) => {
        console.log(evento.currentTarget.id)
        if(evento.currentTarget.className === 'clickable'){
            document.getElementById(evento.currentTarget.id).className = 'clickable collapsed'
    
            for(var i = 0; i < document.getElementsByClassName('collapse ' + evento.currentTarget.id).length; i++){
                document.getElementsByClassName('collapse ' + evento.currentTarget.id)[i].className = 'collapse in ' + evento.currentTarget.id
            }
        }
        else{
            document.getElementById(evento.currentTarget.id).className = 'clickable'

            var cont = document.getElementsByClassName('collapse in ' + evento.currentTarget.id).length

            for(var i = 0; i < cont; i++){
                document.getElementsByClassName('collapse in ' + evento.currentTarget.id)[0].className = 'collapse ' + evento.currentTarget.id
            }
        }
        
    }

    // detalhar = (evento) => {
    //   //console.log(evento.currentTarget.dataset.value)
    //   this.props.history.push('/admin/detalhe/' + evento.currentTarget.dataset.value)
    // }

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        {/* <Container className={"mt--8 pb-8" + FuncoesComum.layout()} fluid style={{minHeight: '65vh'}}> */}
        <Container className={"mt-8 pb-8"} fluid style={{minHeight: '65vh'}}>
            <Row>
                <Col md={2} className='p-2'>
                  <Input style={{color: 'black'}} placeholder="CPF / CNPJ" type="text" value={this.state.cpf_cnpj} onChange={this.changeCpfCnpj} />
                </Col>
                {/* <Col md={4} className='p-2'>
                  <Input style={{color: 'black'}} placeholder="Cliente" type="text" value={this.state.cliente} onChange={this.changeCliente} />
                </Col> */}
                <Col md={2} className='p-2'>
                  <Input style={{color: 'black'}} type="select" value={this.state.locador} onChange={this.changeLocador} >
                    <option value=''>Selecione o tipo de locador</option>
                    <option value='Principal'>Principal</option>
                    <option value='Solidário'>Solidário</option>
                  </Input>
                </Col>
                <Col md={3} className='p-2'>
                  <Input style={{color: 'black'}} type="select" value={this.state.descricao} onChange={this.changeDescricao} >
                    <option value=''>Selecione a descrição</option>
                    <option value='Procob - Dados Financeiros'>Procob - Dados Financeiros</option>
                    <option value='Procob - Dados Cadastrais'>Procob - Dados Cadastrais</option>
                  </Input>
                </Col>
            
                <Col md={2} className='p-2'>
                    <Input style={{color: 'black'}} type='date' value={this.state.inicio} onChange={this.changeInicio}></Input>
                </Col>
                <Col md={2} className='p-2'>
                    <Input style={{color: 'black'}} type='date' value={this.state.fim} onChange={this.changeFim}></Input>
                </Col>
                <Col md={1} className='p-2'>
                    <Button className='colorButtonSecundario float-right' onClick={this.filtrar}>Filtrar</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <div className="col">
                <Card>
                    <CardHeader className="bg-transparent border-0">
                    <h3 className="mb-0" style={{color: 'black'}}>Tarifações</h3>
                    </CardHeader>
                    <Table
                    className="align-items-center table-light"
                    responsive
                    >
                    <thead className="thead-light">
                        <tr>
                        <th scope="col">CPF / CNPJ</th>
                        {/* <th scope="col">Cliente</th> */}
                        <th scope="col">Locador</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Data</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tarifacoes.map(this.rowsTarifacoes)}
                        {/* <tr>
                        <th scope="row">
                            <Media className="align-items-center">
                            <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                alt="..."
                                src={require("assets/img/theme/bootstrap.jpg")}
                                />
                            </a>
                            <Media>
                                <span className="mb-0 text-sm">
                                Argon Design System
                                </span>
                            </Media>
                            </Media>
                        </th>
                        <td>$2,500 USD</td>
                        <td>
                            <Badge color="" className="badge-dot mr-4">
                            <i className="bg-warning" />
                            pending
                            </Badge>
                        </td>
                        <td>
                            <div className="avatar-group">
                            <a
                                className="avatar avatar-sm"
                                href="#pablo"
                                id="tooltip731399078"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                alt="..."
                                className="rounded-circle"
                                src={require("assets/img/theme/team-1-800x800.jpg")}
                                />
                            </a>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip731399078"
                            >
                                Ryan Tompson
                            </UncontrolledTooltip>
                            <a
                                className="avatar avatar-sm"
                                href="#pablo"
                                id="tooltip491083084"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                alt="..."
                                className="rounded-circle"
                                src={require("assets/img/theme/team-2-800x800.jpg")}
                                />
                            </a>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip491083084"
                            >
                                Romina Hadid
                            </UncontrolledTooltip>
                            <a
                                className="avatar avatar-sm"
                                href="#pablo"
                                id="tooltip528540780"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                alt="..."
                                className="rounded-circle"
                                src={require("assets/img/theme/team-3-800x800.jpg")}
                                />
                            </a>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip528540780"
                            >
                                Alexander Smith
                            </UncontrolledTooltip>
                            <a
                                className="avatar avatar-sm"
                                href="#pablo"
                                id="tooltip237898869"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                alt="..."
                                className="rounded-circle"
                                src={require("assets/img/theme/team-4-800x800.jpg")}
                                />
                            </a>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip237898869"
                            >
                                Jessica Doe
                            </UncontrolledTooltip>
                            </div>
                        </td>
                        </tr> */}
                    </tbody>
                    </Table>
                </Card>
                </div>
            </Row>
        </Container>
      </>
    );
  }
}

export default Tarifacao;
