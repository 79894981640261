import React from "react";
import Chart from "chart.js";
import {
    Container,
    Button,
    Label,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import FuncoesComum from '../funcoes/FuncoesComum';
import "assets/scss/relatorios.scss";
import {Link} from 'react-router-dom'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from "react-ga";

class ListarContratos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           contratos: [],
           tableLimit: 10,
           tableindex: 0,
           fnumber: '',
           fcpf: '',
           fnome: '',
           finicio: '',
           ffim: '',
           fcarta: '',
           fendImov: '',
           fvalor: '',
        }
    }

    componentDidMount(){

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // Verifica se o usuário tem acesso
      axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
        console.log(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }))

        if(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }).length === 0){
          this.props.history.push('/admin/logout')
        }
      })

      // Busca as consultas realizadas pelo o usuário logado
      api_epar.get( '/dadoscontratoimobiliaria/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((contratos) => {
          this.setState({contratos: contratos.data.data})
          console.log(contratos.data.data)
          // $(document).ready(() => {
          //   $('#dt_table').DataTable({
          //     order: [[0, 'desc']]
          //   })
          // })
      }).catch((error) => {
          console.log(error)
      })
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    
    let wow = () => {
      console.log(this.state);
    };

    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  filterContratos = (e) => {
    return e.filter(y => {
      return (y.locatario_cnpj || y.locatario_cpf || '').toString().includes(this.state.fcpf) && 
      (y.locatario_nome || y.locatario_razaoSocial || '').toString().includes(this.state.fnome.toLowerCase()) && 
      (y.imovelEnd + ', ' + y.imovelNum).toString().toLowerCase().includes(this.state.fendImov.toLowerCase()) &&
      (y.codigo || "").toString().toLowerCase().includes(this.state.fcarta.toLowerCase()) &&
      FuncoesComum.formatarMoeda(parseFloat(y.valorAluguel)).toString().toLowerCase().replace(/\./g, '').includes(this.state.fvalor.toLowerCase().replace(/\./g, '')) &&
      (y.data_inicio && new Date(y.data_inicio).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).toString().includes(this.state.finicio) || '')  &&
      (y.data_inicio && new Date(y.data_fim).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).toString().includes(this.state.ffim)  || '') 
    })
  }

  // Irá exibir cada contrato da imobiliária
  rowsContratos = (record) => {
    return(
      <tr key={record.id} >
        <th>
          {record.numero_contrato}
        </th>
        <th>
          {record.locatario_tipo && record.locatario_tipo === 'PF' ? record.locatario_cpf : record.locatario_cnpj}
        </th>
        <th>
          {record.locatario_tipo && record.locatario_tipo === 'PF' ? record.locatario_nome : record.locatario_razaoSocial}
        </th>
        <th>
          {record.codigo}
        </th>
        <td>
          {record.data_inicio && new Date(record.data_inicio).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}
          {!record.data_inicio && 'Não informado'}
        </td>
        <td>
          {record.data_fim && new Date(record.data_fim).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}
          {!record.data_fim && 'Não informado'}
        </td>
        <td>
          {record.imovelEnd && record.imovelNum && record.imovelEnd + ', ' + record.imovelNum}
          {!record.data_fim && 'Não informado'}
        </td>
        <td>
          {FuncoesComum.formatarMoeda(parseFloat(record.valorAluguel))}
        </td>
        <td>
          {
            record.inativo == 1 ? (
              <span style={{color: 'red'}}>Contrato cancelado</span>
            ) : (
              <Label style={{border: '1px solid #d9d9d9', borderRadius: '5px', color: 'black', backgroundColor: 'white'}} className='float-right p-1' to={'/admin/formcontrato/' + record.id} tag={Link}>Ver resultado</Label>
            )
          }
        </td>
        {
          record.consulta_id ? (
            <td colSpan='2'>
              ---
            </td>
          ) : (
            <td class="d-flex">
              <Label alt="Sinistro" title="Sinistro" style={{border: '1px solid #d9d9d9', borderRadius: '5px', color: 'white', backgroundColor: '#3b1e3e'}} className='float-right p-1' to={'/admin/listarsinistros/' + record.id} tag={Link}>
                <i className="fa fa-list-ol"></i>

              </Label>
              <Label alt="Adicionar sinistro" title="Adicionar sinistro" style={{border: '1px solid #d9d9d9', borderRadius: '5px', color: 'white', backgroundColor: '#ee585a'}} className='float-right p-1' to={'/admin/addsinistro/' + record.id} tag={Link}>
                <i className="fa fa-plus"></i>
              </Label>
            </td>
          )
        }
      </tr>
    )
  }

  render() {
    return (
      <>
        <link rel="stylesheet" href="https://cdn.datatables.net/1.10.20/css/dataTables.bootstrap4.min.css"></link>
        <Header style={{height: '10vh'}} />

        <Button className='btn-success float-left m-3 mr-5' to={'/admin/formcontrato/'} tag={Link}>Gerar contrato</Button>
        <Container className={"mt-5 d-flex justify-content-center flex-wrap"} fluid style={{'overflow': 'hidden'}}>
          {/* <div className="w-100"> */}
          {/* </div> */}
          <div id="dt_table_wrapper" class="dataTables_wrapper no-footer" style={{'overflow': 'auto', width: "100%"}}>
            <div class="dataTables_length" id="dt_table_length">
              <label>
                Mostrar 
                <select value={this.state.tableLimit} className="ml-1 mr-1" onChange={e => this.setState({tableLimit: Number(e.target.value)})}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                
                Registros
              </label>
            </div>
            <table id="dt_table" className="table dataTable table-striped" style={{width: "100%", 'overflowY': 'hidden'}}>
              <thead >
                <tr >
                    <th >N°</th>
                    <th >CPF</th>
                    <th >Nome</th>
                    <th >Carta</th>
                    <th >Início</th>
                    <th >Fim</th>
                    <th >Endereço imóvel</th>
                    <th >Valor de aluguel</th>
                    <th >Resultado</th>
                    <th >Sinistros</th>
                </tr>
                <tr>
                  <th><input placeholder="Nome" value={this.state.fnumber} onChange={e => this.setState({fnumber: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                  <th><input placeholder="CPF/CNPJ" value={this.state.fcpf} onChange={e => this.setState({fcpf: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                  <th><input placeholder="Nome/razão" value={this.state.fnome} onChange={e => this.setState({fnome: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                  <th><input placeholder="Carta" value={this.state.fcarta} onChange={e => this.setState({fcarta: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                  <th><input placeholder="Inicio" value={this.state.finicio} onChange={e => this.setState({finicio: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                  <th><input placeholder="Fim" value={this.state.ffim} onChange={e => this.setState({ffim: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                  <th><input placeholder="Endereço" value={this.state.fendImov} onChange={e => this.setState({fendImov: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                  <th><input placeholder="Valor" value={this.state.fvalor} onChange={e => this.setState({fvalor: e.target.value})} style={{'width': '100%'}} className="headTableCamp"/></th>
                </tr>
              </thead>

              <tbody>
                {this.filterContratos(this.state.contratos).slice(this.state.tableindex * this.state.tableLimit, this.state.tableindex * this.state.tableLimit + this.state.tableLimit).map(this.rowsContratos)}
              </tbody>

            </table>

            <div class="dataTables_info" id="dt_table_info" role="status" aria-live="polite">
              Mostrando {this.state.tableindex * this.state.tableLimit + 1} até {this.state.tableindex * this.state.tableLimit + this.state.tableLimit + 1} de {this.state.contratos.length} registros
            </div>

            <div class="dataTables_paginate paging_simple_numbers" id="dt_table_paginate">
              {
                this.state.tableindex > 0 ? (
                  <span class="paginate_button previous disabled" id="dt_table_previous" onClick={() => this.setState({tableindex: (this.state.tableindex - 1)})}>
                    {'<<'}
                  </span>
                ) : (null)
              }
              <span>
                {Array.apply(null, Array(Math.ceil(this.state.contratos.length / this.state.tableLimit))).map((e, index) => index).map((e) => (
                    Number(e) >= this.state.tableindex -1 && Number(e) <= this.state.tableindex + 3 ? (
                      <span class="paginate_button current" onClick={a => this.setState({tableindex: e})}>{e + 1}</span>
                    ) : (null)
                ))}
              </span>
              {
                this.state.tableindex + 1 < Math.ceil(this.state.contratos.length / this.state.tableLimit) ? (
                  <span class="paginate_button next disabled" aria-controls="dt_table" onClick={e => this.setState({tableindex: (this.state.tableindex + 1)})}>
                    {'>>'}
                  </span>
                ) : (null)
              }
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default ListarContratos;
