/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import axios from "axios"
import sha1 from "sha1"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody, 
  ModalFooter
} from "reactstrap";

import jwtDecode from 'jwt-decode'

import api_request from '../../services/apis'
import api_admin from '../../services/apiadmin'
import ReactGA from 'react-ga'

class LoginAlugMais extends React.Component {

  constructor(props){
    super(props)
    this.state ={
      usuario: '',
      senha: '',
      mensagemModal: '',
      alert: false
    }

    //Caso já esteja conectado
    if(localStorage.getItem('autenticado_test')){
      props.history.push('/admin/finalidadelocacao')
    }
  }

  componentDidMount(){
     // Analytics
     ReactGA.initialize(process.env.REACT_APP_UA)
     ReactGA.pageview(window.location.pathname + window.location.search)
  }

  toggleAlert = () => {
    this.setState({alert: !this.state.alert})
  }

  changeUsuario = (evento) => {
    this.setState({usuario: evento.target.value})
  }

  changeSenha = (evento) => {
    this.setState({senha: evento.target.value})
  }

  //Realiza o LOGIN
  conectar = () => {
    // Envia o usuário, senha e o sistema para se conectar ao sistema
    axios.post(api_admin + '/imobon/autenticar', {usuario: this.state.usuario, senha: sha1(this.state.senha), sistema: 2}).then((response) => {
        if(response.data.token.indexOf('Dados inválidos') === -1){
            localStorage.setItem('autenticado_test', response.data.token)
            localStorage.setItem('uuid_test', response.data.uuid)
            localStorage.setItem('dadosUsuario_test', JSON.stringify(jwtDecode(response.data.token)))
            
            setTimeout(() => {
              window.location.href = '/admin/finalidadelocacao'
              
            }, 120);
        }
        //Caso os dados são inválidos ou não tem no permissão de acesso
        else{
            console.log(response.data.token)
            // alert(response.data.data)
            this.setState({mensagemModal: <div>
                                            {response.data.token}
                                          </div>})
            this.toggleAlert()
        }
    }).catch((error) => {
      console.log('Error: ' + error)
    })
  }

  // conectar = () => {
  //   api_epar.post( '/autenticar', {usuario: this.state.usuario, senha: sha1(this.state.senha)}).then((response) => {
  //       if(response.data.data !== 'Dados inválidos'){
  //           localStorage.setItem('autenticado_test', response.data.token)
  //           localStorage.setItem('dadosUsuario_test', JSON.stringify(response.data.data[0]))
  //           this.props.history.push('/admin/realizarconsulta')
  //       }
  //       else{
  //           console.log(response.data.data)
  //           // alert(response.data.data)
  //           this.setState({mensagemModal: <div>
  //                                           {response.data.data}
  //                                         </div>})
  //           this.toggleAlert()
  //       }
  //   }).catch((error) => {
  //     console.log('Error: ' + error)
  //   })
  // }

  enter = (evento) => {
    if(evento.key === 'Enter'){
      this.conectar()
    }
  }

  render() {

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0 mb-8 mt-5">
            <CardHeader className="pb-5">
              {/* <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div> */}
              <div className='text-center mb--4 mt-3'>
                <img  src={require("assets/img/logo-alugmais.png")} alt='Logo' className='img-fluid'></img>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Seja bem-vindo, conecte-se ao nosso sistema</small>
              </div>
              {/* <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div> */}
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{color: 'black'}} placeholder="Usuário" type="text" value={this.state.usuario} onChange={this.changeUsuario}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{color: 'black'}} placeholder="Password" type="password" value={this.state.senha} onChange={this.changeSenha} onKeyPress={this.enter}/>
                  </InputGroup>
                </FormGroup>
                {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div> */}
                <div className="text-center">
                  <Button className="my-4 float-right colorButtonSecundario" style={{color: 'white'}} type="button" onClick={this.conectar}>
                    Conectar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          {/* <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
          <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
              <ModalHeader toggle={this.toggleAlert}>Erro no login</ModalHeader>
              <ModalBody>
                  <Row>
                      <Col md={12}>
                          {this.state.mensagemModal}
                      </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                  <Button className="btn-dark" onClick={this.toggleAlert}>Cancelar</Button>
              </ModalFooter>
          </Modal>
        </Col>
      </>
    );
  }
}

export default LoginAlugMais;
