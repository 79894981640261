'use script'

const FuncoesComum = () => {}

FuncoesComum.formatarMoeda = (valor) => {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

//Formata o retorno da API para ser utilizado nos relatórios
FuncoesComum.unirDadosConsulta = (consultas) => {
    console.log(consultas)
    var dictCompleto = []
    var indexSolidario = 0
    var valor
    var principal
    var cpf_cnpj
    var telefone
    var renda_informada
    var status
    var motivo
    var cliente_id
    var solidario = []
    var etapa
    var idConsulta
    var dataConsulta
    var statusGeral = ''
    var nomeLocatario = {}
    var motivoGeral = ''
    var countDoc
    var maxDoc
    var minDoc
    var idwall 
    var finalidadeLocacao
    var qual_finalidadeLocacao
    var score_status 
    var pendenciafinanceira_status
    var processo_status 
    var antecedente_status
    var status_final 
    for(var i = 0; i < consultas.data.data.length; i++){
        if(consultas.data.data[i].tipo_consulta_id == 2){
            nomeLocatario[consultas.data.data[i].consulta_id] = JSON.parse(consultas.data.data[i].retorno_json)['content'].nome.conteudo.nome
        }
        // if(consultas.data.data[i].status !== null){
            if(idConsulta === undefined){
                idConsulta = consultas.data.data[i].consulta_id
                valor = consultas.data.data[i].valor
                etapa = consultas.data.data[i].etapa
                dataConsulta = consultas.data.data[i].data_consulta
                finalidadeLocacao = consultas.data.data[i].finalidadeLocacao
                qual_finalidadeLocacao = consultas.data.data[i].qual_finalidadeLocacao
                if(consultas.data.data[i].tipo_locatario === 'Principal'){
                    principal = consultas.data.data[i].nome
                    cpf_cnpj = consultas.data.data[i].cpf_cnpj
                    telefone = consultas.data.data[i].telefone
                    renda_informada = consultas.data.data[i].renda_informada
                    status = consultas.data.data[i].status
                    motivo = consultas.data.data[i].motivo
                    cliente_id = consultas.data.data[i].clientes_id
                    console.log(consultas.data.data[i])
                    countDoc = consultas.data.data[i].countDoc
                    maxDoc = consultas.data.data[i].maxDoc
                    minDoc = consultas.data.data[i].minDoc
                    statusGeral += consultas.data.data[i].status + ', '
                    motivoGeral += consultas.data.data[i].motivo + ', '
                    score_status = consultas.data.data[i].score_status
                    pendenciafinanceira_status = consultas.data.data[i].pendenciafinanceira_status
                    processo_status = consultas.data.data[i].processo_status
                    antecedente_status = consultas.data.data[i].antecedente_status
                    status_final = consultas.data.data[i].status_final
                    if(consultas.data.data[i].tipo_consulta_id === 3){
                        idwall = consultas.data.data[i].retorno_json
                    }
                }
                else{
                    if(solidario.map((e) => {
                        return e.cliente_id
                    }).indexOf(consultas.data.data[i].clientes_id) === -1){
                    solidario[indexSolidario] = {
                        index: indexSolidario, 
                        nome: consultas.data.data[i].nome, 
                        cliente_id: consultas.data.data[i].clientes_id,
                        cpf_cnpj: consultas.data.data[i].cpf_cnpj,
                        telefone: consultas.data.data[i].telefone,
                        renda_informada: consultas.data.data[i].renda_informada,
                        status: consultas.data.data[i].status,
                        motivo: consultas.data.data[i].motivo,
                        countDoc: consultas.data.data[i].countDoc,
                        maxDoc: consultas.data.data[i].maxDoc,
                        minDoc: consultas.data.data[i].minDoc
                    }
                    statusGeral += consultas.data.data[i].status + ', '
                    motivoGeral += consultas.data.data[i].motivo + ', '
                    indexSolidario++
                    }
                    else{
                        var id = solidario.map((e) => {
                            return e.cliente_id
                        }).indexOf(consultas.data.data[i].clientes_id)
                        if(consultas.data.data[i].tipo_consulta_id === 3){
                            solidario[id].idwall = consultas.data.data[i].retorno_json
                        }
                        statusGeral += consultas.data.data[i].status + ', '
                        motivoGeral += consultas.data.data[i].motivo + ', '
                    }
                }
            }//
            else if(idConsulta !== consultas.data.data[i].consulta_id){
                dictCompleto.push({
                    consulta_id: idConsulta,
                    valor: valor,
                    nomeLocatario: nomeLocatario[idConsulta],
                    principal: principal,
                    cliente_id: cliente_id,
                    cpf_cnpj: cpf_cnpj,
                    telefone: telefone,
                    renda_informada: renda_informada,
                    status: status,
                    motivo: motivo,
                    solidario: solidario,
                    etapa: etapa,
                    countDoc: countDoc,
                    maxDoc: maxDoc,
                    minDoc: minDoc,
                    data_consulta: dataConsulta,
                    statusGeral: statusGeral,
                    motivoGeral: motivoGeral,
                    idwall: idwall,
                    finalidadeLocacao: finalidadeLocacao,
                    qual_finalidadeLocacao: qual_finalidadeLocacao,
                    score_status: score_status,
                    pendenciafinanceira_status: pendenciafinanceira_status,
                    processo_status: processo_status,
                    antecedente_status: antecedente_status,
                    status_final: status_final
                })
                indexSolidario = 0
                solidario = []
                idConsulta = consultas.data.data[i].consulta_id
                valor = consultas.data.data[i].valor
                etapa = consultas.data.data[i].etapa
                dataConsulta = consultas.data.data[i].data_consulta
                finalidadeLocacao = consultas.data.data[i].finalidadeLocacao
                qual_finalidadeLocacao = consultas.data.data[i].qual_finalidadeLocacao
                statusGeral = ''
                motivoGeral = ''
                idwall = undefined
                countDoc = undefined
                maxDoc = undefined
                minDoc = undefined
                if(consultas.data.data[i].tipo_locatario === 'Principal'){
                    principal = consultas.data.data[i].nome
                    cpf_cnpj = consultas.data.data[i].cpf_cnpj
                    telefone = consultas.data.data[i].telefone
                    renda_informada = consultas.data.data[i].renda_informada
                    status = consultas.data.data[i].status
                    motivo = consultas.data.data[i].motivo
                    cliente_id = consultas.data.data[i].clientes_id
                    countDoc = consultas.data.data[i].countDoc
                    maxDoc = consultas.data.data[i].maxDoc
                    minDoc = consultas.data.data[i].minDoc
                    statusGeral += consultas.data.data[i].status + ', '
                    motivoGeral += consultas.data.data[i].motivo + ', '
                    score_status = consultas.data.data[i].score_status
                    pendenciafinanceira_status = consultas.data.data[i].pendenciafinanceira_status
                    processo_status = consultas.data.data[i].processo_status
                    antecedente_status = consultas.data.data[i].antecedente_status
                    status_final = consultas.data.data[i].status_final
                    if(consultas.data.data[i].tipo_consulta_id === 3){
                        idwall = consultas.data.data[i].retorno_json
                    }
                }
                else{
                    if(solidario.map((e) => {
                        return e.cliente_id
                    }).indexOf(consultas.data.data[i].clientes_id) === -1){
                    solidario[indexSolidario] = solidario[indexSolidario] = {
                        index: indexSolidario, 
                        nome: consultas.data.data[i].nome, 
                        cliente_id: consultas.data.data[i].clientes_id,
                        cpf_cnpj: consultas.data.data[i].cpf_cnpj,
                        telefone: consultas.data.data[i].telefone,
                        renda_informada: consultas.data.data[i].renda_informada,
                        status: consultas.data.data[i].status,
                        motivo: consultas.data.data[i].motivo,
                        countDoc: consultas.data.data[i].countDoc,
                        maxDoc: consultas.data.data[i].maxDoc,
                        minDoc: consultas.data.data[i].minDoc
                    }
                    statusGeral += consultas.data.data[i].status + ', '
                    motivoGeral += consultas.data.data[i].motivo + ', '
                    indexSolidario++
                    }
                    else{
                        var id = solidario.map((e) => {
                            return e.cliente_id
                        }).indexOf(consultas.data.data[i].clientes_id)
                        if(consultas.data.data[i].tipo_consulta_id === 3){
                            solidario[id].idwall = consultas.data.data[i].retorno_json
                        }
                        statusGeral += consultas.data.data[i].status + ', '
                        motivoGeral += consultas.data.data[i].motivo + ', '
                    }
                }
            }
            else if(idConsulta === consultas.data.data[i].consulta_id){
                if(consultas.data.data[i].tipo_locatario === 'Principal'){
                    principal = consultas.data.data[i].nome
                    cpf_cnpj = consultas.data.data[i].cpf_cnpj
                    telefone = consultas.data.data[i].telefone
                    renda_informada = consultas.data.data[i].renda_informada
                    status = consultas.data.data[i].status
                    motivo = consultas.data.data[i].motivo
                    cliente_id = consultas.data.data[i].clientes_id
                    countDoc = consultas.data.data[i].countDoc
                    maxDoc = consultas.data.data[i].maxDoc
                    minDoc = consultas.data.data[i].minDoc
                    statusGeral += consultas.data.data[i].status + ', '
                    motivoGeral += consultas.data.data[i].motivo + ', '
                    if(consultas.data.data[i].tipo_consulta_id === 3){
                        idwall = consultas.data.data[i].retorno_json
                    }
                }
                else{
                    if(solidario.map((e) => {
                        return e.cliente_id
                    }).indexOf(consultas.data.data[i].clientes_id) === -1){
                        solidario[indexSolidario] = {
                            index: indexSolidario, 
                            nome: consultas.data.data[i].nome, 
                            cliente_id: consultas.data.data[i].clientes_id,
                            cpf_cnpj: consultas.data.data[i].cpf_cnpj,
                            telefone: consultas.data.data[i].telefone,
                            renda_informada: consultas.data.data[i].renda_informada,
                            status: consultas.data.data[i].status,
                            motivo: consultas.data.data[i].motivo,
                            countDoc: consultas.data.data[i].countDoc,
                            maxDoc: consultas.data.data[i].maxDoc,
                            minDoc: consultas.data.data[i].minDoc
                        }
                        statusGeral += consultas.data.data[i].status + ', '
                        motivoGeral += consultas.data.data[i].motivo + ', '
                        indexSolidario++
                    }
                    else{
                        var id = solidario.map((e) => {
                            return e.cliente_id
                        }).indexOf(consultas.data.data[i].clientes_id)
                        if(consultas.data.data[i].tipo_consulta_id === 3){
                            solidario[id].idwall = consultas.data.data[i].retorno_json
                        }
                        statusGeral += consultas.data.data[i].status + ', '
                        motivoGeral += consultas.data.data[i].motivo + ', '
                    }
                }
            }
        // }
    }
    dictCompleto.push({
        consulta_id: idConsulta,
        valor: valor,
        principal: principal,
        cliente_id: cliente_id,
        cpf_cnpj: cpf_cnpj,
        nomeLocatario: nomeLocatario[idConsulta],
        telefone: telefone,
        renda_informada: renda_informada,
        status: status,
        motivo: motivo,
        solidario: solidario,
        etapa: etapa,
        countDoc: countDoc,
        maxDoc: maxDoc,
        minDoc: minDoc,
        data_consulta: dataConsulta,
        statusGeral: statusGeral,
        motivoGeral: motivoGeral,
        idwall: idwall,
        finalidadeLocacao: finalidadeLocacao,
        qual_finalidadeLocacao: qual_finalidadeLocacao,
        score_status: score_status,
        pendenciafinanceira_status: pendenciafinanceira_status,
        processo_status: processo_status,
        antecedente_status: antecedente_status,
        status_final: status_final
    })
    return dictCompleto
    // this.setState({consultas: dictCompleto})
}

// Retorna um STYLE
FuncoesComum.layout = () => {
    var retorno = ' colorBackground ' 
    // if(localStorage.getItem('dadosUsuario_test')){
    //     if(JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao === 'master'){
    //         retorno = ' bg-gradient-info '
    //     }
    // }
    // else{
    //     retorno = ' bg-gradient-info '
    // }
    return retorno
}

// Retorna um STYLE para BUTTON
FuncoesComum.solida = () => {
    var retorno = ' colorButton ' 
    // if(localStorage.getItem('dadosUsuario_test')){
    //     if(JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao === 'master'){
    //         retorno = ' colorEconsult '
    //     }
    // }
    // else{
    //     retorno = ' colorEconsult '
    // }
    return retorno
}


export default FuncoesComum