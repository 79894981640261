/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import AuthAlugMaisLayout from "layouts/AuthAlugMais.jsx";
import auth from "./auth";
import DetalheConsulta from "views/DetalheConsulta.jsx";
import NewDetalheConsulta from "views/NewDetalheConsulta.jsx";
import Contrato from "./views/Contrato";
import Wizard from "./views/Wizard";
import Tarifacao from "./views/Tarifacao";
import NewTarifacao from "./views/NewTarifacao";
import Financeiro from "./views/Financeiro";
import GerarContrato from "./views/GerarContrato";
import EditarContrato from "./views/EditarContrato";
import LayoutEpar from "./views/LayoutEpar";
import PessoaFisica from "./views/PessoaFisica";
import PessoaJuridica from "./views/PessoaJuridica";
import Relatorios from "./views/Relatorios";
import FormularioContrato from "./views/FormularioContrato";
import FormularioContratoConsulta from "./views/FormularioContratoConsulta";
import FormularioContratoCarta from "./views/FormularioContratoCarta";
import DownloadContrato from "./views/DowloadContrato";
import TelaAprovacao from "./views/TelaAprovacao";
import TelaAprovacaoTodos from "./views/TelaAprovacaoTodos";
import CredPago from "./views/CredPago";
import ListarContratos from "./views/ListarContratos";
import BuscarCarta from "./views/BuscarCarta";
import DownloadGarantia from "./views/DownloadGarantia";
import Termo from "./views/Termo";
import EnviarContratos from "./views/EnviarContratos";

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render = { props => 
      auth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/alugmais/loginalugmais' }} />
      )
    }
  />
)

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/telaaprovacaolote" component={TelaAprovacaoTodos} />
      <Route path="/telaaprovacao" component={TelaAprovacao} />
      <Route path="/downloadgarantia/:id" component={DownloadGarantia} />
      <Route path="/termos" component={Termo} />
      <Route path="/enviarcontratos" component={EnviarContratos} />
      <PrivateRoute path="/admin" component={AdminLayout} />
      <PrivateRoute path="/admin/detalhe/:id" component={DetalheConsulta} />
      <PrivateRoute path="/admin/contrato" component={Contrato} />
      <PrivateRoute path="/admin/wizard" component={Wizard} />
      <PrivateRoute path="/admin/wizard/:id" component={Wizard} />
      <PrivateRoute path="/admin/tarifacao" component={Tarifacao} />
      <PrivateRoute path="/admin/newtarifacao" component={NewTarifacao} />
      <PrivateRoute path="/admin/financeiro" component={Financeiro} />
      <PrivateRoute path="/admin/gerarcontrato" component={GerarContrato} />
      <PrivateRoute path="/admin/editarcontrato" component={EditarContrato} />
      <PrivateRoute path="/admin/realizarconsulta" component={LayoutEpar} />
      <PrivateRoute path="/admin/finalidadelocacao" component={CredPago} />
      <PrivateRoute path="/admin/pessoafisica" component={PessoaFisica} />
      <PrivateRoute path="/admin/pessoajuridica" component={PessoaJuridica} />
      <PrivateRoute path="/admin/relatorios" component={Relatorios} />
      <PrivateRoute path="/admin/newdetalhe/:id" component={NewDetalheConsulta} />
      <PrivateRoute path="/admin/formcontrato/:id" component={FormularioContrato} />
      <PrivateRoute path="/admin/formcontrato" component={FormularioContrato} />
      <PrivateRoute path="/admin/formcontratoconsulta/:consulta" component={FormularioContratoConsulta} />
      <PrivateRoute path="/admin/formcontratocarta/:carta" component={FormularioContratoCarta} />
      <PrivateRoute path="/admin/listarcontratos" component={ListarContratos} />
      <PrivateRoute path="/admin/buscarcarta" component={BuscarCarta} />
      <PrivateRoute path="/admin/downloadcontrato/:id" component={DownloadContrato} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/alugmais" component={AuthAlugMaisLayout} />
      {/* <PrivateRoute path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} /> */}
      <Redirect from="/" to="/admin/finalidadelocacao" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
