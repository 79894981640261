/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts

import {
    Row,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Nav, 
    NavItem, 
    NavLink,
    TabPane,
    TabContent,
    Label,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter,
} from "reactstrap";



// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";


import axios from 'axios'
import moment from 'moment'
import Header from "components/Headers/Header.jsx";
import FuncoesComum from '../funcoes/FuncoesComum';
import FuncoesProcob from '../funcoes/FuncoesProcob';
import InputMask from 'react-input-mask'
import "assets/scss/pessoafisica.scss";

import CurrencyInput from 'react-currency-input'

import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import UploadDoc from "components/UploadDoc.jsx";
import ReactGA from "react-ga";
import extenso from 'extenso'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

// import $ from 'jquery'

class PessoaFisica extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            encargosValor: 0,
            activeTab: '1',
            activeTabPrincipal: '1',
            modal: false,
            valor: '',
            dado: '',
            nome: '',
            renda: '',
            emailEnviarCarta: '',
            cliente_id: '',
            dadoSolidario: '',
            rendaSolidario: '',
            maskSolidario: '999.999.999-99',
            clienteSolidario_id: '',
            tipo_consulta: '1',
            pessoasBusca: [],
            consulta_id: '',
            alert: false,
            qtdSolidario: 0,
            valorConsulta1: 0,
            valorConsulta2: 0,
            valorConsulta3: 0,
            mensagemModal: '',
            interval: 0,
            barraProgress: '0',
            alertSolidario: false,
            mensagemModalSolidario: '',
            min: '',
            chamadas: 0,
            email: 0,
            aluguel: 0,
            condominio: 0,
            imposto: 0,
            outros: 0,
            naoClicar: [],
            luz: 0,
            agua: 0,
            gas: 0,
            finalidadeLocacao: '',
            residir: '',
            emailCarta: '',
            semComprovante: 0,
            porcentagemCarta: 0,
            enviarCarta: '',
            porcentagemScore: 0,
            porcentagemPendencias: 0,
            processosNegar: '',
            load: false,
            codigo_carta: '',
            qual_finalidadeLocacao: '',
            anexarComprovantes: '',
            valorTaxaCardPorcentagem: 0.3,
            openModalValorEncargo: false,
            enableEncargos: "Sim",
            valorMinimoEncargo: 0,
            encargoBan: false
        }
        this.getImobData()
    }

    getImobData = () => {
        axios.get(api_admin + "/config/configs")
            .then(re => {
                let dados = re.data.data.find(e => e.id == 2)
                let encargo = re.data.data.find(e => e.id == 18)
                let valor = re.data.data.find(e => e.id == 19)
                console.log("=>", valor)

                this.setState({
                    valorTaxaCardPorcentagem: Number(dados.padrao), 
                    enableEncargos: encargo.padrao,
                    valorMinimoEncargo: Number(valor.padrao)
                })
            })
    }

    negarConsulta = () => {
        if (!this.state.encargoBan){
            let htmlImob = `<div>
                    <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                    A ALUG+ recebeu a sua consulta ID ${this.state.consulta_id}, no dia ${moment().format('DD/MM/YYYY')}, às ${moment().format('HH:mm')}, com os seguintes dados: <br/><br/>
                    Nome do locatário : ${this.state.pessoasBusca[0].nome} <br/>
                    CPF / CNPJ: ${this.state.pessoasBusca[0].dado} <br/><br/>
                    Informamos que dentro dos critérios e análises efetuadas, a consulta foi reprovada. <br/>
                    Obrigado pela compreensão e lamentamos em nesse momento não poder fazer a liberação dessa consulta, uma vez que não se enquadra da política de aceitação da Garantia ALUG+.<br/>
                    Sugerimos a inclusão de um locatário solidário para reanalise. Favor realizar nova consulta, inserindo os dados do Locatário Solidário.<br/><br/>
                    Atenciosamente,<br/>
                    Central de Cadastro ALUG+.
                </div>`

            api_epar.post( '/sendemailcarta', {
                to: JSON.parse(localStorage.getItem('dadosUsuario_test')).email,
                subject: 'ALUG+ CONSULTA REPROVADA',
                html: htmlImob,
                extras: {
                    cliente: this.state.pessoasBusca[0].cliente,
                    consulta: this.state.consulta_id
                }
            }).then((retorno) => {
                console.log(retorno)
            })
        }
    }
    
    componentDidMount(){
        console.log(moment().format('mm'));
        document.onkeydown = this.keyF5Pressed;
        document.onkeypress = this.keyF5Pressed
        document.onkeyup = this.keyF5Pressed;
         // Analytics
        ReactGA.initialize(process.env.REACT_APP_UA)
        ReactGA.pageview(window.location.pathname + window.location.search)

        // Verifica se o usuário tem acesso
        axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
            console.log(retorno.data.imobs.filter((e) => {
                return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            }))

            if(retorno.data.imobs.filter((e) => {
                return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            }).length === 0  || retorno.data.moba.filter((i) => {
                return i.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id && i.bloqueado === 0
              }).length > 0){
                this.props.history.push('/admin/logout')
            }
        })
        axios.post(api_admin + '/imobiliaria/imobuser/permis', {id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id}).then((retorno) => {
            if(retorno.data.per.filter((e) => {
                return e.sistema_id === 2 && e.permitido === 0
            }).length > 0 ){
                this.props.history.push('/admin/logout')
            }
        })
         

        // Irá analisar quais valores tem no localstorage e atribuir ao states
        if(localStorage.getItem('activeTab_test')){
            this.setState({activeTab: localStorage.getItem('activeTab_test')})
        }
        if(localStorage.getItem('activeTabPrincipal_test')){
            this.setState({activeTabPrincipal: localStorage.getItem('activeTabPrincipal_test')})
            // console.log(localStorage.getItem('activeTabPrincipal_test'))
            if(localStorage.getItem('activeTabPrincipal_test') === '2'){
                this.setState({barraProgress: '50'})
            }
            if(localStorage.getItem('activeTabPrincipal_test') === '3'){
                this.setState({barraProgress: '100'})
            }
            // if(localStorage.getItem('activeTabPrincipal_test') === '4'){
            //     this.setState({barraProgress: '100'})
            // }
        }
        if(localStorage.getItem('finalidadeLocacao')){
            // console.log(localStorage.getItem('finalidadeLocacao'))
            this.setState({finalidadeLocacao: localStorage.getItem('finalidadeLocacao')})
        }
        if(localStorage.getItem('qualFinalidadeLocacao_test')){
            // console.log(localStorage.getItem('finalidadeLocacao'))
            this.setState({qual_finalidadeLocacao: localStorage.getItem('qualFinalidadeLocacao_test')})
        }
        if(localStorage.getItem('valor_test')){
            this.setState({valor: localStorage.getItem('valor_test')})
        }
        if(localStorage.getItem('aluguel_test')){
            this.setState({aluguel: parseFloat(localStorage.getItem('aluguel_test'))})
        }
        if(localStorage.getItem('condominio_test')){
            this.setState({condominio: parseFloat(localStorage.getItem('condominio_test'))})
        }
        if(localStorage.getItem('imposto_test')){
            this.setState({imposto: parseFloat(localStorage.getItem('imposto_test'))})
        }
        if(localStorage.getItem('outros_test')){
            this.setState({outros: parseFloat(localStorage.getItem('outros_test'))})
        }
        if(localStorage.getItem('luz_test')){
            this.setState({luz: parseFloat(localStorage.getItem('luz_test'))})
        }
        if(localStorage.getItem('agua_test')){
            this.setState({agua: parseFloat(localStorage.getItem('agua_test'))})
        }
        if(localStorage.getItem('gas_test')){
            this.setState({gas: parseFloat(localStorage.getItem('gas_test'))})
        }
        if(localStorage.getItem('dado_test')){
            this.setState({dado: localStorage.getItem('dado_test')})
        }
        if(localStorage.getItem('renda_test')){
            this.setState({renda: localStorage.getItem('renda_test')})
        }
        if(localStorage.getItem('emailEnviarCarta_test')){
            this.setState({emailEnviarCarta: localStorage.getItem('emailEnviarCarta_test')})
        }
        if(localStorage.getItem('dadoSolidario_test')){
            this.setState({dadoSolidario: localStorage.getItem('dadoSolidario_test')})
        }
        if(localStorage.getItem('rendaSolidario_test')){
            this.setState({rendaSolidario: localStorage.getItem('rendaSolidario_test')})
        }
        if(localStorage.getItem('residir_test')){
            this.setState({residir: localStorage.getItem('residir_test')})
        }
        if(localStorage.getItem('maskSolidario_test')){
            this.setState({maskSolidario: localStorage.getItem('maskSolidario_test')})
        }
        if(localStorage.getItem('pessoasBusca_test')){
            this.setState({pessoasBusca: JSON.parse(localStorage.getItem('pessoasBusca_test'))})
            if(JSON.parse(localStorage.getItem('pessoasBusca_test')).length - 1 <= 0){
                this.setState({qtdSolidario: 0})
            }
            else{
                this.setState({qtdSolidario: JSON.parse(localStorage.getItem('pessoasBusca_test')).length - 1})
            }
        }
        if(!localStorage.getItem('pessoasBusca_test')){
            this.setState({qtdSolidario: 0})
        }
        if(localStorage.getItem('consulta_id_test')){
            this.setState({consulta_id: localStorage.getItem('consulta_id_test')})
        }
        if(localStorage.getItem('clienteSolidario_test')){
            this.setState({clienteSolidario_id: localStorage.getItem('clienteSolidario_id_test')})
        }
        if(localStorage.getItem('cliente_id_test')){
            this.setState({cliente_id: localStorage.getItem('cliente_id_test')})
        }
        if(localStorage.getItem('nome_test')){
            this.setState({nome: localStorage.getItem('nome_test')})
        }
        if(localStorage.getItem('emailCarta_test')){
            this.setState({emailCarta: localStorage.getItem('emailCarta_test')})
        }
        if(localStorage.getItem('codigo_carta_test')){
            this.setState({codigo_carta: localStorage.getItem('codigo_carta_test')})
        }

        if(localStorage.getItem('minuto_test')){
            this.setState({min: localStorage.getItem('minuto_test')})
            var newJson = []
            if(JSON.parse(localStorage.getItem('pessoasBusca_test'))){
                newJson = JSON.parse(localStorage.getItem('pessoasBusca_test'))
            }
            for(var j = 0; j < JSON.parse(localStorage.getItem('pessoasBusca_test')).length; j++){
                newJson[j].interval = 3
            }
            this.setState({pessoasBusca: newJson})
            localStorage.setItem('pessoasBusca_test', JSON.stringify(newJson))
            for(var i = 0; i < this.state.pessoasBusca.length; i++){
                this.relatorioIdwall(this.state.pessoasBusca[i].idBuscaIdwall, i)
            }
        }

        if(localStorage.getItem('email_test')){
            this.setState({email: localStorage.getItem('email_test')})
        }

        // api_epar.get( '/configuracoes').then((config) => {
        //     console.log(config)
        //     this.setState({semComprovante: parseFloat(config.data.data[0].semComprovante), porcentagemCarta: parseFloat(config.data.data[0].porcentagemCarta)})
        // }).catch((error) => {
        //     console.log(error)
        // })

        axios.get(api_admin + '/config/configsimob/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((config) => {
            var semComprovante 
            var porcentagemCarta
            var porcentagemScore
            var enviarCarta
            var porcentagemPendencias
            var processosNegar
            var anexarComprovantes
            
            console.log(config.data.data)

            if(config.data.data.length > 0){
                var alugmais = config.data.data.filter((alug) => {
                    return alug.sistemas_id === 2
                })
                
                if(alugmais.length > 0){
                    for(var i = 0; i < alugmais.length; i++){
                        if(alugmais[i].configuracoes_id === 1){
                            enviarCarta = alugmais[i].valor
                        }
                        if(alugmais[i].configuracoes_id === 2){
                            porcentagemCarta = parseFloat(alugmais[i].valor)
                        }
                        if(alugmais[i].configuracoes_id === 3){
                            semComprovante = parseFloat(alugmais[i].valor)
                        }
                        if(alugmais[i].configuracoes_id === 4){
                            porcentagemScore = (parseFloat(alugmais[i].valor) * 100)
                        }
                        if(alugmais[i].configuracoes_id === 8){
                            porcentagemPendencias = parseFloat(alugmais[i].valor)
                        }
                        if(alugmais[i].configuracoes_id === 9){
                            processosNegar = alugmais[i].valor
                        }
                        if(alugmais[i].configuracoes_id === 11){
                            anexarComprovantes = parseFloat(alugmais[i].valor)
                        }
                    }
                }
                this.setState({
                    enviarCarta: enviarCarta, 
                    semComprovante: parseFloat(semComprovante), 
                    porcentagemCarta: parseFloat(porcentagemCarta), 
                    porcentagemScore: parseFloat(porcentagemScore),
                    porcentagemPendencias: parseFloat(porcentagemPendencias),
                    anexarComprovantes: parseFloat(anexarComprovantes),
                    processosNegar: processosNegar,
                })
                
                if(!porcentagemCarta){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 2){
                                    porcentagemCarta = parseFloat(alugmais[i].padrao)
                                }
                            }
                            this.setState({porcentagemCarta: parseFloat(porcentagemCarta)})
                            console.log(porcentagemCarta)
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!porcentagemPendencias){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 8){
                                    porcentagemPendencias = parseFloat(alugmais[i].padrao)
                                }
                            }
                            this.setState({porcentagemPendencias: parseFloat(porcentagemPendencias)})
                            console.log(porcentagemPendencias)
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!semComprovante){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 3){
                                    semComprovante = parseFloat(alugmais[i].padrao)
                                }
                            }
                            this.setState({semComprovante: parseFloat(semComprovante)})
                            console.log(semComprovante)
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!enviarCarta){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 1){
                                    enviarCarta = alugmais[i].padrao
                                }
                            }
                            console.log(enviarCarta)
                            this.setState({enviarCarta: enviarCarta})
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!porcentagemScore){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 4){
                                    porcentagemScore = (parseFloat(alugmais[i].padrao) * 100)
                                }
                            }
                            console.log(porcentagemScore)
                            this.setState({porcentagemScore: parseFloat(porcentagemScore)})
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!processosNegar){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 9){
                                    processosNegar = alugmais[i].padrao
                                }
                            }
                            console.log(processosNegar)
                            this.setState({processosNegar: processosNegar})
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!anexarComprovantes){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 11){
                                    anexarComprovantes = (parseFloat(alugmais[i].padrao))
                                }
                            }
                            console.log(anexarComprovantes)
                            this.setState({anexarComprovantes: parseFloat(anexarComprovantes)})
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
            else{
                axios.get(api_admin + '/config/configs').then((configuracoes) => {
                    var alugmais = configuracoes.data.data.filter((alug) => {
                        return alug.sistemas_id === 2
                    })

                    console.log(alugmais)
                    console.log(configuracoes)

                    if(alugmais.length > 0){
                        for(var i = 0; i < alugmais.length; i++){
                            if(alugmais[i].id === 1){
                                enviarCarta = alugmais[i].padrao
                            }
                            if(alugmais[i].id === 2){
                                porcentagemCarta = parseFloat(alugmais[i].padrao)
                            }
                            if(alugmais[i].id === 3){
                                semComprovante = parseFloat(alugmais[i].padrao)
                            }
                            if(alugmais[i].id === 4){
                                porcentagemScore = (parseFloat(alugmais[i].padrao) * 100)
                            }
                            if(alugmais[i].id === 8){
                                porcentagemPendencias = parseFloat(alugmais[i].padrao)
                            }
                            if(alugmais[i].id === 9){
                                processosNegar = alugmais[i].padrao
                            }
                            if(alugmais[i].id === 11){
                                anexarComprovantes = parseFloat(alugmais[i].padrao)
                            }
                        }
                        console.log(enviarCarta)
                        console.log(porcentagemCarta)
                        console.log(semComprovante)
                        console.log(porcentagemScore)
                        console.log(porcentagemPendencias)
                        console.log(processosNegar)
                        console.log(anexarComprovantes)
                        this.setState({
                            enviarCarta: enviarCarta, 
                            semComprovante: parseFloat(semComprovante), 
                            porcentagemCarta: parseFloat(porcentagemCarta), 
                            porcentagemScore: parseFloat(porcentagemScore),
                            porcentagemPendencias: parseFloat(porcentagemPendencias),
                            processosNegar: processosNegar,
                            anexarComprovantes: parseFloat(anexarComprovantes),
                        })
                    } 
                }).catch((error) => {
                    console.log(error)
                })
            }
        }).catch((error) => {
            console.log(error)
        })

        // api_epar.get( '/competencia/' +JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/' + this.state.tipo_consulta).then((tipoConsulta1) => {
        //     this.setState({valorConsulta1: tipoConsulta1.data.data[0].valor_cobrar})
        // }).catch((error) => {
        //     console.log(error)
        // })
        // api_epar.get( '/competencia/' +JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/2').then((tipoConsulta2) => {
        //     this.setState({valorConsulta2: tipoConsulta2.data.data[0].valor_cobrar})
        // }).catch((error) => {
        //     console.log(error)
        // })
        // api_epar.get( '/competencia/' +JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/3').then((tipoConsulta3) => {
        //     this.setState({valorConsulta3: tipoConsulta3.data.data[0].valor_cobrar})
        // }).catch((error) => {
        //     console.log(error)
        // })

    }

    

    keyF5Pressed = (e) => {
        e = e || window.event;

        if (e.keyCode == 116 || (e.ctrlKey && e.keycode == 82) || (e.ctrlKey && e.keycode == 116)) {
            alert("Você não pode recarregar a página no momento, espere a consulta terminar...");
            e.preventDefault();
            e.stopPropagation();
            return
        }
    }

    toggle = tab => {
        if(this.state.activeTab !== tab){
            this.setState({activeTab: tab});
        } 
        // if(tab === '1'){
        //     this.setState({barraProgress: '0'})
        // }
        // if(tab === '2'){
        //     this.setState({barraProgress: '35'})
        // }
        // if(tab === '3'){
        //     this.setState({barraProgress: '70'})
        // }
        // if(tab === '4'){
        //     this.setState({barraProgress: '100'})
        // }
    }
    
    togglePrincipal = tab => {
        if(this.state.activeTabPrincipal !== tab){
            this.setState({activeTabPrincipal: tab});
        } 
        if(tab === '1'){
            this.setState({barraProgress: '0'})
        }
        if(tab === '2'){
            this.setState({barraProgress: '50'})
        }
        if(tab === '3'){
            this.setState({barraProgress: '100'})
        }
        // if(tab === '4'){
        //     this.setState({barraProgress: '100'})
        // }
    }

    // Exibir MODAL para adicionar SOLIDÁRIO
    toggleModal = () => {
        if(this.state.dado !== '' && parseFloat(this.state.renda) !== 0){
            this.setState({modal: !this.state.modal})
        }
        else{
            console.log('Locatário PRINCIPAL não foi adicionado')
            this.setState({mensagemModal: <div>
                                            Locatário PRINCIPAL não foi adicionado ou sua renda líquida não foi informada
                                           </div>})
            this.toggleAlert()
        }
        // document.getElementById('inputAddSolidario').checked = false
        //console.log(this.state.pessoasBusca)
    }

    // Exibir MODAL para adicionar SOLIDÁRIO / checkbox
    // toggleModalAdd = (evento) => {
    //     if(evento.target.checked){
    //         if(this.state.dado !== '' && parseFloat(this.state.renda) !== 0){
    //             this.setState({modal: !this.state.modal})
    //         }
    //         else{
    //             console.log('Locatário PRINCIPAL não foi adicionado')
    //             this.setState({mensagemModal: <div>
    //                                             Locatário PRINCIPAL não foi adicionado ou sua renda líquida não foi informada
    //                                            </div>})
    //             this.toggleAlert()
    //             document.getElementById('inputAddSolidario').checked = false
    //         }
    //     }
        
    //     //console.log(this.state.pessoasBusca)
    // }

    // Exibir MODAL para ALERT
    toggleAlert = () => {
        this.setState({alert: !this.state.alert})
    }

    toggleAlertSolidario = () => {
        this.setState({alertSolidario: !this.state.alertSolidario})
    }

    // modalAlert = (mensagem) => {
    //     return (
    //         <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
    //             <ModalHeader toggle={this.toggleAlert}>Alerta</ModalHeader>
    //             <ModalBody>
    //                 <Row>
    //                     <Col md={12}>
    //                         {this.state.mensagemModal}
    //                     </Col>
    //                 </Row>
    //             </ModalBody>
    //             <ModalFooter>
    //                 <Button className="btn-dark" onClick={this.toggleAlert}>Cancelar</Button>
    //             </ModalFooter>
    //         </Modal>
    //     )
    // }

    toggleLoad = () => {
        this.setState({load: !this.state.load})
      }

    // Trocar a mascara do CPF/CNPJ do locatário SOLIDÁRIO
    mascaraSolidario = (evento) => {
        if(evento.target.value === 'cpf'){
            this.setState({maskSolidario: '999.999.999-99'})
            localStorage.setItem('maskSolidario_test', '999.999.999-99')
        }
        else{
            this.setState({maskSolidario: '99.999.999/9999-99'})
            localStorage.setItem('maskSolidario_test', '99.999.999/9999-99')

        }
    }

    changeEmailCarta = (event) => {
        this.setState({emailCarta: event.target.value})
        localStorage.setItem('emailCarta_test', event.target.value)
    }

    changeValor = (evento, value, maskedValue) => {
        this.setState({valor: value})
        localStorage.setItem('valor_test', value)
    }

    changeAluguel = (evento, value, maskedValue) => {
        this.setState({aluguel: value})
        localStorage.setItem('aluguel_test', value)
    }

    changeCondominio = (evento, value, maskedValue) => {
        this.setState({condominio: value})
        localStorage.setItem('condominio_test', value)
    }

    changeImposto = (evento, value, maskedValue) => {
        this.setState({imposto: value})
        localStorage.setItem('imposto_test', value)
    }

    changeOutros = (evento, value, maskedValue) => {
        this.setState({outros: value})
        localStorage.setItem('outros_test', value)
    }

    changeLuz = (evento, value, maskedValue) => {
        this.setState({luz: value})
        localStorage.setItem('luz_test', value)
    }

    changeAgua = (evento, value, maskedValue) => {
        this.setState({agua: value})
        localStorage.setItem('agua_test', value)
    }

    changeGas = (evento, value, maskedValue) => {
        this.setState({gas: value})
        localStorage.setItem('gas_test', value)
    }

    changeDado = (evento) => {
        this.setState({ dado: evento.target.value} )
        localStorage.setItem('dado_test', evento.target.value)
    }

    changeEncargoValor = (evento, value, maskedValue) => {
        this.setState({ encargosValor: value} )
        localStorage.setItem('encargo_test', value)
    }

    changeEmailEnviarCarta = (evento) => {
        this.setState({ emailEnviarCarta: evento.target.value} )
        localStorage.setItem('emailEnviarCarta_test', evento.target.value)
    }

    changeQualFinalidadeLocacao = (evento) => {
        this.setState({ qual_finalidadeLocacao: evento.target.value} )
        localStorage.setItem('qualFinalidadeLocacao_test', evento.target.value)
    }
    
    changeRenda = (evento, value, maskedValue) => {
        // console.log(evento.target.value.replace('R$ ', '').replace(/\./g, '').replace(/,/g, '.'))
        // console.log(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(evento.target.value.replace('R$ ', '').replace(/\./g, '').replace(/,/g, '.')))
        this.setState({ renda: value})
        localStorage.setItem('renda_test', value)
    }

    changeDadoSolidario = (evento) => {
        this.setState({ dadoSolidario: evento.target.value} )
        localStorage.setItem('dadoSolidario_test', evento.target.value)
    }
    
    changeRendaSolidario = (evento, value, maskedValue) => {
        this.setState({ rendaSolidario: value})
        localStorage.setItem('rendaSolidario_test', value)
    }

    changeResidir = (evento) => {
        this.setState({residir: evento.target.value})
        localStorage.setItem('residir_test', evento.target.value)
    }

    // Função é utilizada para buscar, atualizar ou criar os clientes já relacionados na consulta
    buscarDadosPessoasBusca = () => {
        if(this.state.pessoasBusca.length > 0){
            api_epar.get( '/cliente/' + this.state.dado.replace('/', '_')).then((cliente) => {
                if(cliente.data.data.length > 0){
                    var atualizar = this.state.pessoasBusca
                    atualizar[0].cliente = cliente.data.data[0].id
                    atualizar[0].dado = this.state.dado
                    atualizar[0].emailEnviarCarta = this.state.emailEnviarCarta
                    if(atualizar[0].renda === ''){
                        if(cliente.data.data[0].renda_informada !== null || cliente.data.data[0].renda_informada !== ''){
                            atualizar[0].renda = cliente.data.data[0].renda_informada
                            this.setState({renda: cliente.data.data[0].renda_informada})
                            localStorage.setItem('renda_test', cliente.data.data[0].renda_informada)
                        }
                        else{
                            atualizar[0].renda = 0
                            this.setState({renda: 0})
                            localStorage.setItem('renda_test', 0)
                        }
                    }
                    else{
                        if (this.state.enableEncargos != "Sim" || (this.state.encargosValor >= this.state.valorMinimoEncargo && this.state.renda * this.state.valorTaxaCardPorcentagem >= this.state.encargosValor)){
                            api_epar.put( '/cliente/', {
                                id: cliente.data.data[0].id,
                                renda_informada: this.state.renda
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                            atualizar[0].renda = this.state.renda
                            this.setState({renda: this.state.renda})
                            localStorage.setItem('renda_test', this.state.renda)
                        }
                    }
                    this.setState({pessoasBusca: atualizar})
                    this.setState({cliente_id: cliente.data.data[0].id})
                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                    localStorage.setItem('cliente_id_test', cliente.data.data[0].id)
                }
                else{
                    api_epar.post('/cliente', {cpf_cnpj: this.state.dado, renda_informada: this.state.renda, email: this.state.emailEnviarCarta}).then((retorno) => {
                        var atualizar = this.state.pessoasBusca
                        atualizar[0].cliente = retorno.data.data
                        this.setState({pessoasBusca: atualizar})
                        this.setState({cliente_id: retorno.data.data})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        localStorage.setItem('cliente_id_test', retorno.data.data)
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            })
        }
        else{
            var principal = [{
                dado: this.state.dado,
                renda: this.state.renda,
                email: this.state.emailEnviarCarta,
                nome: 'Aguarde....',
                quem: 'Principal',
                resultado: '',
                motivo: '',
                concluido: 0
            }]
            api_epar.get( '/cliente/' + this.state.dado.replace('/', '_')).then((cliente) => {
                if(cliente.data.data.length > 0){
                    principal[0].cliente = cliente.data.data[0].id
                    localStorage.setItem('cliente_id_test', cliente.data.data[0].id)
                }
                else{
                    api_epar.post('/cliente', {cpf_cnpj: this.state.dado, renda_informada: this.state.renda, email: this.state.emailEnviarCarta}).then((retorno) => {
                        principal[0].cliente = retorno.data.data
                        this.setState({cliente_id: retorno.data.data})
                        localStorage.setItem('cliente_id_test', retorno.data.data)
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
            if(this.state.pessoasBusca.length > 0){
                if(this.state.pessoasBusca[0]['quem'] === 'Principal'){
                    this.state.pessoasBusca.splice(0, 1)
                    this.setState({pessoasBusca: this.state.pessoasBusca})
                }
            }
            localStorage.setItem('pessoasBusca_test', JSON.stringify(principal.concat(this.state.pessoasBusca)))
            this.setState({pessoasBusca: principal.concat(this.state.pessoasBusca)})
        }

        if(this.state.pessoasBusca.length > 1){
            for(var i = 1; i < this.state.pessoasBusca.length; i++){
                this.solidario(i).then((resultado) => {
                    // console.log(this.state.pessoasBusca)
                 })
            }
        }
        else{
            console.log('Não há solidário')
        }
        // await api_epar.get( '/clienteshasconsultacliente/' + this.state.pessoasBusca[indice].cliente).then((consultasRecentes) => {
        //     console.log(consultasRecentes.data.data)
        //     var atualizar = this.state.pessoasBusca
        //     var verificouPessoais = false
        //     var verificouFinanceiros = false
        //     var verificouIdwall = false
        //     for(var i = 0; i < consultasRecentes.data.data.length; i++){
        //         if(consultasRecentes.data.data[i].tipo_consulta_id === 1 && verificouFinanceiros === false){
        //             console.log('passou financeiro')
        //             verificouFinanceiros = true
        //             atualizar[indice].resultado += consultasRecentes.data.data[i].status + ', '
        //             atualizar[indice].motivo += consultasRecentes.data.data[i].motivo + ', '
        //             atualizar[indice].concluido = 3
        //             // api_epar.post( '/clienteshasconsulta', {
        //             //     clientes_id: atualizar[indice].cliente,
        //             //     consulta_id: this.state.consulta_id,
        //             //     tipo_consulta_id: 1,
        //             //     id_tabela_consulta: null,
        //             //     data_consulta: moment(consultasRecentes.data.data[i].data_consulta).format('YYYY-MM-DD HH:mm:ss'),
        //             //     tipo_locatario: 'Principal',
        //             //     retorno_json: consultasRecentes.data.data[i].retorno_json
        //             // }).then((retornoClienteConsulta) => {
        //             //     console.log(retornoClienteConsulta)
        //             // }).catch((error) => {
        //             //     console.log(error)
        //             // })
        //         }
        //         if(consultasRecentes.data.data[i].tipo_consulta_id === 2 && verificouPessoais === false){
        //             console.log('passou pessoais')
        //             verificouPessoais = true
        //             if(JSON.parse(consultasRecentes.data.data[i].retorno_json).content.nome.conteudo){
        //                 atualizar[indice].nome = JSON.parse(consultasRecentes.data.data[i].retorno_json).content.nome.conteudo.nome
        //             }
        //             // api_epar.post( '/clienteshasconsulta', {
        //             //     clientes_id: atualizar[indice].cliente,
        //             //     consulta_id: this.state.consulta_id,
        //             //     tipo_consulta_id: 2,
        //             //     id_tabela_consulta: null,
        //             //     data_consulta: moment(consultasRecentes.data.data[i].data_consulta).format('YYYY-MM-DD HH:mm:ss'),
        //             //     tipo_locatario: 'Principal',
        //             //     retorno_json: consultasRecentes.data.data[i].retorno_json
        //             // }).then((retornoClienteConsulta) => {
        //             //     console.log(retornoClienteConsulta)
        //             // }).catch((error) => {
        //             //     console.log(error)
        //             // })
        //             atualizar[indice].concluido = 3
        //         }
        //         if(consultasRecentes.data.data[i].tipo_consulta_id === 3 && verificouIdwall === false){
        //             console.log('passou idwall')
        //             verificouIdwall = true
        //             atualizar[indice].resultado += consultasRecentes.data.data[i].status + ', '
        //             atualizar[indice].motivo += consultasRecentes.data.data[i].motivo + ', '
        //             atualizar[indice].concluido = 5
        //             // api_epar.post( '/clienteshasconsulta', {
        //             //     clientes_id: atualizar[indice].cliente,
        //             //     consulta_id: this.state.consulta_id,
        //             //     tipo_consulta_id: 3,
        //             //     id_tabela_consulta: null,
        //             //     data_consulta: moment(consultasRecentes.data.data[i].data_consulta).format('YYYY-MM-DD HH:mm:ss'),
        //             //     tipo_locatario: 'Principal',
        //             //     retorno_json: consultasRecentes.data.data[i].retorno_json
        //             // }).then((retornoClienteConsulta) => {
        //             //     console.log(retornoClienteConsulta)
        //             // }).catch((error) => {
        //             //     console.log(error)
        //             // })
        //         }
        //     }
        //     console.log(atualizar)
        //     this.setState({pessoasBusca: atualizar})
        //     return consultasRecentes

        // }).catch((error) => {
        //     console.log(error)
        // })
        
    }

    // Atualizar a etapa da consulta, porém não está sendo usada
    atualizarEtapaConsulta = (etapa) => {
        api_epar.put( '/consulta', {etapa: etapa, id: this.state.consulta_id}).then((consultaAtualizada) => {
            console.log(consultaAtualizada)
        }).catch((error) => {
            console.log(error)
        })
    }

    comecoConsulta = (dadoConsulta) => {
        if (this.state.enableEncargos == "Sim" && (this.state.encargosValor < this.state.valorMinimoEncargo || this.state.renda * this.state.valorTaxaCardPorcentagem < this.state.encargosValor)){
            api_epar.post("/negarconsulta", {
                id: dadoConsulta
            }).then(re => {
                let atualizado = this.state.pessoasBusca
                atualizado[0].resultado = "Negado, "
                atualizado[0].concluido = 3
                
                this.setState({pessoasBusca: atualizado, encargoBan: true})

                this.state.pessoasBusca[0].resultado = "Negado, "
                this.state.pessoasBusca[0].concluido = 3
                this.state.encargoBan = true
            })
        }
        else{
            this.consultar()
        }
    }

    // Validar a passagem para uma próxima etapa
    proximaEtapa = () => {
        var passar = 0

        //Da etapa 1 para a 2
        if(this.state.activeTabPrincipal === '1'){
            if(this.state.enviarCarta === 'Automático'){
                if(this.state.renda !== '' && this.state.dado !== '' && this.validarCPF(this.state.dado) && this.state.emailEnviarCarta !== ''){
                    if(this.state.finalidadeLocacao === 'Comercial' && this.state.qual_finalidadeLocacao !== ''){
                        this.buscarDadosPessoasBusca()
                        passar = 1
                        this.setState({barraProgress: '50'})
                    }
                    else if(this.state.finalidadeLocacao !== 'Comercial'){
                        this.buscarDadosPessoasBusca()
                        passar = 1
                        this.setState({barraProgress: '50'})
                    }
                    else{
                        this.setState({mensagemModal: <div>
                            Campo(s) vazios(s)
                        </div>})
                        this.toggleAlert()
                    }
                }
            }
            else{
                if(this.state.renda !== '' && this.state.dado !== '' && this.validarCPF(this.state.dado)){
                    if(this.state.finalidadeLocacao === 'Comercial' && this.state.qual_finalidadeLocacao !== ''){
                        this.buscarDadosPessoasBusca()
                        passar = 1
                        this.setState({barraProgress: '50'})
                    }
                    else if(this.state.finalidadeLocacao !== 'Comercial'){
                        this.buscarDadosPessoasBusca()
                        passar = 1
                        this.setState({barraProgress: '50'})
                    }
                    else{
                        this.setState({mensagemModal: <div>
                            Campo(s) vazios(s)
                        </div>})
                        this.toggleAlert()
                    }
                }
            }
            // this.atualizarEtapaConsulta('Dados da consulta')
        }

        // //Da etapa 2 para a 3
        // if(this.state.activeTabPrincipal === '2' && this.state.aluguel !== ''){

        //     // Soma dos custos do imóvel
        //     var calcularValor = this.state.outros + this.state.imposto + this.state.condominio + this.state.aluguel + this.state.luz + this.state.gas + this.state.agua
        //     this.setState({valor: calcularValor})
        //     localStorage.setItem('valor_test', calcularValor)

        //     passar = 1

        //     //Caso não exista a consulta irá criar, caso contrário irá atualizar os dados
        //     if(this.state.consulta_id === ''){
        //         api_epar.post( '/consulta', {
        //             data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
        //             usuarios_id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id,
        //             aluguel: this.state.aluguel,
        //             condominio: this.state.condominio,
        //             imposto: this.state.imposto,
        //             outros: this.state.outros,
        //             luz: this.state.luz,
        //             agua: this.state.agua,
        //             gas: this.state.gas,
        //             valor: calcularValor,
        //             etapa: 'Imóvel e valor de aluguel',
        //             downloadLocacao: 0,
        //             downloadGarantia: 0,
        //             finalidadeLocacao: this.state.finalidadeLocacao
        //         }).then((dadoConsulta) => {
        //             this.setState({consulta_id: dadoConsulta.data.data})
        //             localStorage.setItem('consulta_id_test', dadoConsulta.data.data)
        //             console.log(dadoConsulta.data.data)
        //         }).catch((error) => {
        //             console.log(error)
        //         })
        //     }
        //     else{
        //         var calcularValor = this.state.outros + this.state.imposto + this.state.condominio + this.state.aluguel + this.state.luz + this.state.gas + this.state.agua
        //         this.setState({valor: calcularValor})
        //         localStorage.setItem('valor_test', calcularValor)

        //         api_epar.put( '/consultavalor', {
        //             aluguel: this.state.aluguel,
        //             condominio: this.state.condominio,
        //             imposto: this.state.imposto,
        //             outros: this.state.outros,
        //             luz: this.state.luz,
        //             agua: this.state.agua,
        //             gas: this.state.gas,
        //             valor: calcularValor,
        //             finalidadeLocacao: this.state.finalidadeLocacao,
        //             id: this.state.consulta_id
        //         }).then((consultaAtualizada) => {
        //             console.log(consultaAtualizada)
        //         }).catch((error) => {
        //             console.log(error)
        //         })
        //     }
        //     this.buscarDadosPessoasBusca()
        //     this.setState({barraProgress: '70'})
        //     // this.atualizarEtapaConsulta('Valor do aluguel')
        // }

        // // Da etapa 3 para a 4
        // Da etapa 2 para a 3
        if(this.state.activeTabPrincipal === '2' && this.state.pessoasBusca.length > 0){
            if(this.state.consulta_id === ''){
                api_epar.post( '/consulta', {
                    data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                    usuarios_id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id,
                    // aluguel: this.state.aluguel,
                    // condominio: this.state.condominio,
                    // imposto: this.state.imposto,
                    // outros: this.state.outros,
                    // luz: this.state.luz,
                    // agua: this.state.agua,
                    // gas: this.state.gas,
                    // valor: calcularValor,
                    aluguel: 0,
                    condominio: 0,
                    imposto: 0,
                    outros: 0,
                    luz: 0,
                    agua: 0,
                    gas: 0,
                    valor: this.state.encargosValor,
                    etapa: 'Imóvel e valor de aluguel',
                    downloadLocacao: 0,
                    downloadGarantia: 0,
                    finalidadeLocacao: this.state.finalidadeLocacao,
                    qual_finalidadeLocacao: this.state.qual_finalidadeLocacao
                }).then((dadoConsulta) => {
                    this.setState({consulta_id: dadoConsulta.data.data})
                    localStorage.setItem('consulta_id_test', dadoConsulta.data.data)
                    this.comecoConsulta(dadoConsulta.data.data)
                    
                }).catch((error) => {
                    console.log(error)
                })
            }
            else{
                // var calcularValor = this.state.outros + this.state.imposto + this.state.condominio + this.state.aluguel + this.state.luz + this.state.gas + this.state.agua
                // this.setState({valor: calcularValor})
                // localStorage.setItem('valor_test', calcularValor)

                api_epar.put( '/consultavalor', {
                    aluguel: 0,
                    condominio: 0,
                    imposto: 0,
                    outros: 0,
                    luz: 0,
                    agua: 0,
                    gas: 0,
                    valor: this.state.encargosValor,
                    // aluguel: this.state.aluguel,
                    // condominio: this.state.condominio,
                    // imposto: this.state.imposto,
                    // outros: this.state.outros,
                    // luz: this.state.luz,
                    // agua: this.state.agua,
                    // gas: this.state.gas,
                    // valor: calcularValor,
                    finalidadeLocacao: this.state.finalidadeLocacao,
                    id: this.state.consulta_id,
                    qual_finalidadeLocacao: this.state.qual_finalidadeLocacao
                }).then((consultaAtualizada) => {
                    this.comecoConsulta(this.state.consulta_id)
                   
                }).catch((error) => {
                    console.log(error)
                })
            }
            // this.aguardeResposta().then(() => {
            this.buscarDadosPessoasBusca()
            var atualizar = this.state.pessoasBusca
            for(var i = 0; i < this.state.pessoasBusca.length; i++){
                atualizar[i].resultado = ''
                atualizar[i].motivo = ''
            }
            localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
            this.setState({pessoasBusca: atualizar})

            passar = 1
            this.setState({barraProgress: '100'})
            // })
            // // Verifica se o valor do aluguel é 30% menor que a soma da renda dos locatários
            // if(this.verificar30()){
            //     this.buscarDadosPessoasBusca()
            //     var atualizar = this.state.pessoasBusca
            //     for(var i = 0; i < this.state.pessoasBusca.length; i++){
            //         atualizar[i].resultado = ''
            //         atualizar[i].motivo = ''
            //     }
            //     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
            //     this.setState({pessoasBusca: atualizar})
            //     this.consultar()
            //     passar = 1
            //     this.setState({barraProgress: '100'})
            //     // this.atualizarEtapaConsulta('Confirmação dos dados')
            // }
            // else{
            //     console.log('Não será possível prosseguir, valor maior que 30% da renda')
            //     // alert('Não será possível prosseguir, VALOR A SER PAGO é maior que 30% da RENDA INFORMADA\nCaso queira adicionar um SOLIDÁRIO volte para etapa anterior')
            //     if( this.state.pessoasBusca.length === 1){
            //         this.setState({mensagemModalSolidario: <div>
            //                             Renda insuficiente. Vamos adicionar um locatário solidário?
            //                             {/* Caso queira adicionar um SOLIDÁRIO volte para etapa anterior */}
            //                             {/* <Button className={'mt-5 float-right btn-dark'} style={{color: 'white'}} onClick={this.toggleModal}>Adicionar solidário</Button> */}
            //                         </div>})
            //         }
            //         else{
            //             this.setState({mensagemModalSolidario: <div>
            //                 Ainda não temos renda suficiente. Quer incluir um outro locatário solidário?
            //                 {/* Caso queira adicionar um SOLIDÁRIO volte para etapa anterior */}
            //                 {/* <Button className={'mt-5 float-right btn-dark'} style={{color: 'white'}} onClick={this.toggleModal}>Adicionar solidário</Button> */}
            //             </div>})
            //         }
            //     // this.setState({mensagemModalSolidario: <div>
            //     //                                 Verificamos que o Locatário não possui renda suficiente para essa locação.<br/>
            //     //                                 Deseja adicionar um Locatário Solidário para incrementar uma nova renda na consulta? <br/>
            //     //                                 Lembrando que comprovantes poderão ser solicitados durante a consulta.
            //     //                                 {/* Caso queira adicionar um SOLIDÁRIO volte para etapa anterior */}
            //     //                                 {/* <Button className={'mt-5 float-right btn-dark'} style={{color: 'white'}} onClick={this.toggleModal}>Adicionar solidário</Button> */}
            //     //                               </div>})
            //     this.toggleAlertSolidario()

            // }
        }
        else if(this.state.activeTabPrincipal === '2'){
            console.log('Não há pessoas')
            // alert('Não há pessoas para busca')
            this.setState({mensagemModal: <div>
                                            Não há locatários para busca
                                          </div>})
            this.toggleAlert()
        }
        // if(this.state.activeTabPrincipal === '1' && this.state.valor !== ''){
        //     passar = 1
        //     api_epar.post( '/consulta', {
        //        data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
        //        usuarios_id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id,
        //        valor: this.state.valor,
        //        etapa: 'Imóvel e valor de aluguel'
        //     }).then((dadoConsulta) => {
        //         this.setState({consulta_id: dadoConsulta.data.data})
        //         // this.setState({link: '/admin/gerarcontrato/' + dadoConsulta.data.data})
        //         localStorage.setItem('consulta_id_test', dadoConsulta.data.data)
        //         // localStorage.setItem('link_test', '/admin/gerarcontrato/' + dadoConsulta.data.data)
        //         console.log(dadoConsulta.data.data)
        //     }).catch((error) => {
        //         console.log(error)
        //     })
        // }

        // if(this.state.activeTabPrincipal === '2' && this.state.dado !== ''){
        //     this.buscarDadosPessoasBusca()
        //     passar = 1
        //     // this.atualizarEtapaConsulta('Dados pessoais')
        // }
        // if(this.state.activeTabPrincipal === '3' && this.state.pessoasBusca.length > 0){
        //     if(this.verificar30()){
        //         var atualizar = this.state.pessoasBusca
        //         for(var i = 0; i < this.state.pessoasBusca.length; i++){
        //             atualizar[i].resultado = null
        //             atualizar[i].motivo = null
        //         }
        //         localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
        //         this.setState({pessoasBusca: atualizar})
        //         this.consultar()
        //         passar = 1
        //         // this.atualizarEtapaConsulta('Realizar busca')
        //     }
        //     else{
        //         console.log('Não será possível prosseguir, valor maior que 30% da renda')
        //         alert('Não será possível prosseguir, VALOR A SER PAGO é maior que 30% da RENDA INFORMADA\nCaso queira adicionar um SOLIDÁRIO volte para etapa anterior')
        //     }
        // }
        // else if(this.state.activeTabPrincipal === '3'){
        //     console.log('Não há pessoas')
        //     alert('Não há pessoas para busca')
        // }

        // Iniciará uma nova consulta e será direcionado para o detalhe da consulta
        if(this.state.activeTabPrincipal === '3'){
            // this.atualizarEtapaConsulta('Validação da consulta')
            this.setState({barraProgress: '0'})
            var consulta = this.state.consulta_id
            this.novaConsulta()
            this.props.history.push('/admin/newdetalhe/' + consulta)
        }
        
        if(passar === 1){
            localStorage.setItem('activeTabPrincipal_test', (parseInt(this.state.activeTabPrincipal) + 1).toString())
            this.setState({activeTabPrincipal: (parseInt(this.state.activeTabPrincipal) + 1).toString()})
        }
    }

    voltarEtapa = () => {
        if((parseInt(this.state.activeTabPrincipal) - 1).toString() === '1'){
            this.setState({barraProgress: '0'})
        }
        if((parseInt(this.state.activeTabPrincipal) - 1).toString() === '2'){
            this.setState({barraProgress: '50'})
        }
        if((parseInt(this.state.activeTabPrincipal) - 1).toString() === '3'){
            this.setState({barraProgress: '100'})
        }
        // if((parseInt(this.state.activeTabPrincipal) - 1).toString() === '4'){
        //     this.setState({barraProgress: '100'})
        // }
        localStorage.setItem('activeTabPrincipal_test', (parseInt(this.state.activeTabPrincipal) - 1).toString())
        this.setState({activeTabPrincipal: (parseInt(this.state.activeTabPrincipal) - 1).toString()})
    }

    // Início da consulta
    consultar = async () => {
            if(this.state.dado !== ''){
                // Busca os dados cadastrais do locatário principal 
                try {
                    new Promise(resolve => setTimeout(resolve, 3000)).then(() => {

                        api_epar.get( '/procobcadastrais/' + this.state.dado.replace(/\D/g, '') + '/' + this.state.pessoasBusca[0].cliente).then((dadoCliente) => {
                            console.log(dadoCliente.data.data)
                            var atualizar = this.state.pessoasBusca
                            atualizar[0].nome = dadoCliente.data.data.content.nome.conteudo.nome
                            if(dadoCliente.data.data.content.emails.conteudo && dadoCliente.data.data.content.emails.conteudo.length > 0 && this.state.enviarCarta === 'Fim'){
                                localStorage.setItem('emailCarta_test', dadoCliente.data.data.content.emails.conteudo[0].email)
                                this.setState({emailCarta: dadoCliente.data.data.content.emails.conteudo[0].email})
                            }
                            atualizar[0].nome = dadoCliente.data.data.content.nome.conteudo.nome
                            this.setState({pessoasBusca: atualizar})
                            var busca30
                            if(dadoCliente.data.local === 'BANCO'){
                                busca30 = 1
                            }
                            else{
                                busca30 = 0
                            }
                            console.log(localStorage.getItem('consulta_id_test'))
                            localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                api_epar.post( '/clienteshasconsulta', {
                                    clientes_id: atualizar[0].cliente,
                                    consulta_id: parseInt(localStorage.getItem('consulta_id_test')),
                                    tipo_consulta_id: 2,
                                    id_tabela_consulta: null,
                                    data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    tipo_locatario: 'Principal',
                                    busca30: busca30, 
                                    retorno_json: JSON.stringify(dadoCliente.data.data)
                                }).then((retornoClienteConsulta) => {
                                    console.log(retornoClienteConsulta)
                                    // api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/2').then((dadoCompetencia) => {
                                    //     console.log(dadoCompetencia.data.data[0])
                                    //     api_epar.post( '/tarifacao', {
                                    //         data: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    //         valor: dadoCompetencia.data.data[0].valor_cobrar,
                                    //         tipo: 'Débito',
                                    //         clientes_has_consulta_id: retornoClienteConsulta.data.data
                                    //     }).then((retornoTarifacao) => {
                                    //         console.log(retornoTarifacao)
                                    //     }).catch((error) => {
                                    //         console.log(error)
                                    //     })
                                    // }).catch((error) => {
                                    //     console.log(error)
                                    // })
                                }).catch((error) => {
                                    console.log(error)
                                })
                        }).catch((error) => {
                            console.log(error)
                        })
                    }) 
                } catch (e) {
                    console.log(e);
                }     

                try {
                    this.aguardeResposta().then(() => {
                        // if(this.state.valor != '' && this.state.renda != ''){
                        if(this.state.renda != ''){
                                // Verifica se tem locatário solidário
                                if(this.state.pessoasBusca.length > 1){
                                    for(var i = 1; i < this.state.pessoasBusca.length; i++){
                                        console.log('Solidario: ' + i)
                                        // Chama a função para cadastrar os dados cadastrais de cada locatário solidário 
                                        this.solidarioDadosCadastrais(i)
    
                                        // Chama a função para início do cadastro e analise os dados financeiros de cada locatário solidário
                                        this.realizarConsultaSolidario(this.state.pessoasBusca[i].dado, this.state.pessoasBusca[i], this.state.pessoasBusca[i].residir)
                                    }
                                    // Chama a função para início do cadastro e analise os dados financeiros do locatário principal
                                    this.realizarConsulta()
                                    console.log('Realizar consulta')
                                }
                                // Caso não tenha solidário
                                else{
                                    console.log('Não há solidário')
                                    console.log('Realizar consulta')
                                    this.realizarConsulta()
                                }
                        }
                        else{
                            console.log('Campo(s) vazio(s)')
                            // alert('Campo(s) vazio(s)')
                            this.setState({mensagemModal: <div>
                                                            Campo(s) vazios(s)
                                                        </div>})
                            this.toggleAlert()
                            // console.log(this.state.valor)
                            console.log(this.state.renda)
                        }
                    })
                    
                } catch (e) {
                    console.log(e);
                }     
                // api_epar.get( '/clienteshasconsulta/774').then((retorno) => {
                //     console.log(JSON.parse(retorno.data.data[0].retorno_json).result.processos.itens.length)
                //     var atualizar = this.state.pessoasBusca
                //     var resultado = ''
                //     var motivo = ''
                //     if(JSON.parse(retorno.data.data[0].retorno_json).result.processos.itens.length === 0){
                //         atualizar[0].resultado += 'Aprovado, '
                //         atualizar[0].motivo = ''
                //         motivo = 'Aprovado'
                //         this.setState({pessoasBusca: atualizar})
                //         localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                //     }
                //     else{
                //         if(JSON.parse(retorno.data.data[0].retorno_json).result.processos.itens.length > 0){
                //             if(JSON.parse(retorno.data.data[0].retorno_json).result.processos.itens.map((e) => {
                //                 return e.assunto
                //             }).indexOf('PENAL') !== -1 || JSON.parse(retorno.data.data[0].retorno_json).result.processos.itens.map((e) => {
                //                 return e.localizacao.vara
                //             }).indexOf('CRIMINAL') !== -1){
                //                 atualizar[0].resultado += 'Negado, '
                //                 atualizar[0].motivo = '3, '
                //                 resultado = 'Negado'
                //                 motivo = '3'
                //                 this.setState({pessoasBusca: atualizar})
                //                 localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                //             }
                //         }
                //         else{
                //             atualizar[0].resultado += 'Aprovado com ressalva, '
                //             atualizar[0].motivo = ''
                //             resultado = 'Aprovado com ressalva'
                //             this.setState({pessoasBusca: atualizar})
                //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                //         }
                //     }
                //     console.log(atualizar[0].motivo)
                //     this.atualizarStatusMotivo(
                //         atualizar[0].cliente,
                //         3,
                //         this.state.consulta_id,
                //         null,
                //         resultado,
                //         motivo
                //     )

                //     if(JSON.parse(retorno.data.data[0].retorno_json).result.certidoes_negativas.itens.length > 0){
                //         if(JSON.parse(retorno.data.data[0].retorno_json).result.certidoes_negativas.itens.map((e) => {
                //             return e.fonte
                //         }).indexOf('AntecedentesTRF1') !== -1){
                //             var index = JSON.parse(retorno.data.data[0].retorno_json).result.certidoes_negativas.itens.map((e) => {
                //                 return e.fonte
                //             }).indexOf('AntecedentesTRF1')

                //             if(JSON.parse(retorno.data.data[0].retorno_json).result.certidoes_negativas.itens[index].nada_consta === true){
                //                 console.log('Nada consta')
                //             }
                //             else{
                //                 var atualizar = this.state.pessoasBusca
                //                 atualizar[0].resultado += 'Aprovado com ressalva, '
                //                 atualizar[0].motivo = ''
                //                 this.setState({pessoasBusca: atualizar})
                //                 localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))

                //                 this.atualizarStatusMotivo(
                //                     atualizar[0].cliente,
                //                     3,
                //                     this.state.consulta_id,
                //                     null,
                //                     atualizar[0].resultado,
                //                     atualizar[0].motivo = ''
                //                 )
                //             }
                //         }
                //         else{
                //             console.log('Sem antecedentes')
                //         }
                //     }
                //     else{
                //         console.log('Sem certidões')
                //     }
                // }).catch((error) => {
                //     console.log(error)
                // })


                // // Chama a função para buscar os dados judiciais dos locatários
                // for(var j = 0; j < this.state.pessoasBusca.length; j++){
                //     this.consultaIdWall(j)
                // }
                // console.log(this.state.pessoasBusca.length)

                // Função de teste
                // for(var j = 0; j < this.state.pessoasBusca.length; j++){
                //     this.aguardarIDWALL(j)
                // }

                // this.aguardeScore().then(() => {
                //     this.analisarScore()
                // })

        }
        else{
            console.log('Principal sem CPF')
            // alert('Locatário Principal sem CPF')
            this.setState({mensagemModal: <div>
                                            Campo(s) vazios(s)
                                        </div>})
            this.toggleAlert()
        }
    }

    // Identifica qual é o tipo de consulta
    realizarConsulta = () => {
        api_epar.get( '/tipoconsulta/' + this.state.tipo_consulta).then((retornoTipoConsulta) => {
            console.log(retornoTipoConsulta.data.data[0])
            if(retornoTipoConsulta.data.data[0].descricao === 'Procob - Dados Financeiros'){
                // Função que irá cadastrar e analisar os dados financeiros do locatário principal 
                this.consultaProcob()
            }
            else{
                console.log('Falta desenvolver')
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    // Identifica qual é o tipo de consulta
    realizarConsultaSolidario = (dado, id_cliente, residir) => {
        api_epar.get( '/tipoconsulta/' + this.state.tipo_consulta).then((retornoTipoConsulta) => {
            console.log(retornoTipoConsulta.data.data[0])
            if(retornoTipoConsulta.data.data[0].descricao === 'Procob - Dados Financeiros'){
                console.log(id_cliente.cliente)
                // Função que irá cadastrar e analisar os dados financeiros dos solidários 
                this.consultaProcobSolidario(dado, id_cliente.cliente, residir)
            }
            else{
                console.log('Falta desenvolver')
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    // Realiza a consulta dos dados financeiros do PRINCIPAL / estado de aprovação
    consultaProcob = () => {
        var sistema = 'AlugMais'
        // if(JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao === 'master'){
        //     sistema = 'Econsult'
        // }
        // else{
        //     sistema = 'AlugMais'
        // }

        while (!(this.state.cliente_id && this.state.consulta_id)){
            console.log("Aguardando dados...");    
        }

        // Busca os dados financeiros
        api_epar.get( '/procobfinanceiro/' + this.state.dado.replace(/\D/g, '') + '/' + sistema + '/' + this.state.cliente_id + '/' + this.state.consulta_id).then((response) => {
            response.data.data.usuario = JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            response.data.data.cliente_id = this.state.cliente_id
            console.log(this.state.dado)
            var busca30
            if(response.data.local === 'BANCO'){
                busca30 = 1
            }
            else{
                busca30 = 0
            }

            FuncoesProcob.inserirProcobConsulta(response.data.data, this.state.dado).then((retornoConsulta) => {
                this.aguarde().then(() => {  
                    // Cadastra os dados financeiros e realiza a analise 
                    var dadosPessoas = this.state.pessoasBusca 
                    // api_epar.put('/consultascore', {
                    //     valor: response.data.data.score.valor,
                    //     nota: this.state.porcentagemScore,
                    //     status: response.data.data.score.analise === 'Negado' ? 0 : response.data.data.score.analise === 'Aprovado com ressalva' ? 1 : response.data.data.score.analise === 'Aprovado' ? 2 : null,
                    //     id: this.state.consulta_id 
                    // })
                    // api_epar.put('/consultapendencia', {
                    //     valor: response.data.data.pendenciasAnalise.valor,
                    //     nota: dadosPessoas[0].renda * this.state.porcentagemCarta * this.state.porcentagemPendencias,
                    //     status: response.data.data.pendenciasAnalise.analise === 'Negado' ? 0 : response.data.data.pendenciasAnalise.analise === 'Aprovado com ressalva' ? 1 : response.data.data.pendenciasAnalise.analise === 'Aprovado' ? 2 : null,
                    //     id: this.state.consulta_id 
                    // })
                    this.inserirConsultaFinanceiro(response.data.data, retornoConsulta.data.data, busca30).then(() => {
                        var atualizar = this.state.pessoasBusca
                        atualizar[0].resultado += response.data.data.score.analise + ', ' + response.data.data.pendenciasAnalise.analise + ', '
                        atualizar[0].motivo += response.data.data.score.motivo + ', ' + response.data.data.pendenciasAnalise.motivo + ', '
                        // api_epar.put('/consultastatus', {
                        //     item_valor: 'score, pendencias_financeiras',
                        //     item_nota: `${response.data.data.score.valor}; ${response.data.data.pendenciasAnalise.valor}`,
                        //     item_status: `${response.data.data.score.analise}; ${response.data.data.pendenciasAnalise.analise}`,
                        //     status_final: (response.data.data.score.analise.indexOf('Negado') > -1 || response.data.data.pendenciasAnalise.analise.indexOf('Negado') > -1) ? 'Negado' :
                        //                   (response.data.data.score.analise.indexOf('Aprovado com ressalva') > -1 || response.data.data.pendenciasAnalise.analise.indexOf('Aprovado com ressalva') > -1) ? 'Aprovado com ressalva' :
                        //                   'Aprovado',
                        //     id: this.state.consulta_id 
                        // })
                        if(response.data.data.score.analise === 'Negado'){
                            this.analisarScore()
                            atualizar[0].concluido += 1
                        }
                        else{
                            atualizar[0].concluido += 3
                        }
                        this.setState({pessoasBusca: atualizar})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        // this.analisarScore()
                        // var passou = 0  
                        // // Busca a soma das pendências
                        // api_epar.get( '/somapendencias/' + retornoConsulta.data.data).then((soma) => {
                        //     passou += 1
                        //     console.log(passou + ' / Pendencias ')
                        //     console.log(retornoConsulta.data.data)
                        //     console.log(soma.data.data[0].soma)
                        //     var atualizar = this.state.pessoasBusca
                        //     if(soma.data.data[0].soma !== null){
                                
                        //         // Caso as somas das pendências é maior que 50% do valor de aluguel
                        //         // if(soma.data.data[0].soma > this.state.valor*0.5){
                        //         if(soma.data.data[0].soma > this.state.renda*this.state.porcentagemCarta*this.state.porcentagemPendencias){
                        //             console.log('Negado')
                        //             console.log('Cliente: ' + this.state.cliente_id)
                        //             // var atualizar = this.state.pessoasBusca
                        //             atualizar[0].resultado += 'Negado, '
                        //             atualizar[0].motivo += '1, '
                        //             // atualizar[0].concluido = passou
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //             this.atualizarStatusMotivo(
                        //                 this.state.cliente_id,
                        //                 this.state.tipo_consulta,
                        //                 this.state.consulta_id,
                        //                 retornoConsulta.data.data,
                        //                 atualizar[0].resultado,
                        //                 atualizar[0].motivo
                        //             )
                        //         }
                        //         //Caso tenha pendências mas é menor que 50% do valor do aluguel
                        //         else{
                        //             console.log('Aprovado com ressalva')
                        //             // var atualizar = this.state.pessoasBusca
                        //             atualizar[0].resultado += 'Aprovado com ressalva, '
                        //             atualizar[0].motivo += '10, '
                        //             // atualizar[0].concluido = passou
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //             this.atualizarStatusMotivo(
                        //                 this.state.cliente_id,
                        //                 this.state.tipo_consulta,
                        //                 this.state.consulta_id,
                        //                 retornoConsulta.data.data,
                        //                 atualizar[0].resultado,
                        //                 atualizar[0].motivo
                        //             )
                        //         }
                        //     // }

                        //     // // Analisar o score
                        //     // api_epar.get( '/procobscore/consulta/' + retornoConsulta.data.data).then((score) => {
                        //     //     console.log('score')
                        //     //     console.log(score.data.data)
                        //     //     passou += 1
                        //     //     // console.log(passou + ' / Score ')

                        //     //     //Verifica se tem score
                        //     //     if(score.data.data.length === 0 ){
                        //     //         console.log('sem score')
                        //     //         var atualizar = this.state.pessoasBusca
                        //     //         atualizar[0].resultado += 'Aprovado, '
                        //     //         atualizar[0].motivo += ''
                        //     //         // atualizar[0].concluido = passou
                        //     //         this.setState({pessoasBusca: atualizar})
                        //     //         localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     //     }
                        //     //     else{
                        //     //         console.log('com score')
                        //     //         var atualizar = this.state.pessoasBusca

                        //     //         // Percentual do score maior que 50%
                        //     //         if(parseFloat(score.data.data[0].percentual) > 50){
                        //     //             atualizar[0].resultado += 'Aprovado, '
                        //     //             atualizar[0].motivo += ''
                        //     //             // atualizar[0].concluido = passou
                        //     //             this.setState({pessoasBusca: atualizar})
                        //     //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     //             this.atualizarStatusMotivo(
                        //     //                 this.state.cliente_id,
                        //     //                 this.state.tipo_consulta,
                        //     //                 this.state.consulta_id,
                        //     //                 retornoConsulta.data.data,
                        //     //                 atualizar[0].resultado,
                        //     //                 atualizar[0].motivo
                        //     //             )
                        //     //         }
                        //     //         // Percentual do score menor que 50% e maior que 30%
                        //     //         else if(parseFloat(score.data.data[0].percentual) > 30){
                        //     //             atualizar[0].resultado += 'Aprovado com ressalva, '
                        //     //             atualizar[0].motivo += '20, '
                        //     //             // atualizar[0].concluido = passou
                        //     //             this.setState({pessoasBusca: atualizar})
                        //     //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     //             this.atualizarStatusMotivo(
                        //     //                 this.state.cliente_id,
                        //     //                 this.state.tipo_consulta,
                        //     //                 this.state.consulta_id,
                        //     //                 retornoConsulta.data.data,
                        //     //                 atualizar[0].resultado,
                        //     //                 atualizar[0].motivo
                        //     //             )
                        //     //         }
                        //     //         // Percentual do score menor que 30%
                        //     //         else if(parseFloat(score.data.data[0].percentual) < 30){
                        //     //             atualizar[0].resultado += 'Negado, '
                        //     //             atualizar[0].motivo += '2, '
                        //     //             // atualizar[0].concluido = passou
                        //     //             this.setState({pessoasBusca: atualizar})
                        //     //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     //             this.atualizarStatusMotivo(
                        //     //                 this.state.cliente_id,
                        //     //                 this.state.tipo_consulta,
                        //     //                 this.state.consulta_id,
                        //     //                 retornoConsulta.data.data,
                        //     //                 atualizar[0].resultado,
                        //     //                 atualizar[0].motivo
                        //     //             )

                        //     //             // Envia email por negação de score
                        //     //             var html = '<div>'
                        //     //                     +'Prezado Administrador Alug+, uma nova consulta foi realizada com os seguintes parâmetros: <br/><br/>'
                        //     //                     +'Identificador da consulta: ' + this.state.consulta_id + '<br/>'
                        //     //                     +'Imobiliária: ' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria + '<br/>'
                        //     //                     +'Locatário ' + atualizar[0].quem + ': ' + atualizar[0].nome + '<br/>'
                        //     //                     +'Valor da renda informada: ' + FuncoesComum.formatarMoeda(parseFloat(atualizar[0].renda)) + '<br/>'
                        //     //                     +'Valor da locação: ' + FuncoesComum.formatarMoeda(parseFloat(this.state.valor)) + '<br/><br/>'
                        //     //                     +'Esse email é um aviso que a consulta referente aos dados acima, foi negada devido score muito baixo.<br/>'
                        //     //                 +'</div>'
                        //     //                 api_epar.post( '/sendemail', {
                        //     //                     subject: 'Consulta negada por score',
                        //     //                     html: html,
                        //     //                 }).then((retorno) => {
                        //     //                     console.log(retorno)
                        //     //                 })
                        //     //         }
                        //     //     }

                        //     //     // Analisa o comprometimento mensal estimado porém não está sendo usado
                        //     //     api_epar.get( '/clienteshasconsultadetalhe/' + this.state.consulta_id).then((comprometimento) => {
                        //     //         var atualizar = this.state.pessoasBusca
                        //     //         var index = 0
                        //     //         for(var k = 0; k < comprometimento.data.data.length; k++){
                        //     //             if(comprometimento.data.data[k].clientes_id === atualizar[0].cliente){
                        //     //                 if(comprometimento.data.data[k].tipo_consulta_id === 1){
                        //     //                     index = k
                        //     //                 }
                        //     //             }
                        //     //         }
                        //     //         passou += 1
                        //     //         // console.log(passou + ' / Comprometimento ')
                        //     //         console.log(JSON.parse(comprometimento.data.data[index].retorno_json).content.comprometimento_mensal_estimado)
                        //     //         if(JSON.parse(comprometimento.data.data[index].retorno_json).content.comprometimento_mensal_estimado){
                        //     //             if(JSON.parse(comprometimento.data.data[index].retorno_json).content.comprometimento_mensal_estimado.conteudo.percentual < 50){
                        //     //                 atualizar[0].resultado += 'Aprovado, '
                        //     //                 atualizar[0].motivo += ''
                        //     //                 // atualizar[0].concluido = passou
                        //     //                 this.setState({pessoasBusca: atualizar})
                        //     //                 localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     //                 this.atualizarStatusMotivo(
                        //     //                     atualizar[0].cliente,
                        //     //                     this.state.tipo_consulta,
                        //     //                     this.state.consulta_id,
                        //     //                     retornoConsulta.data.data,
                        //     //                     atualizar[0].resultado,
                        //     //                     atualizar[0].motivo
                        //     //                 )
                        //     //             }
                        //     //             else{
                        //     //                 atualizar[0].resultado += 'Aprovado com ressalva, '
                        //     //                 atualizar[0].motivo += '40, '
                        //     //                 // atualizar[0].concluido = passou
                        //     //                 this.setState({pessoasBusca: atualizar})
                        //     //                 localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     //                 this.atualizarStatusMotivo(
                        //     //                     atualizar[0].cliente,
                        //     //                     this.state.tipo_consulta,
                        //     //                     this.state.consulta_id,
                        //     //                     retornoConsulta.data.data,
                        //     //                     atualizar[0].resultado,
                        //     //                     atualizar[0].motivo
                        //     //                 )
                        //     //             }
                        //     //         }
                        //             atualizar[0].concluido += passou
                        //             console.log('Somou 1 soma pendências')
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     }
                        //     else{
                        //         atualizar[0].concluido += passou
                        //         console.log('Somou 1 soma pendências')
                        //         this.setState({pessoasBusca: atualizar})
                        //         localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     }
                        //     //     }).catch((error) => {
                        //     //         console.log(error)
                        //     //     })          
                        //     // }).catch((error) => {
                        //     //     console.log(error)
                        //     // }) 
                        // }).catch((error) => {
                        //     console.log(error)
                        // })    
                        // api_epar.get( '/procobscore/' + retornoConsulta.data.data).then((score) => {
                        //     console.log('score')
                        //     console.log(score.data.data)
                        //     passou += 1
                        //     console.log(passou + ' / Score ')
                        //     if(score.data.data.length === 0){
                        //         console.log('sem score')
                        //         var atualizar = this.state.pessoasBusca
                        //         atualizar[0].resultado += 'Aprovado, '
                        //         atualizar[0].motivo += ''
                        //         atualizar[0].concluido = passou
                        //         this.setState({pessoasBusca: atualizar})
                        //         localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //     }
                        //     else{
                        //         console.log('com score')
                        //         var atualizar = this.state.pessoasBusca
                        //         if(parseFloat(score.data.data[0].percentual) > 50){
                        //             atualizar[0].resultado += 'Aprovado, '
                        //             atualizar[0].motivo += ''
                        //             atualizar[0].concluido = passou
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //             this.atualizarStatusMotivo(
                        //                 this.state.cliente_id,
                        //                 this.state.tipo_consulta,
                        //                 this.state.consulta_id,
                        //                 retornoConsulta.data.data,
                        //                 atualizar[0].resultado,
                        //                 atualizar[0].motivo
                        //             )
                        //         }
                        //         else if(parseFloat(score.data.data[0].percentual) > 25){
                        //             atualizar[0].resultado += 'Aprovado com ressalva, '
                        //             atualizar[0].motivo += '20, '
                        //             atualizar[0].concluido = passou
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //             this.atualizarStatusMotivo(
                        //                 this.state.cliente_id,
                        //                 this.state.tipo_consulta,
                        //                 this.state.consulta_id,
                        //                 retornoConsulta.data.data,
                        //                 atualizar[0].resultado,
                        //                 atualizar[0].motivo
                        //             )
                        //         }
                        //         else if(parseFloat(score.data.data[0].percentual) < 25){
                        //             atualizar[0].resultado += 'Negado, '
                        //             atualizar[0].motivo += '2, '
                        //             atualizar[0].concluido = passou
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //             this.atualizarStatusMotivo(
                        //                 this.state.cliente_id,
                        //                 this.state.tipo_consulta,
                        //                 this.state.consulta_id,
                        //                 retornoConsulta.data.data,
                        //                 atualizar[0].resultado,
                        //                 atualizar[0].motivo
                        //             )
                        //         }
                        //     }
                        // }).catch((error) => {
                        //     console.log(error)
                        // }) 
                        // api_epar.get( '/clienteshasconsulta/' + retornoConsulta.data.data).then((comprometimento) => {
                        //     var atualizar = this.state.pessoasBusca
                        //     console.log(JSON.parse(comprometimento.data.data[0].retorno_json))
                        //     passou += 1
                        //     console.log(passou + ' / Comprometimento ')
                        //     if(JSON.parse(comprometimento.data.data[0].retorno_json).content.comprometimento_mensal_estimado){
                        //         if(JSON.parse(comprometimento.data.data[0].retorno_json).content.comprometimento_mensal_estimado.conteudo.percentual < 50){
                        //             atualizar[0].resultado += 'Aprovado, '
                        //             atualizar[0].motivo += ''
                        //             atualizar[0].concluido = passou
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //             this.atualizarStatusMotivo(
                        //                 atualizar[0].cliente,
                        //                 this.state.tipo_consulta,
                        //                 this.state.consulta_id,
                        //                 retornoConsulta.data.data,
                        //                 atualizar[0].resultado,
                        //                 atualizar[0].motivo
                        //             )
                        //         }
                        //         else{
                        //             atualizar[0].resultado += 'Aprovado com ressalva, '
                        //             atualizar[0].motivo += '40, '
                        //             atualizar[0].concluido = passou
                        //             this.setState({pessoasBusca: atualizar})
                        //             localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //             this.atualizarStatusMotivo(
                        //                 atualizar[0].cliente,
                        //                 this.state.tipo_consulta,
                        //                 this.state.consulta_id,
                        //                 retornoConsulta.data.data,
                        //                 atualizar[0].resultado,
                        //                 atualizar[0].motivo
                        //             )
                        //         }
                        //     }
                        // }).catch((error) => {
                        //     console.log(error)
                        // })          
                    }).catch((error) => {
                        console.log(error)
                    })
                })
            })
            console.log(response.data.data)
        }).catch((error) => {
          console.log(error)
        })
    }

    // Calcular a média do score
    analisarScore = () => {
        var score = 0
        var cont = 0
        for(var c = 0; c < this.state.pessoasBusca.length; c++){
            api_epar.post( '/trazerscore', {
                cliente: this.state.pessoasBusca[c].cliente,
                consulta: this.state.consulta_id
            }).then((retorno) => {
                cont++
                if(retorno.data.data.length > 0){
                    if(JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa && retorno.data.data[0].retorno_json && JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa.conteudo && JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa.conteudo.percentual){
                        score += parseFloat(JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa.conteudo.percentual.replace(',', '.'))
                    }
                }

                // console.log(cont === this.state.pessoasBusca.length)
                // console.log(score/this.state.pessoasBusca.length)

                console.log(score)
                console.log(this.state.porcentagemScore)

                // Caso seja menor que a porcentagem configurada, enviar EMAIL
                if(cont === this.state.pessoasBusca.length && parseFloat(score)/parseFloat(this.state.pessoasBusca.length) < parseFloat(this.state.porcentagemScore)){
                    for(var i = 0; i < this.state.pessoasBusca.length; i++){
                        this.enviarEmail(i)
                    }
                }
                else if(cont === this.state.pessoasBusca.length){
                    for(var i = 0; i < this.state.pessoasBusca.length; i++){
                        var atualizar = this.state.pessoasBusca
                        atualizar[i].concluido += 2
                        console.log('Somou 2 soma score')
                        console.log(atualizar[i].concluido)
                        this.setState({pessoasBusca: atualizar})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                    }
                }
            })
        }
    }

    enviarEmail = (indice) => {
        console.log('Entrou')
        var atualizar = this.state.pessoasBusca
        api_epar.post( '/trazerscore', {
            cliente: this.state.pessoasBusca[indice].cliente,
            consulta: this.state.consulta_id
        }).then((retorno) => {
            // console.log(JSON.parse(retorno.data.data[0].retorno_json))
            if(retorno.data.data.length > 0){
                if(retorno.data.data[0].retorno_json){
                    if(JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa && JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa.conteudo && (!JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa.conteudo.percentual || parseFloat(JSON.parse(retorno.data.data[0].retorno_json).content.score_serasa.conteudo.percentual.replace(',', '.')) < parseFloat(this.state.porcentagemScore))){
                        atualizar[indice].resultado += 'Negado, '
                        atualizar[indice].motivo += '2, '
                        atualizar[indice].concluido += 2
                        console.log('Somou 2 soma score')
                        this.setState({pessoasBusca: atualizar})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        // this.atualizarStatusMotivo(
                        //     atualizar[indice].cliente,
                        //     1,
                        //     this.state.consulta_id,
                        //     retorno.data.data[0].id_tabela_consulta,
                        //     atualizar[indice].resultado,
                        //     atualizar[indice].motivo
                        // )

                        // Envia email por negação de score
                        var html = '<div>'
                                +'Prezado Administrador Alug+, uma nova consulta foi realizada com os seguintes parâmetros: <br/><br/>'
                                +'Identificador da consulta: ' + this.state.consulta_id + '<br/>'
                                +'Imobiliária: ' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria + '<br/>'
                                +'Locatário ' + atualizar[indice].quem + ': ' + atualizar[indice].nome + '<br/>'
                                +'Valor da renda informada: ' + FuncoesComum.formatarMoeda(parseFloat(atualizar[indice].renda)) + '<br/>'
                                // +'Valor da locação: ' + FuncoesComum.formatarMoeda(parseFloat(this.state.valor)) + '<br/><br/>'
                                +'Esse email é um aviso que a consulta referente aos dados acima, foi negada devido score muito baixo.<br/>'
                            +'</div>'

                        api_epar.post( '/sendemail', {
                            subject: 'Consulta negada por score',
                            html: html,
                            extras: {
                                cliente: this.state.pessoasBusca[indice].cliente,
                                consulta: this.state.consulta_id
                            }
                        }).then((retorno) => {
                            console.log(retorno)
                        })
                    }
                    else{
                        atualizar[indice].concluido += 2
                        console.log('Somou 2 soma score')
                        this.setState({pessoasBusca: atualizar})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                    }
                }
            }
        })
    }

    aguarde = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                console.log('Esperar')
                resolve();
            }, 3000);
        });
    }

    aguardeResposta = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                console.log('Esperar')
                resolve();
            }, 2000);
        });
    }

    aguardeScore = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                console.log('Esperar SCORE')
                resolve();
            }, 15000);
        });
    }

    aguardeRespostaParaIdwall = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                console.log('Esperar')
                resolve();
            }, 3000);
        });
    }

    // Após retorno e analise é feita a atualização no banco 
    atualizarStatusMotivo = (cliente, tipo_consulta, consulta, tabela_consulta, status, motivo) => {
        api_epar.put( '/clienteshasconsulta', {
            clientes_id: cliente,
            tipo_consulta_id: tipo_consulta,
            consulta_id: consulta,
            id_tabela_consulta: tabela_consulta,
            status: status,
            motivo: motivo
        }).then((clienteConsultaAtualizada) => {
            console.log(clienteConsultaAtualizada)
        }).catch((error) => {
            console.log(error)
        })
    }

    // Após retorno e analise é feita a atualização no banco 
    atualizarStatusMotivoIdwall = (cliente, tipo_consulta, consulta, tabela_consulta, status, motivo) => {
        api_epar.put( '/clienteshasconsultaidwall', {
            clientes_id: cliente,
            tipo_consulta_id: tipo_consulta,
            consulta_id: consulta,
            id_tabela_consulta: tabela_consulta,
            status: status,
            motivo: motivo
        }).then((clienteConsultaAtualizada) => {
            console.log(clienteConsultaAtualizada)
        }).catch((error) => {
            console.log(error)
        })
    }

    // Realiza a consulta dos dados financeiros dos SOLIDÁRIOS / estado de aprovação
    consultaProcobSolidario = (dado, id_cliente, residir) => {
        var sistema = 'AlugMais'
        // if(JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao === 'master'){
        //     sistema = 'Econsult'
        // }
        // else{
        //     sistema = 'AlugMais'
        // }
        // Busca os dados financeiros
        api_epar.get( '/procobfinanceiro/' + dado.replace(/\D/g, '') + '/' + sistema + '/' + id_cliente + '/' + this.state.consulta_id).then((response) => {
            response.data.data.usuario = JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            response.data.data.cliente_id = id_cliente
            var busca30
            if(response.data.local === 'BANCO'){
                busca30 = 1
            }
            else{
                busca30 = 0
            }
            // console.log(id_cliente)
            FuncoesProcob.inserirProcobConsulta(response.data.data, dado).then((retornoConsulta) => {
                this.aguarde().then(() => {
                    // Cadastra os dados financeiros e realiza a analise 
                    this.inserirConsultaFinanceiroSolidario(response.data.data, retornoConsulta.data.data, id_cliente, busca30, residir).then(() => {
                        var passou = 0
                        // Busca a soma das pendências
                        api_epar.get( '/somapendencias/' + retornoConsulta.data.data).then((soma) => {       
                            console.log(soma.data.data[0].soma)
                            passou += 1
                            var atualizar = this.state.pessoasBusca
                            for(var i = 1; i < this.state.pessoasBusca.length; i++){
                                if(this.state.pessoasBusca[i].cliente === id_cliente){
                                    if(soma.data.data[0].soma !== null){
                                        // Caso as somas das pendências é maior que 50% do valor de aluguel
                                        // if(soma.data.data[0].soma > this.state.valor*0.5){
                                        if(soma.data.data[0].soma > this.state.renda*this.state.porcentagemCarta*this.state.porcentagemPendencias){
                                            console.log('Negado')
                                            // console.log('Cliente: ' + this.state.cliente_id)
                                            atualizar[i].resultado += 'Negado, '
                                            atualizar[i].motivo += '1,'
                                            this.setState({pessoasBusca: atualizar})
                                            localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                            this.atualizarStatusMotivo(
                                                atualizar[i].cliente,
                                                this.state.tipo_consulta,
                                                this.state.consulta_id,
                                                retornoConsulta.data.data,
                                                atualizar[i].resultado,
                                                atualizar[i].motivo
                                            )
                                        }
                                        //Caso tenha pendências mas é menor que 50% do valor do aluguel
                                        else{
                                            console.log('Aprovado com ressalva')
                                            // var atualizar = this.state.pessoasBusca
                                            atualizar[i].resultado += 'Aprovado com ressalva, '
                                            atualizar[i].motivo += '10, '
                                            this.setState({pessoasBusca: atualizar})
                                            localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                            this.atualizarStatusMotivo(
                                                atualizar[i].cliente,
                                                this.state.tipo_consulta,
                                                this.state.consulta_id,
                                                retornoConsulta.data.data,
                                                atualizar[i].resultado,
                                                atualizar[i].motivo
                                            )
                                        }
                                    }
                                    // var atualizar = this.state.pessoasBusca
                                    atualizar[i].concluido += passou
                                    console.log('Somou 1 soma pendências')
                                    // console.log('Solidário ' + i + ': ' + atualizar[i].concluido)
                                    this.setState({pessoasBusca: atualizar})
                                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                    break
                                }
                            }

                            // // Analisar o score
                            // api_epar.get( '/procobscore/consulta/' + retornoConsulta.data.data).then((score) => {
                            //     console.log('score')
                            //     console.log(score.data.data)
                            //     passou += 1
                            //     //Verifica se tem score
                            //     if(score.data.data.length === 0){
                            //         console.log('sem score')
                            //     }
                            //     else{
                            //         console.log('com score')
                            //         for(var i = 1; i < this.state.pessoasBusca.length; i++){
                            //             if(this.state.pessoasBusca[i].cliente === id_cliente){
                            //                 var atualizar = this.state.pessoasBusca

                            //                 // Percentual do score maior que 50%
                            //                 if(parseFloat(score.data.data[0].percentual) > 50){
                            //                     atualizar[i].resultado += 'Aprovado, '
                            //                     atualizar[i].motivo += ''
                            //                     this.setState({pessoasBusca: atualizar})
                            //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                            //                     this.atualizarStatusMotivo(
                            //                         atualizar[i].cliente,
                            //                         this.state.tipo_consulta,
                            //                         this.state.consulta_id,
                            //                         retornoConsulta.data.data,
                            //                         atualizar[i].resultado,
                            //                         atualizar[i].motivo
                            //                     )
                            //                 }

                            //                 // Percentual do score menor que 50% e maior que 30%
                            //                 else if(parseFloat(score.data.data[0].percentual) > 30){
                            //                     atualizar[i].resultado += 'Aprovado com ressalva, '
                            //                     atualizar[i].motivo += '20, '
                            //                     this.setState({pessoasBusca: atualizar})
                            //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                            //                     this.atualizarStatusMotivo(
                            //                         atualizar[i].cliente,
                            //                         this.state.tipo_consulta,
                            //                         this.state.consulta_id,
                            //                         retornoConsulta.data.data,
                            //                         atualizar[i].resultado,
                            //                         atualizar[i].motivo
                            //                     )
                            //                 }

                            //                 // Percentual do score menor que 30%
                            //                 else if(parseFloat(score.data.data[0].percentual) < 30){
                            //                     atualizar[i].resultado += 'Negado, '
                            //                     atualizar[i].motivo += '2, '
                            //                     this.setState({pessoasBusca: atualizar})
                            //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                            //                     this.atualizarStatusMotivo(
                            //                         atualizar[i].cliente,
                            //                         this.state.tipo_consulta,
                            //                         this.state.consulta_id,
                            //                         retornoConsulta.data.data,
                            //                         atualizar[i].resultado,
                            //                         atualizar[i].motivo
                            //                     )

                            //                     // Envia email por negação de score
                            //                     var html = '<div>'
                            //                         +'Prezado Administrador Alug+, uma nova consulta foi realizada com os seguintes parâmetros: <br/><br/>'
                            //                         +'Identificador da consulta: ' + this.state.consulta_id + '<br/>'
                            //                         +'Imobiliária: ' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria + '<br/>'
                            //                         +'Locatário ' + atualizar[i].quem + ': ' + atualizar[i].nome + '<br/>'
                            //                         +'Valor da renda informada: ' + FuncoesComum.formatarMoeda(parseFloat(atualizar[i].renda)) + '<br/>'
                            //                         +'Valor da locação: ' + FuncoesComum.formatarMoeda(parseFloat(this.state.valor)) + '<br/><br/>'
                            //                         +'Esse email é um aviso que a consulta referente aos dados acima, foi negada devido score muito baixo.<br/>'
                            //                     +'</div>'
                            //                     api_epar.post( '/sendemail', {
                            //                         subject: 'Consulta negada por score',
                            //                         html: html,
                            //                     }).then((retorno) => {
                            //                         console.log(retorno)
                            //                     })
                            //                 }
                            //             }
                            //         }
                            //     }

                            //     // Analisa o comprometimento mensal estimado porém não está sendo usado
                            //     api_epar.get( '/clienteshasconsultadetalhe/' + this.state.consulta_id).then((comprometimento) => {
                            //         var atualizar = this.state.pessoasBusca
                            //         var index = 0
                            //         for(var i = 1; i < this.state.pessoasBusca.length; i++){
                            //             if(this.state.pessoasBusca[i].cliente === id_cliente){
                            //                 for(var k = 0; k < comprometimento.data.data.length; k++){
                            //                     if(comprometimento.data.data[i].clientes_id === atualizar[0].cliente){
                            //                         if(comprometimento.data.data[i].tipo_consulta_id === 1){
                            //                             index = k
                            //                         }
                            //                     }
                            //                 }
                            //                 passou += 1
                            //                 // console.log(passou + ' / Comprometimento ')
                            //                 console.log(JSON.parse(comprometimento.data.data[index].retorno_json).content.comprometimento_mensal_estimado)
                            //                 if(JSON.parse(comprometimento.data.data[index].retorno_json).content.comprometimento_mensal_estimado){
                            //                     if(JSON.parse(comprometimento.data.data[index].retorno_json).content.comprometimento_mensal_estimado.conteudo.percentual < 50){
                            //                         atualizar[i].resultado += 'Aprovado, '
                            //                         atualizar[i].motivo += ''
                            //                         this.setState({pessoasBusca: atualizar})
                            //                         localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                            //                         this.atualizarStatusMotivo(
                            //                             atualizar[i].cliente,
                            //                             this.state.tipo_consulta,
                            //                             this.state.consulta_id,
                            //                             retornoConsulta.data.data,
                            //                             atualizar[i].resultado,
                            //                             atualizar[i].motivo
                            //                         )
                            //                     }
                            //                     else{
                            //                         atualizar[i].resultado += 'Aprovado com ressalva, '
                            //                         atualizar[i].motivo += '40, '
                            //                         this.setState({pessoasBusca: atualizar})
                            //                         localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                            //                         this.atualizarStatusMotivo(
                            //                             atualizar[i].cliente,
                            //                             this.state.tipo_consulta,
                            //                             this.state.consulta_id,
                            //                             retornoConsulta.data.data,
                            //                             atualizar[i].resultado,
                            //                             atualizar[i].motivo
                            //                         )
                            //                     }
                            //                 }
                                            // atualizar[i].concluido += passou
                                            // this.setState({pessoasBusca: atualizar})
                                            // localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                            //             }
                            //         }
                                    
                            //     }).catch((error) => {
                            //         console.log(error)
                            //     })
                            // }).catch((error) => {
                            //     console.log(error)
                            // }) 
                        }).catch((error) => {
                            console.log(error)
                        })
                        // api_epar.get( '/procobscore/' + retornoConsulta.data.data).then((score) => {
                        //     console.log('score')
                        //     console.log(score.data.data)
                        //     if(score.data.data.length === 0){
                        //         console.log('sem score')
                        //     }
                        //     else{
                        //         console.log('com score')
                        //         for(var i = 1; i < this.state.pessoasBusca.length; i++){
                        //             if(this.state.pessoasBusca[i].cliente === id_cliente){
                        //                 var atualizar = this.state.pessoasBusca
                        //                 if(parseFloat(score.data.data[0].percentual) > 50){
                        //                     atualizar[i].resultado += 'Aprovado, '
                        //                     atualizar[i].motivo += ''
                        //                     this.setState({pessoasBusca: atualizar})
                        //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //                     this.atualizarStatusMotivo(
                        //                         atualizar[i].cliente,
                        //                         this.state.tipo_consulta,
                        //                         this.state.consulta_id,
                        //                         retornoConsulta.data.data,
                        //                         atualizar[i].resultado,
                        //                         atualizar[i].motivo
                        //                     )
                        //                 }
                        //                 else if(parseFloat(score.data.data[0].percentual) > 25){
                        //                     atualizar[i].resultado += 'Aprovado com ressalva, '
                        //                     atualizar[i].motivo += '20, '
                        //                     this.setState({pessoasBusca: atualizar})
                        //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //                     this.atualizarStatusMotivo(
                        //                         atualizar[i].cliente,
                        //                         this.state.tipo_consulta,
                        //                         this.state.consulta_id,
                        //                         retornoConsulta.data.data,
                        //                         atualizar[i].resultado,
                        //                         atualizar[i].motivo
                        //                     )
                        //                 }
                        //                 else if(parseFloat(score.data.data[0].percentual) < 25){
                        //                     atualizar[i].resultado += 'Negado, '
                        //                     atualizar[i].motivo += '2, '
                        //                     this.setState({pessoasBusca: atualizar})
                        //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //                     this.atualizarStatusMotivo(
                        //                         atualizar[i].cliente,
                        //                         this.state.tipo_consulta,
                        //                         this.state.consulta_id,
                        //                         retornoConsulta.data.data,
                        //                         atualizar[i].resultado,
                        //                         atualizar[i].motivo
                        //                     )
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }).catch((error) => {
                        //     console.log(error)
                        // }) 
                        // api_epar.get( '/clienteshasconsulta/' + retornoConsulta.data.data).then((comprometimento) => {
                        //     for(var i = 1; i < this.state.pessoasBusca.length; i++){
                        //         if(this.state.pessoasBusca[i].cliente === id_cliente){
                        //             var atualizar = this.state.pessoasBusca
                        //             if(JSON.parse(comprometimento.data.data[0].retorno_json).content.comprometimento_mensal_estimado){
                        //                 if(JSON.parse(comprometimento.data.data[0].retorno_json).content.comprometimento_mensal_estimado.conteudo.percentual < 50){
                        //                     atualizar[i].resultado += 'Aprovado, '
                        //                     atualizar[i].motivo += ''
                        //                     this.setState({pessoasBusca: atualizar})
                        //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //                     this.atualizarStatusMotivo(
                        //                         atualizar[i].cliente,
                        //                         this.state.tipo_consulta,
                        //                         this.state.consulta_id,
                        //                         retornoConsulta.data.data,
                        //                         atualizar[i].resultado,
                        //                         atualizar[i].motivo
                        //                     )
                        //                 }
                        //                 else{
                        //                     atualizar[i].resultado += 'Aprovado com ressalva, '
                        //                     atualizar[i].motivo += '40, '
                        //                     this.setState({pessoasBusca: atualizar})
                        //                     localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        //                     this.atualizarStatusMotivo(
                        //                         atualizar[i].cliente,
                        //                         this.state.tipo_consulta,
                        //                         this.state.consulta_id,
                        //                         retornoConsulta.data.data,
                        //                         atualizar[i].resultado,
                        //                         atualizar[i].motivo
                        //                     )
                        //                 }
                        //             }
                        //         }
                        //     }
                            
                        // }).catch((error) => {
                        //     console.log(error)
                        // })       
                    }).catch((error) => {
                        console.log(error)
                    })
                })
            })
            console.log(response.data.data)
        }).catch((error) => {
          console.log(error)
        })
    }

    // Insere a consulta GERAL e o cliente (principal) que está relacionado diretamente
    inserirConsultaFinanceiro = async (consulta, consulta_id, busca30) => {
        this.setState({resultado: [{
            nome: consulta.content.confirmei.conteudo.nome_razao,
            info: this.state.dado,
            id: this.state.consulta_id
        }]})
        api_epar.post( '/clienteshasconsulta/', {
            clientes_id: this.state.cliente_id,
            consulta_id: this.state.consulta_id,
            tipo_consulta_id: this.state.tipo_consulta,
            id_tabela_consulta: consulta_id,
            data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
            tipo_locatario: 'Principal',
            busca30: busca30,
            status: consulta.score.analise + ', ' + consulta.pendenciasAnalise.analise + ', ',
            motivo: consulta.score.motivo + ', ' + consulta.pendenciasAnalise.motivo + ', ',
            retorno_json: JSON.stringify(consulta)
        }).then((retornoClienteConsulta) => {
            console.log(retornoClienteConsulta)
            // api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/' + this.state.tipo_consulta).then((dadoCompetencia) => {
            //     console.log(dadoCompetencia.data.data[0])
            //     api_epar.post( '/tarifacao/', {
            //         data: moment().format('YYYY-MM-DD HH:mm:ss'),
            //         valor: dadoCompetencia.data.data[0].valor_cobrar,
            //         tipo: 'Débito',
            //         clientes_has_consulta_id: retornoClienteConsulta.data.data
            //     }).then((retornoTarifacao) => {
            //         console.log(retornoTarifacao)
            //     }).catch((error) => {
            //         console.log(error)
            //     })
            // }).catch((error) => {
            //     console.log(error)
            // })
        }).catch((error) => {
            console.log(error)
        })
    }

    // Insere a consulta GERAL e os clientes (solidários) que estão relacionados diretamente
    inserirConsultaFinanceiroSolidario = async (consulta, consulta_id, cliente, busca30, residir) => {
        api_epar.post( '/clienteshasconsulta', {
            clientes_id: cliente,
            consulta_id: this.state.consulta_id,
            tipo_consulta_id: this.state.tipo_consulta,
            id_tabela_consulta: consulta_id,
            data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
            tipo_locatario: 'Solidário',
            busca30: busca30,
            residir: residir,
            retorno_json: JSON.stringify(consulta)
        }).then((retornoClienteConsulta) => {
            console.log(retornoClienteConsulta)
            // api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/' + this.state.tipo_consulta).then((dadoCompetencia) => {
            //     console.log(dadoCompetencia.data.data[0])
            //     api_epar.post( '/tarifacao/', {
            //         data: moment().format('YYYY-MM-DD HH:mm:ss'),
            //         valor: dadoCompetencia.data.data[0].valor_cobrar,
            //         tipo: 'Débito',
            //         clientes_has_consulta_id: retornoClienteConsulta.data.data
            //     }).then((retornoTarifacao) => {
            //         console.log(retornoTarifacao)
            //     }).catch((error) => {
            //         console.log(error)
            //     })
            // }).catch((error) => {
            //     console.log(error)
            // })
        }).catch((error) => {
            console.log(error)
        })
    }

    // Cadastrar os dados cadastrais dos solidários
    solidarioDadosCadastrais = async (indice) => {
        return (
            api_epar.get( '/procobcadastrais/' + this.state.pessoasBusca[indice].dado.replace(/\D/g, '') + '/' + this.state.pessoasBusca[indice].cliente).then((dadoCliente) => {
                console.log(dadoCliente.data.data)
                var atualizar = this.state.pessoasBusca
                atualizar[indice].nome = dadoCliente.data.data.content.nome.conteudo.nome
                this.setState({pessoasBusca: atualizar})
                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                var busca30
                if(dadoCliente.data.local === 'BANCO'){
                    busca30 = 1
                }
                else{
                    busca30 = 0
                }
                api_epar.post( '/clienteshasconsulta/', {
                    clientes_id: this.state.pessoasBusca[indice].cliente,
                    consulta_id: this.state.consulta_id,
                    tipo_consulta_id: 2,
                    id_tabela_consulta: null,
                    data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                    tipo_locatario: 'Solidário',
                    busca30: busca30,
                    residir: atualizar[indice].residir,
                    retorno_json: JSON.stringify(dadoCliente.data.data)
                }).then((retornoClienteConsulta) => {
                    console.log(retornoClienteConsulta)
                    // api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/2').then((dadoCompetencia) => {
                    //     console.log(dadoCompetencia.data.data)
                    //     api_epar.post( '/tarifacao/', {
                    //         data: moment().format('YYYY-MM-DD HH:mm:ss'),
                    //         valor: dadoCompetencia.data.data[0].valor_cobrar,
                    //         tipo: 'Débito',
                    //         clientes_has_consulta_id: retornoClienteConsulta.data.data
                    //     }).then((retornoTarifacao) => {
                    //         console.log(retornoTarifacao)
                    //     }).catch((error) => {
                    //         console.log(error)
                    //     })
                    // }).catch((error) => {
                    //     console.log(error)
                    // })
                }).catch((error) => {
                    console.log(error)
                })
            })
        )
    }

    // Adicionar SOLIDÁRIO
    addSolidario = () => {
        this.setState({alert: false})
        // document.getElementById('inputAddSolidario').checked = false
        if((!this.validarCPFsemAlert(this.state.dadoSolidario) && !this.validarCNPJsemAlert(this.state.dadoSolidario) === true) || (!this.validarCNPJsemAlert(this.state.dadoSolidario) && !this.validarCPFsemAlert(this.state.dadoSolidario) === true)){
            console.log(this.state.rendaSolidario)
            this.setState({mensagemModal: <div>
                            Documento inválido
                        </div>})
            this.toggleAlert()
        }
        else{
            if(this.state.pessoasBusca.length === 0){
                var principal = [{
                    dado: this.state.dado,
                    renda: this.state.renda,
                    nome: 'Aguarde....',
                    quem: 'Principal',
                    resultado: '',
                    motivo: '',
                    concluido: 0
                }]
                if(this.state.pessoasBusca.length > 0){
                    if(this.state.pessoasBusca[0]['quem'] === 'Principal'){
                        this.state.pessoasBusca.splice(0, 1)
                        this.setState({pessoasBusca: this.state.pessoasBusca})
                    }
                }
                localStorage.setItem('pessoasBusca_test', JSON.stringify(principal.concat(this.state.pessoasBusca)))
                this.setState({pessoasBusca: principal.concat(this.state.pessoasBusca)})
                if(this.state.dadoSolidario !== this.state.dado){
                    console.log(this.state.rendaSolidario)
                    if(this.state.rendaSolidario !== '' && parseFloat(this.state.rendaSolidario) !== 0 && this.state.residir !== ''){
                        var solidario = { 
                            dado: this.state.dadoSolidario,
                            renda: this.state.rendaSolidario,
                            nome: 'Aguarde....',
                            quem: 'Solidário',
                            resultado: '',
                            motivo: '',
                            concluido: 0,
                            residir: this.state.residir
                        }
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(principal.concat(this.state.pessoasBusca).concat(solidario)))
                        this.setState({pessoasBusca: principal.concat(this.state.pessoasBusca).concat(solidario), qtdSolidario: this.state.qtdSolidario + 1})
                        this.clearSolidario()
                        if(this.state.activeTabPrincipal === '1'){
                            this.toggleModal()
                        }
                        else{
                            this.toggleModal()
                            this.toggleAlertSolidario()
                        }
                    }
                    else{
                        if((this.state.rendaSolidario === '' || this.state.rendaSolidario === '0') && this.state.residir === ''){
                            this.setState({mensagemModal: <div>
                                Renda do solidário não foi informada e "Irá residir no imóvel" não teve resposta
                              </div>})
                            this.toggleAlert()
                            if(this.state.activeTabPrincipal === '1'){
                                this.toggleModal()
                            }
                            else{
                                this.toggleModal()
                                this.toggleAlertSolidario()
                            }
                        }
                        else if(this.state.rendaSolidario === '' || parseFloat(this.state.rendaSolidario) === 0){
                            this.setState({mensagemModal: <div>
                                Renda do solidário não foi informada
                                </div>})
                            this.toggleAlert()
                            if(this.state.activeTabPrincipal === '1'){
                                this.toggleModal()
                            }
                            else{
                                this.toggleModal()
                                this.toggleAlertSolidario()
                            }
                        }
                        else{
                            this.setState({mensagemModal: <div>
                                "Irá residir no imóvel" não teve resposta
                                </div>})
                            this.toggleAlert()
                            if(this.state.activeTabPrincipal === '1'){
                                this.toggleModal()
                            }
                            else{
                                this.toggleModal()
                                this.toggleAlertSolidario()
                            }
                        }
                    }
                }
                else{
                    // alert('Principal e solidário não podem ser a mesma pessoa')
                    this.setState({mensagemModal: <div>
                                                    Principal e solidário não podem ser a mesma pessoa
                                                  </div>})
                    this.toggleAlert()
                    if(this.state.activeTabPrincipal === '1'){
                        this.toggleModal()
                    }
                    else{
                        this.toggleModal()
                        this.toggleAlertSolidario()
                    }
                }
            }
            else{
                if(this.state.pessoasBusca.map((e) => {
                    return e.dado
                }).indexOf(this.state.dadoSolidario) === -1){
                    if(this.state.rendaSolidario !== '' && parseFloat(this.state.rendaSolidario) !== 0 && this.state.residir !== ''){
                        var solidario = {
                            dado: this.state.dadoSolidario,
                            renda: this.state.rendaSolidario,
                            nome: 'Aguarde....',
                            quem: 'Solidário',
                            resultado: '',
                            motivo: '',
                            concluido: 0,
                            residir: this.state.residir
                        }
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(this.state.pessoasBusca.concat(solidario)))
                        this.setState({pessoasBusca: this.state.pessoasBusca.concat(solidario), qtdSolidario: this.state.qtdSolidario + 1})
                        this.clearSolidario()
                        if(this.state.activeTabPrincipal === '1'){
                            this.toggleModal()
                        }
                        else{
                            this.toggleModal()
                            this.toggleAlertSolidario()
                        }
                    }
                    else{
                        if((this.state.rendaSolidario === '' || parseFloat(this.state.rendaSolidario) === 0) && this.state.residir === ''){
                            this.setState({mensagemModal: <div>
                                Renda do solidário não foi informada e "Irá residir no imóvel" não teve resposta
                              </div>})
                            this.toggleAlert()
                            if(this.state.activeTabPrincipal === '1'){
                                this.toggleModal()
                            }
                            else{
                                this.toggleModal()
                                this.toggleAlertSolidario()
                            }
                        }
                        else if(this.state.rendaSolidario === '' || parseFloat(this.state.rendaSolidario) === 0){
                            this.setState({mensagemModal: <div>
                                Renda do solidário não foi informada
                                </div>})
                            this.toggleAlert()
                            if(this.state.activeTabPrincipal === '1'){
                                this.toggleModal()
                            }
                            else{
                                this.toggleModal()
                                this.toggleAlertSolidario()
                            }
                        }
                        else{
                            console.log()
                            this.setState({mensagemModal: <div>
                                "Irá residir no imóvel" não teve resposta
                                </div>})
                            this.toggleAlert()
                            if(this.state.activeTabPrincipal === '1'){
                                this.toggleModal()
                            }
                            else{
                                this.toggleModal()
                                this.toggleAlertSolidario()
                            }
                        }
                    }
                }
                else{
                    // alert('Pessoa já foi adicionada')
                    this.setState({mensagemModal: <div>
                                                    Pessoa já foi adicionada
                                                   </div>})
                    this.toggleAlert()
                    if(this.state.activeTabPrincipal === '1'){
                        this.toggleModal()
                    }
                    else{
                        this.toggleModal()
                        this.toggleAlertSolidario()
                    }
                }
            }
        }
    }

    // Limpar variáveis do Solidário que foi adicionado para ser adicionado um novo
    clearSolidario = () => {
        this.setState({
            rendaSolidario: '',
            dadoSolidario: '',
            clienteSolidario_id: '',
            residir: '',
            maskSolidario: '999.999.999-99',
        })
        localStorage.removeItem('rendaSolidario_test')
        localStorage.removeItem('dadoSolidario_test')
        localStorage.removeItem('clienteSolidario_id_test')
        localStorage.removeItem('maskSolidario_test')
        localStorage.removeItem('residir_test')
    }

    // Verifica de o solidário existe ou não / Caso não cria novo registro
    solidario = async (indice) => {
        return ({0: api_epar.get( '/cliente/' + this.state.pessoasBusca[indice].dado.replace('/', '_')).then((clienteSolidario) => {
            if(clienteSolidario.data.data.length > 0){
                var atualizarSolidario = this.state.pessoasBusca
                atualizarSolidario[indice].cliente = clienteSolidario.data.data[0].id
                if(atualizarSolidario[indice].renda === ''){
                    if(clienteSolidario.data.data[0].renda_informada !== null || clienteSolidario.data.data[0].renda_informada !== ''){
                        atualizarSolidario[indice].renda = clienteSolidario.data.data[0].renda_informada
                    }
                    else{
                        atualizarSolidario[indice].renda = 0
                    }
                }
                else{
                    api_epar.put( '/cliente/', {
                        id: clienteSolidario.data.data[0].id,
                        renda_informada: atualizarSolidario[indice].renda
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
                this.setState({pessoasBusca: atualizarSolidario})
                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizarSolidario))
            }
            else{
                api_epar.post( '/cliente/', {cpf_cnpj: this.state.pessoasBusca[indice].dado, renda_informada: this.state.pessoasBusca[indice].renda}).then((retorno) => {
                    var atualizarSolidario = this.state.pessoasBusca
                    atualizarSolidario[indice].cliente = retorno.data.data
                    this.setState({pessoasBusca: atualizarSolidario})
                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizarSolidario))
                }).catch((error) => {
                    console.log(error)
                })
            }
        }), 1: indice})
    }

    rowDocumentos = (record) => {
        return(
            <div key={record.dado}>{record.dado}</div>
        )
    }

    // Verificar a renda de 30%
    verificar30 = () => {
        var soma = 0
        for(var i = 0; i < this.state.pessoasBusca.length; i++){
            soma += parseFloat(this.state.pessoasBusca[i].renda)
        } 
        console.log(soma*0.3  + ' / ' + soma)
        if(this.state.valor > soma*0.3){
            // console.log('Não será possível prosseguir, 30%')
            return false
        }
        else{
            // console.log('Será possível prosseguir')
            return true
        }
    }

    // Realiza a busca dos dados judiciais
    consultaIdWall = (indice) => {
        this.aguardeRespostaParaIdwall().then(() => {
            // console.log(this.state.pessoasBusca)
            var dado = this.state.pessoasBusca[indice].dado.replace(/\D/g, '')
            var cliente = this.state.pessoasBusca[indice].cliente
            // console.log(cliente)

            // Verificar se é um CNPJ
            if(dado.length > 11){
                console.log(dado + ' / CNPJ')
                return ({0: api_epar.get( '/idwallcnpj/' + dado + '/' + cliente).then((idwall) => {
                    if(idwall.data){
                        var atualizar = this.state.pessoasBusca
                        atualizar[indice].idwall = JSON.stringify(idwall.data)
                        this.setState({pessoasBusca: atualizar})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        var busca30
                        var json = JSON.stringify(idwall.data)
                        if(idwall.data.local === 'BANCO'){
                            busca30 = 1
                            json = JSON.stringify(idwall.data.data)
                            atualizar[indice].idwall = json
                            this.setState({pessoasBusca: atualizar})
                            localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        }
                        else{
                            busca30 = 0
                        }
                        api_epar.post( '/clienteshasconsulta', {
                            clientes_id: this.state.pessoasBusca[indice].cliente,
                            consulta_id: this.state.consulta_id,
                            tipo_consulta_id: 3,
                            id_tabela_consulta: null,
                            data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                            tipo_locatario: this.state.pessoasBusca[indice].quem,
                            busca30: busca30,
                            retorno_json: json
                        }).then((retornoClienteConsulta) => {
                            console.log(retornoClienteConsulta)
                            // api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/3').then((dadoCompetencia) => {
                            //     console.log(dadoCompetencia.data.data)
                            //     api_epar.post( '/tarifacao/', {
                            //         data: moment().format('YYYY-MM-DD HH:mm:ss'),
                            //         valor: dadoCompetencia.data.data[0].valor_cobrar,
                            //         tipo: 'Débito',
                            //         clientes_has_consulta_id: retornoClienteConsulta.data.data
                            //     }).then((retornoTarifacao) => {
                            //         console.log(retornoTarifacao)
                            //     }).catch((error) => {
                            //         console.log(error)
                            //     })
                            // }).catch((error) => {
                            //     console.log(error)
                            // })
                            var atualizar = this.state.pessoasBusca
                            atualizar[indice].idBuscaIdwall = retornoClienteConsulta
                            atualizar[indice].interval = 0
                            atualizar[indice].chamadas = 0
                            atualizar[indice].email = 0
                            this.setState({pessoasBusca: atualizar})
                            localStorage.setItem('minuto_test', moment().format('mm'))
                            this.setState({min: moment().format('mm')})

                            // Analisa retorno ou espera o retorno
                            this.relatorioIdwall(retornoClienteConsulta, indice)
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                }), 1: indice})
            }
            // Caso seja CPF
            else{
                console.log(dado + ' / CPF')
                return ({0: api_epar.get( '/idwallcpf/' + dado + '/' + cliente).then((idwall) => {
                    if(idwall.data){
                        var atualizar = this.state.pessoasBusca
                        atualizar[indice].idwall = JSON.stringify(idwall.data)
                        this.setState({pessoasBusca: atualizar})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        var busca30
                        var json = JSON.stringify(idwall.data)
                        if(idwall.data.local === 'BANCO'){
                            busca30 = 1
                            json = JSON.stringify(idwall.data.data)
                            atualizar[indice].idwall = json
                            this.setState({pessoasBusca: atualizar})
                            localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        }
                        else{
                            busca30 = 0
                        }
                        api_epar.post( '/clienteshasconsulta', {
                            clientes_id: this.state.pessoasBusca[indice].cliente,
                            consulta_id: this.state.consulta_id,
                            tipo_consulta_id: 3,
                            id_tabela_consulta: null,
                            data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                            tipo_locatario: this.state.pessoasBusca[indice].quem,
                            busca30: busca30,
                            retorno_json: json
                        }).then((retornoClienteConsulta) => {
                            console.log(retornoClienteConsulta)
                            // api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/3').then((dadoCompetencia) => {
                            //     console.log(dadoCompetencia.data.data)
                            //     api_epar.post( '/tarifacao/', {
                            //         data: moment().format('YYYY-MM-DD HH:mm:ss'),
                            //         valor: dadoCompetencia.data.data[0].valor_cobrar,
                            //         tipo: 'Débito',
                            //         clientes_has_consulta_id: retornoClienteConsulta.data.data
                            //     }).then((retornoTarifacao) => {
                            //         console.log(retornoTarifacao)
                            //     }).catch((error) => {
                            //         console.log(error)
                            //     })
                            // }).catch((error) => {
                            //     console.log(error)
                            // })
                            var atualizar = this.state.pessoasBusca
                            atualizar[indice].idBuscaIdwall = retornoClienteConsulta
                            atualizar[indice].interval = 0
                            atualizar[indice].chamadas = 0
                            atualizar[indice].email = 0
                            this.setState({pessoasBusca: atualizar})
                            localStorage.setItem('minuto_test', moment().format('mm'))
                            this.setState({min: moment().format('mm')})

                            // Analisa retorno ou espera o retorno
                            this.relatorioIdwall(retornoClienteConsulta, indice)
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                }), 1: indice})
            }
        })
    }

    // Espera o retorno ou analisa o retorno
    relatorioIdwall = (retornoClienteConsulta, indice) => {
        api_epar.get( '/clienteshasconsulta/' + retornoClienteConsulta.data.data).then((retorno) => {
            // console.log(JSON.parse(retorno.data.data[0].retorno_json).result.processos.itens.length)
            // console.log(JSON.parse(retorno.data.data[0].retorno_json).data.result.numero)
           
            var url
            if(JSON.parse(retorno.data.data[0].retorno_json.replace(/'/g, '"')).result){
                console.log('Result')
                url = '/idwallrelatorio/' + JSON.parse(retorno.data.data[0].retorno_json.replace(/'/g, '"').replace(/[\r\n]/g, " ")).result.numero
            }
            else{
                console.log('Data')
                url = '/idwallrelatorio/' + JSON.parse(retorno.data.data[0].retorno_json).data.result.numero
            }

            api_epar.get(url).then((relatorio) => {
                console.log(relatorio.data.data)
                var atualizar = this.state.pessoasBusca

                // Caso já houve o retorno completo
                if(relatorio.data.data.result.status === 'CONCLUIDO'){
                    // atualizar[indice].concluido = atualizar[indice].concluido + 1
                    var processo_valor
                    var processo_nota
                    var processo_status
                    var antecedente_valor
                    var antecedente_nota
                    var antecedente_status
                    var motivo = ''
                    var resultado = ''
                    atualizar[indice].interval = 2
                    atualizar[indice].idwall = JSON.stringify(relatorio.data.data).replace(/'/g, " ").replace(/\\"/g, " ").replace(/"/g, "'")
                    
                    //Caso não tenha processo
                    if(relatorio.data.data.result.processos.itens && relatorio.data.data.result.processos.itens.length === 0){
                        atualizar[indice].resultado += 'Aprovado, '
                        atualizar[indice].motivo += ''
                        resultado += 'Aprovado, '
                        motivo += ''
                        this.setState({pessoasBusca: atualizar})
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                        processo_valor = 'Não'
                        processo_nota = this.state.processosNegar
                        processo_status = 2
                    }
                    // Caso tenha processo
                    else{
                        if(relatorio.data.data.result.processos.itens){
                            if(relatorio.data.data.result.processos.itens.length > 0){
                                // Verificar se o processo é penal ou criminal
                                if((relatorio.data.data.result.processos.itens.map((e) => {
                                    return e.assunto
                                }).indexOf('PENAL') !== -1 || relatorio.data.data.result.processos.itens.map((e) => {
                                    return e.localizacao.vara
                                }).indexOf('CRIMINAL') !== -1) && this.state.processosNegar === 'Sim'){
                                    atualizar[indice].resultado += 'Negado, '
                                    atualizar[indice].motivo += '3, '
                                    resultado += 'Negado, '
                                    motivo += '3, '
                                    this.setState({pessoasBusca: atualizar})
                                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                    processo_valor = 'Sim'
                                    processo_nota = this.state.processosNegar
                                    processo_status = 0
                                }
                                else if((relatorio.data.data.result.processos.itens.map((e) => {
                                    return e.assunto
                                }).indexOf('PENAL') !== -1 || relatorio.data.data.result.processos.itens.map((e) => {
                                    return e.localizacao.vara
                                }).indexOf('CRIMINAL') !== -1) && this.state.processosNegar === 'Não'){
                                    atualizar[indice].resultado += 'Aprovado com ressalva, '
                                    atualizar[indice].motivo += '3, '
                                    resultado += 'Aprovado com ressalva, '
                                    motivo += '3, '
                                    this.setState({pessoasBusca: atualizar})
                                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                    processo_valor = 'Sim'
                                    processo_nota = this.state.processosNegar
                                    processo_status = 1
                                }
                                else{
                                    atualizar[indice].resultado += 'Aprovado com ressalva, '
                                    atualizar[indice].motivo += '30, '
                                    resultado += 'Aprovado com ressalva, '
                                    motivo += '30, '
                                    this.setState({pessoasBusca: atualizar})
                                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                    processo_valor = 'S'
                                    processo_nota = this.state.processosNegar
                                    processo_status = 1
                                }
                            }
                            else{
                                atualizar[indice].resultado += 'Aprovado, '
                                atualizar[indice].motivo += ''
                                resultado += 'Aprovado, '
                                motivo += ''
                                this.setState({pessoasBusca: atualizar})
                                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                processo_valor = 'Não'
                                processo_nota = this.state.processosNegar
                                processo_status = 2
                            }
                        }
                    }

                    // this.atualizarStatusMotivo(
                    //     atualizar[indice].cliente,
                    //     3,
                    //     this.state.consulta_id,
                    //     null,
                    //     resultado,
                    //     motivo
                    // )
                    if(relatorio.data.data.result.certidoes_negativas.itens){
                        if(relatorio.data.data.result.certidoes_negativas.itens.length > 0){
                            
                            // Verificar se tem antecedentes criminais 
                            if(relatorio.data.data.result.certidoes_negativas.itens.map((e) => {
                                return e.fonte
                            }).indexOf('AntecedentesTRF1') !== -1){
                                var index = relatorio.data.data.result.certidoes_negativas.itens.map((e) => {
                                    return e.fonte
                                }).indexOf('AntecedentesTRF1')

                                if(relatorio.data.data.result.certidoes_negativas.itens[index].nada_consta === true){
                                    console.log('Nada consta')
                                    antecedente_valor = 'Não'
                                    antecedente_nota = null
                                    antecedente_status = 2
                                }
                                else{
                                    // var atualizar = this.state.pessoasBusca
                                    atualizar[indice].resultado += 'Aprovado com ressalva, '
                                    atualizar[indice].motivo += '50, '
                                    resultado += 'Aprovado com ressalva, '
                                    motivo += '50, '

                                    antecedente_valor = 'Sim'
                                    antecedente_nota = null
                                    antecedente_status = 1
                                    // this.setState({pessoasBusca: atualizar})
                                    // localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))

                                    // this.atualizarStatusMotivo(
                                    //     atualizar[indice].cliente,
                                    //     3,
                                    //     this.state.consulta_id,
                                    //     null,
                                    //     atualizar[indice].resultado,
                                    //     atualizar[indice].motivo
                                    // )
                                }
                            }
                            else{
                                console.log('Sem antecedentes')
                            }

                            // atualizar[indice].concluido = atualizar[indice].concluido + 1

                            // this.setState({pessoasBusca: atualizar})
                            // localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))

                            // this.atualizarStatusMotivo(
                            //     atualizar[indice].cliente,
                            //     3,
                            //     this.state.consulta_id,
                            //     null,
                            //     atualizar[indice].resultado,
                            //     atualizar[indice].motivo
                            // )
                        }
                        else{
                            console.log('Sem certidões')
                        }
                    }
                    atualizar[indice].concluido = atualizar[indice].concluido + 2
                    console.log('Somou 2 soma judiciais')
                    this.setState({pessoasBusca: atualizar})
                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))

                    // api_epar.put('/consultaprocesso', {
                    //     valor: processo_valor,
                    //     nota: processo_nota,
                    //     status: processo_status,
                    //     id: this.state.consulta_id
                    // })

                    // api_epar.put('/consultaantecedente', {
                    //     valor: antecedente_valor,
                    //     nota: antecedente_nota,
                    //     status: antecedente_status,
                    //     id: this.state.consulta_id
                    // })

                    // Atualizar no banco os dados judiciais
                    this.atualizarStatusMotivoIdwall(
                        atualizar[indice].cliente,
                        3,
                        this.state.consulta_id,
                        null,
                        resultado,
                        motivo
                    )

                    // api_epar.get( '/idwallatualizar').then((atualizarWall) => {})
                }
                // Caso não houve o retorno completo
                else{
                    console.log('PROCESSANDO OU EM ANALISE')
                    if(this.state.pessoasBusca[indice].interval === 0 || this.state.pessoasBusca[indice].interval === 3){
                        var atualizar1 = this.state.pessoasBusca
                        atualizar1[indice].interval = 1
                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar1))
                        this.setState({pessoasBusca: atualizar1})

                        //Função que será chamada de tempo em tempo
                        const intervalFuncao = setInterval(() => {
                            var parar = 0
                            if(this.state.pessoasBusca[indice].resultado){
                                if(this.state.pessoasBusca[indice].resultado.indexOf('Negado') !== -1){
                                   parar = 1
                                }
                            }
                            var atualizar2 = this.state.pessoasBusca
                            atualizar2[indice].chamadas += 1
                            localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar2))
                            this.setState({pessoasBusca: atualizar2})
                            console.log('Irá consultar novamente o registro da tabela CLIENTES_HAS_CONSULTA ' + retornoClienteConsulta.data.data)
                            this.relatorioIdwall(retornoClienteConsulta, indice)
                            if(atualizar2[indice].interval === 2 || parar === 1){
                                clearInterval(intervalFuncao)
                            }
                            var tempo = parseInt(this.state.min) + 5
                            if(parseInt(this.state.min) + 5 >= 60){
                                tempo = parseInt(this.state.min) - 55
                            }

                            var status = retorno.data.data[0].status ? retorno.data.data[0].status : 'Nada'
                            // Verifica se os dados judiciais demorou o retorno
                            if(atualizar2[indice].chamadas === 10 || parseInt(moment().format('mm')) === tempo || atualizar2[indice].resultado.indexOf('Aprovado por tempo excedido') !== -1 || status.indexOf('Aprovado por tempo excedido') !== -1){
                                var atualizar3 = this.state.pessoasBusca
                                atualizar3[indice].resultado += 'Aprovado por tempo excedido, '
                                atualizar3[indice].concluido = 5
                                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar3))
                                this.setState({pessoasBusca: atualizar3})
                                this.atualizarStatusMotivoIdwall(
                                    atualizar3[indice].cliente,
                                    3,
                                    this.state.consulta_id,
                                    null,
                                    'Aprovado por tempo excedido',
                                    ''
                                )

                                //Enviar email informando que foi aprovado por tempo excedido
                                if(atualizar3[indice].email === 0){
                                    var atualizar4 = this.state.pessoasBusca
                                    atualizar4[indice].email = 1
                                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar4))
                                    this.setState({pessoasBusca: atualizar4})
                                    var html = '<div>'
                                                    +'Prezado Administrador Alug+, uma nova consulta foi realizada com os seguintes parâmetros: <br/><br/>'
                                                    +'Identificador da consulta: ' + this.state.consulta_id + '<br/>'
                                                    +'Imobiliária: ' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria + '<br/>'
                                                    +'Locatário ' + atualizar4[indice].quem + ': ' + atualizar4[indice].nome + '<br/>'
                                                    +'Valor da renda informada: ' + FuncoesComum.formatarMoeda(parseFloat(atualizar4[indice].renda)) + '<br/>'
                                                    +'Valor da locação: ' + FuncoesComum.formatarMoeda(parseFloat(this.state.valor)) + '<br/><br/>'
                                                    +'Esse email é um aviso que a consulta referente aos dados acima, foi aprovada devido o tempo excedido de análise.<br/>'
                                                +'</div>'
                                    api_epar.post( '/sendemail', {
                                        subject: 'Aprovação devido tempo excedido',
                                        html: html,
                                        extras: {
                                            cliente: this.state.pessoasBusca[indice].cliente,
                                            consulta: this.state.consulta_id
                                        }
                                    }).then((retorno) => {
                                        console.log(retorno)
                                    })
                                } 
                                // this.setState({mensagemModal: <div>
                                //                                 Essa consulta já está em espera mais de 5 minutos, será aprovado momentaneamente.<br/>
                                //                                 Porém, após realizada toda a consulta, o status aprovado poderá ser alterado conforme a análise do retorno. 
                                //                                 <Row>
                                //                                     <Col md={12} className='float-right mt-5'>
                                //                                         <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.proximaEtapa}>Finalizar consulta</Button>  
                                //                                     </Col>
                                //                                 </Row>
                                //                               </div>})
                                // this.toggleAlert()
                            }
                        }, 30000)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    aguardarIDWALL = (indice) => {
        if(this.state.interval === 0){
            this.setState({interval: 1})
            const intervalFuncao = setInterval(() => {
                var parar = 0
                if(this.state.pessoasBusca[indice].resultado.indexOf('Negado') !== -1){
                    parar = 1
                }
                this.setState({chamadas: this.state.chamadas + 1})
                console.log('Irá consultar novamente o registro da tabela CLIENTES_HAS_CONSULTA')
                if(this.state.interval === 2 || parar === 1){
                    clearInterval(intervalFuncao)
                }
                var tempo = parseInt(this.state.min) + 2
                if(parseInt(this.state.min) + 2 >= 60){
                    tempo = parseInt(this.state.min) - 58
                }
                if(this.state.chamadas === 2 || parseInt(moment().format('mm')) >= tempo){
                    var atualizar = this.state.pessoasBusca
                    atualizar[indice].resultado += 'Aprovado por tempo excedido, '
                    atualizar[indice].concluido = 5
                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                    this.setState({pessoasBusca: atualizar, interval: 2})
                    // this.setState({mensagemModal: <div>
                    //                                 Essa consulta já está em espera mais de 5 minutos, será aprovado momentaneamente.<br/>
                    //                                 Porém, após realizada toda a consulta, o status aprovado poderá ser alterado conforme a análise do retorno. 
                    //                                 <Row>
                    //                                     <Col md={12} className='float-right mt-5'>
                    //                                         {/* <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.proximaEtapa}>Finalizar consulta</Button>   */}
                    //                                         <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta_id}>Upload de arquivo</Button>
                    //                                     </Col>
                    //                                 </Row>
                    //                               </div>})
                    // this.toggleAlert()
                }
            }, 30000)
        }
    }

    // Iniciar uma nova consulta
    novaConsulta = () => {
        // if(this.state.activeTabPrincipal === 4){
        //     this.atualizarEtapaConsulta('Validação da consulta')
        // }
        localStorage.removeItem('nomeSolidario_test')
        localStorage.removeItem('rendaSolidario_test')
        localStorage.removeItem('telefoneSolidario_test')
        localStorage.removeItem('dadoSolidario_test')
        localStorage.removeItem('nome_test')
        localStorage.removeItem('renda_test')
        localStorage.removeItem('telefone_test')
        localStorage.removeItem('dado_test')
        localStorage.removeItem('pessoasBusca_test')
        localStorage.removeItem('mask_test')
        localStorage.removeItem('maskSolidario_test')
        localStorage.removeItem('valor_test')
        localStorage.removeItem('cliente_id_test')
        localStorage.removeItem('clienteSolidario_id_test')
        localStorage.removeItem('consulta_id_test')
        localStorage.removeItem('minuto_test')
        localStorage.removeItem('email_test')
        localStorage.removeItem('aluguel_test')
        localStorage.removeItem('condominio_test')
        localStorage.removeItem('imposto_test')
        localStorage.removeItem('outros_test')
        localStorage.removeItem('luz_test')
        localStorage.removeItem('agua_test')
        localStorage.removeItem('gas_test')
        localStorage.removeItem('emailCarta_test')
        localStorage.removeItem('emailEnviarCarta_test')
        localStorage.removeItem('finalidadeLocacao')
        localStorage.removeItem('codigo_carta_test')
        localStorage.removeItem('qualFinalidadeLocacao_test')
        localStorage.setItem('activeTab_test', '1')
        localStorage.setItem('activeTabPrincipal_test', '1')
        this.setState({activeTabPrincipal: '1'})
        this.setState({qtdSolidario: 0})
        this.clearSolidario()
        this.setState({
            renda: '',
            telefone: '',
            dado: '',
            valor: '',
            cliente_id: '',
            consulta_id: '',
            mask: '999.999.999-99',
            maskSolidario: '999.999.999-99',
            dadoSolidario: '',
            pessoasBusca: [],
            interval: 0,
            barraProgress: '0',
            chamadas: 0,
            email: 0,
            aluguel: 0,
            condominio: 0,
            imposto: 0,
            outros: 0,
            gas: 0,
            luz: 0,
            agua: 0,
            finalidadeLocacao: '',
            emailCarta: '',
            emailEnviarCarta: '',
            codigo_carta: '', 
            qual_finalidadeLocacao: '',
        })
        ReactDOM.render(<div></div>, document.getElementById('retornoGeral'))
        this.props.history.push('/admin/finalidadelocacao')
    }

    carregarRetorno = (record) => {
        var status = 4
        var icon 
        var color
        var mensagem
        var mensagemAnalise
        var resultado
        var exibir = 'd-none'
        var renda = parseFloat(this.state.pessoasBusca[0].renda)
        if(record.concluido >= 3 && record.quem === 'Principal'){
            exibir = 'd-print-block'
        }
        // this.analisarScore()
        if(record.concluido >= 0 ){
            if(record.resultado.indexOf('Negado') !== -1){
                status = 2
                this.carregarRetornoGeral()
            }
        }
        if(status !== 2){
            if(record.concluido >= 3){
            // if(record.concluido >= 5){
                if(record.resultado.indexOf('Negado') !== -1){
                    status = 2
                }
                else if(record.resultado.indexOf('Aprovado por tempo excedido') !== -1){
                    status = 3
                }
                else if(record.resultado.indexOf('Aprovado com ressalva') !== -1){
                    status = 1
                }
                else if(record.resultado.indexOf('Aprovado') !== -1){
                    status = 0
                }
            }
            if(record.concluido >= 0){
                this.carregarRetornoGeral()
            }
            
        }

        if(status === 0){
            color = '#006600'
            icon = 'ni-check-bold'
            mensagem = <div style={{fontSize: '1.5em'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>
        }
        if(status === 1){
            color = '#006600'
            icon = 'ni-check-bold'
            // mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--4' style={{width: '13%'}}/></div>
            mensagem = <div style={{fontSize: '1.5em'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>

        }
        if(status === 2){
            color = '#660000'
            icon= 'ni-fat-remove'
            mensagem = <div>ENCONTRAMOS ALGO ERRADO, <br/>
            MELHOR NÃO PROSSEGUIR!</div>
            resultado = 'Inválido'
            mensagemAnalise = 'Informamos que o pretendente foi recusado pela análise de risco, não se enquadrando na política de aceitação da garantia Alug+.'
        }
        if(status === 3){
            color = '#006600'
            icon = 'ni-check-bold'
            // mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--4' style={{width: '13%'}}/></div>
            mensagem = <div style={{fontSize: '1.5em'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>
            // color = '#000000'
            // icon = 'ni-check-bold'
            // // mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--4' style={{width: '13%'}}/></div>
            // mensagem = 'Aprovado por tempo excedido!'
            // resultado = 'Tempo excedido'
            // mensagemAnalise = 'Essa consulta já está em espera mais de 5 minutos, será aprovado momentaneamente. Porém, após realizada toda a consulta, o status aprovado poderá ser alterado conforme a análise do retorno. '
        }
        if(status === 4){
            color = '#737373'
            icon= 'ni-user-run'
            mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--3' style={{width: '13%'}}/></div>
            resultado = 'Aguarde...'
            mensagemAnalise = 'Estamos analisando as informações....'
        }

        if(status !== 2 && status !== 4 && (renda*this.state.porcentagemCarta > this.state.semComprovante || this.state.finalidadeLocacao === 'Comercial')){
            color = '#ffaf1a'
            icon= 'ni-folder-17'
            mensagem = <div style={{fontSize: '1.5em'}}>Anexar comprovantes de renda</div>
            resultado = 'Arquivos'
            mensagemAnalise = 'Para que a consulta seja aprovada será necessário anexar os comprovantes de renda'
        }

        console.log(mensagemAnalise)
            
        return (
            <div key={record.dado} className='mb-2 d-flex justify-content-center ' style={{maxWidth: '100vw', minWidth: '60vw'}}>
                <Row className='pt-2 ' style={{borderRadius: '5px', backgroundColor: '#d9d9d9', color: 'black', width: '100%'}}>
                    <Col className='float-left ml--2' md={6}>
                        <Label style={{backgroundColor: color, borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>Nome: {record.nome}</Label>
                    </Col>
                    <Col md={4} className='float-left ml--2'>
                        <Label style={{backgroundColor: color, borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>Documento: {record.dado}</Label>
                    </Col>
                    <Col md={2}  className='float-right ml-3'>
                        <Label style={{backgroundColor: color, borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>{resultado}</Label>
                    </Col>
                </Row>
                {/* <Row className='mt-2 p-2' style={{border: '1px solid  #d9d9d9', borderRadius: '5px'}}>
                    <Col md={4}>
                        <i className={"ni text-white p-4 rounded-circle " + icon} style={{marginTop: '6vh', fontSize: '4em', backgroundColor: color}}/>
                    </Col>
                    <Col md={8}>
                        <div className='mb-3 ' style={{fontSize: '1.3em', color: color}}>
                            <strong>{mensagem}</strong>
                        </div><br/>
                        <div className='mb-3 float-left text-left' style={{fontSize: '0.9em'}}>
                            {mensagemAnalise}
                        </div><br/>
                        <div className='mb-3 float-left text-left' style={{fontSize: '0.8em'}}>
                            Informamos que a decisão de aprovação ou não da pessoa pesquisada é de exclusiva responsabilidade do(a) requerente, sendo que o parecer prestado possui objetivo apenas de balizar a decisão a ser tomada.
                        </div>
                    </Col>
                </Row> */}
                {/* <div className={exibir}>
                    <Button className='mt-1 float-left' style={{backgroundColor: '#333333', color: 'white'}} onClick={this.novaConsulta}>Nova consulta</Button>
                    <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} to={'/admin/newdetalhe/' + this.state.consulta_id} tag={Link} onClick={this.proximaEtapa}>Finalizar consulta</Button>  
                </div> */}
            </div>
        )
        
    }

    carregarRetornoGeral = () => {
        var status = 4
        var icon 
        var color
        var mensagem
        var mensagemAnalise
        var resultado
        var renda = parseFloat(this.state.pessoasBusca[0].renda)
        var statusBanco

        console.log(this.state.pessoasBusca)

        for(var i = 0; i < this.state.pessoasBusca.length; i++){
            if(this.state.pessoasBusca[i].concluido >= 3){
                console.log(this.state.pessoasBusca[i].resultado, this.state.pessoasBusca[i].resultado.indexOf('Negado') !== -1);
                if(this.state.pessoasBusca[i].resultado.indexOf('Negado') !== -1){
                    status = 2
                    break;
                }
                else if(this.state.pessoasBusca[i].resultado.indexOf('Aprovado por tempo excedido') !== -1){
                    status = 3
                }
                else if(this.state.pessoasBusca[i].resultado.indexOf('Aprovado com ressalva') !== -1){
                    status = 1
                }
                else if(this.state.pessoasBusca[i].resultado.indexOf('Aprovado') !== -1){
                    status = 0
                }
            }
        }
        
        console.log(status);

        if(status !== 2 && status !== 3){
        // if(status !== 2){
            
            for(var i = 0; i < this.state.pessoasBusca.length; i++){
                // if(this.state.pessoasBusca[i].idwall && JSON.parse(this.state.pessoasBusca[i].idwall.replace(/'/g, '"').replace(/[\r\n]/g, " "))){
                //     if(this.state.pessoasBusca[i].idwall && JSON.parse(this.state.pessoasBusca[i].idwall.replace(/'/g, '"').replace(/[\r\n]/g, " ")).result){
                //         if(this.state.pessoasBusca[i].concluido >= 5){
                        if(this.state.pessoasBusca[i].concluido >= 3){
                            statusBanco = this.atualizarStatusConsulta()
                            if(statusBanco === 0){
                                status = 2
                                break
                            }
                            if(this.state.pessoasBusca[i].resultado.indexOf('Negado') !== -1){
                                status = 2
                                break;
                            }
                            else if(this.state.pessoasBusca[i].resultado.indexOf('Aprovado com ressalva') !== -1){
                                status = 1
                            }
                            else if(this.state.pessoasBusca[i].resultado.indexOf('Aprovado') !== -1){
                                status = 0
                            }
                        }
                        else{
                            status = 4
                        }
                //         }
                //         else{
                //             status = 4
                //         }
                //     }
                //     else{
                //         status = 4
                //         break
                //     }
                // }
                // else{
                //     status = 4
                //     break
                // }
            }

        }
        else{
            statusBanco = this.atualizarStatusConsulta()
            if(statusBanco === 0){
                status = 2
            }
        }
        
        if(status === 0){
            color = '#006600'
            icon = 'ni-check-bold'
            mensagem = <div style={{fontSize: '1.5em'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            // mensagemAnalise = `Garantimos ao ${this.state.pessoasBusca[0].nome}, cujo CPF é ${this.state.dado}, ${FuncoesComum.formatarMoeda(parseFloat(this.state.renda) * 0.3)} para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).`
            mensagemAnalise = <div style={{textAlign: 'center', marginTop: '-20px'}}>
                <p style={{fontSize: '0.8em'}}><strong>Garantimos ao</strong></p> 
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#673778'}}><strong>{this.state.pessoasBusca[0].nome}</strong></p>
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>CPF {this.state.dado}</strong></p>
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#FF5A5B'}}><strong>{FuncoesComum.formatarMoeda(parseFloat(this.state.renda) * this.state.porcentagemCarta)}</strong></p> 
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>Para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos (IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).</strong></p>
            </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>
        }
        if(status === 1){
            color = '#006600'
            icon = 'ni-check-bold'
            // mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--4' style={{width: '13%'}}/></div>
            mensagem = <div style={{fontSize: '1.5em'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            // mensagemAnalise = `Garantimos ao ${this.state.pessoasBusca[0].nome}, cujo CPF é ${this.state.dado}, ${FuncoesComum.formatarMoeda(parseFloat(this.state.renda) * 0.3)} para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).`
            mensagemAnalise = <div style={{textAlign: 'center', marginTop: '-20px'}}>
                <p style={{fontSize: '0.8em'}}><strong>Garantimos ao</strong></p> 
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#673778'}}><strong>{this.state.pessoasBusca[0].nome}</strong></p>
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>CPF {this.state.dado}</strong></p>
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#FF5A5B'}}><strong>{FuncoesComum.formatarMoeda(parseFloat(this.state.renda) * this.state.porcentagemCarta)}</strong></p> 
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>Para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos (IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).</strong></p>
            </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>
        }
        if(status === 2){
            color = '#660000'
            icon= 'ni-fat-remove'
            mensagem = <div>ENCONTRAMOS ALGO ERRADO, <br/>
            MELHOR NÃO PROSSEGUIR!</div>
            resultado = 'Inválido'
            mensagemAnalise = 'Informamos que o pretendente foi recusado pela análise de risco, não se enquadrando na política de aceitação da garantia Alug+.'
            
            if(!this.state.encargoBan){
                var htmlImob = `<div>
                        <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                        A ALUG+ recebeu a sua consulta ID ${this.state.consulta_id}, no dia ${moment().format('DD/MM/YYYY')}, às ${moment().format('HH:mm')}, com os seguintes dados: <br/><br/>
                        Nome do locatário : ${this.state.pessoasBusca[0].nome} <br/>
                        CPF / CNPJ: ${this.state.pessoasBusca[0].dado} <br/><br/>
                        Informamos que dentro dos critérios e análises efetuadas, a consulta foi reprovada. <br/>
                        Obrigado pela compreensão e lamentamos em nesse momento não poder fazer a liberação dessa consulta, uma vez que não se enquadra da política de aceitação da Garantia ALUG+.<br/>
                        Sugerimos a inclusão de um locatário solidário para reanalise. Favor realizar nova consulta, inserindo os dados do Locatário Solidário.<br/><br/>
                        Atenciosamente,<br/>
                        Central de Cadastro ALUG+.
                    </div>`
                    
                api_epar.post( '/sendemailcarta', {
                    to: JSON.parse(localStorage.getItem('dadosUsuario_test')).email,
                    subject: 'ALUG+ CONSULTA REPROVADA',
                    html: htmlImob,
                    extras: {
                        cliente: this.state.pessoasBusca[0].cliente,
                        consulta: this.state.consulta_id
                    }
                }).then((retorno) => {
                    console.log(retorno)
                })

                var htmlAlug = `<div>
                        <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                        Informamos que a consulta ID ${this.state.consulta_id}, efetuada em ${moment().format('DD/MM/YYYY')}, às ${moment().format('HH:mm')}, foi REPROVADA.<br/>
                        Empresa (Imobiliária): ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}. <br/>
                        Nome do locatário : ${this.state.pessoasBusca[0].nome} <br/>
                        CPF / CNPJ: ${this.state.pessoasBusca[0].dado} <br/><br/>
                        Essa informação já foi enviada ao e-mail ${JSON.parse(localStorage.getItem('dadosUsuario_test')).email}.
                    </div>`
    
                api_epar.post( '/sendemail', {
                    subject: 'ALUG+ CONSULTA REPROVADA',
                    html: htmlAlug,
                    extras: {
                        cliente: this.state.pessoasBusca[0].cliente,
                        consulta: this.state.consulta_id
                    }
                }).then((retorno) => {
                    console.log(retorno)
                })
    
                api_epar.post( '/sendalugmais', {
                    mensagem: `ALUG+ CONSULTA REAPROVADA\n\nInformamos que a consulta ID ${this.state.consulta_id}, efetuada em ${moment().format('DD/MM/YYYY')}, às ${moment().format('HH:mm')}, foi REPROVADA.\nEmpresa (Imobiliária): ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}. \n Nome do locatário : ${this.state.pessoasBusca[0].nome}. \nCPF / CNPJ: ${this.state.pessoasBusca[0].dado}. \nEssa informação já foi enviada ao e-mail ${JSON.parse(localStorage.getItem('dadosUsuario_test')).email}.`
                })
            }


        }
        if(status === 3){
            color = '#006600'
            icon = 'ni-check-bold'
            // mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--4' style={{width: '13%'}}/></div>
            mensagem = <div style={{fontSize: '1.5em'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>
            // mensagemAnalise = `Garantimos ao ${this.state.pessoasBusca[0].nome}, cujo CPF é ${this.state.dado}, ${FuncoesComum.formatarMoeda(parseFloat(this.state.renda) * 0.3)} para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).`
            mensagemAnalise = <div style={{textAlign: 'center', marginTop: '-20px'}}>
                <p style={{fontSize: '0.8em'}}><strong>Garantimos ao</strong></p> 
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#673778'}}><strong>{this.state.pessoasBusca[0].nome}</strong></p>
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>CPF {this.state.dado}</strong></p>
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#FF5A5B'}}><strong>{FuncoesComum.formatarMoeda(parseFloat(this.state.renda) * this.state.porcentagemCarta)}</strong></p> 
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>Para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos (IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).</strong></p>
            </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // color = '#000000'
            // icon = 'ni-check-bold'
            // // mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--4' style={{width: '13%'}}/></div>
            // mensagem = 'Aprovado por tempo excedido!'
            // resultado = 'Tempo excedido'
            // mensagemAnalise = 'Essa consulta já está em espera mais de 5 minutos, será aprovado momentaneamente. Porém, após realizada toda a consulta, o status aprovado poderá ser alterado conforme a análise do retorno. '
        }
        if(status === 4){
            color = '#737373'
            icon= 'ni-user-run'
            mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--3' style={{width: '13%'}}/></div>
            resultado = 'Aguarde...'
            mensagemAnalise = 'Estamos analisando as informações....'
        }

        if(status !== 2 && status !== 4 && renda*this.state.porcentagemCarta <= this.state.semComprovante && this.state.finalidadeLocacao !== 'Comercial'){
            if(!localStorage.getItem('codigo_carta_test')){
               this.enviarCartaAutomaticoSemClick()
            }
        }

        if(status !== 2 && status !== 4 && (renda*this.state.porcentagemCarta > this.state.semComprovante || this.state.finalidadeLocacao === 'Comercial')){
            color = '#ffaf1a'
            icon= 'ni-folder-17'
            mensagem = <div style={{fontSize: '1.5em'}}>Anexar comprovantes de renda</div>
            resultado = 'Arquivos'
            mensagemAnalise = 'Para que a consulta seja aprovada será necessário anexar os comprovantes de renda'
        }

            ReactDOM.render(
                <div key={'retornoGeral'} className='mt-3 mb-5' style={{maxWidth: '60vw'}}>
                    <Row className='mt-2 p-2' style={{border: '1px solid  #d9d9d9', borderRadius: '5px'}}>
                        <Col md={4} className='d-flex justify-content-center align-items-center'>
                            <i className={"ni text-white p-4 rounded-circle " + icon} style={{margin: '4vh', fontSize: '4em', backgroundColor: color}}/>
                        </Col>
                        <Col md={8}>
                            <div className='mb-3 ' style={{fontSize: '1.3em', color: color}}>
                                <strong>{mensagem}</strong>
                            </div><br/>
                            <div className='mb-3 float-left text-left' style={{fontSize: '1.1em', fontWeight: 'bolder'}}>
                                {mensagemAnalise}
                            </div><br/>
                            {/* <div className='mb-3 float-left text-left' style={{fontSize: '0.8em'}}>
                                Informamos que a decisão de aprovação ou não da pessoa pesquisada é de exclusiva responsabilidade do(a) requerente, sendo que o parecer prestado possui objetivo apenas de balizar a decisão a ser tomada.
                            </div> */}
                        </Col>
                    </Row>
                    <div className={'mt-4'}>
                        <Button className='mt-1 float-left' style={{backgroundColor: '#333333', color: 'white'}} onClick={this.novaConsulta}>Nova consulta</Button>
                        {status === 2  && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.proximaEtapa}>Finalizar consulta</Button>}  
                        {(status !== 2 && status !== 4 && (renda*this.state.porcentagemCarta > this.state.semComprovante || this.state.finalidadeLocacao === 'Comercial')) && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.modalConsulta} data-value={this.state.consulta_id}>Anexar arquivos</Button>}  
                        {status !== 2 && status !== 4 && renda*this.state.porcentagemCarta <= this.state.semComprovante && this.state.enviarCarta === 'Fim' && this.state.finalidadeLocacao !== 'Comercial' && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={() => {
                            this.novaConsulta()
                            this.props.history.push('/admin/formcontratocarta/' + this.state.codigo_carta)
                        }}>Gerar contrato</Button>}  
                        {status !== 2 && status !== 4 && renda*this.state.porcentagemCarta <= this.state.semComprovante && this.state.enviarCarta === 'Automático' && this.state.finalidadeLocacao !== 'Comercial' && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={() => {
                            this.novaConsulta()
                            this.props.history.push('/admin/formcontratocarta/' + this.state.codigo_carta)
                        }}>Gerar contrato</Button>}  
                        {/* {status !== 2 && status !== 4 && renda*this.state.porcentagemCarta <= this.state.semComprovante && this.state.enviarCarta === 'Fim' && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.enviarCartaAutomatico}>Gerar contrato</Button>}  
                        {status !== 2 && status !== 4 && renda*this.state.porcentagemCarta <= this.state.semComprovante && this.state.enviarCarta === 'Automático' && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.enviarCartaAutomatico}>Gerar contrato</Button>}   */}
                        {/* {status !== 2 && status !== 4 && renda*this.state.porcentagemCarta <= this.state.semComprovante && this.state.enviarCarta === 'Fim' && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.modalEnviarCarta}>Gerar contrato</Button>}  
                        {status !== 2 && status !== 4 && renda*this.state.porcentagemCarta <= this.state.semComprovante && this.state.enviarCarta === 'Automático' && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.enviarCartaAutomatico}>Gerar contrato</Button>}   */}
                        {/* <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.proximaEtapa}>Finalizar consulta</Button>   */}
                        {/* {status === 2 && <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.proximaEtapa}>Finalizar consulta</Button>}   */}
                        {/* {status !== 2 && status !== 4 && <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta_id}>Finalizar consulta</Button>}   */}
                    </div>
                </div>
            , document.getElementById('retornoGeral'))
    }


    // Clientes que serão necessário o upload dos arquivos
    modalConsulta = (evento) => {  
        var element = [] 
        for(var i = 0; i < this.state.pessoasBusca.length; i++){  
            element = element.concat([{
                dado: this.state.pessoasBusca[i].dado,
                cliente: this.state.pessoasBusca[i].cliente,
                rendaInformada: this.state.pessoasBusca[i].renda,
                valorConsulta: this.state.valor,
                consulta: this.state.consulta_id,
                imobiliaria: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria,
                nome: this.state.pessoasBusca[i].nome ? this.state.pessoasBusca[i].nome : 'Não foi identificado',
                locatario: this.state.pessoasBusca[i].quem
            }])
        }
    
        ReactDOM.render(this.uploadArquivos(element), document.getElementById('arquivos'))
        
    }


    uploadArquivos = (element) => {
        return(
            <Modal isOpen={true}>
                <ModalBody>
                    <div style={{textAlign: 'center', fontSize: '1.5em', color: '#ffaf1a'}}>
                        ESTAMOS QUASE LÁ!
                    </div>
                    <div className='mt-2 mb-2' style={{textAlign: 'center', fontSize: '1.1em'}}>
                        <strong>De acordo com as informações passadas, solicitamos anexar os comprovantes de renda. Serão aceitos: </strong>
                    </div>
                    <div style={{fontSize: '0.8em'}}>
                        <Label className='text-danger' style={{textDecoration: 'underline'}}>PARA PESSOA FÍSICA:</Label><br/>
                        EXTRATO BANCÁRIO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 3 MESES</label> ou;<br/>
                        DECLARAÇÃO IRPF <label style={{textDecoration: 'underline'}}>DO ÚLTIMO ANO</label> ou;<br/>
                        HOLERITE DE PAGAMENTO DE SALÁRIO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 3 MESES</label> ou;<br/>
                        EXTRATO DO CARTÃO DE CRÉDITO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 3 MESES</label>.<br/><br/>

                        <Label className='text-danger' style={{textDecoration: 'underline'}}>PARA PESSOA JURÍDICA:</Label><br/>
                        DECLARAÇÃO DE FATURAMENTO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 12 MESES</label>;<br/>
                        (somente com a assinatura do contador)<br/>
                        DIPJ <label style={{textDecoration: 'underline'}}>DO ÚLTIMO ANO</label>.<br/><br/>
                                                                 
                        <Label className='text-danger'>*SOMENTE SERÃO ACEITOS ARQUIVOS DE EXTENSÃO: PDF ou JPG ou JPEG ou PNG</Label><br/><br/>
                    </div>
                    {element.map(this.inputUpload)}
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-dark float-right mt--4' onClick={this.finalizarUpload}>Finalizar</Button>
                </ModalFooter>
            </Modal>
        )
        // return(
        //     <Modal isOpen={true}>
        //         <ModalHeader >ANEXAR ARQUIVOS</ModalHeader>
        //         <ModalBody>
        //             <div style={{fontSize: '0.8em'}}>
        //                 Anexar os 3 últimos comprovantes de renda, serão aceitos:<br/><br/>

        //                 EXTRATO 03 MESES (ÚLTIMOS)<br/>
        //                 Declaração IRPF <br/>
        //                 HOLERITE DE PAGAMENTO <br/>
        //                 DECLARAÇÃO DE FATURAMENTO QUANDO PJ<br/>
        //                 EXTRATO CARTÃO CRÉDITO 03 MESES (ÚLTIMOS)<br/><br/>
                                                                 
        //                 <Label className='text-danger'>*Serão aceitos arquivos em extensão .pdf, .jpg, .jpeg e .png</Label><br/><br/>
        //             </div>
        //             {element.map(this.inputUpload)}
        //         </ModalBody>
        //         <ModalFooter>
        //             <Button className='btn-dark float-right mt--4' onClick={this.finalizarUpload}>Finalizar</Button>
        //         </ModalFooter>
        //     </Modal>
        // )
    }

    finalizarUpload = () => {
        var esperar = false
        for(var i = 0; i < this.state.pessoasBusca.length; i++){  
            if(this.state.naoClicar.indexOf(this.state.pessoasBusca[i].cliente) === -1){
                document.getElementById('botaoUpload' + this.state.pessoasBusca[i].cliente).click()
                esperar = true
            }
        }
        var consulta = this.state.consulta_id
        if(esperar){
            this.aguarde().then(() => {
                this.closeModal()
                this.novaConsulta()
                this.props.history.push('/admin/newdetalhe/' + consulta)
            })
        }
        else{
            this.closeModal()
            this.novaConsulta()
            this.props.history.push('/admin/newdetalhe/' + consulta)
        }
    }

    clicouUpload = (cliente) => {
        var naoClicar = this.state.naoClicar
        naoClicar.push(cliente)
        this.setState({naoClicar: naoClicar})
    }

    closeModal = () => {
        ReactDOM.render(<div></div>, document.getElementById('arquivos'))
    }
  
    // Carrega o component UPLOADDOC para cada cliente
    inputUpload = (record) => { 
        return(
            <div className='text-center' key={record.dado}>
                <Label className='mb-3' style={{fontSize: '1.2em', fontWeight: 'bold'}}>{record.dado}</Label>
                <UploadDoc funcao={() => {this.clicouUpload(record.cliente)}} locatario={record.locatario} nome={record.nome} dado={record.dado} arquivo='Comprovante de renda' cliente={record.cliente} documento='4' rendaInformada={record.rendaInformada} valorConsulta={record.valorConsulta} imobiliaria={record.imobiliaria} consulta={record.consulta}/>
            </div>
        )
    }

    modalEnviarCarta = (evento) => {  
    
        ReactDOM.render(this.modalEmailCarta(), document.getElementById('enviarcarta'))
        
    }

    modalEmailCarta = () => {
        return(
            <Modal isOpen={true}>
                <ModalHeader >Confirmar email</ModalHeader>
                <ModalBody>
                    {/* <div style={{fontSize: '0.8em'}}>
                        Anexar os 3 últimos comprovantes de renda, serão aceitos:<br/><br/>

                        EXTRATO 03 MESES (ÚLTIMOS)<br/>
                        Declaração IRPF <br/>
                        HOLERITE DE PAGAMENTO <br/>
                        DECLARAÇÃO DE FATURAMENTO QUANDO PJ<br/>
                        EXTRATO CARTÃO CRÉDITO 03 MESES (ÚLTIMOS)<br/><br/>
                                                                 
                        <Label className='text-danger'>*Serão aceitos arquivos em extensão .pdf, .jpg, .jpeg e .png</Label><br/><br/>
                    </div> */}
                    <Input type='text' defaultValue={this.state.emailCarta} onChange={this.changeEmailCarta}></Input>
                    <div className='text-center' id='aguardando' style={{display: 'none'}}><img src={require('assets/img/loading.gif')} className='p-5 w-50 h-100' /></div>
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-dark float-right mt--4' onClick={this.enviarCarta} id='bloquearClick'>Enviar carta de crédito</Button>
                </ModalFooter>
            </Modal>
        )
    }

    enviarCarta = () => {
        if(!this.state.emailCarta){
            this.setState({mensagemModal: <div>
                                            Preencha o email
                                        </div>})
            this.toggleAlert() 
        }
        else{
            document.getElementById('aguardando').style.display = 'block';
            document.getElementById('bloquearClick').disabled = true;
            var numeroRandom = Math.floor(Math.random() * 33)
            api_epar.post( '/cartagarantia', {
                renda_informada: parseFloat(this.state.pessoasBusca[0].renda),
                valor_carta: parseFloat(this.state.pessoasBusca[0].renda) * this.state.porcentagemCarta,
                data_emissao: moment().format('YYYY-MM-DD'),
                data_validade: moment().add(15, 'days').format('YYYY-MM-DD'),
                email_envio: this.state.emailCarta,
                clientes_id: this.state.pessoasBusca[0].cliente,
                consulta_id: this.state.consulta_id
            }).then((sucesso) => {
                let codigo = sucesso.data.codigo
                // Busca detalhe da carta
                api_epar.get( '/cartagarantiacodigo/' + codigo).then((carta) => {
                    var extensoTexto = extenso(carta.data.data[0].valor_carta.toString().replace('.', ','), {mode: 'currency'}).toLowerCase()

                    ReactDOM.render(<div className="carta-fundo">
                        <div className="carta">
                            <div className="header">
                                <div className="info">
                                    <p>#CARTA REG</p>
                                    <p>{carta.data.data[0].codigo}</p>
                                </div>
                                <img src={require('../assets/img/logo_carta.png')} height="50px" />
                                <div className="info">
                                    <p>Data emissão</p>
                                    <p>{new Date(carta.data.data[0].data_emissao).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}</p>
                                </div>
                            </div>
                            <div className="body">
                                <div className="garantia">
                                    <h1>Carta de Crédito</h1>
                                    <p>Validade: 15(quinze) dias, após a sua emissão</p>
                                </div>
                                <div className="garantia-info">
                                    <p>Garantimos a</p>
                                    {carta.data.data[0].retorno_json && JSON.parse(carta.data.data[0].retorno_json) && <p>{JSON.parse(carta.data.data[0].retorno_json).content.nome.conteudo.nome}</p>}
                                    {!carta.data.data[0].retorno_json && <p>Não encontrado</p>}
                                    <p>CPF {carta.data.data[0].cpf_cnpj}</p>
                                    <p>{FuncoesComum.formatarMoeda(carta.data.data[0].valor_carta)}</p>
                                    <p>({extensoTexto.substring(0,1).toUpperCase().concat(extensoTexto.substring(1))})</p>
                                </div>
                                <div className="textos">
                                    <p>Para locar um imóvel, até o limite acima mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS)</p>
                                    <p>Esta carta de crédito vale nas imobiliárias conveniadas a ALUG+, mas pode ser oferecida a demais imobiliárias que obrigatoriamente devem fazer seu cadastro junto a alug+ para fazerem uso dessa carta, dentro do prazo aqui estipulado.</p>
                                </div>
                            </div>
                            <div className="footerCarta">
                                <p>Carmem Ribeiro</p>
                                <p>CEO Alug+</p>
                            </div>
                        </div>
                    </div>, document.getElementById('carta'))

                    html2canvas(document.getElementById('carta'), { width: 1500, height: 1200})
                    .then(canvas => {
                        var pdf = new jsPDF({orientation: 'landscape'})
                        const imgData = canvas.toDataURL('image/png');
                        console.log(imgData);
                        pdf.addImage(imgData, 'PNG', 0, -25, 302.5, 270);
                        // pdf.save('download.pdf');
                        api_epar.post( '/sendemailcarta', {
                            to: JSON.parse(localStorage.getItem('dadosUsuario_test')).email,
                            // to: "miguelcolombo3@gmail.com",
                            subject: 'Carta de crédito',
                            html: `<div></div>`,
                            extras: {
                                cliente: this.state.pessoasBusca[0].cliente,
                                consulta: this.state.consulta_id
                            },
                            attachments: [{path: pdf.output('datauristring')}]
                        }).then((retorno) => {
                            document.getElementById('aguardando').style.display = 'none';
                            document.getElementById('bloquearClick').disabled = false;
                            this.setState({mensagemModal: <div>
                                                    Carta de crédito enviada com sucesso.
                                                </div>})
                            this.toggleAlert()
                            this.closeModalEmail()
                            // ReactDOM.render(<div></div>, document.getElementById('carta'))
                            var consulta = this.state.consulta_id
                            new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                                this.novaConsulta()
                                this.props.history.push('/admin/formcontratocarta/' + codigo)
                            })
                        })
                        // api_epar.post( '/sendemailcarta', {
                        //         to: this.state.emailCarta,
                        //         subject: 'Carta de crédito',
                        //         // text: 'Era só o segundo teste mesmo',
                        //         html: '<div></div>',
                        //         attachments: [{path: pdf.output('datauristring')}]
                        //         // attachments: [{path: imgData}]
                        // }).then((retorno) => {
                        //     document.getElementById('aguardando').style.display = 'none';
                        //     document.getElementById('bloquearClick').disabled = false;
                        //     this.setState({mensagemModal: <div>
                        //                             Carta de crédito enviada com sucesso.
                        //                         </div>})
                        //     this.toggleAlert()
                        //     this.closeModalEmail()
                        //     ReactDOM.render(<div></div>, document.getElementById('carta'))
                        //     var consulta = this.state.consulta_id
                        //     new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                        //         this.novaConsulta()
                        //         this.props.history.push('/admin/formcontratocarta/' + codigo)
                        //     })
                        // })
                    });
                   
                    // var pdf = new jsPDF('p','pt','a4')
                    // var anexo
                    // pdf.fromHTML(
                    //     corpoEmailHtml, 50, 50, {
                    //       width: 500,
                    //     }, () => {
                    //         pdf.save('carta_garantia.pdf')
                    //         console.log(pdf.output('datauristring'))
                    //         api_epar.post( '/sendemailcarta', {
                    //             to: this.state.emailCarta,
                    //             subject: 'Carta de garantia',
                    //             // text: 'Era só o segundo teste mesmo',
                    //             html: '<div></div>',
                    //             attachments: [{path: pdf.output('datauristring')}]
                    //         }).then((retorno) => {
                    //             this.setState({mensagemModal: <div>
                    //                                     Carta de garantia enviada com sucesso.
                    //                                 </div>})
                    //             this.toggleAlert()
                    //             this.closeModalEmail()
                    //             this.setState({cartagarantia: <Button className={'btn-success float-right m-1'} onClick={() => {this.props.history.push('/admin/formcontrato/' + this.props.match.params.id)}}>Gerar contrato</Button>})
                    //         })
                    //     }

                    // )


                    // console.log(anexo)
                    
                }).catch((error) => {
                    console.log(error)
                })                
            })
            
        }
    }

    enviarCartaAutomatico = () => {
        this.toggleLoad()  
        var numeroRandom = Math.floor(Math.random() * 33)
        api_epar.get( '/cartagarantiaconsulta/' + this.state.consulta_id).then((cartaConsulta) => {
            if(cartaConsulta.data.data.length === 0){

                api_epar.post( '/cartagarantia', {
                    renda_informada: parseFloat(this.state.pessoasBusca[0].renda),
                    valor_carta: parseFloat(this.state.pessoasBusca[0].renda) * this.state.porcentagemCarta,
                    data_emissao: moment().format('YYYY-MM-DD'),
                    data_validade: moment().add(15, 'days').format('YYYY-MM-DD'),
                    email_envio: this.state.emailEnviarCarta,
                    clientes_id: this.state.pessoasBusca[0].cliente,
                    consulta_id: this.state.consulta_id
                }).then((sucesso) => {
                    let codigo = sucesso.data.codigo
                    // Busca detalhe da carta
                    api_epar.get( '/cartagarantiacodigo/' + codigo).then((carta) => {
                        var extensoTexto = extenso(carta.data.data[0].valor_carta.toString().replace('.', ','), {mode: 'currency'}).toLowerCase()
        
                        ReactDOM.render(<div className="carta-fundo">
                            <div className="carta">
                                <div className="header">
                                    <div className="info">
                                        <p>#CARTA REG</p>
                                        <p>{carta.data.data[0].codigo}</p>
                                    </div>
                                    <img src={require('../assets/img/logo_carta.png')} height="50px" />
                                    <div className="info">
                                        <p>Data emissão</p>
                                        <p>{new Date(carta.data.data[0].data_emissao).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}</p>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="garantia">
                                        <h1>Carta de Crédito</h1>
                                        <p>Validade: 15(quinze) dias, após a sua emissão</p>
                                    </div>
                                    <div className="garantia-info">
                                        <p>Garantimos a</p>
                                        {carta.data.data[0].retorno_json && JSON.parse(carta.data.data[0].retorno_json) && <p>{JSON.parse(carta.data.data[0].retorno_json).content.nome.conteudo.nome}</p>}
                                        {!carta.data.data[0].retorno_json && <p>Não encontrado</p>}
                                        <p>CPF {carta.data.data[0].cpf_cnpj}</p>
                                        <p>{FuncoesComum.formatarMoeda(carta.data.data[0].valor_carta)}</p>
                                        <p>({extensoTexto.substring(0,1).toUpperCase().concat(extensoTexto.substring(1))})</p>
                                    </div>
                                    <div className="textos">
                                        <p>Para locar um imóvel, até o limite acima mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS)</p>
                                        <p>Esta carta de crédito vale nas imobiliárias conveniadas a ALUG+, mas pode ser oferecida a demais imobiliárias que obrigatoriamente devem fazer seu cadastro junto a alug+ para fazerem uso dessa carta, dentro do prazo aqui estipulado.</p>
                                    </div>
                                </div>
                                <div className="footerCarta">
                                    <p>Carmem Ribeiro</p>
                                    <p>CEO Alug+</p>
                                </div>
                            </div>
                        </div>, document.getElementById('carta'))
        
                        html2canvas(document.getElementById('carta'), { width: 1500, height: 1200})
                        .then(canvas => {
                            var pdf = new jsPDF({orientation: 'landscape'})
                            const imgData = canvas.toDataURL('image/png');
                            console.log(imgData);
                            pdf.addImage(imgData, 'PNG', 0, -25, 302.5, 270);
                            // pdf.save('download.pdf');
                            api_epar.post( '/sendemailcarta', {
                                to: JSON.parse(localStorage.getItem('dadosUsuario_test')).email,
                                // to: "miguelcolombo3@gmail.com",
                                subject: 'Carta de crédito',
                                html: `<div></div>`,
                                attachments: [{path: pdf.output('datauristring')}],
                                extras: {
                                    cliente: this.state.pessoasBusca[0].cliente,
                                    consulta: this.state.consulta_id
                                }
                            }).then((retorno) => {
                                console.log(retorno)
                                // document.getElementById('aguardando').style.display = 'none';
                                // document.getElementById('bloquearClick').disabled = false;
                                // this.setState({mensagemModal: <div>
                                //                         Carta de garantia enviada com sucesso.
                                //                     </div>})
                                // this.toggleAlert()
                                // ReactDOM.render(<div></div>, document.getElementById('carta'))
                                // var consulta = this.state.consulta_id
                                // new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                                //     this.novaConsulta()
                                //     this.props.history.push('/admin/formcontratocarta/' + codigo)
                                // })
                            })
                            // api_epar.post( '/sendemailcarta', {
                            //         to: this.state.emailEnviarCarta,
                            //         subject: 'Carta de crédito',
                            //         // text: 'Era só o segundo teste mesmo',
                            //         html: '<div></div>',
                            //         attachments: [{path: pdf.output('datauristring')}]
                            //         // attachments: [{path: imgData}]
                            // }).then((retorno) => {
                            //     console.log(retorno)
                            //     // document.getElementById('aguardando').style.display = 'none';
                            //     // document.getElementById('bloquearClick').disabled = false;
                            //     // this.setState({mensagemModal: <div>
                            //     //                         Carta de garantia enviada com sucesso.
                            //     //                     </div>})
                            //     // this.toggleAlert()
                            //     // ReactDOM.render(<div></div>, document.getElementById('carta'))
                            //     // var consulta = this.state.consulta_id
                            //     // new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                            //     //     this.novaConsulta()
                            //     //     this.props.history.push('/admin/formcontratocarta/' + codigo)
                            //     // })
                            // })
                            this.toggleLoad()  
                            this.props.history.push('/admin/formcontratocarta/' + codigo)
                        });
                        
                        // this.toggleLoad()  
                        // this.props.history.push('/admin/formcontratocarta/' + codigo)
                    }).catch((error) => {
                        console.log(error)
                    })                
                    // this.toggleLoad()  
                    // this.props.history.push('/admin/formcontratocarta/' + codigo)
                })
                    
            }
        })
        
    }

    enviarCartaAutomaticoSemClick = () => {
        // this.toggleLoad()  
        var numeroRandom = Math.floor(Math.random() * 33)
        var consulta = this.state.consulta_id
        api_epar.get( '/cartagarantiaconsulta/' + this.state.consulta_id).then((cartaConsulta) => {
            if(cartaConsulta.data.data.length === 0){

                api_epar.post( '/cartagarantia', {
                    renda_informada: parseFloat(this.state.pessoasBusca[0].renda),
                    valor_carta: parseFloat(this.state.pessoasBusca[0].renda) * this.state.porcentagemCarta,
                    data_emissao: moment().format('YYYY-MM-DD'),
                    data_validade: moment().add(15, 'days').format('YYYY-MM-DD'),
                    email_envio: this.state.emailEnviarCarta,
                    clientes_id: this.state.pessoasBusca[0].cliente,
                    consulta_id: this.state.consulta_id
                }).then((sucesso) => {
                    let codigo = sucesso.data.codigo
                    localStorage.setItem('codigo_carta_test', codigo)
                    this.setState({codigo_carta: codigo})
                    // Busca detalhe da carta
                    api_epar.get( '/cartagarantiacodigo/' + codigo).then((carta) => {
                        var extensoTexto = extenso(carta.data.data[0].valor_carta.toString().replace('.', ','), {mode: 'currency'}).toLowerCase()
        
                        ReactDOM.render(<div className="carta-fundo">
                            <div className="carta">
                                <div className="header">
                                    <div className="info">
                                        <p>#CARTA REG</p>
                                        <p>{carta.data.data[0].codigo}</p>
                                    </div>
                                    <img src={require('../assets/img/logo_carta.png')} height="50px" />
                                    <div className="info">
                                        <p>Data emissão</p>
                                        <p>{new Date(carta.data.data[0].data_emissao).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}</p>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="garantia">
                                        <h1>Carta de Crédito</h1>
                                        <p>Validade: 15(quinze) dias, após a sua emissão</p>
                                    </div>
                                    <div className="garantia-info">
                                        <p>Garantimos a</p>
                                        {carta.data.data[0].retorno_json && JSON.parse(carta.data.data[0].retorno_json) && <p>{JSON.parse(carta.data.data[0].retorno_json).content.nome.conteudo.nome}</p>}
                                        {!carta.data.data[0].retorno_json && <p>Não encontrado</p>}
                                        <p>CPF {carta.data.data[0].cpf_cnpj}</p>
                                        <p>{FuncoesComum.formatarMoeda(carta.data.data[0].valor_carta)}</p>
                                        <p>({extensoTexto.substring(0,1).toUpperCase().concat(extensoTexto.substring(1))})</p>
                                    </div>
                                    <div className="textos">
                                        <p>Para locar um imóvel, até o limite acima mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS)</p>
                                        <p>Esta carta de crédito vale nas imobiliárias conveniadas a ALUG+, mas pode ser oferecida a demais imobiliárias que obrigatoriamente devem fazer seu cadastro junto a alug+ para fazerem uso dessa carta, dentro do prazo aqui estipulado.</p>
                                    </div>
                                </div>
                                <div className="footerCarta">
                                    <p>Carmem Ribeiro</p>
                                    <p>CEO Alug+</p>
                                </div>
                            </div>
                        </div>, document.getElementById('carta'))
        
                        html2canvas(document.getElementById('carta'), { width: 1500, height: 1200})
                        .then(canvas => {
                            var pdf = new jsPDF({orientation: 'landscape'})
                            const imgData = canvas.toDataURL('image/png');
                            console.log(imgData);
                            pdf.addImage(imgData, 'PNG', 0, -25, 302.5, 270);
                            // pdf.save('download.pdf');
                            api_epar.post( '/sendemailcarta', {
                                to: JSON.parse(localStorage.getItem('dadosUsuario_test')).email,
                                // to: "miguelcolombo3@gmail.com",

                                subject: 'ALUG+ CONSULTA APROVADA',
                                html: `<div>
                                    <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                                    Informamos que a consulta ID ${consulta}, efetuada em ${moment().format('DD/MM/YYYY')}, às ${moment().format('HH:mm')}, foi <label style='padding: 2px; border-radius: 5px, background-color: #1aff1a'>APROVADA</label>.<br/><br/>
                                    Nome do locatário: ${carta.data.data[0].retorno_json && JSON.parse(carta.data.data[0].retorno_json) && JSON.parse(carta.data.data[0].retorno_json).content.nome.conteudo.nome}. <br/>
                                    CPF: ${carta.data.data[0].cpf_cnpj}. <br/>
                                    Valor da garantia: ${FuncoesComum.formatarMoeda(parseFloat(this.state.pessoasBusca[0].renda) * this.state.porcentagemCarta)}.  <br/><br/>

                                    <a href='${window.location.origin}/admin/formcontratocarta/${codigo}'>Clique aqui</a> para gerar o contrato de garantia. <br/><br/>

                                    <label style='text-decoration: underline;'><strong>Importante</strong></label><br/><br/>
                                    O CONTRATO DE LOCAÇÃO a ser realizado com o locatário, deverá conter a cláusula abaixo:<br/><br/>
                                    <i>Rescindida ou encerrada a locação, o(s) LOCATÁRIO(S) se obriga(m) a entregar imediatamente o Imóvel ao(s) LOCADOR(ES), sob pena da propositura da 
                                    competente ação de despejo, além do dever de arcar com as sanções previstas nesta cláusula, além das custas do processo e dos honorários advocatícios, 
                                    desde já fixados em 20% (vinte por cento) atribuído a causa.</i><br/><br/>
                                    <i>Considerando a inexistência das garantias locatícias para esse Contrato, previstas no art. 37, da Lei das Locações, o(s) LOCATÁRIO(S) declara(m)
                                     e concorda(m), desde já, que a ausência ou atraso de pagamento de qualquer parcela do Aluguel ou encargos locatícios, e devidamente comunicado de seu 
                                     inadimplemento, ao(s) LOCADOR(ES) será(ão) conferido(s) de direito de exigir, com amparo no art. 59, §1º, da Lei de Locações, a liminar para desocupação 
                                     em 15 (quinze) dias, independentemente da audiência da parte contrária, e desde que prestada a caução no valor equivalente a 03 (três) alugueis.</i>

                                    </div>`,
                                    attachments: [{path: pdf.output('datauristring')}],
                                    extras: {
                                        cliente: this.state.pessoasBusca[0].cliente,
                                        consulta: this.state.consulta_id
                                    }
                            }).then((retorno) => {
                                console.log(retorno)
                                // document.getElementById('aguardando').style.display = 'none';
                                // document.getElementById('bloquearClick').disabled = false;
                                // this.setState({mensagemModal: <div>
                                //                         Carta de garantia enviada com sucesso.
                                //                     </div>})
                                // this.toggleAlert()
                                try{
                                    // ReactDOM.render(<div></div>, document.getElementById('carta'))
                                }catch(error){
                                    console.log(error.message)
                                }
                                // var consulta = this.state.consulta_id
                                // new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                                    //     this.novaConsulta()
                                    //     this.props.history.push('/admin/formcontratocarta/' + codigo)
                                    // })
                                })
                                
                                api_epar.post( '/sendemail', {
                                    subject: 'ALUG+ CONSULTA APROVADA',
                                    html: `<div>
                                        <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                                        Informamos que a consulta abaixo foi aprovada:<br/><br/>
                                        ID da consulta: ${consulta}.<br/>
                                        Empresa (Imobiliária): ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}. <br/>
                                        Nome do locatário: ${carta.data.data[0].retorno_json && JSON.parse(carta.data.data[0].retorno_json) && JSON.parse(carta.data.data[0].retorno_json).content.nome.conteudo.nome}. <br/>
                                        CPF: ${carta.data.data[0].cpf_cnpj}. <br/>
                                        Valor da garantia: ${FuncoesComum.formatarMoeda(parseFloat(this.state.pessoasBusca[0].renda) * this.state.porcentagemCarta)}.  <br/><br/>
                                    </div>`,
                                    attachments: [{path: pdf.output('datauristring')}],
                                    extras: {
                                        cliente: this.state.pessoasBusca[0].cliente,
                                        consulta: this.state.consulta_id
                                    }
                                })

                                api_epar.post( '/sendalugmais', {
                                    mensagem: `ALUG+ CONSULTA APROVADA\n\nInformamos que a consulta abaixo foi aprovada:\n\nID da consulta: ${consulta}.\nEmpresa (Imobiliária): ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}. \nNome do locatário: ${carta.data.data[0].retorno_json && JSON.parse(carta.data.data[0].retorno_json) && JSON.parse(carta.data.data[0].retorno_json).content.nome.conteudo.nome}. \nCPF: ${carta.data.data[0].cpf_cnpj}. \nValor da garantia: ${FuncoesComum.formatarMoeda(parseFloat(this.state.pessoasBusca[0].renda) * this.state.porcentagemCarta)}.  \n\n`
                                })
                            // api_epar.post( '/sendemailcarta', {
                            //         to: this.state.emailEnviarCarta,
                            //         subject: 'Carta de crédito',
                            //         // text: 'Era só o segundo teste mesmo',
                            //         html: '<div></div>',
                            //         attachments: [{path: pdf.output('datauristring')}]
                            //         // attachments: [{path: imgData}]
                            // }).then((retorno) => {
                            //     console.log(retorno)
                            //     // document.getElementById('aguardando').style.display = 'none';
                            //     // document.getElementById('bloquearClick').disabled = false;
                            //     // this.setState({mensagemModal: <div>
                            //     //                         Carta de garantia enviada com sucesso.
                            //     //                     </div>})
                            //     // this.toggleAlert()
                            //     // ReactDOM.render(<div></div>, document.getElementById('carta'))
                            //     // var consulta = this.state.consulta_id
                            //     // new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                            //     //     this.novaConsulta()
                            //     //     this.props.history.push('/admin/formcontratocarta/' + codigo)
                            //     // })
                            // })
                            // this.toggleLoad()  
                            // this.props.history.push('/admin/formcontratocarta/' + codigo)
                        });
                        
                        // this.toggleLoad()  
                        // this.props.history.push('/admin/formcontratocarta/' + codigo)
                    }).catch((error) => {
                        console.log(error)
                    })                
                    // this.toggleLoad()  
                    // this.props.history.push('/admin/formcontratocarta/' + codigo)
                })
                    
            }
        })
        // return codigo
    }

    closeModalEmail = () => {
        ReactDOM.render(<div></div>, document.getElementById('enviarcarta'))
    }

    atualizarStatusConsulta = () => {
        api_epar.get('/consultastatus/' + this.state.consulta_id).then((status) => {
            if(status.data.data.length > 0){
                var status_final = status.data.data[0].score_status === 0 || status.data.data[0].pendenciafinanceira_status === 0 || 
                                   status.data.data[0].processo_status === 0 || status.data.data[0].antecedente_status === 0 ? 0 :
                                   status.data.data[0].score_status === 1 || status.data.data[0].pendenciafinanceira_status === 1 || 
                                   status.data.data[0].processo_status === 1 || status.data.data[0].antecedente_status === 1 ? 1 : 2

                api_epar.put('/consultastatus', {
                    status_final: status_final,
                    id: this.state.consulta_id
                })

                return status_final

            }   
        })
    }
  

    // clickTeste = () => {
    //     console.log('Aqui')
    //     var v = 'Olá'
    //     if(this.state.interval === 0){
    //         this.setState({interval: 1})
    //         var cont = 0
    //         const intervalFuncao = setInterval(() => {
    //             cont++
    //             console.log('30 segundos ' + v)
    //             this.clickTeste()
    //             if(cont === 2){
    //                 clearInterval(intervalFuncao)
    //             }
    //         }, 30000)
    //     }
    // }

    validarCPF = (strCPF) => {
        var Soma;
        var Resto;
        Soma = 0;
        strCPF = strCPF.replace(/\D/g, '')
      if (strCPF == "00000000000" ||
        strCPF == "11111111111" ||
        strCPF == "22222222222" ||
        strCPF == "33333333333" ||
        strCPF == "44444444444" ||
        strCPF == "55555555555" ||
        strCPF == "66666666666" ||
        strCPF == "77777777777" ||
        strCPF == "88888888888" ||
        strCPF == "99999999999"
      ){
        this.setState({mensagemModal: <div>
            CPF inválido
                </div>})
        this.toggleAlert() 
          return false;
      } 
         
      for (var i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
      Resto = (Soma * 10) % 11;
       
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ){
            this.setState({mensagemModal: <div>
                CPF inválido
                    </div>})
            this.toggleAlert() 
            return false;
        } 
       
      Soma = 0;
        for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
       
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ){
            this.setState({mensagemModal: <div>
                CPF inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }  
        return true;
    }

    validarCNPJ = (cnpj) => {
 
        cnpj = cnpj.replace(/[^\d]+/g,'');
     
        if(cnpj == '') {
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
         
        if (cnpj.length != 14){
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
     
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999"){
                this.setState({mensagemModal: <div>
                    CNPJ inválido
                        </div>})
                this.toggleAlert()  
                return false;
            }
             
        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0,tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)){
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
             
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)){
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
                    
        return true;
        
    }

    validarCPFsemAlert = (strCPF) => {
        var Soma;
        var Resto;
        Soma = 0;
        strCPF = strCPF.replace(/\D/g, '')
      if (strCPF == "00000000000"){
          return false;
      } 
         
      for (var i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
      Resto = (Soma * 10) % 11;
       
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ){
            return false;
        } 
       
      Soma = 0;
        for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
       
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ){
            return false;
        }  
        return true;
    }

    validarCNPJsemAlert = (cnpj) => {
 
        cnpj = cnpj.replace(/[^\d]+/g,'');
     
        if(cnpj == '') { 
            return false;
        }
         
        if (cnpj.length != 14){
            return false;
        }
     
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999"){ 
                return false;
            }
             
        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0,tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)){
            return false;
        }
             
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)){
            return false;
        }
                    
        return true;
        
    }

  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  closeModalEncargos = () => this.setState({openModalValorEncargo: !this.state.openModalValorEncargo})


  render() {
    return (
      <>
        <Modal isOpen={this.state.openModalValorEncargo} toggle={this.closeModalEncargos}>

            <ModalBody>
                
                O valor do encargo é maior que {this.state.valorTaxaCardPorcentagem * 100}% do valor da renda informada

            </ModalBody>
            <ModalFooter>
                <Button className="btn-dark" onClick={this.closeModalEncargos}>Fechar</Button>
            </ModalFooter>                                    
        </Modal>


        <Header style={{height: '10vh'}} />

        <Container className={"mb-5 d-flex justify-content-center rebocoDeCarta"} fluid style={{minHeight: '65vh'}}>
            <div className="progress" style={{position: 'absolute'}}>
                <div className={"progress-bar bg-dark "} style={{width: this.state.barraProgress+'%'}} role="progressbar" aria-valuemax="100"></div>
            </div>
            <Nav style={{position: 'absolute'}} className='text-center mt-4 d-flex justify-content-center'>
                <Col>
                    <NavItem  className='d-flex justify-content-center'>
                        {/* <NavLink  className={classnames({ activeOpcaoPrincipal: this.state.activeTabPrincipal === '1', navPrincipal: true })} onClick={() => {this.togglePrincipal('1')}}> */}
                        <NavLink  className={classnames({ activeOpcaoPrincipal: this.state.activeTabPrincipal === '1', navPrincipal: true })} >
                            1
                        </NavLink>
                    </NavItem>
                    <Label className='ocultarTextLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Dados da consulta</Label>
                    <Label className='exibirCelularLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Consulta</Label>
                </Col>
                {/* <Col>
                    <NavItem className='d-flex justify-content-center'>
                        <NavLink className={classnames({ activeOpcaoPrincipal: this.state.activeTabPrincipal === '2', navPrincipal: true })}>
                            2
                        </NavLink>
                    </NavItem>
                    <Label className='ocultarTextLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Valor do aluguel</Label>
                    <Label className='exibirCelularLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Aluguel</Label>
                </Col> */}
                <Col>
                    <NavItem className='d-flex justify-content-center'>
                        {/* <NavLink  className={classnames({ activeOpcaoPrincipal: this.state.activeTabPrincipal === '3', navPrincipal: true })} onClick={() => {this.togglePrincipal('3')}}> */}
                        <NavLink  className={classnames({ activeOpcaoPrincipal: this.state.activeTabPrincipal === '2', navPrincipal: true })}>
                            2
                        </NavLink>
                    </NavItem>
                    <Label className='ocultarTextLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Confirmação do dados</Label>
                    <Label className='exibirCelularLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Dados</Label>
                </Col>
                <Col>
                    <NavItem className='d-flex justify-content-center'>
                        {/* <NavLink  className={classnames({ activeOpcaoPrincipal: this.state.activeTabPrincipal === '4', navPrincipal: true })} onClick={() => {this.togglePrincipal('4')}}> */}
                        <NavLink  className={classnames({ activeOpcaoPrincipal: this.state.activeTabPrincipal === '3', navPrincipal: true })} >
                            3
                        </NavLink>
                    </NavItem>
                    <Label className='ocultarTextLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Validação da consulta</Label>
                    <Label className='exibirCelularLine' style={{fontWeight: 'bold', fontSize: '0.8em'}}>Validar</Label>
                </Col>
            </Nav>
            <div className="text-center">
                <TabContent className='mt-7 pt-6' activeTab={this.state.activeTabPrincipal}>
                    <i className={"ni ni-single-02 text-white p-5 rounded-circle" + FuncoesComum.solida()} style={{fontSize: '5em'}}/>
                    <div className={"mt-3 p-2"} style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                        Consulta Pessoa Física
                    </div>
                    <TabPane tabId="1" className='mt-5'>
                        <Row className='text-center d-flex justify-content-center'>
                            <Nav className='mt--4 p-5 ' pills>
                                <NavItem className='' >
                                    <NavLink className={classnames({ activeOpcao: this.state.activeTab === '1', itemConsulta: true })} onClick={() => {this.toggle('1')}}>
                                        Desejo apenas informar o CPF
                                    </NavLink>
                                </NavItem>
                                <NavItem className=''>
                                    <NavLink className={classnames({ activeOpcao: this.state.activeTab === '2', itemConsulta: true })} onClick={() => {this.toggle('2')}}>
                                        Desejo validar um documento
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Row>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1" className='mt-3'>
                                <div className='mb-3' style={{fontSize: '1.5em'}}>
                                    Informe o CPF e a RENDA LÍQUIDA:
                                </div>
                                <Label className='mt-3' style={{fontSize: '1em', textAlign: 'left', width: '100%'}}><strong>Insira o CPF:</strong></Label>
                                <InputGroup className="input-group-alternative mt--2" style={{border: '1px solid #808080'}}>
                                    <InputGroupAddon addonType="prepend" >
                                        <InputGroupText className='pl-3 pr-3' style={{backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                            <i className="ni ni-badge text-black" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={this.state.dado} onChange={this.changeDado} onBlur={() => {this.validarCPF(this.state.dado)}} className='pl-3' style={{color: 'black', borderLeft: '1px solid #808080', fontSize: '1em'}} placeholder="Ex.: 999.999.999-99" type="text" mask={'999.999.999-99'} maskChar=' ' tag={InputMask}/>
                                </InputGroup>

                                <Label className='mt-3' style={{fontSize: '1em', textAlign: 'left', width: '100%'}}><strong>Renda líquida ou salário líquido é o valor restante de descontos e tarifas como: INSS e imposto de renda</strong></Label>
                                <InputGroup className="input-group-alternative mt--2 bg-white" style={{border: '1px solid #808080'}}>
                                    <InputGroupAddon addonType="prepend" >
                                        <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                            <strong>R$</strong>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <div style={{width: 'calc(100% - 60px) ', display: 'flex', justifyContent: 'space-between'}}>
                                        {/* <Input value={this.state.renda} onChange={this.changeRenda} className='pl-3' style={{color: 'black', borderLeft: '1px solid #808080', fontSize: '1em'}} placeholder="Ex.: 999,99" type="text"/> */}
                                        <CurrencyInput className={'pl-3'} style={{border: 'none', width: '50%', borderRadius: '5px', marginLeft: '1%'}} decimalSeparator="," thousandSeparator="." value={(this.state.renda).toString().replace('.', ',')} onChange={this.changeRenda} />
                                        <div className='tooltipnew'>
                                            <i className="fas fa-info p-3"/>
                                            <span className="tooltiptext">Renda líquida ou salário líquido é o valor restante de descontos e tarifas como: INSS e imposto de renda.</span>
                                        </div>
                                        {/* <div style={{position: 'relative', float: 'right'}} data-toggle="tooltip" data-placement="top" title="Renda líquida ou salário líquido é o valor restante de descontos e tarifas como: INSS e imposto de renda." >
                                            <i className="fas fa-info p-3"/>
                                        </div> */}
                                    </div>
                                </InputGroup>


                                {
                                    this.state.enableEncargos == "Sim" ? (
                                        <>
                                            <Label className='mt-3' style={{fontSize: '1em', textAlign: 'left', width: '100%'}}><strong>Valor da locação + Encargos</strong></Label>
                                            <InputGroup className="input-group-alternative mt--2 bg-white" style={{border: '1px solid #808080'}}>
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                                        <strong>R$</strong>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <div style={{width: 'calc(100% - 60px) ', display: 'flex', justifyContent: 'space-between'}}>
                                                    <CurrencyInput className={'pl-3'} style={{border: 'none', width: '50%', borderRadius: '5px', marginLeft: '1%'}} decimalSeparator="," thousandSeparator="." value={(this.state.encargosValor).toString().replace('.', ',')} onChange={this.changeEncargoValor} />
                                                    <div className='tooltipnew'>
                                                        <i className="fas fa-info p-3"/>
                                                        <span className="tooltiptext"></span>
                                                    </div>
                                                </div>
                                            </InputGroup>
                                        </> 
                                    ) : (null)
                                }

                                {this.state.enviarCarta === 'Automático' && 
                                <><Label className='mt-3' style={{fontSize: '1em', textAlign: 'left', width: '100%'}}><strong>Insira um email para receber a carta de crédito:</strong></Label>
                                <InputGroup className=" mt-3  input-group-alternative mt--2" style={{border: '1px solid #808080'}}>
                                    <InputGroupAddon addonType="prepend" >
                                        <InputGroupText className='pl-3 pr-3' style={{backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                            <i className="fas fa-at text-black" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type='text' value={this.state.emailEnviarCarta} onChange={this.changeEmailEnviarCarta} className='pl-3' style={{color: 'black', borderLeft: '1px solid #808080', fontSize: '1em'}} placeholder='email@email.com'/>
                                </InputGroup></>}
                                
                                {this.state.finalidadeLocacao === 'Comercial' && 
                                <><Label className='mt-3' style={{fontSize: '1em', textAlign: 'left', width: '100%'}}><strong>Qual a finalidade de locação?</strong></Label>
                                <InputGroup className=" mt-3  input-group-alternative mt--2" style={{border: '1px solid #808080'}}>
                                    <InputGroupAddon addonType="prepend" >
                                        <InputGroupText className='pl-3 pr-3' style={{backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                            <i className="fas fa-home text-black" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type='text' value={this.state.qual_finalidadeLocacao} onChange={this.changeQualFinalidadeLocacao} className='pl-3' style={{color: 'black', borderLeft: '1px solid #808080', fontSize: '1em'}} placeholder=''/>
                                </InputGroup></>}
                                <div className='mt-2 text-left' style={{fontSize: '0.8em'}}>
                                    {/* Quantidade de solidário(s): {this.state.qtdSolidario} */}
                                    {/* <Button className={'mt-8 float-left btn-dark'} style={{color: 'white'}} onClick={this.clickTeste}>Teste</Button> */}
                                </div>



                                <Row>
                                    <Col>
                                        {/* <Label className='mt-8 float-left'><strong>Adicionar Solidário? </strong><Input id='inputAddSolidario' style={{marginTop: '6px'}} className='ml-2' type='checkbox' onChange={this.toggleModalAdd} /> <Label className='ml-4'>Sim</Label></Label> */}
                                        {/* <Button className={'mt-8 float-left btn-dark'} style={{color: 'white'}} onClick={this.toggleModal}>Adicionar solidário</Button> */}
                                        <Button className={'mt-8 float-right' + FuncoesComum.solida()} onClick={this.proximaEtapa} style={{color: 'white'}}>Continuar</Button>
                                    </Col>
                                </Row>



                                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                                    <ModalHeader toggle={this.toggleModal}>Adicionar Locatário Solidário</ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-active-40" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input style={{color: 'black'}} type="select" onChange={this.mascaraSolidario}>
                                                        <option value='cpf'>CPF</option>
                                                        <option value='cnpj'>CNPJ</option>
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                            <Col md={12}>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-chat-round" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input style={{color: 'black'}} name='dado' placeholder="CPF / CNPJ" type="text" value={this.state.dadoSolidario} onChange={this.changeDadoSolidario} mask={this.state.maskSolidario} maskChar=' ' tag={InputMask} onBlur={((this.state.dadoSolidario.length <= 14 && (() => {this.validarCPF(this.state.dadoSolidario)})) || (this.state.dadoSolidario.length > 14 && this.state.dadoSolidario.length <=  18 && (() => {this.validarCNPJ(this.state.dadoSolidario)})))}/>
                                                </InputGroup>
                                            </Col>          
                                            <Col md={12}>
                                                <InputGroup className="input-group-alternative mb-3 p-1">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-money-coins" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    {/* <Input style={{color: 'black'}} placeholder="Renda" type="number" value={this.state.rendaSolidario} onChange={this.changeRendaSolidario}/> */}
                                                    <CurrencyInput className={'pl-0'} style={{fontSize: '0.9em', border: 'none', width: '70%', borderRadius: '5px', marginLeft: '1%'}} decimalSeparator="," thousandSeparator="." value={(this.state.rendaSolidario).toString().replace('.', ',')} onChange={this.changeRendaSolidario} />
                                                </InputGroup>
                                            </Col>
                                            <Col md={12}>
                                                <InputGroup className="input-group-alternative mb-3 p-1">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fas fa-question" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type='select' value={this.state.residir} onChange={this.changeResidir}>
                                                        <option value=''>Irá residir no imóvel</option>
                                                        <option value='Sim'>Sim</option>
                                                        <option value='Não'>Não</option>
                                                    </Input>
                                                    {/* <CurrencyInput className={'pl-0'} style={{fontSize: '0.9em', border: 'none', width: '70%', borderRadius: '5px', marginLeft: '1%'}} decimalSeparator="," thousandSeparator="." value={(this.state.rendaSolidario).toString().replace('.', ',')} onChange={this.changeRendaSolidario} /> */}
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        {/* <Button className="btn-dark" onClick={((this.state.dadoSolidario.length <= 14 && (() => {this.validarCPF(this.state.dadoSolidario)})) || (this.state.dadoSolidario.length > 14 && this.state.dadoSolidario.length <=  18 && (() => {this.validarCNPJ(this.state.dadoSolidario)}))) && this.addSolidario}>Adicionar</Button> */}
                                        <Button className="btn-dark" onClick={this.addSolidario}>Adicionar</Button>
                                        <Button className="btn-dark" onClick={this.toggleModal}>Cancelar</Button>
                                    </ModalFooter>
                                </Modal>
                            </TabPane>


                            <TabPane tabId="2" className='mt-3'>
                                <div className='mb-3' style={{fontSize: '1.5em'}}>
                                    Escolha o tipo do <strong>documento</strong>
                                </div>
                                <div className='mb-5'>
                                    <Label className='btn p-3' style={{backgroundColor: '#333333', color: 'white', fontSize: '1.2em'}}><i className="ni ni-badge text-black" /> Validar CNH</Label>
                                    <Label className='btn p-3' style={{backgroundColor: '#333333', color: 'white', fontSize: '1.2em'}}><i className="ni ni-single-02 text-black" /> Validar RG</Label>
                                </div>
                                <div className='mb-3' style={{fontSize: '1.5em'}}>
                                    Documento <strong>Frente</strong>
                                </div>
                                <div className='p-2'>
                                    <div className='float-left pl-6 pr-4 pt-2'>
                                        <i className="ni ni-badge" style={{fontSize: '5em', color: '#666666'}} />
                                    </div>
                                    <div className='text-left'>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Lado <strong>COM</strong> a foto</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Use um fundo uniforme</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Tire o documento da capinha</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Tire a foto reta</Label><br/>
                                    </div>
                                    <Button className={FuncoesComum.solida() + 'w-50 mt-3'} style={{color: 'white'}}>Anexar</Button>
                                </div>
                                <div className='mb-3 mt-5' style={{fontSize: '1.5em'}}>
                                    Documento <strong>Verso</strong>
                                </div>
                                <div className='p-2'>
                                    <div className='float-left pl-6 pr-4 pt-2'>
                                        <i className="ni ni-credit-card" style={{fontSize: '5em', color: '#666666'}} />
                                    </div>
                                    <div className='text-left'>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Lado <strong>SEM</strong> a foto</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Use um fundo uniforme</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Tire o documento da capinha</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Tire a foto reta</Label><br/>
                                    </div>
                                    <Button className={FuncoesComum.solida() + 'w-50 mt-3'} style={{color: 'white'}}>Anexar</Button>
                                </div>
                                <div className='mb-3 mt-5' style={{fontSize: '1.5em'}}>
                                    Anexar <strong>Selfie</strong>
                                </div>
                                <div className='p-2'>
                                    <div className='float-left pl-6 pr-4 pt-2'>
                                        <i className="ni ni-single-02" style={{fontSize: '5em', color: '#666666'}} />
                                    </div>
                                    <div className='text-left'>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Foto do rosto</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Use um fundo uniforme</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Deixe o rosto iluminado</Label><br/>
                                        <Label className='mb--1'><i className="ni ni-check-bold text-green" /> Não use acessórios</Label><br/>
                                    </div>
                                    <Button className={FuncoesComum.solida() + 'w-50 mt-3'} style={{color: 'white'}}>Anexar</Button>
                                </div>
                                <Button className={FuncoesComum.solida() + 'w-50 mt-8'} style={{color: 'white'}}>Continuar</Button>
                            </TabPane>
                        </TabContent>
                    
                    </TabPane>
                    {/* <TabPane tabId="2" className='mt-5'>
                        <div className='mb-3 mt-3' style={{fontSize: '1.5em'}}>
                            Valor de aluguel:
                        </div>
                        <InputGroup className="input-group-alternative mb-4 w-100 bg-white" style={{border: '1px solid #808080'}}>
                            <InputGroupAddon addonType="prepend" >
                                <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                    <strong>R$</strong>
                                </InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput className={'pl-3'} style={{border: 'none', width: '70%', borderRadius: '5px', marginLeft: '3%'}} decimalSeparator="," thousandSeparator="." value={(this.state.aluguel).toString().replace('.', ',')} onChange={this.changeAluguel} />
                        </InputGroup>

                        <div className='mb-3 mt-3' style={{fontSize: '1.5em'}}>
                            Valor Aproximado do condomínio (se existir):
                        </div>
                        <InputGroup className="input-group-alternative mb-4 w-100 bg-white" style={{border: '1px solid #808080'}}>
                            <InputGroupAddon addonType="prepend" >
                                <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                    <strong>R$</strong>
                                </InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput className={'pl-3'} style={{border: 'none', width: '70%', borderRadius: '5px', marginLeft: '3%'}} decimalSeparator="," thousandSeparator="." value={(this.state.condominio).toString().replace('.', ',')} onChange={this.changeCondominio} />
                        </InputGroup>

                        <div className='mb-3 mt-3' style={{fontSize: '1.5em'}}>
                            Valor Aproximado do IPTU (se existir):
                        </div>
                        <InputGroup className="input-group-alternative mb-4 w-100 bg-white" style={{border: '1px solid #808080'}}>
                            <InputGroupAddon addonType="prepend" >
                                <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                    <strong>R$</strong>
                                </InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput className={'pl-3'} style={{border: 'none', width: '70%', borderRadius: '5px', marginLeft: '3%'}} decimalSeparator="," thousandSeparator="." value={(this.state.imposto).toString().replace('.', ',')} onChange={this.changeImposto} />
                        </InputGroup>
                        
                        <div className='mb-3 mt-3' style={{fontSize: '1.5em'}}>
                            Valor da Luz (se existir):
                        </div>
                        <InputGroup className="input-group-alternative mb-4 w-100 bg-white" style={{border: '1px solid #808080'}}>
                            <InputGroupAddon addonType="prepend" >
                                <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                    <strong>R$</strong>
                                </InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput className={'pl-3'} style={{border: 'none', width: '70%', borderRadius: '5px', marginLeft: '3%'}} decimalSeparator="," thousandSeparator="." value={(this.state.luz).toString().replace('.', ',')} onChange={this.changeLuz} />
                        </InputGroup>

                        <div className='mb-3 mt-3' style={{fontSize: '1.5em'}}>
                            Valor da Água (se existir):
                        </div>
                        <InputGroup className="input-group-alternative mb-4 w-100 bg-white" style={{border: '1px solid #808080'}}>
                            <InputGroupAddon addonType="prepend" >
                                <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                    <strong>R$</strong>
                                </InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput className={'pl-3'} style={{border: 'none', width: '70%', borderRadius: '5px', marginLeft: '3%'}} decimalSeparator="," thousandSeparator="." value={(this.state.agua).toString().replace('.', ',')} onChange={this.changeAgua} />
                        </InputGroup>

                        <div className='mb-3 mt-3' style={{fontSize: '1.5em'}}>
                            Valor da Gás (se existir):
                        </div>
                        <InputGroup className="input-group-alternative mb-4 w-100 bg-white" style={{border: '1px solid #808080'}}>
                            <InputGroupAddon addonType="prepend" >
                                <InputGroupText className='pl-3 pr-3' style={{borderRight: '1px solid #808080', backgroundColor: '#f2f2f2', color: '#262626', fontSize: '1.1em'}}>
                                    <strong>R$</strong>
                                </InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput className={'pl-3'} style={{border: 'none', width: '70%', borderRadius: '5px', marginLeft: '3%'}} decimalSeparator="," thousandSeparator="." value={(this.state.gas).toString().replace('.', ',')} onChange={this.changeGas} />
                        </InputGroup>

                        <Row>
                            <Col md={12}>
                                <Button className={'mt-6 w-75' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.proximaEtapa}>CONTINUAR</Button>
                            </Col>
                            <Col md={12}>
                                <Button className='mt-1 w-75' style={{backgroundColor: '#333333', color: 'white'}} onClick={this.voltarEtapa}>Voltar</Button>
                            </Col>
                        </Row>
                    </TabPane> */}
                    <TabPane tabId="2" className='mt-5'>
                        <div className='mb-3 mt-3' style={{fontSize: '1.5em'}}>
                            Confirme os dados da sua consulta:
                        </div>
                        <div className='mb-1' style={{fontSize: '1.1em'}}>
                            Documento a ser consultado: {this.state.pessoasBusca.map(this.rowDocumentos)}
                        </div>
                        {/* <div className='mb-3' style={{fontSize: '1.1em'}}>
                            Valor do aluguel: {FuncoesComum.formatarMoeda(parseFloat(this.state.valor))}
                        </div> */}
                        {/* <div className='mb-1 mt-5' style={{fontSize: '1.1em'}}>
                            <strong>Custo da consulta</strong>
                        </div>
                        <div className='mb-3' style={{fontSize: '1.1em'}}>
                            Valor da consulta: {FuncoesComum.formatarMoeda(this.state.pessoasBusca.length * (this.state.valorConsulta1 + this.state.valorConsulta2 + this.state.valorConsulta3))}
                        </div> */}
                        <Row>
                            <Col md={12}>
                                <Button className={'mt-4 w-75' + FuncoesComum.solida()} style={{color: 'white'}} onClick={this.proximaEtapa}>CONTINUAR</Button>
                            </Col>
                            <Col md={12}>
                                <Button className='mt-1 w-75' style={{backgroundColor: '#333333', color: 'white'}} onClick={this.voltarEtapa}>Voltar</Button>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3" className='mt-5'>
                        {this.state.pessoasBusca.map(this.carregarRetorno)}
                        <div id='retornoGeral'></div>
                        {/* <Button className='mt-1 float-left' style={{backgroundColor: '#333333', color: 'white'}} onClick={this.novaConsulta}>Nova consulta</Button>
                        <Button className={'mt-1 float-right' + FuncoesComum.solida()} style={{color: 'white'}} to={'/admin/newdetalhe/' + this.state.consulta_id} tag={Link} onClick={this.proximaEtapa}>Finalizar consulta</Button>   */}
                        {/* <Row className='pt-2' style={{borderRadius: '5px', backgroundColor: '#d9d9d9', color: 'black', maxWidth: '80vh'}}>
                            <Col className='float-left mt-2' md={6}>
                                <Label style={{backgroundColor: '#006600', borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>Nome: Otávio Romualdo Barbosa</Label>
                            </Col>
                            <Col md={4} className='float-left mt-2'>
                                <Label style={{backgroundColor: '#006600', borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>Documento: 111.111.111-11</Label>
                            </Col>
                            <Col md={2}>
                                <Button style={{backgroundColor: '#006600', fontSize: '0.8em', color: 'white'}} className='mb-2 float-right'>Revisar</Button>
                            </Col>
                        </Row>
                        <Row className='mt-5 p-2' style={{border: '1px solid  #d9d9d9', borderRadius: '5px', maxWidth: '80vh'}}>
                            <Col md={4}>
                                <i className={"ni ni-check-bold text-white p-4 rounded-circle"} style={{marginTop: '6vh', fontSize: '4em', backgroundColor: '#006600'}}/>
                            </Col>
                            <Col md={8}>
                                <div className='mb-3 ' style={{fontSize: '1.3em', color: '#006600'}}>
                                    <strong>PODE ALUGAR!</strong>
                                </div><br/>
                                <div className='mb-3 float-left text-left' style={{fontSize: '0.9em'}}>
                                    Analisamos as informações e concluímos que o candidato preenche todos os requisitos necessários para ser um cliente em potencial.
                                </div><br/>
                                <div className='mb-3 float-left text-left' style={{fontSize: '0.8em'}}>
                                    Informamos que a decisão de aprovação ou não da pessoa pesquisada é de exclusiva responsabilidade do(a) requerente, sendo que o parecer prestado possui objetivo apenas de balizar a decisão a ser tomada.
                                </div>
                            </Col>
                        </Row> */}
                    </TabPane>
                </TabContent>
                <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
                    <ModalHeader toggle={this.toggleAlert}>Alerta</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                {this.state.mensagemModal}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-dark" onClick={this.toggleAlert}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.alertSolidario} toggle={this.toggleAlertSolidario}>
                    <ModalHeader toggle={this.toggleAlertSolidario}>Adicionar solidário</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                {this.state.mensagemModalSolidario}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-success float-left" onClick={this.toggleModal}>Sim</Button>
                        <Button className="btn-danger float-right" onClick={this.toggleAlertSolidario}>Não</Button>
                    </ModalFooter>
                </Modal>
                
                <div id='arquivos'>

                </div>
                <div id='enviarcarta'>

                </div>
            </div>
        </Container>
        <Modal className='d-flex justify-content-center align-items-center' isOpen={this.state.load} >
              <ModalHeader>AGUARDE...</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} className='d-flex justify-content-center align-items-center'>
                          <img src={require('assets/img/loading.gif')} className='p-5 w-50 h-100'/>
                        </Col>
                    </Row>
                </ModalBody>
          </Modal>
        <div style={{marginTop: '80vh', width: '100%'}}>
            <div style={{width: '100%', height: '50%'}} id='carta'>

            </div>
        </div>
      </>
    );
  }
}

export default PessoaFisica;
