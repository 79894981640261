/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Nav, 
    NavItem, 
    NavLink,
    TabPane,
    TabContent,
    Label,
    FormGroup
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum';
import InputMask from 'react-input-mask'
import $ from 'jquery'
import DataTable from 'datatables.net'
import "assets/scss/relatorios.scss";
import {Link} from 'react-router-dom'
import api_admin from '../services/apiadmin'
import ReactGA from 'react-ga'

class ListaUsuarios extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           usuarios: []
        }
    }

    componentDidMount(){

      // Analytics
      ReactGA.initialize(process.env.REACT_APP_UA)
      ReactGA.pageview(window.location.pathname + window.location.search)

        // Busca as imobiliárias e os usuários cadastrados
        axios.get(api_admin + '/imobiliaria/imobuser').then((users) => {
            // console.log(users.data)
            console.log(users.data.imobs.filter(e => {
                return e.imobiliaria_id === JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id
            }))

            // Usuários irá receber somente os usuários referente a imobiliária conectado no sistema
            this.setState({usuarios: users.data.imobs.filter(e => {
                return e.imobiliaria_id === JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id
            })})
            $(document).ready(() => {
                $('#dt_table').DataTable({
                    order: [[0, 'asc']]
                })
            })
        }).catch((error) => {
            console.log(error)
        })
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  // Exibirá cada usuário encontrado 
  rowsUsuarios = (record) => {
    
      return(
        <tr key={record.id} >
            <th scope="row">
                {record.nome}
            </th>
            <td>
                {record.usuario}
            </td>
            <td>
                {record.email}
            </td>
        </tr>
      )
    
  }

  

  render() {
    return (
      <>
        {/* <script src="https://cdn.datatables.net/1.10.20/js/dataTables.bootstrap4.min.js"></script> */}
        {/* <script src="https://cdn.datatables.net/1.10.20/js/jquery.dataTables.min.js"></script> */}
        {/* <script src="https://code.jquery.com/jquery-3.3.1.js"></script> */}
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.css"></link> */}
        <link rel="stylesheet" href="https://cdn.datatables.net/1.10.20/css/dataTables.bootstrap4.min.css"></link>
        <Header style={{height: '10vh'}} />

        <Container className={"mt-5 d-flex justify-content-center"} fluid style={{minHeight: '65vh'}}>
          <table id="dt_table" className="table table-responsive table-striped" style={{width: "80vw"}}>
              <thead >
                  <tr >
                      <th style={{width: "40vw"}}>Nome</th>
                      <th style={{width: "20vw"}}>Usuário</th>
                      <th style={{width: "40vw"}}>Email</th>
                  </tr>
              </thead>
              <tbody>
                {this.state.usuarios.map(this.rowsUsuarios)}
              </tbody>
              <tfoot>
              <tr>
                    <td colSpan='3'>
                        <Link className='btn btn-dark float-right' to='/admin/adduser'>Adicionar usuário</Link>
                    </td>
                </tr>
              </tfoot>
          </table>
        </Container>
      </>
    );
  }
}

export default ListaUsuarios;
