import React from "react";
// node.js library that concatenates classes (strings)
import Chart from "chart.js";
// react plugin used to create charts
import {
    Row,
    Container,
    Button,
    Col,
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import axios from 'axios'
import FuncoesComum from "../funcoes/FuncoesComum";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import api_admin from '../services/apiadmin'
import {api_epar} from '../services/apisaxios'

class Download extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locador_nome_razao: '',
            locador_cpf_cnpj: '',
            locador_endereco: '',
            locador_cep: '',
            locador_cidade: '',
            locador_uf: '',
            locatario_nome_razao: '',
            locatario_cpf_cnpj: '',
            representante_razaosocial_nome: '',
            representante_cnpj_cpf: '',
            numero_garantia: '',
            data_emissao: '',
            imovel_tipo: '',
            de: '',
            ate: '',
            forma_pagamento: 'PAGAMENTO FATURA MENSAL',
            aluguel: '',
            iptu: '',
            condominio: '',
            gas: '',
            agua: '',
            luz: '',
            valor_taxa_mensal: '',
            valor_taxa_anual: '',
            endereco: '',
            cep: '',
            cidade: '',
            uf: '',
            solidarios: [],
            contrato: [],

            ind_cadastral: '',
            cod_energia: '',
            cod_agua: '',
            nome_adm: '',
            tel_adm: '',
            nome_sidico: '',
            tel_sidico: '',
        }
    }

    componentDidMount() {

        console.log(new URLSearchParams(window.location.search).get('enviar'))

        api_epar.get( '/dadoscontrato/' + this.props.match.params.id).then((dadosContrato) => {
            console.log(dadosContrato)
            this.setState({
                locador_nome_razao: dadosContrato.data.data[0].locador_tipo === 'PF' ? dadosContrato.data.data[0].locador_nome : dadosContrato.data.data[0].locador_razaoSocial,
                locador_cpf_cnpj: dadosContrato.data.data[0].locador_tipo === 'PF' ? dadosContrato.data.data[0].locador_cpf : dadosContrato.data.data[0].locador_cnpj,
                locador_endereco: dadosContrato.data.data[0].locador_end ? dadosContrato.data.data[0].locador_end + ', ' + dadosContrato.data.data[0].locador_endNum + ' / ' + dadosContrato.data.data[0].locador_endComplemento + ' - ' + dadosContrato.data.data[0].locador_endBairro : '',
                locador_cep: dadosContrato.data.data[0].locador_cep,
                locador_cidade: dadosContrato.data.data[0].locador_endCidade,
                locador_uf: dadosContrato.data.data[0].locador_endEstado,
                locatario_nome_razao: dadosContrato.data.data[0].locatario_tipo === 'PF' ? dadosContrato.data.data[0].locatario_nome : dadosContrato.data.data[0].locatario_razaoSocial,
                locatario_cpf_cnpj: dadosContrato.data.data[0].locatario_tipo === 'PF' ? dadosContrato.data.data[0].locatario_cpf : dadosContrato.data.data[0].locatario_cnpj,
                de: new Date(dadosContrato.data.data[0].data_inicio).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}),
                ate: dadosContrato.data.data[0].valido === '2' ? new Date(dadosContrato.data.data[0].data_fim).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}) : 'Sem data informada',
                // forma_pagamento: dadosContrato.data.data[0].tipoPag,
                imovel_tipo: dadosContrato.data.data[0].imovel_tipo,
                aluguel: dadosContrato.data.data[0].aluguel,
                iptu: dadosContrato.data.data[0].iptu,
                condominio: dadosContrato.data.data[0].condominio,
                gas: dadosContrato.data.data[0].gas,
                agua: dadosContrato.data.data[0].agua,
                luz: dadosContrato.data.data[0].luz,
                valor_taxa_mensal: dadosContrato.data.data[0].taxa_mensal ? parseFloat(dadosContrato.data.data[0].taxa_mensal) * parseFloat(dadosContrato.data.data[0].valorAluguel) : 'Sem taxa',
                valor_taxa_anual: dadosContrato.data.data[0].taxa_mensal ? (parseFloat(dadosContrato.data.data[0].taxa_mensal) * parseFloat(dadosContrato.data.data[0].valorAluguel)) * 12.0 : 'Sem taxa',
                endereco: dadosContrato.data.data[0].imovel_end ? dadosContrato.data.data[0].imovel_end + ', ' + dadosContrato.data.data[0].imovel_endNum + ' / ' + dadosContrato.data.data[0].imovel_endComplemento + ' - ' + dadosContrato.data.data[0].imovel_endBairro : '',
                cep: dadosContrato.data.data[0].imovel_cep,
                cidade: dadosContrato.data.data[0].imovel_endCidade,
                uf: dadosContrato.data.data[0].imovel_endEstado,
                solidarios: JSON.parse(dadosContrato.data.data[0].dados_solidarios),
                representante_razaosocial_nome: dadosContrato.data.data[0].razao_social,
                representante_cnpj_cpf: dadosContrato.data.data[0].cnpj,

                ind_cadastral: dadosContrato.data.data[0].indice_cadastral,
                cod_agua: dadosContrato.data.data[0].cod_agua,
                cod_energia: dadosContrato.data.data[0].cod_energia,
                nome_adm: dadosContrato.data.data[0].nome_adm,
                tel_adm: dadosContrato.data.data[0].tel_adm,
                nome_sidico: dadosContrato.data.data[0].nome_sindico,
                tel_sidico: dadosContrato.data.data[0].tel_sindico,
                contrato: dadosContrato.data.data
            })
        }).catch((error) => {
            console.log(error.message)
        })

        api_epar.get( '/dadoscontratocartagarantiacontrato/' + this.props.match.params.id).then((cartasRelacionadas) => {
            console.log(cartasRelacionadas.data.data)
            var cartas = ''
            var datas = ''

            for(var i = 0; i < cartasRelacionadas.data.data.length; i++){
                cartas += `${cartasRelacionadas.data.data[i].codigo} `
                datas += `${new Date(cartasRelacionadas.data.data[i].data_emissao).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})} `
            }

            this.setState({numero_garantia: cartas, data_emissao: datas})
        })
        .catch((error) => {
            console.log(error.message)
        })

        if(!new URLSearchParams(window.location.search).get('enviar') && !new URLSearchParams(window.location.search).get('reenviar')){
            console.log("ENNVOIOU");
            new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
                html2canvas(document.getElementById('contrato'), { width: 1500, height: 1560})
                .then(canvas => {
                    var pdf = new jsPDF()
                    const imgData = canvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 0, 0, 200, 200);
    
                    console.log(imgData)

                    api_epar.post( '/sendemail', {
                        subject: 'ALUG+ CONTRATO GERADO',
                        html: `<div>
                            <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                            A empresa ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}, gerou novo contrato, segue abaixo em anexo as informações.<br/><br/>
                            # do Contrato: ${this.state.contrato[0].id}.<br/>
                            Data de início: ${this.state.de}.<br/>
                            Data de término: ${this.state.ate}.<br/>
                            Locador: ${this.state.locador_nome_razao}.<br/>
                            Locador CPF/CNPJ: ${this.state.locador_cpf_cnpj}.<br/>
                            Locatário: ${this.state.locatario_nome_razao}.<br/>
                            Locatário CPF/CNPJ: ${this.state.locatario_cpf_cnpj}.<br/>
                            Valor do aluguel: ${this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Valor dos encargos:  ${this.state.aluguel && this.state.contrato[0].valorAluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.contrato[0].valorAluguel - this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Valor do aluguel + encargos: ${this.state.contrato[0].valorAluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.contrato[0].valorAluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Valor da garantia mensal: ${this.state.valor_taxa_mensal ? FuncoesComum.formatarMoeda(this.state.valor_taxa_mensal) : FuncoesComum.formatarMoeda(0)}.<br/>
                        </div>`,
                        attachments: [{path: pdf.output('datauristring')}]
                    })

                    api_epar.post( '/sendemailcarta', {
                        to: JSON.parse(localStorage.getItem('dadosUsuario_test')).email + ";financeiro@alugmais.com;contato@alugmais.com",
                        subject: 'ALUG+ CONTRATO GERADO',
                        html: `<div>
                            <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                            Informamos que foi gerado o contrato de GARANTIA:<br/><br/>
                            Contrato: ${this.props.match.params.id}.<br/>
                            Nome do Locador: ${this.state.locador_nome_razao}.<br/>
                            Nome do Locatário: ${this.state.locatario_nome_razao}.<br/>
                            Valor do aluguel: ${this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Encargo - Condomínio: ${this.state.condominio ? FuncoesComum.formatarMoeda(parseFloat(this.state.condominio)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Encargo - IPTU: ${this.state.iptu ? FuncoesComum.formatarMoeda(parseFloat(this.state.iptu)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Encargo - Água: ${this.state.agua ? FuncoesComum.formatarMoeda(parseFloat(this.state.agua)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Encargo - Luz: ${this.state.luz ? FuncoesComum.formatarMoeda(parseFloat(this.state.luz)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Encargo - Gás canalizado: ${this.state.gas ? FuncoesComum.formatarMoeda(parseFloat(this.state.gas)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Valor do aluguel + Encargos: ${this.state.contrato[0].valorAluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.contrato[0].valorAluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Valor da garantia mensal: ${this.state.valor_taxa_mensal ? FuncoesComum.formatarMoeda(this.state.valor_taxa_mensal) : FuncoesComum.formatarMoeda(0)}.<br/>
                            Data de início: ${this.state.de}.<br/>
                            Data de término: ${this.state.ate}.<br/><br/>

                            Agradecemos em nome da nossa parceria e esperamos que possa realizar ainda mais negócios.<br/>
                            Conte sempre conosco!<br/>
                            Equipe ALUG+.
                        </div>`,
                        attachments: [{path: pdf.output('datauristring')}]
                    })

                    api_epar.post( '/sendalugmais', {
                        mensagem: `ALUG+ CONTRATO GERADO\n\nInformamos que foi gerado o contrato de GARANTIA:\n\nContrato: ${this.props.match.params.id}.\nNome do Locador: ${this.state.locador_nome_razao}.\nNome do Locatário: ${this.state.locatario_nome_razao}.\nValor do aluguel: ${this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}.\nEncargo - Condomínio: ${this.state.condominio ? FuncoesComum.formatarMoeda(parseFloat(this.state.condominio)) : FuncoesComum.formatarMoeda(0)}.\nEncargo - IPTU: ${this.state.iptu ? FuncoesComum.formatarMoeda(parseFloat(this.state.iptu)) : FuncoesComum.formatarMoeda(0)}.\nEncargo - Água: ${this.state.agua ? FuncoesComum.formatarMoeda(parseFloat(this.state.agua)) : FuncoesComum.formatarMoeda(0)}.\nEncargo - Luz: ${this.state.luz ? FuncoesComum.formatarMoeda(parseFloat(this.state.luz)) : FuncoesComum.formatarMoeda(0)}.\nEncargo - Gás canalizado: ${this.state.gas ? FuncoesComum.formatarMoeda(parseFloat(this.state.gas)) : FuncoesComum.formatarMoeda(0)}.\nValor do aluguel + Encargos: ${this.state.contrato[0].valorAluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.contrato[0].valorAluguel)) : FuncoesComum.formatarMoeda(0)}.\nValor da garantia mensal: ${this.state.valor_taxa_mensal ? FuncoesComum.formatarMoeda(this.state.valor_taxa_mensal) : FuncoesComum.formatarMoeda(0)}.\nData de início: ${this.state.de}.\nData de término: ${this.state.ate}.\n\nAgradecemos em nome da nossa parceria e esperamos que possa realizar ainda mais negócios.\nConte sempre conosco!\nEquipe ALUG+.`
                    })


                    axios.get(api_admin + '/config/configs').then((config) => {
                        var emailfinanceiro = config.data.data.filter((c) => {
                            return c.id === 12
                        })[0].padrao
                        api_epar.post( '/sendemailcarta', {
                            to: emailfinanceiro,
                            subject: 'ALUG+ CONTRATO GERADO',
                            html: `<div>
                                <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                                Novo contrato gerado.<br/><br/>
                                Nome da empresa (Imobiliária): ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}.<br/>
                                # do Contrato: ${this.state.contrato[0].id}.<br/>
                                Data de início: ${this.state.de}.<br/>
                                Data de término: ${this.state.ate}.<br/>
                                Locador: ${this.state.locador_nome_razao}.<br/>
                                Locador CPF/CNPJ: ${this.state.locador_cpf_cnpj}.<br/>
                                Locatário: ${this.state.locatario_nome_razao}.<br/>
                                Locatário CPF/CNPJ: ${this.state.locatario_cpf_cnpj}.<br/>
                                Valor do aluguel: ${this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Valor dos encargos:  ${this.state.aluguel && this.state.contrato[0].valorAluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.contrato[0].valorAluguel - this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Valor do aluguel + encargos: ${this.state.contrato[0].valorAluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.contrato[0].valorAluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Valor da garantia mensal: ${this.state.valor_taxa_mensal ? FuncoesComum.formatarMoeda(this.state.valor_taxa_mensal) : FuncoesComum.formatarMoeda(0)}.<br/>
                            </div>`,
                            attachments: [{path: pdf.output('datauristring')}]
                        })
                    })
                })
            })
        }

        if(new URLSearchParams(window.location.search).get('reenviar') && new URLSearchParams(window.location.search).get('reenviar') === 'sim'){

            new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
                html2canvas(document.getElementById('contrato'), { width: 1500, height: 1560})
                .then(canvas => {
                    var pdf = new jsPDF()
                    const imgData = canvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 0, 0, 200, 200);

                    axios.get(api_admin + '/config/configs').then((config) => {
                        var emailfinanceiro_enviar = config.data.data.filter((c) => {
                            return c.id === 12
                        })[0].padrao
                        api_epar.post( '/sendemailcarta', {
                            to: emailfinanceiro_enviar,
                            subject: 'ENVIO DE CONTRATO',
                            html: `<div>
                                <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                                Informamos que foi gerado o contrato de GARANTIA:<br/><br/>
                                Contrato: ${this.props.match.params.id}.<br/>
                                Nome do Locador: ${this.state.locador_nome_razao}.<br/>
                                Nome do Locatário: ${this.state.locatario_nome_razao}.<br/>
                                Valor do aluguel: ${this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Encargo - Condomínio: ${this.state.condominio ? FuncoesComum.formatarMoeda(parseFloat(this.state.condominio)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Encargo - IPTU: ${this.state.iptu ? FuncoesComum.formatarMoeda(parseFloat(this.state.iptu)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Encargo - Água: ${this.state.agua ? FuncoesComum.formatarMoeda(parseFloat(this.state.agua)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Encargo - Luz: ${this.state.luz ? FuncoesComum.formatarMoeda(parseFloat(this.state.luz)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Encargo - Gás canalizado: ${this.state.gas ? FuncoesComum.formatarMoeda(parseFloat(this.state.gas)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Valor do aluguel + Encargos: ${this.state.contrato[0].valorAluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.contrato[0].valorAluguel)) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Valor da garantia mensal: ${this.state.valor_taxa_mensal ? FuncoesComum.formatarMoeda(this.state.valor_taxa_mensal) : FuncoesComum.formatarMoeda(0)}.<br/>
                                Data de início: ${this.state.de}.<br/>
                                Data de término: ${this.state.ate}.<br/><br/>

                                Agradecemos em nome da nossa parceria e esperamos que possa realizar ainda mais negócios.<br/>
                                Conte sempre conosco!<br/>
                                Equipe ALUG+.
                            </div>`,
                            attachments: [{path: pdf.output('datauristring')}]
                        })
                    })

                })
            })
        }


        // window.print()
    }

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Row>
            <Col md={12}><Button className={'btn-dark m-2 float-right'} id='no-print' onClick={() => {window.print()}}>Imprimir / Download</Button></Col>
        </Row>

        <Container id='contrato' style={{fontFamily: 'Agency FB'}}>
            <Row style={{background: '#FFFFFF'}}>
                <Col md={5}>
                    <img src={require('../assets/img/logo_carta.png')} style={{width: '200px', position: 'relative', float: 'left', margin: '5% 7%'}}/>
                </Col>
                <Col md={7}>
                    <p style={{textAlign: 'center', fontSize: '2em', color: '#660066', fontWeight: 'bolder'}}>GARANTIA ALUG+</p>
                    <p style={{marginTop: '-10px', textAlign: 'justify', padding: '1%', fontSize: '0.8em', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>
                        As condições gerais do produto, foram apresentadas ao LOCADOR/ ADMINISTRADOR previamente, 
                        tendo o mesmo aceitado as nossas condições e termos. Essa garantia, bem como demais contratações, 
                        poderão ser consultadas na nossa plataforma Alug+. www.alugmais.com, no campo login, via usuário.
                    </p>
                </Col>
            </Row>
            <Row style={{padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                1 LOCADOR
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Nome / Razão Social</Col>
                <Col md={5}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locador_nome_razao}</p>
                </Col>

                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>CPF / CNPJ</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locador_cpf_cnpj}</p>
                </Col>
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Endereço</Col>
                <Col md={10}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locador_endereco}</p>
                </Col>
                <Col md={1} style={{color: '#660066', fontWeight: 'bolder'}}>CEP</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locador_cep}</p>
                </Col>
                <Col md={1} style={{color: '#660066', fontWeight: 'bolder'}}>Cidade</Col>
                <Col md={4}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locador_cidade}</p>
                </Col>
                <Col md={1} style={{color: '#660066', fontWeight: 'bolder'}}>UF</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locador_uf}</p>
                </Col>
            </Row>
            <Row style={{padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                2 REPRESENTANTE LEGAL (LOCADOR)
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Nome / Razão Social</Col>
                <Col md={5}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.representante_razaosocial_nome}</p>
                </Col>

                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>CPF / CNPJ</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.representante_cnpj_cpf}</p>
                </Col>
            </Row>
            <Row style={{marginTop: '-5px', padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                3 LOCATÁRIO
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Nome / Razão Social</Col>
                <Col md={5}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locatario_nome_razao}</p>
                </Col>

                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>CPF / CNPJ</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.locatario_cpf_cnpj}</p>
                </Col>
            </Row>
            {this.state.solidarios.map((record, index) => {
                return (
                    <Row style={{background: '#fce4d6'}} key={index}>
                        <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Nome / Razão Social</Col>
                        <Col md={5}>
                            <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{record.tipo === 'PF' ? record.nome : record.azaoSocial}</p>
                        </Col>

                        <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>CPF / CNPJ</Col>
                        <Col md={3}>
                            <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{record.tipo === 'PF' ? record.cpf : record.cnpj}</p>
                        </Col>
                    </Row>
                )
            })}
            <Row style={{marginTop: '-5px', padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                4 GARANTIA
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Identificador do contrato</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.contrato[0] ? this.state.contrato[0].id : ""}</p>
                </Col>
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Número garantia</Col>
                <Col md={5}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.numero_garantia}</p>
                </Col>

                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Data emissão</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.data_emissao}</p>
                </Col>
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Vigência da garantia De</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.de}</p>
                </Col>
                <Col md={1} style={{color: '#660066', fontWeight: 'bolder'}}>Até</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.ate}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Forma de pagamento</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.forma_pagamento}</p>
                </Col>
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Valor do aluguel</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : ''}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Valor IPTU</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.iptu ? FuncoesComum.formatarMoeda(parseFloat(this.state.iptu)) : ''}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Valor condomínio</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.condominio ? FuncoesComum.formatarMoeda(parseFloat(this.state.condominio)) : ''}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Valor gás canalizado</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.gas ? FuncoesComum.formatarMoeda(parseFloat(this.state.gas)) : ''}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Valor luz</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.luz ? FuncoesComum.formatarMoeda(parseFloat(this.state.luz)) : ''}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Valor água</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.agua ? FuncoesComum.formatarMoeda(parseFloat(this.state.agua)) : ''}</p>
                </Col>
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={3} style={{color: '#660066', fontWeight: 'bolder'}}>Valor taxa mensal</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.valor_taxa_mensal !== 'Sem taxa' ? FuncoesComum.formatarMoeda(this.state.valor_taxa_mensal) : this.state.valor_taxa_mensal}</p>
                </Col>
                <Col md={3} style={{color: '#660066', fontWeight: 'bolder'}}>Valor taxa anual</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.valor_taxa_anual !== 'Sem taxa' ? FuncoesComum.formatarMoeda(this.state.valor_taxa_anual) : this.state.valor_taxa_anual}</p>
                </Col>
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Endereço</Col>
                <Col md={10}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.endereco}</p>
                </Col>
                <Col md={1} style={{color: '#660066', fontWeight: 'bolder'}}>CEP</Col>
                <Col md={3}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.cep}</p>
                </Col>
                <Col md={1} style={{color: '#660066', fontWeight: 'bolder'}}>Cidade</Col>
                <Col md={4}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.cidade}</p>
                </Col>
                <Col md={1} style={{color: '#660066', fontWeight: 'bolder'}}>UF</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.uf}</p>
                </Col>
            </Row>
            <Row  style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Índice cadastral</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.ind_cadastral}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Código água</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.cod_agua}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Código energia</Col>
                <Col md={2}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.cod_energia}</p>
                </Col>
            </Row>
            <Row  style={{background: '#fce4d6'}}>
                <br />
            </Row>
            <Row  style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Nome da administradora</Col>
                <Col md={4}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.nome_adm}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Telefone administradora</Col>
                <Col md={4}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.tel_adm}</p>
                </Col>
            </Row>
            <Row  style={{background: '#fce4d6'}}>
                <br />
            </Row>
            <Row  style={{background: '#fce4d6'}}>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Nome do síndico</Col>
                <Col md={4}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.nome_sidico}</p>
                </Col>
                <Col md={2} style={{color: '#660066', fontWeight: 'bolder'}}>Telefone síndico</Col>
                <Col md={4}>
                    <p style={{background: '#FFFFFF', color: '#660066', fontWeight: 'bolder', height: '26px', paddingLeft: '4px'}}>{this.state.tel_sidico}</p>
                </Col>
            </Row>
            <Row style={{marginTop: '-5px', padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                5 OBJETIVOS DA GARANTIA
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={12} style={{color: '#660066', fontWeight: 'bolder'}}>
                    Garantir ao locador o ressarcimento pelos prejuízos que venham a sofrer, 
                    em decorrência do não cumprimento pelo locatário do contrato de locação, 
                    objeto desta garantia. Respeitando para isso todas as condições do produto.
                </Col>
            </Row>
            <Row style={{padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                6 CARACTERÍSTICAS DA GARANTIA
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>GARANTIAS</Col>
                <Col md={3} style={{padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>VALOR</Col>
                <Col md={3} style={{padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>GARANTIA MÁXIMA</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>ALUGUEL</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel) * 12.0) : ''}</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>IPTU</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.iptu ? FuncoesComum.formatarMoeda(parseFloat(this.state.iptu)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.iptu ? FuncoesComum.formatarMoeda(parseFloat(this.state.iptu) * 12.0) : FuncoesComum.formatarMoeda(0)}</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>CONDOMÍNIO</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.condominio ? FuncoesComum.formatarMoeda(parseFloat(this.state.condominio)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.condominio ? FuncoesComum.formatarMoeda(parseFloat(this.state.condominio) * 12.0) : FuncoesComum.formatarMoeda(0)}</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>LUZ</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.luz ? FuncoesComum.formatarMoeda(parseFloat(this.state.luz)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.luz ? FuncoesComum.formatarMoeda(parseFloat(this.state.luz) * 12.0) : FuncoesComum.formatarMoeda(0)}</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>ÁGUA</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.agua ? FuncoesComum.formatarMoeda(parseFloat(this.state.agua)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.agua ? FuncoesComum.formatarMoeda(parseFloat(this.state.agua) * 12.0) : FuncoesComum.formatarMoeda(0)}</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>GÁS CANALIZADO</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.gas ? FuncoesComum.formatarMoeda(parseFloat(this.state.gas)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.gas ? FuncoesComum.formatarMoeda(parseFloat(this.state.gas) * 12.0) : FuncoesComum.formatarMoeda(0)}</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>AVARIAS NO IMÓVEL</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>R$ 60.000,00</Col>
            </Row>
            <Row style={{background: '#fce4d6', paddingLeft: '10px'}}>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>MULTA RESCISÓRIA</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel)) : FuncoesComum.formatarMoeda(0)}</Col>
                <Col md={3} style={{border: '1px solid #FFFFFF', padding: '3px', textAlign: 'center', backgroundColor: '#660066', color: '#FFFFFF', fontWeight: 'bolder'}}>{this.state.aluguel ? FuncoesComum.formatarMoeda(parseFloat(this.state.aluguel) * 3.0) : FuncoesComum.formatarMoeda(0)}</Col>
            </Row>
            <Row style={{padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                *Garantia válida apenas para a finalidade {this.state.imovel_tipo}
            </Row>
            <Row style={{padding: '10px', background: '#fce4d6', fontSize: '1.2em', color: '#660066', fontWeight: 'bolder'}}>
                7 ACIONAMENTO DA GARANTIA
            </Row>
            <Row style={{background: '#fce4d6'}}>
                <Col md={12} style={{marginBottom: '15px', color: '#660066', fontWeight: 'bolder'}}>
                    Em caso de inadimplência, o locador ou um de seus representantes legais deverão
                     entrar em contato com a central de atendimento da ALUG+.
                </Col>
            </Row> 
            <Row style={{background: '#FFFFFF'}}>
                <Col md={9} className='mt--3 d-flex justify-content-center'>
                    <img src={require('../assets/img/assinatura.png')} style={{width: '140px', position: 'relative', float: 'left', margin: '5% 7%'}}/><br/>
                </Col>
            </Row> 
            <Row style={{background: '#FFFFFF'}} className='mt--6'>
                <Col md={9}>
                    <p style={{marginTop: '15px', textAlign: 'center', fontSize: '0.9em', color: '#660066', fontWeight: 'bolder'}}><br/>Carmem Ribeiro - CEO - Alug+</p>
                </Col>
                <Col md={3}>
                    <img className='mt--2' src={require('../assets/img/logo_carta.png')} style={{width: '140px', position: 'relative', float: 'left', margin: '5% 7%'}}/>
                </Col>
            </Row> 
            <Row style={{background: '#FFFFFF', fontSize: '0.9em', color: '#660066', fontWeight: 'bolder', marginBottom: '15px'}}>
                <Col md={2}>
                    Central Alug+
                </Col>
                <Col md={2}>
                    email geral
                </Col>
                <Col md={3}>
                    <label style={{textDecoration: 'underline'}}>contato@alugmais.com</label>
                </Col>
                <Col md={2}>
                    site
                </Col>
                <Col md={3}>
                    <label style={{textDecoration: 'underline'}}>alugmais.com</label>
                </Col>
            </Row> 
        </Container>
      </>
    );
  }
}

export default Download;
