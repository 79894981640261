/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import sha1 from 'sha1'
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import moment from 'moment'
import FuncoesComum from "../funcoes/FuncoesComum";
import link_aprovacao from '../links/links'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'


class UploadDoc extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: [],
            files: [],
            alert: false,
            mensagemModal: '',
            load: false,
            clicar: 0
        }
    }

    toggleAlert = () => {
      if(this.state.load){
        this.setState({load: !this.state.load})
      }
      this.setState({alert: !this.state.alert})
    }

    toggleLoad = () => {
      this.setState({load: !this.state.load})
    }

    // Análise dos documentos e atribuição dos arquivos a uma variável (nome e o arquivo)
    onChangeFile = (evento) => {
        var uploadArquivo = this.state.files
        var subir = 0
        for(var i in evento.target.files){
          if(evento.target.files[i].type){
            for(var k = 0; k < uploadArquivo.length; k++){
              //Verifica se o arquivo já foi adicionado
              if(uploadArquivo[k].name === evento.target.files[i].name){
                subir = 1
              }
            }

            //Não houve a adição do arquivo ainda
            if(subir === 0){
              uploadArquivo.push(evento.target.files[i])
            }
            subir = 0
          }
        }

        //Atribui os arquivos ao INPUT 
        document.getElementById('archive' + this.props.cliente).files = this.FileListItem(uploadArquivo)
        this.setState({
            selectedFile: this.FileListItem(uploadArquivo),
            files: uploadArquivo
        })

        // console.log(evento.target.files)
    }

    upload = () => {   

      if(this.state.selectedFile.length !== 0){

        this.toggleLoad()  
        //Diretório onde os arquivos referente ao cliente serão armazenados
        var diretorio = 'docs/' + sha1(this.props.dado)

        var arquivos = []
        for(var i in this.state.selectedFile){
          if(this.state.selectedFile[i].type){
            arquivos.push(this.state.selectedFile[i])
          }
        }
        // console.log(arquivos)
        // console.log(diretorio)
        // this.createDir(diretorio)
        // var linkAprovar = link_aprovacao + '/telaaprovacao?cliente=' + this.props.cliente + '&documentacao=' + this.props.documento + '&opcao=2'
        // var linkReprovar = link_aprovacao + '/telaaprovacao?cliente=' + this.props.cliente + '&documentacao=' + this.props.documento + '&opcao=3'
      
        //Estruturação do email
        var html = '<div>'
                      +'Prezado Administrador Alug+, uma nova consulta foi realizada com os seguintes parâmetros: <br/><br/>'
                      +'Identificador da consulta: ' + this.props.consulta + '<br/>'
                      +'Imobiliária: ' + this.props.imobiliaria + '<br/>'
                      +'Locatário ' + this.props.locatario + ': ' + this.props.nome + '<br/>'
                      +'Valor da renda informada: ' + FuncoesComum.formatarMoeda(parseFloat(this.props.rendaInformada)) + '<br/>'
                      +'Valor da locação: ' + FuncoesComum.formatarMoeda(parseFloat(this.props.valorConsulta)) + '<br/><br/>'
                      +'Em anexo o(s) comprovante(s) de renda para as devidas tratativas de aprovação para liberação da emissão do contrato.<br/>'
                      +'Favor aprovar ou reprovar para darmos continuidade, clicando nos links a seguir <br/><br/>'
                    +'</div>'
                      +'<table>'
                      +'  <tr>'
                      +'      <td style="font-size: 1.1em">Arquivo</td>'
                      +'  </tr>'
                      +' <tr>'

        var attachments = []
        var totalUpload = 0

        // Passa por todos os arquivos selecionados pelo usuário do sistema referente ao cliente
        for(var cont = 0; cont < arquivos.length; cont++){
            var nomeArquivo = ''
            var nomeAntigo = arquivos[cont].name

            // Onde ocorre a alteração do nome do arquivo para o nosso padrão
            if(arquivos[cont].name.split('.').length > 2){
              for(var o = 0; o < arquivos[cont].name.split('.').length; o++){
                if(o === arquivos[cont].name.split('.').length-1){
                  nomeArquivo += '.' + arquivos[cont].name.split('.')[o]
                }
                else if(o === 0){
                  nomeArquivo += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')  + Math.floor(Math.random() * 1000) + sha1(nomeAntigo)
                }
              }
            }
            else{
              nomeArquivo += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')  + Math.floor(Math.random() * 1000) + sha1(nomeAntigo) +'.'+ arquivos[cont].name.split('.')[1]
            }

            // Cria um novo arquivo com o novo nome do arquivo 
            arquivos[cont] = new File([arquivos[cont]], nomeArquivo, {type: arquivos[cont].type})

            // Adiciona o caminho do arquivo para encontra-lo e enviar o anexo no email 
            attachments.push({path: diretorio + '/' + nomeArquivo})

            // Envia o arquivo para API com o intuito de ser armazenado
            var data = new FormData()
            data.append('file', arquivos[cont])
            data.append('rota', diretorio)
            data.append('nomeAntigo', nomeAntigo)

            // Envia uma requisição POST 
            api_epar.post( '/upload/' + diretorio.replace('/', '|'), data).then((response) => {
              totalUpload++
              // var linkAprovar = link_aprovacao + '/telaaprovacao?cliente=' + this.props.cliente + '&documentacao=' + this.props.documento + '&path=' + diretorio + '/' + response.data + '&opcao=2'
              // var linkReprovar = link_aprovacao + '/telaaprovacao?cliente=' + this.props.cliente + '&documentacao=' + this.props.documento + '&path=' + diretorio + '/' + response.data + '&opcao=3'
              
              // Estruturação do email
              html += '<tr style="height: 20px">'
                              +'     <td style="font-weight: bolder">' + response.data.antigo + '</td>'
                              // +'     <th><a href="' + linkAprovar + '" style=" background-color: #0ea00e;'
                              // +'                         border: none;'
                              // +'                         padding: 8px 20px;'
                              // +'                         border-radius: 2px;'
                              // +'                         color: #fff;    '
                              // +'                         outline: none;    '
                              // +'                         text-decoration: none;    '
                              // +'                         margin: 0px 5px;">Aprovar</a></th>'
                              // +'     <th><a href="' + linkReprovar + '" style=" background-color: #a52121;'
                              // +'                         border: none;'
                              // +'                         outline: none;    '
                              // +'                         text-decoration: none;    '
                              // +'                         padding: 8px 20px;'
                              // +'                         border-radius: 2px;'
                              // +'                        color: #fff;">Reprovar</a></th>'
                              +'</tr>'
                            
              // Cria um registro no banco referente ao arquivo armazenado no banco
              api_epar.post( '/clienteshasdocumentacao', {
                clientes_id: this.props.cliente,
                documentacao_id: this.props.documento,
                path: diretorio + '/' + response.data.novo,
                data_envio: moment().format('YYYY-MM-DD HH:mm:ss'),
                aprovado: 1,
                consulta_id: this.props.consulta,
              }).then((retorno) => {
                  console.log(retorno) 

                  // Caso seja um reenvio de arquivos solicitados, ele muda os status dos arquivos anteriores referente ao cliente e a consulta
                  api_epar.get( '/clienteshasdocumentacao/' + this.props.cliente).then((resposta) => {
                    if(resposta.data.data.length > 0){
                        var documentos = resposta.data.data.filter(e => {return e.documentacao_id === 4 && e.aprovado === 4 && e.consulta_id === this.props.consulta})
                        if(documentos.length > 0){
                            for(var i = 0; i < documentos.length; i++){
                                api_epar.get( '/docatualizarid/' + documentos[i].id + '/5').then((update) => {
                                    console.log(update.data.data)
                                    console.log('Atualizado para 5')
                                }).catch((error) => {
                                    console.log(error)
                                })
                            }
                        }
                        else{
                          console.log('Não há documentos solicitados REENVIO')
                        }
                    }
                    else{
                      console.log('Não há documento(s)')
                    }
                }).catch((error) => {
                    console.log(error)
                })           
              }).catch((error) => {
                console.log(error)
              })

              // Estruturação do email para aprovação dos documentos
              if(totalUpload === arquivos.length){

                var linkAprovar = link_aprovacao + '/telaaprovacaolote?cliente=' + this.props.cliente + '&opcao=2&consulta='  + this.props.consulta
                var linkReprovar = link_aprovacao + '/telaaprovacaolote?cliente=' + this.props.cliente + '&opcao=3&consulta='  + this.props.consulta
                var linkReenviar = link_aprovacao + '/telaaprovacaolote?cliente=' + this.props.cliente + '&opcao=4&consulta='  + this.props.consulta
                html += '<tr style="height: 60px; margin-top: 5%">'
                                +'    <th> <a href="' + linkAprovar + '" style=" background-color: #0ea00e;'
                                +'                         border: none;'
                                +'                         padding: 8px 20px;'
                                +'                         border-radius: 2px;'
                                +'                         color: #fff;    '
                                +'                         outline: none;    '
                                +'                         margin: 0px 30px;'
                                +'                         text-decoration: none;">Aprovar</a>'
                                +'     <a href="' + linkReprovar + '" style=" background-color: #a52121;'
                                +'                         border: none;'
                                +'                         outline: none;    '
                                +'                         text-decoration: none;    '
                                +'                         padding: 8px 20px;'
                                +'                         border-radius: 2px;'
                                +'                         margin: 0px 30px;'
                                +'                         color: #fff;">Reprovar</a>'
                                +'     <a href="' + linkReenviar + '" style=" background-color: #ffae00;'
                                +'                         border: none;'
                                +'                         outline: none;    '
                                +'                         text-decoration: none;    '
                                +'                         padding: 8px 20px;'
                                +'                         border-radius: 2px;'
                                +'                         margin: 0px 30px;'
                                +'                        color: #fff;">Reenviar</a></th>'
                                +'</tr>'
                            
                  // console.log(html)
                  html += '</table>'
                  this.toggleLoad()
                  this.setState({mensagemModal: <div>
                                  Upload de arquivo(s) feito(s) com sucesso, só aguardar a aprovação do(s) documento(s)
                                </div>})
                  this.toggleAlert()
                  this.props.funcao()
                  document.getElementById('uploadDoc' + this.props.cliente).style.display = 'none'


                  // axios.post(api_admin + '/notification/sendnotification', {
                  //   msg: '{"title": "Consulta", "sistema": 2, "rota": "/epar/consulta/*", "link": "' + api_admin + '/epar/consulta/' + this.props.consulta + '", "body": "Comprovantes de renda do locatário ' + this.props.locatario + ' ' + this.props.nome + ', referente a consulta ' + this.props.consulta + '."}'
                  // }).then((retorno) => {
                    
                  // })


                    // GMAIL: https://myaccount.google.com/lesssecureapps
        
                  // Enviar email
                  api_epar.post( '/sendemail', {
                      // to: 'otavioromualdo@gmail.com',
                      subject: 'Aprovação de documentos',
                      // text: 'Era só o segundo teste mesmo',
                      html: html,
                      attachments: attachments,
                      extras: {
                        cliente: this.props.cliente,
                        consulta: this.props.consulta,        
                      }
                  }).then((retorno) => {
                      console.log(retorno)
                      // alert('Upload de arquivo feito com sucesso, só aguardar a aprovação')
                      // this.setState({mensagemModal: <div>
                      //                                 Upload de arquivo e envio de email feito com sucesso, só aguardar a aprovação do documento
                      //                               </div>})
                      // this.toggleAlert()
                  })

                  axios.get(api_admin + '/config/configs').then((config) => {
                    var emailfinanceiro = config.data.data.filter((c) => {
                        return c.id === 12
                    })[0].padrao
                    api_epar.post( '/sendemailcarta', {
                        to: emailfinanceiro,
                        subject: 'Aprovação de documentos - Financeiro',
                        html: html,
                        attachments: attachments,
                        extras: {
                          cliente: this.props.cliente,
                          consulta: this.props.consulta,        
                        }
                    })
                  })
              }
                
          }).catch((error) => {
            console.log(error)
          })
          // console.log(attachments)
        }
      }

    }

    // createDir = (diretorio) => {
    //   console.log(fs.read())
    //   // fs.mkdirSync(process.cwd() + diretorio, {recursive: true}, (error) => {
    //   //   if(error){
    //   //     console.log(error)
    //   //   }
    //   //   console.log('Criado')
    //   // })
    // }

  // Lista os arquivos adicionados 
  carregarArquivos = (record) => {
    return (
      < >
        <Row >
          <Col md={5} className='quebrarLinha'>
            {record.name}
          </Col>
          <Col md={5}>
            {this.props.locatario}
          </Col>
          <Col md={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <i style={{cursor: 'pointer'}} className='fas fa-times p-1' style={{color: 'red'}} onClick={() => {this.removerArquivo(record.name)}}/>
          </Col>
        </Row>
      </>
    )
  }


  removerArquivo = (nome) => {
    var arquivos = this.state.files
    arquivos.splice(arquivos.map(e => {return e.name}).indexOf(nome), 1)
    console.log(arquivos)
    document.getElementById('archive' + this.props.cliente).files = this.FileListItem(arquivos)
    console.log(document.getElementById('archive' + this.props.cliente).files)
    this.setState({files: arquivos, selectedFile: document.getElementById('archive' + this.props.cliente).files})
  }

  // Retorna uma lista FileList
  FileListItem = (a) => {
    for (var c, b = c = a.length, d = !0; b-- && d;) d = a[b] instanceof File
    if (!d) throw new TypeError("Erro")
    for (b = (new ClipboardEvent("")).clipboardData || new DataTransfer; c--;) b.items.add(a[c])
    return b.files
  }

  teste = () => {
    document.getElementById('uploadDoc' + this.props.cliente).style.display = 'none'
  }

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <div>
          <div id={'uploadDoc' + this.props.cliente}>
            <Row>
              <Label style={{color: 'black', fontWeight: 'bolder'}} className='ml-3'>{this.props.arquivo}</Label>
            </Row>
            <Row>
              <Col md={8}>
                <InputGroup className="input-group-alternative mb-1 mt-2">
                  <Input id={'archive' + this.props.cliente} type='file' multiple onChange={this.onChangeFile} accept="application/pdf,image/x-png,image/jpg,image/jpeg"></Input>
                </InputGroup>
              </Col>
              <Col md={4}>
                <Button onClick={this.upload} id={'botaoUpload' + this.props.cliente} className='float-right mb-1 btn btn-success'>ANEXAR</Button>
              </Col>
            </Row>
            {this.state.files.length > 0 && <Row className='mt-2 mb-1'>
                                              <Col md={5} style={{fontWeight: 'bolder'}}>
                                                Arquivo
                                              </Col>
                                              <Col md={5} style={{fontWeight: 'bolder'}}>
                                                Locatário
                                              </Col>
                                              <Col md={2} style={{fontWeight: 'bolder', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                Ação
                                              </Col>
                                            </Row>
                                            }

            <div className='mb-4'>
              {this.state.files.map(this.carregarArquivos)}
            </div>
          </div>
          <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
              <ModalHeader toggle={this.toggleAlert}>Mensagem</ModalHeader>
              <ModalBody>
                  <Row>
                      <Col md={12}>
                          {this.state.mensagemModal}
                      </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                  <Button className="btn-dark" onClick={this.toggleAlert}>Fechar</Button>
              </ModalFooter>
          </Modal>
          <Modal className='d-flex justify-content-center align-items-center' isOpen={this.state.load} >
              <ModalHeader>AGUARDE...</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} className='d-flex justify-content-center align-items-center'>
                          <img src={require('assets/img/loading.gif')} className='p-5 w-50 h-100'/>
                        </Col>
                    </Row>
                </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

export default UploadDoc;
