/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import axios from 'axios'
import api_admin from '../../services/apiadmin'

import {Link} from 'react-router-dom'
import ReactGA from 'react-ga'

class Profile extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      imobiliaria: null,

      password: '',
      newpassword: '',
      passwordagain: '',
      email: JSON.parse(localStorage.getItem('dadosUsuario_test')).email
    }
  }

  componentDidMount(){
     ReactGA.initialize(process.env.REACT_APP_UA)
     ReactGA.pageview(window.location.pathname + window.location.search)

    axios.post(api_admin + '/imobiliaria/getid', {id: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id}).then((response) => {
      this.setState({imobiliaria: response.data.data})
    }).catch((error) => {
      console.log('Error: ' + error)
    })
  }

  trocarSenha = async () => {
    if (this.state.newpassword != this.state.passwordagain) {
      alert("As senhas não batem")
      return
    }
    else if (!this.state.newpassword){
      alert("Preencha uma senha")
      return
    }
    else{
      let e = await axios.post(api_admin + '/imobiliaria/imobuser/new', {senha: this.state.password, email: this.state.email, newpass: this.state.newpassword});
      if (e.data == "error"){
        alert("Senha inválida")
      }
      else{
        alert("Senha alterada com sucesso")
        this.setState({
          newpassword: '',
          password: '',
          passwordagain: ''
        })
      }
    }
  }

  render() {
    return (
      <>
        {/* <UserHeader /> */}
        {/* Page content */}
        <Container className="mt-5" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            </Col>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Dados da imobiliária</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                    <h6 className="heading-small text-muted mb-4">
                      Informações
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-razaoSocial"
                            >
                              Razão social
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-razaoSocial"
                              placeholder="Razão Social"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].razao_social}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-telefone"
                            >
                              Telefone
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-telefone"
                              placeholder="Telefone"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].telefone}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-celular"
                            >
                              Celular
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-celular"
                              placeholder="Celular"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].celular}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">Senha</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-senha"
                            >
                              Senha
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-senha"
                              placeholder="Senha"
                              type="password"
                              style={{backgroundColor: 'white'}}
                              onChange={a => this.setState({password: a.target.value})}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-senhanova"
                            >
                              Nova senha
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-senhanova"
                              placeholder="Senha novo"
                              type="password"
                              style={{backgroundColor: 'white'}}
                              onChange={a => this.setState({newpassword: a.target.value})}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-again"
                            >
                              Confirme
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-again"
                              placeholder="confirme"
                              type="password"
                              style={{backgroundColor: 'white'}}
                              onChange={a => this.setState({passwordagain: a.target.value})}
                            />
                          </FormGroup>
                        </Col>

                      </Row>
                    <div className="pl-lg-4">
                      <button className="btn btn-dark float-right" onClick={this.trocarSenha}>Salvar nova senha</button>
                    </div>
                    </div>
                    <hr className="my-4" />

                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Endereço
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-endereco"
                            >
                              Endereço
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-endereco"
                              placeholder="Endereço"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].endereco}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Número
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-numero"
                              placeholder="Número"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].numero}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bairro"
                            >
                              Bairro
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-bairro"
                              placeholder="Bairro"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].bairro}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Cidade
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-city"
                              placeholder="Cidade"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].cidade}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-estado"
                            >
                              Estado
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-estado"
                              placeholder="Estado"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].estado}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-postal-code"
                            >
                              CEP
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              defaultValue="Não informado"
                              placeholder="CEP"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].cep}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-complemento"
                            >
                              Complemento
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Não informado"
                              id="input-complemento"
                              placeholder="Complemento"
                              type="text"
                              value={this.state.imobiliaria && this.state.imobiliaria[0].complemento}
                              disabled
                              style={{backgroundColor: 'white'}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Link className='btn btn-dark float-right' to='/admin/usersimob'>Lista de usuários</Link>
                    </div>
                    {/* <hr className="my-4" />
                    Description
                    <h6 className="heading-small text-muted mb-4">About me</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          rows="4"
                          defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                          type="textarea"
                        />
                      </FormGroup>
                    </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
