/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom'
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Container,
    CardBody,
    CardFooter,
    Card,
    CardHeader,
    Col,
    Label,
    Button,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from "reactstrap";

import FuncoesComum from '../funcoes/FuncoesComum'

import {Link} from 'react-router-dom'


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'

import Header from "components/Headers/Header.jsx";
import UploadDoc from "components/UploadDoc.jsx";
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from 'react-ga'
import sha1 from 'sha1'
import moment from 'moment'
import extenso from 'extenso'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

class NewDetalheConsulta extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            consulta: this.props.match.params.id,
            retornoConsulta: [],
            pessoas: [],
            dataConsulta: '',
            tamanhoCol: 12,
            exibir: 'd-flex justify-content-center',
            modal: false,
            button: '',
            documento: '',
            naoClicar: [],
            motivoReenvio: '',
            cartagarantia: '',
            carta: '',
            emailCarta: '',
            alert: false,
            mensagemModal: '',
            semComprovante: 0,
            porcentagemCarta: 0,
            enviarCarta: '',
            porcentagemScore: 0,
            porcentagemPendencias: 0,
            renda: '',
            anexarComprovantes: '',
            load: false,
            valor_carta: 0
        }
        // console.log(new URLSearchParams(props.location.search).get('otavio'))
        
    }

    changeEmailCarta = (event) => {
        this.setState({emailCarta: event.target.value})
        localStorage.setItem('emailCarta_test', event.target.value)
    }

    toggleLoad = () => {
        this.setState({load: !this.state.load})
      }

    componentDidMount(){

        
        axios.get(api_admin + '/config/configsimob/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((config) => {
            var semComprovante 
            var porcentagemCarta
            var porcentagemScore
            var enviarCarta
            var porcentagemPendencias
            var anexarComprovantes
            
            console.log(config.data.data)

            if(config.data.data.length > 0){
                var alugmais = config.data.data.filter((alug) => {
                    return alug.sistemas_id === 2
                })
                
                if(alugmais.length > 0){
                    for(var i = 0; i < alugmais.length; i++){
                        if(alugmais[i].configuracoes_id === 1){
                            enviarCarta = alugmais[i].valor
                        }
                        if(alugmais[i].configuracoes_id === 2){
                            porcentagemCarta = parseFloat(alugmais[i].valor)
                        }
                        if(alugmais[i].configuracoes_id === 3){
                            semComprovante = parseFloat(alugmais[i].valor)
                        }
                        if(alugmais[i].configuracoes_id === 4){
                            porcentagemScore = (parseFloat(alugmais[i].valor) * 100)
                        }
                        if(alugmais[i].configuracoes_id === 8){
                            porcentagemPendencias = parseFloat(alugmais[i].valor)
                        }
                        if(alugmais[i].configuracoes_id === 11){
                            anexarComprovantes = parseFloat(alugmais[i].valor)
                        }
                    }
                }
                this.setState({
                    enviarCarta: enviarCarta, 
                    semComprovante: parseFloat(semComprovante), 
                    porcentagemCarta: parseFloat(porcentagemCarta), 
                    porcentagemScore: parseFloat(porcentagemScore),
                    porcentagemPendencias: parseFloat(porcentagemPendencias),
                    anexarComprovantes: parseFloat(anexarComprovantes),
                })
                
                if(!porcentagemCarta){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 2){
                                    porcentagemCarta = parseFloat(alugmais[i].padrao)
                                }
                            }
                            this.setState({porcentagemCarta: parseFloat(porcentagemCarta)})
                            console.log(porcentagemCarta)
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!porcentagemPendencias){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 8){
                                    porcentagemPendencias = parseFloat(alugmais[i].padrao)
                                }
                            }
                            this.setState({porcentagemPendencias: parseFloat(porcentagemPendencias)})
                            console.log(porcentagemPendencias)
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!semComprovante){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 3){
                                    semComprovante = parseFloat(alugmais[i].padrao)
                                }
                            }
                            this.setState({semComprovante: parseFloat(semComprovante)})
                            console.log(semComprovante)
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!enviarCarta){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 1){
                                    enviarCarta = alugmais[i].padrao
                                }
                            }
                            console.log(enviarCarta)
                            this.setState({enviarCarta: enviarCarta})
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!porcentagemScore){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 4){
                                    porcentagemScore = (parseFloat(alugmais[i].padrao) * 100)
                                }
                            }
                            console.log(porcentagemScore)
                            this.setState({porcentagemScore: parseFloat(porcentagemScore)})
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(!anexarComprovantes){
                    axios.get(api_admin + '/config/configs').then((configuracoes) => {
                        alugmais = configuracoes.data.data.filter((alug) => {
                            return alug.sistemas_id === 2
                        })

                        if(alugmais.length > 0){
                            for(var i = 0; i < alugmais.length; i++){
                                if(alugmais[i].id === 11){
                                    anexarComprovantes = (parseFloat(alugmais[i].padrao))
                                }
                            }
                            console.log(anexarComprovantes)
                            this.setState({anexarComprovantes: parseFloat(anexarComprovantes)})
                        } 
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
            else{
                axios.get(api_admin + '/config/configs').then((configuracoes) => {
                    var alugmais = configuracoes.data.data.filter((alug) => {
                        return alug.sistemas_id === 2
                    })

                    console.log(alugmais)
                    console.log(configuracoes)

                    if(alugmais.length > 0){
                        for(var i = 0; i < alugmais.length; i++){
                            if(alugmais[i].id === 1){
                                enviarCarta = alugmais[i].padrao
                            }
                            if(alugmais[i].id === 2){
                                porcentagemCarta = parseFloat(alugmais[i].padrao)
                            }
                            if(alugmais[i].id === 3){
                                semComprovante = parseFloat(alugmais[i].padrao)
                            }
                            if(alugmais[i].id === 4){
                                porcentagemScore = (parseFloat(alugmais[i].padrao) * 100)
                            }
                            if(alugmais[i].id === 8){
                                porcentagemPendencias = parseFloat(alugmais[i].padrao)
                            }
                            if(alugmais[i].id === 11){
                                anexarComprovantes = parseFloat(alugmais[i].padrao)
                            }
                        }
                        this.setState({
                            enviarCarta: enviarCarta, 
                            semComprovante: parseFloat(semComprovante), 
                            porcentagemCarta: parseFloat(porcentagemCarta), 
                            porcentagemScore: parseFloat(porcentagemScore),
                            porcentagemPendencias: parseFloat(porcentagemPendencias),
                            anexarComprovantes: parseFloat(anexarComprovantes),
                        })
                    } 
                }).catch((error) => {
                    console.log(error)
                })
            }
        }).catch((error) => {
            console.log(error)
        })

         // Analytics
        ReactGA.initialize(process.env.REACT_APP_UA)
        ReactGA.pageview(window.location.pathname + window.location.search)
        
        if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
            this.setState({exibir: 'd-none'})
        }
        console.log(this.state.consulta)

        // api_epar.get( '/configuracoes').then((config) => {
        //     console.log(config)
        //     this.setState({semComprovante: parseFloat(config.data.data[0].semComprovante), porcentagemCarta: parseFloat(config.data.data[0].porcentagemCarta)})
        // }).catch((error) => {
        //     console.log(error)
        // })

        // api_epar.post( '/sendemail', {
        //       from: 'otavioromualdo@gmail.com',
        //       to: 'otavioromualdo@gmail.com',
        //       subject: 'Teste envio de email',
        //       text: 'Era só o segundo teste mesmo',
        //       attachments: [{
        //           path: 'http://localhost:3000/alugmaisicon.png'
        //       }]
        //   }).then((retorno) => {
        //       console.log(retorno)
        //   })
    

        new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
            api_epar.get( '/clienteshasconsultadetalhe/' + this.state.consulta).then((retorno) => {
                console.log(retorno)
                var dicionario = []
                var valor = retorno.data.data[0].valor
                var etapa = retorno.data.data[0].etapa
                var idConsulta = retorno.data.data[0].consulta_id
                var dataConsulta = retorno.data.data[0].data_consulta
                var idUsuario = retorno.data.data[0].usuarios_id
                var finalidadeLocacao = retorno.data.data[0].finalidadeLocacao
                var qual_finalidadeLocacao = retorno.data.data[0].qual_finalidadeLocacao
                var score_status = retorno.data.data[0].score_status
                var pendenciafinanceira_status = retorno.data.data[0].pendenciafinanceira_status
                var processo_status = retorno.data.data[0].processo_status
                var antecedente_status = retorno.data.data[0].antecedente_status
                var status_final = retorno.data.data[0].status_final



                if (JSON.parse(localStorage.getItem('dadosUsuario_test')).id !== retorno.data.data[0].usuarios_id){
                    this.props.history.push('/admin/relatorios')
                }
                var pessoas = []
                var ids = new Set(retorno.data.data.map((e) => {
                    return e.clientes_id
                }))
                for(let id of ids){
                    pessoas.push(this.pessoasRetorno(retorno, id))
                }
                var principal = parseInt(pessoas.map((e) => {
                  return e.tipo_locatario
                }).indexOf('Principal'))
                console.log(principal)
                if(principal !== 0){
                  var newOrdem = [pessoas[principal]]
                  for(var i = 0; i < pessoas.length; i++){
                    if(i !== principal){
                      newOrdem.push(pessoas[i])
                    }
                  }
                  pessoas = newOrdem
                }
                console.log(pessoas)
                dicionario = [{
                  consulta_id: idConsulta,
                  valor: valor,
                  pessoas: pessoas,
                  etapa: etapa,
                  data_consulta: dataConsulta,
                  usuarios_id: idUsuario,
                  finalidadeLocacao: finalidadeLocacao,
                  qual_finalidadeLocacao: qual_finalidadeLocacao,
                  score_status: score_status,
                  pendenciafinanceira_status: pendenciafinanceira_status,
                  processo_status: processo_status,
                  antecedente_status: antecedente_status,
                  status_final: status_final
                }]
    
                console.log(dicionario)
                var dividirPor = 0
                if(ids.size > 3){
                  dividirPor = 3
                }
                else{
                  dividirPor = ids.size
                }
    
                var documentacao = ''
                var documento = ''
                var cartagarantia = ''
                var carta = ''
    
                // Irá verificar se os documentos não foram negados (Verifica de todas as pessoas vinculadas a consulta)
                if(documentacao === ''){
                    for(var i = 0; i < pessoas.length; i++){   
                        if(pessoas[i].statusDoc === 3){
                            documentacao = <Button className={'btn-danger float-right m-1'} style={{cursor: 'not-allowed'}} disabled>Documento negado</Button>
                            documento = 'Negado'
                            break
                        }
                    }
                }
    
                // Caso documentos não foram negados, irá verificar se existem documentos (Verifica de todas as pessoas vinculadas a consulta)
                if(documentacao === ''){
                    for(var i = 0; i < pessoas.length; i++){
                        if(pessoas[i].countDoc === 0){
                            documentacao = <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>ANEXAR ARQUIVO</Button>
                            documento = 'Sem'
                            break
                        }
                    }
                }
    
                // Caso documentos não foram negados e existem documentos, irá verificar se houve a análise dos documentos
                // (Verifica de todas as pessoas vinculadas a consulta)
                if(documentacao === ''){
                    for(var i = 0; i < pessoas.length; i++){   
                        if(pessoas[i].statusDocMin === 1){
                            documentacao = <Button className={'float-right m-1'} style={{backgroundColor: '#ffc107', color: 'white', cursor: 'not-allowed'}} disabled>Aprovando documento</Button>
                            documento = 'Aprovando'
                            break
                        }
                    }
                }
    
                // Caso documentos não foram negados, existem documentos e já houve análise, irá verificar se será necessário novos arquivos
                // (Verifica de todas as pessoas vinculadas a consulta)
                if(documentacao === ''){
                    for(var i = 0; i < pessoas.length; i++){   
                        if(pessoas[i].statusDoc === 4){
                                api_epar.get( '/clienteshasdocumentacao/' + pessoas[i].cliente_id).then((resposta) => {
                                  if(resposta.data.data.length > 0){
                                    // Verifica se tem documentos pendentes a serem analisados de um cliente e consulta específica
                                      var documentos = resposta.data.data.filter(e => {return e.documentacao_id === 4 && e.aprovado === 4 && e.consulta_id === parseInt(this.state.consulta)})
                                      if(documentos.length > 0){
                                        var cont = 0
                                        for(var m = 0; m < documentos.length; m++){ 
                                            if(documentos[m].motivo){
    
                                                this.setState({button: <div>
                                                                            <div className='tooltipnew bg'>
                                                                                <i className="fas fa-info" style={{margin: '4px', padding: '13px 20px', border: '1px solid', borderRadius: '10px', color: 'white', backgroundColor: 'black'}} />
                                                                                <span className="tooltiptext" style={{fontSize: '0.9em', left: '-33px'}}>{documentos[m].motivo}</span>
                                                                            </div>
                                                                            <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>
                                                                                ANEXAR NOVOS ARQUIVOS
                                                                            </Button>
                                                                        </div>}) 
                                            }
                                          break;
                                        }
                                      }
                                  }
                                  else{
    
                                      documentacao = <div>
                                                          <div className='tooltipnew bg'>
                                                              <i className="fas fa-info" style={{margin: '4px', padding: '13px 20px', border: '1px solid', borderRadius: '10px', color: 'white', backgroundColor: 'black'}} />
                                                              <span className="tooltiptext" style={{fontSize: '0.9em', left: '-33px'}}>Não informado</span>
                                                          </div>
                                                          <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>
                                                              ANEXAR NOVOS ARQUIVOS
                                                          </Button>
                                                      </div>
                                    documento = 'Reenvio'
                                  }
                                })
                                documentacao = <div>
                                                          <div className='tooltipnew bg'>
                                                              <i className="fas fa-info" style={{margin: '4px', padding: '13px 20px', border: '1px solid', borderRadius: '10px', color: 'white', backgroundColor: 'black'}} />
                                                              <span className="tooltiptext" style={{fontSize: '0.9em', left: '-33px'}}>Não informado</span>
                                                          </div>
                                                          <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>
                                                              ANEXAR NOVOS ARQUIVOS
                                                          </Button>
                                                      </div>
                                documento = 'Reenvio'
                            break
                        }
                    }
                }
    
                // Caso documentos não foram negados, existem documentos, já houve análise e não precisará de novos arquivos,
                // poderá então ser gerado o contrato para essa consulta
                // (Verifica de todas as pessoas vinculadas a consulta)
                if(documentacao === ''){
                    documentacao = <Button className='btn-success float-right m-1' to={'/admin/formcontratoconsulta/' + this.state.consulta} tag={Link}>Gerar contrato</Button>
                    documento = 'Gerar'
                }
                
            
                // Caso a consulta tenha sido negada ele dá preferência para essa opção
                for(var i = 0; i < pessoas.length; i++){
                    // if(pessoas[i].idwall){
                    //     if(pessoas[i].idwall.result){
                            if(pessoas[i].status.indexOf('Negado') !== -1){
                                documentacao = ''
                                documento = ''
                                break
                            }
                    //     }
                    //     else{
                    //         documentacao = ''
                    //     }
                    // }
                    // else{
                    //     documentacao = ''
                    // }
                }
    
                this.setState({
                    tamanhoCol: this.state.tamanhoCol / dividirPor, 
                    retornoConsulta: dicionario, 
                    pessoas: pessoas, 
                    button: documentacao,
                    documento: documento,
                    dataConsulta: retorno.data.data[0].data_consulta
                })
    
                if(new Date(retorno.data.data[0].data_consulta) > new Date('2020-07-12') && documento !== 'Negado'){
    
                    var statusConsulta
                    for(var i = 0; i < pessoas.length; i++){
                        if(pessoas[i].status.indexOf('Negado') !== -1){
                            statusConsulta = 2
                            break
                        }
                    }
        
                    // if(documento !== ''){
                        console.log(pessoas[0].renda_informada*this.state.porcentagemCarta)
                        console.log(this.state.porcentagemCarta)
                        console.log(documento)
                        if(new Date('2020-08-04') < new Date(retorno.data.data[0].data_consulta) && (retorno.data.data[0].finalidadeLocacao === 'Comercial' || retorno.data.data[0].cpf_cnpj.length > 14)){
                            if(((pessoas[0].renda_informada*this.state.porcentagemCarta <= this.state.semComprovante || documento === 'Gerar') && finalidadeLocacao !== 'Comercial' && retorno.data.data[0].cpf_cnpj.length < 15) || documento === 'Gerar'){
                                console.log('Verificação da renda')
                                documentacao = ''
                                documento = ''
                                this.setState({
                                    button: documentacao,
                                    documento: documento,
                                    renda: 'SemDoc'
                                })
                            }
                        }
                        else{
                            if(pessoas[0].renda_informada*this.state.porcentagemCarta <= this.state.semComprovante || documento === 'Gerar'){
                                console.log('Verificação da renda AQUI')
                                documentacao = ''
                                documento = ''
                                this.setState({
                                    button: documentacao,
                                    documento: documento,
                                    renda: 'SemDoc'
                                })
                            }
                        }
                    // }
        
                    if(statusConsulta !== 2){
                        api_epar.get( '/cartagarantiaconsulta/' + this.props.match.params.id).then((cartaConsulta) => {
                            if(cartaConsulta.data.data.length === 0){
                                cartagarantia = <Button className={'btn-danger float-right m-1'} onClick={this.modalEnviarCarta}>Gerar carta de crédito</Button>
                                carta = 'Enviar'
                            }
                            else{
                                this.setState({valor_carta: cartaConsulta.data.data[0].valor_carta})

                                if(cartaConsulta.data.data.length > 0 && new Date(cartaConsulta.data.data[0].data_validade) >= new Date()){
                                    cartagarantia = ''
                                    carta = 'Gerada'
                                    // cartagarantia = <Button className={'btn-success float-right m-1'} onClick={() => {this.props.history.push('/admin/cartagarantia')}}>Gerar contrato</Button>
                                    // carta = 'Gerada'
                                }
                                else{
                                    cartagarantia = <Button className={'btn-warning float-right m-1'} style={{cursor: 'not-allowed'}} disabled>Carta de crédito fora do prazo</Button>
                                    carta = 'Vencida'
                                }
                            }
                            console.log(carta)
                            this.setState({
                                cartagarantia: cartagarantia,
                                carta: carta,
                            })
                        })
                        .catch((erro) => {
                            console.log(erro.message)
                        })
                    }           
                }
    
                this.setState({
                    button: documentacao,
                    documento: documento,
                })
                // for(var cont = 0; cont < pessoas.length; cont++){
                //     // var documentacao = 0
                //     api_epar.get( '/clienteshasdocumentacao/' + pessoas[cont].cliente_id + '/1').then((doc) => {
                //         if(doc.data.data.length === 0){
                //             // documentacao = 1
                //             this.setState({button: <Button className='btn-dark float-right' onClick={this.modalConsulta} data-value={this.state.consulta}>Upload de arquivos</Button>})
                //         }
                //     })
                // }
                
                
            }).catch((error) => {
                console.log(error)
            })
        })
        // Busca as informações da consulta em questão
        // api_epar.get( '/clienteshasconsultadetalhe/' + this.state.consulta).then((retorno) => {
        //     console.log(retorno)
        //     var dicionario = []
        //     var valor = retorno.data.data[0].valor
        //     var etapa = retorno.data.data[0].etapa
        //     var idConsulta = retorno.data.data[0].consulta_id
        //     var dataConsulta = retorno.data.data[0].data_consulta
        //     var idUsuario = retorno.data.data[0].usuarios_id
        //     if (JSON.parse(localStorage.getItem('dadosUsuario_test')).id !== retorno.data.data[0].usuarios_id){
        //         this.props.history.push('/admin/relatorios')
        //     }
        //     var pessoas = []
        //     var ids = new Set(retorno.data.data.map((e) => {
        //         return e.clientes_id
        //     }))
        //     for(let id of ids){
        //         pessoas.push(this.pessoasRetorno(retorno, id))
        //     }
        //     var principal = parseInt(pessoas.map((e) => {
        //       return e.tipo_locatario
        //     }).indexOf('Principal'))
        //     console.log(principal)
        //     if(principal !== 0){
        //       var newOrdem = [pessoas[principal]]
        //       for(var i = 0; i < pessoas.length; i++){
        //         if(i !== principal){
        //           newOrdem.push(pessoas[i])
        //         }
        //       }
        //       pessoas = newOrdem
        //     }
        //     console.log(pessoas)
        //     dicionario = [{
        //       consulta_id: idConsulta,
        //       valor: valor,
        //       pessoas: pessoas,
        //       etapa: etapa,
        //       data_consulta: dataConsulta,
        //       usuarios_id: idUsuario,
        //     }]

        //     console.log(dicionario)
        //     var dividirPor = 0
        //     if(ids.size > 3){
        //       dividirPor = 3
        //     }
        //     else{
        //       dividirPor = ids.size
        //     }

        //     var documentacao = ''
        //     var documento = ''
        //     var cartagarantia = ''
        //     var carta = ''

        //     // Irá verificar se os documentos não foram negados (Verifica de todas as pessoas vinculadas a consulta)
        //     if(documentacao === ''){
        //         for(var i = 0; i < pessoas.length; i++){   
        //             if(pessoas[i].statusDoc === 3){
        //                 documentacao = <Button className={'btn-danger float-right m-1'} style={{cursor: 'not-allowed'}} disabled>Documento negado</Button>
        //                 documento = 'Negado'
        //                 break
        //             }
        //         }
        //     }

        //     // Caso documentos não foram negados, irá verificar se existem documentos (Verifica de todas as pessoas vinculadas a consulta)
        //     if(documentacao === ''){
        //         for(var i = 0; i < pessoas.length; i++){
        //             if(pessoas[i].countDoc === 0){
        //                 documentacao = <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>ANEXAR ARQUIVO</Button>
        //                 documento = 'Sem'
        //                 break
        //             }
        //         }
        //     }

        //     // Caso documentos não foram negados e existem documentos, irá verificar se houve a análise dos documentos
        //     // (Verifica de todas as pessoas vinculadas a consulta)
        //     if(documentacao === ''){
        //         for(var i = 0; i < pessoas.length; i++){   
        //             if(pessoas[i].statusDocMin === 1){
        //                 documentacao = <Button className={'float-right m-1'} style={{backgroundColor: '#ffc107', color: 'white', cursor: 'not-allowed'}} disabled>Aprovando documento</Button>
        //                 documento = 'Aprovando'
        //                 break
        //             }
        //         }
        //     }

        //     // Caso documentos não foram negados, existem documentos e já houve análise, irá verificar se será necessário novos arquivos
        //     // (Verifica de todas as pessoas vinculadas a consulta)
        //     if(documentacao === ''){
        //         for(var i = 0; i < pessoas.length; i++){   
        //             if(pessoas[i].statusDoc === 4){
        //                     api_epar.get( '/clienteshasdocumentacao/' + pessoas[i].cliente_id).then((resposta) => {
        //                       if(resposta.data.data.length > 0){
        //                         // Verifica se tem documentos pendentes a serem analisados de um cliente e consulta específica
        //                           var documentos = resposta.data.data.filter(e => {return e.documentacao_id === 4 && e.aprovado === 4 && e.consulta_id === parseInt(this.state.consulta)})
        //                           if(documentos.length > 0){
        //                             var cont = 0
        //                             for(var m = 0; m < documentos.length; m++){ 
        //                                 if(documentos[m].motivo){

        //                                     this.setState({button: <div>
        //                                                                 <div className='tooltipnew bg'>
        //                                                                     <i className="fas fa-info" style={{margin: '4px', padding: '13px 20px', border: '1px solid', borderRadius: '10px', color: 'white', backgroundColor: 'black'}} />
        //                                                                     <span className="tooltiptext" style={{fontSize: '0.9em', left: '-33px'}}>{documentos[m].motivo}</span>
        //                                                                 </div>
        //                                                                 <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>
        //                                                                     ANEXAR NOVOS ARQUIVOS
        //                                                                 </Button>
        //                                                             </div>}) 
        //                                 }
        //                               break;
        //                             }
        //                           }
        //                       }
        //                       else{

        //                           documentacao = <div>
        //                                               <div className='tooltipnew bg'>
        //                                                   <i className="fas fa-info" style={{margin: '4px', padding: '13px 20px', border: '1px solid', borderRadius: '10px', color: 'white', backgroundColor: 'black'}} />
        //                                                   <span className="tooltiptext" style={{fontSize: '0.9em', left: '-33px'}}>Não informado</span>
        //                                               </div>
        //                                               <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>
        //                                                   ANEXAR NOVOS ARQUIVOS
        //                                               </Button>
        //                                           </div>
        //                         documento = 'Reenvio'
        //                       }
        //                     })
        //                     documentacao = <div>
        //                                               <div className='tooltipnew bg'>
        //                                                   <i className="fas fa-info" style={{margin: '4px', padding: '13px 20px', border: '1px solid', borderRadius: '10px', color: 'white', backgroundColor: 'black'}} />
        //                                                   <span className="tooltiptext" style={{fontSize: '0.9em', left: '-33px'}}>Não informado</span>
        //                                               </div>
        //                                               <Button className={'float-right m-1 ' + FuncoesComum.solida()} onClick={this.modalConsulta} data-value={this.state.consulta}>
        //                                                   ANEXAR NOVOS ARQUIVOS
        //                                               </Button>
        //                                           </div>
        //                     documento = 'Reenvio'
        //                 break
        //             }
        //         }
        //     }

        //     // Caso documentos não foram negados, existem documentos, já houve análise e não precisará de novos arquivos,
        //     // poderá então ser gerado o contrato para essa consulta
        //     // (Verifica de todas as pessoas vinculadas a consulta)
        //     if(documentacao === ''){
        //         documentacao = <Button className='btn-success float-right m-1' to={'/admin/formcontratoconsulta/' + this.state.consulta} tag={Link}>Gerar contrato</Button>
        //         documento = 'Gerar'
        //     }
            
        
        //     // Caso a consulta tenha sido negada ele dá preferência para essa opção
        //     for(var i = 0; i < pessoas.length; i++){
        //         // if(pessoas[i].idwall){
        //         //     if(pessoas[i].idwall.result){
        //                 if(pessoas[i].status.indexOf('Negado') !== -1){
        //                     documentacao = ''
        //                     documento = ''
        //                     break
        //                 }
        //         //     }
        //         //     else{
        //         //         documentacao = ''
        //         //     }
        //         // }
        //         // else{
        //         //     documentacao = ''
        //         // }
        //     }

            
            

        //     this.setState({
        //         tamanhoCol: this.state.tamanhoCol / dividirPor, 
        //         retornoConsulta: dicionario, 
        //         pessoas: pessoas, 
        //         button: documentacao,
        //         documento: documento,
        //         dataConsulta: retorno.data.data[0].data_consulta
        //     })

        //     if(new Date(retorno.data.data[0].data_consulta) > new Date('2020-07-12')){

        //         var statusConsulta
        //         for(var i = 0; i < pessoas.length; i++){
        //             if(pessoas[i].status.indexOf('Negado') !== -1){
        //                 statusConsulta = 2
        //                 break
        //             }
        //         }
    
        //         // if(documento !== ''){
        //             console.log(pessoas[0].renda_informada*this.state.porcentagemCarta)
        //             console.log(this.state.porcentagemCarta)
        //             if(pessoas[0].renda_informada*this.state.porcentagemCarta <= this.state.semComprovante){
        //                 console.log('Verificação da renda')
        //                 documentacao = ''
        //                 documento = ''
        //                 this.setState({
        //                     button: documentacao,
        //                     documento: documento,
        //                     renda: 'SemDoc'
        //                 })
        //             }
        //         // }
    
        //         if(statusConsulta !== 2){
        //             api_epar.get( '/cartagarantiaconsulta/' + this.props.match.params.id).then((cartaConsulta) => {
        //                 if(cartaConsulta.data.data.length === 0){
        //                     cartagarantia = <Button className={'btn-danger float-right m-1'} onClick={this.modalEnviarCarta}>Gerar carta de crédito</Button>
        //                     carta = 'Enviar'
        //                 }
        //                 else{
        //                     if(cartaConsulta.data.data.length === 1 && new Date(cartaConsulta.data.data[0].data_validade) >= new Date()){
        //                         cartagarantia = ''
        //                         carta = 'Gerada'
        //                         // cartagarantia = <Button className={'btn-success float-right m-1'} onClick={() => {this.props.history.push('/admin/cartagarantia')}}>Gerar contrato</Button>
        //                         // carta = 'Gerada'
        //                     }
        //                     else{
        //                         cartagarantia = <Button className={'btn-warning float-right m-1'} style={{cursor: 'not-allowed'}} disabled>Carta de crédito fora do prazo</Button>
        //                         carta = 'Vencida'
        //                     }
        //                 }
        //                 this.setState({
        //                     cartagarantia: cartagarantia,
        //                     carta: carta,
        //                 })
        //             })
        //             .catch((erro) => {
        //                 console.log(erro.message)
        //             })
        //         }           
        //     }

        //     this.setState({
        //         button: documentacao,
        //         documento: documento,
        //     })
        //     // for(var cont = 0; cont < pessoas.length; cont++){
        //     //     // var documentacao = 0
        //     //     api_epar.get( '/clienteshasdocumentacao/' + pessoas[cont].cliente_id + '/1').then((doc) => {
        //     //         if(doc.data.data.length === 0){
        //     //             // documentacao = 1
        //     //             this.setState({button: <Button className='btn-dark float-right' onClick={this.modalConsulta} data-value={this.state.consulta}>Upload de arquivos</Button>})
        //     //         }
        //     //     })
        //     // }
            
            
        // }).catch((error) => {
        //     console.log(error)
        // })
    }

    // Exibir MODAL para ALERT
    toggleAlert = () => {
        this.setState({alert: !this.state.alert})
    }


    // Função para ajudar na formatação da consulta
    pessoasRetorno = (retorno, id_cliente) => {
        console.log(retorno)
        var dadosFalta = 0 
        var dadosCadastrais
        var dadosFinanceiros 
        var idwall
        var tipo_locatario
        var nome
        var cpf_cnpj
        var telefone
        var renda_informada
        var status = ''
        var motivo = ''
        var cliente_id
        var countDoc
        var statusDoc
        var email
        var statusDocMin
        for(var i = 0; i < retorno.data.data.length; i++){
            if(retorno.data.data[i].clientes_id === id_cliente){
                if(dadosFalta === 0){
                    nome = retorno.data.data[i].nome
                    cpf_cnpj = retorno.data.data[i].cpf_cnpj
                    tipo_locatario = retorno.data.data[i].tipo_locatario
                    telefone = retorno.data.data[i].telefone
                    renda_informada = retorno.data.data[i].renda_informada
                    status += retorno.data.data[i].status + ', '
                    motivo += retorno.data.data[i].motivo + ', '
                    cliente_id = retorno.data.data[i].clientes_id
                    countDoc = retorno.data.data[i].countDoc
                    statusDoc = retorno.data.data[i].statusDoc
                    statusDocMin = retorno.data.data[i].statusDocMin
                    if(retorno.data.data[i].tipo_consulta_id === 1){
                        dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
                        dadosFalta = 1
                        status += retorno.data.data[i].status + ', '
                        motivo += retorno.data.data[i].motivo + ', '
                    }
                    else if(retorno.data.data[i].tipo_consulta_id === 2){
                        dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json)
                        dadosFalta = 2
                        if(tipo_locatario === 'Principal' && JSON.parse(retorno.data.data[i].retorno_json) && JSON.parse(retorno.data.data[i].retorno_json).content.emails.conteudo && JSON.parse(retorno.data.data[i].retorno_json).content.emails.conteudo.length > 0){
                            if(retorno.data.data[i].email){
                                this.setState({emailCarta: retorno.data.data[i].email})
                            }
                            else {
                                this.setState({emailCarta: JSON.parse(retorno.data.data[i].retorno_json).content.emails.conteudo[0].email})
                            }
                        }
                    }
                }
                else if(dadosFalta === 1 && retorno.data.data[i].tipo_consulta_id === 2){
                    dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json.replace(/'/g, '"'))
                }
                else if(dadosFalta === 2 && retorno.data.data[i].tipo_consulta_id === 1){
                    dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
                    status += retorno.data.data[i].status + ', '
                    motivo += retorno.data.data[i].motivo + ', '
                }
                if(retorno.data.data[i].tipo_consulta_id === 3){
                    idwall = JSON.parse(retorno.data.data[i].retorno_json.replace(/'/g, '"').replace(/[\r\n]/g, " "))
                    status += retorno.data.data[i].status + ', '
                    motivo += retorno.data.data[i].motivo + ', '
                }
            }
        }
        return {
            nome: nome,
            cliente_id: cliente_id,
            cpf_cnpj: cpf_cnpj,
            tipo_locatario: tipo_locatario,
            telefone: telefone,
            renda_informada: renda_informada,
            status: status,
            motivo: motivo,
            countDoc: countDoc,
            statusDoc: statusDoc,
            statusDocMin: statusDocMin,
            dadosCadastrais: dadosCadastrais,
            dadosFinanceiros: dadosFinanceiros,
            idwall: idwall
        }
    }

    carregarRetorno = (record) => {
        var status = 4
        var icon 
        var color
        var mensagem
        var resultado
        var mensagemAnalise
        // console.log(record.pessoas[0].idwall.result)

        // Verifica se houve negação na consulta
        for(var i = 0; i < record.pessoas.length; i++){
            if(record.pessoas[i].status.indexOf('Negado') !== -1){
                status = 2
                break
            }
        }

        if(record.score_status === 0 || record.pendenciafinanceira_status === 0 || 
            record.processo_status === 0 || record.antecedente_status === 0 || record.status_final === 0 ){
                // console.log('ENTROU')
                status = 2
            }

        // // Caso não tenha sido negado, verifica se está pré-aprovada por tempo excedido 
        // if(status !== 2){
        //     for(var i = 0; i < record.pessoas.length; i++){
        //         if(record.pessoas[i].status.indexOf('Aprovado por tempo excedido') !== -1){
        //             status = 3
        //             break
        //         }
        //     }
        // }


        // Caso não tenha sido negado e nem pré-aprovada, verifica todas as possibilidades 
        // if(status !== 2 && status !== 3){
        if(status !== 2){
            // if(record.pessoas[0].idwall){
            //     if(record.pessoas[0].idwall.result){
                    for(var i = 0; i < record.pessoas.length; i++){
                        if(record.pessoas[i].status.indexOf('Negado') !== -1){
                            status = 2
                            break
                        }
                        // if(record.pessoas[i].status.indexOf('Aprovado por tempo excedido') !== -1){
                        //     status = 3
                        // }
                        else if(record.pessoas[i].status.indexOf('Aprovado com ressalva') !== -1){
                            status = 1
                            
                        }
                        else if(record.pessoas[i].status.indexOf('Aprovado') !== -1){
                            status = 0
                        }
                    }
            //     }
            //     else{
            //         status = 4
            //     }
            // }
            // else{
            //     status = 4
            // }
        }

        if(new Date(record.data_consulta) > new Date('2020-07-12')){
            if(status !== 2){
                if(this.state.documento !== ''){
                    // if(this.state.documento === 'Gerar'){
                    //     status = 5
                    // }
            
                    if(this.state.documento === 'Negado'){
                        status = 2
                    }
                }
            }
        }
        else{
            if(status !== 2){
                if(this.state.documento !== ''){
                    if(this.state.documento === 'Gerar'){
                        status = 5
                    }
            
                    if(this.state.documento === 'Negado'){
                        status = 2
                    }
                }
                else{
                    status = 2
                }
            }
        }

        if(new Date(record.data_consulta) > new Date('2020-08-04') && this.state.carta !== 'Gerada' && !this.state.renda && status !== 2){
            status = 6
        }

        if(status === 0 && new Date(record.data_consulta) > new Date('2020-07-12')){
            color = '#006600'
            icon = 'ni ni-check-bold'
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO {this.state.button}</div>
            mensagem = <div style={{fontSize: '1.5em', textAlign: 'center'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            mensagemAnalise = <div style={{textAlign: 'center', marginTop: '-20px'}}>
                <p style={{fontSize: '0.8em'}}><strong>Garantimos ao</strong></p> 
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#673778'}}><strong>{this.state.pessoas[0].dadosCadastrais ? this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome : 'Não encontrado'}</strong></p>
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>CPF {this.state.pessoas[0].cpf_cnpj}</strong></p>
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#FF5A5B'}}><strong>{FuncoesComum.formatarMoeda(parseFloat(this.state.valor_carta))}</strong></p> 
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>Para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos (IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).</strong></p>
            </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ.</div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA.</div>
        }
        if(status === 0 && new Date(record.data_consulta) < new Date('2020-07-12')){
            color = '#006600'
            icon = 'ni ni-check-bold'
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO {this.state.button}</div>
            mensagem = <div style={{fontSize: '1.5em', textAlign: 'center'}}>APROVADO</div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            resultado = 'Aprovado'
            // resultado = 'Pré-aprovado'
            // mensagemAnalise = <div style={{textAlign: 'center', marginTop: '-20px'}}>
            //     <p style={{fontSize: '0.8em'}}><strong>Garantimos ao</strong></p> 
            //     <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#673778'}}><strong>{this.state.pessoas[0].dadosCadastrais ? this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome : 'Não encontrado'}</strong></p>
            //     <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>CPF {this.state.pessoas[0].cpf_cnpj}</strong></p>
            //     <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#FF5A5B'}}><strong>{FuncoesComum.formatarMoeda(parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta)}</strong></p> 
            //     <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>Para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos (IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).</strong></p>
            // </div>
            mensagemAnalise = <div>ESTAMOS QUASE LÁ.</div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA.</div>
        }
        if(status === 1 && new Date(record.data_consulta) > new Date('2020-07-12')){
            color = '#006600'
            icon = 'ni ni-check-bold'
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO {this.state.button}</div>
            mensagem = <div style={{fontSize: '1.5em', textAlign: 'center'}}>APROVADO</div>
            resultado = 'Aprovado'
            mensagemAnalise = <div style={{textAlign: 'center', marginTop: '-20px'}}>
                <p style={{fontSize: '0.8em'}}><strong>Garantimos ao</strong></p> 
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#673778'}}><strong>{this.state.pessoas[0].dadosCadastrais ? this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome : 'Não encontrado'}</strong></p>
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>CPF {this.state.pessoas[0].cpf_cnpj}</strong></p>
                <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#FF5A5B'}}><strong>{!this.state.valor_carta ? FuncoesComum.formatarMoeda(parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta) : FuncoesComum.formatarMoeda(this.state.valor_carta)}</strong></p> 
                <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>Para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos (IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).</strong></p>
            </div>
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            // resultado = 'Pré-aprovado'
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>
        }
        if(status === 1 && new Date(record.data_consulta) < new Date('2020-07-12')){
            color = '#006600'
            icon = 'ni ni-check-bold'
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO {this.state.button}</div>
            mensagem = <div style={{fontSize: '1.5em', textAlign: 'center'}}>APROVADO</div>
            resultado = 'Aprovado'
            // mensagemAnalise = <div style={{textAlign: 'center', marginTop: '-20px'}}>
            //     <p style={{fontSize: '0.8em'}}><strong>Garantimos ao</strong></p> 
            //     <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#673778'}}><strong>{this.state.pessoas[0].dadosCadastrais ? this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome : 'Não encontrado'}</strong></p>
            //     <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>CPF {this.state.pessoas[0].cpf_cnpj}</strong></p>
            //     <p style={{fontSize: '1.2em', marginTop: '-10px', color: '#FF5A5B'}}><strong>{FuncoesComum.formatarMoeda(parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta)}</strong></p> 
            //     <p style={{fontSize: '0.8em', marginTop: '-10px'}}><strong>Para locar um imóvel, até o limite mencionado, incluindo além do aluguel os encargos (IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS).</strong></p>
            // </div>
            mensagemAnalise = <div>ESTAMOS QUASE LÁ. </div>
            // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
            // resultado = 'Pré-aprovado'
            // mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA. </div>
        }
        if(status === 2){
            color = '#660000'
            icon= 'ni ni-fat-remove'
            mensagem = <div>ENCONTRAMOS ALGO ERRADO, <br/>
                       MELHOR NÃO PROSSEGUIR!</div>
            resultado = 'Inválido'
            mensagemAnalise = 'Informamos que o pretendente foi recusado pela análise de risco, não se enquadrando na política de aceitação da garantia Alug+.'
        }
        // if(status === 3){
        //     color = '#006600'
        //     icon = 'ni ni-check-bold'
        //     // mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO {this.state.button}</div>
        //     mensagem = <div style={{fontSize: '1.5em'}}>PRÉ APROVADO</div>
        //     resultado = 'Pré-aprovado'
        //     mensagemAnalise = <div>ESTAMOS QUASE LÁ, PRECISAMOS APENAS DO COMPROVANTE DE RENDA.</div>
        //     // color = '#000000'
        //     // icon = 'ni-check-bold'
        //     // mensagem = 'Aprovado por tempo excedido!'
        //     // resultado = 'Tempo excedido'
        //     // mensagemAnalise = 'Essa consulta já está em espera mais de 5 minutos, será aprovado momentaneamente. Porém, após realizada toda a consulta, o status aprovado poderá ser alterado conforme a análise do retorno. '
        // }
        if(status === 4){
            color = '#737373'
            icon= 'ni ni-user-run'
            mensagem = <div><img src={require('assets/img/aguarde.gif')} className='p-2 mb--1' style={{width: '13%'}}/></div>
            resultado = 'Aguarde...'
            mensagemAnalise = 'Estamos analisando as informações....'
        }
        if(status === 5){
            color = '#3b1e3e'
            icon = 'ni ni-check-bold'
            // mensagem = <div style={{fontSize: '1.5em'}}>TUDO CERTO, PODE ALUGAR! {this.state.button}</div>
            mensagem = <div style={{fontSize: '1.5em'}}>TUDO CERTO, PODE ALUGAR!</div>
            resultado = 'Válido'
            mensagemAnalise = <div>GERAR CONTRATO GARANTIA ALUG+. </div> 
        }
        if(status === 6){
            color = '#ffaf1a'
            icon= 'ni ni-folder-17'
            mensagem = <div style={{fontSize: '1.5em'}}>Anexar comprovantes de renda</div>
            resultado = 'Arquivos'
            mensagemAnalise = 'Para que a consulta seja aprovada será necessário anexar os comprovantes de renda'
        }

        return (
            <div key={record.consulta_id} className='mb-5' style={{width: '80%'}}>
                <Row className='p-1' style={{borderRadius: '5px', backgroundColor: '#d9d9d9', color: 'black'}}>
                    <Col className='float-left mt-2' md={5}>
                        <Label style={{backgroundColor: color, borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>Nome: {record.pessoas[0].dadosCadastrais && record.pessoas[0].dadosCadastrais.content.nome.conteudo.nome}</Label>
                    </Col>
                    <Col md={4} className='float-left mt-2'>
                        <Label style={{backgroundColor: color, borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>Documento: {record.pessoas[0].cpf_cnpj}</Label>
                    </Col>
                    <Col md={3}  className='float-right mt-2'>
                        <Label style={{backgroundColor: color, borderRadius: '4px', color: 'white', fontSize: '0.8em'}} className='p-1'>{resultado}</Label>
                    </Col>
                </Row>
                <Row className='mt-2 p-2' style={{border: '1px solid  #d9d9d9', borderRadius: '5px'}}>
                    <Col md={4} className='d-flex justify-content-center'>
                        {status !== 5 && <i className={"text-white p-3 rounded-circle text-center " + icon} style={{margin: '4vh', fontSize: '6em', backgroundColor: color, width: '130px', height: '130px'}}/>}
                        {status === 5 && <img src={require('assets/img/alugmaisicon.png')} style={{height: '100px', margin: '4vh'}}/>}
                    </Col>
                    <Col md={8}>
                        <div className='' style={{fontSize: '1.3em', color: color}}>
                            <strong>{mensagem}</strong>
                            {/* {this.state.button} */}
                        </div><br/>
                        <div className='mb-2 float-left text-left' style={{fontSize: '1.1em', fontWeight: 'bolder'}}>
                            {mensagemAnalise}
                        </div><br/>
                        {/* {this.state.button} */}
                        {/* <div className='mb-1 float-left text-left' style={{fontSize: '0.7em'}}>
                            Informamos que a decisão de aprovação ou não da pessoa pesquisada é de exclusiva responsabilidade do(a) requerente, sendo que o parecer prestado possui objetivo apenas de balizar a decisão a ser tomada.
                        </div> */}
                    </Col>
                </Row>
            </div>
        )
    }


    // Identificar os motivos que a consulta possa ter sida negada ou tenha alguma restrição que interfirá o prosseguimento 
    identificarMotivos = (record) => {
        var motivos = ''
        var status = ''
        var exibir = 1
        for(var i = 0; i < record.pessoas.length; i++){
            motivos += record.pessoas[i].motivo
            status += record.pessoas[i].status
        }
        var todosMotivos = new Set(motivos.replace(/ /g, '').split(','))
        var todosStatus = new Set(status.replace(/ /g, '').split(','))
        var arrayMotivos = []
        var arrayStatus = []
        for(let motivo of todosMotivos){
            arrayMotivos.push(motivo)
        }
        for(let status of todosStatus){
            arrayStatus.push(status)
        }

        if(arrayStatus.indexOf('Negado') !== -1){
            exibir = 0
        }
        if(arrayStatus.indexOf('Aprovadocomressalva') !== -1){
            exibir = 0
        }


        if(exibir === 1){
            return(null)
        }
        else{
            return(
                <div key={record.consulta_id} className='text-center' style={{border: '1px solid  #d9d9d9', borderRadius: '5px', width: '83%'}}>
                    <div className='pt-3'>O candidato não atende os seguintes critérios:</div>
                    <div className='p-4'>
                        {arrayMotivos.map(this.retornoMotivos)}
                    </div>
                </div>
            )
        }
    }

    // Após identificar os motivos, exibe um texto amigável ao usuário 
    retornoMotivos = (motivo) => {
        var color = ''
        for(var i = 0; i < this.state.retornoConsulta[0].pessoas.length; i++){
            if(this.state.retornoConsulta[0].pessoas[i].status.indexOf('Negado') !== -1){
                color = '#660000'
                break
            }
            else if(this.state.retornoConsulta[0].pessoas[i].status.indexOf('Aprovado com ressalva') !== -1){
                color = '#006600'
                break
            }
        }
        
        if(this.state.documento === 'Negado'){
            color = '#660000'
        }
        if(this.state.documento === 'Gerar'){
            color = '#3b1e3e'
        }

        var textoMotivo = ''
        if(motivo !== '' && color !== '' && motivo !== 'null'){
          if(motivo === '1'){
            textoMotivo += 'Pendências internas e financeiras é maior que 50% do valor para locação' 
          }
          if(motivo === '2'){
            textoMotivo += 'Score menor que 30%' 
          }
          if(motivo === '3'){
            textoMotivo += 'Possui processos criminais' 
          }
          if(motivo === '10'){
            textoMotivo += 'Pendências internas e financeiras é menor que 50% do valor para locação' 
          }
          if(motivo === '20'){
            textoMotivo += 'Score menor que 50% e maior que 30%' 
          }
          if(motivo === '30'){
            textoMotivo += 'Possui processos' 
          }
          if(motivo === '40'){
            textoMotivo += 'Comprometimento mensal estimado é maior que 50%' 
          }
          if(motivo === '50'){
            textoMotivo += 'Possui antecedentes criminais' 
          }
          return (
                <div key={motivo} className='text-center' style={{border: '1px solid  #d9d9d9', width: '100%'}}>
                    <div>
                        <Label className={'pt-2'} style={{color: color}}>
                            <i className={"fas fa-exclamation text-white p-1 rounded-circle text-center mr-2"} style={{fontSize: '0.8em', backgroundColor: color, width: '20px', height: '20px'}}/>
                            {textoMotivo}
                        </Label>
                    </div>
                </div>
            )
        }
        else{
            return (
                null
            )
        }
      }

      ocultarDiv = (id) => {
            if(document.getElementById(id).classList.contains('ocultarInformacoes')){
                document.getElementById(id).classList.remove('ocultarInformacoes')
            }
            else{
                document.getElementById(id).classList.add('ocultarInformacoes')
            }
      }

      //Irá exibir os dados pessoais de cada cliente relacionado na consulta
        dadosPessoais = (record) => {
            return(
                <div key={record.content.nome.conteudo.nome}>
                    <Row className={'pl-3 pr-3 pt-1 mb-1'} style={{fontSize: '0.9em'}}>
                        <Col xs={8}>
                            Data de nascimento
                        </Col>
                        <Col xs={4} className='text-right'>
                            {record.content.nome.conteudo.data_nascimento}
                        </Col>
                    </Row>
                    <Row className={'pl-3 pr-3 pt-1 mb-1'} style={{fontSize: '0.9em'}}>
                        <Col xs={8}>
                            Idade
                        </Col>
                        <Col xs={4} className='text-right'>
                            {record.content.nome.conteudo.idade}
                        </Col>
                    </Row>
                    <Row className={'pl-3 pr-3 pt-1 mb-1'} style={{fontSize: '0.9em'}}>
                        <Col xs={8}>
                            Sexo
                        </Col>
                        <Col xs={4} className='text-right'>
                            {record.content.nome.conteudo.sexo}
                        </Col>
                    </Row>
                    <Row className={'pl-3 pr-3 pt-1 mb-1'} style={{fontSize: '0.9em'}}>
                        <Col xs={8}>
                            Nacionalidade
                        </Col>
                        <Col xs={4} className='text-right'>
                            {record.content.nome.conteudo.estrangeiro  && record.content.nome.conteudo.estrangeiro.pais_origem && record.content.nome.conteudo.estrangeiro.pais_origem.origem }
                        </Col>
                    </Row>
                    <Row className={'pl-3 pr-3 pt-1 mb-3'} style={{fontSize: '0.9em'}}>
                        <Col xs={8}>
                            Situação Receita Federal
                        </Col>
                        <Col xs={4} className='text-right'>
                            {record.content.nome.conteudo.situacao_receita}
                        </Col>
                    </Row>
                    <Row className={'p-3'} style={{cursor: 'pointer', backgroundColor: '#e6e6e6'}} onClick={() => {this.ocultarDiv('enderecos'+record.content.nome.conteudo.documento)}}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            Endereços
                        </Col>
                        <Col xs={6} className='text-right' style={{fontSize: '0.9em'}}>
                            <i className='ni ni-bold-down pr-2' style={{borderRadius: '6px', fontSize: '0.9em'}} />
                            Detalhes
                        </Col>
                    </Row>
                    <div id={'enderecos'+record.content.nome.conteudo.documento} className='ocultarInformacoes' style={{width: '100%'}}>
                        {record.content.pesquisa_enderecos.conteudo && record.content.pesquisa_enderecos.conteudo.map(this.dadosEndereco)}
                        {!record.content.pesquisa_enderecos.conteudo && <div className='p-3' style={{fontSize: '0.8em', fontStyle: 'italic'}}>Sem endereços</div>}
                    </div>
                    <Row className={'p-3'} style={{cursor: 'pointer', backgroundColor: '#e6e6e6'}} onClick={() => {this.ocultarDiv('telefones'+record.content.nome.conteudo.documento)}}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            Telefones
                        </Col>
                        <Col xs={6} className='text-right' style={{fontSize: '0.9em'}}>
                            <i className='ni ni-bold-down pr-2' style={{borderRadius: '6px', fontSize: '0.9em'}} />
                            Detalhes
                        </Col>
                    </Row>
                    <div id={'telefones'+record.content.nome.conteudo.documento} className='ocultarInformacoes' style={{width: '100%'}}>
                        <div className='m-2 mb-4'>
                            <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                                <Col md={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                    Telefone fixo
                                </Col>
                            </Row>
                            {record.content.pesquisa_telefones.conteudo && record.content.pesquisa_telefones.conteudo.fixo && 
                            record.content.pesquisa_telefones.conteudo.fixo.map(this.dadosTelefone)}
                        </div>
                        <div className='m-2 mb-4'>
                            <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                                <Col md={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                    Telefone celular
                                </Col>
                            </Row>
                            {record.content.pesquisa_telefones.conteudo && record.content.pesquisa_telefones.conteudo.celular && 
                            record.content.pesquisa_telefones.conteudo.celular.map(this.dadosTelefone)}
                        </div>
                        <div className='m-2 mb-4'>
                            <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                                <Col md={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                    Telefone comercial
                                </Col>
                            </Row>
                            {record.content.pesquisa_telefones.conteudo && record.content.pesquisa_telefones.conteudo.comercial && 
                            record.content.pesquisa_telefones.conteudo.comercial.map(this.dadosTelefone)}
                        </div>
                        <div className='m-2 mb-4'>
                            <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                                <Col md={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                    Outros telefones
                                </Col>
                            </Row>
                            {record.content.pesquisa_telefones.conteudo && record.content.pesquisa_telefones.conteudo.outros && 
                            record.content.pesquisa_telefones.conteudo.outros.map(this.dadosTelefone)}
                        </div>
                    </div>
                    <Row className={'p-3'} style={{cursor: 'pointer', backgroundColor: '#e6e6e6'}} onClick={() => {this.ocultarDiv('emails'+record.content.nome.conteudo.documento)}}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            Emails
                        </Col>
                        <Col xs={6} className='text-right' style={{fontSize: '0.9em'}}>
                            <i className='ni ni-bold-down pr-2' style={{borderRadius: '6px', fontSize: '0.9em'}} />
                            Detalhes
                        </Col>
                    </Row>
                    <div id={'emails'+record.content.nome.conteudo.documento} className='ocultarInformacoes' style={{width: '100%'}}>
                        {record.content.emails.conteudo &&  record.content.emails.conteudo.length > 0 &&  record.content.emails.conteudo.map(this.dadosEmail)}
                        {(!record.content.emails.conteudo || record.content.emails.conteudo.length === 0) && <div className='p-3' style={{fontSize: '0.8em', fontStyle: 'italic'}}>Sem email</div>}
                    </div>
                    <Row className={'p-3'} style={{cursor: 'pointer', backgroundColor: '#e6e6e6'}} onClick={() => {this.ocultarDiv('beneficios'+record.content.nome.conteudo.documento)}}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            Benefícios
                        </Col>
                        <Col xs={6} className='text-right' style={{fontSize: '0.9em'}}>
                            <i className='ni ni-bold-down pr-2' style={{borderRadius: '6px', fontSize: '0.9em'}} />
                            Detalhes
                        </Col>
                    </Row>
                    <div id={'beneficios'+record.content.nome.conteudo.documento} className='ocultarInformacoes' style={{width: '100%'}}>
                        {record.content.numero_beneficio.conteudo && [record.content.numero_beneficio.conteudo].map(this.dadosBeneficio)}
                        {!record.content.numero_beneficio.conteudo && <div className='p-3' style={{fontSize: '0.8em', fontStyle: 'italic'}}>Sem benefícios</div>}
                    </div>
                    <Row className={'p-3'} style={{cursor: 'pointer', backgroundColor: '#e6e6e6'}} onClick={() => {this.ocultarDiv('parentes'+record.content.nome.conteudo.documento)}}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            Dados de Parentes
                        </Col>
                        <Col xs={6} className='text-right' style={{fontSize: '0.9em'}}>
                            <i className='ni ni-bold-down pr-2' style={{borderRadius: '6px', fontSize: '0.9em'}} />
                            Detalhes
                        </Col>
                    </Row>
                    <div id={'parentes'+record.content.nome.conteudo.documento} className='ocultarInformacoes' style={{width: '100%'}}>
                        {
                            record.content.dados_parentes && record.content.dados_parentes.conteudo ? (
                                record.content.dados_parentes.conteudo.map(this.dadosParentes)
                            ) : (
                                <div className='p-3' style={{fontSize: '0.8em', fontStyle: 'italic'}}>Sem informações de parentes</div>
                            )
                        }
                    </div>
                </div>
            )
        }

        // Endereços encontrados referente a um cliente da consulta em questão
        dadosEndereco = (record) => {
            return (
                <div className='m-2 mb-4' key={record.endereco}>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Endereço
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Número
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Complemento
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            {record.logradouro + ' ' + record.endereco}
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.numero}
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.complemento}
                        </Col>
                    </Row>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Bairro
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Cidade
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Estado
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            {record.bairro}
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.cidade}
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.uf}
                        </Col>
                    </Row>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            CEP
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={2} style={{fontSize: '0.9em'}}>
                            {record.cep}
                        </Col>
                    </Row>
                </div>
            )
        }
        
        // Telefones encontrados referente a um cliente da consulta em questão
        dadosTelefone = (record) => {
            return (
                <Row className={'p-3'} key={record.telefone}>
                    <Col xs={6} style={{fontSize: '0.9em'}}>
                        DDD: {record.ddd}
                    </Col>
                    <Col xs={6} style={{fontSize: '0.9em'}}>
                        Telefone: {record.telefone}
                    </Col>
                </Row>
            )
        }

        // Emails encontrados referente a um cliente da consulta em questão
        dadosEmail = (record) => {
            return (
                <div className='m-2 mb-4' key={record.email}>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col md={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Email
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col md={12} style={{fontSize: '0.9em'}}>
                            {record.email}
                        </Col>
                    </Row>
                </div>
            )
        }

        // Benefícios encontrados referente a um cliente da consulta em questão
        dadosBeneficio = (record) => {
            return (
                <div className='m-2 mb-4' key={record.endereco}>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            INSS
                        </Col>
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Número
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            {record.inss}
                        </Col>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            {record.numero}
                        </Col>
                    </Row>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Afastado por doença
                        </Col>
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Acidente de trabalho
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            {record.afastado_doenca}
                        </Col>
                        <Col xs={6} style={{fontSize: '0.9em'}}>
                            {record.acidente_trabalho}
                        </Col>
                    </Row>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Ano aposentadoria
                        </Col>
                        <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Tipo de aposentadoria
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={2} style={{fontSize: '0.9em'}}>
                            {record.ano_aposentadoria}
                        </Col>
                        <Col xs={2} style={{fontSize: '0.9em'}}>
                            {record.tipo_aposentadoria}
                        </Col>
                    </Row>
                </div>
            )
        }

        // Parentes encontrados referente a um cliente da consulta em questão
        dadosParentes = (record) => {
            return (
                <div className='m-2 mb-4' key={record.endereco}>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Documento
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Nome
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Parentesco
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.documento}
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em'}}>
                            {record.nome}
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em'}}>
                            {record.campo}
                        </Col>
                    </Row>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Óbito
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Benefício
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Aposentado
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.obito}
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em'}}>
                            {record.tipo_beneficio}
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em'}}>
                            {record.aposentado}
                        </Col>
                    </Row>
                </div>
            )
        }

        // Certidões encontrados referente a um cliente da consulta em questão
        certidoes = (record) => {
            var consta = <i className="ni ni-check-bold bg-success text-white p-1 rounded-circle text-center" style={{fontSize: '0.7em', width: '20px', height: '20px'}}/> 
                        
            if(record.nada_consta === true){
                consta = <i className="ni ni-check-bold bg-success text-white p-1 rounded-circle text-center" style={{fontSize: '0.7em', width: '20px', height: '20px'}}/>            
            }
            else{
                consta = <i className="ni ni-fat-remove text-white p-1 rounded-circle text-center" style={{fontSize: '0.7em', backgroundColor: '#660000', width: '20px', height: '20px'}}/>    
            }
            return(
                <div className='m-2 mb-0' key={record.fonte}>
                    <Row className={'p-2'}>
                        <Col xs={4} style={{fontSize: '0.9em'}} className='quebrarLinha'>
                            {record.fonte}
                        </Col>
                        <Col xs={2} className='quebrarLinha'>
                            {consta}
                        </Col>
                        <Col xs={2} style={{fontSize: '0.8em'}} className='quebrarLinha'>
                            {new Date(record.emitido_em).toLocaleString('pt-BR')}
                            {/* {record.emitido_em} */}
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em'}} className='quebrarLinha'>
                            {record.protocolo}
                        </Col>
                    </Row>
                </div>
            )
        }

        // Processos encontrados referente a um cliente da consulta em questão
        processos = (record) => {
            return(
                <div key={record.numero}>
                    <Row className={'p-3'} style={{cursor: 'pointer', backgroundColor: '#e6e6e6'}} onClick={() => {this.ocultarDiv(record.numero)}}>
                        <Col xs={10} style={{fontSize: '0.9em'}}>
                            {record.fonte + ': '} {record.assunto || 'Dados do processo'}
                        </Col>
                        <Col xs={2} className='text-right' style={{fontSize: '0.9em'}}>
                            <i className='ni ni-bold-down pr-2' style={{borderRadius: '6px', fontSize: '0.9em'}} />
                            Detalhes
                        </Col>
                    </Row>
                    <div id={record.numero} className='m-2 mb-4'>
                        <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                            <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                Fonte
                            </Col>
                            <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                Número
                            </Col>
                        </Row>
                        <Row className={'p-3'}>
                            <Col xs={6} style={{fontSize: '0.9em'}}>
                                {record.fonte}
                            </Col>
                            <Col xs={6} style={{fontSize: '0.9em'}}>
                                {record.numero}
                            </Col>
                        </Row>
                        <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                            <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                Classe
                            </Col>
                            <Col xs={6} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                Instância
                            </Col>
                        </Row>
                        <Row className={'p-3'}>
                            <Col md={6} style={{fontSize: '0.9em'}}>
                                {record.classe}
                            </Col>
                            <Col md={3} style={{fontSize: '0.9em'}}>
                                {record.instancia}
                            </Col>
                        </Row>
                        <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                            <Col xs={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                URL
                            </Col>
                        </Row>
                        <Row className={'p-3'}>
                            <Col xs={12} style={{fontSize: '0.9em'}} className='quebrarLinha'>
                                {record.url}
                            </Col>
                        </Row>
                        <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                            <Col xs={12} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                                Partes
                            </Col>
                        </Row>
                        <Row className={'p-3'}>
                            <Col md={12} style={{fontSize: '0.9em'}}>
                                {record.partes.map(this.partes)}
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        }

        // Partes encontrados de um processo referente a um cliente da consulta em questão
        partes = (record) => {
            return(
                <div className='m-2 mb-4' key={record.nome}>
                    <Row className={'p-2'} style={{backgroundColor: '#f2f2f2'}}>
                        <Col md={12} style={{fontSize: '0.9em'}}>
                            Nome: {record.nome}
                        </Col>
                    </Row>
                    <Row className={'p-2'}>
                        <Col md={12} style={{fontSize: '0.9em'}}>
                            Título: {record.titulo}
                        </Col>
                    </Row>
                </div>
            )
        }

        // Estados sem protestos encontrados referente a um cliente da consulta em questão
        estadosSem = (record) => {
            return(
                <div key={record} className='m-1 p-2 bg-dark' style={{color: 'white', borderRadius: '4px'}}>
                    {record}
                </div>
            )
        }

        // Estados com protestos encontrados referente a um cliente da consulta em questão
        estadosCom = (record) => {
            return(
                <div key={record} className='m-1 p-2 bg-danger' style={{color: 'white', borderRadius: '4px'}}>
                    {record}
                </div>
            )
        }

        // Bacens encontrados referente a um cliente da consulta em questão
        bacen = (record) => {
            return(
                <div className='m-2 mb-4' key={record.numero_cheque}>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Número do banco
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Nome do banco
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Agência
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.numero_banco}
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em'}}>
                            {record.nome_banco}
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em'}}>
                            {record.agencia}
                        </Col>
                    </Row>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Número do cheque
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Data
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.numero_cheque}
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em'}}>
                            {record.data_ocorrencia}
                        </Col>
                    </Row>
                </div>
            )
        }

        // Pendências internas encontrados referente a um cliente da consulta em questão
        pendenciasInternas = (record) => {
            return(
                <div className='m-2 mb-4' key={record.numero_cheque}>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={2} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Data da ocorrência
                        </Col>
                        <Col xs={2} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Valor
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Origem
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Contrato
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={2} style={{fontSize: '0.9em'}}>
                            {record.data_ocorrencia}
                        </Col>
                        <Col xs={2} style={{fontSize: '0.9em'}}>
                            {record.tipo_moeda + ' ' + record.valor}
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em'}}>
                            {record.origem}
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.contrato}
                        </Col>
                    </Row>
                </div>
            )
        }

        detalhesFinanceiros = (record) => {
            return(
                <div key={record.valor}>
                    {record.pendencias.map(this.pendenciasFinanceiras)}
                </div>
            )
        }

        // Pendências financeiras encontrados referente a um cliente da consulta em questão
        pendenciasFinanceiras = (record) => {
            return(
                <div className='m-2 mb-4' key={record.contrato}>
                    <Row className={'p-3'} style={{backgroundColor: '#f2f2f2'}} >
                        <Col xs={2} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Data da ocorrência
                        </Col>
                        <Col xs={2} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Valor
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Origem
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Contrato
                        </Col>
                    </Row>
                    <Row className={'p-3'}>
                        <Col xs={2} style={{fontSize: '0.9em'}}>
                            {record.data_ocorrencia}
                        </Col>
                        <Col xs={2} style={{fontSize: '0.9em'}}>
                            {record.tipo_moeda + ' ' + record.valor}
                        </Col>
                        <Col xs={5} style={{fontSize: '0.9em'}}>
                            {record.origem}
                        </Col>
                        <Col xs={3} style={{fontSize: '0.9em'}}>
                            {record.contrato}
                        </Col>
                    </Row>
                </div>
            )
        }

        // Valor do score encontrado referente a um cliente da consulta em questão
        score = (record) => {
            var score = <div>
                            <span className="text-white bg-success text-center p-1" style={{fontSize: '0.8em'}}/> 
                        </div>

            if(parseFloat(record.percentual) < 25){
                score = <div>
                            <span className="text-white text-center p-1" style={{fontSize: '0.8em', backgroundColor: '#660000', borderRadius: '5px'}}>{record.percentual} %</span> 
                        </div>
            }
            else if(parseFloat(record.percentual) > 25 && parseFloat(record.percentual) < 50){
                score = <div>
                            <span className="text-white text-center p-1" style={{fontSize: '0.8em', backgroundColor: '#ffc107', borderRadius: '5px'}}>{record.percentual} %</span> 
                        </div>
            }
            else{
                score = <div>
                            <span className="text-white bg-success text-center p-1" style={{fontSize: '0.8em', borderRadius: '5px'}}>{record.percentual} %</span> 
                        </div>
            }
            return(
                <Row className='p-3' key={record.percentual}>
                    <Col xs={6} style={{fontSize: '0.9em'}}>
                        Score estimado
                    </Col>
                    <Col xs={6} className='text-right'>
                        {score}
                    </Col>
                </Row>
            )
        }

        // Renda mensal estimada encontrada referente a um cliente da consulta em questão
        renda = (record) => {
            return(
                <Row className='p-3' key={record.renda}>
                    <Col xs={6} style={{fontSize: '0.9em'}}>
                        Renda mensal estimada
                    </Col>
                    <Col xs={6} className='text-right'>
                        <div>
                            <span className="text-white bg-success text-center p-1" style={{fontSize: '0.8em', borderRadius: '5px'}}>R$ {record.renda}</span> 
                        </div>
                    </Col>
                </Row>
            )
        }
        
        // Comprometimento mensal encontrado referente a um cliente da consulta em questão
        comprometimentoMensal = (record) => {
            var comprometimento = <div>
                            <span className="text-white bg-success text-center p-1" style={{fontSize: '0.8em'}}/> 
                        </div>

            if(parseFloat(record) < 50){
                comprometimento =   <div>
                                        <span className="text-white bg-success text-center p-1" style={{fontSize: '0.8em', borderRadius: '5px'}}>{record} %</span> 
                                    </div>
            }
            else{
                comprometimento =   <div>
                                        <span className="text-white text-center p-1" style={{fontSize: '0.8em', backgroundColor: '#ffc107', borderRadius: '5px'}}>{record} %</span> 
                                    </div>
            }
            return(
                <Row className='p-3' key={record}>
                    <Col xs={6} style={{fontSize: '0.9em'}}>
                        Comprometimento mensal estimado
                    </Col>
                    <Col xs={6} className='text-right'>
                        {comprometimento}
                    </Col>
                </Row>
            )
        }

        // Irá carregar os locatários encontrados referente a consulta em questão
      carregarDadosLocatarios = (record) => {

            var pessoais

            // Irá verificar se há dados cadastrais
            if(record.dadosCadastrais !== undefined){
                pessoais = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
            }
            else{
                pessoais = <i className='ni ni-fat-remove text-white p-2 float-right' style={{backgroundColor: '#660000', borderRadius: '6px'}} />
            }

          var certidoes
          var processos
          var protestos
            // Verifica se há todos os retornos necessários do IDWALL
            if(record.idwall !== undefined){
                if(record.idwall.result){
                    //Verfica se tem processo e quantos possuem
                    if(record.idwall.result.processos.itens.length === 0){
                        processos = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                    }
                    else{
                        processos = <span className='text-white p-2 float-right' style={{borderRadius: '6px', backgroundColor: '#FFC107'}}>{record.idwall.result.processos.itens.length}</span>
                    }
                }

                if(record.idwall.result && record.idwall.result.protestos ){
                    //Verfica se tem estados com protestos e quantos possuem
                    if(!record.idwall.result.protestos.estados_com_protestos){
                        protestos = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                    }
                    else{
                        protestos = <span className='text-white p-2 float-right' style={{borderRadius: '6px', backgroundColor: '#FFC107'}}>{record.idwall.result.protestos.estados_com_protestos.length}</span>
                    }
                }

                if(record.idwall.result){
                    //Verfica se tem certidões nagativas
                    if(!record.idwall.result.certidoes_negativas){
                        certidoes = <i className='ni ni-fat-remove text-white p-2 float-right' style={{backgroundColor: '#660000', borderRadius: '6px'}} />
                    }
                    else{
                        certidoes = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                    }
                }
            }

            var bacen
            var financeiras
            var internas
            var situacao
            var validarSituacao
            // Verifica se tem os dados financeiros
            if(record.dadosFinanceiros){
                //Verfica se tem cheques bacen e quantos possuem
                if(!record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen){
                  bacen = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                }
                else{
                  bacen = <span className='text-white p-2 float-right' style={{borderRadius: '6px', backgroundColor: '#FFC107'}}>{record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen.length}</span>
                }
      
                //Verfica se tem pendências financeiras e quantas possuem
                if(!record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes){
                  financeiras = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                }
                else{
                      var cont = 0
                      for(var i = 0; i < record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes.length; i++){
                          for(var j = 0; j < record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes[i].pendencias.length; j++){
                              cont++
                          }
                      }
                      financeiras = <span className='text-white p-2 float-right' style={{borderRadius: '6px', backgroundColor: '#FFC107'}}>{cont}</span>
                }
      
                //Verfica se tem pendências internas e quantas possuem
                if(!record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas){
                  internas = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                }
                else{
                  internas = <span className='text-white p-2 float-right' style={{borderRadius: '6px', backgroundColor: '#FFC107'}}>{record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas.length}</span>
                }
      
                //Verfica se tem score
                  if(record.dadosFinanceiros.content.score_serasa){
                      if(parseFloat(record.dadosFinanceiros.content.score_serasa.conteudo.percentual) < 25){
                          situacao = <i className='ni ni-fat-remove text-white p-2 float-right' style={{backgroundColor: '#660000', borderRadius: '6px'}} />
                          validarSituacao = 1
                      }
                      else if(parseFloat(record.dadosFinanceiros.content.score_serasa.conteudo.percentual) > 25 && parseFloat(record.dadosFinanceiros.content.score_serasa.conteudo.percentual) < 50){
                          situacao = <i className='fas fa-exclamation text-white p-2 float-right' style={{backgroundColor: '#ffc107', borderRadius: '6px'}} />
                          validarSituacao = 2
                      }
                      else{
                          situacao = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                      }
                  }
      
                  if(validarSituacao === null){
                      if(parseFloat(record.dadosFinanceiros.content.comprometimento_mensal_estimado.conteudo.percentual) < 50){
                          situacao = <i className='ni ni-check-bold bg-success text-white p-2 float-right' style={{borderRadius: '6px'}} />
                      }
                      else{
                          situacao = <i className='fas fa-exclamation text-white p-2 float-right' style={{backgroundColor: '#ffc107', borderRadius: '6px'}} />
                      }
                  }
            }

        return (
            <Col md={this.state.tamanhoCol} key={record.cliente_id} className={'mt-5'} style={{border: '1px solid  #d9d9d9', borderRadius: '6px'}}>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('dadosPessoais'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-id-card pr-3' />
                        Dados pessoais
                    </Col>
                    <Col md={2}>
                        {pessoais}
                    </Col>
                </Row>
                <div className='mt-2' id={'dadosPessoais'+record.cliente_id} style={{width: '100%'}}>
                    {record.dadosCadastrais && [record.dadosCadastrais].map(this.dadosPessoais)}
                </div>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9', borderTop: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('certidoes'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-file-invoice pr-3' />
                        Certidões
                    </Col>
                    <Col md={2}>
                        {certidoes}
                    </Col>
                </Row>
                <div id={'certidoes'+record.cliente_id} style={{width: '100%'}}  >
                    <Row className={'p-3'} >
                        <Col xs={4} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Fonte
                        </Col>
                        <Col xs={2} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Nada consta
                        </Col>
                        <Col xs={2} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Data de emissão
                        </Col>
                        <Col xs={4} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Protocolo
                        </Col>
                    </Row>
                    {record.idwall && record.idwall.result && record.idwall.result.certidoes_negativas && record.idwall.result.certidoes_negativas.itens && record.idwall.result.certidoes_negativas.itens.map(this.certidoes)}
                </div>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9', borderTop: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('processos'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-gavel pr-3' />
                        Processos
                    </Col>
                    <Col md={2}>
                        {processos}
                    </Col>
                </Row>
                <div id={'processos'+record.cliente_id} style={{width: '100%'}}  >
                    {record.idwall && record.idwall.result && record.idwall.result.processos.itens.length !== 0 && record.idwall.result.processos.itens.map(this.processos)}
                    {record.idwall && (!record.idwall.result || (record.idwall.result && record.idwall.result.processos.itens.length === 0))  && <div className='p-3' style={{fontSize: '0.9em', fontStyle: 'italic'}}>Sem processos</div>}
                </div>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9', borderTop: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('protestos'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-money-bill pr-3' />
                        Protestos
                    </Col>
                    <Col md={2}>
                        {protestos}
                    </Col>
                </Row>
                <div id={'protestos'+record.cliente_id} style={{width: '100%'}}  >
                    <Row className={'p-3'} >
                        <Col md={3} style={{fontSize: '0.9em'}}>
                            Não constam
                        </Col>
                        <Col md={9} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            <Row>
                                {record.idwall && record.idwall.result && record.idwall.result.protestos && record.idwall.result.protestos.estados_sem_protestos && record.idwall.result.protestos.estados_sem_protestos.map(this.estadosSem)}
                            </Row>
                        </Col>
                    </Row>
                    <Row className={'p-3'} >
                        <Col md={3} style={{fontSize: '0.9em'}}>
                            Constam protestos
                        </Col>
                        <Col md={9} style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            <Row>
                                {record.idwall && record.idwall.result && record.idwall.result.protestos && record.idwall.result.protestos.estados_com_protestos && record.idwall.result.protestos.estados_com_protestos.map(this.estadosCom)}
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9', borderTop: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('bacen'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-university pr-3' />
                        Bacen
                    </Col>
                    <Col md={2}>
                        {bacen}
                    </Col>
                </Row>
                <div id={'bacen'+record.cliente_id} style={{width: '100%'}}  >
                    {record.dadosFinanceiros && record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen && record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen.map(this.bacen)}
                    {record.dadosFinanceiros && !record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen && <div className='p-3' style={{fontSize: '0.9em', fontStyle: 'italic'}}>Não foram encontrados registros.</div>}
                </div>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9', borderTop: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('pendenciasFin'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-file-invoice-dollar pr-3' />
                        Pendências financeiras
                    </Col>
                    <Col md={2}>
                        {financeiras}
                    </Col>
                </Row>
                <div id={'pendenciasFin'+record.cliente_id} style={{width: '100%'}}  >
                    {record.dadosFinanceiros && record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes && record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes.map(this.detalhesFinanceiros)}
                    {record.dadosFinanceiros && !record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes && <div className='p-3' style={{fontSize: '0.9em', fontStyle: 'italic'}}>Não foram encontradas pendências financeiras.</div>}
                </div>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9', borderTop: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('pendenciasInt'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-folder-open pr-3' />
                        Pendências internas
                    </Col>
                    <Col md={2}>
                        {internas}
                    </Col>
                </Row>
                <div id={'pendenciasInt'+record.cliente_id} style={{width: '100%'}}  >
                    {record.dadosFinanceiros && record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas && record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas.map(this.pendenciasInternas)}
                    {record.dadosFinanceiros && !record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas && <div className='p-3' style={{fontSize: '0.9em', fontStyle: 'italic'}}>Não foram encontradas pendências internas.</div>}
                </div>
                <Row className={'p-3'} style={{cursor: 'pointer', borderBottom: '1px solid #d9d9d9', borderTop: '1px solid #d9d9d9'}} onClick={() => {this.ocultarDiv('situacao'+record.cliente_id)}}>
                    <Col md={10} style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>
                        <i className='fas fa-hand-holding-usd pr-3' />
                        Situação financeira
                    </Col>
                    <Col md={2}>
                        {situacao}
                    </Col>
                </Row>
                <div id={'situacao'+record.cliente_id} style={{width: '100%'}} >
                    {record.dadosFinanceiros && record.dadosFinanceiros.content.score_serasa && [record.dadosFinanceiros.content.score_serasa.conteudo].map(this.score)}
                    {record.dadosFinanceiros && record.dadosFinanceiros.content.comprometimento_mensal_estimado && this.comprometimentoMensal(record.dadosFinanceiros.content.comprometimento_mensal_estimado.conteudo.percentual)}
                    {record.dadosFinanceiros && record.dadosFinanceiros.content.renda_pro && [record.dadosFinanceiros.content.renda_pro.conteudo].map(this.renda)}
                </div>
            </Col>
        )
      }

    // // Quais clientes irão aparecer para upload de arquivos  
    modalConsulta = (evento) => {  
        var element = [] 
        for(var i = 0; i < this.state.retornoConsulta[0].pessoas.length; i++){  
            if(this.state.retornoConsulta[0].pessoas[i].countDoc === 0 || this.state.retornoConsulta[0].pessoas[i].statusDoc === 4 || this.state.retornoConsulta[0].pessoas[i].statusDocMin === 4){
                element = element.concat([{
                    dado: this.state.retornoConsulta[0].pessoas[i].cpf_cnpj,
                    cliente: this.state.retornoConsulta[0].pessoas[i].cliente_id,
                    rendaInformada: this.state.retornoConsulta[0].pessoas[i].renda_informada,
                    valorConsulta: this.state.retornoConsulta[0].valor,
                    consulta: this.state.retornoConsulta[0].consulta_id,
                    imobiliaria: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria,
                    nome:  this.state.retornoConsulta[0].pessoas[i].dadosCadastrais ? this.state.retornoConsulta[0].pessoas[i].dadosCadastrais.content.nome.conteudo.nome : this.state.retornoConsulta[0].pessoas[i].dadosFinanceiros.content.confirmei.conteudo.nome_razao,
                    locatario: this.state.retornoConsulta[0].pessoas[i].tipo_locatario
                }])
            }
        }
    
  
        ReactDOM.render(this.uploadArquivos(element), document.getElementById('arquivos'))
          
        this.toggleModal()
        
    }

    toggleModal = () => {
        this.setState({modal: !this.state.modal})
      }

    uploadArquivos = (element) => {
        return(
            <Modal isOpen={true}>
                <ModalBody>
                    <div style={{textAlign: 'center', fontSize: '1.5em', color: '#ffaf1a'}}>
                        ESTAMOS QUASE LÁ!
                    </div>
                    <div className='mt-2 mb-2' style={{textAlign: 'center', fontSize: '1.1em'}}>
                        <strong>De acordo com as informações passadas, solicitamos anexar os comprovantes de renda. Serão aceitos: </strong>
                    </div>
                    <div style={{fontSize: '0.8em'}}>
                        <Label className='text-danger' style={{textDecoration: 'underline'}}>PARA PESSOA FÍSICA:</Label><br/>
                        EXTRATO BANCÁRIO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 3 MESES</label> ou;<br/>
                        DECLARAÇÃO IRPF <label style={{textDecoration: 'underline'}}>DO ÚLTIMO ANO</label> ou;<br/>
                        HOLERITE DE PAGAMENTO DE SALÁRIO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 3 MESES</label> ou;<br/>
                        EXTRATO DO CARTÃO DE CRÉDITO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 3 MESES</label>.<br/><br/>

                        <Label className='text-danger' style={{textDecoration: 'underline'}}>PARA PESSOA JURÍDICA:</Label><br/>
                        DECLARAÇÃO DE FATURAMENTO <label style={{textDecoration: 'underline'}}>DOS ÚLTIMOS 12 MESES</label>;<br/>
                        (somente com a assinatura do contador)<br/>
                        DIPJ <label style={{textDecoration: 'underline'}}>DO ÚLTIMO ANO</label>.<br/><br/>
                                                                 
                        <Label className='text-danger'>*SOMENTE SERÃO ACEITOS ARQUIVOS DE EXTENSÃO: PDF ou JPG ou JPEG ou PNG</Label><br/><br/>
                    </div>
                    {element.map(this.inputUpload)}
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-dark float-right mt--4' onClick={this.finalizarUpload}>Finalizar</Button>
                </ModalFooter>
            </Modal>
        )
        // return(
        //     <Modal isOpen={true} >
        //         <ModalHeader>ANEXAR ARQUIVOS</ModalHeader>
        //         <ModalBody>
        //             <div style={{fontSize: '0.8em'}}>
        //                 Anexar os 3 últimos comprovantes de renda, serão aceitos:<br/><br/>

        //                 EXTRATO 03 MESES (ÚLTIMOS)<br/>
        //                 Declaração IRPF <br/>
        //                 HOLERITE DE PAGAMENTO <br/>
        //                 DECLARAÇÃO DE FATURAMENTO QUANDO PJ<br/>
        //                 EXTRATO CARTÃO CRÉDITO 03 MESES (ÚLTIMOS)<br/><br/>
                                                                 
        //                 <Label className='text-danger'>*Serão aceitos arquivos em extensão .pdf, .jpg, .jpeg e .png</Label><br/><br/>
        //             </div>
        //             {element.map(this.inputUpload)}
        //         </ModalBody>
        //         <ModalFooter>
        //             <Button className='btn-dark float-right mt--4' onClick={this.finalizarUpload}>Finalizar</Button>
        //         </ModalFooter>
        //     </Modal>
        // )
    }

    finalizarUpload = () => {
        var esperar = false
        for(var i = 0; i < this.state.retornoConsulta[0].pessoas.length; i++){  
            if(this.state.retornoConsulta[0].pessoas[i].countDoc === 0 || this.state.retornoConsulta[0].pessoas[i].statusDoc === 4 || this.state.retornoConsulta[0].pessoas[i].statusDocMin === 4){
                // Caso não houve o upload de arquivos referente a um cliente ele força o click 
                if(this.state.naoClicar.indexOf(this.state.retornoConsulta[0].pessoas[i].cliente_id) === -1){
                    document.getElementById('botaoUpload' + this.state.retornoConsulta[0].pessoas[i].cliente_id).click()
                    esperar = true
                }
            }
        }
        if(esperar){
            this.aguardeUpload().then(() => {
                window.location.reload()
            })
        }
        else{
            window.location.reload()
        }
    }

    aguardeUpload = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                console.log('Esperar')
                resolve();
            }, 3000);
        });
    }

    closeModal = () => {
        ReactDOM.render(<div></div>, document.getElementById('arquivos'))
    }

    clicouUpload = (cliente) => {
        var naoClicar = this.state.naoClicar
        naoClicar.push(cliente)
        this.setState({naoClicar: naoClicar})
    }
  
    // Cria um INPUT FILE para cada cliente que ainda precisa realizar o upload dos arquivos ou novos arquivos
    inputUpload = (record) => { 
        return(
            <div key={record.dado}>
                <Label className='mb-3' style={{fontSize: '1.2em', fontWeight: 'bold'}}>{record.dado}</Label>
                <UploadDoc funcao={() => {this.clicouUpload(record.cliente)}} locatario={record.locatario} nome={record.nome} dado={record.dado} arquivo='Comprovante de renda' cliente={record.cliente} documento='4' rendaInformada={record.rendaInformada} valorConsulta={record.valorConsulta} imobiliaria={record.imobiliaria} consulta={record.consulta}/>
            </div>
        )
    }

    
    modalEnviarCarta = (evento) => {  
        this.enviarCarta()
        // ReactDOM.render(this.modalEmailCarta(), document.getElementById('enviarcarta'))
        
    }

    modalEmailCarta = () => {
        return(
            <Modal isOpen={true}>
                <ModalHeader >Confirmar email</ModalHeader>
                <ModalBody>
                    {/* <div style={{fontSize: '0.8em'}}>
                        Anexar os 3 últimos comprovantes de renda, serão aceitos:<br/><br/>

                        EXTRATO 03 MESES (ÚLTIMOS)<br/>
                        Declaração IRPF <br/>
                        HOLERITE DE PAGAMENTO <br/>
                        DECLARAÇÃO DE FATURAMENTO QUANDO PJ<br/>
                        EXTRATO CARTÃO CRÉDITO 03 MESES (ÚLTIMOS)<br/><br/>
                                                                 
                        <Label className='text-danger'>*Serão aceitos arquivos em extensão .pdf, .jpg, .jpeg e .png</Label><br/><br/>
                    </div> */}
                    <Input type='text' defaultValue={this.state.emailCarta} onChange={this.changeEmailCarta}></Input>
                    <div className='text-center' id='aguardando' style={{display: 'none'}}><img src={require('assets/img/loading.gif')} className='p-5 w-50 h-100' /></div>
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-dark float-right mt--4' onClick={this.enviarCarta} id='bloquearClick'>Enviar carta de crédito</Button>
                </ModalFooter>
            </Modal>
        )
    }

    enviarCarta = () => {
        this.toggleLoad()  
        var numeroRandom = Math.floor(Math.random() * 33)
        var codigo = sha1(this.state.pessoas[0].cliente_id.toString()).substring(numeroRandom, numeroRandom+6).toUpperCase().toString()
        // localStorage.setItem('codigo_carta_test', codigo)
        // this.setState({codigo_carta: codigo})
            api_epar.post( '/cartagarantia', {
                codigo: codigo, 
                renda_informada: parseFloat(this.state.pessoas[0].renda_informada),
                valor_carta: parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta,
                data_emissao: moment().format('YYYY-MM-DD'),
                data_validade: moment().add(15, 'days').format('YYYY-MM-DD'),
                email_envio: this.state.emailCarta,
                clientes_id: this.state.pessoas[0].cliente_id,
                consulta_id: this.props.match.params.id
            }).then((sucesso) => {
                    console.log(sucesso)
                    // Busca detalhe da carta
                    api_epar.get( '/cartagarantiacodigo/' + codigo).then((carta) => {
                        this.setState({cartagarantia: ''})
                        this.toggleLoad() 
                        this.setState({mensagemModal: <div>
                            Carta de crédito gerada com sucesso.
                        </div>})
                        this.toggleAlert()
                        var extensoTexto = extenso(parseFloat((this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta).toString().replace('.', ','), {mode: 'currency'}).toLowerCase()
        
                        ReactDOM.render(<div className="carta-fundo">
                            <div className="carta">
                                <div className="header">
                                    <div className="info">
                                        <p>#CARTA REG</p>
                                        <p>{codigo}</p>
                                    </div>
                                    <img src={require('../assets/img/logo_carta.png')} height="50px" />
                                    <div className="info">
                                        <p>Data emissão</p>
                                        <p>{moment().format('DD/MM/YYYY')}</p>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="garantia">
                                        <h1>Carta de Crédito</h1>
                                        <p>Validade: 15(quinze) dias, após a sua emissão</p>
                                    </div>
                                    <div className="garantia-info">
                                        <p>Garantimos a</p>
                                        {this.state.pessoas[0].dadosCadastrais && this.state.pessoas[0].dadosCadastrais && <p>{this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome}</p>}
                                        {!this.state.pessoas[0].dadosCadastrais && <p>Não encontrado</p>}
                                        <p>CPF {this.state.pessoas[0].cpf_cnpj}</p>
                                        <p>{FuncoesComum.formatarMoeda(parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta)}</p>
                                        <p>({extensoTexto.substring(0,1).toUpperCase().concat(extensoTexto.substring(1))})</p>
                                    </div>
                                    <div className="textos">
                                        <p>Para locar um imóvel, até o limite acima mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS)</p>
                                        <p>Esta carta de crédito vale nas imobiliárias conveniadas a ALUG+, mas pode ser oferecida a demais imobiliárias que obrigatoriamente devem fazer seu cadastro junto a alug+ para fazerem uso dessa carta, dentro do prazo aqui estipulado.</p>
                                    </div>
                                </div>
                                <div className="footerCarta">
                                    <p>Carmem Ribeiro</p>
                                    <p>CEO Alug+</p>
                                </div>
                            </div>
                        </div>, document.getElementById('carta'))
        
                        html2canvas(document.getElementById('carta'), { width: 1500, height: 1200})
                        .then(canvas => {
                            var pdf = new jsPDF({orientation: 'landscape'})
                            const imgData = canvas.toDataURL('image/png');
                            console.log(imgData);
                            pdf.addImage(imgData, 'PNG', 0, -25, 302.5, 270);
                            // pdf.save('download.pdf');
                            api_epar.post( '/sendemailcarta', {
                                to: JSON.parse(localStorage.getItem('dadosUsuario_test')).email,
                                subject: 'ALUG+ CONSULTA APROVADA',
                                html: `<div>
                                    <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                                    Informamos que a consulta ID ${this.props.match.params.id}, foi <label style='padding: 2px; border-radius: 5px, background-color: #1aff1a'>APROVADA</label>.<br/><br/>
                                    Nome do locatário: ${this.state.pessoas[0].dadosCadastrais && this.state.pessoas[0].dadosCadastrais && this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome}. <br/>
                                    CPF: ${this.state.pessoas[0].cpf_cnpj}. <br/>
                                    Valor da garantia: ${FuncoesComum.formatarMoeda(parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta)}.  <br/><br/>

                                    <a href='${window.location.origin}/admin/formcontratocarta/${codigo}'>Clique aqui</a> para gerar o contrato de garantia. <br/><br/>

                                    <label style='text-decoration: underline;'><strong>Importante</strong></label><br/><br/>
                                    O CONTRATO DE LOCAÇÃO a ser realizado com o locatário, deverá conter a cláusula abaixo:<br/><br/>
                                    <i>Rescindida ou encerrada a locação, o(s) LOCATÁRIO(S) se obriga(m) a entregar imediatamente o Imóvel ao(s) LOCADOR(ES), sob pena da propositura da 
                                    competente ação de despejo, além do dever de arcar com as sanções previstas nesta cláusula, além das custas do processo e dos honorários advocatícios, 
                                    desde já fixados em 20% (vinte por cento) atribuído a causa.</i><br/><br/>
                                    <i>Considerando a inexistência das garantias locatícias para esse Contrato, previstas no art. 37, da Lei das Locações, o(s) LOCATÁRIO(S) declara(m)
                                     e concorda(m), desde já, que a ausência ou atraso de pagamento de qualquer parcela do Aluguel ou encargos locatícios, e devidamente comunicado de seu 
                                     inadimplemento, ao(s) LOCADOR(ES) será(ão) conferido(s) de direito de exigir, com amparo no art. 59, §1º, da Lei de Locações, a liminar para desocupação 
                                     em 15 (quinze) dias, independentemente da audiência da parte contrária, e desde que prestada a caução no valor equivalente a 03 (três) alugueis.</i>

                                    </div>`,
                                    attachments: [{path: pdf.output('datauristring')}],
                                    extras: {
                                        cliente: this.state.pessoas[0].id,
                                        consulta: this.props.match.params.id
                                    }
                            }).then((retorno) => {
                                console.log(retorno)
                                // this.setState({cartagarantia: ''})
                                // document.getElementById('aguardando').style.display = 'none';
                                // document.getElementById('bloquearClick').disabled = false;
                                // this.setState({mensagemModal: <div>
                                //                         Carta de garantia enviada com sucesso.
                                //                     </div>})
                                // this.toggleAlert()
                                try{
                                    ReactDOM.render(<div></div>, document.getElementById('carta'))
                                }catch(error){
                                    console.log(error.message)
                                }
                                // var consulta = this.state.consulta_id
                                // new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                                //     this.novaConsulta()
                                //     this.props.history.push('/admin/formcontratocarta/' + codigo)
                                // })
                            })
                            api_epar.post( '/sendemail', {
                                subject: 'ALUG+ CONSULTA APROVADA',
                                html: `<div>
                                    <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                                    Informamos que a consulta abaixo foi aprovada:<br/><br/>
                                    ID da consulta: ${this.props.match.params.id}.<br/>
                                    Empresa (Imobiliária): ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}. <br/>
                                    Nome do locatário: ${this.state.pessoas[0].dadosCadastrais && this.state.pessoas[0].dadosCadastrais && this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome}. <br/>
                                    CPF: ${this.state.pessoas[0].cpf_cnpj}. <br/>
                                    Valor da garantia: ${FuncoesComum.formatarMoeda(parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta)}.  <br/><br/>
                                </div>`,
                                attachments: [{path: pdf.output('datauristring')}],
                                extras: {
                                    consulta: this.props.match.params.id,
                                    cliente: this.state.pessoas[0].id
                                }
                            })

                            api_epar.post( '/sendalugmais', {
                                mensagem: `ALUG+ CONSULTA APROVADA\n\nInformamos que a consulta abaixo foi aprovada:\n\nID da consulta: ${this.props.match.params.id}.\nEmpresa (Imobiliária): ${JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria}. \nNome do locatário: ${this.state.pessoas[0].dadosCadastrais && this.state.pessoas[0].dadosCadastrais && this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome}. \nCPF: ${this.state.pessoas[0].cpf_cnpj}. \nValor da garantia: ${FuncoesComum.formatarMoeda(parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta)}.  \n\n`
                            })
                            // api_epar.post( '/sendemailcarta', {
                            //         to: this.state.emailEnviarCarta,
                            //         subject: 'Carta de crédito',
                            //         // text: 'Era só o segundo teste mesmo',
                            //         html: '<div></div>',
                            //         attachments: [{path: pdf.output('datauristring')}]
                            //         // attachments: [{path: imgData}]
                            // }).then((retorno) => {
                            //     console.log(retorno)
                            //     // document.getElementById('aguardando').style.display = 'none';
                            //     // document.getElementById('bloquearClick').disabled = false;
                            //     // this.setState({mensagemModal: <div>
                            //     //                         Carta de garantia enviada com sucesso.
                            //     //                     </div>})
                            //     // this.toggleAlert()
                            //     // ReactDOM.render(<div></div>, document.getElementById('carta'))
                            //     // var consulta = this.state.consulta_id
                            //     // new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                            //     //     this.novaConsulta()
                            //     //     this.props.history.push('/admin/formcontratocarta/' + codigo)
                            //     // })
                            // })
                            // this.toggleLoad()  
                            // this.props.history.push('/admin/formcontratocarta/' + codigo)
                        });
                        
                        // this.toggleLoad()  
                        // this.props.history.push('/admin/formcontratocarta/' + codigo)
                    }).catch((error) => {
                        console.log(error)
                    })                
                    // this.toggleLoad()  
                    // this.props.history.push('/admin/formcontratocarta/' + codigo)
                })

        // return codigo
    }

    // enviarCarta = () => {
    //     // if(!this.state.emailCarta){
    //     //     this.setState({mensagemModal: <div>
    //     //                                     Preencha o email
    //     //                                 </div>})
    //     //     this.toggleAlert() 
    //     // }
    //     // else{
    //         // document.getElementById('aguardando').style.display = 'block';
    //         // document.getElementById('bloquearClick').disabled = true;
    //         var numeroRandom = Math.floor(Math.random() * 33)
    //         var codigo = sha1(this.state.pessoas[0].cliente_id).substring(numeroRandom, numeroRandom+6).toUpperCase().toString()
    //         api_epar.post( '/cartagarantia', {
    //             codigo: codigo, 
    //             renda_informada: parseFloat(this.state.pessoas[0].renda_informada),
    //             valor_carta: parseFloat(this.state.pessoas[0].renda_informada) * this.state.porcentagemCarta,
    //             data_emissao: moment().format('YYYY-MM-DD'),
    //             data_validade: moment().add(15, 'days').format('YYYY-MM-DD'),
    //             email_envio: this.state.emailCarta,
    //             clientes_id: this.state.pessoas[0].cliente_id,
    //             consulta_id: this.props.match.params.id
    //         }).then((sucesso) => {
    //             console.log(sucesso)
    //             // document.getElementById('aguardando').style.display = 'none';
    //             // document.getElementById('bloquearClick').disabled = false;
    //             this.setState({mensagemModal: <div>
    //                                     Carta de crédito gerada com sucesso.
    //                                 </div>})
    //             this.toggleAlert()
    //             // this.closeModalEmail()
    //             // this.setState({cartagarantia: <Button className={'btn-success float-right m-1'} onClick={() => {this.props.history.push('/admin/cartagarantia')}}>Gerar contrato</Button>})
    //             this.setState({cartagarantia: ''})
    //             ReactDOM.render(<div></div>, document.getElementById('carta'))
    //             new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
    //                 this.props.history.push('/admin/formcontratocarta/' + codigo)
    //             })
    //             // Busca detalhe da carta
    //             // api_epar.get( '/cartagarantiacodigo/' + codigo).then((carta) => {
    //             //     var extensoTexto = extenso(carta.data.data[0].valor_carta.toString().replace('.', ','), {mode: 'currency'}).toLowerCase()

    //             //     ReactDOM.render(<div className="carta-fundo">
    //             //         <div className="carta">
    //             //             <div className="header">
    //             //                 <div className="info">
    //             //                     <p>#CARTA REG</p>
    //             //                     <p>{carta.data.data[0].codigo}</p>
    //             //                 </div>
    //             //                 <img src={require('../assets/img/logo_carta.png')} height="50px" />
    //             //                 <div className="info">
    //             //                     <p>Data emissão</p>
    //             //                     <p>{new Date(carta.data.data[0].data_emissao).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}</p>
    //             //                 </div>
    //             //             </div>
    //             //             <div className="body">
    //             //                 <div className="garantia">
    //             //                     <h1>Carta de Crédito</h1>
    //             //                     <p>Validade: 15(quinze) dias, após a sua emissão</p>
    //             //                 </div>
    //             //                 <div className="garantia-info">
    //             //                     <p>Garantimos a</p>
    //             //                     {carta.data.data[0].retorno_json && JSON.parse(carta.data.data[0].retorno_json) && <p>{JSON.parse(carta.data.data[0].retorno_json).content.nome.conteudo.nome}</p>}
    //             //                     {!carta.data.data[0].retorno_json && <p>Não encontrado</p>}
    //             //                     <p>CPF {carta.data.data[0].cpf_cnpj}</p>
    //             //                     <p>{FuncoesComum.formatarMoeda(carta.data.data[0].valor_carta)}</p>
    //             //                     <p>({extensoTexto.substring(0,1).toUpperCase().concat(extensoTexto.substring(1))})</p>
    //             //                 </div>
    //             //                 <div className="textos">
    //             //                     <p>Para locar um imóvel, até o limite acima mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS)</p>
    //             //                     <p>Esta carta de crédito vale nas imobiliárias conveniadas a ALUG+, mas pode ser oferecida a demais imobiliárias que obrigatoriamente devem fazer seu cadastro junto a alug+ para fazerem uso dessa carta, dentro do prazo aqui estipulado.</p>
    //             //                 </div>
    //             //             </div>
    //             //             <div className="footerCarta">
    //             //                 <p>Carmem Ribeiro</p>
    //             //                 <p>CEO Alug+</p>
    //             //             </div>
    //             //         </div>
    //             //     </div>, document.getElementById('carta'))

    //             //     html2canvas(document.getElementById('carta'))
    //             //     .then(canvas => {
    //             //         var pdf = new jsPDF({orientation: 'landscape'})
    //             //         const imgData = canvas.toDataURL('image/png');
    //             //         // console.log(imgData);
    //             //         pdf.addImage(imgData, 'PNG', 0, 0, 306.5, 185);
    //             //         // pdf.save('download.pdf');
    //             //         api_epar.post( '/sendemailcarta', {
    //             //                 to: this.state.emailCarta,
    //             //                 subject: 'Carta de crédito',
    //             //                 // text: 'Era só o segundo teste mesmo',
    //             //                 html: '<div></div>',
    //             //                 attachments: [{path: pdf.output('datauristring')}]
    //             //                 // attachments: [{path: imgData}]
    //             //         }).then((retorno) => {
    //             //             document.getElementById('aguardando').style.display = 'none';
    //             //             document.getElementById('bloquearClick').disabled = false;
    //             //             this.setState({mensagemModal: <div>
    //             //                                     Carta de crédito enviada com sucesso.
    //             //                                 </div>})
    //             //             this.toggleAlert()
    //             //             this.closeModalEmail()
    //             //             // this.setState({cartagarantia: <Button className={'btn-success float-right m-1'} onClick={() => {this.props.history.push('/admin/cartagarantia')}}>Gerar contrato</Button>})
    //             //             this.setState({cartagarantia: ''})
    //             //             ReactDOM.render(<div></div>, document.getElementById('carta'))
    //             //             new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
    //             //                 this.props.history.push('/admin/formcontratocarta/' + codigo)
    //             //             })
    //             //         })
    //             //     });
                   
    //                 // var pdf = new jsPDF('p','pt','a4')
    //                 // var anexo
    //                 // pdf.fromHTML(
    //                 //     corpoEmailHtml, 50, 50, {
    //                 //       width: 500,
    //                 //     }, () => {
    //                 //         pdf.save('carta_garantia.pdf')
    //                 //         console.log(pdf.output('datauristring'))
    //                 //         api_epar.post( '/sendemailcarta', {
    //                 //             to: this.state.emailCarta,
    //                 //             subject: 'Carta de garantia',
    //                 //             // text: 'Era só o segundo teste mesmo',
    //                 //             html: '<div></div>',
    //                 //             attachments: [{path: pdf.output('datauristring')}]
    //                 //         }).then((retorno) => {
    //                 //             this.setState({mensagemModal: <div>
    //                 //                                     Carta de garantia enviada com sucesso.
    //                 //                                 </div>})
    //                 //             this.toggleAlert()
    //                 //             this.closeModalEmail()
    //                 //             this.setState({cartagarantia: <Button className={'btn-success float-right m-1'} onClick={() => {this.props.history.push('/admin/formcontrato/' + this.props.match.params.id)}}>Gerar contrato</Button>})
    //                 //         })
    //                 //     }

    //                 // )


    //                 // console.log(anexo)
                    
    //             // }).catch((error) => {
    //             //     console.log(error)
    //             // })                
    //         })
            
    //     // }
    // }

    closeModalEmail = () => {
        ReactDOM.render(<div></div>, document.getElementById('enviarcarta'))
    }
  

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        
        
        <Container className={"mt-4 pb-8" } style={{minHeight: '65vh'}}>
            <Row className='d-flex justify-content-center mb-5'>
                <Col md={12}>
                    <Label to='/admin/relatorios' tag={Link}><i className='ni ni-bold-left'/> Relatórios</Label> /
                </Col>
                <Col md={4} className='float-right p-3' style={{fontSize: '1.2em'}}>
                    {this.state.dataConsulta !== '' && new Date(this.state.dataConsulta).toLocaleString('pt-BR', {day: 'numeric', month: 'long', year: 'numeric'})} {this.state.dataConsulta !== '' && ' às ' + new Date(this.state.dataConsulta).toLocaleString('pt-BR', {hour: 'numeric', minute: 'numeric'})}
                </Col>
                <Col md={8} className='float-right'>
                    {(!this.state.button || this.state.renda === 'SemDoc') && this.state.cartagarantia}
                    {this.state.carta !== 'Gerada' && !this.state.renda && this.state.button}
                    <Button className='btn-light float-right m-1' to='/admin/finalidadelocacao' tag={Link}><i className='fas fa-file-alt'/> Nova consulta</Button>
                    <Button className='btn-secondary float-right m-1' onClick={() => {window.print()}}><i className='fas fa-print'/> Imprimir</Button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                {this.state.retornoConsulta.map(this.carregarRetorno)}
            </Row>
            <Row className={this.state.exibir}>
                {this.state.retornoConsulta.map(this.identificarMotivos)}
            </Row>
            <Row className={this.state.exibir}>
                {this.state.pessoas.length > 0 && this.state.pessoas.map(this.carregarDadosLocatarios)}
            </Row>
            <Row className='d-flex justify-content-center text-center' style={{fontSize: '0.9em'}}>
                <div className='mb-4 mt-3'>
                    Este relatório contém informações confidenciais e privilegiadas, sendo seu sigilo protegido por lei.<br/>
                    Seu uso é estritamente limitado e sua distribuição ou divulgação é proibida.<br/>
                </div>

                <div>
                    É importante salientar que tais dados podem não refletir a renda real da pessoa, sendo assim,<br/>
                    uma eventual comprovação de renda extra pode ser um balizador para complementar<br/>
                    e/ou aumentar e/ou melhorar sua capacidade de pagamento.<br/>
                </div>    
            </Row>
            <div id='arquivos'>

            </div>
            <div id='enviarcarta'>

            </div>
        </Container>
        <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
            <ModalHeader toggle={this.toggleAlert}>Alerta</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        {this.state.mensagemModal}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className="btn-dark" onClick={this.toggleAlert}>Cancelar</Button>
            </ModalFooter>
        </Modal>
        <Modal className='d-flex justify-content-center align-items-center' isOpen={this.state.load} >
            <ModalHeader>AGUARDE...</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12} className='d-flex justify-content-center align-items-center'>
                        <img src={require('assets/img/loading.gif')} className='p-5 w-50 h-100'/>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
        <div id='carta' style={{paddingTop: '18vh', width: '100%', height: '50%'}}></div>
      </>
    );
  }
}

export default NewDetalheConsulta;

