/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
import NewConsulta from "views/NewConsulta.jsx";
import MyConsulta from "views/MyConsulta.jsx";
import DetalheConsulta from "views/DetalheConsulta.jsx";
import NewDetalheConsulta from "views/NewDetalheConsulta.jsx";
import Tarifacao from "views/Tarifacao.jsx";
import Profile from "views/examples/Profile.jsx";
import Maps from "views/examples/Maps.jsx";
import Register from "views/examples/Register.jsx";
import Login from "views/examples/Login.jsx";
import Tables from "views/examples/Tables.jsx";
import Icons from "views/examples/Icons.jsx";
import logout from "./logout";
import Contrato from "./views/Contrato";
import Wizard from "./views/Wizard";
import Financeiro from "./views/Financeiro";
import GerarContrato from "./views/GerarContrato";
import EditarContrato from "./views/EditarContrato";
import LayoutEpar from "./views/LayoutEpar";
import PessoaFisica from "./views/PessoaFisica";
import PessoaJuridica from "./views/PessoaJuridica";
import Relatorios from "./views/Relatorios";
import NewTarifacao from "./views/NewTarifacao";
import FuncoesComum from "./funcoes/FuncoesComum";
import LoginAlugMais from "./views/examples/LoginAlugMais";
import FormularioContrato from "./views/FormularioContrato";
import FormularioContratoConsulta from "./views/FormularioContratoConsulta";
import FormularioContratoCarta from "./views/FormularioContratoCarta";
import DownloadContrato from "./views/DowloadContrato";
import ListaUsuarios from "./views/ListaUsuarios";
import AddUsuario from "./views/AddUsuario";
import CredPago from "./views/CredPago";
import Atualizacoes from "./views/Atualizacoes";
import CartaGarantia from "./views/CartaGarantia";
import DetalheCarta from "./views/DetalheCarta";
import DownloadContratoCarta from "./views/DownloadContratoCarta";
import ListarContratos from "./views/ListarContratos";
import BuscarCarta from "./views/BuscarCarta";
import DownloadGarantia from "./views/DownloadGarantia";
import AddSinistro from "./views/AddSinistro";
import ListarSinistroContrato from "./views/ListarSinistroContrato";
import Termo from "./views/Termo";

var colorText = ' text-info '
if(FuncoesComum.layout() !== ' bg-gradient-info '){
  colorText = ' text-danger '
}

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 ",
    component: Index,
    layout: "/admin",
    perfil: "comum"
  },
  {
    path: "/realizarconsulta",
    name: "Consulta",
    icon: "ni fas fa-search ",
    component: LayoutEpar,
    layout: "/admin",
    perfil: "comum"
  },
  {
    path: "/finalidadelocacao",
    name: "Finalidade da locação",
    icon: "ni fas fa-search ",
    component: CredPago,
    layout: "/admin",
    perfil: "comum"
  },
  // {
  //   path: "/wizard",
  //   name: "Wizard",
  //   icon: "ni ni-active-40 ",
  //   component: Wizard,
  //   layout: "/admin",
  //   perfil: "comum"
  // },
  {
    path: "/wizard/:id",
    name: "Consulta",
    component: Wizard,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet ",
    component: Icons,
    layout: "/admin",
    perfil: "comum"
  },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  //   perfil: "comum"
  // },
  {
    path: "/user-profile",
    name: "Meu perfil",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    perfil: "comum"
  },
  {
    path: "/usersimob",
    name: "Listagem de usuários",
    icon: "ni ni-single-02 text-yellow",
    component: ListaUsuarios,
    layout: "/admin",
    perfil: "comum"
  },
  {
    path: "/adduser",
    name: "Adicionar usuário",
    icon: "ni ni-single-02 text-yellow",
    component: AddUsuario,
    layout: "/admin",
    perfil: "comum"
  },
  // {
  //   path: "/tables",
  //   name: "Tabelas",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  //   perfil: "comum"
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/loginalugmais",
    name: "Login",
    component: LoginAlugMais,
    layout: "/alugmais"
  },
  {
    path: "/register",
    name: "Criar novo usuário",
    icon: "ni ni-circle-08 ",
    component: Register,
    layout: "/auth",
    perfil: "master"
  },
  // {
  //   path: "/index",
  //   name: "Usuários",
  //   icon: "ni ni-circle-08 text-blue",
  //   component: Index,
  //   layout: "/admin",
  //   perfil: "master"
  // },
  // {
  //   path: "/newconsulta",
  //   name: "Nova consulta",
  //   icon: "ni fas fa-search text-info",
  //   component: NewConsulta,
  //   layout: "/admin",
  //   perfil: "comum"
  // },
  {
    path: "/myconsulta",
    name: "Minhas consultas",
    icon: "ni ni-badge ",
    component: MyConsulta,
    layout: "/admin",
    perfil: "comum"
  },
  // {
  //   path: "/index",
  //   name: "Relatórios",
  //   icon: "ni ni-chart-pie-35 text-yellow",
  //   component: Index,
  //   layout: "/admin",
  //   perfil: "comum"
  // },
  {
    path: "/tarifacao",
    name: "Tarifação",
    icon: "ni ni-atom ",
    component: Tarifacao,
    layout: "/admin",
    perfil: "master"
  },
  {
    path: "/detalhe/:id",
    name: "Detalhes da Consulta",
    component: DetalheConsulta,
    layout: "/admin"
  },
  {
    path: "/newdetalhe/:id",
    name: "Detalhes da Consulta",
    component: NewDetalheConsulta,
    layout: "/admin"
  },
  {
    path: "/downloadcontrato/:id",
    name: "Download do contrato",
    component: DownloadContrato,
    layout: "/admin"
  },
  {
    path: "/downloadcontratocarta/:id",
    name: "Download do contrato",
    component: DownloadContratoCarta,
    layout: "/admin"
  },
  {
    path: "/contrato",
    name: "Contrato",
    icon: "ni ni-single-copy-04 ",
    component: Contrato,
    layout: "/admin",
    perfil: "master"
  },
  {
    path: "/downloadgarantia/:id",
    name: "Download",
    icon: "ni ni-single-copy-04 ",
    component: DownloadGarantia,
    layout: "/admin"
  },
  {
    path: "/editarcontrato",
    name: "Editar Contrato",
    icon: "ni ni-single-copy-04 ",
    component: EditarContrato,
    layout: "/admin",
    perfil: "master"
  },
  {
    path: "/financeiro",
    name: "Financeiro",
    icon: "ni ni-credit-card ",
    component: Financeiro,
    layout: "/admin",
    perfil: "master"
  },
  // {
  //   path: "/realizarconsulta",
  //   name: "Realizar consulta - Teste",
  //   icon: "ni ni-active-40 ",
  //   component: LayoutEpar,
  //   layout: "/admin",
  //   perfil: "comum"
  // },
  {
    path: "/relatorios",
    name: "Relatórios",
    icon: "ni ni-collection text-danger",
    component: Relatorios,
    layout: "/admin",
    perfil: "master"
  },
  {
    path: "/newtarifacao",
    name: "Tarifações",
    icon: "ni ni-collection text-danger",
    component: NewTarifacao,
    layout: "/admin",
    perfil: "master"
  },
  // {
  //   path: "/formcontrato",
  //   name: "Formulário para o contrato",
  //   icon: "ni ni-collection text-danger",
  //   component: FormularioContrato,
  //   layout: "/admin",
  //   perfil: "master"
  // },
  {
    path: "/formcontrato/:id",
    name: "Formulário para o contrato",
    component: FormularioContrato,
    layout: "/admin"
  },
  {
    path: "/formcontrato",
    name: "Formulário para o contrato",
    component: FormularioContrato,
    layout: "/admin"
  },
  {
    path: "/formcontratoconsulta/:consulta",
    name: "Formulário para o contrato",
    component: FormularioContratoConsulta,
    layout: "/admin"
  },
  {
    path: "/formcontratocarta/:carta",
    name: "Formulário para o contrato",
    component: FormularioContratoCarta,
    layout: "/admin"
  },
  {
    path: "/listarcontratos",
    name: "Contratos da imobiliária",
    component: ListarContratos,
    layout: "/admin"
  },
  {
    path: "/buscarcarta",
    name: "Buscar carta",
    component: BuscarCarta,
    layout: "/admin"
  },
  {
    path: "/addsinistro/:id",
    name: "Criar um novo sinistro",
    component: AddSinistro,
    layout: "/admin"
  },
  {
    path: "/listarsinistros/:id",
    name: "Listar os sinistros de um contrato",
    component: ListarSinistroContrato,
    layout: "/admin"
  },
  {
    path: "/termos",
    name: "Termos para criar contrato",
    component: Termo,
    layout: "/admin"
  },
  // {
  //   path: "/wizard",
  //   name: "Wizard",
  //   icon: "ni ni-active-40 ",
  //   component: Wizard,
  //   layout: "/admin",
  //   perfil: "comum"
  // },
  {
    path: "/pessoafisica",
    name: "Pessoa Física",
    component: PessoaFisica,
    layout: "/admin",
  },
  {
    path: "/pessoajuridica",
    name: "Pessoa Jurídica",
    component: PessoaJuridica,
    layout: "/admin",
  },
  {
    path: "/gerarcontrato/:id",
    name: "Gerar contrato",
    // icon: "ni ni-paper-diploma text-blue",
    component: GerarContrato,
    layout: "/admin",
    // perfil: "comum"
  },
  {
    path: "/sobre",
    name: "Sobre",
    // icon: "ni ni-paper-diploma text-blue",
    component: Atualizacoes,
    layout: "/admin",
    // perfil: "comum"
  },
  {
    path: "/cartagarantia",
    name: "Cartas de Garantia",
    // icon: "ni ni-paper-diploma text-blue",
    component: CartaGarantia,
    layout: "/admin",
    // perfil: "comum"
  },
  {
    path: "/detalhecarta/:codigo",
    name: "Detalhe da cartas de garantia",
    // icon: "ni ni-paper-diploma text-blue",
    component: DetalheCarta,
    layout: "/admin",
    // perfil: "comum"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-fat-remove ",
    component: logout,
    layout: "/admin",
    perfil: "comum"
  }
];
export default routes;
