/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    Input,
    Table,
    Container,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'


class Termo extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            ip: ''
        }
    }

    componentDidMount(){
        axios.get('https://api.ipify.org?format=jsonp&callback=?').then((retorno) => {
            this.setState({ip: JSON.parse(retorno.data.replace(/\(|\)|\?|\;/g, '')).ip})
        })

        // axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((retorno) => {
        //     var log = retorno.data + 'data=' + moment().format('DD/MM/YYYY HH:mm:ss')
        //     console.log(log)
        // })
    }


  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>

        <Container style={{padding: '4% 3%', width: '80vw', background: 'white', fontFamily: 'Calibri Light', color: 'black', fontSize: '1.1em'}}>
            <div style={{width: '100%'}} >
                <img src={require('../assets/img/logo_carta.png')} alt='Alug+' style={{width: '250px', height: '100px'}}/>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'center', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                TERMO DE PARCERIA ALUG+
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                DA GARANTIA PARA LOCAÇÃO
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                A imobiliária parceira ALUG+ poderá a seu critério, a partir desta data, consultar e aprovar cadastros para
                celebração de contratos de locações que serão garantidos pela solução do ALUG+, através de sistema próprio
                com acesso pelos usuários supracitados e em concordância ao termo de uso do mesmo.
            </div>
            
            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                DAS ASSINATURAS ELETRÔNICAS 
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                A imobiliária parceira ALUG+ acima descrita poderá a seu critério, a partir desta data, enviar para as partes
                interessadas na celebração de contrato de locação e/ou contrato de administração e/ou laudo de vistoria, que
                serão garantidos pela solução ALUG+, sem ônus, desde que respeitado o uso exclusivo para tal finalidade. 
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                DO SIGILO DAS INFORMAÇÕES
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                A partir desta data, todo e qualquer documento tratado pelas partes estarão sob sigilo das informações abordadas,
                transacionadas, informadas e enviadas, em conformidade com o convencionado na Lei Geral de Proteção de Dados Pessoais,
                Lei nº 13.709/2018.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                DA PARCERIA
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                Para fins dessa parceria, fica convencionado que todos os contratos garantidos pela solução ALUG+ terão seus valores
                somados no período de 01 (um) a 30 (trinta) de cada mês, sendo gerado apenas uma única cobrança em nome da imobiliária
                parceira, com vencimento todo dia 10 (dez) do mês subsequente. Caso a imobiliária parceira deixe de efetuar o pagamento
                na data prevista, fica ciente que a solução ALUG+ sobre os contratos celebrados, será suspensa até a regularização do
                pagamento. A imobiliária parceira na qualidade de procurador(a)(es) do(a)(s) locador(a)(es), deverá celebrar com o ALUG+
                o contrato de garantia.
            </div>

            <div style={{marginTop: '50px', width: '100%', textAlign: 'center', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                CONTRATO DE GARANTIA PARA LOCAÇÃO DE IMÓVEL
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I. QUADRO RESUMO
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I.1 CONTRATANTE(S)
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (i) Conforme qualificação do(a)(s) LOCADOR(A)(S) especificada no <i>CARTA DE CRÉDITO.</i>
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I.2 CONTRATADA
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (i) <strong>ALUGMAIS CADASTRO IMOBILIÁRIO LTDA</strong>, sociedade inscrita no CNPJ: com sede Alameda Oscar Niemeyer,
                nº 1033, sala 501, CEP: 24006-065, Nova Lima/MG, doravante (“ALUG+”).
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I.3 OBJETO
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (i) Garantia do contrato de locação do imóvel (“Imóvel”) descrito no <i>CARTA DE CRÉDITO</i>, formalizado entre CONTRATANTE(S)
                – locador(es) descrito no mesmo documento, e o(a)(s) LOCATÁRIO(A)(S),  doravante apenas (“Locatários”), denominada adiante
                apenas como (“GARANTIA DA LOCAÇÃO”).
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (ii) A GARANTIA DA LOCAÇÃO se limitará (a) ao pagamento das parcelas do aluguel e encargos, descritas nas alíneas “i” e “ii”
                do item I.4 deste Quadro Resumo, limitada ao número de 12 (doze) vezes o valor do aluguel e encargos contratados, (b) ao
                pagamento da multa rescisória prevista no Contrato de Locação e devida ao(s) CONTRATANTE(S); (c) pelo pagamento de 
                responsabilidade do Locatário necessário à reposição e reparação de danos ao Imóvel ao final do Contrato de Locação, limitados
                à quantia máxima de R$60.000,00 (sessenta mil reais), observadas as disposições previstas neste instrumento, em especial, as
                políticas de exclusão de danos; e (d) o adiantamento ao(s) CONTRATANTE(S) dos valores correspondentes à caução imposta pelo 
                art. 59, §1º, inciso IX da Lei n.º 8.245/91, na quantia de 3 (três) valores locatícios.
            </div>
            
            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I.4 DO ALUGUEL E REAJUSTE
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Aluguel e encargos</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (i) O valor do aluguel propriamente dito, declarado no Contrato de Locação, alcançou a quantia descrita no <i>CARTA DE CRÉDITO.</i> Além 
                do aluguel, integram o valor total na data de assinatura do Contrato de Locação os encargos descritos no <i>CARTA DE CRÉDITO</i>.
                Os valores informados neste item poderão ser corrigidos a partir das decisões tomadas em assembleia condominial e revisões das 
                bases de cálculos e alíquotas dos tributos e taxas formuladas pela legislação municipal.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (ii) Os valores descritos nos itens “i” e “ii” desse tópico serão enviados por meio de boletos bancários ao(s) Locatário(s)
                para pagamento da data de seu vencimento, e para fins deste contrato serão considerados (“Aluguel”), de forma englobada.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Reajuste</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (iii) O valor do aluguel propriamente dito será corrigido anualmente, pelo índice indicado no Contrato de Locação, e na sua
                falta e/ou inaplicabilidade, pelo INPC, de acordo com a variação acumulada de 12 (doze) meses a contar da data da assinatura
                deste contrato.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Alterações no contrato de locação</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (iv) É dever do(s) Locador(es) comunicar previamente, por escrito, quaisquer alterações feitas no Contrato de Locação e não
                as efetuar sem a prévia e expressa anuência do ALUG+. A data de vencimento do aluguel não poderá ser alterada sem comunicação
                prévio ao ALUG+, sob pena de rescisão desse contrato e perda da GARANTIA DA LOCAÇÃO;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Atualização da GARANTIA DE LOCAÇÃO</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (v) As correções nos valores dos encargos contratados inicialmente deverão ser comunicadas à ALUG+ para a atualização da
                GARANTIA DA LOCAÇÃO, caso contrário serão reembolsados, em caso de inadimplência, os valores contratados inicialmente.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I.5. VALOR DE REMUNERAÇÃO PELA GARANTIA DA LOCAÇÃO:
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (vi) Pela contratação da GARANTIA DA LOCAÇÃO o(s) CONTRATANTE(S) pagarão a quantia mensal correspondente a 5% (cinco por
                cento) do valor do Aluguel e encargos que serão pagos diretamente para a ALUG+, que aqui se perfaz no montante informado
                no Quadro 3.1.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (vii) O primeiro pagamento será realizado no dia 10 (dez) do mês imediatamente seguinte ao da assinatura desse contrato,
                e demais, com vencimento programado para os dias 10 (dez) dos meses subsequentes;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Movimentação na fatura</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (viii) O encerramento ou rescisão da GARANTIA DA LOCAÇÃO deverá ser comunicada até o 30º (trigésimo) dias do mês corrente
                para que a ALUG+ não envie ou realize as cobranças no mês seguinte. 
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I.6. PRAZO DA GARANTIA DA LOCAÇÃO
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                (ix) Este instrumento terá o prazo de vigência de 12 (doze) meses contados de sua assinatura, iniciando conforme data 
                no <i>CARTA DE CRÉDITO</i>, com renovação automática, observando-se as disposições contidas na cláusula segunda e terceira desse contrato.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                As Partes acima identificadas firmam o presente instrumento através do aceite a ser realizado Plataforma Alug+.
            </div>

            <div style={{marginTop: '50px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                I. CONDIÇÕES GERAIS DO CONTRATO 
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                Pelo presente instrumento, doravante <strong>(“<label style={{textDecoration: 'underline'}}>Contrato</label>”)</strong> os
                CONTRATANTE(S)/LOCADOR, qualificados no <i>CARTA DE CRÉDITO</i> e identificados individualmente apenas
                como (“<label style={{textDecoration: 'underline'}}>Parte</label>”) ou em conjunto como (“<label style={{textDecoration: 'underline'}}>
                Partes</label>”) e <strong>ALUGMAIS CADASTRO IMOBILIÁRIO LTDA</strong>, sociedade inscrita no CNPJ: com sede Alameda Oscar 
                Niemeyer, nº 1033, sala 501, CEP: 24006-065, Nova Lima/MG, contratam, nos termos da Lei 10.406/2002 (“Código Civil”), a presente
                garantia de locação no âmbito do Contrato de Locação do Imóvel citado no CARTA DE CRÉDITO, cujas cláusulas e condições
                encontram-se adiante dispostas.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                1. CLÁSULA PRIMEIRA. OBJETO.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                1.1 O objeto do presente Contrato é a <strong>GARANTIA DA LOCAÇÃO</strong> do Imóvel citado no <i>Carta de Crédito</i> baseado
                no Contrato de Locação firmado entre <strong>CONTRATANTE(S)</strong> e Locatário, limitando-se <label style={{textDecoration: 'underline'}}><strong>exclusivamente</strong></label> ao:
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Limites da GARANTIA DA LOCAÇÃO</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                a) Inadimplemento por parte do Locatário referente ao pagamento do valor mensal dos aluguéis (alínea “i”, do item I.4 do <i>Quadro Resumo</i>), limitados 
                à 12 (doze) parcelas;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                b) Inadimplemento por parte do Locatário referente as taxas mensais <label style={{textDecoration: 'underline'}}>ordinárias</label> de condomínio, se o Imóvel estiver 
                em regime condominial edilício, limitados à 12 (doze) parcelas do valor contratado; 
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                c) Inadimplemento por parte do Locatário referente ao IPTU (Imposto Predial e Territorial Urbano) incidente sobre
                o Imóvel, limitados à 12 (doze) parcelas do valor contratado; 
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                d) Inadimplemento por parte do Locatário referente aos encargos de água, luz e gás canalizado incidente sobre
                o Imóvel, limitados à 12 (doze) parcelas do valor contratado de cada encargo;  
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                e) Inadimplemento por parte do Locatário referente aos custos referentes à reposição e reparação de danos ao Imóvel
                ao final do Contrato de Locação, limitados à quantia máxima de R$60.000,00 (sessenta mil reais), observadas as disposições
                previstas neste instrumento, em especial, as políticas de exclusão de danos; 
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                f) O adiantamento dos valores correspondentes à caução imposta pelo art. 59, §1º, inciso IX da Lei n.º 8.245/91, na 
                quantia de 3 (três) aluguéis, nos casos de propositura de ação de despejo e pedido liminar para desocupação imediata;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                g) Multa pela rescisão imotivada do Contrato de Locação por parte do(s) Locatário(s), e antes de completar o prazo de
                12 (doze) meses de vigência, no valor  máximo correspondente a 03 (três) vezes o valor da locação proporcionalmente
                ao tempo do Contrato já cumprido, ou o valor previsto no Contrato de Locação, dos dois valores, o menor;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                h) A indenização da <strong>GARANTIA DE LOCAÇÃO</strong> se limitará até a entrega das chaves do imóvel.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Inadimplemento das taxas de condomínio</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                1.2 Entende-se como despesas ordinárias lançadas na taxa de condomínio aqueles que fazem referências às despesas
                básicas correntes, excluídas (a) as despesas extraordinárias, (b) contribuições para composição do fundo de reservas
                do Condomínio, (c) as despesas eventualmente lançadas que decorram de tarifas de usos de espaços comuns 
                (utilização do salão de festas, espaço <i>gourmet</i> etc.) ou qualquer outros serviços <i>pay per use</i> tarifados
                a parte, e (d) as multas ou sanções impostas pelo Condomínio em razão de desvios de condutas
                e infrações às posturas previstas na convenção, praticadas pelo(s) Locatário(s).
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                1.3 A <strong>ALUG+</strong> se obrigará a realizar o pagamento das parcelas inadimplidas pelo Locatário ao(s) <strong>CONTRATANTE(S)</strong> no 
                âmbito do Contrato de Locação, respeitados os limites e exclusões contidos 
                no item 1.1. e 1.2. bem como as disposições previstas nas cláusulas adiante dispostas.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Sub-rogação</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                1.4 Em relação aos pagamentos previstos nas alíneas “a” a “f”, quando efetivamente cumprida pela <strong>GARANTIA DA LOCAÇÃO</strong>, pagos 
                pela <strong>ALUG+</strong> na forma das cláusulas adiantes dispostas, o(s) <strong>CONTRATANTE(S)</strong> automaticamente
                cederão os direitos e obrigações decorrentes das parcelas assumidas pela <strong>ALUG+</strong>, que passará, a partir desses eventos, na 
                condição de sub-rogada, a tomar as medidas extrajudiciais e judiciais para exigir do(s) Locatário(s) os valores por 
                ele(s) inadimplidos, inclusive, multas, juros de mora, correções e demais acessórios que porventura incidam sobre o montante em aberto.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Liminar (art. 59, §1º, IX, da Lei 8.245/91)</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                1.5 Nos casos em que houver a necessidade da propositura da ação de despejo, com o pagamento da caução (art. 59, §1º, IX, da Lei 8.245/91), 
                o adiantamento efetivamente realizado pela <strong>ALUG+</strong> deverá ser levantado e à ela devolvido 
                imediatamente pelo(s) <strong>CONTRATANTE(S)</strong>, sob pena de descumprimento desse Contrato.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                1.6 A <strong>GARANTIA DA LOCAÇÃO</strong> objeto desse Contrato não tem relação alguma com as garantias previstas no artigo 37, da Lei 8.245/91.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                2.CLÁUSULA SEGUNDA. DA VIGÊNCIA, VINCULAÇÃO E RENOVAÇÃO AUTOMÁTICA.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Vigência.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                2.1 O <strong>Contrato</strong> vigorará pelo prazo de 12 (doze) meses, indicado no item I.6 do <i>Quadro Resumo</i>, com início e 
                término nas datas indicadas no <i>CARTA DE CRÉDITO</i>.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Vinculação.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                2.2 O <strong>Contrato</strong> está inteiramente vinculado ao Contrato de Locação, devendo o(s) <strong>CONTRATANTE(S)</strong> não 
                realizar qualquer modificação ou alteração no tocante às cláusulas essenciais e primordiais exigidas 
                pela <strong>ALUG+</strong> – vide cláusula quarta, sob pena de descumprimento desta avença e, principalmente,
                perda da <strong>GARANTIA DA LOCAÇÃO</strong>.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Renovação automática.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                2.3 A renovação deste <strong>Contrato</strong> será automática e por igual período, limitado ao prazo de vigência do Contrato de Locação,
                ainda que transformado por prazo indeterminado, que será confirmada com o pagamento pelo(s) <strong>CONTRATANTE(S)</strong> da <strong>GARANTIA DA LOCAÇÃO</strong> no 
                13º (décimo terceiro) mês contado da data de assinatura do Contrato, <label style={{textDecoration: 'underline'}}>desde que obedecidas as premissas indicadas nos subitens abaixo</label>.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                2.3.1 Informar à <strong>ALUG+</strong> até o 30º dia do 12º (décimo segundo) mês de vigência do <strong>Contrato</strong>, os 
                valores do Aluguel devidamente reajustados pelo índice de Contrato de Locação;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                2.3.2 Qualquer reajuste do Aluguel acima do índice previsto no Contrato de Locação, a <strong>ALUG+</strong> se reserva o
                direito de reanalisar a situação financeira do(s) <strong>CONTRATANTE(S)</strong> para adequação de seu “<i>score</i>”, visando
                a manutenção do equilíbrio financeiro e avaliação de risco da <strong>GARANTIA DA LOCAÇÃO</strong> contratada;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                2.3.3 O(s) <strong>CONTRATANTE(S)</strong> se obrigam a enviar os documentos solicitados pela <strong>ALUG+</strong> com 
                antecedência máxima de 10 (dez) dias da data de reajuste do Contrato de Locação para realização da reanálise mencionada
                no subitem anterior;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                2.3.4 Caso (i) o(s) <strong>CONTRATANTE(S)</strong> não envie(m) a documentação informada no subitem 2.3.1.; ou a (ii) a 
                reanálise mencionada nos subitens anteriores seja indeferida, à critério exclusivo da <strong>ALUG+</strong>; o valor 
                da <strong>GARANTIA DE LOCAÇÃO</strong> não será atualizado, mantendo-se para fins desse <strong>Contrato</strong>, os valores vigentes.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                3.CLÁUSULA TERCEIRA. DA REMUNERAÇÃO PELA GARANTIA DA LOCAÇÃO 
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                3.1 Pela contratação da <strong>GARANTIA DA LOCAÇÃO</strong> o(s) <strong>CONTRATANTE(S)</strong> pagará(ão) a quantia
                mensal correspondente a 5% (cinco por cento) do valor do Aluguel e encargos que serão pagos diretamente para a <strong>ALUG+</strong>. O 
                primeiro pagamento será realizado no dia 10 (dez) do mês imediatamente seguinte ao da assinatura desse contrato,
                e demais, com vencimento programado para os dias 10 (dez) dos meses subsequentes. O encerramento ou rescisão
                da <strong>GARANTIA DA LOCAÇÃO</strong> deverá ser comunicada até o 30º (trigésimo) dias do mês corrente para 
                que a <strong>ALUG+</strong> não envie ou realize as cobranças no mês seguinte. 
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                3.1.1 A <strong>CONTRATANTE</strong> deverá informar à <strong>ALUG+</strong> até o 30º (trigésimo) dia de cada mês qualquer movimentação da fatura.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Pagamento da GARANTIA DA LOCAÇÃO.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                3.2 O pagamento deverá ser realizado, prioritariamente, por meio de boleto bancário a ser enviado pela <strong>ALUG+</strong> ao(s) <strong>CONTRATANTE(S)</strong>, com 
                antecedência mínima de 5 (cinco) dias do vencimento.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                3.2.1 Caso o(s) <strong>CONTRATANTE(S)</strong> não receba(m) o boleto de pagamento com a antecedência mencionada no item
                anterior, deverá(ão), imediatamente, entrar em contato com a <strong>ALUG+</strong> e solicitar o envio do boleto, ou 
                mesmo, para receber orientações sobre a forma de pagamento.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                3.2.2 O eventual não recebimento do boleto, não exclui a obrigação de o(s) <strong>CONTRATANTE(S)</strong> de pagar 
                pontualmente a remuneração pela contratação da <strong>GARANTIA DA LOCAÇÃO</strong>; com exceção nos casos em que
                a <strong>ALUG+</strong> ou não conseguir, a tempo e modo, disponibilizar os meios disponíveis para realização do
                pagamento, oportunidade em que as multas e sanções previstas no item seguinte não serão devidas.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                3.3 O não pagamento da <strong>GARANTIA DA LOCAÇÃO</strong> no dia de seu vencimento implicará na incidência de multa
                moratória de 10% (dez por cento) calculada sobre o valor em atraso, corrigido pelo índice do IGPM/FGV, acrescido de 
                juros de mora de 1% (um por cento) ao mês calculados pro rata dia desde a data do inadimplemento até o efetivo e integral
                pagamento, independentemente de qualquer notificação, interpelação ou aviso judicial ou extrajudicial.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                3.4 O atraso ou não pagamento de qualquer parcela da contratação pela <strong>GARANTIA DA LOCAÇÃO</strong>, suspenderá e 
                exonerará a <strong>ALUG+</strong> das obrigações assumidas nesse Contrato, independentemente de notificação ou aviso
                prévio ao(s) <strong>CONTRATANTE(S)</strong>, durante o período da inadimplência.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                4.CLÁUSULA QUARTA. CLÁUSULAS OBRIGATÓRIAS E CONDIÇÕES EXIGIDAS NO CONTRATO DE LOCAÇÃO
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                4.1 Para que esse Contrato tenha sua eficácia alcançada e a <strong>GARANTIA DA LOCAÇÃO</strong> possa ser plenamente
                exercida pelo(s) <strong>CONTRATANTE(S)</strong>, e caso não utilizada a minuta sugerida pela <strong>ALUG+</strong>, o 
                instrumento de locação deverá conter as seguintes cláusulas e condições mínimas:
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                a) Não poderá, <label style={{textDecoration: 'underline'}}><strong>em hipótese alguma</strong></label>, conter cláusula
                que contenha as garantias previstas no art. 37, da Lei 8.245/91, ou seja, o Contrato de Locação não contemplará garantia alguma;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                b) Em relação à ação de despejo:
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Ação de despejo.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                <i>4.1.1 Rescindida ou encerrada a locação, o(s) LOCATÁRIO(S) se obriga(m) a entregar imediatamente o Imóvel ao(s) 
                LOCADOR(ES), sob pena da propositura da competente ação de despejo, além do dever de arcar com as sanções previstas nesta
                cláusula, além das custas do processo e dos honorários advocatícios, desde já fixados em 20% (vinte por cento) atribuído
                a causa.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                <i>4.1.2 Considerando a inexistência das garantias locatícias para esse Contrato, previstas no art. 37, da Lei das Locações, o(s) 
                LOCATÁRIO(S) declara(m) e concorda(m), desde já, que a ausência ou atraso de pagamento de qualquer parcela do Aluguel ou 
                encargos locatícios, e devidamente comunicado de seu inadimplemento, ao(s) LOCADOR(ES) será(ão) conferido(s) de direito de
                exigir, com amparo no art. 59, §1º, da Lei de Locações, a liminar para desocupação em 15 (quinze) dias, independentemente
                da audiência da parte contrária, e desde que prestada a caução no valor equivalente a 03 (três) alugueis.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                4.2 Ausentes as cláusulas dispostas nas alíneas acima, ou mesmo modificadas posteriormente por meio de instrumento aditivo
                ao Contrato de Locação, fica(m) o(s) <strong>CONTRATANTE(S)</strong> ciente(s) e concordam desde já com a exoneração da
                obrigação de prestação da <strong>GARANTIA DA LOCAÇÃO</strong> objeto de Contrato de Garantia Locatícia, por parte da <strong>ALUG+</strong>.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                5.CLÁUSULA QUINTA. PROCEDIMENTOS PARA O EXERCÍCIO E PAGAMENTO DA GARANTIA DA LOCAÇÃO. 
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.1 O(s) <strong>CONTRATANTE(S)</strong> fará(ão) jus à indenização da <strong>GARANTIA DA LOCAÇÃO</strong> até o dia
                imediatamente anterior à data de vencimento da próxima obrigação mensal do Aluguel, que deverá seguir o seguinte rito:
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                a) Comunicar, imediatamente, a <strong>ALUG+</strong> sobre o inadimplemento do Locatário, dentro do prazo de <strong>03 (três)</strong> dias 
                úteis contados do vencimento da prestação devida, limitado ao prazo máximo de 30 (trinta) dias corridos contados do
                vencimento da prestação devida;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                b) Caso a <strong>ALUG+</strong>, por liberalidade, realize o pagamento da indenização ao(s) <strong>CONTRATANTE(S)</strong> no 
                prazo máximo de 5 (cinco) dias úteis contados do vencimento da obrigação da prestação vencida, o(s) <strong>CONTRATANTE(S)</strong> fará(ão) 
                jus ao recebimento apenas do valor histórico do aluguel, sem a incidência das multas e acessórios;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                c) Caso a <strong>ALUG+</strong> realize o pagamento da indenização ao(s) <strong>CONTRATANTE(S)</strong> no período 
                compreendido entre o 6º (sexto) dia útil contado do vencimento da prestação devida e o dia imediatamente anterior ao
                vencimento da prestação seguinte, o(s) <strong>CONTRATANTE(S)</strong> fará(ão) jus ao recebimento apenas do valor
                histórico do aluguel, acrescido das multas e acessórios;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                d) Caso o(s) <strong>CONTRATANTE(S)</strong> não realizem a comunicação sobre o inadimplemento do Locatário no prazo
                máximo de 30 (trinta) dias corridos contados do vencimento da prestação devida, a <strong>ALUG+</strong> deverá realizar o pagamento da 
                indenização ao(s) <strong>CONTRATANTE(S)</strong> apenas do valor histórico do aluguel, sem a incidência das multas e acessórios da locação;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                e) No caso de o(s) <strong>CONTRATANTE(S)</strong> não realizar(em) nenhum dos eventos descritos nas alíneas acima, ou 
                seja, deixar de comunicar à <strong>ALUG+</strong> o inadimplemento  do Locatário no prazo máximo de 60 (sessenta) dias 
                contados do vencimento da prestação devida, o(s) <strong>CONTRATANTE(S)</strong> declara(m)-se ciente(s) e concorda(m) desde
                já que a <strong>ALUGMAIS</strong> não se obrigará a indenizar qualquer quantia decorrente desse evento;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                f) Inadimplências anteriores à vigência desse Contrato não estão inseridos na <strong>GARANTIA DE LOCAÇÃO</strong>;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.1.1 No tocante às eventuais indenizações referentes às despesas necessárias para a reposição e reparação de danos ao 
                Imóvel ao final do Contrato de Locação, limitados à quantia máxima de R$60.000,00 (sessenta mil reais), deverão ser
                observados os Termos de Vistoria de Entrada e Termo de Vistoria de Saída do Imóvel, obrigatoriamente contemplando relatório
                fotográfico, observando-se, contudo, as disposições previstas na cláusula sétima abaixo, referente às políticas de exclusão
                de danos.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.2 Cumpridos os procedimentos estabelecidos no item 5.1. acima, os pagamentos da <strong>GARANTIA DE LOCAÇÃO</strong> serão pagos
                pela <strong>ALUG+</strong> ao(s) <strong>CONTRATANTE(S)</strong> compreendendo os valores do Aluguel e encargos 
                locatícios brutos, descontados apenas o valor da remuneração devida à <strong>ALUG+</strong> descrita na cláusula terceira.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.3 Caso a <strong>ALUG+</strong>, na condição de sub-rogada dos direitos creditórios do(s) <strong>CONTRATANTE(S)</strong>, opte, a 
                qualquer momento, a propor a ação de despejo por falta de pagamento cumulada com a cobrança das locações vencidas, deverá
                comunicar imediatamente o(s) <strong>CONTRATANTE(S)</strong> dessa decisão, e (a) continuar com o pagamento dos Alugueis 
                e encargos locatícios vencidos, (b) realizar o pagamento da multa contratual rescisória (caso devida), além de (c) antecipar
                o pagamento da caução correspondente a 3 (três) aluguéis necessária para a viabilizar a liminar de desocupação em 15 (quinze)
                dias sem a necessidade de ouvir o Locatário (art. 59, § 1º, IX, da Lei 8.245/91).
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.3.1 A <strong>ALUG+</strong> não antecipará ao(s) <strong>CONTRATANTE(S)</strong> eventuais multas decorrentes de infrações do 
                Contrato de Locação por parte do Locatário.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Assistência jurídica.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.4 A <strong>ALUG+</strong> se responsabilizará pelo custeio dos advogados para o patrocínio das cobranças
                extrajudiciais e ação de despejo cumuladas com cobrança que porventura for proposta contra o Locatário, 
                inclusive com o adiamento de custas processuais eventualmente devidas.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.5 A antecipação da caução a que se refere a alínea “c” do item 5.3, bem como do item 5.4. anterior, será revertida
                para a <strong>ALUG+</strong> nos casos em que o(s) <strong>CONTRATANTE(S)</strong> lograr(em) êxito na ação de despejo
                cumulada com cobrança, acrescidos, inclusive, com eventuais encargos, correções e, principalmente, honorários incidentes.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                5.6 Como condição para o exercício da <strong>GARANTIA DA LOCAÇÃO</strong>, o(s) <strong>CONTRATANTE(S)</strong> se 
                obriga(m) a ceder imediatamente à <strong>ALUG+</strong> os valores por ela pagos, acrescidos de todos os encargos (correção, multa, juros 
                de mora, honorários etc.) além das eventuais multas decorrentes de infrações ao Contrato de Locação, e esta, na condição
                de sub-rogada desses direitos, poderá exigir, em seu próprio nome ou do(s) <strong>CONTRATANTE(S)</strong>, os 
                valores aos quais tiver adiantado ou cujos direitos lhes forem cedidos.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                6.CLÁUSULA SEXTA. OBRIGAÇÕES DO(S) CONTRATANTE(S)
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                6.1 O(s) <strong>CONTRATANTE(S)</strong> compromete(m)-se a (a) notificar a <strong>ALUG+</strong> sobre quaisquer tentativas de acordo 
                intentadas ou negociadas com o(s) Locatário(s), bem como a informá-lo(s) sobre a existência da cessão de créditos, 
                (b) não realizar quaisquer entendimentos ou acordos que recaiam sobre créditos cedidos a <strong>ALUG+</strong>, (c) 
                seguir eventuais orientações da <strong>ALUG+</strong>, principalmente em relação à condução dos casos de inadimplência 
                obrigações do(s) <strong>LOCATÁRIO(S)</strong>, bem como (d) notificar a <strong>ALUG+</strong> prontamente sobre qualquer fato ou 
                circunstância de seu conhecimento que possa afetar a locação ou a exigibilidade das obrigações do(s) Locatário(s), ou, ainda, a 
                execução deste Contrato. A violação destas disposições, em todos os casos, acarretará (a) a revogação 
                da <strong>GARANTIDA DA LOCAÇÃO</strong>, bem como (b) a perda do direito ao recebimento de qualquer adiantamento 
                e/ou (c) na obrigação de devolver os valores já adiantados ou pagos pela <strong>ALUG+</strong>, conforme o caso.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                6.2 O(s) <strong>CONTRATANTE(S)</strong> responderão pelas mesmas sanções previstas no item anterior, caso, em virtude
                de sua ação ou omissão, quaisquer dos créditos cedidos à <strong>ALUG+</strong> tornarem-se inexigíveis em relação ao(s) Locatário(s).
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                7.CLÁUSULA SÉTIMA. MODALIDADES E EXCLUDENTES DAS REPARAÇÕES DE DANOS AO IMÓVEL/AVARIAS.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                7.1 Os eventuais danos causados ao Imóvel pelo(s) Locatário(s) e obrigações por ele assumidas (art. 23, da Lei 8.245/91), ao 
                encerramento ou rescisão do Contrato de Locação somente serão reparados pela <strong>ALUG+</strong> se constatada as seguintes condições:
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                a) Comprovação da existência do dano mediante comparação do Termo de Vistoria de Entrada e Termo de Vistoria de 
                Saída do Imóvel devidamente acompanhados pela <strong>ALUG+</strong>;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                b) Manifestação do(s) <strong>CONTRATANTES</strong> sobre os danos dentro do prazo disponibilizado para análise dos
                Termo de Vistoria de Saída expresso no Contrato de Locação, no prazo máximo de 10 (dez) dias, sob pena de perda da 
                indenização pelos danos ao imóvel;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                c) Execução dos serviços necessários para reparar o dano conduzidos pela <strong>ALUG+</strong> ou prestadores de serviços por ela 
                indicados. Caso o(s) <strong>CONTRATANTE(S)</strong> opte(m) por realizar o serviço por sua 
                conta, a <strong>ALUG+</strong> irá reembolsá-lo(s), pelo preço de custo orçado se o serviço fosse realizado por 
                ela mesma ou por prestadores de serviços parceiros, sempre limitado ao valor de R$60.000,00 (sessenta mil reais);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                d) Liberação do acesso ao Imóvel para a <strong>ALUG+</strong> ou para os prestadores de serviços por ela 
                indicados, em data informada previamente com o(s) <strong>CONTRATANTE(S)</strong>;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                e) Danos que não estejam englobados no item “Exclusões”, abaixo;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                f) Eventuais danos que supere a quantia de R$60.000,00 (sessenta mil reais) deverão ser exigidos diretamente do(s) Locatário(s).
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                g) A restauração/reparo da pintura ocorrerá objetivando deixá-la em condições visuais semelhantes às do início da 
                vigência do Contrato de Locação. Isso não implica na responsabilidade da devolução do imóvel com pintura nova, mas sim com o 
                reparo de falhas no acabamento, furos, avarias, rachaduras, bolhas, entre outros identificados no Termo de Vistoria 
                de Saída do Imóvel e não identificados no Termo de Vistoria de Entrada;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                h) A recuperação ou refazimento da pintura do imóvel só será realizada se no Laudo de Entrada estiver claramente constatada 
                a “Pintura Nova”.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                7.1.1 Somente poderão ser acionadas as avarias citadas no item 7.1, a partir do valor de  R$300,00 (trezentos reais). Conforme 
                previsto no item 7.1, sub item c,  acima deste valor, se faz obrigatório o envio de no mínimo 3(três orçamentos), discriminando
                mão de obra e material, contendo os dados completos do prestador (Nome, CPF/CNPJ, Telefone, endereço, e-mail) para análise.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder', textDecoration: 'underline'}}> 
                <i>Excludentes de indenização.</i>
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                7.2 Serão consideradas excludentes de indenização da <strong>GARANTIA DA LOCAÇÃO</strong> nos casos de reposição e reparação de danos ao Imóvel:
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                a) Problemas que sejam de responsabilidade do(s) <strong>CONTRATANTE(S)</strong>, como os de caráter 
                estrutural (vazamento entre unidades, infiltração, mal funcionamento da rede elétrica, etc.) ou não-estrutural, porém 
                decorrentes de um problema estrutural (como uma parede que necessita acabamento pois apresenta descamação devido a uma
                infiltração, ou eletrodomésticos queimados em virtude de defeito nas instalações elétricas);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                b) Problemas decorrentes de terceiros não relacionados ao Contrato de Locação ou que não guardem relação com o(s) 
                Locatário(s) (como vazamento entre apartamentos que atinja a unidade locada, problemas causados ou de responsabilidade 
                do condomínio, problemas causados por procuradores ou prestadores de serviço contratados 
                pelo(s) <strong>CONTRATANTE(S)</strong> etc.);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                c) Problemas nas redes elétrica, hidráulica ou de gás, cuja manutenção seja de responsabilidade das concessionárias de serviços
                públicos ou, no caso de condomínios, do administrador legal (como vazamentos na prumada de esgoto do edifício);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                d) Pintura de ambientes que estejam com as mesmas condições apresentadas no Termo de Vistoria de Entrada (como paredes que
                se encontravam sem imperfeições no início da locação e continuam assim após a saída do(s) Locatário(s);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                e) Itens que apresentem desgaste natural do uso (como pequenos riscos em pisos, estofados com cores desbotadas, espelhos com 
                fundo oxidado, objetos deteriorados pela ação do clima etc.);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                f) Itens que apresentem pequenos defeitos (como pequenos amassados ou riscos em eletrodomésticos, pequenas lascas em 
                móveis, cortinas com leves desfiados) mas que não estejam com sua funcionalidade comprometida;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                g) Substituição ou restauração do piso de ambientes inteiros, caso existam danos somente em regiões localizadas (por 
                exemplo, se houver uma peça cerâmica trincada, cujo lote não esteja mais a venda no mercado, ela será substituída por 
                uma peça similar. Não serão substituídas todas as peças do ambiente por novas, para que fiquem exatamente iguais);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                h) Utensílios domésticos (como talheres e louças), roupas de cama, mesa ou banho;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                i) Itens decorativos, obras de arte, artigos raros e antiguidades;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                j) Itens com caráter sentimental (como coleções, souvenirs de viagens, artigos de herança);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                k) Danos nas áreas externas de residências (como piscina, caixa d’água, pintura, paisagismo);
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                l) Danos decorrentes de incêndio, queda de raio e explosão, por qualquer natureza, cuja indenização será feita exclusivamente
                pelo Seguro Contra Incêndio do Imóvel, que deverá ser exigido no Contrato de Locação;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                m) Danos decorrentes de caso fortuito ou força maior ou de fatos da natureza (como inundação, vendaval, desmoronamento, tremor 
                de terra, prejuízos causados por roubo ou furto no imóvel ou pela tentativa de roubo ou furto, mesmo que não consumado).
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                n) Custas e despesas com ações extrajudiciais/judiciais/arbitrais de interesse do(s) <strong>CONTRATANTE(S)</strong> e 
                que sejam entendidas pela <strong>ALUG+</strong>, a seu exclusivo critério, como indevidas pelo(s) <strong>LOCATÁRIO(S)</strong>;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                o) Nos casos em que o(s) <strong>CONTRATANTE(S)</strong> autorizar a cessão ou sublocação do Imóvel sem a anuência e concordância
                da <strong>ALUGMAIS</strong>;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                p) Quaisquer deteriorações decorrentes do uso normal do Imóvel, danos causados pela ação paulatina de 
                temperatura, umidade, infiltração e vibração, bem como poluição e contaminação decorrente de qualquer 
                causa, inclusive, áreas internas que estejam expostas à estes riscos;
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                q) Roubos e furtos ocorridos no Imóvel;
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                8. CLÁUSULA OITAVA. PENALIDADES E RESCISÃO.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                8.1 Mantido o inadimplemento referente aos pagamentos devidos pelo(s) <strong>CONTRATANTE(S)</strong> à <strong>ALUG+</strong> por 
                prazo superior a duas prestações, seguidas ou intercaladas, o presente <strong>Contrato</strong> será considerado imediatamente 
                extinto, com a exoneração das obrigações da <strong>ALUG+</strong> no tocante às indenizações decorrentes da <strong>GARANTIA DE LOCAÇÃO</strong>.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                8.2 O(s) <strong>CONTRATANTE(S)</strong> poderão requerer a rescisão deste Contrato a qualquer momento, desde que realizada
                a comunicação prévia de 30 (trinta) dias, oportunidade em que a partir do recebimento da notificação, a <strong>ALUG+</strong> não 
                se responsabilizará mais pela <strong>GARANTIA DA LOCAÇÃO</strong>.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                8.3 Independentemente da rescisão deste Contrato, a <strong>ALUG+</strong> continuará detentora dos direitos 
                creditórios porventura cedidos pelo(s) <strong>CONTRATANTE(S)</strong>, cabendo à aquela o direito de exigir do(s) 
                Locatário(s) a indenização dos valores eventualmente pagos ao(s) <strong>CONTRATANTE(S)</strong>.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                9. CLÁUSULA NONA. DISPOSIÇÕES FINAIS.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                9.1 Caso qualquer das Partes deixe de exigir o cumprimento pontual e integral das obrigações decorrentes deste 
                ajuste, ou deixe de exercer qualquer direito ou faculdade que lhe seja atribuído, tal fato será interpretado como 
                mera tolerância, a título de liberalidade, e não importará em renúncia aos direitos e faculdades não exercidos, nem 
                em precedente, novação ou revogação de qualquer cláusula ou condição do presente contrato.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                9.2 Toda e qualquer modificação, alteração ou aditamento ao presente instrumento somente será válido se feito por 
                instrumento escrito, assinado pelas partes.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                9.3 As partes atribuem ao presente instrumento força de título executivo extrajudicial, podendo ser objeto de execução específica.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                9.4 As Partes desde já autorizam que toda e qualquer autorização ou notificação extrajudicial será feita por e-mail, bem 
                como quaisquer notificações judiciais ou extrajudiciais, incluída citações e intimações. Se esta disposição não for aplicável 
                por força legal ou por decisão judicial superveniente, qualquer notificação poderá ser feita mediante correspondência com 
                aviso de recebimento (AR), enviada para o endereço do Imóvel ou para os endereços cadastrados no indicados no momento do 
                cadastro na plataforma Alug+, sendo que tais citações, intimações ou notificações judiciais serão consideradas validamente 
                entregues quando recebidas por qualquer pessoa que estiver nos referidos endereços.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                9.5 Na hipótese de múltiplos <strong>CONTRATANTE(S)</strong>, para exercer os direitos e dar cumprimento às obrigações
                desse Contrato de Garantia Locatícia, todos declaram-se solidários entre si e constituem-se reciprocamente 
                procuradores, conferindo-se mutuamente poderes especiais para receber citações, notificações e 
                intimações, confessar, desistir, e assinar tudo quanto se tornar necessário, transigir em Juízo ou fora 
                dele, fazer acordos, firmar compromissos judiciais ou extrajudiciais, receber e dar quitação.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                9.6 As partes contratantes obrigam-se a agir com lealdade e boa-fé umas com as outras no cumprimento e execução deste contrato.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                9.7 Este <strong>Contrato</strong> representa o acordo integral das Partes com relação às matérias aqui tratadas, prevalecendo sobre 
                quaisquer documentos ou tratativas anteriores em contrário. 
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                10. CLÁUSULA DÉCIMA. DA CESSÃO DE CRÉDITO E MANDATO PARA ASSUNÇÃO DE OBRIGAÇÕES.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                10.1 Realizado o pagamento de qualquer quantia referente à <strong>GARANTIA DA LOCAÇÃO</strong>, a <strong>ALUG+</strong> se 
                sub-rogará em todos os direitos e obrigações decorrente dos Contrato de Locação no que tocar aos créditos originados 
                do Aluguel, incluindo-se principal, multas e juros de mora.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                10.2 Para formalização da cessão informada no item anterior, o(s) <strong>CONTRANTE(S)</strong> nomeia(m) e 
                constitui(em), em caráter irrevogável, conforme dispõe o artigo 684 do Código Civil, a <strong>ALUG+</strong> como 
                sua procuradora, com o fim especial de representá-lo(s) perante o(s) Locatário(s), Condomínio Edilício sobre o qual 
                o Imóvel pertença, qualquer órgão e repartição pública, empresas concessionárias de serviços públicos, entidades públicas 
                federais, estaduais, municipais e suas autarquias, em tudo que se fizer necessário 
                para a regularização e cobrança dos valores devidos do Aluguel e encargos decorrentes do inadimplemento do(s) 
                Locatário(s), sub-rogados à <strong>ALUG+</strong>, podendo, para tanto, a assinar 
                todos os documentos necessários, constituir advogados, receber, dar quitação, realizar pagamentos e 
                antecipações que fizerem necessários, enfim, todos os atos necessário para o cumprimento dessa cláusula.
            </div>
            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                10.3 Caso preciso for, o(s) <strong>CONTRANTE(S)</strong> se obrigam a outorgar o instrumento particular ou 
                público de mandato à <strong>ALUG+</strong>, à sua ordem, inclusive com os poderes ad negotia e <i>ad judicia</i> para 
                que, em nome do(s) CONTRATANTE(S) inicie a cobrança dos alugueis, encargos locatícios, multa e juros de mora devidos.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                11. CLÁUSULA DÉCIMA PRIMEIRO. FORO.
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'justify'}}> 
                11.1 As Partes elegem o foro da Comarca de Nova Lima, por mais privilegiado que outro venha ser, para dirimir dúvidas 
                ou controvérsias originadas deste Contrato.
            </div>
            <div style={{marginTop: '40px', width: '100%', textAlign: 'justify'}}> 
                <strong>AO ACEITAR O PRESENTE INSTRUMENTO, O USUÁRIO DECLARA EXPRESSAMENTE QUE ESTA CIENTE E CONCORDA COM OS TERMOS E 
                CONDIÇÕES GERAIS DOS SERVIÇOS DE GARANTIA ALUG+.</strong>
            </div>

            <div style={{marginTop: '30px', width: '100%', textAlign: 'left', fontWeight: 'bolder'}}> 
                ASSINATURA DIGITAL
            </div>

            <div style={{marginTop: '40px', width: '100%', textAlign: 'justify'}}> 
                Registro de aceite efetuado para (nome do diretor/administrador/usuário responsável por assinar pela empresa) em {moment().format('DD/MM/YYYY')}, 
                às {moment().format('HH:mm:ss')}.
                {/* às {moment().format('HH:mm:ss')}, utilizando endereço virtual (IP: {this.state.ip}). */}
            </div>

            <div style={{marginTop: '60px', width: '100%', textAlign: 'justify'}}> 
                Assinatura
            </div>

            <div style={{marginTop: '50px', width: '100%', textAlign: 'justify'}}> 
                ALUGMAIS CADASTRO IMOBILIÁRIO LTDA<br/>
                CNPJ: 34.467.250/0001-58
            </div>

            <Row style={{marginTop: '80px', width: '100%', textAlign: 'center', color: '#5f2965'}}> 
                <Col md={4}>
                    <i className='fas fa-mobile-alt' style={{fontSize: '1.3em'}}/>
                    <label className='ml-2' style={{fontSize: '1.2em'}}><strong>31 3517-4838</strong></label>
                </Col>
                <Col md={4}>
                    <i className='fas fa-envelope' style={{fontSize: '1.3em'}}/>
                    <label className='ml-2' style={{fontSize: '1.1em'}}><strong>comercial@alugmais.com</strong></label>
                </Col>
                <Col md={4}>
                    <i className='fas fa-globe' style={{fontSize: '1.3em'}}/>
                    <label className='ml-2' style={{fontSize: '1.2em'}}><strong>alugmais.com</strong></label>
                </Col>
            </Row>
            <Row style={{marginTop: '5px', width: '100%', textAlign: 'center', color: '#5f2965'}}> 
                <Col md={12}>
                    <i className='fas fa-map-marker-alt' style={{fontSize: '1.3em'}}/>
                    <label className='ml-2' style={{fontSize: '1.1em'}}><strong>Alameda Oscar Niemeyer, 1033 - Sala 501 - Vila da Serra, Nova Lima - MG, 34000-000</strong></label>
                </Col>
            </Row>



        </Container>
      </>
    );
  }
}

export default Termo;
