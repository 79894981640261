'use script'

import axios from 'axios'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'

const FuncoesProcob = () => {}

// Inserir registro referente a procob
FuncoesProcob.inserirProcobConsulta = async (consulta, dado) => {
    return api_epar.post( '/procobconsulta', {
        code: consulta.code,
        date: consulta.date,
        hour: consulta.hour,
        message: consulta.message,
        revision: consulta.revision,
        saldo: consulta.saldo,
        server: consulta.server,
        retorno: JSON.stringify(consulta),
        usuarios_id: consulta.usuario,
        clientes_id: consulta.cliente_id,
    }).then((retornoConsulta) => {
        FuncoesProcob.inserirProcobAdvertencias(consulta, retornoConsulta.data.data)
        FuncoesProcob.inserirProcobBacen(consulta, retornoConsulta.data.data)
        FuncoesProcob.inserirProcobConfirmei(consulta, retornoConsulta.data.data)
        FuncoesProcob.inserirProcobPendenciasInternas(consulta, retornoConsulta.data.data)
        FuncoesProcob.inserirProcobPendenciasFinanceiras(consulta, retornoConsulta.data.data)
        if(consulta.content.score_serasa){
            FuncoesProcob.inserirProcobScore(consulta, retornoConsulta.data.data)
        }
        if(dado.length > 14){
            FuncoesProcob.inserirProcobAdministradores(consulta, retornoConsulta.data.data)
            FuncoesProcob.inserirProcobSocios(consulta, retornoConsulta.data.data)
        }
        else{
            FuncoesProcob.inserirProcobParticipacoes(consulta, retornoConsulta.data.data)
        }
        return retornoConsulta
    }).catch((error) => {
        console.log(error)
    })
}

FuncoesProcob.inserirProcobPendenciasInternas = (consulta, consulta_id) => {
    if(consulta.content.pendencias_internas.conteudo.pendencias_internas){
        for(var i = 0; i < consulta.content.pendencias_internas.conteudo.pendencias_internas.length; i++){
            api_epar.post( '/procobpendenciasinternas', {
                avalista: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].avalista,
                contrato: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].contrato,
                data_ocorrencia: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].data_ocorrencia,
                modalidade: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].modalidade,
                origem: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].origem,
                sigla_embratel: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].sigla_embratel,
                tipo_moeda: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].tipo_moeda,
                valor: consulta.content.pendencias_internas.conteudo.pendencias_internas[i].valor,
                consulta_id: consulta_id
            }).then((retornoInternas) => {
                console.log(retornoInternas)
            }).catch((error) => {
                console.log('Pendencias internas ERROR -> ' + error.message)
            })
        }
    }
}

FuncoesProcob.inserirProcobPendenciasFinanceiras = (consulta, consulta_id) => {
    if(consulta.content.pendencias_financeiras.conteudo.detalhes){
        for(var c = 0; c < consulta.content.pendencias_financeiras.conteudo.detalhes.length; c++){
            for(var i = 0; i < consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias.length; i++){
                api_epar.post( '/procobpendenciasfinanceiras', {
                    avalista: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].avalista,
                    contrato: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].contrato,
                    data_ocorrencia: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].data_ocorrencia,
                    mensagem_subjudice: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].mensagem_subjudice,
                    modalidade: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].modalidade,
                    origem: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].origem,
                    sigla_embratel: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].sigla_embratel,
                    subjudice: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].subjudice,
                    tipo_anotacao: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].tipo_anotacao,
                    tipo_moeda: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].tipo_moeda,
                    valor: consulta.content.pendencias_financeiras.conteudo.detalhes[c].pendencias[i].valor,
                    consulta_id: consulta_id
                }).then((retornoFinanceiras) => {
                    console.log(retornoFinanceiras)
                }).catch((error) => {
                    console.log('Pendencias financeiras ERROR -> ' + error.message)
                })
            }
        }
    }
}

FuncoesProcob.inserirProcobConfirmei = (consulta, consulta_id) => {
    api_epar.post( '/procobconfirmei', {
        data_nascimento_fundacao: consulta.content.confirmei.conteudo.data_nascimento_fundacao,
        data_situacao_documento: consulta.content.confirmei.conteudo.data_situacao_documento,
        nome_mae: consulta.content.confirmei.conteudo.nome_mae,
        nome_razao: consulta.content.confirmei.conteudo.nome_razao,
        situacao_documento: consulta.content.confirmei.conteudo.situacao_documento,
        consulta_id: consulta_id
    }).then((retornoConfirmei) => {
        console.log(retornoConfirmei)
    }).catch((error) => {
        console.log('Confirmei ERROR -> ' + error.message)
    })
}

FuncoesProcob.inserirProcobParticipacoes = (consulta, consulta_id) => {
    if(consulta.content.participacao_societaria.conteudo.participacoes){
        for(var i = 0; i < consulta.content.participacao_societaria.conteudo.participacoes.length; i++){
            api_epar.post( '/procobparticipacoes', {
                cnpj_empresa: consulta.content.participacao_societaria.conteudo.participacoes[i].cnpj_empresa,
                data_atualizacao: consulta.content.participacao_societaria.conteudo.participacoes[i].data_atualizacao,
                data_inicio_participacao: consulta.content.participacao_societaria.conteudo.participacoes[i].data_inicio_participacao,
                nome_empresa: consulta.content.participacao_societaria.conteudo.participacoes[i].nome_empresa,
                percentual_participacao: consulta.content.participacao_societaria.conteudo.participacoes[i].percentual_participacao,
                uf: consulta.content.participacao_societaria.conteudo.participacoes[i].uf,
                consulta_id: consulta_id
            }).then((retornoParticipacoes) => {
                console.log(retornoParticipacoes)
            }).catch((error) => {
                console.log('Participações ERROR -> ' + error.message)
            })
        }
    }
}

FuncoesProcob.inserirProcobAdvertencias = (consulta, consulta_id) => {
    api_epar.post( '/procobadvertencias', {
        bacen: consulta.content.advertencias.bacen,
        documentos_roubados: consulta.content.advertencias.documentos_roubados,
        pendencias_financeiras: consulta.content.advertencias.pendencias_financeiras,
        pendencias_internas: consulta.content.advertencias.pendencias_internas,
        protesto_estadual: consulta.content.advertencias.protesto_estadual,
        consulta_id: consulta_id
    }).then((retornoAdvertencias) => {
        console.log(retornoAdvertencias)
    }).catch((error) => {
        console.log('Advertencias ERROR -> ' + error.message)
    }) 
}

FuncoesProcob.inserirProcobBacen = (consulta, consulta_id) => {
    if(consulta.content.bacen.conteudo.cheques_bacen){
        for(var i = 0; i < consulta.content.bacen.conteudo.cheques_bacen.length; i++){
            api_epar.post( '/procobchequesbacen', {
                agencia: consulta.content.bacen.conteudo.cheques_bacen[i].agencia,
                alinea_cheque: consulta.content.bacen.conteudo.cheques_bacen[i].alinea_cheque,
                cidade: consulta.content.bacen.conteudo.cheques_bacen[i].cidade,
                data_ocorrencia: consulta.content.bacen.conteudo.cheques_bacen[i].data_ocorrencia,
                nome_banco: consulta.content.bacen.conteudo.cheques_bacen[i].nome_banco,
                numero_banco: consulta.content.bacen.conteudo.cheques_bacen[i].numero_banco,
                numero_cheque: consulta.content.bacen.conteudo.cheques_bacen[i].numero_cheque,
                quantidade_ccf_banco: consulta.content.bacen.conteudo.cheques_bacen[i].quantidade_ccf_banco,
                uf: consulta.content.bacen.conteudo.cheques_bacen[i].uf,
                valor: consulta.content.bacen.conteudo.cheques_bacen[i].valor,
                consulta_id: consulta_id
            }).then((retornoBacen) => {
                console.log(retornoBacen)
            }).catch((error) => {
                console.log('Bacen ERROR -> ' + error.message)
            })
        }
    }
}

FuncoesProcob.inserirProcobAdministradores = (consulta, consulta_id) => {
    if(consulta.content.socios_administradores.conteudo && consulta.content.socios_administradores.conteudo.administradores){
        for(var i = 0; i < consulta.content.socios_administradores.conteudo.administradores.length; i++){
            api_epar.post( '/procobadministradores', {
                cargo: consulta.content.socios_administradores.conteudo.administradores[i].cargo,
                documento_socio: consulta.content.socios_administradores.conteudo.administradores[i].documento_socio,
                nome_socio: consulta.content.socios_administradores.conteudo.administradores[i].nome_socio,
                possui_restricao_financeira: consulta.content.socios_administradores.conteudo.administradores[i].possui_restricao_financeira,
                tipo_pessoa: consulta.content.socios_administradores.conteudo.administradores[i].tipo_pessoa,
                consulta_id: consulta_id
            }).then((retornoAdministradores) => {
                console.log(retornoAdministradores)
            }).catch((error) => {
                console.log('Administradores ERROR -> ' + error.message)
            })
        }
    }
}

FuncoesProcob.inserirProcobSocios = (consulta, consulta_id) => {
    if(consulta.content.socios_administradores.conteudo && consulta.content.socios_administradores.conteudo.socios){
        for(var i = 0; i < consulta.content.socios_administradores.conteudo.socios.length; i++){
            api_epar.post( '/procobsocios', {
                documento_socio: consulta.content.socios_administradores.conteudo.socios[i].documento_socio,
                nome_socio: consulta.content.socios_administradores.conteudo.socios[i].nome_socio,
                percentual: consulta.content.socios_administradores.conteudo.socios[i].percentual,
                possui_restricao_financeira: consulta.content.socios_administradores.conteudo.socios[i].possui_restricao_financeira,
                tipo_pessoa: consulta.content.socios_administradores.conteudo.socios[i].tipo_pessoa,
                consulta_id: consulta_id
            }).then((retornoSocios) => {
                console.log(retornoSocios)
            }).catch((error) => {
                console.log('Sócios ERROR -> ' + error.message)
            })
        }
    }
}

FuncoesProcob.inserirProcobScore = (consulta, consulta_id) => {
    if(consulta.content.score_serasa && consulta.content.score_serasa.conteudo && consulta.content.score_serasa.conteudo.percentual){
        api_epar.post( '/procobscore', {
            score: consulta.content.score_serasa.conteudo.score,
            percentual: consulta.content.score_serasa.conteudo.percentual,
            mensagem: consulta.content.score_serasa.conteudo.mensagem,
            procob_consulta_id: consulta_id
        }).then((retornoScore) => {
            console.log(retornoScore)
        }).catch((error) => {
            console.log('Score ERROR -> ' + error.message)
        })
    }
}

export default FuncoesProcob 