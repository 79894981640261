/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Nav, 
    NavItem, 
    NavLink,
    TabPane,
    TabContent,
    Label,
    FormGroup
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum';
import InputMask from 'react-input-mask'
import $ from 'jquery'
import DataTable from 'datatables.net'
import "assets/scss/relatorios.scss";
import {Link} from 'react-router-dom'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from "react-ga";

import extenso from 'extenso'

class BuscarCarta extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           carta: []
        }
    }

   

    componentDidMount(){

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // Verifica se o usuário tem acesso
      axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
        console.log(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }))

        if(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }).length === 0){
          this.props.history.push('/admin/logout')
        }
      })

    }

    carregarDetalhe = (record) => {
      var extensoTexto = extenso(record.valor_carta.toString().replace('.', ','), {mode: 'currency'}).toLowerCase()
      console.log(extensoTexto)
      return (
        <div className="carta-fundo" key={record.codigo}>
          <div className="carta">
            <div className="header">
                <div className="info">
                    <p>#CARTA REG</p>
                    <p>{record.codigo}</p>
                </div>
                <img src={require('../assets/img/logo_carta.png')} height="50px" />
                <div className="info">
                    <p>Data emissão</p>
                    <p>{new Date(record.data_emissao).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}</p>
                </div>
            </div>
            <div className="body">
                <div className="garantia">
                    <h1>Carta de Crédito</h1>
                    <p>Validade: 15(quinze) dias, após a sua emissão</p>
                </div>
                <div className="garantia-info">
                    <p>Garantimos a</p>
                    {record.retorno_json && JSON.parse(record.retorno_json) && <p>{JSON.parse(record.retorno_json).content.nome.conteudo.nome}</p>}
                    {!record.retorno_json && <p>Não encontrado</p>}
                    <p>CPF {record.cpf_cnpj}</p>
                    <p>{FuncoesComum.formatarMoeda(record.valor_carta)}</p>
                    <p>({extensoTexto.substring(0,1).toUpperCase().concat(extensoTexto.substring(1))})</p>
                </div>
                <div className="textos">
                    <p>Para locar um imóvel, até o limite acima mencionado, incluindo além do aluguel os encargos(IPTU, CONDOMÍNIO, LUZ, ÁGUA, GÁS)</p>
                    <p>Esta carta de crédito vale nas imobiliárias conveniadas a ALUG+, mas pode ser oferecida a demais imobiliárias que obrigatoriamente devem fazer seu cadastro junto a alug+ para fazerem uso dessa carta, dentro do prazo aqui estipulado.</p>
                </div>
            </div>
            <div className="footerCarta">
                <p>Carmem Ribeiro</p>
                <p>CEO Alug+</p>
            </div>
          </div>
        </div>
      )
    }

    buscarCarta = () => {
        var codigo = document.getElementsByName('codigo_carta')[0].value
        var cpf = document.getElementsByName('cpf_codigo')[0].value

        api_epar.get( '/cartagarantiacodigo/' + codigo ).then((existe) => {
            if(existe.data.data.length > 0){
                api_epar.get( '/cartagarantiacodigocpfbatem/' + codigo + '/' + cpf.replace(/\D/g, '')).then((batem) => {
                    if(batem.data.data.length > 0){
                        api_epar.get( '/cartagarantiacodigocpfprazo/' + codigo + '/' + cpf.replace(/\D/g, '')).then((prazo) => {
                            if(prazo.data.data.length > 0){
                                api_epar.get( '/cartagarantiacodigocpf/' + codigo + '/' + cpf.replace(/\D/g, '')).then((carta) => {
                                    if(carta.data.data.length > 0 ){
                                        console.log(carta.data.data)
                                        this.setState({carta: carta.data.data})
                                        this.limparBuscaCarta()
                                    }
                                    else{
                                        console.log(carta.data.data)
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                })
                            }
                            else{
                                this.setState({mensagemModal: <div>
                                    O prazo de validade para a carta "{codigo}" foi excedido.
                                    </div>})
                                this.toggleAlert()
                            }
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                    else{
                        this.setState({mensagemModal: <div>
                            Código e CPF não possuem um vínculo. Tente novamente.
                            </div>})
                        this.toggleAlert()
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
            else{
                this.setState({mensagemModal: <div>
                    Carta com o código {codigo} não encontrada.
                    </div>})
                this.toggleAlert()
            }
        }).catch((error) => {
            console.log(error)
        })
        

    }

    limparBuscaCarta = () => {
        document.getElementsByName('codigo_carta')[0].value = ''
        document.getElementsByName('cpf_codigo')[0].value = ''
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }



  render() {
    return (
      <>
        <Header style={{height: '10vh'}} />

        <div className="form-row m-5" >
            <div className="form-group col-md-3">
                <input type="text" name='codigo_carta' className="form-control" placeholder="Código da carta" />
            </div>
            <div className="form-group col-md-5">
                <input type="text" name='cpf_codigo' className="form-control" placeholder="CPF para validar existência do código"/>
            </div>
            <div className="form-group col-md-4">
                <Button className='btn-success float-right' onClick={this.buscarCarta}>Buscar carta</Button>
            </div>
        </div>
        <Container className={"mt-5 mb-8 d-flex justify-content-center"} fluid style={{minHeight: '65vh'}}>
            {this.state.carta.map(this.carregarDetalhe)}
        </Container>
      </>
    );
  }
}

export default BuscarCarta;
