/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import FuncoesComum from '../../funcoes/FuncoesComum'

class AdminNavbar extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      nome: JSON.parse(localStorage.getItem('dadosUsuario_test')).nome,
      itensMaster: <div>
                      <DropdownItem to="/auth/register" tag={Link}>
                        <i className="fas fa-user-plus" />
                        <span>Criar usuário</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      {/* <DropdownItem to="/admin/tarifacao" tag={Link}>
                        <i className="fas fa-list-alt" />
                        <span>Tarifação</span>
                      </DropdownItem>
                      <DropdownItem divider /> */}
                      <DropdownItem to="/admin/newtarifacao" tag={Link}>
                        <i className="fas fa-list-alt" />
                        <span>Tarifação</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem to="/admin/contrato" tag={Link}>
                        <i className="fas fa-file-medical" />
                        <span>Criar contrato</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem to="/admin/editarcontrato" tag={Link}>
                        <i className="fas fa-file-signature" />
                        <span>Editar contrato</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem to="/admin/financeiro" tag={Link}>
                        <i className="fas fa-money-check" />
                        <span>Financeiro</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      
                    </div>,
      link: 'logo-econsult.png',
    }
    
  }

  componentDidMount(){
    if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
      this.setState({itensMaster: <div></div>})
    }
    if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
      this.setState({link: 'logo-alugmais.png'})
    }
    else{
      this.setState({link: 'logo-econsult.png'})
    }
  }

  render() {
    return (
      <>
        <Navbar className={"navbar-top navbar-dark" + FuncoesComum.layout()} expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase  "
              to="/"
            >
              {/* {this.props.brandText} */}
              <img
                alt="Logo"
                className="navbar-brand-img p-3"
                src={require("assets/img/" + this.state.link)}
                style={{maxHeight: '80px'}}
              />
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-md-flex" navbar>
              <Media className="ml-2 d-none d-lg-block text-white" style={{fontSize: '1em'}}>
                Bem-vindo(a)
                <span className="font-weight-bold">
                  {', ' + this.state.nome}
                </span>
              </Media>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    {/* <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/team-1-800x800.jpg")}
                      />
                    </span> */}
                    <span className="navbar-toggler-icon pl-5" style={{fontSize: '1.3em'}}></span>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {/* <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bem vindo!</h6>
                  </DropdownItem> */}
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Meu perfil</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  {/* <DropdownItem to="/auth/register" tag={Link}>
                    <i className="ni ni-fat-add" />
                    <span>Criar usuário</span>
                  </DropdownItem>
                  <DropdownItem divider /> */}
                  <DropdownItem to="/admin/finalidadelocacao" tag={Link}>
                    <i className="fas fa-search" />
                    <span>Consulta</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  {/* <DropdownItem to="/admin/myconsulta" tag={Link}>
                    <i className="fas fa-list-ol" />
                    <span>Minhas consultas</span>
                  </DropdownItem>
                  <DropdownItem divider /> */}
                  <DropdownItem to="/admin/relatorios" tag={Link}>
                    <i className="fas fa-file-alt" />
                    <span>Relatórios</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem to="/admin/cartagarantia" tag={Link}>
                    <i className="fas fa-envelope-open-text " />
                    <span>Cartas de garantia</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem to="/admin/listarcontratos" tag={Link}>
                    <i className="fas fa-file-contract " />
                    <span>Contratos</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem to="/admin/buscarcarta" tag={Link}>
                    <i className="fas fa-envelope " />
                    <span>Buscar carta</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  {this.state.itensMaster}
                  <DropdownItem to="/admin/sobre" tag={Link}>
                    <i className="fas fa-info ml-2" />
                    <span>Sobre</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem to="/admin/logout" tag={Link}>
                    <i className="fas fa-sign-out-alt" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
