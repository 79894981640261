import React from "react";
import Chart from "chart.js";
import {
    Container,
    Label,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter,
    Row,
    Col,
    Button
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import FuncoesComum from '../funcoes/FuncoesComum';
import $ from 'jquery'
import "assets/scss/relatorios.scss";
import {Link} from 'react-router-dom'
// import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import {api_epar} from '../services/apisaxios'
import ReactGA from "react-ga";

class Relatorios extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           imob: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria,
           consultas: [],
           semComprovante: 0,
           porcentagemCarta: 0,
           anexarComprovantes: 0,
           porcentagemScore: '',
           alert: false,
           enviarCarta: '',
           dadosContratoUnico: {},
        }
    }

    componentDidMount(){

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // Verifica se o usuário tem acesso
      axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
        console.log(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }))

        if(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }).length === 0  || retorno.data.moba.filter((i) => {
          return i.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id && i.bloqueado === 0
        }).length > 0){
          this.props.history.push('/admin/logout')
        }
      })
      axios.post(api_admin + '/imobiliaria/imobuser/permis', {id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id}).then((retorno) => {
        if(retorno.data.per.filter((e) => {
            return e.sistema_id === 2 && e.permitido === 0
        }).length > 0 ){
            this.props.history.push('/admin/logout')
        }
      })

      axios.get(api_admin + '/config/configsimob/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((config) => {
        var semComprovante 
        var porcentagemCarta
        var porcentagemScore
        var enviarCarta
        var anexarComprovantes
        
        console.log(config.data.data)

        if(config.data.data.length > 0){
            var alugmais = config.data.data.filter((alug) => {
                return alug.sistemas_id === 2
            })
            
            if(alugmais.length > 0){
                for(var i = 0; i < alugmais.length; i++){
                    if(alugmais[i].configuracoes_id === 1){
                        enviarCarta = alugmais[i].valor
                    }
                    if(alugmais[i].configuracoes_id === 2){
                        porcentagemCarta = parseFloat(alugmais[i].valor)
                    }
                    if(alugmais[i].configuracoes_id === 3){
                        semComprovante = parseFloat(alugmais[i].valor)
                    }
                    if(alugmais[i].configuracoes_id === 4){
                        porcentagemScore = (parseFloat(alugmais[i].valor) * 100)
                    }
                    if(alugmais[i].id === 11){
                      anexarComprovantes = parseFloat(alugmais[i].padrao)
                  }
                }
            }
            this.setState({enviarCarta: enviarCarta, semComprovante: parseFloat(semComprovante), porcentagemCarta: parseFloat(porcentagemCarta), porcentagemScore: parseFloat(porcentagemScore), anexarComprovantes: parseFloat(anexarComprovantes)})
            
            if(!porcentagemCarta){
                axios.get(api_admin + '/config/configs').then((configuracoes) => {
                    alugmais = configuracoes.data.data.filter((alug) => {
                        return alug.sistemas_id === 2
                    })

                    if(alugmais.length > 0){
                        for(var i = 0; i < alugmais.length; i++){
                            if(alugmais[i].id === 2){
                                porcentagemCarta = parseFloat(alugmais[i].padrao)
                            }
                        }
                        this.setState({porcentagemCarta: parseFloat(porcentagemCarta)})
                        console.log(porcentagemCarta)
                    } 
                }).catch((error) => {
                    console.log(error)
                })
            }
            if(!semComprovante){
                axios.get(api_admin + '/config/configs').then((configuracoes) => {
                    alugmais = configuracoes.data.data.filter((alug) => {
                        return alug.sistemas_id === 2
                    })

                    if(alugmais.length > 0){
                        for(var i = 0; i < alugmais.length; i++){
                            if(alugmais[i].id === 3){
                                semComprovante = parseFloat(alugmais[i].padrao)
                            }
                        }
                        this.setState({semComprovante: parseFloat(semComprovante)})
                        console.log(semComprovante)
                    } 
                }).catch((error) => {
                    console.log(error)
                })
            }
            if(!enviarCarta){
                axios.get(api_admin + '/config/configs').then((configuracoes) => {
                    alugmais = configuracoes.data.data.filter((alug) => {
                        return alug.sistemas_id === 2
                    })

                    if(alugmais.length > 0){
                        for(var i = 0; i < alugmais.length; i++){
                            if(alugmais[i].id === 1){
                                enviarCarta = alugmais[i].padrao
                            }
                        }
                        console.log(enviarCarta)
                        this.setState({enviarCarta: enviarCarta})
                    } 
                }).catch((error) => {
                    console.log(error)
                })
            }
            if(!porcentagemScore){
                axios.get(api_admin + '/config/configs').then((configuracoes) => {
                    alugmais = configuracoes.data.data.filter((alug) => {
                        return alug.sistemas_id === 2
                    })

                    if(alugmais.length > 0){
                        for(var i = 0; i < alugmais.length; i++){
                            if(alugmais[i].id === 4){
                                porcentagemScore = (parseFloat(alugmais[i].padrao) * 100)
                            }
                        }
                        console.log(porcentagemScore)
                        this.setState({porcentagemScore: parseFloat(porcentagemScore)})
                    } 
                }).catch((error) => {
                    console.log(error)
                })
            }
            if(!anexarComprovantes){
              axios.get(api_admin + '/config/configs').then((configuracoes) => {
                  alugmais = configuracoes.data.data.filter((alug) => {
                      return alug.sistemas_id === 2
                  })

                  if(alugmais.length > 0){
                      for(var i = 0; i < alugmais.length; i++){
                          if(alugmais[i].id === 11){
                              anexarComprovantes = (parseFloat(alugmais[i].padrao))
                          }
                      }
                      console.log(anexarComprovantes)
                      this.setState({anexarComprovantes: parseFloat(anexarComprovantes)})
                  } 
              }).catch((error) => {
                  console.log(error)
              })
          }
        }
        else{
            axios.get(api_admin + '/config/configs').then((configuracoes) => {
                var alugmais = configuracoes.data.data.filter((alug) => {
                    return alug.sistemas_id === 2
                })

                console.log(alugmais)
                console.log(configuracoes)

                if(alugmais.length > 0){
                    for(var i = 0; i < alugmais.length; i++){
                        if(alugmais[i].id === 1){
                            enviarCarta = alugmais[i].padrao
                        }
                        if(alugmais[i].id === 2){
                            porcentagemCarta = parseFloat(alugmais[i].padrao)
                        }
                        if(alugmais[i].id === 3){
                            semComprovante = parseFloat(alugmais[i].padrao)
                        }
                        if(alugmais[i].id === 4){
                            porcentagemScore = (parseFloat(alugmais[i].padrao) * 100)
                        }
                        if(alugmais[i].id === 11){
                          anexarComprovantes = parseFloat(alugmais[i].padrao)
                      }
                    }
                    console.log(enviarCarta)
                    console.log(porcentagemCarta)
                    console.log(semComprovante)
                    console.log(porcentagemScore)
                    console.log(anexarComprovantes)
                    this.setState({enviarCarta: enviarCarta, semComprovante: parseFloat(semComprovante), porcentagemCarta: parseFloat(porcentagemCarta), porcentagemScore: parseFloat(porcentagemScore), anexarComprovantes: parseFloat(anexarComprovantes)})
                } 
            }).catch((error) => {
                console.log(error)
            })
        }
    }).catch((error) => {
        console.log(error)
    })

      // Busca as consultas realizadas pelo o usuário logado
      api_epar.get('/clienteshasconsulta/user/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).id).then((consultas) => {
          // console.log(consultas.data.data)
          if(consultas.data.data !== 'TOKEN inexistente' && consultas.data.data !== 'TOKEN inválido'){
            console.log(FuncoesComum.unirDadosConsulta(consultas))
            // Formata o retorno da API
            this.setState({consultas: FuncoesComum.unirDadosConsulta(consultas)})
          }
          else{
            alert(consultas.data.data)
          }
          $(document).ready(() => {
            $('#dt_table').DataTable({
              order: [[0, 'desc']]
            })
          })
      }).catch((error) => {
          console.log(error)
      })
    }

  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  // Irpa exibir cada consulta com seu devido status
  rowsConsultas = (record) => {
    if(record.consulta_id !== undefined){

      var resultado
      var mensagem
      var uploadPorComercialouCNPJ = 0

      var statusGeral = new Set(record.statusGeral.replace(/ /g, '').split(','))
      var arrayStatus = []
      for(let status of statusGeral){
        arrayStatus.push(status)
      }

      // O status da consulta em ordem de prioridade
      if(arrayStatus.indexOf('Negado') !== -1){
        // console.log('Negado')
        resultado = 2
      }
      else if(arrayStatus.indexOf('Aprovadoportempoexcedido') !== -1){
        // console.log('Aprovado por tempo excedido')
        resultado = 6
      }
      else if(arrayStatus.indexOf('Aprovadocomressalva') !== -1){
        // console.log('Aprovado com ressalva')
        resultado = 1
      }
      else if(arrayStatus.indexOf('Aprovado') !== -1){
        // console.log('Aprovado')
        resultado = 0
      }

      if(record.score_status === 0 || record.pendenciafinanceira_status === 0 || 
        record.processo_status === 0 || record.antecedente_status === 0 || record.status_final === 0 ){
          // console.log('ENTROU')
            resultado = 2
        }

      // Caso não tenha sido negado ou pré aprovado, verificará se tem o retorno do IDWALL
      if(resultado !== 6 && resultado !== 2){
      // if(resultado !== 2){
        if(record.idwall){
          if(JSON.parse(record.idwall.replace(/'/g, '"').replace(/[\r\n]/g, " ")).data){
            resultado = 3
          }
        }
        // else{
        //   resultado = 8
        // }
      }

      
      // Caso não tenha sido negado ou pré aprovado, verificará se tem o retorno do IDWALL
      // if(resultado !== 8 && resultado !== 2){
      if(resultado !== 2){
        if(new Date('2020-08-04') < new Date(record.data_consulta) && (record.finalidadeLocacao === 'Comercial' || record.cpf_cnpj.length > 14)){
          // console.log('passou')
          resultado = 4
          uploadPorComercialouCNPJ = 1
          
        }
        for(var k = 0; k < record.solidario.length; k++){
          if(record.solidario[k].idwall){
            if(JSON.parse(record.solidario[k].idwall.replace(/'/g, '"').replace(/[\r\n]/g, " ")).data){
              resultado = 3
              break
            }
          }
          // else{
          //   resultado = 8
          //   break
          // }
        }
      }
      
      // console.log(resultado)

      // Caso não tenha sido negado, verificará os status do DOCUMENTOS
      // 1 - Aprovando
      // 2 - Aprovado
      // 3 - Negado
      // 4 - Reenvio
      // 5 - Houve reenvio e pode estar em qualquer passo anterior
      if(resultado === 1 || resultado === 0 || resultado === 6 || resultado === 4){
        // console.log('AQUI', uploadPorComercialouCNPJ)
        if(record.maxDoc === 3 || record.minDoc === 3){
          resultado = 2 
        }
        else if(record.countDoc === 0){
          resultado = 4
        }
        else if(record.minDoc === 1 && resultado !== 4){
          resultado = 5
        }
        else if(record.minDoc === 2 && (resultado !== 4 || uploadPorComercialouCNPJ === 1)){
          // console.log('Entrou', resultado)
          if(record.maxDoc === 3 ){
            resultado = 2
          }
          else if(record.maxDoc === 4 && (resultado !== 4 || uploadPorComercialouCNPJ === 1)){
            resultado = 7
          }
          else if((resultado !== 4 || uploadPorComercialouCNPJ === 1)){
            resultado = 0
          }
        }
        else if(record.minDoc === 4){
          resultado = 7
        }
        
        if(record.countDoc === 0){
          resultado = 4
        }
        else{
          if(record.minDoc === 1 && (resultado !== 4 || uploadPorComercialouCNPJ === 1)){
            resultado = 5
          }
          else if(record.minDoc === 2 && (resultado !== 4 || uploadPorComercialouCNPJ === 1)){
            if(record.maxDoc === 3 ){
              resultado = 2
            }
            else if(record.maxDoc === 4 && (resultado !== 4 || uploadPorComercialouCNPJ === 1)){
              resultado = 7
            }
            else if((resultado !== 4 || uploadPorComercialouCNPJ === 1)){
              resultado = 0
            }
          }
          else if(record.minDoc === 3 || record.maxDoc === 3){
            resultado = 2
          }
          else if(record.minDoc === 4){
            resultado = 7
          }
        }
      }

      // console.log(resultado, record.consulta_id)

      // if(new Date(record.data_consulta) > new Date('2020-07-12') && record.renda_informada*this.state.porcentagemCarta <= this.state.semComprovante && resultado !== 2 && record.finalidadeLocacao !== 'Comercial' && (record.renda_informada*this.state.porcentagemCarta) < this.state.anexarComprovantes){
      if(new Date(record.data_consulta) > new Date('2020-08-04')){
        if(new Date(record.data_consulta) > new Date('2020-07-12') && 
          record.renda_informada * this.state.porcentagemCarta <= this.state.semComprovante && 
          resultado !== 2 && record.finalidadeLocacao !== 'Comercial' && (record.finalidadeLocacao !== 'Comercial' && record.cpf_cnpj.length < 15)){
            resultado = 1
        } 
      }
      else{
        if(new Date(record.data_consulta) > new Date('2020-07-12') && record.renda_informada*this.state.porcentagemCarta <= this.state.semComprovante && resultado !== 2){
          resultado = 1
        } 
      }
      // console.log(resultado)

      // Caso não tenha sido negado, verificará os status do DOCUMENTOS
      // 1 - Aprovando
      // 2 - Aprovado
      // 3 - Negado
      // 4 - Reenvio
      // 5 - Houve reenvio e pode estar em qualquer passo anterior
      for(var k = 0; k < record.solidario.length; k++){
        if(resultado === 1 || resultado === 0 || resultado === 5 || resultado === 4 || resultado === 6 || resultado === 7){
          if(record.solidario[k].maxDoc === 3 || record.solidario[k].minDoc === 3){
            resultado = 2
          }
        }
      }

      for(var k = 0; k < record.solidario.length; k++){
        if(resultado === 1 || resultado === 0 || resultado === 5 || resultado === 4 || resultado === 6 || resultado === 7){
          if(record.solidario[k].countDoc === 0){
            resultado = 4
          }
        }
      }

      for(var k = 0; k < record.solidario.length; k++){
        if(resultado === 1 || resultado === 0 || resultado === 5 || resultado === 6 || resultado === 7){
          if(record.solidario[k].minDoc === 1){
            resultado = 5
          }
        }
      }

      for(var k = 0; k < record.solidario.length; k++){
        if(resultado === 1 || resultado === 0 || resultado === 6 || resultado === 7){
          if(record.solidario[k].minDoc === 4 || record.solidario[k].maxDoc === 4){
            resultado = 7
          }
        }
      }

      for(var k = 0; k < record.solidario.length; k++){
        if(resultado === 1 || resultado === 0 || resultado === 6){
          if(record.solidario[k].minDoc === 2 && record.solidario[k].maxDoc !== 3){
            resultado = 0
          }
        }
      }

      if(resultado === 7){
        // console.log('Entrou')
        api_epar.get( '/clienteshasdocumentacao/' + record.cliente_id).then((resposta) => {
          if(resposta.data.data.length > 0){
            // Verifica se tem documentos pendentes a serem analisados de um cliente e consulta específica
              var documentos = resposta.data.data.filter(e => {return e.documentacao_id === 4 && e.aprovado === 4 && e.consulta_id === parseInt(record.consulta_id)})
              console.log(documentos)
              if(documentos.length > 0){
                for(var a = 0; a < documentos.length; a++){ 
                  if(documentos[a].motivo){
                    if(document.getElementById(record.consulta_id)){
                      document.getElementById(record.consulta_id).innerText = documentos[a].motivo
                    }
                  }
                  else{
                    if(document.getElementById(record.consulta_id)){
                      document.getElementById(record.consulta_id).innerText = 'Não informado'
                    }
                  }
                  break;
                }
              }
              else{
                for(var l = 0; l < record.solidario.length; l++){
                  api_epar.get( '/clienteshasdocumentacao/' + record.solidario[l].cliente_id).then((resposta) => {
                    if(resposta.data.data.length > 0){
                      // Verifica se tem documentos pendentes a serem analisados de um cliente e consulta específica
                        var documentos = resposta.data.data.filter(e => {return e.documentacao_id === 4 && e.aprovado === 4 && e.consulta_id === parseInt(record.consulta_id)})
                        if(documentos.length > 0){
                          var cont = 0
                          for(var m = 0; m < documentos.length; m++){ 
                            if(documentos[m].motivo){
                              if(document.getElementById(record.consulta_id)){
                                document.getElementById(record.consulta_id).innerText = documentos[m].motivo
                              }
                            }
                            else{
                              if(document.getElementById(record.consulta_id)){
                                document.getElementById(record.consulta_id).innerText = 'Não informado'
                              }
                            }
                            break;
                          }
                        }
                    }
                  })
                }
              }
          }
        })

        
      }

      for(var k = 0; k < record.solidario.length; k++){
        if(resultado === 1 || resultado === 0 || resultado === 5 || resultado === 4 || resultado === 6 || resultado === 7){
          if(record.solidario[k].countDoc === 0){
            resultado = 4
          }
          else{
            if(record.solidario[k].minDoc === 1 && resultado !== 4){
              resultado = 5
            }
            else if(record.solidario[k].minDoc === 2 && resultado !== 4){
              if(record.solidario[k].maxDoc === 3){
                resultado = 2
                break
              }
              else if(record.solidario[k].maxDoc === 4 && resultado !== 4){
                resultado = 7
              }
              else if(resultado !== 4){
                resultado = 0
              }
            }
            else if(record.solidario[k].minDoc === 3){
                resultado = 2
                break
            }
            else if(record.solidario[k].minDoc === 4){
              resultado = 7
            }   
          }
        }
      }
      // console.log(resultado + ' : ' + record.consulta_id)


      //Mensagem de acordo com a análise feita anteriormente
      if(resultado === 0){
        // mensagem = <Label className='p-1'>Válido</Label>
        mensagem = <Label style={{backgroundColor: '#006600', borderRadius: '4px', color: 'white'}} className='p-1'>Válido</Label>
      }
      if(resultado === 1){
        // mensagem = <Label className='p-1'>Aguardando confirmação</Label>
        mensagem = <Label style={{backgroundColor: '#006600', borderRadius: '4px', color: 'white'}} className='p-1'>Válido</Label>
        // mensagem = <Label style={{backgroundColor: '#ffc107', borderRadius: '4px', color: 'white'}} className='p-1'>Aguardando confirmação</Label>
      }
      if(resultado === 2){
        // mensagem = <Label className='p-1'>Inválido</Label>
        mensagem = <Label style={{backgroundColor: '#660000', borderRadius: '4px', color: 'white'}} className='p-1'>Inválido</Label>
      }
      if(resultado === 3){
        // mensagem = <Label className='p-1'>Inválido</Label>
        mensagem = <Label style={{backgroundColor: '#0099ff', borderRadius: '4px', color: 'white'}} className='p-1'>Aguardando retorno</Label>
      }
      if(resultado === 4){
        // mensagem = <Label className='p-1'>Inválido</Label>
        mensagem = <Label style={{backgroundColor: '#ffaf1a', borderRadius: '4px', color: 'white'}} className='p-1'>Esperando anexar arquivos</Label>
      }
      if(resultado === 5){
        // mensagem = <Label className='p-1'>Inválido</Label>
        mensagem = <Label style={{backgroundColor: '#999999', borderRadius: '4px', color: 'white'}} className='p-1'>Aguardando aprovação de documentos</Label>
      }
      if(resultado === 6){
        // mensagem = <Label className='p-1'>Inválido</Label>
        // mensagem = <Label style={{backgroundColor: '#000000', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado por tempo excedido</Label>
        mensagem = <Label style={{backgroundColor: '#ffc107', borderRadius: '4px', color: 'white'}} className='p-1'>Pré-aprovado</Label>
      }
      if(resultado === 7){
        // mensagem = <Label className='p-1'>Inválido</Label>
        // mensagem = <Label style={{backgroundColor: '#000000', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado por tempo excedido</Label>
        mensagem = <Label style={{backgroundColor: '#ff4d4d', borderRadius: '4px', color: 'white'}} className='p-1'>
                    Reenvio de documentos
                    <div className='tooltipnew'>
                        <i className="fas fa-info ml-4 pr-2"/>
                        <span id={record.consulta_id} className="tooltiptext" style={{fontSize: '0.8em', left: '-35px', bottom: '150%'}}></span>
                    </div>
                   </Label>
      }
      if(resultado === 8){
        // mensagem = <Label className='p-1'>Inválido</Label>
        mensagem = <Label style={{backgroundColor: '#ff6666', borderRadius: '4px', color: 'white'}} className='p-1'>Sem retorno</Label>
      }

      return(
        <tr key={record.consulta_id} >
          <th scope="row">
            {record.consulta_id}
          </th>
          <td>
            {FuncoesComum.formatarMoeda(parseFloat(record.valor))}
          </td>
          <th scope="row">
            {record.cpf_cnpj}
          </th>
          <td>
            {record.nomeLocatario}
          </td>
          <td>
            {new Date(record.data_consulta).toLocaleString('pt-BR')}
          </td>
          <td>
            {mensagem}
          </td>
          <td className="d-flex align-itens-center">
            <Label style={{border: '1px solid #d9d9d9', borderRadius: '5px', color: 'black', backgroundColor: 'white'}} className='float-right p-1' to={'/admin/newdetalhe/' + record.consulta_id} tag={Link}>Ver resultado</Label>
            <Label onClick={a => this.getDadosContrato(record.consulta_id)} alt="Sinistro" title="Detalhes" style={{border: '1px solid #d9d9d9', borderRadius: '5px', color: 'white', backgroundColor: '#3b1e3e'}} className='float-right p-1 m-0 ml-1'>
                <i className="fa fa-list-ol"></i>
              </Label>
          </td>
        </tr>
      )
    }
    else{
      return(null)
    }
  }

  rowSolidario = (record) => {
    return (
      <div key={record.cpf_cnpj}>
        {record.cpf_cnpj}
      </div>
    )
  }

  dateFormat = (dx) => {
    if (!dx) return dx

    let [y, m, d] = dx.split("T")[0].split('-')
    
    return `${d}/${m}/${y}`
  }

  diffDay = (d1, d2) => {

    return Math.ceil((new Date(d2).getTime() - new Date(d1).getTime()) / (1000 * 3600 * 24))
  }

  toggleAlert = () => this.setState({alert: false})

  getDadosContrato = async (id) => {
    let re = await api_epar.get('/dadoscontratocartagarantiabycarta/' + id)
    
    console.log(re.data.data);
    this.setState({
      dadosContratoUnico: re.data.data[0],
      alert: true
    })
  }

  render() {
    return (
      <>
        {/* <script src="https://cdn.datatables.net/1.10.20/js/dataTables.bootstrap4.min.js"></script> */}
        {/* <script src="https://cdn.datatables.net/1.10.20/js/jquery.dataTables.min.js"></script> */}
        {/* <script src="https://code.jquery.com/jquery-3.3.1.js"></script> */}
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.css"></link> */}
        <link rel="stylesheet" href="https://cdn.datatables.net/1.10.20/css/dataTables.bootstrap4.min.css"></link>
        <Header style={{height: '10vh'}} />

        <Container className={"mt-5 d-flex justify-content-center"} fluid style={{minHeight: '65vh'}}>
          <table id="dt_table" className="table table-responsive table-striped" style={{width: "100%"}}>
              <thead >
                  <tr >
                      <th >Consulta</th>
                      <th >Valor</th>
                      <th >Locatário principal</th>
                      <th >Locatário nome</th>
                      <th >Data da consulta</th>
                      {/* <th >Status</th> */}
                      <th >Resultado</th>
                      <th >Detalhes</th>
                  </tr>
              </thead>
              <tbody>
                {this.state.consultas.map(this.rowsConsultas)}
              </tbody>
              {/* <tfoot>
                  <tr>
                      <th >CONSULTA</th>
                      <th >VALOR</th>
                      <th >LOCATÁRIO PRINCIPAL</th>
                      <th >LOCATÁRIO(S) SOLIDÁRIO(S)</th>
                      <th >DATA DA CONSULTA</th>
                      <th >STATUS</th>
                      <th >RESULTADO</th>
                  </tr>
              </tfoot> */}
          </table>
        </Container>
        <Modal isOpen={this.state.alert} toggle={this.toggleAlert} id="modal-max-800">
          <ModalHeader toggle={this.toggleAlert}>informações extras</ModalHeader>
          <ModalBody>
              <Row>
                  <Col md={6}>
                    <strong><p>Data (intervalo em dias)</p></strong>
                    <div className="input-group mb-5 p-0">
                      {this.diffDay(this.state.dadosContratoUnico.data_inicio, this.state.dadosContratoUnico.data_fim)}
                    </div>
                  </Col>
                  <Col md={6}>
                    <strong><p>Locador</p></strong>
                    <div className="input-group mb-5 p-0">
                      {this.state.dadosContratoUnico.locador_nome}
                    </div>
                  </Col>

                  <Col md={6}>
                    <strong><p>Locatário</p></strong>
                    <div className="input-group mb-5 p-0">
                      {this.state.dadosContratoUnico.locatario_nome}
                    </div>
                  </Col>
                  <Col md={6}>
                    <strong><p>Imobiliária</p></strong>
                    <div className="input-group mb-5 p-0">
                      {this.state.imob}
                    </div>
                  </Col>

                  <Col md={6}>
                    <strong><p>Data de início</p></strong>
                    <div className="input-group mb-5 p-0">
                      {this.dateFormat(this.state.dadosContratoUnico.data_inicio)}
                    </div>
                  </Col>
                  <Col md={6}>
                    <strong><p>Data de termíno</p></strong>
                    <div className="input-group mb-5 p-0">
                      {this.dateFormat(this.state.dadosContratoUnico.data_fim)}
                    </div>
                  </Col>

                  <Col md={6}>
                    <strong><p>Valor do aluguel</p></strong>
                    <div className="input-group mb-5 p-0">
                      {Number(this.state.dadosContratoUnico.aluguel).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </div>
                  </Col>
                  <Col md={6}>
                    <strong><p>Valor de encargo</p></strong>
                    <div className="input-group mb-5 p-0">
                      Ggás: {Number(this.state.dadosContratoUnico.gas).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })};<br />
                      Luz: {Number(this.state.dadosContratoUnico.luz).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })};<br />
                      Água: {Number(this.state.dadosContratoUnico.agua).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })};<br />
                      Condominio: {Number(this.state.dadosContratoUnico.condominio).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })};<br />
                      IPTU: {Number(this.state.dadosContratoUnico.iptu).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })};<br />
                      Aluguel: {Number(this.state.dadosContratoUnico.aluguel).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}; <br />
                    </div>
                  </Col>

                  <Col md={6}>
                    <strong><p>Valor total</p></strong>
                    <div className="input-group mb-5 p-0">
                      {Number(this.state.dadosContratoUnico.valorAluguel).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </div>
                  </Col>
                  <Col md={6}>
                    <strong><p>Valor alug+</p></strong>
                    <div className="input-group mb-5 p-0">
                      {(Number(this.state.dadosContratoUnico.valorAluguel) * Number(this.state.dadosContratoUnico.taxa_mensal)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </div>
                  </Col>
              </Row>
          </ModalBody>
          <ModalFooter>
              <Button className="btn-dark" onClick={this.toggleAlert}>Fechar</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Relatorios;
