/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter,
    Label
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from "../funcoes/FuncoesComum";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import UploadDoc from "components/UploadDoc.jsx";
import moment from 'moment'
import sha1 from 'sha1'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import link_aprovacao from '../links/links'
import ReactGA from 'react-ga'

class DownloadContratoCarta extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contrato: [],
            pdf: '',
            garantia: '',
            data_inicio: '',
            selectedFile1: null,
            selectedFile2: null,
            selectedFile3: null,
            file1: 'd-print-block',
            file2: 'd-print-block',
            file3: 'd-print-block',
            divArquivos: 'd-none',
            clientePrincipal: null,
            cpf: '',
            alert: false,
            mensagemModal: '',
            dadosContrato: [],
            renda: 0,
            load: false
            // contratoGarantia: true,
            // cursor: 'not-allowed'
        }
    }

    toggleAlert = () => {
      if(this.state.load){
        this.setState({load: !this.state.load})
      }
      this.setState({alert: !this.state.alert})
    }

    onChangeFile1 = (evento) => {
      this.setState({
          selectedFile1: evento.target.files[0] 
      })
      // console.log(evento.target.files[0])
    }

    onChangeFile2 = (evento) => {
      this.setState({
          selectedFile2: evento.target.files[0] 
      })
      // console.log(evento.target.files[0])
    }

    onChangeFile3 = (evento) => {
      this.setState({
          selectedFile3: evento.target.files[0] 
      })
      // console.log(evento.target.files[0])
    }

    // upload1 = () => {     
    //   var diretorio = 'docs/' + sha1(this.state.cpf)
    //   var data = new FormData()
    //   data.append('file', this.state.selectedFile1)
    //   data.append('rota', diretorio)
    //   api_epar.post( '/upload', data).then((response) => {
    //       console.log(response.statusText)
    //       api_epar.post( '/clienteshasdocumentacao', {
    //         clientes_id: this.state.clientePrincipal,
    //         documentacao_id: 5,
    //         path: diretorio + '/' + this.state.selectedFile1.name,
    //         data_envio: moment().format('YYYY-MM-DD HH:mm:ss'),
    //         aprovado: 1
    //       }).then((retorno) => {
    //           console.log(retorno)
    //           this.setState({mensagemModal: <div>
    //                                           Upload do arquivo feito com sucesso
    //                                         </div>})
    //           this.toggleAlert()
    //           this.setState({file1: 'd-none'})
    //       }).catch((error) => {
    //         console.log(error)
    //       })
    //   }).catch((error) => {
    //     console.log(error)
    //   })
    // }

    // upload2 = () => {     
    //   var diretorio = 'docs/' + sha1(this.state.cpf)
    //   var data = new FormData()
    //   data.append('file', this.state.selectedFile2)
    //   data.append('rota', diretorio)
    //   api_epar.post( '/upload', data).then((response) => {
    //       console.log(response.statusText)
    //       api_epar.post( '/clienteshasdocumentacao', {
    //         clientes_id: this.state.clientePrincipal,
    //         documentacao_id: 6,
    //         path: diretorio + '/' + this.state.selectedFile2.name,
    //         data_envio: moment().format('YYYY-MM-DD HH:mm:ss'),
    //         aprovado: 1
    //       }).then((retorno) => {
    //           console.log(retorno)
    //           this.setState({mensagemModal: <div>
    //                                           Upload do arquivo feito com sucesso
    //                                         </div>})
    //           this.toggleAlert()
    //           this.setState({file2: 'd-none'})
    //       }).catch((error) => {
    //         console.log(error)
    //       })
    //   }).catch((error) => {
    //     console.log(error)
    //   })
    // }

    // upload3 = () => {     
    //   var diretorio = 'docs/' + sha1(this.state.cpf)
    //   var data = new FormData()
    //   data.append('file', this.state.selectedFile3)
    //   data.append('rota', diretorio)
    //   api_epar.post( '/upload', data).then((response) => {
    //       console.log(response.statusText)
    //       api_epar.post( '/clienteshasdocumentacao', {
    //         clientes_id: this.state.clientePrincipal,
    //         documentacao_id: 7,
    //         path: diretorio + '/' + this.state.selectedFile3.name,
    //         data_envio: moment().format('YYYY-MM-DD HH:mm:ss'),
    //         aprovado: 1
    //       }).then((retorno) => {
    //           console.log(retorno)
    //           this.setState({mensagemModal: <div>
    //                                           Upload do arquivo feito com sucesso
    //                                         </div>})
    //           this.toggleAlert()
    //           this.setState({file3: 'd-none'})
    //       }).catch((error) => {
    //         console.log(error)
    //       })
    //   }).catch((error) => {
    //     console.log(error)
    //   })
    // }

    uploadArquivos = () => {   
      var attachments = []  

      this.toggleLoad()

      //Estrutura do email
      var email =  '<div>'
                    +'Prezado Administrador Alug+, os documentos do contrato da consulta abaixo estão em anexo: <br/><br/>'
                    +'Identificador da consulta: ' + this.props.match.params.id + '<br/>'
                    +'Imobiliária: ' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria + '<br/>'
                    +'Valor da renda informada: ' + FuncoesComum.formatarMoeda(parseFloat(this.state.renda)) + '<br/>'
                    +'Valor da locação: ' + FuncoesComum.formatarMoeda(parseFloat(this.state.dadosContrato.data.data[0].valorAluguel)) + '<br/><br/>'
                    // +'Em anexo o(s) arquivo(s) para as devidas tratativas de aprovação para liberação da emissão do contrato de garantia.<br/>'
                    // +'Favor aprovar ou reprovar para darmos continuidade, clicando nos links a seguir <br/><br/>'
                  +'</div>'
                  +'<table>'
                  +'  <tr style="height: 40px">'
                  +'      <td>Arquivo</td>'
                  +'  </tr>'

      if(this.state.selectedFile1 !== null){
        
        var diretorio1 = 'docs/' + sha1(this.state.cpf)
        var data1 = new FormData()
        var nomeArquivo1 = ''
        var nomeAntigo1 = this.state.selectedFile1.name

        //Renomear nome do arquivo de acordo com o nosso padrão
        if(this.state.selectedFile1.name.split('.').length > 2){
          for(var o = 0; o < this.state.selectedFile1.name.split('.').length; o++){
            if(o === this.state.selectedFile1.name.split('.').length-1){
              nomeArquivo1 += '.' + this.state.selectedFile1.name.split('.')[o]
            }
            else{
              nomeArquivo1 += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')   + Math.floor(Math.random() * 1000) + sha1(nomeAntigo1)
            }
          }
        }
        else{
          nomeArquivo1 += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')   + Math.floor(Math.random() * 1000) + sha1(nomeAntigo1) +'.'+ this.state.selectedFile1.name.split('.')[1]
        }

        //Criar o novo arquivo como o nome novo
        var arquivo1 = new File([this.state.selectedFile1], nomeArquivo1, {type: this.state.selectedFile1.type})

        // Adiciona o caminho do arquivo para encontra-lo e enviar o anexo no email 
        attachments.push({path: diretorio1 + '/' + nomeArquivo1})


        // var linkAprovar1 = link_aprovacao + '/telaaprovacao?cliente=' + this.state.clientePrincipal + '&documentacao=5&path='+ diretorio1 + '/' + nomeArquivo1 +'&opcao=2'
        // var linkReprovar1 = link_aprovacao + '/telaaprovacao?cliente=' + this.state.clientePrincipal + '&documentacao=5&path='+ diretorio1 + '/' + nomeArquivo1 +'&opcao=3'
        
        // Estrutura do email
        email += ''
        +' <tr style="height: 40px">'
        +'     <th>' + this.state.selectedFile1.name + '</th>'
        // +'     <th><a href="' + linkAprovar1 + '" style=" background-color: #0ea00e;'
        // +'                         border: none;'
        // +'                         padding: 8px 20px;'
        // +'                         border-radius: 2px;'
        // +'                         color: #fff;    '
        // +'                         outline: none;    '
        // +'                         text-decoration: none;    '
        // +'                         margin: 0px 5px;">Aprovar</a></th>'
        // +'     <th><a href="' + linkReprovar1 + '" style=" background-color: #a52121;'
        // +'                         border: none;'
        // +'                         outline: none;    '
        // +'                         text-decoration: none;    '
        // +'                         padding: 8px 20px;'
        // +'                         border-radius: 2px;'
        // +'                        color: #fff;">Reprovar</a></th>'
        +'</tr>'

        // Envia o arquivo para API com o intuito de ser armazenado
        data1.append('file', arquivo1)
        data1.append('rota', diretorio1)
        data1.append('nomeAntigo', nomeAntigo1)

        // Envia uma requisição POST 
        api_epar.post( '/upload/'  + diretorio1.replace('/', '|'), data1).then((response) => {
            console.log(response.statusText)
            api_epar.post( '/clienteshasdocumentacao', {
              clientes_id: this.state.clientePrincipal,
              documentacao_id: 5,
              path: diretorio1 + '/' + nomeArquivo1,
              data_envio: moment().format('YYYY-MM-DD HH:mm:ss'),
              aprovado: 1,
              dados_contrato_id: this.props.match.params.id
            }).then((retorno) => {
                console.log(retorno)
                this.setState({mensagemModal: <div>
                                  Upload do(s) arquivo(s) feito(s) com sucesso
                                </div>})
                this.toggleAlert()
                this.setState({file1: 'd-none'})
                if(this.state.file2 === 'd-none' && this.state.file3 === 'd-none'){
                  this.setState({divArquivos: 'd-none'})
                }
                // this.toggleLoad()
            }).catch((error) => {
              console.log(error)
            })
        }).catch((error) => {
          console.log(error)
        })
      }  
      
      if(this.state.selectedFile2 !== null){

        var diretorio2 = 'docs/' + sha1(this.state.cpf)
        var data2 = new FormData()
        var nomeArquivo2 = ''
        var nomeAntigo2 = this.state.selectedFile2.name

        // Onde ocorre a alteração do nome do arquivo para o nosso padrão
        if(this.state.selectedFile2.name.split('.').length > 2){
          for(var o = 0; o < this.state.selectedFile2.name.split('.').length; o++){
            if(o === this.state.selectedFile2.name.split('.').length-1){
              nomeArquivo2 += '.' + this.state.selectedFile2.name.split('.')[o]
            }
            else{
              nomeArquivo2 += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')   + Math.floor(Math.random() * 1000) + sha1(nomeAntigo2)
            }
          }
        }
        else{
          nomeArquivo2 += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')   + Math.floor(Math.random() * 1000) + sha1(nomeAntigo2) +'.'+ this.state.selectedFile2.name.split('.')[1]
        }

        // Cria um novo arquivo com o novo nome do arquivo 
        var arquivo2 = new File([this.state.selectedFile2], nomeArquivo2, {type: this.state.selectedFile2.type})
        
        // Adiciona o caminho do arquivo para encontra-lo e enviar o anexo no email 
        attachments.push({path: diretorio2 + '/' + nomeArquivo2})


        var linkAprovar2 = link_aprovacao + '/telaaprovacao?cliente=' + this.state.clientePrincipal + '&documentacao=6&path='+ diretorio2 + '/' + nomeArquivo2 +'&opcao=2'
        var linkReprovar2 = link_aprovacao + '/telaaprovacao?cliente=' + this.state.clientePrincipal + '&documentacao=6&path='+ diretorio2 + '/' + nomeArquivo2 +'&opcao=3'
        
        // Estrutura do email
        email += ''
        +' <tr style="height: 40px">'
        +'     <th>' + this.state.selectedFile2.name + '</th>'
        // +'     <th><a href="' + linkAprovar2 + '" style=" background-color: #0ea00e;'
        // +'                         border: none;'
        // +'                         padding: 8px 20px;'
        // +'                         border-radius: 2px;'
        // +'                         color: #fff;    '
        // +'                         outline: none;    '
        // +'                         text-decoration: none;    '
        // +'                         margin: 0px 5px;">Aprovar</a></th>'
        // +'     <th><a href="' + linkReprovar2 + '" style=" background-color: #a52121;'
        // +'                         border: none;'
        // +'                         outline: none;    '
        // +'                         text-decoration: none;    '
        // +'                         padding: 8px 20px;'
        // +'                         border-radius: 2px;'
        // +'                        color: #fff;">Reprovar</a></th>'
        +'</tr>'

        // Envia o arquivo para API com o intuito de ser armazenado
        data2.append('file', arquivo2)
        data2.append('rota', diretorio2)
        data2.append('nomeAntigo', nomeAntigo2)

        // Envia uma requisição POST 
        api_epar.post( '/upload/'  + diretorio2.replace('/', '|'), data2).then((response) => {
            console.log(response.statusText)
            api_epar.post( '/clienteshasdocumentacao', {
              clientes_id: this.state.clientePrincipal,
              documentacao_id: 6,
              path: diretorio2 + '/' + nomeArquivo2,
              data_envio: moment().format('YYYY-MM-DD HH:mm:ss'),
              aprovado: 1,
              dados_contrato_id: this.props.match.params.id
            }).then((retorno) => {
                console.log(retorno)
                this.setState({mensagemModal: <div>
                                                Upload do(s) arquivo(s) feito(s) com sucesso
                                              </div>})
                this.toggleAlert()
                this.setState({file2: 'd-none'})
                if(this.state.file1 === 'd-none' && this.state.file3 === 'd-none'){
                  this.setState({divArquivos: 'd-none'})
                }
                // this.toggleLoad()
            }).catch((error) => {
              console.log(error)
            })
        }).catch((error) => {
          console.log(error)
        })
      }

      if(this.state.selectedFile3 !== null){
        var diretorio3 = 'docs/' + sha1(this.state.cpf)
        var data3 = new FormData()
        var nomeArquivo3 = ''
        var nomeAntigo3 = this.state.selectedFile3.name

        // Onde ocorre a alteração do nome do arquivo para o nosso padrão
        if(this.state.selectedFile3.name.split('.').length > 2){
          for(var o = 0; o < this.state.selectedFile3.name.split('.').length; o++){
            if(o === this.state.selectedFile3.name.split('.').length-1){
              nomeArquivo3 += '.' + this.state.selectedFile3.name.split('.')[o]
            }
            else{
              nomeArquivo3 += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')  + Math.floor(Math.random() * 1000) + sha1(nomeAntigo3)
            }
          }
        }
        else{
          nomeArquivo3 += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')  + Math.floor(Math.random() * 1000) + sha1(nomeAntigo3) +'.'+ this.state.selectedFile3.name.split('.')[1]
        }

        // Cria um novo arquivo com o novo nome do arquivo 
        var arquivo3 = new File([this.state.selectedFile3], nomeArquivo3, {type: this.state.selectedFile3.type})

        // Adiciona o caminho do arquivo para encontra-lo e enviar o anexo no email 
        attachments.push({path: diretorio3 + '/' + nomeArquivo3})


        var linkAprovar3 = link_aprovacao + '/telaaprovacao?cliente=' + this.state.clientePrincipal + '&documentacao=7&path='+ diretorio3 + '/' + nomeArquivo3 +'&opcao=2'
        var linkReprovar3 = link_aprovacao + '/telaaprovacao?cliente=' + this.state.clientePrincipal + '&documentacao=7&path='+ diretorio3 + '/' + nomeArquivo3 +'&opcao=3'
        
        //Estrutura do email
        email += ''
        +' <tr style="height: 40px">'
        +'     <th>' + this.state.selectedFile3.name + '</th>'
        // +'     <th><a href="' + linkAprovar3 + '" style=" background-color: #0ea00e;'
        // +'                         border: none;'
        // +'                         padding: 8px 20px;'
        // +'                         border-radius: 2px;'
        // +'                         color: #fff;    '
        // +'                         outline: none;    '
        // +'                         text-decoration: none;    '
        // +'                         margin: 0px 5px;">Aprovar</a></th>'
        // +'     <th><a href="' + linkReprovar3 + '" style=" background-color: #a52121;'
        // +'                         border: none;'
        // +'                         outline: none;    '
        // +'                         text-decoration: none;    '
        // +'                         padding: 8px 20px;'
        // +'                         border-radius: 2px;'
        // +'                        color: #fff;">Reprovar</a></th>'
        +'</tr>'

        // Envia o arquivo para API com o intuito de ser armazenado
        data3.append('file', arquivo3)
        data3.append('rota', diretorio3)
        data3.append('nomeAntigo', nomeAntigo3)

        // Envia uma requisição POST
        api_epar.post( '/upload/'  + diretorio3.replace('/', '|'), data3).then((response) => {
            console.log(response.statusText)
            api_epar.post( '/clienteshasdocumentacao', {
              clientes_id: this.state.clientePrincipal,
              documentacao_id: 7,
              path: diretorio3 + '/' + nomeArquivo3,
              data_envio: moment().format('YYYY-MM-DD HH:mm:ss'),
              aprovado: 1,
              dados_contrato_id: this.props.match.params.id
            }).then((retorno) => {
                console.log(retorno)
                this.setState({mensagemModal: <div>
                                  Upload do(s) arquivo(s) feito(s) com sucesso
                                </div>})
                this.toggleAlert()
                this.setState({file3: 'd-none'})
                if(this.state.file1 === 'd-none' && this.state.file2 === 'd-none'){
                  this.setState({divArquivos: 'd-none'})
                }
                
            }).catch((error) => {
              console.log(error)
            })
        }).catch((error) => {
          console.log(error)
        })
      }
      email += '</table>'


      //Envia o email com os arquivos armazenados
      api_epar.post( '/sendemail', {
              // to: 'otavioromualdo@gmail.com',
              subject: 'Documentos do contrato',
              // text: 'Era só o segundo teste mesmo',
              html: email,
              attachments: attachments,
              extras: {
                consulta: this.props.match.params.id,
                cliente: this.state.clientePrincipal
              }
      }).then((retorno) => {
        // this.toggleLoad()
        console.log(retorno)
          // alert('Upload de arquivo feito com sucesso, só aguardar a aprovação')
          // this.setState({mensagemModal: <div>
          //                                 Upload de arquivo e envio de email feito com sucesso, só aguardar a aprovação do documento
          //                               </div>})
          // this.toggleAlert()
      }).catch((error) => {
        console.log(error)
      })
    }

    componentDidMount(){

      // Analytics
      ReactGA.initialize(process.env.REACT_APP_UA)
      ReactGA.pageview(window.location.pathname + window.location.search)

        api_epar.get( '/dadoscontrato/' + this.props.match.params.id).then((dadosContrato) => {
          console.log(dadosContrato)
          if (JSON.parse(localStorage.getItem('dadosUsuario_test')).id !== dadosContrato.data.data[0].usuario){
            this.props.history.push('/admin/relatorios')
          }
            // Busca o modelo de contrato para LOCAÇÃO
            api_epar.get( '/contrato/1').then((retornoContrato) => {
                console.log(retornoContrato.data.data)
                this.setState({contrato: retornoContrato.data.data, data_inicio: dadosContrato.data.data[0].data_inicio, dadosContrato: dadosContrato})

                //Substitui as variáveis pelo seu devido valor
                var atualizarContrato = retornoContrato.data.data[0].contrato
                .replace(/&ldquo;/g, '"')
                .replace(/&rdquo;/g, '"')
                .replace(/&ndash;/g, '-')
                .replace(/<code>@numero contrato<\/code>/g, dadosContrato.data.data[0].numero_contrato)
                .replace(/<code>@data inicio<\/code>/g, new Date(dadosContrato.data.data[0].data_inicio).toLocaleString('pt-BR'))
                .replace(/<code>@data fim<\/code>/g, new Date(dadosContrato.data.data[0].data_fim).toLocaleString('pt-BR'))
                .replace(/<code>@valorAluguel<\/code>/g, dadosContrato.data.data[0].valorAluguel)
                .replace(/<code>@aluguel<\/code>/g, dadosContrato.data.data[0].aluguel)
                .replace(/<code>@condominio<\/code>/g, dadosContrato.data.data[0].condominio)
                .replace(/<code>@iptu<\/code>/g, dadosContrato.data.data[0].iptu)
                .replace(/<code>@agua<\/code>/g, dadosContrato.data.data[0].agua)
                .replace(/<code>@luz<\/code>/g, dadosContrato.data.data[0].luz)
                .replace(/<code>@gas<\/code>/g, dadosContrato.data.data[0].gas)
                .replace(/<code>@diaPag<\/code>/g, dadosContrato.data.data[0].diaPag)
                .replace(/<code>@tipoPag<\/code>/g, dadosContrato.data.data[0].tipoPag)
                .replace(/<code>@periodicidade<\/code>/g, dadosContrato.data.data[0].periodicidade)
                .replace(/<code>@indice<\/code>/g, dadosContrato.data.data[0].indice)
                .replace(/<code>@t1 nome<\/code>/g, dadosContrato.data.data[0].t1_nome)
                .replace(/<code>@t1 cpf<\/code>/g, dadosContrato.data.data[0].t1_cpf)
                .replace(/<code>@t1 telefone<\/code>/g, dadosContrato.data.data[0].t1_telefone)
                .replace(/<code>@t2 nome<\/code>/g, dadosContrato.data.data[0].t2_nome)
                .replace(/<code>@t2 cpf<\/code>/g, dadosContrato.data.data[0].t2_cpf)
                .replace(/<code>@t2 telefone<\/code>/g, dadosContrato.data.data[0].t2_telefone)
                .replace(/<code>@imovel tipo<\/code>/g, dadosContrato.data.data[0].imovel_tipo)
                .replace(/<code>@imovel finalidade<\/code>/g, dadosContrato.data.data[0].imovel_finalidade)
                .replace(/<code>@imovel tipoImovel<\/code>/g, dadosContrato.data.data[0].imovel_tipoImovel)
                .replace(/<code>@imovel cep<\/code>/g, dadosContrato.data.data[0].imovel_cep)
                .replace(/<code>@imovel end<\/code>/g, dadosContrato.data.data[0].imovel_end)
                .replace(/<code>@imovel endNum<\/code>/g, dadosContrato.data.data[0].imovel_endNum)
                .replace(/<code>@imovel endComplemento<\/code>/g, dadosContrato.data.data[0].imovel_endComplemento)
                .replace(/<code>@imovel endBairro<\/code>/g, dadosContrato.data.data[0].imovel_endBairro)
                .replace(/<code>@imovel endCidade<\/code>/g, dadosContrato.data.data[0].imovel_endCidade)
                .replace(/<code>@imovel endEstado<\/code>/g, dadosContrato.data.data[0].imovel_endEstado)
                .replace(/<code>@locador tipo<\/code>/g, dadosContrato.data.data[0].locador_tipo)
                .replace(/<code>@locador nome<\/code>/g, dadosContrato.data.data[0].locador_nome)
                .replace(/<code>@locador sexo<\/code>/g, dadosContrato.data.data[0].locador_sexo)
                .replace(/<code>@locador nacionalidade<\/code>/g, dadosContrato.data.data[0].locador_nacionalidade)
                .replace(/<code>@locador profissao<\/code>/g, dadosContrato.data.data[0].locador_profissao)
                .replace(/<code>@locador cpf<\/code>/g, dadosContrato.data.data[0].locador_cpf)
                .replace(/<code>@locador rg<\/code>/g, dadosContrato.data.data[0].locador_rg)
                .replace(/<code>@locador orgEmissor<\/code>/g, dadosContrato.data.data[0].locador_orgEmissor)
                .replace(/<code>@locador estadoCivil<\/code>/g, dadosContrato.data.data[0].locador_estadoCivil)
                .replace(/<code>@locador residencial<\/code>/g, dadosContrato.data.data[0].locador_residencial)
                .replace(/<code>@locador celular<\/code>/g, dadosContrato.data.data[0].locador_celular)
                .replace(/<code>@locador email<\/code>/g, dadosContrato.data.data[0].locador_email)
                .replace(/<code>@locador cep<\/code>/g, dadosContrato.data.data[0].locador_cep)
                .replace(/<code>@locador end<\/code>/g, dadosContrato.data.data[0].locador_end)
                .replace(/<code>@locador endNum<\/code>/g, dadosContrato.data.data[0].locador_endNum)
                .replace(/<code>@locador endComplemento<\/code>/g, dadosContrato.data.data[0].locador_endComplemento)
                .replace(/<code>@locador endBairro<\/code>/g, dadosContrato.data.data[0].locador_endBairro)
                .replace(/<code>@locador endCidade<\/code>/g, dadosContrato.data.data[0].locador_endCidade)
                .replace(/<code>@locador endEstado<\/code>/g, dadosContrato.data.data[0].locador_endEstado)
                .replace(/<code>@locador razaoSocial<\/code>/g, dadosContrato.data.data[0].locador_razaoSocial)
                .replace(/<code>@locador fantasia<\/code>/g, dadosContrato.data.data[0].locador_fantasia)
                .replace(/<code>@locador cnpj<\/code>/g, dadosContrato.data.data[0].locador_cnpj)
                .replace(/<code>@locador pjEmail<\/code>/g, dadosContrato.data.data[0].locador_pjEmail)
                .replace(/<code>@locador telContato<\/code>/g, dadosContrato.data.data[0].locador_telContato)
                .replace(/<code>@locatario tipo<\/code>/g, dadosContrato.data.data[0].locatario_tipo)
                .replace(/<code>@locatario nome<\/code>/g, dadosContrato.data.data[0].locatario_nome)
                .replace(/<code>@locatario sexo<\/code>/g, dadosContrato.data.data[0].locatario_sexo)
                .replace(/<code>@locatario nacionalidade<\/code>/g, dadosContrato.data.data[0].locatario_nacionalidade)
                .replace(/<code>@locatario profissao<\/code>/g, dadosContrato.data.data[0].locatario_profissao)
                .replace(/<code>@locatario cpf<\/code>/g, dadosContrato.data.data[0].locatario_cpf)
                .replace(/<code>@locatario rg<\/code>/g, dadosContrato.data.data[0].locatario_rg)
                .replace(/<code>@locatario orgEmissor<\/code>/g, dadosContrato.data.data[0].locatario_orgEmissor)
                .replace(/<code>@locatario estadoCivil<\/code>/g, dadosContrato.data.data[0].locatario_estadoCivil)
                .replace(/<code>@locatario residencial<\/code>/g, dadosContrato.data.data[0].locatario_residencial)
                .replace(/<code>@locatario celular<\/code>/g, dadosContrato.data.data[0].locatario_celular)
                .replace(/<code>@locatario email<\/code>/g, dadosContrato.data.data[0].locatario_email)
                .replace(/<code>@locatario cep<\/code>/g, dadosContrato.data.data[0].locatario_cep)
                .replace(/<code>@locatario end<\/code>/g, dadosContrato.data.data[0].locatario_end)
                .replace(/<code>@locatario endNum<\/code>/g, dadosContrato.data.data[0].locatario_endNum)
                .replace(/<code>@locatario endComplemento<\/code>/g, dadosContrato.data.data[0].locatario_endComplemento)
                .replace(/<code>@locatario endBairro<\/code>/g, dadosContrato.data.data[0].locatario_endBairro)
                .replace(/<code>@locatario endCidade<\/code>/g, dadosContrato.data.data[0].locatario_endCidade)
                .replace(/<code>@locatario endEstado<\/code>/g, dadosContrato.data.data[0].locatario_endEstado)
                .replace(/<code>@locatario razaoSocial<\/code>/g, dadosContrato.data.data[0].locatario_razaoSocial)
                .replace(/<code>@locatario fantasia<\/code>/g, dadosContrato.data.data[0].locatario_fantasia)
                .replace(/<code>@locatario cnpj<\/code>/g, dadosContrato.data.data[0].locatario_cnpj)
                .replace(/<code>@locatario pjEmail<\/code>/g, dadosContrato.data.data[0].locatario_pjEmail)
                .replace(/<code>@locatario telContato<\/code>/g, dadosContrato.data.data[0].locatario_telContato)

                //Caso tenha pelo menos 1 solidário 
                if(JSON.parse(dadosContrato.data.data[0].dados_solidarios).length >= 1){
                    atualizarContrato = atualizarContrato
                    .replace(/<code>@locatario solidario 1 tipo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].tipo)
                    .replace(/<code>@locatario solidario 1 nome<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].nome)
                    .replace(/<code>@locatario solidario 1 sexo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].sexo)
                    .replace(/<code>@locatario solidario 1 nacionalidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].nacionalidade)
                    .replace(/<code>@locatario solidario 1 profissao<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].profissao)
                    .replace(/<code>@locatario solidario 1 cpf<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].cpf)
                    .replace(/<code>@locatario solidario 1 rg<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].rg)
                    .replace(/<code>@locatario solidario 1 orgEmissor<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].orgEmissor)
                    .replace(/<code>@locatario solidario 1 estadoCivil<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].estadoCivil)
                    .replace(/<code>@locatario solidario 1 residencial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].residencial)
                    .replace(/<code>@locatario solidario 1 celular<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].celular)
                    .replace(/<code>@locatario solidario 1 email<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].email)
                    .replace(/<code>@locatario solidario 1 cep<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].cep)
                    .replace(/<code>@locatario solidario 1 end<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].end)
                    .replace(/<code>@locatario solidario 1 endNum<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endNum)
                    .replace(/<code>@locatario solidario 1 endComplemento<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endComplemento)
                    .replace(/<code>@locatario solidario 1 endBairro<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endBairro)
                    .replace(/<code>@locatario solidario 1 endCidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endCidade)
                    .replace(/<code>@locatario solidario 1 endEstado<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endEstado)
                    .replace(/<code>@locatario solidario 1 razaoSocial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].razaoSocial)
                    .replace(/<code>@locatario solidario 1 fantasia<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].fantasia)
                    .replace(/<code>@locatario solidario 1 cnpj<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].cnpj)
                    .replace(/<code>@locatario solidario 1 pjEmail<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].pjEmail)
                    .replace(/<code>@locatario solidario 1 telContato<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].telContato)
                }

                //Caso tenha pelo menos 2 solidários
                if(JSON.parse(dadosContrato.data.data[0].dados_solidarios).length >= 2){
                    atualizarContrato = atualizarContrato
                    .replace(/<code>@locatario solidario 2 tipo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].tipo)
                    .replace(/<code>@locatario solidario 2 nome<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].nome)
                    .replace(/<code>@locatario solidario 2 sexo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].sexo)
                    .replace(/<code>@locatario solidario 2 nacionalidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].nacionalidade)
                    .replace(/<code>@locatario solidario 2 profissao<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].profissao)
                    .replace(/<code>@locatario solidario 2 cpf<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].cpf)
                    .replace(/<code>@locatario solidario 2 rg<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].rg)
                    .replace(/<code>@locatario solidario 2 orgEmissor<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].orgEmissor)
                    .replace(/<code>@locatario solidario 2 estadoCivil<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].estadoCivil)
                    .replace(/<code>@locatario solidario 2 residencial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].residencial)
                    .replace(/<code>@locatario solidario 2 celular<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].celular)
                    .replace(/<code>@locatario solidario 2 email<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].email)
                    .replace(/<code>@locatario solidario 2 cep<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].cep)
                    .replace(/<code>@locatario solidario 2 end<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].end)
                    .replace(/<code>@locatario solidario 2 endNum<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endNum)
                    .replace(/<code>@locatario solidario 2 endComplemento<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endComplemento)
                    .replace(/<code>@locatario solidario 2 endBairro<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endBairro)
                    .replace(/<code>@locatario solidario 2 endCidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endCidade)
                    .replace(/<code>@locatario solidario 2 endEstado<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endEstado)
                    .replace(/<code>@locatario solidario 2 razaoSocial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].razaoSocial)
                    .replace(/<code>@locatario solidario 2 fantasia<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].fantasia)
                    .replace(/<code>@locatario solidario 2 cnpj<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].cnpj)
                    .replace(/<code>@locatario solidario 2 pjEmail<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].pjEmail)
                    .replace(/<code>@locatario solidario 2 telContato<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].telContato)
                }

                //Caso tenha pelo menos 3 solidários
                if(JSON.parse(dadosContrato.data.data[0].dados_solidarios).length >= 3){
                    atualizarContrato = atualizarContrato
                    .replace(/<code>@locatario solidario 3 tipo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].tipo)
                    .replace(/<code>@locatario solidario 3 nome<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].nome)
                    .replace(/<code>@locatario solidario 3 sexo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].sexo)
                    .replace(/<code>@locatario solidario 3 nacionalidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].nacionalidade)
                    .replace(/<code>@locatario solidario 3 profissao<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].profissao)
                    .replace(/<code>@locatario solidario 3 cpf<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].cpf)
                    .replace(/<code>@locatario solidario 3 rg<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].rg)
                    .replace(/<code>@locatario solidario 3 orgEmissor<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].orgEmissor)
                    .replace(/<code>@locatario solidario 3 estadoCivil<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].estadoCivil)
                    .replace(/<code>@locatario solidario 3 residencial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].residencial)
                    .replace(/<code>@locatario solidario 3 celular<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].celular)
                    .replace(/<code>@locatario solidario 3 email<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].email)
                    .replace(/<code>@locatario solidario 3 cep<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].cep)
                    .replace(/<code>@locatario solidario 3 end<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].end)
                    .replace(/<code>@locatario solidario 3 endNum<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endNum)
                    .replace(/<code>@locatario solidario 3 endComplemento<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endComplemento)
                    .replace(/<code>@locatario solidario 3 endBairro<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endBairro)
                    .replace(/<code>@locatario solidario 3 endCidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endCidade)
                    .replace(/<code>@locatario solidario 3 endEstado<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endEstado)
                    .replace(/<code>@locatario solidario 3 razaoSocial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].razaoSocial)
                    .replace(/<code>@locatario solidario 3 fantasia<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].fantasia)
                    .replace(/<code>@locatario solidario 3 cnpj<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].cnpj)
                    .replace(/<code>@locatario solidario 3 pjEmail<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].pjEmail)
                    .replace(/<code>@locatario solidario 3 telContato<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].telContato)
                }
                
                console.log(atualizarContrato)

                // console.log(dadosContrato.data.data[0].locatario_cpf.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'))
                
                //Irá verificar se o cliente já tem o upload de documentos referente ao que é pedido nessa tela
                var dadoConsulta
                if(dadosContrato.data.data[0].locatario_tipo === 'PF'){
                  dadoConsulta = dadosContrato.data.data[0].locatario_cpf.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4').replace('/', '_')
                }
                else{
                  dadoConsulta = dadosContrato.data.data[0].locatario_cnpj.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5').replace('/', '_')
                }
                // console.log(dadoConsulta)
                api_epar.get( '/cliente/' + dadoConsulta).then((cliente) => {
                  console.log(cliente.data.data[0].id)
                  this.setState({clientePrincipal: cliente.data.data[0].id, renda: cliente.data.data[0].renda_informada, cpf: dadosContrato.data.data[0].locatario_cpf.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')})
                  var doc5Aprovado = false
                  var doc6Aprovado = false
                  var doc7Aprovado = false

                  // // Verifica se tem CONTRATO DE LOCAÇÃO para não exibir o INPUT para UPLOAD novamente
                  // api_epar.get( '/clienteshasdocumentacaodownloadcarta/' + cliente.data.data[0].id + '/5/' + this.props.match.params.id).then((doc) => {
                  //   console.log(doc)
                  //   if(doc.data.data.length > 0){
                  //     this.setState({file1: 'd-none'})
                  //   }
                  //   if(doc.data.data[0].aprovado === 2){
                  //     doc5Aprovado = true
                  //   }
                  //   // this.setState({clientePrincipal: cliente.data.data[0].id})

                  //   // Verifica se tem CONTRATO DE ADMINISTRAÇÃO DO IMÓVEL para não exibir o INPUT para UPLOAD novamente
                  //   api_epar.get( '/clienteshasdocumentacaodownloadcarta/' + cliente.data.data[0].id + '/6/' + this.props.match.params.id).then((doc) => {
                  //     console.log(doc)
                  //     if(doc.data.data.length > 0){
                  //       this.setState({file2: 'd-none'})
                  //     }
                  //     if(doc.data.data[0].aprovado === 2){
                  //       doc6Aprovado = true
                  //     }
                  //     // this.setState({clientePrincipal: cliente.data.data[0].id})

                  //     // Verifica se tem TERMO DE VISTORIA DO IMÓVEL para não exibir o INPUT para UPLOAD novamente
                  //     api_epar.get( '/clienteshasdocumentacaodownloadcarta/' + cliente.data.data[0].id + '/7/' + this.props.match.params.id).then((doc) => {
                  //       console.log(doc)
                  //       if(doc.data.data.length > 0){
                  //         this.setState({file3: 'd-none'})
                  //       }
                  //       if(this.state.file1 === 'd-none' && this.state.file2 === 'd-none' && doc.data.data.length > 0){
                  //         this.setState({divArquivos: 'd-none'})
                  //         if(doc.data.data[0].aprovado === 2){
                  //           doc7Aprovado = true
                  //         }
                  //       }
                  //       if(doc5Aprovado === true && doc6Aprovado === true && doc7Aprovado === true){
                  //         // this.setState({contratoGarantia: false, cursor: 'pointer'})
                  //       }
                  //       // this.setState({clientePrincipal: cliente.data.data[0].id})
                  //     }).catch((error) => {
                  //         console.log(error)
                  //     })
                  //   }).catch((error) => {
                  //       console.log(error)
                  //   })
                  // }).catch((error) => {
                  //     console.log(error)
                  // })
                }).catch((error) => {
                    console.log(error)
                })
                this.setState({pdf: atualizarContrato})
                
            }).catch((error) => {
                console.log(error)
            })

            // Busca o modelo de contrato de GARANTIA
            api_epar.get( '/contrato/2').then((retornoContrato) => {               

              //Substitui as variáveis pelo seu devido valor
                var atualizarContratoGarantia = retornoContrato.data.data[0].contrato
                .replace(/&ldquo;/g, '"')
                .replace(/&rdquo;/g, '"')
                .replace(/&ndash;/g, '-')
                .replace(/<code>@numero contrato<\/code>/g, dadosContrato.data.data[0].numero_contrato)
                .replace(/<code>@data inicio<\/code>/g, new Date(dadosContrato.data.data[0].data_inicio).toLocaleString('pt-BR'))
                .replace(/<code>@data fim<\/code>/g, new Date(dadosContrato.data.data[0].data_fim).toLocaleString('pt-BR'))
                .replace(/<code>@valorAluguel<\/code>/g, dadosContrato.data.data[0].valorAluguel)
                .replace(/<code>@aluguel<\/code>/g, dadosContrato.data.data[0].aluguel)
                .replace(/<code>@condominio<\/code>/g, dadosContrato.data.data[0].condominio)
                .replace(/<code>@iptu<\/code>/g, dadosContrato.data.data[0].iptu)
                .replace(/<code>@agua<\/code>/g, dadosContrato.data.data[0].agua)
                .replace(/<code>@luz<\/code>/g, dadosContrato.data.data[0].luz)
                .replace(/<code>@gas<\/code>/g, dadosContrato.data.data[0].gas)
                .replace(/<code>@diaPag<\/code>/g, dadosContrato.data.data[0].diaPag)
                .replace(/<code>@tipoPag<\/code>/g, dadosContrato.data.data[0].tipoPag)
                .replace(/<code>@periodicidade<\/code>/g, dadosContrato.data.data[0].periodicidade)
                .replace(/<code>@indice<\/code>/g, dadosContrato.data.data[0].indice)
                .replace(/<code>@t1 nome<\/code>/g, dadosContrato.data.data[0].t1_nome)
                .replace(/<code>@t1 cpf<\/code>/g, dadosContrato.data.data[0].t1_cpf)
                .replace(/<code>@t1 telefone<\/code>/g, dadosContrato.data.data[0].t1_telefone)
                .replace(/<code>@t2 nome<\/code>/g, dadosContrato.data.data[0].t2_nome)
                .replace(/<code>@t2 cpf<\/code>/g, dadosContrato.data.data[0].t2_cpf)
                .replace(/<code>@t2 telefone<\/code>/g, dadosContrato.data.data[0].t2_telefone)
                .replace(/<code>@imovel tipo<\/code>/g, dadosContrato.data.data[0].imovel_tipo)
                .replace(/<code>@imovel finalidade<\/code>/g, dadosContrato.data.data[0].imovel_finalidade)
                .replace(/<code>@imovel tipoImovel<\/code>/g, dadosContrato.data.data[0].imovel_tipoImovel)
                .replace(/<code>@imovel cep<\/code>/g, dadosContrato.data.data[0].imovel_cep)
                .replace(/<code>@imovel end<\/code>/g, dadosContrato.data.data[0].imovel_end)
                .replace(/<code>@imovel endNum<\/code>/g, dadosContrato.data.data[0].imovel_endNum)
                .replace(/<code>@imovel endComplemento<\/code>/g, dadosContrato.data.data[0].imovel_endComplemento)
                .replace(/<code>@imovel endBairro<\/code>/g, dadosContrato.data.data[0].imovel_endBairro)
                .replace(/<code>@imovel endCidade<\/code>/g, dadosContrato.data.data[0].imovel_endCidade)
                .replace(/<code>@imovel endEstado<\/code>/g, dadosContrato.data.data[0].imovel_endEstado)
                .replace(/<code>@locador tipo<\/code>/g, dadosContrato.data.data[0].locador_tipo)
                .replace(/<code>@locador nome<\/code>/g, dadosContrato.data.data[0].locador_nome)
                .replace(/<code>@locador sexo<\/code>/g, dadosContrato.data.data[0].locador_sexo)
                .replace(/<code>@locador nacionalidade<\/code>/g, dadosContrato.data.data[0].locador_nacionalidade)
                .replace(/<code>@locador profissao<\/code>/g, dadosContrato.data.data[0].locador_profissao)
                .replace(/<code>@locador cpf<\/code>/g, dadosContrato.data.data[0].locador_cpf)
                .replace(/<code>@locador rg<\/code>/g, dadosContrato.data.data[0].locador_rg)
                .replace(/<code>@locador orgEmissor<\/code>/g, dadosContrato.data.data[0].locador_orgEmissor)
                .replace(/<code>@locador estadoCivil<\/code>/g, dadosContrato.data.data[0].locador_estadoCivil)
                .replace(/<code>@locador residencial<\/code>/g, dadosContrato.data.data[0].locador_residencial)
                .replace(/<code>@locador celular<\/code>/g, dadosContrato.data.data[0].locador_celular)
                .replace(/<code>@locador email<\/code>/g, dadosContrato.data.data[0].locador_email)
                .replace(/<code>@locador cep<\/code>/g, dadosContrato.data.data[0].locador_cep)
                .replace(/<code>@locador end<\/code>/g, dadosContrato.data.data[0].locador_end)
                .replace(/<code>@locador endNum<\/code>/g, dadosContrato.data.data[0].locador_endNum)
                .replace(/<code>@locador endComplemento<\/code>/g, dadosContrato.data.data[0].locador_endComplemento)
                .replace(/<code>@locador endBairro<\/code>/g, dadosContrato.data.data[0].locador_endBairro)
                .replace(/<code>@locador endCidade<\/code>/g, dadosContrato.data.data[0].locador_endCidade)
                .replace(/<code>@locador endEstado<\/code>/g, dadosContrato.data.data[0].locador_endEstado)
                .replace(/<code>@locador razaoSocial<\/code>/g, dadosContrato.data.data[0].locador_razaoSocial)
                .replace(/<code>@locador fantasia<\/code>/g, dadosContrato.data.data[0].locador_fantasia)
                .replace(/<code>@locador cnpj<\/code>/g, dadosContrato.data.data[0].locador_cnpj)
                .replace(/<code>@locador pjEmail<\/code>/g, dadosContrato.data.data[0].locador_pjEmail)
                .replace(/<code>@locador telContato<\/code>/g, dadosContrato.data.data[0].locador_telContato)
                .replace(/<code>@locatario tipo<\/code>/g, dadosContrato.data.data[0].locatario_tipo)
                .replace(/<code>@locatario nome<\/code>/g, dadosContrato.data.data[0].locatario_nome)
                .replace(/<code>@locatario sexo<\/code>/g, dadosContrato.data.data[0].locatario_sexo)
                .replace(/<code>@locatario nacionalidade<\/code>/g, dadosContrato.data.data[0].locatario_nacionalidade)
                .replace(/<code>@locatario profissao<\/code>/g, dadosContrato.data.data[0].locatario_profissao)
                .replace(/<code>@locatario cpf<\/code>/g, dadosContrato.data.data[0].locatario_cpf)
                .replace(/<code>@locatario rg<\/code>/g, dadosContrato.data.data[0].locatario_rg)
                .replace(/<code>@locatario orgEmissor<\/code>/g, dadosContrato.data.data[0].locatario_orgEmissor)
                .replace(/<code>@locatario estadoCivil<\/code>/g, dadosContrato.data.data[0].locatario_estadoCivil)
                .replace(/<code>@locatario residencial<\/code>/g, dadosContrato.data.data[0].locatario_residencial)
                .replace(/<code>@locatario celular<\/code>/g, dadosContrato.data.data[0].locatario_celular)
                .replace(/<code>@locatario email<\/code>/g, dadosContrato.data.data[0].locatario_email)
                .replace(/<code>@locatario cep<\/code>/g, dadosContrato.data.data[0].locatario_cep)
                .replace(/<code>@locatario end<\/code>/g, dadosContrato.data.data[0].locatario_end)
                .replace(/<code>@locatario endNum<\/code>/g, dadosContrato.data.data[0].locatario_endNum)
                .replace(/<code>@locatario endComplemento<\/code>/g, dadosContrato.data.data[0].locatario_endComplemento)
                .replace(/<code>@locatario endBairro<\/code>/g, dadosContrato.data.data[0].locatario_endBairro)
                .replace(/<code>@locatario endCidade<\/code>/g, dadosContrato.data.data[0].locatario_endCidade)
                .replace(/<code>@locatario endEstado<\/code>/g, dadosContrato.data.data[0].locatario_endEstado)
                .replace(/<code>@locatario razaoSocial<\/code>/g, dadosContrato.data.data[0].locatario_razaoSocial)
                .replace(/<code>@locatario fantasia<\/code>/g, dadosContrato.data.data[0].locatario_fantasia)
                .replace(/<code>@locatario cnpj<\/code>/g, dadosContrato.data.data[0].locatario_cnpj)
                .replace(/<code>@locatario pjEmail<\/code>/g, dadosContrato.data.data[0].locatario_pjEmail)
                .replace(/<code>@locatario telContato<\/code>/g, dadosContrato.data.data[0].locatario_telContato)

                //Caso tenha pelo menos 1 solidário
                if(JSON.parse(dadosContrato.data.data[0].dados_solidarios).length >= 1){
                  atualizarContratoGarantia = atualizarContratoGarantia
                    .replace(/<code>@locatario solidario 1 tipo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].tipo)
                    .replace(/<code>@locatario solidario 1 nome<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].nome)
                    .replace(/<code>@locatario solidario 1 sexo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].sexo)
                    .replace(/<code>@locatario solidario 1 nacionalidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].nacionalidade)
                    .replace(/<code>@locatario solidario 1 profissao<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].profissao)
                    .replace(/<code>@locatario solidario 1 cpf<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].cpf)
                    .replace(/<code>@locatario solidario 1 rg<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].rg)
                    .replace(/<code>@locatario solidario 1 orgEmissor<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].orgEmissor)
                    .replace(/<code>@locatario solidario 1 estadoCivil<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].estadoCivil)
                    .replace(/<code>@locatario solidario 1 residencial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].residencial)
                    .replace(/<code>@locatario solidario 1 celular<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].celular)
                    .replace(/<code>@locatario solidario 1 email<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].email)
                    .replace(/<code>@locatario solidario 1 cep<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].cep)
                    .replace(/<code>@locatario solidario 1 end<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].end)
                    .replace(/<code>@locatario solidario 1 endNum<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endNum)
                    .replace(/<code>@locatario solidario 1 endComplemento<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endComplemento)
                    .replace(/<code>@locatario solidario 1 endBairro<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endBairro)
                    .replace(/<code>@locatario solidario 1 endCidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endCidade)
                    .replace(/<code>@locatario solidario 1 endEstado<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endEstado)
                    .replace(/<code>@locatario solidario 1 razaoSocial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].razaoSocial)
                    .replace(/<code>@locatario solidario 1 fantasia<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].fantasia)
                    .replace(/<code>@locatario solidario 1 cnpj<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].cnpj)
                    .replace(/<code>@locatario solidario 1 pjEmail<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].pjEmail)
                    .replace(/<code>@locatario solidario 1 telContato<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].telContato)
                }

                //Caso tenha pelo menos 2 solidários
                if(JSON.parse(dadosContrato.data.data[0].dados_solidarios).length >= 2){
                  atualizarContratoGarantia = atualizarContratoGarantia
                    .replace(/<code>@locatario solidario 2 tipo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].tipo)
                    .replace(/<code>@locatario solidario 2 nome<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].nome)
                    .replace(/<code>@locatario solidario 2 sexo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].sexo)
                    .replace(/<code>@locatario solidario 2 nacionalidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].nacionalidade)
                    .replace(/<code>@locatario solidario 2 profissao<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].profissao)
                    .replace(/<code>@locatario solidario 2 cpf<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].cpf)
                    .replace(/<code>@locatario solidario 2 rg<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].rg)
                    .replace(/<code>@locatario solidario 2 orgEmissor<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].orgEmissor)
                    .replace(/<code>@locatario solidario 2 estadoCivil<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].estadoCivil)
                    .replace(/<code>@locatario solidario 2 residencial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].residencial)
                    .replace(/<code>@locatario solidario 2 celular<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].celular)
                    .replace(/<code>@locatario solidario 2 email<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].email)
                    .replace(/<code>@locatario solidario 2 cep<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].cep)
                    .replace(/<code>@locatario solidario 2 end<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].end)
                    .replace(/<code>@locatario solidario 2 endNum<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[0].endNum)
                    .replace(/<code>@locatario solidario 2 endComplemento<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endComplemento)
                    .replace(/<code>@locatario solidario 2 endBairro<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endBairro)
                    .replace(/<code>@locatario solidario 2 endCidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endCidade)
                    .replace(/<code>@locatario solidario 2 endEstado<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].endEstado)
                    .replace(/<code>@locatario solidario 2 razaoSocial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].razaoSocial)
                    .replace(/<code>@locatario solidario 2 fantasia<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].fantasia)
                    .replace(/<code>@locatario solidario 2 cnpj<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].cnpj)
                    .replace(/<code>@locatario solidario 2 pjEmail<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].pjEmail)
                    .replace(/<code>@locatario solidario 2 telContato<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[1].telContato)
                }

                //Caso tenha pelo menos 3 solidários
                if(JSON.parse(dadosContrato.data.data[0].dados_solidarios).length >= 3){
                  atualizarContratoGarantia = atualizarContratoGarantia
                    .replace(/<code>@locatario solidario 3 tipo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].tipo)
                    .replace(/<code>@locatario solidario 3 nome<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].nome)
                    .replace(/<code>@locatario solidario 3 sexo<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].sexo)
                    .replace(/<code>@locatario solidario 3 nacionalidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].nacionalidade)
                    .replace(/<code>@locatario solidario 3 profissao<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].profissao)
                    .replace(/<code>@locatario solidario 3 cpf<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].cpf)
                    .replace(/<code>@locatario solidario 3 rg<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].rg)
                    .replace(/<code>@locatario solidario 3 orgEmissor<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].orgEmissor)
                    .replace(/<code>@locatario solidario 3 estadoCivil<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].estadoCivil)
                    .replace(/<code>@locatario solidario 3 residencial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].residencial)
                    .replace(/<code>@locatario solidario 3 celular<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].celular)
                    .replace(/<code>@locatario solidario 3 email<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].email)
                    .replace(/<code>@locatario solidario 3 cep<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].cep)
                    .replace(/<code>@locatario solidario 3 end<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].end)
                    .replace(/<code>@locatario solidario 3 endNum<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endNum)
                    .replace(/<code>@locatario solidario 3 endComplemento<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endComplemento)
                    .replace(/<code>@locatario solidario 3 endBairro<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endBairro)
                    .replace(/<code>@locatario solidario 3 endCidade<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endCidade)
                    .replace(/<code>@locatario solidario 3 endEstado<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].endEstado)
                    .replace(/<code>@locatario solidario 3 razaoSocial<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].razaoSocial)
                    .replace(/<code>@locatario solidario 3 fantasia<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].fantasia)
                    .replace(/<code>@locatario solidario 3 cnpj<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].cnpj)
                    .replace(/<code>@locatario solidario 3 pjEmail<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].pjEmail)
                    .replace(/<code>@locatario solidario 3 telContato<\/code>/g, JSON.parse(dadosContrato.data.data[0].dados_solidarios)[2].telContato)
                }
                
                console.log(atualizarContratoGarantia)

                this.setState({pdfGarantia: atualizarContratoGarantia})
                
            }).catch((error) => {
                console.log(error)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    // Realizar o DOWNLOAD do contrato de locação 
    downloadPDF = () => {

      // Atualizar no banco informando que houve o download e o horário do mesmo
    //   api_epar.put( '/consultalocacao', {download: 1, id: this.props.match.params.id, data: moment().format('YYYY-MM-DD HH:mm:ss')}).then((consultaAtualizada) => {
    //       console.log(consultaAtualizada)
    //   }).catch((error) => {
    //       console.log(error)
    //   })

      // Download
      var pdf = new jsPDF('p','pt','a4')
      pdf.fromHTML(
          "<div style='line-height:27px'>" + this.state.pdf + "</div>", 50, 50, {
            width: 500,
          }, () => {
            pdf.save('contrato.pdf')
          }
      )
    }

    // Realizar o DOWNLOAD do contrato de garantia 
    downloadGarantia = () => {

      // Atualizar no banco informando que houve o download e o horário do mesmo
      // api_epar.put( '/consultagarantia', {download: 1, id: this.props.match.params.id, data: moment().format('YYYY-MM-DD HH:mm:ss')}).then((consultaAtualizada) => {
      //     console.log(consultaAtualizada)
      // }).catch((error) => {
      //     console.log(error)
      // })

      this.props.history.push('/downloadgarantia/' + this.props.match.params.id)

      // Download
      // var pdf = new jsPDF('p','pt','a4')
      // pdf.fromHTML(
      //     "<div style='line-height:27px'>" + this.state.pdfGarantia + "</div>", 50, 50, {
      //       width: 500,
      //     }, () => {
      //       pdf.save('contratoGarantia.pdf')
      //     }
      // )
    }

    toggleLoad = () => {
      this.setState({load: !this.state.load})
    }


  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />

        <Container className="mt-5 pl-5 tamanhoDownloadContrato" fluid>
          <Row className='mb-5' style={{fontSize: '1.2em'}}>
            {this.state.data_inicio !== '' && new Date(this.state.data_inicio).toLocaleString({day: 'numeric', month: 'long', year: 'numeric'})} 
          </Row>
          <Row className={'mb-5 mr-3 bg-white'} style={{border: '1px solid  #d9d9d9', borderRadius: '5px'}}>
              <Col md={4} className='d-flex justify-content-center'>
                  <i className={"fas fa-laugh-wink text-black p-3 rounded-circle text-center"} style={{margin: '4vh', fontSize: '6em', width: '130px', height: '130px'}}/>
              </Col>
              <Col md={8} className='pt-4'>
                  <div className='' style={{fontSize: '1.3em'}}>
                      <strong>ESTAMOS QUASE LÁ</strong>
                  </div><br/>
                  <div className='mb-2 float-left text-justify pr-2' style={{fontSize: '0.9em'}}>
                    Agora você só precisa enviar os arquivos necessários para finalizar seu contrato.
                    Após validação dos documentos, os contratos de locação e contrato de garantia estarão disponíveis para download!
                  </div><br/>
              </Col>
          </Row>

          <div className={this.state.divArquivos}>
            <div className={this.state.file1}>
              <Row>
                <Label style={{color: 'black', fontWeight: 'bolder'}} className='ml-3'>CONTRATO DE LOCAÇÃO:</Label>
              </Row>
              <Row>
                <Col md={12}>
                  <InputGroup className="input-group-alternative mb-5 mt-2">
                    <Input type='file' onChange={this.onChangeFile1} accept="application/pdf,image/x-png,image/jpg,image/jpeg"></Input>
                  </InputGroup>
                </Col>
                {/* <Col md={4}>
                  <Button onClick={this.upload1} className='float-right mb-5 btn btn-dark'>Upload</Button>
                </Col> */}
              </Row>
            </div>

            <div className={this.state.file2}>
              <Row>
                <Label style={{color: 'black', fontWeight: 'bolder'}} className='ml-3'>CONTRATO DE ADMINISTRAÇÃO DO IMÓVEL:</Label>
              </Row>
              <Row>
                <Col md={12}>
                  <InputGroup className="input-group-alternative mb-5 mt-2">
                    <Input type='file' onChange={this.onChangeFile2} accept="application/pdf,image/x-png,image/jpg,image/jpeg"></Input>
                  </InputGroup>
                </Col>
                {/* <Col md={4}>
                  <Button onClick={this.upload2} className='float-right mb-5 btn btn-dark'>Upload</Button>
                </Col> */}
              </Row>
            </div>

            <div className={this.state.file3}>
              <Row>
                <Label style={{color: 'black', fontWeight: 'bolder'}} className='ml-3'>TERMO DE VISTORIA DO IMÓVEL:</Label>
              </Row>
              <Row>
                <Col md={12}>
                  <InputGroup className="input-group-alternative mb-5 mt-2">
                    <Input type='file' onChange={this.onChangeFile3} accept="application/pdf,image/x-png,image/jpg,image/jpeg"></Input>
                  </InputGroup>
                </Col>
                {/* <Col md={4}>
                  <Button onClick={this.upload3} className='float-right mb-5 btn btn-dark'>Upload</Button>
                </Col> */}
              </Row>
            </div>
            <Button onClick={this.uploadArquivos} className='float-right mb-5 btn btn-dark'>ANEXAR DOCUMENTOS</Button>
          </div>


          {/* <Row className={'mb-2 mr-3'}>
            <UploadDoc arquivo='DOCUMENTO DE IDENTIFICAÇÃO (RG OU CNH):' />
          </Row>

          <Row className={'mb-2 mr-3'}>
            <UploadDoc arquivo='CONTRATO DE ADMINISTRAÇÃO DO IMÓVEL:' />
          </Row>

          <Row className={'mb-2 mr-3'}>
            <UploadDoc arquivo='TERMO DE VISTORIA DO IMÓVEL:' />
          </Row> */}

          <Row className={'d-flex mb-5 justify-content-center w-100 p-3 bg-white'} style={{border: '1px solid  #d9d9d9', borderRadius: '6px'}}>
            {/* <Col md={6}> */}
              <Button className={FuncoesComum.solida() + ' m-2'} onClick={this.downloadPDF}>
                <Label style={{fontSize: '1em', marginBottom: '0px', cursor: 'pointer'}}>Baixar</Label><br/>
                <Label style={{fontSize: '1.5em', marginBottom: '0px', cursor: 'pointer'}}>Contrato de locação</Label>
              </Button>
            {/* </Col> */}
            {/* <Col md={6}> */}
              <Button className={'btn-success m-2'} onClick={this.downloadGarantia} style={{cursor: 'pointer'}}>
                <Label style={{fontSize: '1em', marginBottom: '0px', cursor: 'pointer'}}>Baixar</Label><br/>
                <Label style={{fontSize: '1.5em', marginBottom: '0px', cursor: 'pointer'}}>Contrato de garantia</Label>
              </Button>
            {/* </Col> */}
          </Row>
          <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
              <ModalHeader toggle={this.toggleAlert}>Mensagem</ModalHeader>
              <ModalBody>
                  <Row>
                      <Col md={12}>
                          {this.state.mensagemModal}
                      </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                  <Button className="btn-dark" onClick={this.toggleAlert}>Fechar</Button>
              </ModalFooter>
          </Modal>

          <Modal className='d-flex justify-content-center align-items-center' isOpen={this.state.load} >
              <ModalHeader>AGUARDE...</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} className='d-flex justify-content-center align-items-center'>
                          <img src={require('assets/img/loading.gif')} className='p-5 w-50 h-100'/>
                        </Col>
                    </Row>
                </ModalBody>
          </Modal>
        </Container>
      </>
    );
  }
}

export default DownloadContratoCarta;
