/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    Input,
    Table,
    Container,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'


class TelaAprovacao extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            mensagem: ''
        }
    }

    componentDidMount(){
      console.log(api_request + '/clienteshasdocumentacaocliente/' + new URLSearchParams(this.props.location.search).get('cliente') + '/' + new URLSearchParams(this.props.location.search).get('path').replace(/\//g, '|') + '/' + new URLSearchParams(this.props.location.search).get('documentacao'))
        api_epar.get( '/clienteshasdocumentacaocliente/' + new URLSearchParams(this.props.location.search).get('cliente') + '/' + new URLSearchParams(this.props.location.search).get('path').replace(/\//g, '|') + '/' + new URLSearchParams(this.props.location.search).get('documentacao')).then((resposta) => {
          if(resposta.data.data.length > 0){
            if(resposta.data.data[0].aprovado !== 1){
              this.setState({mensagem: 'ESSE DOCUMENTO JÁ FOI ANALISADO'})
            }
            else{
              api_epar.get( '/docatualizar/' + new URLSearchParams(this.props.location.search).get('cliente') + '/' + new URLSearchParams(this.props.location.search).get('documentacao') + '/' + new URLSearchParams(this.props.location.search).get('path').replace(/\//g, '|') + '/' + new URLSearchParams(this.props.location.search).get('opcao')).then((update) => {
                console.log(update.data.data)
                this.setState({mensagem: 'ANÁLISE REALIZADA COM SUCESSO!!'})
              }).catch((error) => {
                console.log(error)
              })
            }
          }
          else{
            this.setState({mensagem: 'NÃO EXISTE ESSE DOCUMENTO'})
          }
        }).catch((error) => {
            console.log(error)
        })
    }


  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Container className='d-flex justify-content-center align-items-center w-100' style={{height: '100vh'}}>
            <div className={'p-5 bg-white'} style={{border: '1px solid  #d9d9d9', borderRadius: '6px'}}>
                {this.state.mensagem}
            </div>
        </Container>
      </>
    );
  }
}

export default TelaAprovacao;
