/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios";
import sha1 from "sha1";

// reactstrap components
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody, 
  ModalFooter
} from "reactstrap";

import api_admin from '../services/apiadmin'
import ReactGA from 'react-ga'
import Header from "components/Headers/Header.jsx";

class Atualizacoes extends React.Component {

  constructor(props){
    super(props)
    this.state ={

    }

  }

    componentDidMount(){
       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // axios.post('https://apieparteste.anvali.com.br:3333/dadoscontratocartagarantiadeletecontrato', {
      //   dados_contrato_id: 32
      // }).then((result) => {
      //     console.log(JSON.stringify(result))
      // })
    }

  
  render() {
    return (
      <>
        <Header />
        <Container className="mt-5 pb-8" style={{minHeight: '65vh'}}>
            <h1>Últimas atualizações do sistema:</h1>
            <ul>
                27/04/2020
                <li className='mt-2 mb-4'>1. Informar quais foram as últimas alterações no Alug+.</li>
                31/03/2020
                <li className='mt-2 mb-4'>1. "Aguarde..." no envio de documentos do contrato que é o último passo de uma consulta.</li>
                27/03/2020
                <li className='mt-2 mb-4'>1. Quando solicitado reenvio de arquivos é exibido no relatório e no detalhe da consulta o motivo para reenvio.</li>
                25/03/2020
                <li className='mt-2'>1. Qual seria a finalidade de locação, comercial ou residencial;</li>
                <li>2. Informativo do que seria a renda líquida quando solicitado ao cliente a sua renda;</li>
                <li>3. Adicionar um novo usuário para acessar o Alug+;</li>
                <li className='mb-4'>4. Dados da imobiliária no perfil.</li>
            </ul>
        </Container>
      </>
    );
  }
}

export default Atualizacoes;
