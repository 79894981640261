/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Chart from "chart.js";
import {
    Container,    
    Label
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import FuncoesComum from '../funcoes/FuncoesComum';
import $ from 'jquery'
import "assets/scss/relatorios.scss";
import {Link} from 'react-router-dom'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from "react-ga";
import moment from "moment";

class CartaGarantia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           cartas: []
        }
    }

    componentDidMount(){

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // Verifica se o usuário tem acesso
      axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
        console.log(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }))

        if(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }).length === 0){
          this.props.history.push('/admin/logout')
        }
      })

      // Busca as consultas realizadas pelo o usuário logado
      api_epar.get( '/cartagarantia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id, {headers: {"x-access-token": localStorage.getItem('autenticado_test')}}).then((cartas) => {
          if(cartas.data.data !== 'TOKEN inexistente' && cartas.data.data !== 'TOKEN inválido'){
            this.setState({cartas: cartas.data.data})
          }
          else{
            alert(cartas.data.data)
          }
          console.log(cartas.data.data)
          $(document).ready(() => {
            $('#dt_table').DataTable({
              order: [[0, 'desc']]
            })
          })
      }).catch((error) => {
          console.log(error)
      })
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  // Irá exibir cada carta
  rowsCartas = (record) => {

      var cor

      if(moment().format('YYYY-MM-DD') > moment(record.data_validade).format('YYYY-MM-DD')){
        cor = '#ff6666'
      }
      else{
        if(moment().format('YYYY-MM-DD') < moment(record.data_validade).subtract(7, 'days').format('YYYY-MM-DD')){
          cor = '#00e600'
        }
        else{
          cor = '#e6e600'
        }
      }

      return(
        <tr key={record.consulta_id} >
          <th>
            {record.consulta_id}
          </th>
          <th>
            {record.codigo}
          </th>
          <th>
            {record.retorno_json && JSON.parse(record.retorno_json) && JSON.parse(record.retorno_json).content.nome.conteudo.nome}
            {!record.retorno_json && 'Não encontrado'} <br />
            <span className="mt-1">({record.cpf_cnpj})</span>
          </th>
          <td>
            {FuncoesComum.formatarMoeda(parseFloat(record.renda_informada))}
          </td>
          <td>
            {FuncoesComum.formatarMoeda(parseFloat(record.valor_carta))}
          </td>
          <td>
            {new Date(record.data_emissao).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}
          </td>
          <td>
            <label className='p-1' style={{backgroundColor: cor, color: 'white', borderRadius: '3px'}}>{new Date(record.data_validade).toLocaleString('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'})}</label>
          </td>
          <td>
            {
              record.contrato_id != null ? (
                "Contrato gerado"
              ) : cor === "#ff6666" ? (
                <span style={{color: cor}}>Vencidas</span>
              ) : (
                "Válidas"
              )
            }
          </td>
          <td>
            <Label style={{border: '1px solid #d9d9d9', borderRadius: '5px', color: 'black', backgroundColor: 'white'}} className='float-right p-1' to={'/admin/detalhecarta/' + record.codigo} tag={Link}>Ver resultado</Label>
          </td>
          <td>
            {record.contrato_id == null && cor != "#ff6666" && <Label style={{border: '1px solid #d9d9d9', borderRadius: '5px', color: 'white', backgroundColor: '#2dce89'}} className='text-center p-1' to={'/admin/formcontratocarta/' + record.codigo} tag={Link}>Gerar contrato</Label>}
          </td>
        </tr>
      )
  }

  render() {
    return (
      <>
        {/* <script src="https://cdn.datatables.net/1.10.20/js/dataTables.bootstrap4.min.js"></script> */}
        {/* <script src="https://cdn.datatables.net/1.10.20/js/jquery.dataTables.min.js"></script> */}
        {/* <script src="https://code.jquery.com/jquery-3.3.1.js"></script> */}
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.css"></link> */}
        <link rel="stylesheet" href="https://cdn.datatables.net/1.10.20/css/dataTables.bootstrap4.min.css"></link>
        <Header style={{height: '10vh'}} />

        <Container className={"mt-5 d-flex justify-content-center"} fluid style={{minHeight: '65vh'}}>
          <table id="dt_table" className="table table-responsive table-striped" style={{width: "100%"}}>
              <thead >
                  <tr >
                      <th >Consulta</th>
                      <th >Código</th>
                      <th >Nome e CPF</th>
                      <th >Renda informada</th>
                      <th >Valor da carta</th>
                      <th >Emissão</th>
                      <th >Validade</th>
                      <th >Status</th>
                      <th >Resultado</th>
                      <th >Gerar contrato</th>
                  </tr>
              </thead>
              <tbody>
                {this.state.cartas.map(this.rowsCartas)}
              </tbody>
              {/* <tfoot>
                  <tr>
                      <th >CONSULTA</th>
                      <th >VALOR</th>
                      <th >LOCATÁRIO PRINCIPAL</th>
                      <th >LOCATÁRIO(S) SOLIDÁRIO(S)</th>
                      <th >DATA DA CONSULTA</th>
                      <th >STATUS</th>
                      <th >RESULTADO</th>
                  </tr>
              </tfoot> */}
          </table>
        </Container>
      </>
    );
  }
}

export default CartaGarantia;
