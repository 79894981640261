/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Nav, 
    NavItem, 
    NavLink,
    TabPane,
    TabContent,
    Label,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum';
import InputMask from 'react-input-mask'
import $ from 'jquery'
import DataTable from 'datatables.net'
import "assets/css/formulario.css";
import moment from "moment";
import {Link} from 'react-router-dom'
import CurrencyInput from 'react-currency-input'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from 'react-ga'

class EnviarContratos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           contratos: [],
           data_inicio: moment().startOf('month').format('YYYY-MM-DD'),
           data_fim: moment().endOf('month').format('YYYY-MM-DD')
        }
    }


    componentDidMount(){

        // Verifica se o usuário tem acesso
        axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
            console.log(retorno.data.imobs.filter((e) => {
                return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            }))

            if(retorno.data.imobs.filter((e) => {
                return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            }).length === 0  || retorno.data.moba.filter((i) => {
                return i.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id && i.bloqueado === 0
              }).length > 0){
                this.props.history.push('/admin/logout')
            }
        })
        axios.post(api_admin + '/imobiliaria/imobuser/permis', {id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id}).then((retorno) => {
            if(retorno.data.per.filter((e) => {
                return e.sistema_id === 2 && e.permitido === 0
            }).length > 0 ){
                this.props.history.push('/admin/logout')
            }
        })

        console.log(window.location.host)

         // Analytics
        ReactGA.initialize(process.env.REACT_APP_UA)
        ReactGA.pageview(window.location.pathname + window.location.search)

        this.contratos(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'))

    }

    contratos = async (data_inicio, data_fim) => {
      api_epar.get('/dadoscontrato').then((contratos) => {
        this.setState({contratos: contratos.data.data.filter((c) => {
          return moment(c.data_inicio) >= moment(data_inicio) && moment(c.data_inicio) <= moment(data_fim)
        })})
      })
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <div className='p-2'>
          Data inicial:<br/> <input type='date' style={{width: '150px'}} defaultValue={moment().startOf('month').format('YYYY-MM-DD')} 
              onChange={(event) => {
                  this.setState({data_inicio: event.target.value})
              }} /><br/>
        </div>  
        <div className='p-2'>    
          Data final:<br/> <input style={{width: '150px'}} type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} 
                onChange={(event) => {
                    this.setState({data_fim: event.target.value})
                }} 
            /><br/>
        </div>
        <div className='p-2'>
          <button className='btn btn-success' style={{width: '150px'}} onClick={() => {
            this.contratos(this.state.data_inicio, this.state.data_fim)
          }}>Filtrar</button>
        </div>
        <table className='table table-stripped'>
          <tr>
            <th>Contrato</th>
            <th>Data início</th>
            <th>Data fim</th>
            <th>CPF/CNPJ</th>
            <th>Locatário</th>
            <th>Enviar</th>
          </tr>
        {this.state.contratos.map((c) => {
          return (
            <tr>
              <td>{c.id}</td> 
              <td>{moment(c.data_inicio).format('DD/MM/YYYY')}</td> 
              <td>{moment(c.data_fim).format('DD/MM/YYYY')}</td> 
              <td>{c.locatario_tipo === 'PF' ? c.locatario_cpf : c.locatario_cnpj}</td> 
              <td>{c.locatario_tipo === 'PF' ? c.locatario_nome : c.locatario_razaoSocial}</td> 
              <td><button style={{borderRadius: '3px', border: '1px solid', backgroundColor: 'white', width: '100px', padding: '2px'}} onClick={() => {
                window.open(`/downloadgarantia/${c.id}?reenviar=sim`, '_blank')
              }}>Enviar</button></td> 
            </tr>
          )
        })}
        </table>
            
      </>
    );
  }
}

export default EnviarContratos;
