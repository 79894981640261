/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from "../funcoes/FuncoesComum";

import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'

class Contrato extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            descricaoContrato: '',
            contratoPadrao: '',
            alert: false,
            mensagemModal: ''
        }

        if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
          props.history.push('/admin/finalidadelocacao')
        }
    }

    changeDescricao = (evento) => {
      this.setState({ descricaoContrato: evento.target.value} )
    }

    toggleAlert = () => {
      this.setState({alert: !this.state.alert})
    }

    // Cadastra o modelo de contrato criado
    cadastrar = () => {
      if(this.state.descricaoContrato !== ''){
        api_epar.get( '/contrato/desc/' + this.state.descricaoContrato + '/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((retornoDescricao) => {
          if(retornoDescricao.data.data.length === 0){
            api_epar.post( '/contrato', {descricao: this.state.descricaoContrato, contrato: this.state.contratoPadrao, imobiliaria_id: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id}).then((retornoInsert) => {
                console.log(retornoInsert.data.data)
                this.setState({mensagemModal: <div>
                                                Contrato cadastrado com sucesso <br/>
                                              </div>})
                this.toggleAlert()
            }).catch((error) => {
                console.log(error)
            })
          }
          else{
            console.log('Já cadastrado')
            this.setState({mensagemModal: <div>
                                            Contrato já cadastrado <br/>
                                          </div>})
            this.toggleAlert()
          }
        }).catch((error) => {
            console.log(error)
        })
      }
      else{
        console.log('Sem descrição')
        // alert('Sem descrição')
        this.setState({mensagemModal: <div>
                                        Sem descrição para o contrato <br/>
                                      </div>})
        this.toggleAlert()
      }
      // console.log(this.state.descricaoContrato)
      // console.log(this.state.contratoPadrao)
    }


  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />

        <Container className="mt-5 pb-8" fluid style={{minHeight: '65vh'}}>

            <InputGroup className="input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="ni ni-bullet-list-67" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input style={{color: 'black'}} type='text' value={this.state.descricaoContrato} onChange={this.changeDescricao} placeholder='Descrição do contrato'></Input>
            </InputGroup>
            <div className='mt-4'>

              {/* Biblioteca para edição de texto | Intuito é montar um modelo de contrato */}
              <Editor
                apiKey='0ru2y66aocmef191wyzppdto1j5o4xeerj331tksrvbopnqz'
                initialValue='<p>Monte o modelo de contrato</p>'
                init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                    'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                    'save table directionality emoticons template paste'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic link backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent table | searchreplace removeformat | \
                    image | preview print | variable | fullscreen',
                  setup: (editor) => {
                    editor.ui.registry.addMenuButton('variable', {
                      text: 'Adicionar variável/campo',
                      fetch: function (callback) {
                        var items = [
                          { type: 'menuitem', text: 'Número do contrato',
                            onAction: function () {
                              editor.insertContent(' <code>@numero contrato</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Data de início',
                            onAction: function () {
                              editor.insertContent(' <code>@data inicio</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Data de término',
                            onAction: function () {
                              editor.insertContent(' <code>@data fim</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Tipo de pessoa',
                            onAction: function () {
                              editor.insertContent(' <code>@locador tipo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Nome completo',
                            onAction: function () {
                              editor.insertContent(' <code>@locador nome</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Sexo',
                            onAction: function () {
                              editor.insertContent(' <code>@locador sexo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Nacionalidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locador nacionalidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Profissão',
                            onAction: function () {
                              editor.insertContent(' <code>@locador profissao</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - CPF',
                            onAction: function () {
                              editor.insertContent(' <code>@locador cpf</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - RG',
                            onAction: function () {
                              editor.insertContent(' <code>@locador rg</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Orgão emissor',
                            onAction: function () {
                              editor.insertContent(' <code>@locador orgEmissor</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Estado cívil',
                            onAction: function () {
                              editor.insertContent(' <code>@locador estadoCivil</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Telefone residencial',
                            onAction: function () {
                              editor.insertContent(' <code>@locador residencial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Telefone celular',
                            onAction: function () {
                              editor.insertContent(' <code>@locador celular</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locador email</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - CEP',
                            onAction: function () {
                              editor.insertContent(' <code>@locador cep</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Endereço',
                            onAction: function () {
                              editor.insertContent(' <code>@locador end</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Número',
                            onAction: function () {
                              editor.insertContent(' <code>@locador endNum</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Complemento',
                            onAction: function () {
                              editor.insertContent(' <code>@locador endComplemento</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Bairro',
                            onAction: function () {
                              editor.insertContent(' <code>@locador endBairro</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Cidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locador endCidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Estado',
                            onAction: function () {
                              editor.insertContent(' <code>@locador endEstado</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Razão Social',
                            onAction: function () {
                              editor.insertContent(' <code>@locador razaoSocial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Nome fantasia',
                            onAction: function () {
                              editor.insertContent(' <code>@locador fantasia</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - CNPJ',
                            onAction: function () {
                              editor.insertContent(' <code>@locador cnpj</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - PJ Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locador pjEmail</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locador - Telefone de contato',
                            onAction: function () {
                              editor.insertContent(' <code>@locador telContato</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Tipo de pessoa',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario tipo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Nome completo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario nome</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Sexo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario sexo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Nacionalidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario nacionalidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Profissão',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario profissao</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - CPF',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario cpf</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - RG',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario rg</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Orgão emissor',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario orgEmissor</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Estado cívil',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario estadoCivil</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Telefone residencial',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario residencial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Telefone celular',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario celular</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario email</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - CEP',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario cep</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Endereço',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario end</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Número',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario endNum</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Complemento',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario endComplemento</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Bairro',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario endBairro</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Cidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario endCidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Estado',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario endEstado</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Razão Social',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario razaoSocial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Nome fantasia',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario fantasia</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - CNPJ',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario cnpj</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - PJ Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario pjEmail</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário - Telefone de contato',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario telContato</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Tipo de pessoa',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 tipo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Nome completo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 nome</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Sexo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 sexo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Nacionalidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 nacionalidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Profissão',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 profissao</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - CPF',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 cpf</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - RG',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 rg</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Orgão emissor',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 orgEmissor</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Estado cívil',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 estadoCivil</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Telefone residencial',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 residencial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Telefone celular',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 celular</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 email</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - CEP',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 cep</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Endereço',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 end</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Número',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 endNum</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Complemento',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 endComplemento</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Bairro',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 endBairro</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Cidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 endCidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Estado',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 endEstado</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Razão Social',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 razaoSocial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Nome fantasia',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 fantasia</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - CNPJ',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 cnpj</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - PJ Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 pjEmail</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 1 - Telefone de contato',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 1 telContato</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Tipo de pessoa',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 tipo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Nome completo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 nome</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Sexo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 sexo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Nacionalidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 nacionalidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Profissão',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 profissao</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - CPF',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 cpf</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - RG',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 rg</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Orgão emissor',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 orgEmissor</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Estado cívil',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 estadoCivil</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Telefone residencial',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 residencial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Telefone celular',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 celular</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 email</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - CEP',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 cep</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Endereço',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 end</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Número',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 endNum</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Complemento',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 endComplemento</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Bairro',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 endBairro</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Cidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 endCidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Estado',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 endEstado</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Razão Social',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 razaoSocial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Nome fantasia',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 fantasia</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - CNPJ',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 cnpj</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - PJ Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 pjEmail</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 2 - Telefone de contato',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 2 telContato</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Tipo de pessoa',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 tipo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Nome completo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 nome</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Sexo',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 sexo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Nacionalidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 nacionalidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Profissão',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 profissao</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - CPF',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 cpf</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - RG',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 rg</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Orgão emissor',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 orgEmissor</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Estado cívil',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 estadoCivil</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Telefone residencial',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 residencial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Telefone celular',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 celular</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 email</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - CEP',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 cep</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Endereço',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 end</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Número',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 endNum</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Complemento',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 endComplemento</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Bairro',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 endBairro</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Cidade',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 endCidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Estado',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 endEstado</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Razão Social',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 razaoSocial</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Nome fantasia',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 fantasia</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - CNPJ',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 cnpj</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - PJ Email',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 pjEmail</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Locatário Solidário 3 - Telefone de contato',
                            onAction: function () {
                              editor.insertContent(' <code>@locatario solidario 3 telContato</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Tipo',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel tipo</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Finalidade',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel finalidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Tipo de imóvel',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel tipoImovel</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - CEP',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel cep</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Endereço',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel end</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Número',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel endNum</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Complemento',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel endComplemento</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Bairro',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel endBairro</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Cidade',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel endCidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Imóvel - Estado',
                            onAction: function () {
                              editor.insertContent(' <code>@imovel endEstado</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Valor do aluguel',
                            onAction: function () {
                              editor.insertContent(' <code>@valorAluguel</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Dia do pagamento do aluguel',
                            onAction: function () {
                              editor.insertContent(' <code>@diaPag</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Tipo do pagamento do aluguel',
                            onAction: function () {
                              editor.insertContent(' <code>@tipoPag</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Periodicidade do reajuste',
                            onAction: function () {
                              editor.insertContent(' <code>@periodicidade</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Índice de reajuste',
                            onAction: function () {
                              editor.insertContent(' <code>@indice</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Testemunha 1 - Nome completo',
                            onAction: function () {
                              editor.insertContent(' <code>@t1 nome</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Testemunha 1 - CPF',
                            onAction: function () {
                              editor.insertContent(' <code>@t1 cpf</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Testemunha 1 - Telefone',
                            onAction: function () {
                              editor.insertContent(' <code>@t1 telefone</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Testemunha 2 - Nome completo',
                            onAction: function () {
                              editor.insertContent(' <code>@t2 nome</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Testemunha 2 - CPF',
                            onAction: function () {
                              editor.insertContent(' <code>@t2 cpf</code> ');
                            }
                          },
                          { type: 'menuitem', text: 'Testemunha 2 - Telefone',
                            onAction: function () {
                              editor.insertContent(' <code>@t2 telefone</code> ');
                            }
                          },
                        ];
                        callback(items);
                      }
                    });
                  }
                }}
                onInit={ (event) => {
                  // this.setState({ contratoPadrao: editor.getData()} )
                  this.setState({ contratoPadrao: event.target.getContent()} )
                } }
                onChange={(event)=> {
                  this.setState({ contratoPadrao: event.target.getContent()} )
                }}
              />
            </div>
            <Button className={'btn float-right mt-5 ' + FuncoesComum.solida()} onClick={this.cadastrar}>Criar modelo de contrato</Button>
            <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
                <ModalHeader toggle={this.toggleAlert}>Mensagem</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            {this.state.mensagemModal}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-dark" onClick={this.toggleAlert}>Fechar</Button>
                </ModalFooter>
            </Modal>
        </Container>
      </>
    );
  }
}

export default Contrato;
