/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Nav, 
    NavItem, 
    NavLink,
    TabPane,
    TabContent,
    Label,
    FormGroup
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum';
import InputMask from 'react-input-mask'
import $ from 'jquery'
import DataTable from 'datatables.net'
import "assets/scss/relatorios.scss";
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'


// $(document).ready(() => {
//   $('#dt_table').DataTable()
// })
class NewTarifacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           tarifacoes: []
        }
        if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
          props.history.push('/admin/finalidadelocacao')
      }
    }

    componentDidMount(){
      // Buscará todas as tarifações registradas
      api_epar.get( '/tarifacao/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((tarifacoes) => {
        console.log(tarifacoes.data.data)
        this.setState({tarifacoes: tarifacoes.data.data})
        $(document).ready(() => {
          $('#dt_table').DataTable()
        })
      }).catch((error) => {
          console.log(error)
      })
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  // Carrega as linhas da tabela para cada tarifação
rowsTarifacoes = (record) => {
      return(
          <tr key={record.id} >
            <th scope="row">
              {record.consult_id}
            </th>
            <th scope="row">
              {record.cpf_cnpj}
            </th>
            <td>
              {record.tipo_locatario}
            </td>
            <td>
              {record.descricao}
            </td>
            <td>
              {FuncoesComum.formatarMoeda(record.valor)}
            </td>
            <td>
              {record.tipo}
            </td>
            <td>
              {new Date(record.data).toLocaleString('pt-BR')}
            </td>
          </tr>
      )
  
}

  render() {
    return (
      <>
        <link rel="stylesheet" href="https://cdn.datatables.net/1.10.20/css/dataTables.bootstrap4.min.css"></link>
        <Header />

        <Container className={"mt-5 d-flex justify-content-center"} fluid style={{minHeight: '65vh'}}>
          <table id="dt_table" className="table table-responsive table-striped" style={{width: "100%"}}>
              <thead>
                  <tr>
                      <th >Consulta</th>
                      <th >CPF / CNPJ</th>
                      <th >Locatário</th>
                      <th >Descrição</th>
                      <th >Valor</th>
                      <th >Tipo</th>
                      <th >Data</th> 
                  </tr>
              </thead>
              <tbody>
                {this.state.tarifacoes.map(this.rowsTarifacoes)}
              </tbody>
              {/* <tfoot>
                  <tr>
                      <th >CONSULTA</th>
                      <th >CPF / CNPJ</th>
                      <th >LOCADOR</th>
                      <th >DESCRIÇÃO</th>
                      <th >VALOR</th>
                      <th >TIPO</th>
                      <th >DATA</th>
                  </tr>
              </tfoot> */}
          </table>
        </Container>
      </>
    );
  }
}

export default NewTarifacao;
