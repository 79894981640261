import React from "react";
import Chart from "chart.js";

import {
    Row,
    Input,
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter
} from "reactstrap";

import {
  chartOptions,
  parseOptions
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import FuncoesComum from '../funcoes/FuncoesComum';
import "assets/css/formulario.css";
import moment from "moment";
import {Link} from 'react-router-dom'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from 'react-ga'
import InputMask from 'react-input-mask'


class FormularioContratoCarta extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           valido: '2',
           retornoConsulta: [],
           pessoas: [],
           solidarios: [],
           existe: 0,
           mensagemModal: '',
           alert: false,
           dadosContrato: [],
           usuario: JSON.parse(localStorage.getItem('dadosUsuario_test')).id,
           cartasAdicionadas: [],
           preAdicionadas: [],
           idContrato: '',
           check: false,
           taxaMensal: '',
           log: '',
           imobiliaria: [],

           cartas: [],
           cartaSelected: '',
           cpf: '',

           mask_tel: '(99) 99999-9999',

           ind_cadastral: '',
           cod_energia: '',
           cod_agua: '',
           nome_adm: '',
           tel_adm: '',
           nome_sidico: '',
           tel_sidigo: '',
        }
    }


    componentDidMount(){
        console.log(window.location.host)

         // Analytics
        ReactGA.initialize(process.env.REACT_APP_UA)
        ReactGA.pageview(window.location.pathname + window.location.search)

        document.getElementsByName('locadorPJ')[0].classList.add('d-none')
        document.getElementsByName('locadorPF')[0].classList.add('d-print-block')
        document.getElementsByName('locatarioPJ')[0].classList.add('d-none')
        document.getElementsByName('locatarioPF')[0].classList.add('d-print-block')

        axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((retorno) => {
            var log = retorno.data + 'data=' + moment().format('DD/MM/YYYY HH:mm:ss')
            this.setState({log: log})
        })

        axios.post(api_admin + '/imobiliaria/getid', {id: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id}).then((retornoImob) => {
            // console.log(retornoImob)

            this.setState({taxaMensal: parseFloat(retornoImob.data.data[0].taxa_mensal), imobiliaria: retornoImob.data.data})
        })

        api_epar.get( '/cartagarantiacodigo/' + this.props.match.params.carta).then((cartasRelacionadas) => {
            if(moment().format('YYYY-MM-DD') > moment(cartasRelacionadas.data.data[0].data_validade).format('YYYY-MM-DD')){
                this.props.history.push('/admin/cartagarantia')
            }
            console.log(cartasRelacionadas.data.data)
            this.setState({cartasAdicionadas: cartasRelacionadas.data.data})

                api_epar.get( '/clienteshasconsultadetalhe/' + cartasRelacionadas.data.data[0].consulta_id).then((retorno) => {
                    console.log(retorno)

                    // Formatar o retorno
                    var dicionario = []
                    var valor = retorno.data.data[0].valor
                    var etapa = retorno.data.data[0].etapa
                    var idConsulta = retorno.data.data[0].consulta_id
                    var dataConsulta = retorno.data.data[0].data_consulta
                    var idUsuario = retorno.data.data[0].usuarios_id

                    console.log(JSON.parse(localStorage.getItem('dadosUsuario_test')).id, retorno.data.data[0].usuarios_id);
                    // if (JSON.parse(localStorage.getItem('dadosUsuario_test')).id !== retorno.data.data[0].usuarios_id){
                    //     this.props.history.push('/admin/relatorios')
                    // }
                    var pessoas = []
                    var ids = new Set(retorno.data.data.map((e) => {
                        return e.clientes_id
                    }))
                    for(let id of ids){
                        pessoas.push(this.pessoasRetorno(retorno, id))
                    }
                    var principal = parseInt(pessoas.map((e) => {
                    return e.tipo_locatario
                    }).indexOf('Principal'))

                    if(principal !== 0){
                    var newOrdem = [pessoas[principal]]
                    for(var i = 0; i < pessoas.length; i++){
                        if(i !== principal){
                        newOrdem.push(pessoas[i])
                        }
                    }
                    pessoas = newOrdem
                    }
                
                    var solidarios = []
                    for(var i = 0; i < pessoas.length; i++){
                        if(pessoas[i].tipo_locatario === 'Solidário'){
                            solidarios.push(pessoas[i])
                        }
                    }

                    dicionario = [{
                        consulta_id: idConsulta,
                        valor: valor,
                        pessoas: pessoas,
                        etapa: etapa,
                        data_consulta: dataConsulta,
                        usuario_id: idUsuario,
                    }]

                    console.log(dicionario)
                    var dividirPor = 0
                    if(ids.size > 3){
                        dividirPor = 3
                    }
                    else{
                        dividirPor = ids.size
                    }

                    this.setState({
                        retornoConsulta: dicionario, 
                        pessoas: dicionario[0].pessoas, 
                        solidarios: solidarios, 
                        usuario: idUsuario
                    })

                    // Irá preencher alguns campos com os retornos das consultas realizadas
                    document.getElementsByName('locadorPF')[0].classList.add('d-print-block')
                    document.getElementsByName('locadorPJ')[0].classList.add('d-none')

                    // Verifica se tem valor de aluguel e outros valores e preenche os campos com suas respectivas informações
                    if(dicionario[0].valor){
                        console.log(dicionario[0].valor)
                        document.getElementsByName('valorAluguel')[0].value = dicionario[0].valor
                    }
                    if(dicionario[0].aluguel){
                        console.log(dicionario[0].aluguel)
                        document.getElementsByName('aluguel')[0].value = dicionario[0].aluguel
                    }
                    if(dicionario[0].condominio){
                        console.log(dicionario[0].condominio)
                        document.getElementsByName('condominio')[0].value = dicionario[0].condominio
                    }
                    if(dicionario[0].imposto){
                        console.log(dicionario[0].imposto)
                        document.getElementsByName('iptu')[0].value = dicionario[0].imposto
                    }
                    if(dicionario[0].luz){
                        console.log(dicionario[0].luz)
                        document.getElementsByName('luz')[0].value = dicionario[0].luz
                    }
                    if(dicionario[0].agua){
                        console.log(dicionario[0].agua)
                        document.getElementsByName('agua')[0].value = dicionario[0].agua
                    }
                    if(dicionario[0].gas){
                        console.log(dicionario[0].gas)
                        document.getElementsByName('gas')[0].value = dicionario[0].gas
                    }
                    

                    // Verifica a pessoa possui dados cadastrais
                    if(pessoas[0].dadosCadastrais){
                        this.setState({valido: 2})
                        document.getElementsByName('cont-validade')[0].checked = true
                        document.getElementsByName('data_termino')[0].classList.add('d-print-block')
                        document.getElementsByName('locatario_tipo')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.tipo_documento
                        
                        // Caso seja PF irá exibir e preencher alguns campos referente a PF
                        if(pessoas[0].dadosCadastrais.content.nome.conteudo.tipo_documento === 'PF'){
                            document.getElementsByName('locatarioPJ')[0].classList.add('d-none')
                            document.getElementsByName('locatarioPF')[0].classList.add('d-print-block')
                            
                            document.getElementsByName('locatario_nome')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.nome
                            document.getElementsByName('locatario_sexo')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.sexo
                            document.getElementsByName('locatario_nacionalidade')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.estrangeiro.pais_origem.origem
                            document.getElementsByName('locatario_cpf')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.documento.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            if(pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo){
                                if(pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo && pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo.length > 0 ){
                                    document.getElementsByName('locatario_residencial')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].ddd + ' ' + pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].telefone
                                }

                                if(pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular && pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular.length > 0){
                                    document.getElementsByName('locatario_celular')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].ddd + ' ' + pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].telefone
                                }
                            }
                            if(pessoas[0].dadosCadastrais.content.emails.conteudo && pessoas[0].dadosCadastrais.content.emails.conteudo.length > 0){
                                document.getElementsByName('locatario_email')[0].value = pessoas[0].dadosCadastrais.content.emails.conteudo[0].email
                            }
                        }
                        // Caso seja PJ irá exibir e preencher alguns campos referente a PJ
                        else{
                            document.getElementsByName('locatarioPF')[0].classList.add('d-none')
                            document.getElementsByName('locatarioPJ')[0].classList.add('d-print-block')

                            document.getElementsByName('locatario_razaoSocial')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.nome
                            document.getElementsByName('locatario_fantasia')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.nome_fantasia
                            document.getElementsByName('locatario_cnpj')[0].value = pessoas[0].dadosCadastrais.content.nome.conteudo.documento.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                            if(pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo){
                                if(pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo && pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo.length > 0){
                                    document.getElementsByName('locatario_telContato')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].ddd + ' ' + pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].telefone
                                }
                                else if(pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular && pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular.length > 0){
                                    document.getElementsByName('locatario_telContato')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].ddd + ' ' + pessoas[0].dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].telefone
                                }
                            }
                            if(pessoas[0].dadosCadastrais.content.emails.conteudo){
                                if(pessoas[0].dadosCadastrais.content.emails.conteudo.length > 0){
                                    document.getElementsByName('locatario_pjEmail')[0].value = pessoas[0].dadosCadastrais.content.emails.conteudo[0].email
                                }
                            }
                        }

                        // Caso tenha endereço irá preencher com o primeiro que encontrar
                        if(pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo && pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo.length > 0){
                            document.getElementsByName('locatario_cep')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo[0].cep.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3')
                            document.getElementsByName('locatario_end')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo[0].endereco
                            document.getElementsByName('locatario_endNum')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo[0].numero
                            document.getElementsByName('locatario_endComplemento')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo[0].complemento
                            document.getElementsByName('locatario_endBairro')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo[0].bairro
                            document.getElementsByName('locatario_endCidade')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo[0].cidade
                            document.getElementsByName('locatario_endEstado')[0].value = pessoas[0].dadosCadastrais.content.pesquisa_enderecos.conteudo[0].uf
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                })
        })
        .catch((error) => {
            console.log(error)
        })
    }

    // Criada para facilitar na formatação da consulta
    pessoasRetorno = (retorno, id_cliente) => {
        console.log(retorno)
        var dadosFalta = 0 
        var dadosCadastrais
        var dadosFinanceiros 
        var idwall
        var tipo_locatario
        var nome
        var cpf_cnpj
        var telefone
        var renda_informada
        var status = ''
        var motivo = ''
        var cliente_id
        var countDoc
        var statusDoc
        for(var i = 0; i < retorno.data.data.length; i++){
            if(retorno.data.data[i].clientes_id === id_cliente){
                if(dadosFalta === 0){
                    nome = retorno.data.data[i].nome
                    cpf_cnpj = retorno.data.data[i].cpf_cnpj
                    tipo_locatario = retorno.data.data[i].tipo_locatario
                    telefone = retorno.data.data[i].telefone
                    renda_informada = retorno.data.data[i].renda_informada
                    status += retorno.data.data[i].status + ', '
                    motivo += retorno.data.data[i].motivo + ', '
                    cliente_id = retorno.data.data[i].clientes_id
                    countDoc = retorno.data.data[i].countDoc
                    statusDoc = retorno.data.data[i].statusDoc
                    if(retorno.data.data[i].tipo_consulta_id === 1){
                        dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
                        dadosFalta = 1
                    }
                    else if(retorno.data.data[i].tipo_consulta_id === 2){
                        dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json)
                        dadosFalta = 2
                    }
                }
                else if(dadosFalta === 1 && retorno.data.data[i].tipo_consulta_id === 2){
                    dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json.replace(/'/g, '"'))
                }
                else if(dadosFalta === 2 && retorno.data.data[i].tipo_consulta_id === 1){
                    dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
                }
                if(retorno.data.data[i].tipo_consulta_id === 3){
                    idwall = JSON.parse(retorno.data.data[i].retorno_json.replace(/'/g, '"').replace(/[\r\n]/g, " "))
                    status += retorno.data.data[i].status + ', '
                    motivo += retorno.data.data[i].motivo + ', '
                }
            }
        }
        return {
            nome: nome,
            cliente_id: cliente_id,
            cpf_cnpj: cpf_cnpj,
            tipo_locatario: tipo_locatario,
            telefone: telefone,
            renda_informada: renda_informada,
            status: status,
            motivo: motivo,
            countDoc: countDoc,
            statusDoc: statusDoc,
            dadosCadastrais: dadosCadastrais,
            dadosFinanceiros: dadosFinanceiros,
            idwall: idwall
        }
    }


    // Início e término do contrato 
    changeValido = (evento) => {
        this.setState({valido: evento.target.value})
        if(evento.target.value === '1'){
            if(document.getElementsByName('data_termino')[0].classList.contains('d-print-block')){
                document.getElementsByName('data_termino')[0].classList.remove('d-print-block')
                document.getElementsByName('data_termino')[0].classList.add('d-none')
            }
            else{
                document.getElementsByName('data_termino')[0].classList.add('d-none')
            }
        }
        else{
            if(document.getElementsByName('data_termino')[0].classList.contains('d-none')){
                document.getElementsByName('data_termino')[0].classList.remove('d-none')
                document.getElementsByName('data_termino')[0].classList.add('d-print-block')
            }
            else{
                document.getElementsByName('data_termino')[0].classList.add('d-print-block')
            }
        }
    }

    toggleAlert = () => {
        this.setState({alert: !this.state.alert})
    }

    //Cadastrar formulário
    registrar = () => {

        var numero_contrato = document.getElementsByName('numero_contrato')[0].value
        var data_inicio = document.getElementsByName('data_inicio')[0].value
        var data_fim = document.getElementsByName('data_fim')[0].value

        if (new Date(data_inicio) > new Date(data_fim)){
            this.setState({mensagemModal: <div>
                A data de término deve ser maior que a data de início.
            </div>})
            this.toggleAlert()
            return
        }

        var locador_tipo = document.getElementsByName('locador_tipo')[0].value
        var locador_nome = document.getElementsByName('locador_nome')[0].value
        var locador_sexo = document.getElementsByName('locador_sexo')[0].value
        var locador_nacionalidade = document.getElementsByName('locador_nacionalidade')[0].value
        var locador_profissao = document.getElementsByName('locador_profissao')[0].value
        var locador_cpf = document.getElementsByName('locador_cpf')[0].value
        var locador_rg = document.getElementsByName('locador_rg')[0].value
        var locador_orgEmissor = document.getElementsByName('locador_orgEmissor')[0].value
        var locador_estadoCivil = document.getElementsByName('locador_estadoCivil')[0].value
        var locador_residencial = document.getElementsByName('locador_residencial')[0].value
        var locador_celular = document.getElementsByName('locador_celular')[0].value
        var locador_email = document.getElementsByName('locador_email')[0].value
        var locador_cep = document.getElementsByName('locador_cep')[0].value
        var locador_end = document.getElementsByName('locador_end')[0].value
        var locador_endNum = document.getElementsByName('locador_endNum')[0].value
        var locador_endComplemento = document.getElementsByName('locador_endComplemento')[0].value
        var locador_endBairro = document.getElementsByName('locador_endBairro')[0].value
        var locador_endCidade = document.getElementsByName('locador_endCidade')[0].value
        var locador_endEstado = document.getElementsByName('locador_endEstado')[0].value
        var locador_razaoSocial = document.getElementsByName('locador_razaoSocial')[0].value
        var locador_fantasia = document.getElementsByName('locador_fantasia')[0].value
        var locador_cnpj = document.getElementsByName('locador_cnpj')[0].value
        var locador_pjEmail = document.getElementsByName('locador_pjEmail')[0].value
        var locador_telContato = document.getElementsByName('locador_telContato')[0].value
        var locatario_tipo = document.getElementsByName('locatario_tipo')[0].value
        var locatario_nome = document.getElementsByName('locatario_nome')[0].value
        var locatario_sexo = document.getElementsByName('locatario_sexo')[0].value
        var locatario_nacionalidade = document.getElementsByName('locatario_nacionalidade')[0].value
        var locatario_profissao = document.getElementsByName('locatario_profissao')[0].value
        var locatario_cpf = document.getElementsByName('locatario_cpf')[0].value
        var locatario_rg = document.getElementsByName('locatario_rg')[0].value
        var locatario_orgEmissor = document.getElementsByName('locatario_orgEmissor')[0].value
        var locatario_estadoCivil = document.getElementsByName('locatario_estadoCivil')[0].value
        var locatario_residencial = document.getElementsByName('locatario_residencial')[0].value
        var locatario_celular = document.getElementsByName('locatario_celular')[0].value
        var locatario_email = document.getElementsByName('locatario_email')[0].value
        var locatario_cep = document.getElementsByName('locatario_cep')[0].value
        var locatario_end = document.getElementsByName('locatario_end')[0].value
        var locatario_endNum = document.getElementsByName('locatario_endNum')[0].value
        var locatario_endComplemento = document.getElementsByName('locatario_endComplemento')[0].value
        var locatario_endBairro = document.getElementsByName('locatario_endBairro')[0].value
        var locatario_endCidade = document.getElementsByName('locatario_endCidade')[0].value
        var locatario_endEstado = document.getElementsByName('locatario_endEstado')[0].value
        var locatario_razaoSocial = document.getElementsByName('locatario_razaoSocial')[0].value
        var locatario_fantasia = document.getElementsByName('locatario_fantasia')[0].value
        var locatario_cnpj = document.getElementsByName('locatario_cnpj')[0].value
        var locatario_pjEmail = document.getElementsByName('locatario_pjEmail')[0].value
        var locatario_telContato = document.getElementsByName('locatario_telContato')[0].value
        var imovel_tipo = document.getElementsByName('imovel_tipo')[0].value
        var imovel_finalidade = document.getElementsByName('imovel_finalidade')[0].value
        var imovel_tipoImovel = document.getElementsByName('imovel_tipoImovel')[0].value
        var imovel_cep = document.getElementsByName('imovel_cep')[0].value
        var imovel_end = document.getElementsByName('imovel_end')[0].value
        var imovel_endNum = document.getElementsByName('imovel_endNum')[0].value
        var imovel_endComplemento = document.getElementsByName('imovel_endComplemento')[0].value
        var imovel_endBairro = document.getElementsByName('imovel_endBairro')[0].value
        var imovel_endCidade = document.getElementsByName('imovel_endCidade')[0].value
        var imovel_endEstado = document.getElementsByName('imovel_endEstado')[0].value
        var valorAluguel = document.getElementsByName('valorAluguel')[0].value
        var aluguel = document.getElementsByName('aluguel')[0].value
        var condominio = document.getElementsByName('condominio')[0].value
        var iptu = document.getElementsByName('iptu')[0].value
        var gas = document.getElementsByName('gas')[0].value
        var agua = document.getElementsByName('agua')[0].value
        var luz = document.getElementsByName('luz')[0].value
        var diaPag
        var tipoPag
        var periodicidade
        var indice
        var t1_nome 
        var t1_cpf 
        var t1_telefone 
        var t2_nome 
        var t2_cpf 
        var t2_telefone
        var dados_solidarios = []

        var tel_sindico = document.getElementsByName('tel_sindico')[0].value
        var nome_sindico = document.getElementsByName('nome_sindico')[0].value
        var tel_adm = document.getElementsByName('tel_adm')[0].value
        var nome_adm = document.getElementsByName('nome_adm')[0].value
        var cod_lig_agua = document.getElementsByName('cod_lig_agua')[0].value
        var cod_lig_energia = document.getElementsByName('cod_lig_energia')[0].value
        var indice_cadastral = document.getElementsByName('indice_cadastral')[0].value

        if (!cod_lig_agua || !cod_lig_energia || !indice_cadastral){
            this.setState({mensagemModal: <div>
                Preencha os campos de alguel
            </div>})
            this.toggleAlert()
            return
        }

        if(this.state.solidarios.length > 0){
            for(var s = 0; s < this.state.solidarios.length; s++){
                var dictSolidario = {}
                dictSolidario.tipo = document.getElementsByName('locatario_tipo' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.nome = document.getElementsByName('locatario_nome' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.sexo = document.getElementsByName('locatario_sexo' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.nacionalidade = document.getElementsByName('locatario_nacionalidade' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.profissao = document.getElementsByName('locatario_profissao' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.cpf = document.getElementsByName('locatario_cpf' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.rg = document.getElementsByName('locatario_rg' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.orgEmissor = document.getElementsByName('locatario_orgEmissor' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.estadoCivil = document.getElementsByName('locatario_estadoCivil' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.residencial = document.getElementsByName('locatario_residencial' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.celular = document.getElementsByName('locatario_celular' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.email = document.getElementsByName('locatario_email' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.cep = document.getElementsByName('locatario_cep' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.end = document.getElementsByName('locatario_end' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.endNum = document.getElementsByName('locatario_endNum' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.endComplemento = document.getElementsByName('locatario_endComplemento' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.endBairro = document.getElementsByName('locatario_endBairro' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.endCidade = document.getElementsByName('locatario_endCidade' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.endEstado = document.getElementsByName('locatario_endEstado' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.razaoSocial = document.getElementsByName('locatario_razaoSocial' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.fantasia = document.getElementsByName('locatario_fantasia' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.cnpj = document.getElementsByName('locatario_cnpj' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.pjEmail = document.getElementsByName('locatario_pjEmail' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.telContato = document.getElementsByName('locatario_telContato' + this.state.solidarios[s].cliente_id)[0].value
                dictSolidario.cliente_id = this.state.solidarios[s].cliente_id
                dados_solidarios.push(dictSolidario)
            }
            // console.log(JSON.parse(JSON.stringify(dadosSolidarios)))

        }

        var prossegir = 0
        var usar = 0
        var limiteCad = -1
        if(this.state.cartasAdicionadas.length === 0){
            prossegir = 1
        }
        else{
            var somar = 0
            if(this.state.cartasAdicionadas.length === 1){
                if(!valorAluguel){
                    prossegir = 3
                }
                else if(!aluguel){
                    prossegir = 6
                }
                else{
                    somar = parseFloat(valorAluguel)
                    usar = parseFloat(this.state.cartasAdicionadas[0].valor_usado_total ? this.state.cartasAdicionadas[0].valor_usado_total : 0)
                    limiteCad = parseFloat(this.state.cartasAdicionadas[0].valor_carta)

                    console.log(somar, usar, limiteCad);

                    if((somar + usar) > limiteCad){
                        document.getElementById('cartaUsado' + this.state.cartasAdicionadas[0].codigo).value = ''
                        prossegir = 5
                    }
                    else{
                        document.getElementById('cartaUsado' + this.state.cartasAdicionadas[0].codigo).value = somar
                    }
                }
            }
            else{
                for(var cont = 0; cont < this.state.cartasAdicionadas.length; cont++){
                    somar += parseFloat(document.getElementById('cartaUsado' + this.state.cartasAdicionadas[cont].codigo).value)
                }
            }
            new Promise(resolve => setTimeout(resolve, 500)).then(() => {
                if(prossegir !== 5 && prossegir !== 3 && prossegir !== 6){
                    for(var v = 0; v < this.state.cartasAdicionadas.length; v++){
                        var verificar = parseFloat(document.getElementById('cartaUsado' + this.state.cartasAdicionadas[v].codigo).value)
                        if(!verificar || verificar === 0){
                            prossegir = 4
                        }
                    }
                }
                if(prossegir !== 4  && prossegir !== 5 && prossegir !== 3 && prossegir !== 6){
                    if(valorAluguel){
                        if(somar < parseFloat(valorAluguel)){
                            prossegir = 2
                        }
                    }
                    else{
                        prossegir = 3
                    }
                }
            })
        }

        
        new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
            if(!this.state.check){
                prossegir = 7
            }
            if(!imovel_cep || !imovel_end || !imovel_endNum || !imovel_endBairro || !imovel_endCidade || !imovel_endEstado){
                prossegir = 8
            }
            console.log(prossegir)

            if(prossegir === 0){
                // Caso não exista o formulário, haverá o cadastro das informações
                if(this.state.existe === 0){
                    api_epar.post( '/dadoscontrato', {
                        consulta_id: null,
                        numero_contrato: numero_contrato,
                        valido: this.state.valido,
                        data_inicio: data_inicio,
                        data_fim: data_fim,
                        locador_tipo: locador_tipo,
                        locador_nome: locador_nome,
                        locador_sexo: locador_sexo,
                        locador_nacionalidade: locador_nacionalidade,
                        locador_profissao: locador_profissao,
                        locador_cpf: locador_cpf,
                        locador_rg: locador_rg,
                        locador_orgEmissor: locador_orgEmissor,
                        locador_estadoCivil: locador_estadoCivil,
                        locador_residencial: locador_residencial,
                        locador_celular: locador_celular,
                        locador_email: locador_email,
                        locador_cep: locador_cep,
                        locador_end: locador_end,
                        locador_endNum: locador_endNum,
                        locador_endComplemento: locador_endComplemento,
                        locador_endBairro: locador_endBairro,
                        locador_endCidade: locador_endCidade,
                        locador_endEstado: locador_endEstado,
                        locador_razaoSocial: locador_razaoSocial,
                        locador_fantasia: locador_fantasia,
                        locador_cnpj: locador_cnpj,
                        locador_pjEmail: locador_pjEmail,
                        locador_telContato: locador_telContato,
                        locatario_tipo: locatario_tipo,
                        locatario_nome: locatario_nome,
                        locatario_sexo: locatario_sexo,
                        locatario_nacionalidade: locatario_nacionalidade,
                        locatario_profissao: locatario_profissao,
                        locatario_cpf: locatario_cpf,
                        locatario_rg: locatario_rg,
                        locatario_orgEmissor: locatario_orgEmissor,
                        locatario_estadoCivil: locatario_estadoCivil,
                        locatario_residencial: locatario_residencial,
                        locatario_celular: locatario_celular,
                        locatario_email: locatario_email,
                        locatario_cep: locatario_cep,
                        locatario_end: locatario_end,
                        locatario_endNum: locatario_endNum,
                        locatario_endComplemento: locatario_endComplemento,
                        locatario_endBairro: locatario_endBairro,
                        locatario_endCidade: locatario_endCidade,
                        locatario_endEstado: locatario_endEstado,
                        locatario_razaoSocial: locatario_razaoSocial,
                        locatario_fantasia: locatario_fantasia,
                        locatario_cnpj: locatario_cnpj,
                        locatario_pjEmail: locatario_pjEmail,
                        locatario_telContato: locatario_telContato,
                        imovel_tipo: imovel_tipo,
                        imovel_finalidade: imovel_finalidade,
                        imovel_tipoImovel: imovel_tipoImovel,
                        imovel_cep: imovel_cep,
                        imovel_end: imovel_end,
                        imovel_endNum: imovel_endNum,
                        imovel_endComplemento: imovel_endComplemento,
                        imovel_endBairro: imovel_endBairro,
                        imovel_endCidade: imovel_endCidade,
                        imovel_endEstado: imovel_endEstado,
                        valorAluguel: valorAluguel,
                        aluguel: aluguel,
                        condominio: condominio,
                        iptu: iptu,
                        gas: gas,
                        agua: agua,
                        luz: luz,
                        diaPag: diaPag,
                        tipoPag: tipoPag,
                        periodicidade: periodicidade,
                        indice: indice,
                        t1_nome: t1_nome,
                        t1_cpf: t1_cpf,
                        t1_telefone: t1_telefone,
                        t2_nome: t2_nome,
                        t2_cpf: t2_cpf,
                        t2_telefone: t2_telefone,
                        usuario: this.state.usuario,
                        taxa_mensal: this.state.taxaMensal,
                        dados_solidarios: JSON.stringify(dados_solidarios),
                        log: this.state.log + ' | CRIAR',
                        indice_cadastral: indice_cadastral,
                        cod_agua: cod_lig_agua,
                        cod_energia: cod_lig_energia,
                        nome_adm: nome_adm,
                        tel_adm: tel_adm,
                        nome_sindico: nome_sindico,
                        tel_sindico: tel_sindico, 
                    }).then((insert) => {
                        console.log(insert)
                        for(var contador = 0; contador < this.state.cartasAdicionadas.length; contador++){
                            // console.log(parseFloat(document.getElementById('cartaUsado' + this.state.cartasAdicionadas[contador].codigo).value))
                            api_epar.post( '/dadoscontratocartagarantia', {
                                carta_garantia_id: this.state.cartasAdicionadas[contador].id,
                                dados_contrato_id: insert.data.data,
                                valor_usado: parseFloat(document.getElementById('cartaUsado' + this.state.cartasAdicionadas[contador].codigo).value)
                            }).then((dc) => {
                                console.log(dc)
                            })
                        }

                        this.setState({idContrato: insert.data.data})

                        // document.getElementById('downloadCartaGarantia').style.display = 'block'

                        this.setState({mensagemModal: <div>
                            Contrato gerado, faça o download do contrato.
                        </div>})
                        this.toggleAlert()
                        
                        window.open('/downloadgarantia/' + insert.data.data, '_blank')

                        new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
                            this.props.history.push('/admin/formcontrato/' + insert.data.data)
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                //Caso já tenha o formulário no banco, ele irá atualizar as informações
                else{
                    console.log('Atualizar')
                    console.log(this.state.valido)
                    api_epar.put( '/dadoscontrato', {
                        consulta_id: null,
                        numero_contrato: numero_contrato,
                        valido: this.state.valido,
                        data_inicio: data_inicio,
                        data_fim: data_fim,
                        locador_tipo: locador_tipo,
                        locador_nome: locador_nome,
                        locador_sexo: locador_sexo,
                        locador_nacionalidade: locador_nacionalidade,
                        locador_profissao: locador_profissao,
                        locador_cpf: locador_cpf,
                        locador_rg: locador_rg,
                        locador_orgEmissor: locador_orgEmissor,
                        locador_estadoCivil: locador_estadoCivil,
                        locador_residencial: locador_residencial,
                        locador_celular: locador_celular,
                        locador_email: locador_email,
                        locador_cep: locador_cep,
                        locador_end: locador_end,
                        locador_endNum: locador_endNum,
                        locador_endComplemento: locador_endComplemento,
                        locador_endBairro: locador_endBairro,
                        locador_endCidade: locador_endCidade,
                        locador_endEstado: locador_endEstado,
                        locador_razaoSocial: locador_razaoSocial,
                        locador_fantasia: locador_fantasia,
                        locador_cnpj: locador_cnpj,
                        locador_pjEmail: locador_pjEmail,
                        locador_telContato: locador_telContato,
                        locatario_tipo: locatario_tipo,
                        locatario_nome: locatario_nome,
                        locatario_sexo: locatario_sexo,
                        locatario_nacionalidade: locatario_nacionalidade,
                        locatario_profissao: locatario_profissao,
                        locatario_cpf: locatario_cpf,
                        locatario_rg: locatario_rg,
                        locatario_orgEmissor: locatario_orgEmissor,
                        locatario_estadoCivil: locatario_estadoCivil,
                        locatario_residencial: locatario_residencial,
                        locatario_celular: locatario_celular,
                        locatario_email: locatario_email,
                        locatario_cep: locatario_cep,
                        locatario_end: locatario_end,
                        locatario_endNum: locatario_endNum,
                        locatario_endComplemento: locatario_endComplemento,
                        locatario_endBairro: locatario_endBairro,
                        locatario_endCidade: locatario_endCidade,
                        locatario_endEstado: locatario_endEstado,
                        locatario_razaoSocial: locatario_razaoSocial,
                        locatario_fantasia: locatario_fantasia,
                        locatario_cnpj: locatario_cnpj,
                        locatario_pjEmail: locatario_pjEmail,
                        locatario_telContato: locatario_telContato,
                        imovel_tipo: imovel_tipo,
                        imovel_finalidade: imovel_finalidade,
                        imovel_tipoImovel: imovel_tipoImovel,
                        imovel_cep: imovel_cep,
                        imovel_end: imovel_end,
                        imovel_endNum: imovel_endNum,
                        imovel_endComplemento: imovel_endComplemento,
                        imovel_endBairro: imovel_endBairro,
                        imovel_endCidade: imovel_endCidade,
                        imovel_endEstado: imovel_endEstado,
                        valorAluguel: valorAluguel,
                        aluguel: aluguel,
                        condominio: condominio,
                        iptu: iptu,
                        gas: gas,
                        agua: agua,
                        luz: luz,
                        diaPag: diaPag,
                        tipoPag: tipoPag,
                        periodicidade: periodicidade,
                        indice: indice,
                        t1_nome: t1_nome,
                        t1_cpf: t1_cpf,
                        t1_telefone: t1_telefone,
                        t2_nome: t2_nome,
                        t2_cpf: t2_cpf,
                        t2_telefone: t2_telefone,
                        usuario: this.state.usuario,
                        dados_solidarios: JSON.stringify(dados_solidarios),
                        log: this.state.log + ' | ATUALIZAR',
                        indice_cadastral: indice_cadastral,
                        cod_agua: cod_lig_agua,
                        cod_energia: cod_lig_energia,
                        nome_adm: nome_adm,
                        tel_adm: tel_adm,
                        nome_sindico: nome_sindico,
                        tel_sindico: tel_sindico, 
                    }).then((atualizar) => {
                        console.log(atualizar)
                        api_epar.post( '/dadoscontratocartagarantiadeletecontrato', {
                            dados_contrato_id: this.state.dadosContrato[0].id
                        }).then((deletar) => {
                            console.log(deletar)
                            for(var contador = 0; contador < this.state.cartasAdicionadas.length; contador++){
                                api_epar.post( '/dadoscontratocartagarantia', {
                                    carta_garantia_id: this.state.cartasAdicionadas[contador].id,
                                    dados_contrato_id: this.state.dadosContrato[0].id,
                                    valor_usado: parseFloat(document.getElementById('cartaUsado' + this.state.cartasAdicionadas[contador].codigo).value)
                                }).then((dc) => {
                                    console.log(dc)
                                })
                            }
                        })

                        window.open('/downloadgarantia/' + this.state.dadosContrato[0].id + '?enviar=nao', '_blank')
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
            else if(prossegir === 1){
                this.setState({mensagemModal: <div>
                    Não há carta de crédito adicionada para criar o contrato, é necessário inserir pelo menos uma carta de crédito.
                </div>})
                this.toggleAlert()
            }
            else if(prossegir === 2){
                this.setState({mensagemModal: <div>
                    A(s) carta(s) não supera(m) o valor total de locação.
                </div>})
                this.toggleAlert()
            }
            else if(prossegir === 3){
                this.setState({mensagemModal: <div>
                    Valores para locação estão vazios, preencha para prossegir.
                </div>})
                this.toggleAlert()
            }
            else if(prossegir === 4){
                this.setState({mensagemModal: <div>
                    Não informou o valor que será usado de uma carta.
                </div>})
                this.toggleAlert()
            }
            else if(prossegir === 5){
                this.setState({mensagemModal: <div>
                        Não é possível utilizar o valor {FuncoesComum.formatarMoeda(parseFloat(somar))}. Pois irá exceder o limite remanescente da carta de {FuncoesComum.formatarMoeda(limiteCad - usar)}.<br/>
                        Será necessário inserir uma nova carta de crédito (no início da página) ou finalize a consulta. 
                    </div>})
                this.toggleAlert()
            }
            else if(prossegir === 6){
                this.setState({mensagemModal: <div>
                    Valor de aluguel vazio, preencha para prosseguir.
                </div>})
                this.toggleAlert()
            }
            else if(prossegir === 7){
                this.setState({mensagemModal: <div>
                    É necessário aceitar os termos para prosseguir.
                </div>})
                this.toggleAlert()
            }
            else if(prossegir === 8){
                this.setState({mensagemModal: <div>
                    É necessário preencher o CEP e os demais dados sobre o endereço do imóvel para prosseguir.
                </div>})
                this.toggleAlert()
            }
        })
    }
      carregarSolidarios = (record) => {
        var tipo = ''
        var nome = ''
        var sexo = ''
        var nacionalidade = ''
        var profissao = ''
        var cpf = ''
        var rg = ''
        var orgEmissor = ''
        var estadoCivil = ''
        var residencial = ''
        var celular = ''
        var email = ''
        var cep = ''
        var end = ''
        var endNum = ''
        var endComplemento = ''
        var endBairro = ''
        var endCidade = ''
        var endEstado = ''
        var razaoSocial = ''
        var fantasia = ''
        var cnpj = ''
        var telContato = ''
        var pjEmail = ''
        var carta = this.state.cartasAdicionadas.filter((carta) => {
            return carta.clientes_id === record.cliente_id
        })[0].codigo
        console.log(record)
          if(record.tipo){
            tipo = record.tipo
            // if(record.tipo === 'PF'){
            //     document.getElementsByName('locatarioPJ' + record.cliente_id)[0].classList.add('d-none')
            //     document.getElementsByName('locatarioPF' + record.cliente_id)[0].classList.add('d-print-block')
            // }
            // else{
            //     document.getElementsByName('locatarioPF' + record.cliente_id)[0].classList.add('d-none')
            //     document.getElementsByName('locatarioPJ' + record.cliente_id)[0].classList.add('d-print-block')
            // }
            nome = record.nome
            sexo = record.sexo
            nacionalidade = record.nacionalidade
            profissao = record.profissao
            cpf = record.cpf
            rg = record.rg
            orgEmissor = record.orgEmissor
            estadoCivil = record.estadoCivil
            residencial = record.residencial
            celular = record.celular
            email = record.email
            cep = record.cep
            end = record.end
            endNum = record.endNum
            endComplemento = record.endComplemento
            endBairro = record.endBairro
            endCidade = record.endCidade
            endEstado = record.endEstado
            razaoSocial = record.razaoSocial
            fantasia = record.fantasia
            cnpj = record.cnpj
            pjEmail = record.pjEmail
            telContato = record.telContato
          }
          else{
              if(record.dadosCadastrais){
                tipo = record.dadosCadastrais.content.nome.conteudo.tipo_documento
                if(record.dadosCadastrais.content.nome.conteudo.tipo_documento === 'PF'){
                    nome = record.dadosCadastrais.content.nome.conteudo.nome
                    sexo = record.dadosCadastrais.content.nome.conteudo.sexo
                    nacionalidade = record.dadosCadastrais.content.nome.conteudo.estrangeiro.pais_origem.origem
                    cpf = record.dadosCadastrais.content.nome.conteudo.documento.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    
                    if(record.dadosCadastrais.content.pesquisa_telefones.conteudo){
                        if(record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo && record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo.length > 0){
                            residencial = record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].ddd + ' ' + record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].telefone
                        }
                        if(record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular && record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular.length > 0){
                            celular = record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].ddd + ' ' + record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].telefone
                        }
                    }
                    if(record.dadosCadastrais.content.emails.conteudo){
                        if(record.dadosCadastrais.content.emails.conteudo.length > 0){
                            email = record.dadosCadastrais.content.emails.conteudo[0].email
                        }
                    }
                }
                else{
                    razaoSocial = record.dadosCadastrais.content.nome.conteudo.nome
                    fantasia = record.dadosCadastrais.content.nome.conteudo.nome_fantasia
                    cnpj = record.dadosCadastrais.content.nome.conteudo.documento.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                    if(record.dadosCadastrais.content.pesquisa_telefones.conteudo){
                        if(record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo && record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo.length > 0){
                            telContato = record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].ddd + ' ' + record.dadosCadastrais.content.pesquisa_telefones.conteudo.fixo[0].telefone
                        }
                        else if(record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular && record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular.length > 0){
                            telContato = record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].ddd + ' ' + record.dadosCadastrais.content.pesquisa_telefones.conteudo.celular[0].telefone
                        }
                    }
                    if(record.dadosCadastrais.content.emails.conteudo){
                        if(record.dadosCadastrais.content.emails.conteudo.length > 0){
                            pjEmail = record.dadosCadastrais.content.emails.conteudo[0].email
                        }
                    }
                }
                if(record.dadosCadastrais.content.pesquisa_enderecos.conteudo && record.dadosCadastrais.content.pesquisa_enderecos.conteudo.length > 0){
                    cep = record.dadosCadastrais.content.pesquisa_enderecos.conteudo[0].cep.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3')
                    end = record.dadosCadastrais.content.pesquisa_enderecos.conteudo[0].endereco
                    endNum = record.dadosCadastrais.content.pesquisa_enderecos.conteudo[0].numero
                    endComplemento = record.dadosCadastrais.content.pesquisa_enderecos.conteudo[0].complemento
                    endBairro = record.dadosCadastrais.content.pesquisa_enderecos.conteudo[0].bairro
                    endCidade = record.dadosCadastrais.content.pesquisa_enderecos.conteudo[0].cidade
                    endEstado = record.dadosCadastrais.content.pesquisa_enderecos.conteudo[0].uf
                }
            }
          }

          return (
            <div id="locatario" key={record.cliente_id} className="mb-5">
                <div className="cabecalho">
                    <h2>DADOS DO LOCATÁRIO SOLIDÁRIO | {carta}</h2>
                </div>

                <div className="form-group col-md-4 p-0">
                    <select name={'locatario_tipo' + record.cliente_id} defaultValue={tipo} className="form-control" data-value={record.cliente_id} onChange={this.changeSolidario}>
                        <option value='PF'>Pessoa Fisíca</option>
                        <option value='PJ'>Pessoa Jurídica</option>
                    </select>
                </div>

                <div name={'locatarioPF' + record.cliente_id} className={tipo === 'PJ' && 'd-none'}>
                    <div className="form-row">
                        <div className="form-group col-md-9">
                        <input type="text" name={'locatario_nome' + record.cliente_id} defaultValue={nome} className="form-control" placeholder="Nome completo"/>
                        </div>
                        <div className="form-group col-md-3">
                            <select name={'locatario_sexo' + record.cliente_id} defaultValue={sexo} className="form-control">
                                <option value='Sexo'>Sexo</option>
                                <option value='M'>Masculino</option>
                                <option value='F'>Feminino</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                        <input type="text" name={'locatario_nacionalidade' + record.cliente_id} defaultValue={nacionalidade} className="form-control" placeholder="Nacionalidade"/>
                        </div>
                        <div className="form-group col-md-6">
                        <input type="text" name={'locatario_profissao' + record.cliente_id}  defaultValue={profissao} className="form-control" placeholder="Profissão"/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                        <Input type="text" name={'locatario_cpf' + record.cliente_id} defaultValue={cpf} className="form-control" placeholder="CPF" />
                        </div>
                        <div className="form-group col-md-4">
                            <Input type="text" name={'locatario_rg' + record.cliente_id} defaultValue={rg} className="form-control" placeholder="RG"/>
                        </div>
                        <div className="form-group col-md-4">
                        <input type="text" name={'locatario_orgEmissor' + record.cliente_id} defaultValue={orgEmissor} className="form-control" placeholder="Orgão Emissor"/>
                        </div>
                    </div>

                    <div className="form-group col-md-4 p-0">
                        <select className="form-control" defaultValue={estadoCivil} name={'locatario_estadoCivil'  + record.cliente_id}>
                            <option value='Estado cívil'>Estado cívil</option>
                            <option value='Casado(a)'>Casado(a)</option>
                            <option value='Solteiro(a)'>Solteiro(a)</option>
                            <option value='Viúvo'>Viúvo</option>
                            <option value='Divorciado'>Divorciado</option>
                            <option value='Em uma união estável'>Em uma união estável</option>  
                        </select>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                        <input type="text" name={'locatario_residencial' + record.cliente_id} defaultValue={residencial} className="form-control" placeholder="Telefone residencial"/>
                        </div>
                        <div className="form-group col-md-4">
                            <input type="text" name={'locatario_celular' + record.cliente_id} defaultValue={celular} className="form-control" placeholder="Telefone celular"/>
                        </div>
                        <div className="form-group col-md-4">
                        <input type="text" name={'locatario_email'  + record.cliente_id} defaultValue={email} className="form-control" placeholder="Email"/>
                        </div>
                    </div>
                </div>

                <div name={'locatarioPJ' + record.cliente_id} className={tipo === 'PF' && 'd-none'}>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <input type="text" name={'locatario_razaoSocial' + record.cliente_id} defaultValue={razaoSocial} className="form-control" placeholder="Razão social" />
                        </div>
                        <div className="form-group col-md-6">
                            <input type="text" name={'locatario_fantasia' + record.cliente_id} defaultValue={fantasia} className="form-control" placeholder="Nome fantasia" />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <input type="text" name={'locatario_cnpj' + record.cliente_id} defaultValue={cnpj} className="form-control" placeholder="CNPJ" />
                        </div>
                        <div className="form-group col-md-4">
                            <input type="text" name={'locatario_pjEmail' + record.cliente_id} defaultValue={pjEmail} className="form-control" placeholder="Email"/>
                        </div>
                        <div className="form-group col-md-4">
                            <input type="text" name={'locatario_telContato' + record.cliente_id} defaultValue={telContato} className="form-control" placeholder="Telefone de contato"/>
                        </div>
                    </div>

                </div>


                <div className="form-row">
                    <div className="form-group col-md-3">
                        <Input type="text" name={'locatario_cep' + record.cliente_id} defaultValue={cep} className="form-control" placeholder="CEP" onChange={this.changeCep} />
                    </div>
                    <div className="form-group col-md-10">
                    <input type="text" name={'locatario_end' + record.cliente_id} defaultValue={end} className="form-control" placeholder="Endereço"/>
                    </div>
                    <div className="form-group col-md-2">
                    <input type="text" name={'locatario_endNum' + record.cliente_id} defaultValue={endNum} className="form-control" placeholder="Nº"/>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-4">
                    <input type="text" name={'locatario_endComplemento' + record.cliente_id} defaultValue={endComplemento} className="form-control" placeholder="Complemento"/>
                    </div>
                    <div className="form-group col-md-8">
                    <input type="text" name={'locatario_endBairro' + record.cliente_id} defaultValue={endBairro} className="form-control" placeholder="Bairro"/>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-8">
                    <input type="text" name={'locatario_endCidade' + record.cliente_id} defaultValue={endCidade} className="form-control" placeholder="Cidade"/>
                    </div>
                    <div className="form-group col-md-4">
                    <select className="form-control" name={'locatario_endEstado' + record.cliente_id} defaultValue={endEstado}>
                            <option value>Selecione</option>
                            <option value='AC'>Acre</option>
                            <option value='AL'>Alagoas</option>
                            <option value='AP'>Amapá</option>
                            <option value='AM'>Amazonas</option>
                            <option value='BA'>Bahia</option>
                            <option value='CE'>Ceará</option>
                            <option value='DF'>Distrito Federal</option>
                            <option value='ES'>Espírito Santo</option>
                            <option value='GO'>Goiás</option>
                            <option value='MA'>Maranhão</option>
                            <option value='MT'>Mato Grosso</option>
                            <option value='MS'>Mato Grosso do Sul</option>
                            <option value='MG'>Minas Gerais</option>
                            <option value='PA'>Pará</option>
                            <option value='PB'>Paraíba</option>
                            <option value='PR'>Paraná</option>
                            <option value='PE'>Pernambuco</option>
                            <option value='PI'>Piauí</option>
                            <option value='RJ'>Rio de Janeiro</option>
                            <option value='RN'>Rio Grande do Norte</option>
                            <option value='RS'>Rio Grande do Sul</option>
                            <option value='RO'>Rondônia</option>
                            <option value='RR'>Roraima</option>
                            <option value='SC'>Santa Catarina</option>
                            <option value='SP'>São Paulo</option>
                            <option value='SE'>Sergipe</option>
                            <option value='TO'>Tocantins</option>
                        </select>
                    </div>
                </div>
            </div>
          )
      }

      changeLocador = (evento) => {
        if(evento.target.value === 'PJ'){
            if(document.getElementsByName('locadorPJ')[0].classList.contains('d-none')){
                document.getElementsByName('locadorPJ')[0].classList.remove('d-none')
            }
            document.getElementsByName('locadorPF')[0].classList.add('d-none')
            document.getElementsByName('locadorPJ')[0].classList.add('d-print-block')
        }
        else{
            if(document.getElementsByName('locadorPF')[0].classList.contains('d-none')){
                document.getElementsByName('locadorPF')[0].classList.remove('d-none')
            }
            document.getElementsByName('locadorPF')[0].classList.add('d-print-block')
            document.getElementsByName('locadorPJ')[0].classList.add('d-none')
        }
      }

      changeLocatario = (evento) => {
        if(evento.target.value === 'PJ'){
            if(document.getElementsByName('locatarioPJ')[0].classList.contains('d-none')){
                document.getElementsByName('locatarioPJ')[0].classList.remove('d-none')
            }
            document.getElementsByName('locatarioPF')[0].classList.add('d-none')
            document.getElementsByName('locatarioPJ')[0].classList.add('d-print-block')
        }
        else{
            if(document.getElementsByName('locatarioPF')[0].classList.contains('d-none')){
                document.getElementsByName('locatarioPF')[0].classList.remove('d-none')
            }
            document.getElementsByName('locatarioPF')[0].classList.add('d-print-block')
            document.getElementsByName('locatarioPJ')[0].classList.add('d-none')
        }
      }

    changeSolidario = (evento) => {
        if(evento.target.value === 'PJ'){
            if(document.getElementsByName('locatarioPJ' + evento.currentTarget.dataset.value)[0].classList.contains('d-none')){
                document.getElementsByName('locatarioPJ' + evento.currentTarget.dataset.value)[0].classList.remove('d-none')
            }
            document.getElementsByName('locatarioPF' + evento.currentTarget.dataset.value)[0].classList.add('d-none')
            document.getElementsByName('locatarioPJ' + evento.currentTarget.dataset.value)[0].classList.add('d-print-block')
        }
        else{
            if(document.getElementsByName('locatarioPF' + evento.currentTarget.dataset.value)[0].classList.contains('d-none')){
                document.getElementsByName('locatarioPF' + evento.currentTarget.dataset.value)[0].classList.remove('d-none')
            }
            document.getElementsByName('locatarioPF' + evento.currentTarget.dataset.value)[0].classList.add('d-print-block')
            document.getElementsByName('locatarioPJ' + evento.currentTarget.dataset.value)[0].classList.add('d-none')
        }
    }

    changeCep = (evento) => {
        var prefixo = evento.target.name.split('_')
        if(evento.target.value.replace(/\D/g, '').length === 8){
            axios.get('http://viacep.com.br/ws/' + evento.target.value.replace(/\D/g, '') + '/json').then((dadosCep) => {
                document.getElementsByName(prefixo[0] + '_cep' + prefixo[1].replace('cep', ''))[0].value = dadosCep.data.cep
                document.getElementsByName(prefixo[0] + '_end' + prefixo[1].replace('cep', ''))[0].value = dadosCep.data.logradouro
                document.getElementsByName(prefixo[0] + '_endBairro' + prefixo[1].replace('cep', ''))[0].value = dadosCep.data.bairro
                document.getElementsByName(prefixo[0] + '_endCidade' + prefixo[1].replace('cep', ''))[0].value = dadosCep.data.localidade
                document.getElementsByName(prefixo[0] + '_endEstado' + prefixo[1].replace('cep', ''))[0].value = dadosCep.data.uf
                document.getElementsByName(prefixo[0] + '_endNum' + prefixo[1].replace('cep', ''))[0].value = ''
                document.getElementsByName(prefixo[0] + '_endComplemento' + prefixo[1].replace('cep', ''))[0].value = ''
            })
        }
        else{
            document.getElementsByName(prefixo[0] + '_end' + prefixo[1].replace('cep', ''))[0].value = ''
            document.getElementsByName(prefixo[0] + '_endBairro' + prefixo[1].replace('cep', ''))[0].value = ''
            document.getElementsByName(prefixo[0] + '_endCidade' + prefixo[1].replace('cep', ''))[0].value = ''
            document.getElementsByName(prefixo[0] + '_endEstado' + prefixo[1].replace('cep', ''))[0].value = ''
            document.getElementsByName(prefixo[0] + '_endNum' + prefixo[1].replace('cep', ''))[0].value = ''
            document.getElementsByName(prefixo[0] + '_endComplemento' + prefixo[1].replace('cep', ''))[0].value = ''
        }
    }

    changeCPF = (evento) => {
        var prefixo = evento.target.name.split('_')
        var cpf = evento.target.value.replace(/\D/g, '')
        document.getElementsByName(prefixo[0] + '_cpf')[0].value = cpf.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        if(cpf.length === 11){
            if(this.validarCPF(cpf)){
                api_epar.get( '/procobcadastraisl/' + cpf).then((dadosLocador) => {
                    console.log(dadosLocador)
                    if(dadosLocador.data.data.content){
                        if(dadosLocador.data.data.content.nome.conteudo){
                            document.getElementsByName(prefixo[0] + '_nome')[0].value = dadosLocador.data.data.content.nome.conteudo.nome
                            document.getElementsByName(prefixo[0] + '_sexo')[0].value = dadosLocador.data.data.content.nome.conteudo.sexo
                            document.getElementsByName(prefixo[0] + '_nacionalidade')[0].value = dadosLocador.data.data.content.nome.conteudo.estrangeiro ? dadosLocador.data.data.content.nome.conteudo.estrangeiro.pais_origem.origem : ''
                            if(dadosLocador.data.data.content.pesquisa_telefones.conteudo && dadosLocador.data.data.content.pesquisa_telefones.conteudo.fixo){
                                document.getElementsByName(prefixo[0] + '_residencial')[0].value = dadosLocador.data.data.content.pesquisa_telefones.conteudo.fixo[0].ddd + ' ' + dadosLocador.data.data.content.pesquisa_telefones.conteudo.fixo[0].telefone
                            }
                            if(dadosLocador.data.data.content.pesquisa_telefones.conteudo && dadosLocador.data.data.content.pesquisa_telefones.conteudo.celular){
                                document.getElementsByName(prefixo[0] + '_celular')[0].value = dadosLocador.data.data.content.pesquisa_telefones.conteudo.celular[0].ddd + ' ' + dadosLocador.data.data.content.pesquisa_telefones.conteudo.celular[0].telefone
                            }
                            if(dadosLocador.data.data.content.emails.conteudo){
                                document.getElementsByName(prefixo[0] + '_email')[0].value = dadosLocador.data.data.content.emails.conteudo[0].email
                            }
                        }
                        if(dadosLocador.data.data.content.pesquisa_enderecos.conteudo){
                            document.getElementsByName(prefixo[0] + '_cep')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].cep
                            document.getElementsByName(prefixo[0] + '_end')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].endereco
                            document.getElementsByName(prefixo[0] + '_endBairro')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].bairro
                            document.getElementsByName(prefixo[0] + '_endCidade')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].cidade
                            document.getElementsByName(prefixo[0] + '_endEstado')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].uf
                            document.getElementsByName(prefixo[0] + '_endNum')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].numero
                            document.getElementsByName(prefixo[0] + '_endComplemento')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].complemento
                        }
                    }
                })
            }
        }
        else{
            document.getElementsByName(prefixo[0] + '_cnpj')[0].value = ''
            document.getElementsByName(prefixo[0] + '_razaoSocial')[0].value = ''
            document.getElementsByName(prefixo[0] + '_fantasia')[0].value = ''
            document.getElementsByName(prefixo[0] + '_telContato')[0].value = ''
            document.getElementsByName(prefixo[0] + '_pjEmail')[0].value = ''
            document.getElementsByName(prefixo[0] + '_nome')[0].value = ''
            document.getElementsByName(prefixo[0] + '_sexo')[0].value = 'Sexo'
            document.getElementsByName(prefixo[0] + '_nacionalidade')[0].value = '' 
            document.getElementsByName(prefixo[0] + '_residencial')[0].value = ''
            document.getElementsByName(prefixo[0] + '_celular')[0].value = ''
            document.getElementsByName(prefixo[0] + '_email')[0].value = ''
            document.getElementsByName(prefixo[0] + '_cep')[0].value = ''
            document.getElementsByName(prefixo[0] + '_end')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endBairro')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endCidade')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endEstado')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endNum')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endComplemento')[0].value = ''
        }
    }

    changeCNPJ = (evento) => {
        var prefixo = evento.target.name.split('_')
        var cnpj = evento.target.value.replace(/\D/g, '')
        document.getElementsByName(prefixo[0] + '_cnpj')[0].value = cnpj.replace(/[^\d]/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        if(cnpj.length === 14){
            if(this.validarCNPJ(cnpj)){
                api_epar.get( '/procobcadastraisl/' + cnpj).then((dadosLocador) => {
                    console.log(dadosLocador)
                    if(dadosLocador.data.data.content){
                        if(dadosLocador.data.data.content.nome.conteudo){
                            document.getElementsByName(prefixo[0] + '_razaoSocial')[0].value = dadosLocador.data.data.content.nome.conteudo.nome
                            document.getElementsByName(prefixo[0] + '_fantasia')[0].value = dadosLocador.data.data.content.nome.conteudo.nome_fantasia
                            if(dadosLocador.data.data.content.pesquisa_telefones.conteudo && dadosLocador.data.data.content.pesquisa_telefones.conteudo.fixo){
                                document.getElementsByName(prefixo[0] + '_telContato')[0].value = dadosLocador.data.data.content.pesquisa_telefones.conteudo.fixo[0].ddd + ' ' + dadosLocador.data.data.content.pesquisa_telefones.conteudo.fixo[0].telefone
                            }
                            if(dadosLocador.data.data.content.emails.conteudo){
                                document.getElementsByName(prefixo[0] + '_pjEmail')[0].value = dadosLocador.data.data.content.emails.conteudo[0].email
                            }
                        }
                        if(dadosLocador.data.data.content.pesquisa_enderecos.conteudo){
                            document.getElementsByName(prefixo[0] + '_cep')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].cep
                            document.getElementsByName(prefixo[0] + '_end')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].endereco
                            document.getElementsByName(prefixo[0] + '_endBairro')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].bairro
                            document.getElementsByName(prefixo[0] + '_endCidade')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].cidade
                            document.getElementsByName(prefixo[0] + '_endEstado')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].uf
                            document.getElementsByName(prefixo[0] + '_endNum')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].numero
                            document.getElementsByName(prefixo[0] + '_endComplemento')[0].value = dadosLocador.data.data.content.pesquisa_enderecos.conteudo[0].complemento
                        }
                    }
                })
            }
        }
        else{
            document.getElementsByName(prefixo[0] + '_cpf')[0].value = ''
            document.getElementsByName(prefixo[0] + '_razaoSocial')[0].value = ''
            document.getElementsByName(prefixo[0] + '_fantasia')[0].value = ''
            document.getElementsByName(prefixo[0] + '_telContato')[0].value = ''
            document.getElementsByName(prefixo[0] + '_pjEmail')[0].value = ''
            document.getElementsByName(prefixo[0] + '_nome')[0].value = ''
            document.getElementsByName(prefixo[0] + '_sexo')[0].value = 'Sexo'
            document.getElementsByName(prefixo[0] + '_nacionalidade')[0].value = '' 
            document.getElementsByName(prefixo[0] + '_residencial')[0].value = ''
            document.getElementsByName(prefixo[0] + '_celular')[0].value = ''
            document.getElementsByName(prefixo[0] + '_email')[0].value = ''
            document.getElementsByName(prefixo[0] + '_cep')[0].value = ''
            document.getElementsByName(prefixo[0] + '_end')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endBairro')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endCidade')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endEstado')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endNum')[0].value = ''
            document.getElementsByName(prefixo[0] + '_endComplemento')[0].value = ''
        }
    }

    validarCPF = (strCPF) => {
        var Soma;
        var Resto;
        Soma = 0;
        strCPF = strCPF.replace(/\D/g, '')
      if (strCPF == "00000000000" ||
        strCPF == "11111111111" ||
        strCPF == "22222222222" ||
        strCPF == "33333333333" ||
        strCPF == "44444444444" ||
        strCPF == "55555555555" ||
        strCPF == "66666666666" ||
        strCPF == "77777777777" ||
        strCPF == "88888888888" ||
        strCPF == "99999999999"
      ){
        this.setState({mensagemModal: <div>
            CPF inválido
                </div>})
        this.toggleAlert() 
          return false;
      } 
         
      for (var i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
      Resto = (Soma * 10) % 11;
       
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ){
            this.setState({mensagemModal: <div>
                CPF inválido
                    </div>})
            this.toggleAlert() 
            return false;
        } 
       
      Soma = 0;
        for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
       
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ){
            this.setState({mensagemModal: <div>
                CPF inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }  
        return true;
    }

    validarCNPJ = (cnpj) => {
 
        cnpj = cnpj.replace(/[^\d]+/g,'');
     
        if(cnpj == '') {
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
         
        if (cnpj.length != 14){
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
     
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999"){
                this.setState({mensagemModal: <div>
                    CNPJ inválido
                        </div>})
                this.toggleAlert()  
                return false;
            }
             
        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0,tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)){
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
             
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)){
            this.setState({mensagemModal: <div>
                CNPJ inválido
                    </div>})
            this.toggleAlert()  
            return false;
        }
                    
        return true;
        
    }

    adicionarCarta = () => {
        var codigo = this.state.cartaSelected
        var cpf = this.state.cpf

        if (!cpf || !codigo) {
            this.setState({mensagemModal: <div>
                Digite um CPF e selecione uma carta
              </div>})
            this.toggleAlert()
            return
        }

        if(this.state.cartasAdicionadas.filter((dados) => {
            return dados.codigo === codigo
        }).length > 0){
            this.setState({mensagemModal: <div>
                Carta já foi adicionada para esse contrato.
              </div>})
            this.toggleAlert()
        }
        else{
            api_epar.get( '/cartagarantiacodigo/' + codigo ).then((existe) => {
                if(existe.data.data.length > 0){
                    api_epar.get( '/cartagarantiacodigocpfbatem/' + codigo + '/' + cpf.replace(/\D/g, '')).then((batem) => {
                        if(batem.data.data.length > 0){
                            api_epar.get( '/cartagarantiacodigocpfprazo/' + codigo + '/' + cpf.replace(/\D/g, '')).then((prazo) => {
                                if(prazo.data.data.length > 0){
                                    api_epar.get( '/cartagarantiacodigocpf/' + codigo + '/' + cpf.replace(/\D/g, '')).then((carta) => {
                                        if(carta.data.data.length > 0 ){
                                            console.log(carta.data.data)
                                            var add = this.state.cartasAdicionadas
                                            add.push(carta.data.data[0])
                                            this.setState({cartasAdicionadas: add})
                                            this.limparBuscaCarta()
                                            api_epar.get( '/clienteshasconsultadetalhe/' + carta.data.data[0].consulta_id).then((addsolidario) => {
                                                var solidarios = this.state.solidarios
                                                solidarios.push(this.pessoasRetorno(addsolidario, carta.data.data[0].clientes_id))
                                                this.setState({solidarios: solidarios})
                                            })
                                        }
                                        else{
                                            console.log(carta.data.data)
                                            // this.setState({mensagemModal: <div>
                                            //                                 Carta sendo utilizada em outro contrato
                                            //                               </div>})
                                            // this.toggleAlert()
                                        }
                                    }).catch((error) => {
                                        console.log(error)
                                    })
                                }
                                else{
                                    this.setState({mensagemModal: <div>
                                        O prazo de validade para a carta "{codigo}" foi excedido.
                                      </div>})
                                    this.toggleAlert()
                                }
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        else{
                            this.setState({mensagemModal: <div>
                                Código e CPF não possuem um vínculo. Tente novamente.
                              </div>})
                            this.toggleAlert()
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                else{
                    this.setState({mensagemModal: <div>
                        Carta com o código {codigo} não encontrada.
                      </div>})
                    this.toggleAlert()
                }
            }).catch((error) => {
                console.log(error)
            })
        }

    }

    changeValorAluguel = (evento) => {
        var valorAluguel = 0

        if(document.getElementsByName('aluguel')[0].value){
            valorAluguel += parseFloat(document.getElementsByName('aluguel')[0].value)
        }
        if(document.getElementsByName('condominio')[0].value){
            valorAluguel += parseFloat(document.getElementsByName('condominio')[0].value)
        }
        if(document.getElementsByName('iptu')[0].value){
            valorAluguel += parseFloat(document.getElementsByName('iptu')[0].value)
        }
        if(document.getElementsByName('gas')[0].value){
            valorAluguel += parseFloat(document.getElementsByName('gas')[0].value)
        }
        if(document.getElementsByName('agua')[0].value){
            valorAluguel += parseFloat(document.getElementsByName('agua')[0].value)
        }
        if(document.getElementsByName('luz')[0].value){
            valorAluguel += parseFloat(document.getElementsByName('luz')[0].value)
        }

        document.getElementsByName('valorAluguel')[0].value = valorAluguel
    }

    limparBuscaCarta = () => {
        document.getElementsByName('codigo_carta')[0].value = ''
        document.getElementsByName('cpf_codigo')[0].value = ''
    }

    removerCartaAdicionada = (id) => {
        var remover = this.state.cartasAdicionadas
        var cliente 
        cliente = remover.splice(remover.map((carta) => {
            return carta.id
        }).indexOf(parseInt(id)), 1)
        this.setState({cartasAdicionadas: remover})
        var solidarios = this.state.solidarios
        solidarios.splice(solidarios.map((excluir) => {
            return excluir.cliente_id
        }).indexOf(cliente[0].clientes_id), 1)
        this.setState({solidarios: solidarios})
    }

    rowsCartasAdicionadas = (record) => {
        var limite = -1
        var usado = 0
        api_epar.get( '/cartagarantiacpf/' + record.cpf_cnpj.replace(/\D/g, '')).then((quantidade) => {
            console.log(quantidade.data.data)
            for(var q = 0; q < quantidade.data.data.length; q++){
                if (record.codigo != quantidade.data.data[q].codigo) continue
                if(limite < parseFloat(quantidade.data.data[q].valor_carta)){
                    limite = parseFloat(quantidade.data.data[q].valor_carta)
                }
                if(this.state.preAdicionadas.filter((pre) => {
                    return pre.codigo === quantidade.data.data[q].codigo
                }).length === 0){
                    usado += parseFloat(quantidade.data.data[q].valor_usado_total ? quantidade.data.data[q].valor_usado_total : 0)
                }
            }
            console.log(limite)
            console.log(usado)
           
        }).catch((error) => {
            console.log(error.message)
        })
        return (
            <div className="form-row" key={record.id}>
                <div className="form-group col-md-3">
                    <input type="text" className="form-control" value={record.codigo} readOnly />
                </div>
                <div className="form-group col-md-3">
                    <input type="text" className="form-control" id={'cartaValor' + record.codigo} value={FuncoesComum.formatarMoeda(record.valor_carta)} data-value={record.valor_carta} readOnly />
                </div>
                <div className="form-group col-md-4">
                    <input readOnly={this.state.cartasAdicionadas.length === 1} type="number" className="form-control" defaultValue={record.valor_usado ? record.valor_usado : ''} placeholder='Valor utilizado da carta *' id={'cartaUsado' + record.codigo} data-value={record.codigo} onChange={(evento) => {                  
                        var usadoAgora = 0
                        for(var igual = 0; igual < this.state.cartasAdicionadas.length; igual++){
                            if(record.cpf_cnpj === this.state.cartasAdicionadas[igual].cpf_cnpj && record.id !== this.state.cartasAdicionadas[igual].id){
                                console.log(this.state.cartasAdicionadas[igual]);
                                console.log(document.getElementById('cartaUsado' + this.state.cartasAdicionadas[igual].codigo).value);
                                usadoAgora += parseFloat(document.getElementById('cartaUsado' + this.state.cartasAdicionadas[igual].codigo).value ? document.getElementById('cartaUsado' + this.state.cartasAdicionadas[igual].codigo).value : 0)
                            }
                        }
                        usadoAgora += usado
                        console.log(usadoAgora);
                        if(parseFloat(evento.target.value) > parseFloat(document.getElementById('cartaValor' + evento.currentTarget.dataset.value).dataset.value)){
                            this.setState({mensagemModal: <div>
                                Valor utilizado é maior do que a carta disponibiliza
                                </div>})
                            this.toggleAlert()
                            document.getElementById('cartaUsado' + evento.currentTarget.dataset.value).value = ''
                        }
                        else if((parseFloat(evento.target.value) + record.valor_usado_total) > record.valor_carta){
                            this.setState({mensagemModal: <div>
                                Não é possível utilizar o valor {FuncoesComum.formatarMoeda(parseFloat(evento.target.value))}. Pois irá exceder o limite remanescente da carta de {FuncoesComum.formatarMoeda(limite - usadoAgora)}.
                                </div>})
                            this.toggleAlert()
                            document.getElementById('cartaUsado' + evento.currentTarget.dataset.value).value = ''
                        }
                        else if((parseFloat(evento.target.value) + usadoAgora) > limite && limite !== -1){
                            this.setState({mensagemModal: <div>
                                Não é possível utilizar o valor {FuncoesComum.formatarMoeda(parseFloat(evento.target.value))}. 
                                Pois esse CPF ou CNPJ possui mais de uma carta de crédito, 
                                então seu limite é maior valor disponível entre as cartas que é {FuncoesComum.formatarMoeda(limite)}, 
                                porém está em uso {FuncoesComum.formatarMoeda(usadoAgora)}.
                                </div>})
                            this.toggleAlert()
                            document.getElementById('cartaUsado' + evento.currentTarget.dataset.value).value = ''
                        }
                    }} />
                </div>
                <div className="form-group col-md-2">
                    <Button disabled={record.codigo === this.props.match.params.carta} className='btn-danger float-right' onClick={() => {this.removerCartaAdicionada(record.id)}}>Remover</Button>
                </div>
            </div>
        )
    }

    getCartasByCPF = (e) => {
        if (e.target.value.replace(/\D/g, '').length == 11){
            api_epar.get( '/cartagarantiacpf/' + e.target.value.replace(/\D/g, '')).then((re) => {
                console.log(re.data);
                this.setState({
                    cartas: re.data.data,
                    cartaSelected: re.data.data.length > 0 ? re.data.data[0].codigo : ''
                })
            })
        }
        else{
            this.setState({cartas: []})
        }
    }


  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        {/* <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta> */}
        {/* <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" /> */}
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" />

        <Header />
            <section>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cont_head">
                                <h1 className="display-4 mb-3" style={{fontSize: '3rem', fontWeight: 'bolder'}}>Contrato</h1>
                                <div className="linha"></div>
                            </div>
                            <div className="px-1 w-100 pt-5">
                                <form className="w-100">
                                    <div id="contrato">
                                        <div className="cabecalho">
                                            <h2>CARTAS DE CRÉDITO</h2>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-5">
                                                <input onChange={a => {this.setState({cpf: a.target.value.replace()}); this.getCartasByCPF(a)}} type="number" name='cpf_codigo' className="form-control" placeholder="CPF para validar existência do código"/>
                                            </div>

                                            <div className="form-group col-md-3">
                                                <select className="form-control" onChange={a => this.setState({cartaSelected: a.target.value})}>
                                                    {
                                                        this.state.cartas.map(e => (
                                                            <option value={e.codigo}>{e.codigo} | R${e.valor_carta}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <Button className='btn-success float-right' onClick={this.adicionarCarta}>Adicionar solidário</Button>
                                            </div>
                                        </div>
                                        
                                        {this.state.cartasAdicionadas.map(this.rowsCartasAdicionadas)}

                                        <div className="cabecalho">
                                            <h2>DADOS DO CONTRATO</h2>
                                        </div>

                                        <div className="form-group col-md-4 mb-5 p-0" >
                                            <input name='numero_contrato' className="form-control" type="text" placeholder="Nº do contrato (Opcional)" />
                                        </div>

                                        <h3 style={{display: 'none'}}>Por quanto tempo o contrato será válido?</h3>
                                        <div style={{display: 'none'}} className="form-row pt-2 mb-5" >
                                            {/* <div className="mr-3 btn m-1 mb-0 bg-roxo font-size-1">
                                                <div className="form-check button-check">
                                                    <input className="form-check-input" type="radio" name="cont-validade" id="cont-val-ind" onClick={this.changeValido} value="1" />
                                                    <label className="form-check-label font-size-9 pl-1" htmlFor="cont-val-ind">
                                                        Por tempo indeterminado
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="mr-3 btn m-1 bg-roxo">
                                                <div className="form-check button-check">
                                                    <input className="form-check-input" type="radio" name="cont-validade" id="cont-val-det" onClick={this.changeValido} value="2" checked/>
                                                    <label className="form-check-label font-size-9 pl-1" htmlFor="cont-val-det">
                                                        Por tempo determinado
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <h2>Data de início</h2>
                                                <div className="input-group mb-5 p-0">
                                                    <div className="input-group-prepend">
                                                    <span className="input-group-text" id="cont-data"><i className="far fa-calendar-alt"></i></span>
                                                    </div>
                                                    <input name='data_inicio' min={moment().format('YYYY-MM-DD')} defaultValue={moment().format('YYYY-MM-DD')} type="date" className="form-control" />
                                                </div>
                                            </div>

                                            <div className='col-md-4' name='data_termino'>
                                                <h2>Data de término</h2>
                                                <div className="input-group mb-5 p-0">
                                                    <div className="input-group-prepend">
                                                    <span className="input-group-text" id="cont-data"><i className="far fa-calendar-alt"></i></span>
                                                    </div>
                                                    <input name='data_fim' min={moment().add(12, 'month').format('YYYY-MM-DD')} defaultValue={moment().add(12, 'month').format('YYYY-MM-DD')} type="date" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="locador" className="mb-5">
                                        <div className="cabecalho">
                                            <h2>DADOS DO LOCADOR</h2>
                                        </div>

                                        <div className="form-group col-md-4 p-0">
                                            <select name='locador_tipo' className="form-control" onChange={this.changeLocador}>
                                                <option value='PF'>Pessoa Fisíca</option>
                                                <option value='PJ'>Pessoa Jurídica</option>
                                            </select>
                                        </div>
                                        <div name='locadorPF'>
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <Input type="text" name='locador_cpf' className="form-control" placeholder="CPF" onChange={this.changeCPF}/>
                                                    {/* <Input type="text" name='locador_cpf' className="form-control" placeholder="CPF" onChange={this.changeCPF} mask={'999.999.999-99'} maskChar=' ' tag={InputMask}/> */}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-9">
                                                    <input type="text" name='locador_nome' className="form-control" placeholder="Nome completo" />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <select className="form-control" name='locador_sexo'>
                                                        <option value='Sexo'>Sexo</option>
                                                        <option value='M'>Masculino</option>
                                                        <option value='F'>Feminino</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                <input type="text" name='locador_nacionalidade' className="form-control" placeholder="Nacionalidade" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                <input type="text" name='locador_profissao' className="form-control" placeholder="Profissão"/>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                {/* <div className="form-group col-md-4">
                                                    <Input type="text" name='locador_cpf' className="form-control" placeholder="CPF" />
                                                </div> */}
                                                <div className="form-group col-md-4">
                                                    <Input type="text" name='locador_rg' className="form-control" placeholder="RG"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locador_orgEmissor' className="form-control" placeholder="Orgão Emissor"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <select name='locador_estadoCivil' className="form-control" >
                                                        <option value='Estado cívil'>Estado cívil</option>
                                                        <option value='Casado(a)'>Casado(a)</option>
                                                        <option value='Solteiro(a)'>Solteiro(a)</option>
                                                        <option value='Viúvo'>Viúvo</option>
                                                        <option value='Divorciado'>Divorciado</option>
                                                        <option value='Em uma união estável'>Em uma união estável</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/* <div className="form-group col-md-4 p-0">
                                                <select name='locador_estadoCivil' className="form-control" >
                                                    <option value='Estado cívil'>Estado cívil</option>
                                                    <option value='Casado(a)'>Casado(a)</option>
                                                    <option value='Solteiro(a)'>Solteiro(a)</option>
                                                    <option value='Viúvo'>Viúvo</option>
                                                    <option value='Divorciado'>Divorciado</option>
                                                    <option value='Em uma união estável'>Em uma união estável</option>
                                                </select>
                                            </div> */}

                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                <input type="text" name='locador_residencial'  className="form-control" placeholder="Telefone residencial"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locador_celular' className="form-control" placeholder="Telefone celular"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                <input type="text" name='locador_email'  className="form-control" placeholder="Email"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div name='locadorPJ'>
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locador_cnpj' className="form-control" placeholder="CNPJ" onChange={this.changeCNPJ} />
                                                    {/* <Input type="text" name='locador_cnpj' className="form-control" placeholder="CNPJ" onChange={this.changeCNPJ} mask={'99.999.999/9999-99'} maskChar=' ' tag={InputMask}/> */}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <input type="text" name='locador_razaoSocial' className="form-control" placeholder="Razão social" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="text" name='locador_fantasia' className="form-control" placeholder="Nome fantasia" />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                {/* <div className="form-group col-md-4">
                                                    <input type="text" name='locador_cnpj' className="form-control" placeholder="CNPJ" />
                                                </div> */}
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locador_pjEmail' className="form-control" placeholder="Email"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locador_telContato' className="form-control" placeholder="Telefone de contato"/>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <Input type="text" name='locador_cep'  className="form-control" placeholder="CEP" onChange={this.changeCep}/>
                                            </div>
                                            <div className="form-group col-md-10">
                                            <input type="text" name='locador_end' className="form-control" placeholder="Endereço"/>
                                            </div>
                                            <div className="form-group col-md-2">
                                            <input type="text" name='locador_endNum' className="form-control" placeholder="Nº"/>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                            <input type="text" name='locador_endComplemento' className="form-control" placeholder="Complemento"/>
                                            </div>
                                            <div className="form-group col-md-8">
                                            <input type="text" name='locador_endBairro' className="form-control" placeholder="Bairro"/>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-8">
                                            <input type="text" name='locador_endCidade'  className="form-control" placeholder="Cidade"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <select className="form-control" name='locador_endEstado'>
                                                    <option value>Selecione</option>
                                                    <option value='AC'>Acre</option>
                                                    <option value='AL'>Alagoas</option>
                                                    <option value='AP'>Amapá</option>
                                                    <option value='AM'>Amazonas</option>
                                                    <option value='BA'>Bahia</option>
                                                    <option value='CE'>Ceará</option>
                                                    <option value='DF'>Distrito Federal</option>
                                                    <option value='ES'>Espírito Santo</option>
                                                    <option value='GO'>Goiás</option>
                                                    <option value='MA'>Maranhão</option>
                                                    <option value='MT'>Mato Grosso</option>
                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                    <option value='MG'>Minas Gerais</option>
                                                    <option value='PA'>Pará</option>
                                                    <option value='PB'>Paraíba</option>
                                                    <option value='PR'>Paraná</option>
                                                    <option value='PE'>Pernambuco</option>
                                                    <option value='PI'>Piauí</option>
                                                    <option value='RJ'>Rio de Janeiro</option>
                                                    <option value='RN'>Rio Grande do Norte</option>
                                                    <option value='RS'>Rio Grande do Sul</option>
                                                    <option value='RO'>Rondônia</option>
                                                    <option value='RR'>Roraima</option>
                                                    <option value='SC'>Santa Catarina</option>
                                                    <option value='SP'>São Paulo</option>
                                                    <option value='SE'>Sergipe</option>
                                                    <option value='TO'>Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> 

                                    
                                    <div id="locatario" className="mb-5">
                                        <div className="cabecalho">
                                            <h2>DADOS DO LOCATÁRIO PRINCIPAL</h2>
                                        </div>

                                        <div className="form-group col-md-4 p-0">
                                            <select name='locatario_tipo' className="form-control" onChange={this.changeLocatario}>
                                                <option value='PF'>Pessoa Fisíca</option>
                                                <option value='PJ'>Pessoa Jurídica</option>
                                            </select>
                                        </div>
                                        
                                        <div name='locatarioPF'>
                                            <div className="form-row">
                                                <div className="form-group col-md-9">
                                                <input type="text" name='locatario_nome' className="form-control" placeholder="Nome completo"/>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <select name='locatario_sexo' className="form-control">
                                                        <option value='Sexo'>Sexo</option>
                                                        <option value='M'>Masculino</option>
                                                        <option value='F'>Feminino</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                <input type="text" name='locatario_nacionalidade' className="form-control" placeholder="Nacionalidade"/>
                                                </div>
                                                <div className="form-group col-md-6">
                                                <input type="text" name='locatario_profissao'  className="form-control" placeholder="Profissão"/>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                <Input type="text" name='locatario_cpf' className="form-control" placeholder="CPF" />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <Input type="text" name='locatario_rg' className="form-control" placeholder="RG"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                <input type="text" name='locatario_orgEmissor' className="form-control" placeholder="Orgão Emissor"/>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-4 p-0">
                                                <select className="form-control" name='locatario_estadoCivil'>
                                                    <option value='Estado cívil'>Estado cívil</option>
                                                    <option value='Casado(a)'>Casado(a)</option>
                                                    <option value='Solteiro(a)'>Solteiro(a)</option>
                                                    <option value='Viúvo'>Viúvo</option>
                                                    <option value='Divorciado'>Divorciado</option>
                                                    <option value='Em uma união estável'>Em uma união estável</option>
                                                </select>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                <input type="text" name='locatario_residencial' className="form-control" placeholder="Telefone residencial"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locatario_celular' className="form-control" placeholder="Telefone celular"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                <input type="text" name='locatario_email'  className="form-control" placeholder="Email"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div name='locatarioPJ'>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <input type="text" name='locatario_razaoSocial' className="form-control" placeholder="Razão social" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="text" name='locatario_fantasia' className="form-control" placeholder="Nome fantasia" />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locatario_cnpj' className="form-control" placeholder="CNPJ" />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locatario_pjEmail' className="form-control" placeholder="Email"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <input type="text" name='locatario_telContato' className="form-control" placeholder="Telefone de contato"/>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <Input type="text" name='locatario_cep' className="form-control" placeholder="CEP" onChange={this.changeCep} />
                                            </div>
                                            <div className="form-group col-md-10">
                                            <input type="text" name='locatario_end'  className="form-control" placeholder="Endereço"/>
                                            </div>
                                            <div className="form-group col-md-2">
                                            <input type="text" name='locatario_endNum' className="form-control" placeholder="Nº"/>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                            <input type="text" name='locatario_endComplemento' className="form-control" placeholder="Complemento"/>
                                            </div>
                                            <div className="form-group col-md-8">
                                            <input type="text" name='locatario_endBairro' className="form-control" placeholder="Bairro"/>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-8">
                                            <input type="text" name='locatario_endCidade' className="form-control" placeholder="Cidade"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                            <select className="form-control" name='locatario_endEstado'>
                                                    <option value>Selecione</option>
                                                    <option value='AC'>Acre</option>
                                                    <option value='AL'>Alagoas</option>
                                                    <option value='AP'>Amapá</option>
                                                    <option value='AM'>Amazonas</option>
                                                    <option value='BA'>Bahia</option>
                                                    <option value='CE'>Ceará</option>
                                                    <option value='DF'>Distrito Federal</option>
                                                    <option value='ES'>Espírito Santo</option>
                                                    <option value='GO'>Goiás</option>
                                                    <option value='MA'>Maranhão</option>
                                                    <option value='MT'>Mato Grosso</option>
                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                    <option value='MG'>Minas Gerais</option>
                                                    <option value='PA'>Pará</option>
                                                    <option value='PB'>Paraíba</option>
                                                    <option value='PR'>Paraná</option>
                                                    <option value='PE'>Pernambuco</option>
                                                    <option value='PI'>Piauí</option>
                                                    <option value='RJ'>Rio de Janeiro</option>
                                                    <option value='RN'>Rio Grande do Norte</option>
                                                    <option value='RS'>Rio Grande do Sul</option>
                                                    <option value='RO'>Rondônia</option>
                                                    <option value='RR'>Roraima</option>
                                                    <option value='SC'>Santa Catarina</option>
                                                    <option value='SP'>São Paulo</option>
                                                    <option value='SE'>Sergipe</option>
                                                    <option value='TO'>Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.solidarios.map(this.carregarSolidarios)}

                                    <div id="imovel" className="mb-5"> 
                                        <div className="cabecalho">
                                            <h2>DADOS DO IMÓVEL</h2>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                    <label className="input-group-text" htmlFor="imv-tipo"><i className="fas fa-home"></i></label>
                                                    </div>
                                                    <select name='imovel_tipo' className="custom-select" id="imv-tipo">
                                                        <option value='Residencial'>Residencial</option>
                                                        <option value='Comercial'>Comercial</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-8">
                                                <input name='imovel_finalidade' type="text" className="form-control" placeholder="Finalidade(Ex: Moradia, Escritório, etc...)"/>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div id="endereco" className="mb-5">
                                        <div className="cabecalho">
                                            <h2>ENDEREÇO DO IMÓVEL</h2>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-8">
                                            <input name='imovel_tipoImovel' type="text" className="form-control" placeholder="Tipo de imóvel (Ex: Casa, Apartamento, Galpão, etc.)"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                            <Input name='imovel_cep' type="text" className="form-control" placeholder="CEP" onChange={this.changeCep} />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-10">
                                            <input type="text" name='imovel_end' className="form-control" placeholder="Endereço"/>
                                            </div>
                                            <div className="form-group col-md-2">
                                            <input type="text" name='imovel_endNum' className="form-control" placeholder="Nº"/>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                            <input type="text" name='imovel_endComplemento' className="form-control" placeholder="Complemento"/>
                                            </div>
                                            <div className="form-group col-md-8">
                                            <input type="text" name='imovel_endBairro' className="form-control" placeholder="Bairro"/>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-8">
                                            <input type="text" name='imovel_endCidade' className="form-control" placeholder="Cidade"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                            <select className="form-control" name='imovel_endEstado'>
                                                    <option value>Selecione</option>
                                                    <option value='AC'>Acre</option>
                                                    <option value='AL'>Alagoas</option>
                                                    <option value='AP'>Amapá</option>
                                                    <option value='AM'>Amazonas</option>
                                                    <option value='BA'>Bahia</option>
                                                    <option value='CE'>Ceará</option>
                                                    <option value='DF'>Distrito Federal</option>
                                                    <option value='ES'>Espírito Santo</option>
                                                    <option value='GO'>Goiás</option>
                                                    <option value='MA'>Maranhão</option>
                                                    <option value='MT'>Mato Grosso</option>
                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                    <option value='MG'>Minas Gerais</option>
                                                    <option value='PA'>Pará</option>
                                                    <option value='PB'>Paraíba</option>
                                                    <option value='PR'>Paraná</option>
                                                    <option value='PE'>Pernambuco</option>
                                                    <option value='PI'>Piauí</option>
                                                    <option value='RJ'>Rio de Janeiro</option>
                                                    <option value='RN'>Rio Grande do Norte</option>
                                                    <option value='RS'>Rio Grande do Sul</option>
                                                    <option value='RO'>Rondônia</option>
                                                    <option value='RR'>Roraima</option>
                                                    <option value='SC'>Santa Catarina</option>
                                                    <option value='SP'>São Paulo</option>
                                                    <option value='SE'>Sergipe</option>
                                                    <option value='TO'>Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="aluguel" className="mb-5">
                                        <div className="cabecalho">
                                            <h2>ALUGUEL:</h2>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="iptu_imposto">Índice cadastral do imovél:</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"></span>
                                                    </div>
                                                    <input name='indice_cadastral' type="" className="form-control" placeholder="Índice" id="indice_cadastral" />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="gas">Código de ligação de energia:</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"></span>
                                                    </div>
                                                    <input name='cod_lig_energia' type="" className="form-control" placeholder="Código da energia" id="cod_lig_energia" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="iptu_imposto">Código de ligação de água:</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"></span>
                                                    </div>
                                                    <input name='cod_lig_agua' type="" className="form-control" placeholder="Código da água" id="cod_lig_agua" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="iptu_imposto">Nome da administradora: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"></span>
                                                    </div>
                                                    <input name='nome_adm' type="" className="form-control" placeholder="Nome administradora" id="nome_adm"/>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="gas">Telefone da administradora: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"></span>
                                                    </div>
                                                    <Input name='tel_adm' className="form-control" placeholder="Telefone administradora" id="tel_adm" mask={this.state.mask_tel} maskChar=' ' tag={InputMask}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="iptu_imposto">Nome do síndico: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"></span>
                                                    </div>
                                                    <input name='nome_sindico' type="" className="form-control" placeholder="Nome síndico" id="nome_sindico"/>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="gas">Telefone do síndico: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"></span>
                                                    </div>
                                                    <Input name='tel_sindico' className="form-control" placeholder="Telefone síndico" id="tel_sindico" mask={this.state.mask_tel} maskChar=' ' tag={InputMask}/>
                                                </div>
                                            </div>
                                        </div>

                                        <br/>
                                        <br/>

                                        <div className="form-group">
                                            <label htmlFor="alg_valor">Valor mensal do aluguel + encargos:</label>
                                            <div className="input-group col-md-4 p-0">
                                                <div className="input-group-prepend">
                                                {/* <span className="input-group-text"><i className="fas fa-dollar-sign"></i></span> */}
                                                <span className="input-group-text">R$</span>
                                                </div>
                                                <input name='valorAluguel' type="number" className="form-control" placeholder="Valor do aluguel" id="alg_valor" readOnly/>
                                                {/* <CurrencyInput placeholder='Valor do aluguel' id="alg_valor" className={'form-control'} decimalSeparator="," thousandSeparator="."  name='valorAluguel' /> */}
                                            </div>
                                            <label style={{marginTop: '10px', fontSize: '0.9em', color: 'red'}}><i>Os centavos podem ser separados por vírgula ( , ) ou ponto final ( . ).</i></label>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="alug">Valor do aluguel: *</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                    {/* <span className="input-group-text" ><i className="fas fa-dollar-sign"></i></span> */}
                                                    <span className="input-group-text" >R$</span>
                                                    </div>
                                                    <input name='aluguel' type="number" className="form-control" placeholder="Valor do aluguel" id="alug" onChange={this.changeValorAluguel}/>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="cond">Valor do condomínio: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                    {/* <span className="input-group-text" ><i className="fas fa-dollar-sign"></i></span> */}
                                                    <span className="input-group-text" >R$</span>
                                                    </div>
                                                    <input name='condominio' type="number" className="form-control" placeholder="Valor do condomínio" id="cond" onChange={this.changeValorAluguel}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="iptu_imposto">Valor do IPTU: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                    {/* <span className="input-group-text" ><i className="fas fa-dollar-sign"></i></span> */}
                                                    <span className="input-group-text" >R$</span>
                                                    </div>
                                                    <input name='iptu' type="number" className="form-control" placeholder="Valor do IPTU" id="iptu_imposto" onChange={this.changeValorAluguel}/>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="gas">Valor do gás canalizado: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                    {/* <span className="input-group-text" ><i className="fas fa-dollar-sign"></i></span> */}
                                                    <span className="input-group-text" >R$</span>
                                                    </div>
                                                    <input name='gas' type="number" className="form-control" placeholder="Valor do gás canalizado" id="gas" onChange={this.changeValorAluguel}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="agua">Valor da água: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                    {/* <span className="input-group-text" ><i className="fas fa-dollar-sign"></i></span> */}
                                                    <span className="input-group-text" >R$</span>
                                                    </div>
                                                    <input name='agua' type="number" className="form-control" placeholder="Valor da água" id="agua" onChange={this.changeValorAluguel}/>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="luz">Valor da luz: (Se necessário)</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                    {/* <span className="input-group-text" ><i className="fas fa-dollar-sign"></i></span> */}
                                                    <span className="input-group-text" >R$</span>
                                                    </div>
                                                    <input name='luz' type="number" className="form-control" placeholder="Valor da luz" id="luz" onChange={this.changeValorAluguel}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <input id="termo" type="checkbox" checked={this.state.check} onChange={event => {this.setState({check: event.target.checked})}}/>
                                    <label htmlFor="termo" className='ml-2'>
                                        {/* <Link to='/termos' target='_blank'>Leia nossos termos clicando aqui.</Link> Concordo com os termos apresentados.  */}
                                        <Link to='/termo_alugmais.pdf' target='_blank'>Leia nossos termos clicando aqui.</Link> Li e concordo com os termos e condições da Garantia Alug +. Confirmo as verbas contratadas acima. 
                                    </label><br/><br/>
                                    <i style={{color: '#990000'}}>* Campos obrigatórios, necessário preencher pelo menos um valor de locação para ser calculado o valor total.</i>
                                    {/* <Button className="w-100 btn-dark btn-lg" to={'/admin/gerarcontrato/' + this.props.match.params.consulta} tag={Link} onClick={this.registrar}>Continuar</Button> */}
                                    <Button className="w-100 btn-dark btn-lg mt-3 btn-success" onClick={this.registrar}>Gerar contrato</Button>
                                    {/* <Button id='downloadCartaGarantia' style={{display: 'none'}} className="w-100 btn-success btn-lg mt-3" onClick={() => {window.open('/downloadgarantia/' + this.state.idContrato, '_blank')}}>Download do contrato de garantia</Button> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
              <ModalHeader toggle={this.toggleAlert}>Alerta</ModalHeader>
              <ModalBody>
                  <Row>
                      <Col md={12}>
                          {this.state.mensagemModal}
                      </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                  <Button className="btn-dark" onClick={this.toggleAlert}>Fechar</Button>
              </ModalFooter>
            </Modal>
      </>
    );
  }
}

export default FormularioContratoCarta;
