/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    Input,
    InputGroup,
    Table,
    Container,
    Label,
    Button
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'

import Header from "components/Headers/Header.jsx";

import ReactDOM from 'react-dom';
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import ReactGA from 'react-ga'

class GerarContrato extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            tiposContratos: [],
            contrato: '',
            dadosParaContrato: [],
            consulta: this.props.match.params.id,
            retornoConsulta: [],
            pessoas: [],
            tamanhoCol: 12,
            dadosImobiliaria: [],
            usuario: JSON.parse(localStorage.getItem('dadosUsuario_test'))
        }
        // console.log(props.match.params.id)
    }

    componentDidMount(){

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

        api_epar.get( '/contrato/imob/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((retornoContratos) => {
            this.setState({tiposContratos: retornoContratos.data.data, contrato: retornoContratos.data.data[0].id})
        }).catch((error) => {
            console.log(error)
        })
        api_epar.get( '/imobiliaria/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((retornoImobiliaria) => {
          this.setState({dadosImobiliaria: retornoImobiliaria.data.data[0]})
          console.log(retornoImobiliaria.data.data[0])
        }).catch((error) => {
            console.log(error)
        })
        if(this.props.match.params.id !== ':id'){
          api_epar.get( '/clienteshasconsulta/user/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).id + '/' + this.props.match.params.id).then((retornoConsulta) => {
            this.setState({dadosParaContrato: FuncoesComum.unirDadosConsulta(retornoConsulta)})
            console.log(FuncoesComum.unirDadosConsulta(retornoConsulta))
          }).catch((error) => {
              console.log(error)
          })
        }
        api_epar.get( '/clienteshasconsultadetalhe/' + this.state.consulta).then((retorno) => {
            console.log(retorno)
            var dicionario = []
            var valor = retorno.data.data[0].valor
            var etapa = retorno.data.data[0].etapa
            var idConsulta = retorno.data.data[0].consulta_id
            var dataConsulta = retorno.data.data[0].data_consulta
            var pessoas = []
            var ids = new Set(retorno.data.data.map((e) => {
                return e.clientes_id
            }))
            for(let id of ids){
                pessoas.push(this.pessoasRetorno(retorno, id))
            }
            console.log(pessoas)
            var principal = parseInt(pessoas.map((e) => {
              return e.tipo_locatario
            }).indexOf('Principal'))
            if(principal !== 0){
              var newOrdem = [pessoas[principal]]
              for(var i = 0; i < pessoas.length; i++){
                if(i !== principal){
                  newOrdem.push(pessoas[i])
                }
              }
              pessoas = newOrdem
            }
            console.log(pessoas)
            dicionario = [{
              consulta_id: idConsulta,
              valor: valor,
              pessoas: pessoas,
              etapa: etapa,
              data_consulta: dataConsulta,
            }]
            console.log(dicionario)
            // console.log(pessoas)
            this.setState({tamanhoCol: this.state.tamanhoCol / ids.size, retornoConsulta: dicionario, pessoas: dicionario[0].pessoas})
        }).catch((error) => {
            console.log(error)
        })
    }

    pessoasRetorno = (retorno, id_cliente) => {
      console.log(retorno)
      var dadosFalta = 0 
      var dadosCadastrais
      var dadosFinanceiros 
      var tipo_locatario
      var nome
      var cpf_cnpj
      var telefone
      var renda_informada
      var status = ''
      var motivo = ''
      var cliente_id
      for(var i = 0; i < retorno.data.data.length; i++){
          if(retorno.data.data[i].clientes_id === id_cliente){
              if(dadosFalta === 0){
                  nome = retorno.data.data[i].nome
                  cpf_cnpj = retorno.data.data[i].cpf_cnpj
                  tipo_locatario = retorno.data.data[i].tipo_locatario
                  telefone = retorno.data.data[i].telefone
                  renda_informada = retorno.data.data[i].renda_informada
                  status += retorno.data.data[i].status + ', '
                  motivo += retorno.data.data[i].motivo + ', '
                  cliente_id = retorno.data.data[i].clientes_id
                  if(retorno.data.data[i].tipo_consulta_id === 1){
                      dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
                      dadosFalta = 1
                  }
                  else if(retorno.data.data[i].tipo_consulta_id === 2){
                      dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json)
                      dadosFalta = 2
                  }
              }
              else if(dadosFalta === 1 && retorno.data.data[i].tipo_consulta_id === 2){
                dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json.replace(/'/g, '"'))
              }
              else if(dadosFalta === 2 && retorno.data.data[i].tipo_consulta_id === 1){
                  dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
              }
              if(retorno.data.data[i].tipo_consulta_id === 3){
                status += retorno.data.data[i].status + ', '
                motivo += retorno.data.data[i].motivo + ', '
              }
          }
      }
      return {
          nome: nome,
          cliente_id: cliente_id,
          cpf_cnpj: cpf_cnpj,
          tipo_locatario: tipo_locatario,
          telefone: telefone,
          renda_informada: renda_informada,
          status: status,
          motivo: motivo,
          dadosCadastrais: dadosCadastrais,
          dadosFinanceiros: dadosFinanceiros
      }
    }

    changeContrato = (evento) => {
        this.setState({ contrato: evento.target.value} )
    }

    atualizarEtapaConsulta = (etapa) => {
      api_epar.put( '/consulta', {etapa: etapa, id: this.props.match.params.id}).then((consultaAtualizada) => {
          console.log(consultaAtualizada)
      }).catch((error) => {
          console.log(error)
      })
    }

    // nomeConcat = (record) => {
    //   return (' ' + record.dadosCadastrais.content.nome.conteudo.nome)
    // }

    // dadoConcat = (record) => {
    //   return (' ' + record.cpf_cnpj)
    // }

    gerarContrato = () => {
        ReactDOM.render(<div></div>, document.getElementById('editorContrato'))
        api_epar.get( '/contrato/' + this.state.contrato).then((retornoContrato) => {
            var atualizarContrato = retornoContrato.data.data[0].contrato
            .replace(/<code>@nome<\/code>/g, this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.nome)
            .replace(/<code>@cpf principal<\/code>/g, this.state.pessoas[0].cpf_cnpj)  
            .replace(/<code>@nacionalidade principal<\/code>/g, this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.estrangeiro.pais_origem.origem)  
            .replace(/<code>@sexo principal<\/code>/g, this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.sexo)  
            .replace(/<code>@idade principal<\/code>/g, this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.idade)  
            .replace(/<code>@data de nascimento principal<\/code>/g, this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.data_nascimento)  
            .replace(/<code>@estrangeiro principal<\/code>/g, this.state.pessoas[0].dadosCadastrais.content.nome.conteudo.estrangeiro.estrangeiro)  
            .replace(/<code>@renda pro principal<\/code>/g, 'R$ ' + this.state.pessoas[0].dadosCadastrais.content.renda_pro.conteudo.renda)  
            .replace(/<code>@renda informada principal<\/code>/g, this.state.pessoas[0].renda_informada)  
            .replace(/<code>@valor do aluguel<\/code>/g, this.state.retornoConsulta[0].valor)  
            .replace(/<code>@data da consulta<\/code>/g, this.state.retornoConsulta[0].data_consulta)  
            .replace(/<code>@nome usuario<\/code>/g, this.state.usuario.nome)  
            .replace(/<code>@email usuario<\/code>/g, this.state.usuario.email)  
            .replace(/<code>@razao social imobiliaria<\/code>/g, this.state.imobiliaria.razao_social)  
            .replace(/<code>@cnpj imobiliaria<\/code>/g, this.state.imobiliaria.cnpj)  
            .replace(/<code>@telefone imobiliaria<\/code>/g, this.state.imobiliaria.telefone)  
            .replace(/<code>@celular imobiliaria<\/code>/g, this.state.imobiliaria.celular)  
            .replace(/<code>@endereco imobiliaria<\/code>/g, this.state.imobiliaria.endereco)  
            .replace(/<code>@numero imobiliaria<\/code>/g, this.state.imobiliaria.numero)  
            .replace(/<code>@complemento imobiliaria<\/code>/g, this.state.imobiliaria.complemento)  
            .replace(/<code>@bairro imobiliaria<\/code>/g, this.state.imobiliaria.bairro)  
            .replace(/<code>@cidade imobiliaria<\/code>/g, this.state.imobiliaria.cidade)  
            .replace(/<code>@estado imobiliaria<\/code>/g, this.state.imobiliaria.estado)  
            ReactDOM.render(<Editor
                apiKey='0ru2y66aocmef191wyzppdto1j5o4xeerj331tksrvbopnqz'
                initialValue={atualizarContrato}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                    'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                    'save table directionality emoticons template paste'
                  ],
                  toolbar:
                    'print',
                }}
              />, document.getElementById('editorContrato'))
              this.atualizarEtapaConsulta('Gerar contrato')
        }).catch((error) => {
            console.log(error)
        })
    }

    // Modelos de contrato criados para a imobiliaria que está conectada no sistema 
    rowsContratos = (record) => {
        return(
            <option key={record.id} value={record.id}>{record.descricao}</option>
        )
    } 

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className={"mt-5 pb-8"} fluid style={{minHeight: '65vh'}}>
            <Row>
                <Col md={10}>
                    <InputGroup className="input-group-alternative mb-3">
                        <Input style={{color: 'black'}} type='select' value={this.state.contrato} onChange={this.changeContrato}>
                            {this.state.tiposContratos.map(this.rowsContratos)}
                        </Input>
                    </InputGroup>
                </Col>
                <Col md={2}>
                    <Button onClick={this.gerarContrato} className='btn-dark float-right' >Gerar contrato</Button>
                </Col>
            </Row>
            <div className='mt-4' id='editorContrato'>
                
            </div>
        </Container>
      </>
    );
  }
}

export default GerarContrato;
