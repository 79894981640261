/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import moment from "moment";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import { 
    Container,
    TabContent, 
    TabPane, 
    Nav, 
    NavItem, 
    NavLink,
    Card,
    CardBody,
    Button, 
    CardTitle, 
    CardText, 
    Row, 
    Col,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalHeader,
    ModalBody, 
    ModalFooter
} from 'reactstrap';

import {Link} from 'react-router-dom'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import "assets/scss/wizard.scss";

import axios from 'axios'
import Header from "components/Headers/Header.jsx"

import FuncoesProcob from "funcoes/FuncoesProcob"

import InputMask from 'react-input-mask'
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum'

class Wizard extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            activeTab: '1',
            modal: false,
            valor: '',
            dado: '',
            renda: '',
            nome: '',
            telefone: '',
            cliente_id: '',
            mask: '999.999.999-99',
            dadoSolidario: '',
            rendaSolidario: '',
            nomeSolidario: '',
            telefoneSolidario: '',
            clienteSolidario_id: '',
            maskSolidario: '999.999.999-99',
            tipo_consulta: '1',
            pessoasBusca: [],
            tiposContratos: [],
            contrato: '',
            consulta_id: '',
            link: '/admin/gerarcontrato/:id'
        }
    }

    // O que vai acontecer a cada REFRESH ou CARREGAMENTO da página
    componentDidMount(){
        // if(this.props.location.pathname.split('/')[3]){
        //     api_epar.get( '/clienteshasconsulta/user/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).id + '/' + this.props.location.pathname.split('/')[3]).then((consultas) => {

        //         var retorno = FuncoesComum.unirDadosConsulta(consultas)
        //         var pessoasBusca

        //         var pessoa = [{
        //             cliente: retorno[0].cliente_id,
        //             nome: retorno[0].principal,
        //             telefone: retorno[0].telefone,
        //             renda: retorno[0].renda_informada,
        //             dado: retorno[0].cpf_cnpj,
        //             quem: 'Principal',
        //             resultado: retorno[0].status,
        //             motivo: retorno[0].motivo,
        //         }]

        //         pessoasBusca = pessoa

        //         this.setState({valor: retorno[0].valor})
        //         localStorage.setItem('valor_test', retorno[0].valor)

        //         this.setState({nome: retorno[0].principal})
        //         localStorage.setItem('nome_test', retorno[0].principal)

        //         this.setState({dado: retorno[0].cpf_cnpj})
        //         localStorage.setItem('dado_test', retorno[0].cpf_cnpj)

        //         this.setState({renda: retorno[0].renda})
        //         localStorage.setItem('renda_test', retorno[0].renda_informada)

        //         this.setState({telefone: retorno[0].telefone})
        //         localStorage.setItem('telefone_test', retorno[0].telefone)

        //         this.setState({cliente_id: retorno[0].cliente_id})
        //         localStorage.setItem('cliente_id_test', retorno[0].cliente_id)

        //         this.setState({consulta_id: retorno[0].consulta_id})
        //         localStorage.setItem('consulta_id_test', retorno[0].consulta_id)

        //         for(var i = 0; i < retorno[0].solidario.length; i++){
        //             var solidario = [{
        //                 cliente: retorno[0].solidario[i].cliente_id,
        //                 nome: retorno[0].solidario[i].nome,
        //                 telefone: retorno[0].solidario[i].telefone,
        //                 renda: retorno[0].solidario[i].renda_informada,
        //                 dado: retorno[0].solidario[i].cpf_cnpj,
        //                 quem: 'Solidário',
        //                 resultado: retorno[0].solidario[i].status,
        //                 motivo: retorno[0].solidario[i].motivo,
        //             }]
        //             pessoasBusca = pessoasBusca.concat(solidario)
        //         }

        //         this.setState({pessoasBusca: pessoasBusca})
        //         localStorage.setItem('pessoasBusca_test', JSON.stringify(pessoasBusca))

        //         if(retorno[0].etapa === 'Imóvel e valor de aluguel'){
        //             this.setState({activeTab: '2'})
        //             localStorage.setItem('activeTab_test', '2')
        //         }
        //         else if(retorno[0].etapa === 'Dados pessoais'){
        //             this.setState({activeTab: '3'})
        //             localStorage.setItem('activeTab_test', '3')
        //         }
        //         else{
        //             this.setState({activeTab: '4'})
        //             localStorage.setItem('activeTab_test', '4')
        //         }

        //     }).catch((error) => {
        //         console.log(error)
        //     })
        // }

        if(localStorage.getItem('activeTab_test')){
            this.setState({activeTab: localStorage.getItem('activeTab_test')})
        }
        if(localStorage.getItem('valor_test')){
            this.setState({valor: localStorage.getItem('valor_test')})
        }
        if(localStorage.getItem('dado_test')){
            this.setState({dado: localStorage.getItem('dado_test')})
        }
        if(localStorage.getItem('renda_test')){
            this.setState({renda: localStorage.getItem('renda_test')})
        }
        if(localStorage.getItem('telefone_test')){
            this.setState({telefone: localStorage.getItem('telefone_test')})
        }
        if(localStorage.getItem('nome_test')){
            this.setState({nome: localStorage.getItem('nome_test')})
        }
        if(localStorage.getItem('mask_test')){
            this.setState({mask: localStorage.getItem('mask_test')})
        }
        if(localStorage.getItem('dadoSolidario_test')){
            this.setState({dadoSolidario: localStorage.getItem('dadoSolidario_test')})
        }
        if(localStorage.getItem('nomeSolidario_test')){
            this.setState({nomeSolidario: localStorage.getItem('nomeSolidario_test')})
        }
        if(localStorage.getItem('telefoneSolidario_test')){
            this.setState({telefoneSolidario: localStorage.getItem('telefoneSolidario_test')})
        }
        if(localStorage.getItem('rendaSolidario_test')){
            this.setState({rendaSolidario: localStorage.getItem('rendaSolidario_test')})
        }
        if(localStorage.getItem('maskSolidario_test')){
            this.setState({maskSolidario: localStorage.getItem('maskSolidario_test')})
        }
        if(localStorage.getItem('pessoasBusca_test')){
            this.setState({pessoasBusca: JSON.parse(localStorage.getItem('pessoasBusca_test'))})
        }
        if(localStorage.getItem('consulta_id_test')){
            this.setState({consulta_id: localStorage.getItem('consulta_id_test')})
        }
        if(localStorage.getItem('link_test')){
            this.setState({link: localStorage.getItem('link_test')})
        }
        if(localStorage.getItem('clienteSolidario_test')){
            this.setState({clienteSolidario_id: localStorage.getItem('clienteSolidario_id_test')})
        }
        if(localStorage.getItem('cliente_id_test')){
            this.setState({cliente_id: localStorage.getItem('cliente_id_test')})
        }

        api_epar.get( '/contrato/imob/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((retornoContratos) => {
            this.setState({tiposContratos: retornoContratos.data.data, contrato: retornoContratos.data.data[0].id})
        }).catch((error) => {
            console.log(error)
        })

    }

    // Trocar a mascara do CPF/CNPJ do locador PRINCIPAL
    mascara = (evento) => {
        if(evento.target.value === 'cpf'){
            this.setState({mask: '999.999.999-99'})
            localStorage.setItem('mask_test', '999.999.999-99')
        }
        else{
            this.setState({mask: '99.999.999/9999-99'})
            localStorage.setItem('mask_test', '99.999.999/9999-99')
        }
    }

    // Trocar a mascara do CPF/CNPJ do locador SOLIDÁRIO
    mascaraSolidario = (evento) => {
        if(evento.target.value === 'cpf'){
            this.setState({maskSolidario: '999.999.999-99'})
            localStorage.setItem('maskSolidario_test', '999.999.999-99')
        }
        else{
            this.setState({maskSolidario: '99.999.999/9999-99'})
            localStorage.setItem('maskSolidario_test', '99.999.999/9999-99')

        }
    }

    // Início: Alteração de valores 
    changeValor = (evento) => {
        this.setState({valor: evento.target.value})
        localStorage.setItem('valor_test', evento.target.value)
    }

    changeDado = (evento) => {
        this.setState({ dado: evento.target.value} )
        localStorage.setItem('dado_test', evento.target.value)
    }
    
    changeRenda = (evento) => {
        this.setState({ renda: evento.target.value})
        localStorage.setItem('renda_test', evento.target.value)
    }

    changeTelefone = (evento) => {
        this.setState({ telefone: evento.target.value} )
        localStorage.setItem('telefone_test', evento.target.value)
    }

    changeNome = (evento) => {
        this.setState({ nome: evento.target.value} )
        localStorage.setItem('nome_test', evento.target.value)
    }

    changeDadoSolidario = (evento) => {
        this.setState({ dadoSolidario: evento.target.value} )
        localStorage.setItem('dadoSolidario_test', evento.target.value)
    }
    
    changeRendaSolidario = (evento) => {
        this.setState({ rendaSolidario: evento.target.value})
        localStorage.setItem('rendaSolidario_test', evento.target.value)
    }

    changeTelefoneSolidario = (evento) => {
        this.setState({ telefoneSolidario: evento.target.value} )
        localStorage.setItem('telefoneSolidario_test', evento.target.value)
    }

    changeNomeSolidario = (evento) => {
        this.setState({ nomeSolidario: evento.target.value} )
        localStorage.setItem('nomeSolidario_test', evento.target.value)
    }

    changeContrato = (evento) => {
        this.setState({ contrato: evento.target.value} )
    }
    // Término: Alteração de valores

    // Etapa atual do processo
    toggle = tab => {
        if(this.state.activeTab !== tab){
            this.setState({activeTab: tab});
            localStorage.setItem('activeTab_test', tab)
        } 
    }

    // Etapa atual do processo
    toggleEtapa = tab => {
        if(this.state.activeTab !== tab && (tab === '1' || tab === '2' || tab === '3') && (this.state.activeTab === '2' || this.state.activeTab === '3')){
            this.setState({activeTab: tab});
            localStorage.setItem('activeTab_test', tab)
        } 
    }

    atualizarEtapaConsulta = (etapa) => {
        api_epar.put( '/consulta', {etapa: etapa, id: this.state.consulta_id}).then((consultaAtualizada) => {
            console.log(consultaAtualizada)
        }).catch((error) => {
            console.log(error)
        })
    }

    novaConsulta = () => {
        localStorage.removeItem('nomeSolidario_test')
        localStorage.removeItem('rendaSolidario_test')
        localStorage.removeItem('telefoneSolidario_test')
        localStorage.removeItem('dadoSolidario_test')
        localStorage.removeItem('nome_test')
        localStorage.removeItem('renda_test')
        localStorage.removeItem('telefone_test')
        localStorage.removeItem('dado_test')
        localStorage.removeItem('pessoasBusca_test')
        localStorage.removeItem('mask_test')
        localStorage.removeItem('maskSolidario_test')
        localStorage.removeItem('valor_test')
        localStorage.removeItem('cliente_id_test')
        localStorage.removeItem('clienteSolidario_id_test')
        localStorage.removeItem('consulta_id_test')
        localStorage.setItem('activeTab_test', '1')
        this.setState({activeTab: '1'})
        this.clearSolidario()
        this.setState({
            nome: '',
            renda: '',
            telefone: '',
            dado: '',
            valor: '',
            cliente_id: '',
            mask: '999.999.999-99',
            maskSolidario: '999.999.999-99',
            dadoSolidario: '',
            pessoasBusca: []
        })
        this.props.history.push('/admin/wizard')
    }

    buscarDadosPessoasBusca = () => {
        api_epar.get( '/cliente/' + this.state.dado.replace('/', '_')).then((cliente) => {
            if(cliente.data.data.length > 0){
                var atualizar = this.state.pessoasBusca
                atualizar[0].cliente = cliente.data.data[0].id
                // atualizar[0].nome = cliente.data.data[0].nome
                // atualizar[0].telefone = cliente.data.data[0].telefone
                atualizar[0].renda = cliente.data.data[0].renda_informada
                this.setState({pessoasBusca: atualizar})
                this.setState({cliente_id: cliente.data.data[0].id})
                // this.setState({nome: cliente.data.data[0].nome})
                // this.setState({telefone: cliente.data.data[0].telefone})
                this.setState({renda: cliente.data.data[0].renda_informada})
                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                // localStorage.setItem('nome_test', cliente.data.data[0].nome)
                // localStorage.setItem('telefone_test', cliente.data.data[0].telefone)
                localStorage.setItem('renda_test', cliente.data.data[0].renda_informada)
            }
            else{
                // api_epar.post( '/cliente', {nome: this.state.nome, cpf_cnpj: this.state.dado, telefone: this.state.telefone, renda_informada: this.state.renda}).then((retorno) => {
                api_epar.post( '/cliente', {cpf_cnpj: this.state.dado, renda_informada: this.state.renda}).then((retorno) => {
                    var atualizar = this.state.pessoasBusca
                    atualizar[0].cliente = retorno.data.data
                    this.setState({pessoasBusca: atualizar})
                    this.setState({cliente_id: retorno.data.data})
                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                }).catch((error) => {
                    console.log(error)
                })
            }
        })

        if(this.state.pessoasBusca.length > 1){
            for(var i = 1; i < this.state.pessoasBusca.length; i++){
                this.solidario(i).then((resultado) => {
                    //console.log(resultado[0])
                })
            }
        }
        else{
            console.log('Não há solidário')
        }

        var principal = [{
            // nome: this.state.nome, 
            dado: this.state.dado,
            renda: this.state.renda,
            // telefone: this.state.telefone,
            quem: 'Principal'
        }]
        if(this.state.pessoasBusca.length > 0){
            if(this.state.pessoasBusca[0]['quem'] === 'Principal'){
                this.setState({pessoasBusca: this.state.pessoasBusca.splice(0, 1)})
            }
        }
        localStorage.setItem('pessoasBusca_test', JSON.stringify(principal.concat(this.state.pessoasBusca)))
        this.setState({pessoasBusca: principal.concat(this.state.pessoasBusca)})
    }

    // Trocar de etapa
    proximaEtapa = () => {
        var passar = 0
        if(this.state.activeTab === '1' && this.state.valor !== ''){
            passar = 1
            api_epar.post( '/consulta', {
               data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
               usuarios_id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id,
               valor: this.state.valor,
               etapa: 'Imóvel e valor de aluguel'
            }).then((dadoConsulta) => {
                this.setState({consulta_id: dadoConsulta.data.data})
                this.setState({link: '/admin/gerarcontrato/' + dadoConsulta.data.data})
                localStorage.setItem('consulta_id_test', dadoConsulta.data.data)
                localStorage.setItem('link_test', '/admin/gerarcontrato/' + dadoConsulta.data.data)
                console.log(dadoConsulta.data.data)
            }).catch((error) => {
                console.log(error)
            })
        }

        if(this.state.activeTab === '2' && this.state.dado !== ''){
            this.buscarDadosPessoasBusca()
            passar = 1
            this.atualizarEtapaConsulta('Dados pessoais')
        }
        if(this.state.activeTab === '3' && this.state.pessoasBusca.length > 0){
            if(this.verificar30()){
                var atualizar = this.state.pessoasBusca
                for(var i = 0; i < this.state.pessoasBusca.length; i++){
                    atualizar[i].resultado = null
                    atualizar[i].motivo = null
                }
                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                this.setState({pessoasBusca: atualizar})
                this.consultar()
                passar = 1
                this.atualizarEtapaConsulta('Realizar busca')
            }
            else{
                console.log('Não será possível prosseguir, valor maior que 30% da renda')
                alert('Não será possível prosseguir, VALOR A SER PAGO é maior que 30% da RENDA INFORMADA\nCaso queira adicionar um SOLIDÁRIO volte para etapa anterior')
            }
        }
        else if(this.state.activeTab === '3'){
            console.log('Não há pessoas')
            alert('Não há pessoas para busca')
        }

        if(this.state.activeTab === '4'){
            this.atualizarEtapaConsulta('Retorno da busca')
            this.novaConsulta()
        }
        
        if(passar === 1){
            localStorage.setItem('activeTab_test', (parseInt(this.state.activeTab) + 1).toString())
            this.setState({activeTab: (parseInt(this.state.activeTab) + 1).toString()})
        }
    }

    // Exibir MODAL para adicionar SOLIDÁRIO
    toggleModal = () => {
        if(this.state.dado !== ''){
            this.setState({modal: !this.state.modal})
        }
        else{
            console.log('Locador PRINCIPAL não foi adicionado')
        }
        //console.log(this.state.pessoasBusca)
    }

    // Adicionar SOLIDÁRIO
    addSolidario = () => {
        if(this.state.pessoasBusca.length === 0){
            var principal = [{
                // nome: this.state.nome, 
                dado: this.state.dado,
                renda: this.state.renda,
                // telefone: this.state.telefone,
                quem: 'Principal'
            }]
            if(this.state.pessoasBusca.length > 0){
                if(this.state.pessoasBusca[0]['quem'] === 'Principal'){
                    this.state.pessoasBusca.splice(0, 1)
                    this.setState({pessoasBusca: this.state.pessoasBusca})
                }
            }
            localStorage.setItem('pessoasBusca_test', JSON.stringify(principal.concat(this.state.pessoasBusca)))
            this.setState({pessoasBusca: principal.concat(this.state.pessoasBusca)})
            
            var solidario = {
                // nome: this.state.nomeSolidario, 
                dado: this.state.dadoSolidario,
                renda: this.state.rendaSolidario,
                // telefone: this.state.telefoneSolidario,
                quem: 'Solidário'
            }
            localStorage.setItem('pessoasBusca_test', JSON.stringify(principal.concat(this.state.pessoasBusca).concat(solidario)))
            this.setState({pessoasBusca: principal.concat(this.state.pessoasBusca).concat(solidario)})
            this.clearSolidario()
            this.toggleModal()
        }
        else{
            var solidario = {
                // nome: this.state.nomeSolidario, 
                dado: this.state.dadoSolidario,
                renda: this.state.rendaSolidario,
                // telefone: this.state.telefoneSolidario,
                quem: 'Solidário'
            }
            localStorage.setItem('pessoasBusca_test', JSON.stringify(this.state.pessoasBusca.concat(solidario)))
            this.setState({pessoasBusca: this.state.pessoasBusca.concat(solidario)})
            this.clearSolidario()
            this.toggleModal()
        }
    }

    // Limpar variáveis do Solidário que foi adicionado para ser adicionado um novo
    clearSolidario = () => {
        this.setState({
            // nomeSolidario: '',
            rendaSolidario: '',
            // telefoneSolidario: '',
            dadoSolidario: '',
            clienteSolidario_id: '',
            maskSolidario: '999.999.999-99',
        })
        localStorage.removeItem('nomeSolidario_test')
        localStorage.removeItem('rendaSolidario_test')
        localStorage.removeItem('telefoneSolidario_test')
        localStorage.removeItem('dadoSolidario_test')
        localStorage.removeItem('clienteSolidario_id_test')
        localStorage.removeItem('maskSolidario_test')
    }

    // Verificar a renda de 30%
    verificar30 = () => {
        var soma = 0
        for(var i = 0; i < this.state.pessoasBusca.length; i++){
            soma += this.state.pessoasBusca[i].renda
        } 
        if(this.state.valor > soma*0.3){
            // console.log('Não será possível prosseguir, 30%')
            return false
        }
        else{
            // console.log('Será possível prosseguir')
            return true
        }
    }

    // Irá carregar todas as pessoas que estarão relacionas na busca PRINCIPAL e/ou SOLIDÁRIOS
    rowsPessoas = (record) => {
        return(
            <Row key={record.dado}>
                <Col md={12}><h3 style={{fontWeight: 'bolder'}}>{record.quem}</h3></Col>
                <Col md={3}>
                    <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-chat-round" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input style={{color: 'black'}} name='dado' placeholder="CPF / CNPJ" className='desabilitar' type="text" defaultValue={record.dado} disabled/>
                    </InputGroup>
                </Col>
                {/* <Col md={4}>
                    <InputGroup className="input-group-alternative mb-3" >
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-single-02" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input style={{color: 'black'}} type="text" placeholder='Nome' className='desabilitar' defaultValue={record.nome} disabled></Input>
                    </InputGroup>
                </Col>    */}                 
                <Col md={2}>
                    <InputGroup className="input-group-alternative mb-3" >
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-money-coins" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input style={{color: 'black'}} placeholder="Renda" type="number" className='desabilitar' defaultValue={record.renda} disabled/>
                    </InputGroup>
                </Col>
                {/*<Col md={2}>
                    <InputGroup className="input-group-alternative mb-3" >
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-mobile-button" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input style={{color: 'black'}} placeholder="Celular" type="text" className='desabilitar' defaultValue={record.telefone} disabled/>
                    </InputGroup>
                </Col> */}
                <Col md={1}>
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow" data-value={record.dado} onClick={this.removerPessoa}>
                        <i className="ni ni-fat-remove" /> 
                    </div>
                </Col>
            </Row>
        )
    }

    // Remover uma pessoa que iria participar da busca
    removerPessoa = (evento) => {
        for(var i = 0; i < this.state.pessoasBusca.length; i++){
            if(evento.currentTarget.dataset.value === this.state.pessoasBusca[i].dado){
                if(this.state.pessoasBusca[i].quem === 'Principal'){
                    this.setState({pessoasBusca: []})
                    localStorage.setItem('pessoasBusca_test', '[]')
                    break
                }
                else{
                    this.state.pessoasBusca.splice(i, 1)
                    localStorage.setItem('pessoasBusca_test', JSON.stringify(this.state.pessoasBusca))
                    this.setState({pessoasBusca: this.state.pessoasBusca})
                    break
                }
            }
        }
    }

    // Identifica qual é o tipo de consulta
    realizarConsulta = () => {
        api_epar.get( '/tipoconsulta/' + this.state.tipo_consulta).then((retornoTipoConsulta) => {
            console.log(retornoTipoConsulta.data.data[0])
            if(retornoTipoConsulta.data.data[0].descricao === 'Procob - Dados Financeiros'){
                this.consultaProcob()
            }
            else{
                console.log('Falta desenvolver')
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    realizarConsultaSolidario = (dado, id_cliente) => {
        api_epar.get( '/tipoconsulta/' + this.state.tipo_consulta).then((retornoTipoConsulta) => {
            console.log(retornoTipoConsulta.data.data[0])
            if(retornoTipoConsulta.data.data[0].descricao === 'Procob - Dados Financeiros'){
                console.log(id_cliente.cliente)
                this.consultaProcobSolidario(dado, id_cliente.cliente)
            }
            else{
                console.log('Falta desenvolver')
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    aguarde = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                console.log('Esperar')
                resolve();
            }, 3000);
        });
    }

    atualizarStatusMotivo = (cliente, tipo_consulta, consulta, tabela_consulta, status, motivo) => {
        api_epar.put( '/clienteshasconsulta', {
            clientes_id: cliente,
            tipo_consulta_id: tipo_consulta,
            consulta_id: consulta,
            id_tabela_consulta: tabela_consulta,
            status: status,
            motivo: motivo
        }).then((clienteConsultaAtualizada) => {
            console.log(clienteConsultaAtualizada)
        }).catch((error) => {
            console.log(error)
        })
    }

    // Realiza a consulta do PRINCIPAL no PROCOB / estado de aprovação
    consultaProcob = () => {
        axios.get('https://api.procob.com/restricao/v1/R0001/' + this.state.dado.replace(/\D/g, ''), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((response) => {
            response.data.usuario = JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            response.data.cliente_id = this.state.cliente_id
            console.log(response.data)
            FuncoesProcob.inserirProcobConsulta(response.data, this.state.dado).then((retornoConsulta) => {
                this.aguarde().then(() => {      
                    this.inserirConsultaFinanceiro(response.data, retornoConsulta.data.data).then(() => {
                        api_epar.get( '/somapendencias/' + retornoConsulta.data.data).then((soma) => {
                            console.log(retornoConsulta.data.data)
                            console.log(soma.data.data[0].soma)
                            if(soma.data.data[0].soma > this.state.valor*0.5){
                                console.log('Negado')
                                console.log('Cliente: ' + this.state.cliente_id)
                                var atualizar = this.state.pessoasBusca
                                atualizar[0].resultado = 'Negado'
                                atualizar[0].motivo = 'Pendencias internas e financeiras é maior que 50% do valor'
                                this.setState({pessoasBusca: atualizar})
                                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                this.atualizarStatusMotivo(
                                    this.state.cliente_id,
                                    this.state.tipo_consulta,
                                    this.state.consulta_id,
                                    retornoConsulta.data.data,
                                    'Negado',
                                    '1'
                                )
                            }
                            else{
                                console.log('Aprovado com ressalva')
                                var atualizar = this.state.pessoasBusca
                                atualizar[0].resultado = 'Aprovado com ressalva'
                                atualizar[0].motivo = ''
                                this.setState({pessoasBusca: atualizar})
                                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                this.atualizarStatusMotivo(
                                    this.state.cliente_id,
                                    this.state.tipo_consulta,
                                    this.state.consulta_id,
                                    retornoConsulta.data.data,
                                    'Aprovado com ressalva',
                                    ''
                                )
                            }
                        }).catch((error) => {
                            console.log(error)
                        })    
                    }).catch((error) => {
                        console.log(error)
                    })
                })
            })
            console.log(response.data)
        }).catch((error) => {
          console.log(error)
        })
    }

    // Realiza a consulta dos SOLIDÁRIOS no PROCOB / estado de aprovação
    consultaProcobSolidario = (dado, id_cliente) => {
        axios.get('https://api.procob.com/restricao/v1/R0001/' + dado.replace(/\D/g, ''), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((response) => {
            response.data.usuario = JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            response.data.cliente_id = id_cliente
            // console.log(id_cliente)
            FuncoesProcob.inserirProcobConsulta(response.data, dado).then((retornoConsulta) => {
                this.aguarde().then(() => {
                    this.inserirConsultaFinanceiroSolidario(response.data, retornoConsulta.data.data, id_cliente).then(() => {
                        api_epar.get( '/somapendencias/' + retornoConsulta.data.data).then((soma) => {       
                            console.log(soma.data.data[0].soma)
                            for(var i = 1; i < this.state.pessoasBusca.length; i++){
                                if(this.state.pessoasBusca[i].cliente === id_cliente){
                                    if(soma.data.data[0].soma > this.state.valor*0.5){
                                        console.log('Negado')
                                        // console.log('Cliente: ' + this.state.cliente_id)
                                        var atualizar = this.state.pessoasBusca
                                        atualizar[i].resultado = 'Negado'
                                        atualizar[i].motivo = 'Pendencias internas e financeiras é maior que 50% do valor'
                                        this.setState({pessoasBusca: atualizar})
                                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                        this.atualizarStatusMotivo(
                                            atualizar[i].cliente,
                                            this.state.tipo_consulta,
                                            this.state.consulta_id,
                                            retornoConsulta.data.data,
                                            'Negado',
                                            '1'
                                        )
                                    }
                                    else{
                                        console.log('Aprovado com ressalva')
                                        var atualizar = this.state.pessoasBusca
                                        atualizar[i].resultado = 'Aprovado com ressalva'
                                        atualizar[i].motivo = ''
                                        this.setState({pessoasBusca: atualizar})
                                        localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizar))
                                        this.atualizarStatusMotivo(
                                            atualizar[i].cliente,
                                            this.state.tipo_consulta,
                                            this.state.consulta_id,
                                            retornoConsulta.data.data,
                                            'Aprovado com ressalva',
                                            ''
                                        )
                                    }
                                    break
                                }
                            }
                        }).catch((error) => {
                            console.log(error)
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
                })
            })
            console.log(response.data)
        }).catch((error) => {
          console.log(error)
        })
    }

    // Insere a consulta GERAL e os clientes que estão relacionados diretamente
    inserirConsultaFinanceiro = async (consulta, consulta_id) => {
        this.setState({resultado: [{
            nome: consulta.content.confirmei.conteudo.nome_razao,
            info: this.state.dado,
            id: this.state.consulta_id
        }]})
        //console.log(novaConsulta.data)
        api_epar.post( '/clienteshasconsulta/', {
            clientes_id: this.state.cliente_id,
            consulta_id: this.state.consulta_id,
            tipo_consulta_id: this.state.tipo_consulta,
            id_tabela_consulta: consulta_id,
            data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
            tipo_locador: 'Principal',
            retorno_json: JSON.stringify(consulta)
        }).then((retornoClienteConsulta) => {
            console.log(retornoClienteConsulta)
            api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/' + this.state.tipo_consulta).then((dadoCompetencia) => {
                console.log(dadoCompetencia.data.data[0])
                api_epar.post( '/tarifacao/', {
                    data: moment().format('YYYY-MM-DD HH:mm:ss'),
                    valor: dadoCompetencia.data.data[0].valor_cobrar,
                    tipo: 'Débito',
                    clientes_has_consulta_id: retornoClienteConsulta.data.data
                }).then((retornoTarifacao) => {
                    console.log(retornoTarifacao)
                }).catch((error) => {
                    console.log(error)
                })
            }).catch((error) => {
                console.log(error)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    // Insere a consulta GERAL e os clientes que estão relacionados diretamente
    inserirConsultaFinanceiroSolidario = async (consulta, consulta_id, cliente) => {
        //console.log(novaConsulta.data)
        // for(var i = 1; i < this.state.pessoasBusca.length; i++){

        // if(this.state.clienteSolidario !== '' && this.state.necessario >= 2){
            api_epar.post( '/clienteshasconsulta', {
                clientes_id: cliente,
                consulta_id: this.state.consulta_id,
                tipo_consulta_id: this.state.tipo_consulta,
                id_tabela_consulta: consulta_id,
                data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                tipo_locador: 'Solidário',
                retorno_json: JSON.stringify(consulta)
            }).then((retornoClienteConsulta) => {
                console.log(retornoClienteConsulta)
                api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/' + this.state.tipo_consulta).then((dadoCompetencia) => {
                    console.log(dadoCompetencia.data.data[0])
                    api_epar.post( '/tarifacao/', {
                        data: moment().format('YYYY-MM-DD HH:mm:ss'),
                        valor: dadoCompetencia.data.data[0].valor_cobrar,
                        tipo: 'Débito',
                        clientes_has_consulta_id: retornoClienteConsulta.data.data
                    }).then((retornoTarifacao) => {
                        console.log(retornoTarifacao)
                    }).catch((error) => {
                        console.log(error)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }).catch((error) => {
                console.log(error)
            })
        // }
        // }
    }

    // Verifica de o solidário existe ou não / Caso não cria novo registro
    solidario = async (indice) => {
        return ({0: api_epar.get( '/cliente/' + this.state.pessoasBusca[indice].dado.replace('/', '_')).then((clienteSolidario) => {
            if(clienteSolidario.data.data.length > 0){
                var atualizarSolidario = this.state.pessoasBusca
                atualizarSolidario[indice].cliente = clienteSolidario.data.data[0].id
                // atualizarSolidario[indice].nome = clienteSolidario.data.data[0].nome
                // atualizarSolidario[indice].telefone = clienteSolidario.data.data[0].telefone
                atualizarSolidario[indice].renda = clienteSolidario.data.data[0].renda_informada
                this.setState({pessoasBusca: atualizarSolidario})
                console.log(atualizarSolidario)
                localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizarSolidario))
                // this.setState({nomeSolidario1: clienteSolidario1.data.data[0].nome})
                // this.setState({telefoneSolidario1: clienteSolidario1.data.data[0].telefone})
                this.setState({rendaSolidario: clienteSolidario.data.data[0].renda_informada})
            }
            else{
                // api_epar.post( '/cliente/', {nome: this.state.pessoasBusca[indice].nome, cpf_cnpj: this.state.pessoasBusca[indice].dado, telefone: this.state.pessoasBusca[indice].telefone, renda_informada: this.state.pessoasBusca[indice].renda}).then((retorno) => {
                api_epar.post( '/cliente/', {cpf_cnpj: this.state.pessoasBusca[indice].dado, renda_informada: this.state.pessoasBusca[indice].renda}).then((retorno) => {
                    //response.data.cliente_id = retorno.data.data
                    var atualizarSolidario = this.state.pessoasBusca
                    atualizarSolidario[indice].cliente = retorno.data.data
                    this.setState({pessoasBusca: atualizarSolidario})
                    localStorage.setItem('pessoasBusca_test', JSON.stringify(atualizarSolidario))
                }).catch((error) => {
                    console.log(error)
                })
            }
            // axios.get('https://api.procob.com/consultas/v2/L0001/' + this.state.pessoasBusca[indice].dado.replace('/', '_'), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((dadoCliente) => {
            //     console.log(dadoCliente.data)
            //     // api_epar.post( '/consulta', {
            //     //     data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
            //     //     usuarios_id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id
            //     // }).then((dadoConsulta) => {
            //     //     console.log(dadoConsulta.data.data)
            //         api_epar.post( '/clienteshasconsulta', {
            //             clientes_id: this.state.pessoasBusca[indice].cliente,
            //             consulta_id: this.state.consulta_id,
            //             tipo_consulta_id: 2,
            //             id_tabela_consulta: null,
            //             data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
            //             retorno_json: JSON.stringify(dadoCliente.data)
            //         }).then((retornoClienteConsulta) => {
            //             console.log(retornoClienteConsulta)
            //         }).catch((error) => {
            //             console.log(error)
            //         })
            //     // }).catch((error) => {
            //     //     console.log(error)
            //     // })
            // }).catch((error) => {
            //     console.log(error)
            // })
        }), 1: indice})
    }

    solidarioDadosCadastrais = async (indice) => {
        return (
            axios.get('https://api.procob.com/consultas/v2/L0001/' + this.state.pessoasBusca[indice].dado.replace('/', '_'), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((dadoCliente) => {
                console.log(dadoCliente.data)
                api_epar.post( '/clienteshasconsulta/', {
                    clientes_id: this.state.pessoasBusca[indice].cliente,
                    consulta_id: this.state.consulta_id,
                    tipo_consulta_id: 2,
                    id_tabela_consulta: null,
                    data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                    tipo_locador: 'Solidário',
                    retorno_json: JSON.stringify(dadoCliente.data)
                }).then((retornoClienteConsulta) => {
                    console.log(retornoClienteConsulta)
                    api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/2').then((dadoCompetencia) => {
                        console.log(dadoCompetencia.data.data)
                        api_epar.post( '/tarifacao/', {
                            data: moment().format('YYYY-MM-DD HH:mm:ss'),
                            valor: dadoCompetencia.data.data[0].valor_cobrar,
                            tipo: 'Débito',
                            clientes_has_consulta_id: retornoClienteConsulta.data.data
                        }).then((retornoTarifacao) => {
                            console.log(retornoTarifacao)
                        }).catch((error) => {
                            console.log(error)
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            })
        )
    }

    // Início do processo da consulta / Verifica se já existe esse cliente PRINCIPA, caso não cria um novo
    consultar = () => {
        if(this.state.dado !== ''){
            // api_epar.get( '/cliente/' + this.state.dado.replace('/', '_')).then((cliente) => {
            //     if(cliente.data.data.length > 0){
            //         var atualizar = this.state.pessoasBusca
            //         atualizar[0].cliente = cliente.data.data[0].id
            //         atualizar[0].nome = cliente.data.data[0].nome
            //         atualizar[0].telefone = cliente.data.data[0].telefone
            //         atualizar[0].renda = cliente.data.data[0].renda_informada
            //         this.setState({pessoasBusca: atualizar})
            //         this.setState({cliente_id: cliente.data.data[0].id})
            //         this.setState({nome: cliente.data.data[0].nome})
            //         this.setState({telefone: cliente.data.data[0].telefone})
            //         this.setState({renda: cliente.data.data[0].renda_informada})
            //     }
            //     else{
            //         api_epar.post( '/cliente', {nome: this.state.nome, cpf_cnpj: this.state.dado, telefone: this.state.telefone, renda_informada: this.state.renda}).then((retorno) => {
            //             var atualizar = this.state.pessoasBusca
            //             atualizar[0].cliente = retorno.data.data
            //             this.setState({pessoasBusca: atualizar})
            //             this.setState({cliente_id: retorno.data.data})
            //         }).catch((error) => {
            //             console.log(error)
            //         })
            //     }

                axios.get('https://api.procob.com/consultas/v2/L0001/' + this.state.dado.replace('/', '_'), {headers: {authorization: 'Basic c2FuZGJveEBwcm9jb2IuY29tOlRlc3RlQXBp'}}).then((dadoCliente) => {
                    console.log(dadoCliente.data)
                    // api_epar.post( '/consulta', {
                    //     data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                    //     usuarios_id: JSON.parse(localStorage.getItem('dadosUsuario_test')).id
                    // }).then((dadoConsulta) => {
                    //     console.log(dadoConsulta.data.data)
                        api_epar.post( '/clienteshasconsulta', {
                            clientes_id: this.state.cliente_id,
                            consulta_id: this.state.consulta_id,
                            tipo_consulta_id: 2,
                            id_tabela_consulta: null,
                            data_consulta: moment().format('YYYY-MM-DD HH:mm:ss'),
                            tipo_locador: 'Principal',
                            retorno_json: JSON.stringify(dadoCliente.data)
                        }).then((retornoClienteConsulta) => {
                            console.log(retornoClienteConsulta)
                            api_epar.get( '/competencia/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id + '/2').then((dadoCompetencia) => {
                                console.log(dadoCompetencia.data.data[0])
                                api_epar.post( '/tarifacao', {
                                    data: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    valor: dadoCompetencia.data.data[0].valor_cobrar,
                                    tipo: 'Débito',
                                    clientes_has_consulta_id: retornoClienteConsulta.data.data
                                }).then((retornoTarifacao) => {
                                    console.log(retornoTarifacao)
                                }).catch((error) => {
                                    console.log(error)
                                })
                            }).catch((error) => {
                                console.log(error)
                            })
                        }).catch((error) => {
                            console.log(error)
                        })
                    // }).catch((error) => {
                    //     console.log(error)
                    // })
                }).catch((error) => {
                    console.log(error)
                })

                if(this.state.valor != '' && this.state.renda != ''){
                    // if(this.state.valor > this.state.renda*0.3){
                    //     console.log('Não é possível fazer consulta 30%')
                        if(this.state.pessoasBusca.length > 1){
                            for(var i = 1; i < this.state.pessoasBusca.length; i++){
                                console.log('Solidario: ' + i)
                                // this.solidario(i).then((resultado) => {
                                    this.solidarioDadosCadastrais(i)
                                    // this.solidarioDadosCadastrais(resultado[1])
                                    // if(this.state.valor > (this.state.renda*0.3 + this.state.pessoasBusca[resultado[1]].renda*0.3)){
                                    //     console.log('Não é possível fazer consulta 30%')
                                    // }
                                    // else{
                                        // console.log('Teste: ' + JSON.stringify(this.state.pessoasBusca[resultado[1]]))
                                        // console.log(this.state.pessoasBusca[resultado[1]])
                                        this.realizarConsultaSolidario(this.state.pessoasBusca[i].dado, this.state.pessoasBusca[i])
                                        // this.realizarConsultaSolidario(this.state.pessoasBusca[resultado[1]].dado, this.state.pessoasBusca[resultado[1]])
                                    // }
                                // })
                            }
                            this.realizarConsulta()
                            console.log('Realizar consulta')
                        }
                        else{
                            console.log('Não há solidário')
                            console.log('Realizar consulta')
                            this.realizarConsulta()
                        }
                    // }
                    // else{
                        // console.log('Realizar consulta')
                        // this.realizarConsulta()
                    // }
                }
                else{
                    console.log('Campo(s) vazio(s)')
                    alert('Campo(s) vazio(s)')
                    console.log(this.state.valor)
                    console.log(this.state.renda)
                }
            // }).catch((error) => {
            //     console.log(error)
            // })
        }
        else{
            console.log('Principal sem CPF')
            alert('Locador Principal sem CPF')
        }
    }

    // Modelos de contrato criados para a imobiliaria que está conectada no sistema 
    rowsContratos = (record) => {
        return(
            <option key={record.id} value={record.id}>{record.descricao}</option>
        )
    } 

    // Gera o contrato com os dados dos clientes
    // gerarContrato = () => {
    //     api_epar.get( '/contrato/' + this.state.contrato).then((retornoContrato) => {
    //         var atualizarContrato = retornoContrato.data.data[0].contrato
    //         .replace(/<code>@nome<\/code>/g, this.state.nome)
    //         .replace(/<code>@cpf<\/code>/g, this.state.dado)  
    //         console.log(atualizarContrato)
    //         ReactDOM.render(<Editor
    //             apiKey='0ru2y66aocmef191wyzppdto1j5o4xeerj331tksrvbopnqz'
    //             initialValue={atualizarContrato}
    //             init={{
    //               height: 500,
    //               menubar: false,
    //               plugins: [
    //                 'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
    //                 'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
    //                 'save table directionality emoticons template paste'
    //               ],
    //               toolbar:
    //                 'print',
    //             }}
    //           />, document.getElementById('editorContrato'))
    //           this.atualizarEtapaConsulta('Gerar contrato')
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    // Retorna visualmente a resposta para cada cliente
    respostaAprovacao = (record) => {
        var retorno
        if(record.resultado === 'Aprovado sem ressalva'){
            retorno =   <Col className="col-auto mt-4">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                <i className="fas fa-check" />
                            </div>
                        </Col>
            
        }
        else if(record.resultado === 'Aprovado com ressalva'){
            retorno =   <Col className="col-auto mt-4">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="fas fa-check" />
                            </div>
                        </Col>
        }
        else if(record.resultado === 'Negado'){
            retorno =   <Col className="col-auto mt-4">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="ni ni-fat-remove" /> 
                            </div>
                        </Col>
        }
        return(
            <Card className="card-stats w-50 mx-auto mb-3" key={record.dado}>
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                            >
                                {record.quem} 
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">
                                {record.dado}
                                <p>{record.resultado}  {record.motivo}</p>
                            </span>
                        </div>
                        {retorno}
                    </Row>
                </CardBody>
            </Card>
        )
    }

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className={"pb-8" + FuncoesComum.layout()} fluid style={{minHeight: '65vh'}}>

            <Nav style={{position: 'relative'}} tabs>
                <NavItem className='itemWizard'>
                    <NavLink
                        className={classnames({ activeWizard: this.state.activeTab === '1' })}
                        // onClick={() => { this.toggle('1'); }}
                        onClick={() => { this.toggleEtapa('1'); }}
                    >
                        1. Imóvel e valor de aluguel
                    </NavLink>
                </NavItem>
                <NavItem className='itemWizard'>
                    <NavLink
                        className={classnames({ activeWizard: this.state.activeTab === '2' })}
                        onClick={() => { this.toggleEtapa('2'); }}
                    >
                        2. Dados pessoais
                    </NavLink>
                </NavItem>
                <NavItem className='itemWizard'>
                    <NavLink
                        className={classnames({ activeWizard: this.state.activeTab === '3' })}
                        onClick={() => { this.toggleEtapa('3'); }}
                    >
                        3. Realizar busca
                    </NavLink>
                </NavItem>
                <NavItem className='itemWizard'>
                    <NavLink
                        className={classnames({ activeWizard: this.state.activeTab === '4' })}
                        // onClick={() => { this.toggle('4'); }}
                    >
                        4. Retorno da busca
                    </NavLink>
                </NavItem>
                {/* <NavItem className='itemWizard'>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '5' })}
                        // onClick={() => { this.toggle('5'); }}
                    >
                        5. Gerar contrato
                    </NavLink>
                </NavItem> */}
            </Nav>
            {/* <div className='position-relative'>
                <div className='float-left p-3 text-white itemWizard' onClick={() => { this.toggle('1') }} style={{cursor: 'pointer'}}>1. Imóvel e valor de aluguel</div>
                <div className='float-left p-3 text-white itemWizard' onClick={() => { this.toggle('2') }} style={{cursor: 'pointer'}}>2. Dados Pessoais</div>
                <div className='float-left p-3 text-white itemWizard' onClick={() => { this.toggle('3') }} style={{cursor: 'pointer'}}>3. Realizar busca</div>
                <div className='float-left p-3 text-white itemWizard' onClick={() => { this.toggle('4') }} style={{cursor: 'pointer'}}>4. Retorno da busca</div>
                <div className='p-3 text-white itemWizard'  onClick={() => { this.toggle('5') }} style={{cursor: 'pointer'}}>5. Gerar contrato</div>
            </div> */}
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1" className='p-5 bg-white borderConteudo'>
                    <Row>
                        <Col md={12} className='mb-4'>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-money-coins" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input style={{color: 'black'}} placeholder="Valor" type="number" value={this.state.valor} onChange={this.changeValor}/>
                            </InputGroup>
                        </Col>
                        {/* <Col md={1} className='mb-4'>
                            <Button>Buscar</Button>
                        </Col> */}
                        <Col md={12} className='mb-4'>   
                            <Button className='float-right colorButtonSecundario' onClick={this.proximaEtapa}>Próxima etapa <i className="ni ni-bold-right" /></Button>
                        </Col>
                    </Row>
                </TabPane>


                <TabPane tabId="2" className='p-5 bg-white borderConteudo'>
                    <Row>
                        <Col md={6}>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-active-40" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input style={{color: 'black'}} type="select" onChange={this.mascara}>
                                    <option value='cpf'>CPF</option>
                                    <option value='cnpj'>CNPJ</option>
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col md={6}>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-chat-round" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input style={{color: 'black'}} name='dado' placeholder="CPF / CNPJ" type="text" value={this.state.dado} onChange={this.changeDado} mask={this.state.mask} maskChar=' ' tag={InputMask} />
                            </InputGroup>
                        </Col>
                        {/* <Col md={7}>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-single-02" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input style={{color: 'black'}} type="text" placeholder='Nome' value={this.state.nome} onChange={this.changeNome}></Input>
                            </InputGroup>
                    </Col>     */}                
                        <Col md={3}>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-money-coins" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input style={{color: 'black'}} placeholder="Renda" type="number" value={this.state.renda} onChange={this.changeRenda}/>
                            </InputGroup>
                        </Col>
                        {/*<Col md={2}>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-mobile-button" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input style={{color: 'black'}} placeholder="Celular" type="text" value={this.state.telefone} onChange={this.changeTelefone} mask='(99) 99999-9999' maskChar=' ' tag={InputMask} />
                            </InputGroup>
                        </Col> */}
                    </Row>
                    <Button className='colorButtonSecundario' onClick={this.toggleModal}>Adicionar Locador Solidário <i className="ni ni-fat-add" /></Button>
                    <Button className='float-right colorButtonSecundario' onClick={this.proximaEtapa}>Próxima etapa <i className="ni ni-bold-right" /></Button>
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>Adicionar Locador Solidário</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={4}>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-active-40" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{color: 'black'}} type="select" onChange={this.mascaraSolidario}>
                                            <option value='cpf'>CPF</option>
                                            <option value='cnpj'>CNPJ</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col md={8}>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-chat-round" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{color: 'black'}} name='dado' placeholder="CPF / CNPJ" type="text" value={this.state.dadoSolidario} onChange={this.changeDadoSolidario} mask={this.state.maskSolidario} maskChar=' ' tag={InputMask} />
                                    </InputGroup>
                                </Col>
                                {/* <Col md={12}>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{color: 'black'}} type="text" placeholder='Nome' value={this.state.nomeSolidario} onChange={this.changeNomeSolidario}></Input>
                                    </InputGroup>
                    </Col>    */}                 
                                <Col md={6}>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-money-coins" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{color: 'black'}} placeholder="Renda" type="number" value={this.state.rendaSolidario} onChange={this.changeRendaSolidario}/>
                                    </InputGroup>
                                </Col>
                                {/*<Col md={6}>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-mobile-button" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input style={{color: 'black'}} placeholder="Celular" type="text" value={this.state.telefoneSolidario} onChange={this.changeTelefoneSolidario} mask='(99) 99999-9999' maskChar=' ' tag={InputMask} />
                                    </InputGroup>
                                </Col> */}
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="colorButtonSecundario" onClick={this.addSolidario}>Adicionar</Button>
                            <Button className="colorButtonSecundario" onClick={this.toggleModal}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                </TabPane>

                <TabPane tabId="3" className='p-5 bg-white borderConteudo'>
                    {this.state.pessoasBusca.map(this.rowsPessoas)}
                    <Col md={12} className='mb-4'>  
                        <Button className='colorButtonSecundario float-left' onClick={this.novaConsulta}>Nova consulta</Button> 
                        <Button className='float-right colorButtonSecundario' onClick={this.proximaEtapa}>Próxima etapa <i className="ni ni-bold-right" /></Button>
                    </Col>
                </TabPane>
                
                <TabPane tabId="4" className='p-5 bg-white borderConteudo'>
                    {this.state.pessoasBusca.map(this.respostaAprovacao)}
                    <Col md={12} className='mb-4 mt-4'>   
                        {/* <Button color="info" className='float-right' onClick={this.proximaEtapa}>Próxima etapa <i className="ni ni-bold-right" /></Button> */}
                        <Button className='colorButtonSecundario float-left' onClick={this.novaConsulta}>Nova consulta</Button>
                        <Button className='colorButtonSecundario float-right' onClick={this.proximaEtapa} to='/admin/myconsulta' tag={Link}>Finalizar consulta</Button>
                    </Col>
                </TabPane>

                {/* <TabPane tabId="5" className='p-5 bg-white borderConteudo'>
                    <Row>
                        <Col md={10}>
                            <InputGroup className="input-group-alternative mb-3">
                                <Input type='select' value={this.state.contrato} onChange={this.changeContrato}>
                                    {this.state.tiposContratos.map(this.rowsContratos)}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <Button onClick={this.gerarContrato}>Gerar contrato</Button>
                        </Col>
                    </Row>
                    <div className='mt-4' id='editorContrato'>
                        
                    </div>
                </TabPane> */}

            </TabContent>

        </Container>

        
      </>
    );
  }
}

export default Wizard;
