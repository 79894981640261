/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Table,
    Container,
    Input,
    Button,
    InputGroup,
    Col,
    InputGroupAddon,
    InputGroupText,
    Nav, 
    NavItem, 
    NavLink,
    TabPane,
    TabContent,
    Label,
    FormGroup
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'
import Header from "components/Headers/Header.jsx";
import { Editor } from '@tinymce/tinymce-react';
import FuncoesComum from '../funcoes/FuncoesComum';
import InputMask from 'react-input-mask'
import $ from 'jquery'
import DataTable from 'datatables.net'
import "assets/scss/relatorios.scss";
import {Link} from 'react-router-dom'
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'
import api_admin from '../services/apiadmin'
import ReactGA from "react-ga";
import moment from "moment";
import sha1 from "sha1";

class AddSinistro extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           data_abertura: moment().format('YYYY-MM-DD'),
           descricao: '',
           arquivos: ['CONTRATO DE LOCAÇÃO', 'CONTRATO DE ADMINISTRAÇÃO DO IMÓVEL', 'TERMO DE VISTORIA DO IMÓVEL']
        }
    }

   

    componentDidMount(){

       // Analytics
       ReactGA.initialize(process.env.REACT_APP_UA)
       ReactGA.pageview(window.location.pathname + window.location.search)

      // Verifica se o usuário tem acesso
      axios.get(api_admin + '/imobiliaria/imobuser').then((retorno) => {
        console.log(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }))

        if(retorno.data.imobs.filter((e) => {
          return e.id === JSON.parse(localStorage.getItem('dadosUsuario_test')).id
        }).length === 0){
          this.props.history.push('/admin/logout')
        }
      })
    }

    cadastrarSinistro = () => {
      var prossegir = 0
      for(var j = 0; j < this.state.arquivos.length; j++){
        if(!document.getElementsByName('arquivo' + j)[0].files[0]){
          prossegir = 1
          break
        }
      }
      if(prossegir === 0){
        if(this.state.descricao){
          api_epar.post( '/sinistro', {
            descricao: this.state.descricao,
            data_abertura: this.state.data_abertura,
            dados_contrato_id: this.props.match.params.id
          }, {headers: {"x-access-token": localStorage.getItem('autenticado_test')}}).then((sinistro) => {
            if(sinistro.data.data !== 'TOKEN inexistente' && sinistro.data.data !== 'TOKEN inválido'){
              for(var i = 0; i < this.state.arquivos.length; i++){
                // console.log(document.getElementsByName('arquivo' + i)[0].files[0])
                if(document.getElementsByName('arquivo' + i)[0].files[0]){
                  var diretorio = 'docs/sinistro' + sinistro.data.data 
                  var nomeArquivo = ''
                  var nomeAntigo = document.getElementsByName('arquivo' + i)[0].files[0].name
          
                  if(document.getElementsByName('arquivo' + i)[0].files[0].name.split('.').length > 2){
                    for(var o = 0; o < document.getElementsByName('arquivo' + i)[0].files[0].name.split('.').length; o++){
                      if(o === document.getElementsByName('arquivo' + i)[0].files[0].name.split('.').length-1){
                        nomeArquivo += '.' + document.getElementsByName('arquivo' + i)[0].files[0].name.split('.')[o]
                      }
                      else if(o === 0){
                        nomeArquivo += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')  + Math.floor(Math.random() * 1000) + sha1(nomeAntigo)
                      }
                    }
                  }
                  else{
                    nomeArquivo += new Date().toLocaleString('pt-BR').replace(/([\W]|[ ])/g, '')  + Math.floor(Math.random() * 1000) + sha1(nomeAntigo) +'.'+ document.getElementsByName('arquivo' + i)[0].files[0].name.split('.')[1]
                  }
          
                  var data = new FormData()
                  data.append('file', new File([document.getElementsByName('arquivo' + i)[0].files[0]], nomeArquivo, {type: document.getElementsByName('arquivo' + i)[0].files[0].type}))
                  data.append('rota', diretorio)
                  data.append('nomeAntigo', nomeAntigo)
      
                  api_epar.post( '/arquivossinistro', {
                    descricao: this.state.arquivos[i],
                    path: diretorio + '/' + nomeArquivo,
                    sinistro_id: sinistro.data.data
                  }, {headers: {"x-access-token": localStorage.getItem('autenticado_test')}})
      
                  // Envia uma requisição POST 
                  api_epar.post( '/upload/' + diretorio.replace('/', '|'), data, {headers: {"x-access-token": localStorage.getItem('autenticado_test')}}).then((response) => {})
    
                }
              }
              api_epar.post( '/sendemailcarta', {
                subject: 'Notificação de adição de sinistro',
                to: "comercial@alugmais.com;financeiro@alugmais.com;eduardo@alugmais.com",
                html: `
                  <style>
                      * {
                          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                      }
                  </style>
                  
                  <div>
                      <img src='http://consulta.alugmais.com/logo_carta.png' alt='Logo' style='width: 180px; height: 80px'/><br/><br/>
                      <h3>
                          Adição de sinistro
                      </h3>
                      <br>
                      <p>
                          Um sinistro foi adicionado ao contrato ${this.props.match.params.id} às ${new Date().toLocaleString()} com os seguintes dados:
                      </p>
                      Data de abertura:<br>
                      ${this.state.data_abertura}
                      <br>
                      <br>
                      Descrição: <br>
                      ${this.state.descricao}
                      <br>
                      <br>
                      
                  </div>
                
                `,
              }).then((retorno) => {
                this.limpar()
              })
      
              alert('Seu sinistro foi registrado com sucesso')
            }
            else{
              alert(sinistro.data.data)
            }
          }).catch((error) => {
            console.log(error.message)
            alert('Ocorreu algum erro')
          })
  
        }
        else{
          alert('Preencha a descrição do sinistro')
        }
      }
      else{
        alert('Necessário selecionar todos os arquivos')
      }
    }

    novoArquivo = () => {
      var a = this.state.arquivos

      a.push(a.length)

      this.setState({arquivos: a})
    }

    rowArquivos = (record, index) => {
      return (

          <Row className='mt-4 justify-content-center' key={index}>
            <Col md={8}><strong>{record}</strong><Input type='file' name={'arquivo' + index} style={{width: '100%'}} className='mt-2' /></Col>
          </Row>
  
      )
    } 

    limpar = () => {
      this.setState({
        data_abertura: moment().format('YYYY-MM-DD'),
        descricao: '',
        arquivos: ['CONTRATO DE LOCAÇÃO', 'CONTRATO DE ADMINISTRAÇÃO DO IMÓVEL', 'TERMO DE VISTORIA DO IMÓVEL']
      })
      document.getElementById('desc').value = ''
    }

  state = {
    activeTab: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeTab: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {
    return (
      <>
        <Header style={{height: '10vh'}} />

        <Container className={"mt-5 d-flex justify-content-center"} fluid style={{width: '80%', minHeight: '65vh', flexDirection: 'column'}}>
          <h2 className='text-center mb-5'>Adicionar um novo sinistro</h2>
          <Row className='p-2 justify-content-center'>
            <Col md={8}>Data de abertura<Input type='date' value={this.state.data_abertura} onChange={a => this.setState({data_abertura: a.target.value})}/></Col>
          </Row>
          <Row className='p-2 justify-content-center'>
            <Col md={8}>Descrição<textarea id='desc' style={{width: '100%'}} defaultValue={this.state.descricao} rows="4" placeholder='Descreva o motivo para abertura do sinistro' onChange={(evento) => {
              this.setState({
                descricao: evento.target.value
              })
            }}/></Col>
          </Row> 
          {/* <Row>
            <Col md={12} className='float-right'><Button className='btn-success float-right mt-3' onClick={this.novoArquivo}>Adicionar novo arquivo +</Button></Col>
          </Row> */}
          <div className='mt-4 mb-5'>
            {/* <Row className='mt-4 justify-content-center' key={index}>
              <Col md={8}>CONTRATO DE LOCAÇÃO<Input type='file' name={'contratoLocacao'} style={{width: '100%'}} className='mt-2' /></Col>
            </Row>
            <Row className='mt-4 justify-content-center' key={index}>
              <Col md={8}>CONTRATO DE ADMINISTRAÇÃO DO IMÓVEL<Input type='file' name={'contratoAdm'} style={{width: '100%'}} className='mt-2' /></Col>
            </Row>
            <Row className='mt-4 justify-content-center' key={index}>
              <Col md={8}>TERMO DE VISTORIA DO IMÓVEL<Input type='file' name={'termoVis'} style={{width: '100%'}} className='mt-2' /></Col>
            </Row> */}
            {this.state.arquivos.map(this.rowArquivos)}
          </div>
          <Row>
            <Col md={12} className='float-right'><Button className='btn btn-success float-right mb-5' onClick={this.cadastrarSinistro}>Cadastrar sinistro</Button></Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddSinistro;
