import axios from 'axios'

// const api_request = 'https://apialugmais.herokuapp.com'
// const api_request = 'http://localhost:3333'
// const api_request = 'http://epar.anvali.com.br:3333'
// const api_request = 'https://api.e2par.com:3333'
const api_request = 'https://apieparteste.anvali.com.br:3333'

let api_epar 

console.log("UUID => ", localStorage.getItem('uuid_test'))
console.log("UUID TYPE => ", typeof localStorage.getItem('uuid_test'))
api_epar = axios.create({ 
    baseURL: api_request, 
    headers: {
        "Authorization": localStorage.getItem('uuid_test'),
        "x-access-token": localStorage.getItem('autenticado_test'),
    }
});

export {
    api_epar
}