/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    CardText,
    Row,
    Col,
    Input,
    Table,
    Container,
    Label,
    Button
} from "reactstrap";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'

import Header from "components/Headers/Header.jsx";
import PaypalBtn from 'react-paypal-checkout';
import FuncoesComum from '../funcoes/FuncoesComum';
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'

class Financeiro extends React.Component {

    constructor(props){
        super(props)
        this.state = {
          env: 'sandbox',
          currency: 'BRL',
          total: 0,
          locale: 'pt_BR',
          style: {
              'label': 'pay', 
              'tagline': false, 
              'size':'responsive', 
              'shape':'rect', 
              'color':'black'
          },
          client: {
            sandbox:    'AVUwLEaItrXE-RjZj6Znj1ZPsK-2V4fz89Hf_SWqIlHiDd9f0IM57nuCoQT9PJ5-kl5CmBfGAm-9Wdxw',
            production: 'YOUR-PRODUCTION-APP-ID',
          },
          exibir: 'mt-5 d-none',
          debito: 0,
          credito: 0,
          pagar: '',
          interval: 0
        }

        if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
            props.history.push('/admin/finalidadelocacao')
        }

    }

    componentDidMount(){
    //   var json = { "data": {"data": {"result": { "numero": "6c0b6784-8fb2-44d5-9460-67b75916e930" }, "status_code": 200 }, "local": "API" }, "local": "BANCO"  }

    //  if(json.local === 'BANCO'){
    //   console.log(json.data)
    //  }
    //  else{
    //    console.log('não')
    //  }

      // Valor de débito
      api_epar.get( '/tarifacaodebito/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((debito) => {
        console.log(debito.data.data)
        if(debito.data.data[0].debito !== null){
          this.setState({debito: debito.data.data[0].debito})
        }

        //Valor de crédito
        api_epar.get( '/tarifacaocredito/' + JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id).then((credito) => {
            console.log(credito.data.data)
            if(credito.data.data[0].credito !== null){
              this.setState({credito: credito.data.data[0].credito})
            }
            if(this.state.credito - this.state.debito <= 0){
              this.setState({pagar: 'p-1 d-print-block'})
            }
            else{
              this.setState({pagar: 'p-1 d-none'})
            }
        }).catch((error) => {
            console.log(error)
        })
      }).catch((error) => {
          console.log(error)
      })

    }  

    
    changeTotal = (evento) => {
      this.setState({total: evento.target.value})
      if(evento.target.value <= 0){
        this.setState({exibir: 'mt-5 d-none'})
      }
      else{
        this.setState({exibir: 'mt-5 d-print-block'})
      }
    }

    // Caso haja sucesso no pagamento por PAYPAL
    onSuccess = (payment) => {
      // Congratulation, it came here means everything's fine!
      console.log("The payment was succeeded!");
      api_epar.post( '/tarifacaocredito', {
          data: moment().format('YYYY-MM-DD HH:mm:ss'),
          valor: payment.valor,
          tipo: 'Crédito',
          imobiliaria: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id
      }).then((retornoTarifacao) => {
          console.log(retornoTarifacao)
          this.setState({credito: this.state.credito + parseFloat(payment.valor)})
      }).catch((error) => {
          console.log(error)
      })
    }		

    // Caso pagamento foi cancelado
    onCancel = (data) => {
      // User pressed "cancel" or close Paypal's popup!
      console.log('The payment was cancelled!', data);
    }	

    // Caso haja erro no pagamento por PAYPAL
    onError = (err) => {
      // The main Paypal's script cannot be loaded or somethings block the loading of that script!
      console.log("Error!", err);		
    }		
    
  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className={"mt-5 pb-8"} fluid style={{minHeight: '65vh'}}>
          <Row >
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Saldo
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {FuncoesComum.formatarMoeda(this.state.credito - this.state.debito)}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i className="ni ni-chart-bar-32" />
                      </div>
                    </Col>
                  </Row>
                  {/* <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>{" "}
                    <span className="text-nowrap">Since last month</span>
                  </p> */}
                </CardBody>
                <div className={this.state.pagar}>
                  <PaypalBtn 
                    env={this.state.env} 
                    client={this.state.client} 
                    currency={this.state.currency} 
                    total={this.state.debito - this.state.credito}
                    locale={this.state.locale} 
                    style={this.state.style}
                    onError={this.onError} 
                    onSuccess={this.onSuccess} 
                    onCancel={this.onCancel} 
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <Row >
            <Col md={3} className='p-3'>
              <Card style={{color: 'black'}} className='text-center'>
                <CardHeader style={{fontSize: '1.3em', backgroundColor: '#e6e6e6'}}><strong>PLANO 100</strong></CardHeader>
                <CardBody>
                  <CardTitle style={{fontSize: '1.3em'}}><strong>R$ 100,00</strong></CardTitle>
                  <CardText className='mb-3'>Na compra deste plano você terá um crédito no valor de R$ 100,00 para realizar suas consultas</CardText>
                  <div className='mt-5'>
                    <PaypalBtn 
                      env={this.state.env} 
                      client={this.state.client} 
                      currency={this.state.currency} 
                      total={100}
                      locale={this.state.locale} 
                      style={this.state.style}
                      onError={this.onError} 
                      onSuccess={this.onSuccess} 
                      onCancel={this.onCancel} 
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={3} className='p-3'>
              <Card style={{color: 'black'}} className='text-center'>
                <CardHeader style={{fontSize: '1.3em', backgroundColor: '#e6e6e6'}}><strong>PLANO 300</strong></CardHeader>
                <CardBody>
                  <CardTitle style={{fontSize: '1.3em'}}><strong>R$ 300,00</strong></CardTitle>
                  <CardText className='mb-3'>Na compra deste plano você terá um crédito no valor de R$ 300,00 para realizar suas consultas</CardText>
                  <div className='mt-5'>
                    <PaypalBtn 
                      env={this.state.env} 
                      client={this.state.client} 
                      currency={this.state.currency} 
                      total={300}
                      locale={this.state.locale} 
                      style={this.state.style}
                      onError={this.onError} 
                      onSuccess={this.onSuccess} 
                      onCancel={this.onCancel} 
                      onClick={this.planoCompra}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={3} className='p-3'>
              <Card style={{color: 'black'}} className='text-center'>
                <CardHeader style={{fontSize: '1.3em', backgroundColor: '#e6e6e6'}}><strong>PLANO 500</strong></CardHeader>
                <CardBody>
                  <CardTitle style={{fontSize: '1.3em'}}><strong>R$ 500,00</strong></CardTitle>
                  <CardText className='mb-3'>Na compra deste plano você terá um crédito no valor de R$ 500,00 para realizar suas consultas</CardText>
                  <div className='mt-5'>
                    <PaypalBtn 
                      env={this.state.env} 
                      client={this.state.client} 
                      currency={this.state.currency} 
                      total={500}
                      locale={this.state.locale} 
                      style={this.state.style}
                      onError={this.onError} 
                      onSuccess={this.onSuccess} 
                      onCancel={this.onCancel} 
                      onClick={this.planoCompra}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={3} className='p-3'>
              <Card style={{color: 'black'}} className='text-center'>
                <CardHeader style={{fontSize: '1.3em', backgroundColor: '#e6e6e6'}} ><strong>PLANO PERSONALIZADO</strong></CardHeader>
                <CardBody>
                  <CardTitle><Input className='p-2' style={{height: '4vh', color: 'black'}} type='number' placeholder='Preço' value={this.state.total} onChange={this.changeTotal}></Input></CardTitle>
                  <CardText className='mt--2'>No plano personalizado você terá créditos no valor digitado no campo acima para realizar suas consultas</CardText>
                  <div className={this.state.exibir}>
                    <PaypalBtn 
                      env={this.state.env} 
                      client={this.state.client} 
                      currency={this.state.currency} 
                      total={parseFloat(this.state.total)}
                      locale={this.state.locale} 
                      style={this.state.style}
                      onError={this.onError} 
                      onSuccess={this.onSuccess} 
                      onCancel={this.onCancel} 
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Financeiro;
