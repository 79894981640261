/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";

import routes from "routes.js";
import { api_epar } from '../services/apisaxios'
import api_admin from "../services/apiadmin";
import Axios from "axios";

import {
  Row,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody, 
  ModalFooter,
} from "reactstrap";

class Admin extends React.Component {

  constructor(props){
    super(props)
    this.state ={
      link: 'logo-econsult.png',
      razaoPermitida: "valid"
    }
    
  }

  componentDidMount(){
    if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
      this.setState({link: 'logo-alugmais.png'})
    }
    else{
      this.setState({link: 'logo-econsult.png'})
    }

    this.testeConnection()
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  validContrato = async () => {
    let user = JSON.parse(localStorage.getItem('dadosUsuario_test'))
    Axios.get(api_admin + "/imobon/verificarRazao/" + user.imobiliaria_id).then(re => {
      this.setState({razaoPermitida: re.data.data})
    })
  }

  testeConnection = async () => {
    let re = await api_epar.get("/testeme")

    if (re.data.data != "OK"){
      alert("Sua sessão expirou")
      localStorage.clear()
      window.location.href = "/"
      return
    }

    this.validContrato()
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "";
  };
  render() {
    return (
      <>
        {
          this.state.razaoPermitida == "valid" ? (
            <div ref="mainContent">
              <AdminNavbar
                {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
              />
              <Switch>{this.getRoutes(routes)}</Switch>
              {/* <Container fluid >
                <AdminFooter />
              </Container> */}
              <p className="m-0">{process.env.REACT_APP_VERSION}</p>

            </div>
          ) : (
            <div ref="mainContent">
              <AdminNavbar
                {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
              />
              <Modal isOpen={true}>
                <ModalHeader>Alerta</ModalHeader>
                <ModalBody>
                  <Row>
                      <Col md={12}>
                        {this.state.razaoPermitida}
                      </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          )
        }
      </>
    );
  }
}

export default Admin;
