/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios";
import sha1 from "sha1";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody, 
  ModalFooter
} from "reactstrap";

import api_request from '../../services/apis'
import {api_epar} from '../../services/apisaxios'


class Register extends React.Component {

  constructor(props){
    super(props)
    this.state ={
      mensagemModal: '',
      alert: false
    }


    if(!localStorage.getItem('autenticado_test')){
      props.history.push('/auth/login')
    }
    else if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
      props.history.push('/admin/realizarconsulta')
    }
    else{
      this.state ={
        imobiliaria: JSON.parse(localStorage.getItem('dadosUsuario_test')).imobiliaria_id
      } 
    }
  }

  toggleAlert = () => {
    this.setState({alert: !this.state.alert})
  }

  registrar = () => {
    var usuario = document.getElementsByName('usuario')[0].value
    var nome = document.getElementsByName('nome')[0].value
    var email = document.getElementsByName('email')[0].value
    var senha = document.getElementsByName('senha')[0].value
    // var tipo_pagamento = document.getElementsByName('tipo_pagamento')[0].value
    var permissao = document.getElementsByName('permissao')[0].value
    var criar = 1

    if(usuario === ''){
      criar = 0
    }
    if(nome === ''){
      criar = 0
    }
    if(email === ''){
      criar = 0
    }
    if(senha === ''){
      criar = 0
    }
    if(permissao === ''){
      criar = 0
    }

    if(criar === 1){
      api_epar.get( '/userusuario/' + usuario).then((newUsuario) => {
        if(newUsuario.data.data.length === 0){
          api_epar.get( '/useremail/' + email).then((retorno) => {
            if(retorno.data.data.length === 0){
              api_epar.post( '/user', {
                usuario: usuario,
                email: email,
                senha: sha1(senha),
                nome: nome,
                // tipo_pagamento: tipo_pagamento,
                permissao: permissao,
                imobiliaria_id: this.state.imobiliaria
              }).then((response) => {
                this.setState({mensagemModal: <div>
                              Usuário {nome} criado com sucesso!
                            </div>})
                this.toggleAlert()
              }).catch((error) => {
                console.log('Error: ' + error)
              })
            }
            else{
              console.log('Já existe esse email')
              this.setState({mensagemModal: <div>
                                              Já existe um usuário com este email ({email})
                                            </div>})
              this.toggleAlert()
            }
          }).catch((error) => {
            console.log('Error: ' + error)
          })
        }
        else{
          console.log('Já existe esse usuário')
          this.setState({mensagemModal: <div>
                                          Já existe um usuário ({usuario})
                                        </div>})
          this.toggleAlert()
        }
      })
    }
    else{
      this.setState({mensagemModal: <div>
                                      Campo(s) vazio(s), todos os campos são obrigatórios
                                    </div>})
      this.toggleAlert()
    }
  }

  enter = (evento) => {
    if(evento.key === 'Enter'){
      this.registrar()
    }
  }

  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader style={{backgroundColor: '#2b71b5'}} className=" ">
              {/* <div className="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
              <div className="text-center">
                <Button
                  className="btn-neutral btn-icon mr-4"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div> */}
              <div className='text-center'>
                <img src={require("assets/img/logo-econsult.png")} alt='Logo' className='img-fluid'></img>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Cadastre um novo usuário para acessar seu sistema</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{color: 'black'}} placeholder="Nome" type="text" name='nome' />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{color: 'black'}} placeholder="Email" type="email" name='email' />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{color: 'black'}} placeholder="Usuário" type="text" name='usuario' />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{color: 'black'}} placeholder="Senha" type="password" name='senha' onKeyPress={this.enter}/>
                  </InputGroup>
                </FormGroup>
                {/* <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-money-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" name='tipo_pagamento' onKeyPress={this.enter}>
                      <option value='POS'>PÓS</option>
                      <option value='PRE'>PRÉ</option>
                    </Input>
                  </InputGroup>
                </FormGroup> */}
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-settings" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input style={{color: 'black'}} type="select" name='permissao' onKeyPress={this.enter}>
                      <option value='comum'>Comum</option>
                      <option value='master'>Master</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
                {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                {/* <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row> */}
                <div className="text-center">
                  <Button className="mt-4 float-right" style={{backgroundColor: '#2b71b5', color: 'white'}} type="button" onClick={this.registrar}>
                    Criar registro
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Modal isOpen={this.state.alert} toggle={this.toggleAlert}>
              <ModalHeader toggle={this.toggleAlert}>Erro ao criar um usuário</ModalHeader>
              <ModalBody>
                  <Row>
                      <Col md={12}>
                          {this.state.mensagemModal}
                      </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                  <Button className="btn-dark" onClick={this.toggleAlert}>Cancelar</Button>
              </ModalFooter>
          </Modal>
        </Col>
      </>
    );
  }
}

export default Register;
