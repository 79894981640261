const logout = (props) => {

    // localStorage.removeItem('autenticado_test')
    // localStorage.removeItem('dadosUsuario_test')
    // localStorage.removeItem('nomeSolidario_test')
    // localStorage.removeItem('rendaSolidario_test')
    // localStorage.removeItem('telefoneSolidario_test')
    // localStorage.removeItem('dadoSolidario_test')
    // localStorage.removeItem('nome_test')
    // localStorage.removeItem('renda_test')
    // localStorage.removeItem('telefone_test')
    // localStorage.removeItem('dado_test')
    // localStorage.removeItem('pessoasBusca_test')
    // localStorage.removeItem('mask_test')
    // localStorage.removeItem('maskSolidario_test')
    // localStorage.removeItem('activeTab_test')
    // localStorage.removeItem('activeTabPrincipal_test')
    // localStorage.removeItem('valor_test')
    // localStorage.removeItem('aluguel_test')
    // localStorage.removeItem('condominio_test')
    // localStorage.removeItem('imposto_test')
    // localStorage.removeItem('outros_test')
    // localStorage.removeItem('cliente_id_test')
    // localStorage.removeItem('clienteSolidario_id_test')
    // localStorage.removeItem('consulta_id_test')
    // localStorage.removeItem('email_test')
    // localStorage.removeItem('minuto_test')
    // localStorage.removeItem('emailJF_test')
    // localStorage.removeItem('minutoJF_test')
    // localStorage.removeItem('nomeSolidarioJF_test')
    // localStorage.removeItem('rendaSolidarioJF_test')
    // localStorage.removeItem('telefoneSolidarioJF_test')
    // localStorage.removeItem('dadoSolidarioJF_test')
    // localStorage.removeItem('nomeJF_test')
    // localStorage.removeItem('rendaJF_test')
    // localStorage.removeItem('telefoneJF_test')
    // localStorage.removeItem('dadoJF_test')
    // localStorage.removeItem('pessoasBuscaJF_test')
    // localStorage.removeItem('maskJF_test')
    // localStorage.removeItem('maskSolidarioJF_test')
    // localStorage.removeItem('activeTabJF_test')
    // localStorage.removeItem('activeTabPrincipalJF_test')
    // localStorage.removeItem('valorJF_test')
    // localStorage.removeItem('aluguelJF_test')
    // localStorage.removeItem('condominioJF_test')
    // localStorage.removeItem('impostoJF_test')
    // localStorage.removeItem('outrosJF_test')
    // localStorage.removeItem('cliente_idJF_test')
    // localStorage.removeItem('clienteSolidario_idJF_test')
    // localStorage.removeItem('consulta_idJF_test')
    localStorage.clear()
    props.history.push('/')

    return ( null );
    
}

export default logout;