/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Container,
    CardBody,
    CardFooter,
    Card,
    CardHeader,
    Col,
    Label,
    Button,
    Row,
} from "reactstrap";

import FuncoesComum from '../funcoes/FuncoesComum'


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import axios from 'axios'

import Header from "components/Headers/Header.jsx";
import api_request from '../services/apis'
import {api_epar} from '../services/apisaxios'

class DetalheConsulta extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            consulta: this.props.match.params.id,
            retornoConsulta: [],
            pessoas: [],
            tamanhoCol: 12
        }

        if (JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao !== 'master'){
          props.history.push('/admin/index')
        }
    }

    componentDidMount(){
        console.log(this.state.consulta)
    
        api_epar.get( '/clienteshasconsultadetalhe/' + this.state.consulta).then((retorno) => {
            console.log(retorno)
            var dicionario = []
            var valor = retorno.data.data[0].valor
            var etapa = retorno.data.data[0].etapa
            var idConsulta = retorno.data.data[0].consulta_id
            var dataConsulta = retorno.data.data[0].data_consulta
            var pessoas = []
            var ids = new Set(retorno.data.data.map((e) => {
                return e.clientes_id
            }))
            for(let id of ids){
                pessoas.push(this.pessoasRetorno(retorno, id))
            }
            var principal = parseInt(pessoas.map((e) => {
              return e.tipo_locatario
            }).indexOf('Principal'))
            console.log(principal)
            if(principal !== 0){
              var newOrdem = [pessoas[principal]]
              for(var i = 0; i < pessoas.length; i++){
                if(i !== principal){
                  newOrdem.push(pessoas[i])
                }
              }
              pessoas = newOrdem
            }
            console.log(pessoas)
            dicionario = [{
              consulta_id: idConsulta,
              valor: valor,
              pessoas: pessoas,
              etapa: etapa,
              data_consulta: dataConsulta,
            }]
            console.log(dicionario)
            var dividirPor = 0
            if(ids.size > 3){
              dividirPor = 3
            }
            else{
              dividirPor = ids.size
            }
            this.setState({tamanhoCol: this.state.tamanhoCol / dividirPor, retornoConsulta: dicionario, pessoas: dicionario[0].pessoas})
        }).catch((error) => {
            console.log(error)
        })
    }

    pessoasRetorno = (retorno, id_cliente) => {
        console.log(retorno)
        var dadosFalta = 0 
        var dadosCadastrais
        var dadosFinanceiros 
        var tipo_locatario
        var nome
        var cpf_cnpj
        var telefone
        var renda_informada
        var status = ''
        var motivo = ''
        var cliente_id
        for(var i = 0; i < retorno.data.data.length; i++){
            if(retorno.data.data[i].clientes_id === id_cliente){
                if(dadosFalta === 0){
                    nome = retorno.data.data[i].nome
                    cpf_cnpj = retorno.data.data[i].cpf_cnpj
                    tipo_locatario = retorno.data.data[i].tipo_locatario
                    telefone = retorno.data.data[i].telefone
                    renda_informada = retorno.data.data[i].renda_informada
                    status += retorno.data.data[i].status + ', '
                    motivo += retorno.data.data[i].motivo + ', '
                    cliente_id = retorno.data.data[i].clientes_id
                    if(retorno.data.data[i].tipo_consulta_id === 1){
                        dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
                        dadosFalta = 1
                    }
                    else if(retorno.data.data[i].tipo_consulta_id === 2){
                        dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json)
                        dadosFalta = 2
                    }
                }
                else if(dadosFalta === 1 && retorno.data.data[i].tipo_consulta_id === 2){
                  dadosCadastrais = JSON.parse(retorno.data.data[i].retorno_json.replace(/'/g, '"'))
                }
                else if(dadosFalta === 2 && retorno.data.data[i].tipo_consulta_id === 1){
                    dadosFinanceiros = JSON.parse(retorno.data.data[i].retorno_json)
                }
                if(retorno.data.data[i].tipo_consulta_id === 3){
                  status += retorno.data.data[i].status + ', '
                  motivo += retorno.data.data[i].motivo + ', '
                }
            }
        }
        return {
            nome: nome,
            cliente_id: cliente_id,
            cpf_cnpj: cpf_cnpj,
            tipo_locatario: tipo_locatario,
            telefone: telefone,
            renda_informada: renda_informada,
            status: status,
            motivo: motivo,
            dadosCadastrais: dadosCadastrais,
            dadosFinanceiros: dadosFinanceiros
        }
    }

    rowConsulta = (record) => {
      
      var status
      var textoMensagem
      var valor = 0
      if(record.valor !== null){
        valor = record.valor
      }
      for(var i = 0; i < record.pessoas.length; i++){
        if(record.pessoas[i].status.indexOf('Negado') !== -1){
            status = 2
            break
        }
        else if(record.pessoas[i].status.indexOf('Aprovado com ressalva') !== -1){
            status = 1
            break
        }
        else if(record.pessoas[i].status.indexOf('Aprovado') !== -1){
            status = 0
            break
        }
      }
      
      if(status === 0){
        textoMensagem = <Label style={{backgroundColor: '#006600', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado</Label>
      }
      if(status === 1){
        textoMensagem = <Label style={{backgroundColor: '#b37400', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado com ressalva</Label>
      }
      if(status === 2){
        textoMensagem = <Label style={{backgroundColor: '#660000', borderRadius: '4px', color: 'white'}} className='p-1'>Negado</Label>
      }
      
      // if(record.etapa === 'Retorno da busca' || record.etapa === 'Gerar contrato' || record.etapa === 'Realizar busca'){
      //   button = <Button className='colorButton float-right' to={link} tag={Link}>Gerar contrato</Button>
      // }
      // else{
      //   button = <Button className='colorButton float-right' to={linkConsulta} tag={Link}>Voltar para consulta</Button>
      // }
      return (
        <Col key={record.consulta_id} md={12} className='mb-4'>
          <Card>
            <CardHeader style={{backgroundColor: '#e6e6e6'}}>
              <h2 className="mb-0" style={{color: 'black'}}><strong>Consulta:</strong> {record.consulta_id}</h2>
            </CardHeader>
            <CardBody className='cardConsultas'>
              <strong>Valor: </strong>{FuncoesComum.formatarMoeda(valor)} <br/>
              {/* <strong>Locatário principal: </strong>{record.principal}<br/>
              <strong>Locatário(s) solidário(s): </strong><br/>{record.solidario.map(this.rowSolidario)} */}
              <strong>Data da consulta: </strong>{new Date(record.data_consulta).toLocaleString('pt-BR')}<br/>
              {/* <Button className='colorButtonSecundario float-right mt-4' to={linkDetalhe} tag={Link}>Detalhe da consulta</Button> */}
              <Label className='mt-2' style={{color: 'black'}}>
                <strong>Status: </strong>{textoMensagem}
              </Label>
            </CardBody> 
          </Card>
        </Col>
      )
    }

    rowPessoasConsulta = (record) => {
      var classExibir = 'd-none'
      if(JSON.parse(localStorage.getItem('dadosUsuario_test')).permissao === 'master'){
        classExibir = 'd-print-block'
      }
      var cpf_ou_cnpj
      if(record.cpf_cnpj.length > 14){
        cpf_ou_cnpj = <div>
                        {/* <strong>Administradores: </strong> {JSON.stringify(record.dadosFinanceiros.content.socios_administradores.conteudo.administradores)}<br/> */}
                        <strong>Administradores: </strong> {record.dadosFinanceiros.content.socios_administradores.conteudo.administradores && record.dadosFinanceiros.content.socios_administradores.conteudo.administradores.map(this.administradores)}<br/>
                        {/* <strong>Sócios: </strong> {JSON.stringify(record.dadosFinanceiros.content.socios_administradores.conteudo.socios)} */}
                        <strong>Sócios: </strong> {record.dadosFinanceiros.content.socios_administradores.conteudo.socios && record.dadosFinanceiros.content.socios_administradores.conteudo.socios.map(this.socios)}
                      </div>
      }
      else{
        cpf_ou_cnpj = <div>
                        {/* <strong>Participações: </strong> {JSON.stringify(record.dadosFinanceiros.content.participacao_societaria.conteudo.participacoes)} */}
                        <strong>Participações: </strong> {record.dadosFinanceiros.content.participacao_societaria.conteudo.participacoes && record.dadosFinanceiros.content.participacao_societaria.conteudo.participacoes.map(this.participacoes)}
                      </div>
      }
      var textoMensagem
      if(record.status.indexOf('Negado') !== -1){
        textoMensagem = <Label style={{backgroundColor: '#660000', borderRadius: '4px', color: 'white'}} className='p-1'>Negado</Label>
      }
      else if(record.status.indexOf('Aprovado com ressalva') !== -1){
        textoMensagem = <Label style={{backgroundColor: '#b37400', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado com ressalva</Label>
      }
      else if(record.status.indexOf('Aprovado') !== -1){
        textoMensagem = <Label style={{backgroundColor: '#006600', borderRadius: '4px', color: 'white'}} className='p-1'>Aprovado</Label>
      }
      var todosMotivos = new Set(record.motivo.replace(/ /g, '').split(','))
      var arrayMotivos = []
      for(let motivo of todosMotivos){
          arrayMotivos.push(motivo)
      }
      return (
        <Col key={record.cliente_id} md={this.state.tamanhoCol} className='mb-4'>
          <Card>
            <CardHeader style={{backgroundColor: '#e6e6e6'}}>
              <h2 className="mb-0" style={{color: 'black'}}><strong>{record.cpf_cnpj}</strong> </h2>
              <Label className='mt-2' style={{color: 'black'}}>
                <strong>Status: </strong>{textoMensagem}
              </Label><br/>
              <Label className='mt-2' style={{color: 'black'}}>
                <strong>Motivos: </strong>{arrayMotivos.map(this.motivos)}
                {/* <strong>Motivo: </strong>{this.motivosNegados(record.motivo)} */}
              </Label>
            </CardHeader>
            <CardBody className={'cardConsultas ' + classExibir }>
              <strong>Tipo locatário: </strong>{record.tipo_locatario} <br/>
              {/* <strong>CPF / CNPJ: </strong>{record.cpf_cnpj} <br/> */}
              {/* <strong>Telefone: </strong>{record.telefone}<br/> */}
              {/* <strong>Renda informada: </strong>{FuncoesComum.formatarMoeda(record.renda_informada)}<br/><br/> */}
              {/* <strong>Advertências: </strong>{JSON.stringify(record.dadosFinanceiros.content.advertencias)}<br/> */}
              <strong>Advertências: </strong>{[record.dadosFinanceiros.content.advertencias].map(this.advertencias)}<br/>
              {/* <strong>Bacen: </strong>{JSON.stringify(record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen)}<br/> */}
              <strong>Bacen: </strong>{record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen && record.dadosFinanceiros.content.bacen.conteudo.cheques_bacen.map(this.bacen)}<br/>
              {/* <strong>Confirmei: </strong>{JSON.stringify(record.dadosFinanceiros.content.confirmei.conteudo)}<br/> */}
              <strong>Confirmei: </strong>{[record.dadosFinanceiros.content.confirmei.conteudo].map(this.confirmei)}<br/>
              {/* <strong>Pendências internas: </strong>{JSON.stringify(record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas)}<br/> */}
              <strong>Pendências internas: </strong>{record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas && record.dadosFinanceiros.content.pendencias_internas.conteudo.pendencias_internas.map(this.pendenciasInternas)}<br/>
              {/* <strong>Pendências financeiras: </strong>{JSON.stringify(record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes)}<br/> */}
              <strong>Pendências financeiras: </strong>{record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes && record.dadosFinanceiros.content.pendencias_financeiras.conteudo.detalhes.map(this.todasPendenciasFinanceiras)}<br/>
              {/* <strong>Participações: </strong>{JSON.stringify(record.dadosFinanceiros.content.participacao_societaria.conteudo.participacoes)}<br/> */}
              <strong>Score: </strong>{record.dadosFinanceiros.content.score_serasa && [record.dadosFinanceiros.content.score_serasa.conteudo].map(this.scoreSerasa)}<br/>
              {cpf_ou_cnpj}
            </CardBody> 
            {/* <CardFooter>
              <Label className='mt-2' style={{color: 'black'}}>
                <strong>Status: </strong>{textoMensagem}
              </Label><br/>
              <Label className='mt-2' style={{color: 'black'}}>
                <strong>Motivos: </strong>{record.motivo.replace(/ /g, '').split(',').map(this.motivosNegados)}
                
              </Label>
            </CardFooter> */}
          </Card>
        </Col>
      )
    }

    motivos = (motivo) => {
      var textoMotivo = ''
      if(motivo !== ''){
        if(motivo === '1'){
          textoMotivo += 'Pendências internas e financeiras é maior que 50% do valor para locação' 
        }
        if(motivo === '2'){
          textoMotivo += 'Score menor que 25%' 
        }
        if(motivo === '3'){
          textoMotivo += 'Possui processos criminais' 
        }
        if(motivo === '10'){
          textoMotivo += 'Pendências internas e financeiras é menor que 50% do valor para locação' 
        }
        if(motivo === '20'){
          textoMotivo += 'Score menor que 50% e maior que 25%' 
        }
        if(motivo === '30'){
          textoMotivo += 'Possui processos' 
        }
        if(motivo === '40'){
          textoMotivo += 'Comprometimento mensal estimado é maior que 50%' 
        }
        if(motivo === '50'){
          textoMotivo += 'Possui antecedentes criminais' 
        }
      }
      return (
        <div key={motivo}>
          <Label >{textoMotivo}</Label>
        </div>
      )
    }

    advertencias = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.bacen}>
          <Label style={{fontSize: '0.8em'}}><strong>Protesto estadual:</strong> {record.protesto_estadual}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Bacen:</strong> {record.bacen}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Documentos roubados:</strong> {record.documentos_roubados}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Pendências financeiras:</strong> {record.pendencias_financeiras}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Pendências internas:</strong> {record.pendencias_internas}</Label>
        </div>
      )
    }

    scoreSerasa = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.score}>
          <Label style={{fontSize: '0.8em'}}><strong>Score:</strong> {record.score}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Percentual:</strong> {record.percentual}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Mensagem:</strong> {record.mensagem}</Label>
        </div>
      )
    }

    bacen = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.data_ocorrencia}>
          <Label style={{fontSize: '0.8em'}}><strong>Data da ocorrência:</strong> {record.data_ocorrencia}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Número do cheque:</strong> {record.numero_cheque}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Alínea cheque:</strong> {record.alinea_cheque}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Quantidade de *CCF:</strong> {record.quantidade_ccf_banco}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Valor:</strong> R$ {record.valor}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Número do banco:</strong> {record.numero_banco}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Nome do banco:</strong> {record.nome_banco}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Agência:</strong> {record.agencia}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Cidade:</strong> {record.cidade}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>UF:</strong> {record.uf}</Label><br/>
          <Label style={{fontSize: '0.6em'}}><strong>*Cadastro de Emitente de Cheques sem Fundos</strong></Label>
        </div>
      )
    }

    confirmei = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.nome_razao}>
          <Label style={{fontSize: '0.8em'}}><strong>Nome / Razão social:</strong> {record.nome_razao}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Nascimento / Fundação:</strong> {record.data_nascimento_fundacao}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Situação do documento:</strong> {record.situacao_documento}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Data da situação do documento:</strong> {record.data_situacao_documento}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Nome da mãe:</strong> {record.nome_mae}</Label>
        </div>
      )
    }

    pendenciasInternas = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.data_ocorrencia}>
          <Label style={{fontSize: '0.8em'}}><strong>Data da ocorrência:</strong> {record.data_ocorrencia}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Modalidade:</strong> {record.modalidade}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Avalista:</strong> {record.avalista}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Tipo da moeda:</strong> {record.tipo_moeda}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Valor:</strong> {record.valor}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Contrato:</strong> {record.contrato}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Origem:</strong> {record.origem}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Sigla Embratel:</strong> {record.sigla_embratel}</Label>
        </div>
      )
    }

    todasPendenciasFinanceiras = (record) => {
      return record.pendencias.map(this.pendenciasFinanceiras)
    }
    
    pendenciasFinanceiras = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.modalidade}>
          <Label style={{fontSize: '0.8em'}}><strong>Data da ocorrência:</strong> {record.data_ocorrencia}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Modalidade:</strong> {record.modalidade}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Avalista:</strong> {record.avalista}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Tipo da moeda:</strong> {record.tipo_moeda}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Valor:</strong> {record.valor}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Contrato:</strong> {record.contrato}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Origem:</strong> {record.origem}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Sigla Embratel:</strong> {record.sigla_embratel}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Sub judice:</strong> {record.subjudice}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Mensagem do sub judice:</strong> {record.mensagem_subjudice}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Tipo de anotação:</strong> {record.tipo_anotacao}</Label><br/>
        </div>
      )
    }

    participacoes = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.cnpj_empresa}>
          <Label style={{fontSize: '0.8em'}}><strong>Nome da empresa:</strong> {record.nome_empresa}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>CNPJ da empresa:</strong> {record.cnpj_empresa}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Percentual da participação:</strong> {record.percentual_participacao}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>UF:</strong> {record.uf}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Início da participação:</strong> {record.data_inicio_participacao}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Data de atualização:</strong> {record.data_atualizacao}</Label>
        </div>
      )
    }

    administradores = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.nome_socio}>
          <Label style={{fontSize: '0.8em'}}><strong>Documento do sócio:</strong> {record.documento_socio}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Nome:</strong> {record.nome_socio}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Tipo de pessoa:</strong> {record.tipo_pessoa}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Cargo:</strong> {record.cargo}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Possui restrição financeira:</strong> {record.possui_restricao_financeira}</Label>
        </div>
      )
    }
    
    socios = (record) => {
      return (
        <div className='m-2 p-2' style={{backgroundColor: '#f8f8f8', borderRadius: '4px'}} key={record.nome_socio}>
          <Label style={{fontSize: '0.8em'}}><strong>Documento do sócio:</strong> {record.documento_socio}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Nome:</strong> {record.nome_socio}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Tipo de pessoa:</strong> {record.tipo_pessoa}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Percentual:</strong> {record.percentual}</Label><br/>
          <Label style={{fontSize: '0.8em'}}><strong>Possui restrição financeira:</strong> {record.possui_restricao_financeira}</Label>
        </div>
      )
    }

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className={"mt-4 pb-8"} fluid style={{minHeight: '65vh'}}>
          <Row>
            {this.state.retornoConsulta.map(this.rowConsulta)}
          </Row>
          <Row>
            {this.state.pessoas.map(this.rowPessoasConsulta)}
          </Row>
        </Container>
      </>
    );
  }
}

export default DetalheConsulta;
